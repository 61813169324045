import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SGF_API } from '../../../../../shared/api/sgf.api';
import { ClientePlanejamento } from 'app/main/shared/model/cliente-planejamento/cliente-planejamento.model';

@Injectable({
  providedIn: 'root'
})
export class ClientePlanejamentoService {

  host = `${SGF_API}` + 'sgf/api/public/cliente-planejamento';
  hostCliente = `${SGF_API}` + 'cliente/api/public/cliente-planejamento';
  private posto: string;

  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    return this.httpClient.get<ClientePlanejamento[]>(this.host, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  page(size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  pageSitucao(size: number, page: number, situacao: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&situacao=in:${situacao}&${this.posto}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  
  }
  
  pageFuncionario(size: number, page: number, funcionario: number): Observable<any> {
    this.checkIfHasPosto();
    
    return this.httpClient.get(`${this.host}?size=${size}&${this.posto}&page=${page}&funcionario=${funcionario}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
   
  }

  pageNomeCliente(size: number, page: number, nomeCliente: string): Observable<any> {
    this.checkIfHasPosto();
    
    return this.httpClient.get(`${this.host}?nomeCliente=like:${nomeCliente}&size=${size}&${this.posto}&page=${page}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  addCliente(clientePlanejamento: ClientePlanejamento): Observable<any> {
    const body = JSON.stringify(clientePlanejamento);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  editCliente(cliente: ClientePlanejamento): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<ClientePlanejamento[]>(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  captar(cliente: any): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.post(`${this.host}/captar`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
      if (sessionStorage.getItem('funcionario')) {
        const user = JSON.parse(sessionStorage.getItem('funcionario'));
        this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
      }
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
