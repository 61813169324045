import { OnInit, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-repactuacao-divida-form',
  templateUrl: './repactuacao-divida-form.component.html',
  styleUrls: ['./repactuacao-divida-form.component.scss']
})
export class RepactuacaoDividaFormComponent implements OnInit {

  repactuacao: FormGroup;
  repactuarIsEnable = true;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<RepactuacaoDividaFormComponent>,
    public dialog: MatDialog,
    public contratoService: ContractsService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  ngOnInit(): void {
    let valorParcela = 0;
    let valorPagoParcela = 0;
    this._data.contrato.listParcela
      .filter(p => ['GERADA'].includes(p.situacaoParcela))
      .forEach(parcela => {
        valorParcela += parcela.valor;
        valorPagoParcela += parcela.valorPago;
      });
    this.repactuacao = this.formBuilder.group({
      quantidadeParcelas: 1,
      valorAcordado: valorParcela - valorPagoParcela,
      periodicidade: -1,
      dataLiberacao: null
    });
  }

  validateDialog(value): any {
    let isValid = true;
    let dialogMessage = {};

    const quantidadeParcelas = Number(value.quantidadeParcelas);
    const valorAcordado = value.valorAcordado;
    let periodicidade = value.periodicidade;
    let dataLiberacao = value.dataLiberacao;

    if (dataLiberacao != null) {
      dataLiberacao = value.dataLiberacao.getTime ? value.dataLiberacao.getTime() : value.dataLiberacao.valueOf();
    }

    if (!quantidadeParcelas || quantidadeParcelas < 1) {
      isValid = false;
      dialogMessage = { type: 'info', title: 'Informação!', text: `A quantidade de parcelas deve ser um número acima de 0!` };
    }

    if (valorAcordado != null && valorAcordado < 1) {
      isValid = false;
      dialogMessage = { type: 'info', title: 'Informação!', text: `O novo valor da dívida deve ser acima de 0!` };
    }

    if (isValid === false) {
      this.repactuarIsEnable = true;
      this.feedback(dialogMessage);
    }

    if (periodicidade === -1) {
      periodicidade = null;
    }

    return { isValid, quantidadeParcelas, valorAcordado, periodicidade, dataLiberacao };
  }

  onSave(value): any {
    
    this.repactuarIsEnable = false;
    
    const { isValid, ...repactuacaoData } = this.validateDialog(value);
    
    if (isValid === false) {
      return;
    }

    const [id, { quantidadeParcelas, valorAcordado, periodicidade, dataLiberacao }] = [this._data.contrato.id, repactuacaoData];
    this.contratoService.repactuarDivida(id, quantidadeParcelas, valorAcordado, periodicidade, dataLiberacao)
      .subscribe(data => {
        if (data.success) {
          this.feedback({ type: 'success', title: 'Sucesso!', text: `Contrato repactuado com sucesso! Novo contrato criado.` });
          this.matDialogRef.close(data.object);
        } else {
          const dialogMessages = (data.messages[0].text != null)
            ? { type: 'info', title: 'Informação!', text: `${data.messages[0].text}` }
            : { type: 'error', title: 'Error!' };
            this.feedback(dialogMessages);
            this.repactuarIsEnable = true;
        }
      });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  private feedback(message: {}): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 1500);
  }

}
