import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';

import { GeneratedListComponent } from './generated-list/generated-list.component';
import { GeneratedMainComponent } from './generated-main/generated-main.component';
import { GeneratedSidebarComponent } from './generated-sidebar/generated-sidebar.component';
import { ContractsService } from '../../services/contracts.service';
import { SharedMaterialComponentsModule } from '../../../../shared-material-components.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '**',
    component: GeneratedMainComponent,
    resolve: {}
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    FlexLayoutModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})


  ],
  declarations: [GeneratedMainComponent, GeneratedListComponent, GeneratedSidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContractsService]
})
export class GeneratedModule { }
