import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras
} from '@angular/router';
import { Subscription } from 'rxjs';
import { uuid } from '../../../main/shared/util/uuid';
import { AuthenticationService, AuthState } from '../../../main/authentication/services/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    
    loggedIn: boolean;
    private authChangeSubscription_: Subscription;

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ){
        this.authChangeSubscription_ = this.authService.authChange.subscribe(
            newAuthState => this.loggedIn = (newAuthState === AuthState.LoggedIn)
        );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        const isLoggedIn = this.loggedIn;

        if (sessionStorage.getItem('Authorization')) { return true; }
     
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;
     
        const sessionId = uuid();

        const navigationExtras: NavigationExtras = {
            queryParams: { 'session_id': sessionId },
            fragment: 'anchor'
        };

        // Navigate to the login page
        this.router.navigate(['authentication/login'], navigationExtras);
        return false;
      }

}
