import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaquinaCartaoService {
  private despesa =  `${SGF_API}` +  'sgf/api/public/maquinacartao';
  private posto: string;
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  findAll(): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.despesa}?size=10000`)
      .pipe(
        catchError(this.handleError)
      );
  }

  findDesvinculadas(antecipacao: any): Observable<any> {
    if (antecipacao == null) {
      antecipacao = true;
    }
    return this.httpClient.get(`${this.despesa}/desvinculadas/`+antecipacao, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  listarMaquinas(): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.despesa}/listar-maquinas?${this.posto}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  create(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.post(this.despesa, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(this.despesa, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  page(size: number, page: number, postoId?: any): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.despesa}?size=${size}&page=${page}&${this.posto}`)
      .pipe(
        catchError(this.handleError)
      )
    
  }

  filtroMaquinaCartao(filter: string, page: number,  size: number,  postoId?: any): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.despesa}?numero=like:${filter}&page=${page}&${this.posto}&size=${size}&sort=-id`)
      .pipe(
        catchError(this.handleError)
      )
    
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
 }


  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
