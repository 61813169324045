import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from '../../model/contract/contract.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
declare var jsPDF: any;

@Component({
  selector: 'app-lancamentos',
  templateUrl: './lancamentos.component.html',
  styleUrls: ['./lancamentos.component.scss'],
  animations:fuseAnimations
})
export class LancamentosComponent implements OnInit {

  @Input() lancamentos:any;
  @Input() contrato: Contract;
  @Input() valorTotal: number = 0;
  @Input() valorEspecie: number = 0;
  @Input() valorExcedente: number = 0;
  @Input() valorPago: number = 0;
  @Input() valorJuros: number = 0;

  @Input() showEditarValor: boolean;
  @Output() editarLancamentoEvent = new EventEmitter;

  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  timeout:any;
  valorAReceber: number = 0;
  selectedClear: boolean;
  resumoContrato: string;
  resumoPagamento:string;
  constructor() { }

  ngOnInit() {
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }
  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  editarLancamento(value){
    this.editarLancamentoEvent.emit(value);
  }

  gerarPDF() {
    let rows = [];

    let dataFinalizacao = new Date(this.contrato.dataFinalizacao);
    let dataVencimento = new Date(this.contrato.dataVencimento);
    let valorExcedenteAnterior = this.contrato.valorExcedenteAnterior != null ? this.contrato.valorExcedenteAnterior : 0;
    if (this.valorJuros <= 0) {
      this.valorJuros = 0;
    }
    this.valorAReceber = (this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros)) + this.valorJuros - this.valorPago;
    let hoje = new Date();
    let doc = new jsPDF('p', 'pt', 'a4');
    this.resumoContrato = `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, N°: ${this.contrato.id}\nValor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor a receber: ${this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nVencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}\nSituação: ${this.contrato.situacaoContrato}\nFinalização do Contrato: ${this.contrato.dataFinalizacao != null ? dataFinalizacao.toLocaleDateString('pt-br') : 'Nenhuma informação'}`
    this.resumoPagamento =  `Valor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor Excedente do Contrato Anterior: ${valorExcedenteAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor pago maquineta: ${this.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor pago em espécie: ${this.valorEspecie.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor juros: ${this.valorJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor excedente: ${this.valorExcedente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
    doc.setFontSize(11);
    doc.text(12, 20, `Emissão: ${hoje.toLocaleDateString('pt-br')} Hora: ${hoje.toLocaleTimeString('pt-br').slice(0, 5)}`);

    //Keys

    let colunas = [
      { title: "Resumo contrato", dataKey: "resumoContrato" },
      { title: "Resumo do pagamento", dataKey: "resumoPagamento" },
    ];

    doc.autoTable(colunas, rows = [{
      resumoContrato: this.resumoContrato,
      resumoPagamento: this.resumoPagamento
    }], {
      theme: "plain",
      margin: { horizontal: 7, top: 20 },
      bodyStyles: { valign: 'top' },
    });

    //Objeto criado para utilização da tablea
    this.lancamentos.forEach(element => {
      let date = new Date(element.dataVencimento)
      let data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valorLancamento: element.valorLancamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      }
      rows.push(data)
    })
    let columns = [
      { title: "Lançamento", dataKey: "dataVencimento" },
      { title: "Valor do Lançamento", dataKey: "valorLancamento" },
    ];

    //Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: "striped",
      margin: { horizontal: 7, top: 10 },
      bodyStyles: { valign: 'top' },
    });

    //Tabela exibida no pdf
    doc.autoTable(columns = [
      { title: "Valor Total", dataKey: "valorTotal" }], rows = [{
        valorTotal: this.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }], {
      theme: "plain",
      margin: { horizontal: 7 },
      bodyStyles: { valign: 'top' },
    });


    doc.save(`acompanhamento-lancamentos.pdf`);
  }
}
