import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ArquivoClienteService } from 'app/main/customers/services/arquivo-cliente.service';
import { ArquivoCliente } from '../../model/customer/arquivo-cliente.model';
import { Page } from '../../model/page/page.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-upload-documento',
  templateUrl: './upload-documento.component.html',
  styleUrls: ['./upload-documento.component.scss']
})
export class UploadDocumentoComponent implements OnInit {

  arquivoClienteForm: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  disabled = false;
  documento: File;
  nomeDocumento = '';
  documentoBase64 = '';
  arquivoCliente: ArquivoCliente = new ArquivoCliente();
  arquivosCliente = Array<ArquivoCliente>();
  itensAPI = Array<ArquivoCliente>();
  page: Page = new Page();
  pageNumber = 1;
  public loading = false;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<UploadDocumentoComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private service: ArquivoClienteService
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.over();
  }

  setForm(): void {
    this.arquivoClienteForm = this.formBuilder.group({
      tipoReferenciaArquivoCliente: new FormControl(null),
      fileName: new FormControl(null)
    });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(arquivo: any): void {
    this.arquivoCliente.tipoReferenciaArquivoCliente = arquivo.tipoReferenciaArquivoCliente;
    this.arquivoCliente.clienteId = this._data.cliente.id;
    this.service.save(this.arquivoCliente).subscribe(rest => {
      if (rest.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Upload realizado com Sucesso!`
        };
      } else {
        if (rest.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${rest.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
            text: 'Erro ao realizar upload'
          };
        }
      }
      this.feedback(this.dialogMessages);
      this.over();
    });
  }

  onFileSelected(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files.item(0);
    this.documento = file;
    this.nomeDocumento = file.name;

    const reader = new FileReader();
    const future = new Promise((resolve) => {
      reader.addEventListener('loadend', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });

    this.arquivoClienteForm.get('fileName').setValue(this.nomeDocumento);
    
    future.then(data => {
      this.transformBlobTo64(data);
      this.arquivoCliente.cliente = this._data.cliente;
      this.arquivoCliente.nome = this.nomeDocumento;
      this.arquivoCliente.documentoBase64 = this.documentoBase64;
    });
   
  }

  transformBlobTo64(result: any): void {
    this.documentoBase64 = result;
  }

  onExcluirArquivo(arquivo: any): void {
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: `Deseja excluir o arquivo: ${arquivo.nome} ?`,
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'
    };
    this.dialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {
        this.service.delete(arquivo.id).subscribe(result => {
          if (result.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Arquivo escluído com Sucesso!`
            };
            this.over();
          } else {
            if (data.messages != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${data.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
                text: 'Erro ao realizar exclusão'
              };
            }
          }
          this.feedback(this.dialogMessages);
        });
      }
    });
  }

  onDownloadArquivo(arquivo: any): void {
    this.service.baixarArquivo(arquivo.id).subscribe((comprovante: Blob | any) => {
      if (comprovante && comprovante.size > 0) {
        FileSaver.saveAs(
          comprovante, `${arquivo.nome}`,
        );
      } else {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Não tem comprovante para esse pagamento`,
        };
      }
    });
  }

  private feedback(message: string[]): void  {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  over(): void {
    this.service.pageByCliente(10, 1, this._data.cliente.id).subscribe(data => {
      if (data.success) {
        this.arquivosCliente = data.object.content;
        const page = data.object;
        page.pageNumber = 0;
        this.setPage(page);
        this.itensAPI = this.arquivosCliente;
      }
      
    });
  }

  setPage(obj: any): void {
    this.page.pageNumber = obj ? obj.pageNumber : 0;
    this.page.totalPages = obj ? obj.totalPages : 0;
    this.page.size = obj ? obj.size : 0;
    this.page.totalElements = obj ? obj.totalElements : 0;
  }
}
