import { Injectable } from '@angular/core';
import { ContaService } from '../services/conta-service.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveContaService {

  constructor(private contaService: ContaService) {}

  resolve(): Observable<any> {
    return this.contaService.findAll().pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
