import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { Information } from '../../model/information/information.model';
import { CaptacaoService } from '../../../customers/components/captacao/services/captacao.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { CustomersModel } from '../../model/customers/customers-model';
import { SegmentsService } from '../../../global/components/segments/services/segments.service';
import { ListUnididadeFederacao } from '../../model/uf/ufs.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { Estado } from '../../model/estado/estado.model';
import { GlobalService } from 'app/main/global/services/global.service';
import { ProdutoService } from 'app/main/global/components/produto/services/produto-service.service';
import { CustomersService } from 'app/main/customers/services/customers.service';
import { Cliente } from '../../model/cliente/cliente.model';
import { IndicadorModel } from '../../model/indicador/indicador-model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';
import { Team } from '../../model/team/team.model';
import { TeamService } from 'app/main/global/components/teams/services/team.service';
import { Telephone } from '../../model/telephone/telephone.model';
declare var google: any;

@Component({
  selector: 'app-captacao-full',
  templateUrl: './captacao-full.component.html',
  styleUrls: ['./captacao-full.component.scss']
})
export class CaptacaoFullComponent implements OnInit{

  titleHeader: string;

  @Input() customerId;
  dialogRef: any;
  snackbarRef: any;
  maska: string;
  selected: any;
  dialogMessages;

  imageData: any[] = [];
  step = 0;
  totalCustomer: number;
  customer: FormGroup;
  showPanelId: Boolean;
  currentCustomer: any;
  customerInformation: Information;
  telephones: FormArray;
  captadorPosto: Posto = new Posto({});
  
  segmentList: any[] = [];
  bairrosList: any[] = [];
  estadosList: Estado[];
  municipiosDescriptions: any[] = [];
  ufs = ListUnididadeFederacao;
  objectMunicipio: any;
  municipio: any;
  listFuncionarios: Array<Employee> = [];
  listClientes: Array<Cliente> = [];
  loggedEmployee: Employee;
  editedCaptacao: any;
  disabledButton: boolean;
  // valida cpf e cnpj
  cpfCnpj = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;
  isCpf: boolean;
  isIndicadorPf: boolean;
  isNewIndicador = false;
  possuiIndicador = false;
  tipoIndicador: any;
  indicador: any;
  postos: Array<Posto>;
  equipes: Array<Team>;
  captador: Employee;
  loading = false;
  isEletro = false;

  public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<CaptacaoFullComponent>,
    public _matDialog: MatDialog,
    private segmentService: SegmentsService,
    private captacaoService: CaptacaoService,
    private employeeService: EmployeeService,
    private customerService: CustomersService,
    private produtoService: ProdutoService,
    private teamService: TeamService,
    public snackBar: MatSnackBar,
    private globalService: GlobalService,
    private postoService: PostsService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

      if (this.usuario.idPosto == null) {
        this.postoService.listarPostos().subscribe(data => {
          this.postos = data.object;
        });
      } else {
        this.teamService.findByPostoId(this.usuario.idPosto).subscribe(data => {
          this.equipes = data.object;
        });
      }

      this.isEletro = this.usuario.postoEletro;   

      this.currentCustomer = this.loadFormControlDefaults();

      this.employeeService.findAll().subscribe(data => {
        this.listFuncionarios = data.object.content;
      });

      this.employeeService.findById(this.usuario.idFuncionario).subscribe(data => {
        
        if (data != null && data.success) {
          this.captador = data.object;
        } else {
          this.captador = null;
        }
      });

      this.segmentService.findAll().subscribe(data => {
        this.segmentList = data.object.content;
      });

      // tslint:disable-next-line:triple-equals
      if (this._data.action == 'update' && this._data.customer.cpf == null) {
        this.isCpf = false;
      } else {
        this.isCpf = true;
      }
    }

  loadFormControlDefaults(): any {
    let currentCustomer: any;
    switch (this._data.action) {
      case 'new': {
        this.changeTitleHeader();
        currentCustomer = new CustomersModel({});
        this.indicador = new IndicadorModel({});
        break;
      }
      case 'update': {
        this.changeTitleHeader();
        currentCustomer = this._data.customer;
        this.indicador = currentCustomer.indicador ? currentCustomer.indicador : new IndicadorModel({});
        this.loadCustomerIndicador(currentCustomer);
        break;
      }
    }
    return currentCustomer;
  }

  ngOnInit(): void {
    this.checkIfHasPosto();
    this.setFormGroup(true, this.isEletro);
  }

  

  changeTitleHeader(): void {
    if (!this._data.clientes) {
      // tslint:disable-next-line:triple-equals
      if (this._data.action == 'new') {
        this.titleHeader = 'Nova Captação';
      } else {
        this.titleHeader = 'Editar Captação';
      }
    } else {
      // tslint:disable-next-line:triple-equals
      if (this._data.action == 'new') {
        this.titleHeader = 'Novo Cliente';
      } else {
        this.titleHeader = 'Editar Cliente';
      }
    }
  }

  setFormGroup(recarregar: boolean, eletro: boolean) {
    let indicadorFuncionario = null;
    let indicadorCliente = null;
    let posto = null;
    let equipe = null;
    let visitador = null;

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      indicadorFuncionario = this._data.customer.indicadorFuncionario;
      indicadorCliente = this._data.customer.indicadorCliente;
      posto = this._data.customer.posto;
      equipe = this._data.customer.equipe;
      visitador = this._data.customer.visitador;

      // tslint:disable-next-line: triple-equals
      if (this.currentCustomer.listTelefone == undefined || this.currentCustomer.listTelefone.length == 0) {
        this.currentCustomer.listTelefone = [new Telephone({})];
      }

        // tslint:disable-next-line:triple-equals
      if (this.currentCustomer.tipoPessoa == 'PESSOA_FISICA') {
        this.isCpf = true;
      // tslint:disable-next-line:triple-equals
      } else if (this.currentCustomer.tipoPessoa == 'PESSOA_JURIDICA') {
        this.isCpf = false;
      }
    }
    
    this.customer = this.formBuilder.group(
      {
        id: this.currentCustomer ? this.currentCustomer.id : null,
        posto: posto ? this.currentCustomer.posto.descricao : null,
        equipe: equipe ? this.currentCustomer.equipe.descricao : null,
        visitador: visitador ? this.currentCustomer.visitador.id : null,
        // eletro: this.isEletro,
        isCpf             : [this.isCpf],
        possuiIndicador   : [this.possuiIndicador],
        tipoIndicador     : [this.tipoIndicador, eletro == true ? Validators.required : null],
        indicador         : this.buildIndicador(),
        indicadorFuncionario : [indicadorFuncionario ? indicadorFuncionario.id : null],
        indicadorCliente :  [indicadorCliente ? indicadorCliente.id : null],
        // tslint:disable-next-line:triple-equals
        nome              : [this.currentCustomer ? this.currentCustomer.nome : null],
          // tslint:disable-next-line:triple-equals
        nomeFantasia      : [this.currentCustomer ? this.currentCustomer.nomeFantasia : null, this.isCpf == false ? Validators.required : null],
        razaoSocial       : [this.currentCustomer ? this.currentCustomer.razaoSocial : null],
          // tslint:disable-next-line:triple-equals
        cpf               : [this.currentCustomer ? this.currentCustomer.cpf : null],
          // tslint:disable-next-line:triple-equals
        cnpj              : [this.currentCustomer ? this.currentCustomer.cnpj : null],
        atividadeComercial: [this.currentCustomer.atividadeComercial ? this.currentCustomer.atividadeComercial.descricao : null],
        listTelefone      : this.buildPhone(),
        listEndereco      : this.buildAddress(recarregar),
        turno             : [this.currentCustomer ? this.currentCustomer.turno : null],
        produto           : [this.currentCustomer.produto ? this.currentCustomer.produto : null],
        listCaptadoProduto: this.buildProduto(),
        tipoPessoa : this.currentCustomer ? this.currentCustomer.tipoPessoa : null,
        instagram         : [this.currentCustomer ? this.currentCustomer.instagram : null],
        instagramProfissional  : [this.currentCustomer ? this.currentCustomer.instagramProfissional : null],
        nomeDoComercio  : [this.currentCustomer ? this.currentCustomer.nomeDoComercio : null],
        inicioFuncionamento  : [this.currentCustomer ? this.currentCustomer.inicioFuncionamento : null],
        fimFuncionamento  : [this.currentCustomer ? this.currentCustomer.fimFuncionamento : null],
        tempoComercial  : [this.currentCustomer ? this.currentCustomer.tempoComercial : null],
        canalAquisicao  : [this.currentCustomer ? this.currentCustomer.canalAquisicao : null],
        nivelInteresse  : [this.currentCustomer.nivelInteresse ? this.currentCustomer.nivelInteresse : null],
        detalhesInteracao  : [this.currentCustomer ? this.currentCustomer.detalhesInteracao : null],
        dataRegistro  : [this.currentCustomer.dataRegistro ?  new Date(this.currentCustomer.dataRegistro) : null]

      }
    );
  }

  getIsCpfEvent(value): void {
    this.customer.get('isCpf').setValue(value);
    this.isCpf = value;
    this.setFormGroup(false, this.isEletro);
  }

  getIsIndicadorPfEvent(value): void {
    this.customer.get('indicador').get('isCpf').setValue(value);
    this.isIndicadorPf = value;
  }

  setPossuiIndicador(value): void {
      // tslint:disable-next-line:triple-equals
    if (value == 'NAO_POSSUI_INDICADOR' || value == null) {
      this.customer.get('possuiIndicador').setValue(false);
      this.possuiIndicador = false;
    } else {
      this.customer.get('possuiIndicador').setValue(true);
      this.possuiIndicador = true;
    }
  }

  getTipoIndicador(value): void {
    this.customer.get('tipoIndicador').setValue(value);
    this.tipoIndicador = value;
    // tslint:disable-next-line:triple-equals
    if (this.tipoIndicador == 'NOVO_INDICADOR') {
      this.isNewIndicador = true;
      this.customer.get('indicadorFuncionario').setValidators(null);
      this.customer.get('indicadorCliente').setValidators(null);
      this.customer.removeControl('indicador');
      this.customer.addControl('indicador', this.buildIndicador());
    }
    // tslint:disable-next-line:triple-equals
    if (this.tipoIndicador == 'NAO_POSSUI_INDICADOR') {
      this.customer.get('indicadorFuncionario').setValidators(null);
      this.customer.get('indicadorCliente').setValidators(null);
      this.customer.removeControl('indicador');
    }
    // tslint:disable-next-line:triple-equals
    if (this.tipoIndicador == 'INDICADOR_CLIENTE') {
      this.customer.get('indicadorFuncionario').setValidators(null);
      this.customer.get('indicadorCliente').setValidators(Validators.required);
      this.customer.removeControl('indicador');
    }
    // tslint:disable-next-line:triple-equals
    if (this.tipoIndicador == 'INDICADOR_FUNCIONARIO') {
      this.customer.get('indicadorCliente').setValidators(null);
      this.customer.get('indicadorFuncionario').setValidators(Validators.required);
      this.customer.removeControl('indicador');
    }

    this.setPossuiIndicador(value);
  }

  buildPhone(): FormArray {

    const phone = this.currentCustomer.listTelefone.map(v => {

      return this.formBuilder.group({
        ativo   : new FormControl(v.ativo || null),
        numero  : new FormControl(v.numero || null),
        id      : new FormControl(v.id || null),
        instalarAppCliente: new FormControl(v.instalarAppCliente || false),
        possuiInternet: new FormControl(v.possuiInternet || false),
        tipoSmartphone: new FormControl(v.tipoSmartphone || false)
      });
    });
    return this.formBuilder.array(phone);
  }

  buildPhoneReference(listTelefone: any, required?): FormArray {
    const phone = listTelefone.map(tel => {
      return this.formBuilder.group({
        ativo: new FormControl(tel.ativo ? tel.ativo : true),
        numero: new FormControl(tel.numero ? tel.numero : '', required ? Validators.required : null),
        id: new FormControl(tel.id ? tel.id : null),
        instalarAppCliente: new FormControl(tel.instalarAppCliente || false),
        possuiInternet: new FormControl(tel.possuiInternet || false),
        tipoSmartphone: new FormControl(tel.tipoSmartphone || false)
      });
    });
    return this.formBuilder.array(phone);
  }

  buildIndicador(): FormGroup {
    return this.formBuilder.group({
       id: this.indicador ? this.indicador.id : null,
       isCpf             : [this.isIndicadorPf],
         // tslint:disable-next-line:triple-equals
       email: new FormControl(this.indicador ? this.indicador.email : null, (this.isNewIndicador == true && this.isIndicadorPf == true) ? 
       Validators.compose([Validators.required, Validators.email]) : null),
         // tslint:disable-next-line:triple-equals
       nome: [this.indicador ? this.indicador.nome : null, (this.isNewIndicador == true && this.isIndicadorPf == true) ? Validators.required  : null],
         // tslint:disable-next-line:triple-equals
       percentual: [this.indicador ? this.indicador.percentual : null, this.isNewIndicador == true ? Validators.required  : null],
         // tslint:disable-next-line:triple-equals
       cpf: [this.indicador ? this.indicador.cpf : null, (this.isNewIndicador == true && this.isIndicadorPf == true) ? Validators.required  : null],
         // tslint:disable-next-line:triple-equals
       nomeFantasia      : [this.indicador ? this.indicador.nomeFantasia : null, (this.isNewIndicador == true && this.isIndicadorPf == false) ? Validators.required : null],
         // tslint:disable-next-line:triple-equals
       razaoSocial       : [this.indicador ? this.indicador.razaoSocial : null],
         // tslint:disable-next-line:triple-equals
       cnpj              : [this.indicador ? this.indicador.cnpj : null, (this.isNewIndicador == true && this.isIndicadorPf == false) ? Validators.required  : null],
         // tslint:disable-next-line:triple-equals
       listTelefone: this.buildPhoneReference(this.indicador ? this.indicador.listTelefone : [], this.isNewIndicador == true ? false : null),
         // tslint:disable-next-line:triple-equals
       listEndereco: this.buildIndicadorAddress(this.indicador ? this.indicador.listEndereco : [], this.isNewIndicador),

       tipoPessoa : this.indicador ?  this.indicador.tipoPessoa : null
     });
 
   }

  get phoneForms(): FormArray {
    return this.customer.get('listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  get indicadorPhoneForms(): FormArray {
    return this.indicador.get('listTelefone') as FormArray;
  }

  deleteIndicadorPhone(index: number): void {
    this.indicadorPhoneForms.removeAt(index);
  }

  buildProduto(): FormArray {
    const produtos = this.currentCustomer.listCaptadoProduto ? this.currentCustomer.listCaptadoProduto : [''];
    const produto = produtos.map(v => {
      return this.formBuilder.group({
        id                 : new FormControl(v ? v.id :  null),
        captado            : new FormControl(v ? v.captadoId :  null),
        produto            : new FormControl(v ? v.produto :  null),
        quantidadeProduto  : new FormControl(v ? v.quantidade : null),
      });
    });
    return this.formBuilder.array(produto);
  }


  get produtoForms(): FormArray {
    return this.customer.get('listCaptadoProduto') as FormArray;
  }

  deleteProduto(index: number): void {
    this.phoneForms.removeAt(index);
  }

  buildIndicadorAddress(listEndereco: any, required?): FormArray {
    const address = listEndereco.map(a => {
      return this.formBuilder.group({
        id              : new FormControl(a ? a.id : null),
        cep             : new FormControl(a ? a.cep : null, required ? Validators.required : null),
        logradouro      : new FormControl(a ? a.logradouro : null, required ? Validators.required : null),
        numero          : new FormControl(a ? a.numero : null, required ? Validators.required : null),
        latitude        : new FormControl(a ? a.latitude : null),
        longitude       : new FormControl(a ? a.longitude : null),
        bairro          : new FormControl(a ? (a.bairro ? a.bairro.descricao : null) : null, required ? Validators.required : null),
        municipio       : new FormControl(a ? (a.municipio ? a.municipio.nomeMunicipio : null) : null, required ? Validators.required : null),
        complemento     : new FormControl(a ? a.complemento : null),
        estado          : new FormControl(a ? (a.estado ? a.estado.sigla : null) : null, required ? Validators.required : null),
        pontoReferencia : new FormControl(a ? a.pontoReferencia : null),
        logradouroCompleto : new FormControl(null),
        ativo           : new FormControl(true)
      });
    });

    return this.formBuilder.array(address, required ? Validators.required : null);
  }

  buildAddress(recarregar: boolean): FormArray {
    let address = null;
    if (recarregar) {

      address = this.currentCustomer.listEndereco.map(a => {
        return this.formBuilder.group({
          id              : new FormControl(this.currentCustomer ? a.id : null),
          cep             : new FormControl(this.currentCustomer ? a.cep : null),
          logradouro      : new FormControl(this.currentCustomer ? a.logradouro : null),
          numero          : new FormControl(this.currentCustomer ? a.numero : null),
          latitude        : new FormControl(this.currentCustomer ? a.latitude : null),
          longitude       : new FormControl(this.currentCustomer ? a.longitude : null),
          bairro          : new FormControl(this.currentCustomer ? (a.bairro ? a.bairro.descricao : null) : null),
          municipio       : new FormControl(this.currentCustomer ? (a.municipio ? a.municipio.nomeMunicipio : null) : null),
          complemento     : new FormControl(this.currentCustomer ? a.complemento : null),
          estado          : new FormControl(this.currentCustomer ? (a.estado ? a.estado.sigla : null) : null),
          pontoReferencia : new FormControl(this.currentCustomer ? a.pontoReferencia : null),
          tipoEndereco    : new FormControl(this.currentCustomer ? a.tipoEndereco : null),
          logradouroCompleto : new FormControl(null),
          ativo : new FormControl(true),
        });
      });
    
    } else {

      address = this.currentCustomer.listEndereco.map(a => {
        return this.formBuilder.group({
          id              : new FormControl(a ? a.id : null),
          cep             : new FormControl(a ? a.cep : null),
          logradouro      : new FormControl(a ? a.logradouro : null),
          numero          : new FormControl(a ? a.numero : null),
          latitude        : new FormControl(a ? a.latitude : null),
          longitude       : new FormControl(a ? a.longitude : null),
          bairro          : new FormControl(a ? (a.bairro ? a.bairro.descricao : null) : null),
          municipio       : new FormControl(a ? (a.municipio ? a.municipio.nomeMunicipio : null) : null),
          complemento     : new FormControl(a ? a.complemento : null),
          estado          : new FormControl(a ? (a.estado ? a.estado.sigla : null) : null),
          pontoReferencia : new FormControl(a ? a.pontoReferencia : null),
          tipoEndereco    : new FormControl(a ? a.tipoEndereco : null),
          logradouroCompleto : new FormControl(null),
          ativo           : new FormControl(true),
        });
      });
    }

    return this.formBuilder.array(address);

  }

  get addressForms(): FormArray {
    return this.customer.get('listEndereco') as FormArray;
  }

  get indicadorAddressForms(): FormArray {
    return this.indicador.get('listEndereco') as FormArray;
  }

  onAdd(customers: any): void {

    this.disabledButton = true;
    this.loading = true;
    
    if (this.captador != null) {
      customers.captador = this.captador;
    }
    
    // tslint:disable-next-line:triple-equals
    if (customers.tipoIndicador == 'NAO_POSSUI_INDICADOR') {
      customers.indicador = null;
    }

      // tslint:disable-next-line:triple-equals
    if (customers.produto == '' || customers.produto == null) {
      customers.produto = null;
    } else {
      this.produtoService.findById(customers.produto).subscribe(data => {
        customers.produto = data.object;
      });
    }

    if (customers.indicadorFuncionario != null) {
      customers.indicadorCliente = null;
      customers.indicador = null;
      this.employeeService.findById(customers.indicadorFuncionario).subscribe(data => {
        customers.indicadorFuncionario = data.object;
      });
    }

    if (customers.indicadorCliente != null) {
      customers.indicadorFuncionario = null;
      customers.indicador = null;
      this.customerService.findById(customers.indicadorCliente).subscribe(data => {
        customers.indicadorCliente = data.object;
      });
    }

    if (customers.indicador != null) {
      customers.indicadorFuncionario = null;
      customers.indicadorCliente = null;

      customers.indicador.listEndereco.forEach(data => {
        if (data.bairro != null) {
          if (typeof data.bairro === 'object') {
            data.municipio = data.bairro.municipio;
            data.estado = data.bairro.municipio.estado;
          } else {
            this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
              // tslint:disable-next-line:no-shadowed-variable
              const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
              data.bairro = bairro;
              data.municipio = bairro.municipio;
              data.estado = bairro.municipio.estado;
            });
          }
        }
      });
    }

    if (!customers.isCpf) {
      customers.cpf = null;
    }


    if (customers.indicador != null) {
      if (customers.indicador.isCpf) {
        customers.indicador.cnpj = null;
      } else {
        customers.indicador.cpf = null;
      } 
    }

    let UF = '';
    let municipio: any;
    
    customers.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });

    customers.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            // tslint:disable-next-line:no-shadowed-variable
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });
    

    delete customers.tipoIndicador;
    delete customers.isCpf;

    this.segmentList.forEach(att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == customers.atividadeComercial) {
        customers.atividadeComercial = att;
      }
    });

    this.segmentList.forEach(att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == customers.atividadeComercial) {
        customers.atividadeComercial = att;
      }
    });


    if (this.captadorPosto == null) {
      this.postos.forEach(att => {
        // tslint:disable-next-line:triple-equals
        if (att.descricao == customers.posto) {
          customers.posto = att;
        }
      });
    } else {
      customers.posto = this.captadorPosto;
    }

    customers.equipe = this.equipes.find(equipe => equipe.descricao === customers.equipe);

    // tslint:disable-next-line:triple-equals
    if (this.isCpf == true) {
      customers.type = 'pf';
      customers.tipoPessoa = 'PESSOA_FISICA';
    } else {
      customers.type = 'pj';
      customers.tipoPessoa = 'PESSOA_JURIDICA';
    }

    
    setTimeout(() => {
      this.create(customers);
    }, 2000);
  }

  onUpdate(customers: any): void {
    console.log(customers);
    this.disabledButton = true;
    this.loading = true;

    // tslint:disable-next-line:triple-equals
    if (customers.tipoIndicador == 'NAO_POSSUI_INDICADOR') {
      customers.indicador = null;
    }

    // tslint:disable-next-line:triple-equals
    if (customers.produto == '' || customers.produto == null) {
      customers.produto = null;
    } else {
      this.produtoService.findById(customers.produto).subscribe(data => {
        customers.produto = data.object;
      });
    }

    if (customers.indicadorFuncionario != null) {
      customers.indicadorCliente = null;
      customers.indicador = null;
      this.employeeService.findById(customers.indicadorFuncionario).subscribe(data => {
        customers.indicadorFuncionario = data.object;
      });
    }

    if (customers.indicadorCliente != null) {
      customers.indicadorFuncionario = null;
      customers.indicador = null;
      this.customerService.findById(customers.indicadorCliente).subscribe(data => {
        customers.indicadorCliente = data.object;
      });
    }

    if (customers.indicador != null) {
      customers.indicadorFuncionario = null;
      customers.indicadorCliente = null;

      customers.indicador.listEndereco.forEach(data => {
        if (data.bairro != null) {
          if (typeof data.bairro === 'object') {
            data.municipio = data.bairro.municipio;
            data.estado = data.bairro.municipio.estado;
          } else {
            this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
              // tslint:disable-next-line:no-shadowed-variable
              const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
              data.bairro = bairro;
              data.municipio = bairro.municipio;
              data.estado = bairro.municipio.estado;
            });
          }
        }
      });
    }

    let UF = '';
    let municipio: any;

    customers.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });

    // tslint:disable-next-line:triple-equals
    if (customers.tipoIndicador == 'NAO_POSSUI_INDICADOR' || customers.tipoIndicador == null) {     
      customers.possuiIndicador = false;
    } else {
      customers.possuiIndicador = true;
    }
    
    delete customers.tipoIndicador;
    delete customers.isCpf;

    this.segmentList.forEach(att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == customers.atividadeComercial) {
        customers.atividadeComercial = att;
      }
    });


    customers.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            // tslint:disable-next-line:no-shadowed-variable
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    if (this.captadorPosto == null) {
      this.postos.forEach(att => {
        // tslint:disable-next-line:triple-equals
        if (att.descricao == customers.posto) {
          customers.posto = att;
        }
      });
    } else {
      customers.posto = this.captadorPosto;
    }

    customers.equipe = this.equipes.find(equipe => equipe.descricao === customers.equipe);

    // tslint:disable-next-line:triple-equals
    if (this.isCpf == true) {
      customers.type = 'pf';
      customers.tipoPessoa = 'PESSOA_FISICA';
    } else {
      customers.type = 'pj';
      customers.tipoPessoa = 'PESSOA_JURIDICA';
    }

    // tslint:disable-next-line:triple-equals
    if (this.isIndicadorPf == true) {
      customers.type = 'pf';
      if (customers.indicador != null) {
        customers.indicador.tipoPessoa = 'PESSOA_FISICA';
      }
    } else {
      customers.type = 'pj';
      if (customers.indicador != null) {
        customers.indicador.tipoPessoa = 'PESSOA_JURIDICA';
      }
    }

    customers.listEndereco.forEach(element => {
      this.googleMaps(element);
    });


    customers.listTelefone.forEach(element => {
      const numero = element.numero.replace(/[\(\)\.\s-]+/g, '');
      element.numero = numero;
    });

    setTimeout(() => {
      this.update(customers);
    }, 2000);

  }

  private validateTelefone(object: any): boolean {
    
    let valid = true;
    
    for (const telefone of object.listTelefone) {
      
      if (telefone.numero.length < 10) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Quantidade de digitos do telefone incorreto - ${telefone.numero}`
        };
        this.feedback(this.dialogMessages);
        this.disabledButton = false;
        this.loading = false;
        valid = false;
        break;
      } else {
        valid = true;
      }
    }
    return valid;
  }

  private validate(object: any): boolean {
    return this.validateTelefone(object);    
  }

  private create(object: any): void {

    if (!this.validate(object)) {
      return;
    }
    const edit = false;
    this.captacaoService.create(object).subscribe(
      (data) => {
        this.disabledButton = false;
        this.loading = false;
        if (data.success) {
          this.uploadImage(edit, data.object);
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.loading = false;
          this.disabledButton = false;
          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        console.log(onerror);
        if (onerror.response && onerror.response.status === 400 && onerror.response.error.messages) {
          this.dialogMessages = {
            type: 'info',
            title: 'Dado(s) Inválido(s)',
            text: `${onerror.response.error.messages[0].text.split(' - ', 2)[1]}`
          };
          this.feedback(this.dialogMessages);
          this.loading = false;
          this.disabledButton = false;
        }
      }
    );
  }

  getImages(event): void {
    this.imageData = event;
  }

  private update(object: any): void {
    if (!this.validate(object)) {
      return;
    }

    const edit = true;
    this.captacaoService.update(object).subscribe(
      (data) => {
        this.disabledButton = false;
        this.loading = false;
        if (data.success) {
          this.uploadImage(edit, data.object);
          this.editedCaptacao = data.object;
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.disabledButton = false;
          this.loading = false;
          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }

  getEmployeeLogged(event): void {
    this.loggedEmployee = event;
  }


  getIndicadorValidate(event): void {
    // tslint:disable-next-line:triple-equals
    if (event == true) {
      this.disabledButton = true;
    } else {
      this.disabledButton = false;
    }
  }

  getEmployeeValidate(event): void {
    
    if(this.isEletro) {
      this.customer.get('tipoIndicador').setValidators(Validators.required);
    } else {
      this.customer.get('tipoIndicador').setValidators(null);
    }
    // tslint:disable-next-line:triple-equals
    if (event == true) {
      this.disabledButton = true;
    } else {
      this.disabledButton = false;
    }
  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  onClosed(): void {
    if (this.editedCaptacao) {
      this.matDialogRef.close({ captacao: this.editedCaptacao, success: true });
    } else {
      this.matDialogRef.close();
    }
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  getErrorMessage(field: string): any {
    return this.customer.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedbackSucesss(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 1500);
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 5000);
  }


  googleMaps(customer): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${customer.logradouro}, ${customer.numero}, ${customer.municipio ? customer.municipio.nomeMunicipio : ''}, ${customer.estado}, ${customer.cep}`;

    geocoder.geocode({ 'address': address }, (results, status) => {
      // tslint:disable-next-line:triple-equals
      if (status == 'OK') {
        customer.logradouroCompleto = results[0].formatted_address;

        customer.latitude = results[0].geometry.location.lat();
        customer.longitude = results[0].geometry.location.lng();
      }

    });
  }

  checkIfHasPosto(): boolean {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const postoId = user.idPosto ? user.idPosto : 0;
      this.postoService.findById(postoId).subscribe(r => {
        this.captadorPosto = r.object;
        if (this.captadorPosto.parametroPosto.eletro) {
          this.customer.get('tipoIndicador').setValidators(Validators.required);
          this.isEletro = true;
          return true;
        }
      });
    } else {
      return false;
    }
  }

  loadCustomerIndicador(customer: any): void {
    if (customer.possuiIndicador) {
      if (customer.indicador != null) {
        this.indicador = customer.indicador;
        this.isNewIndicador = true;
        if (this.indicador.cpf != null) {
          this.isIndicadorPf = true;
        }else {
          this.isIndicadorPf = false;
        }
       customer.tipoIndicador = 'NOVO_INDICADOR';
       this.tipoIndicador = 'NOVO_INDICADOR';
      } else if (customer.indicadorFuncionario != null) {
       customer.tipoIndicador = 'INDICADOR_FUNCIONARIO';
       this.tipoIndicador = 'INDICADOR_FUNCIONARIO';
      } else if (customer.indicadorCliente != null ) {
       customer.tipoIndicador = 'INDICADOR_CLIENTE';
       this.tipoIndicador = 'INDICADOR_CLIENTE';
      }
    }
  }

  private uploadImage(edit, captado) {
    this.imageData.forEach(image => {
        image.idCaptado = captado.id;
        if (image.contentsBase64 != null || image.contentsBase64 != undefined ) {
          image.contentsBase64 = image.contentsBase64.replace('jpeg', 'jpg', 'png').slice(22);
        }
    });

    this.captacaoService.listaDeArquivo64(this.imageData).subscribe(response => {
      if (response.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Informação',
          text: 'Captado inserida com sucesso'
        };
        if (edit) {
          this.dialogMessages.text = 'Captado editado com sucesso'
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(response);
        this.disabledButton = false;
        this.loading = false;
      } else {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação',
          text: response.messages[0].text
        };
        this.feedback(this.dialogMessages);
        this.disabledButton = false;
        this.loading = false;
      }
    }, (onerror) => {
      console.log('UPDATE ARQUIVO ERROR', onerror);
    });
  }

  preencherEndereco(value: any) {
    console.log(value);
    this.customer.get('listEndereco').setValue(value);
  }
}
