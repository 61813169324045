import { MarcaService } from './../../../global/components/marca/services/marca.service';
import { ModeloFormComponent } from './../modelo-form/modelo-form.component';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-marca-form',
  templateUrl: './marca-form.component.html',
  styleUrls: ['./marca-form.component.scss']
})
export class MarcaFormComponent implements OnInit {

  marca: FormGroup
  disabledButton: boolean;
  dialogMessages
  marcas = []
  titleHeader: string;

  @ViewChild('descricao') nome: ElementRef;

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private formBuild: FormBuilder,
    private matDialogRef: MatDialogRef<ModeloFormComponent>,
    private marcaService: MarcaService,
  ) {

    this.titleHeader = this._data.action == 'new' ? "Cadastro de Marca" : "Edição de Marca"
  }

  ngOnInit() {
    this.setForm();
  }


  ngAfterViewInit() {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }


  setForm() {
    this.marca = this.formBuild.group({
      id: [this._data.marca ? this._data.marca.id : null],
      nome: [this._data.marca ? this._data.marca.nome : null]
    })
  }

  onClosed() {
    this.matDialogRef.close()
  }

  onSave(value) {
    this.disabledButton = true;
    this.marcaService.create(value).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR MARCA', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    this.marcaService.update(value).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR MODELO', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
