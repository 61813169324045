import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormControl } from '@angular/forms';
import { ContratoLancamento } from 'app/main/shared/model/contrato-lancamento/contrato-lancamento.model';
import { ContratoLancamentoFormComponent } from 'app/main/shared/components/contrato-lancamento-form/contrato-lancamento-form.component';
import { ContratoLancamentoService } from 'app/main/contracts/services/contrato-lancamento.service';
declare var jsPDF: any;

@Component({
  selector: 'app-in-progress-lancamento-list',
  templateUrl: './in-progress-lancamento-list.component.html',
  styleUrls: ['./in-progress-lancamento-list.component.scss'],
  animations: fuseAnimations
})
export class InProgressLancamentoListComponent implements OnInit {

  contrato: any;
  lancamentos: ContratoLancamento[];
  parcelas: Parcela[];
  searchInput: FormControl;
  interval;
  filter;
  timeout: any;
  selected: Array<any>;
  dialogMessages;
  count: number = 0;
  public loading = false;
  valorTotal: number = 0;
  valorAReceber: number = 0;
  selectedClear: boolean;
  valorExcedente: number = 0;
  valorEspecie: number = 0;
  valorJuros: number = 0;
  valorPago: number = 0;
  resumoContrato: string;
  resumoPagamento:string;
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  permissoes = [];
  constructor(private contratoService: ContractsService,
    private contratoLancamentoService: ContratoLancamentoService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<Parcela>();

    const perm = JSON.parse(sessionStorage.getItem('permissoes'))
    perm.forEach(element => {
      this.permissoes.push(element)
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {
    this.listById();
  }

  listById() {
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object;
      this.parcelas = data.object.listParcela.sort((a, b) => a.ordem - b.ordem);
      
      this.parcelas.filter(p => p.dataAgendamento).forEach(k => {
        this.valorEspecie = this.valorEspecie + k.valorPago;
      })
      this.parcelas.forEach(k => {
        this.valorPago = this.valorPago + k.valorPago;

      })
      this.valorJuros = this.valorPago - this.contrato.visita.valorAprovado
    })
    this.contratoService.getLancamentos(this.route.snapshot.params['id']).subscribe(data => {
      this.lancamentos = data.object;

      this.lancamentos.forEach(data => {
        this.valorTotal = this.valorTotal + data.valorLancamento
      })

      this.valorExcedente = (this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros)) - this.valorTotal;
    })
  }


  over() {
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object
    })
    this.contratoService.getLancamentos(this.route.snapshot.params['id']).subscribe(data => {
      this.lancamentos = data.object;
    })
  }

  ngOnChanges() {
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  editarLancamento(lancamento) {
    this._matDialog.open(ContratoLancamentoFormComponent, {
      data: {
        lancamento: lancamento
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          lancamento.valorLancamento = data.valor;
          this.contratoLancamentoService.update(lancamento).subscribe((data) => {
            if (data.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `${data.messages[0].text}`
              }
              this.feedback(this.dialogMessages);
              this.over();
            } else {
              if (data.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${data.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages);
              this.over();
            }
          })
        }
      }
    )
  }

 

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }
}
