import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AttendanceService } from '../services/attendance.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveAttendanceService implements Resolve<any>{

  constructor(
    private attendanceService: AttendanceService) { }

  resolve(): Observable<any> {
    return this.attendanceService.findAll().pipe(
      take(1),
      mergeMap(attendance => {
        return of(attendance['object']);
      })
    );
  }

}
