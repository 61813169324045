import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { User } from '../../shared/model/user/user.model';
import { tap, catchError, map } from 'rxjs/operators';
import { SGF_API } from '../../../shared/api/sgf.api';
import * as SockJS from 'sockjs-client';
const Stomp = require('stompjs');

@Injectable()
export class AuthenticationService {

  private authManager_: BehaviorSubject<AuthState> = new BehaviorSubject(AuthState.LoggedOut);
  private authState_: AuthState;
  authChange: Observable<AuthState>;
  redirectUrl: string;
  private stompClient = null;

  user: User;
  token: string;

  private error: String;
  url: string = `${SGF_API}` + 'sgf/api/public/login';

  constructor(private http: Http) {
    this.authChange = this.authManager_.asObservable();
  }

  login(usuario): Observable<any> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const body = JSON.stringify(usuario);
    return this.http.post(this.url, body, options)
      .pipe(
        tap(res => {
          if (res.json().object) {
          this.user = res.json().object;
          this.token = res.json().object.token;
          this.setToken(this.token);
          this.setAuthState_(AuthState.LoggedIn);
          }
        }),
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response | any): any {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }

  private extractData(res: Response): any {
    const body = res.json();
    return body as any[] || {};
  }

  emitAuthState(): void {
    this.authManager_.next(this.authState_);
  }

  private setAuthState_(newAuthState: AuthState): void {
    this.authState_ = newAuthState;
    this.emitAuthState();
  }

  setToken(token: string): void {
    sessionStorage.setItem('Authorization', token);
  }

  logout(): void {
    sessionStorage.clear();
    this.setAuthState_(AuthState.LoggedOut);
  }

  connectToWebsocketWithStomp(): any {
    const socket = new SockJS(`${SGF_API}sgf/alerta-stomp`);
    const stompClient = Stomp.over(socket);
    return stompClient;
  }

  disconnect(): void {
    if (this.stompClient != null) {
      this.stompClient.disconnect();
    }
  }

  submit(): void {
    this.stompClient.send('/sgf/alertas', {});
  }

}

export const enum AuthState {
  LoggedIn,
  LoggedOut
}

export const AUTH_PROVIDERS: Array<any> = [
  { provide: AuthenticationService, useClass: AuthenticationService }
];

