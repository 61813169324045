import { Telephone } from "../telephone/telephone.model";
import { Address } from "../address/address.model";

export class IndicadorModel {

    id?: number;
    email?: string;
    nomeFantasia?: string;
    razaoSocial?: string;        
    nome?: string;
    percentual?: number;
    cpf?: string;
    cnpj?: string;
    listTelefone?: Telephone[];
    listEndereco?: Address[];

    constructor(obj?: any){
        this.id = obj.id || null;
        this.email = obj.email || null;
        this.nomeFantasia = obj.nomeFantasia || null;
        this.razaoSocial = obj.razaoSocial || null;
        this.nome = obj.nome || null;
        this.percentual = obj.percentual || null;
        this.cpf = obj.cpf || null;
        this.cnpj = obj.cnpj || null;
        this.listTelefone = obj.listTelefone || [new Telephone({})];
        this.listEndereco = obj.listEndereco || [new Address({})];
    }
}