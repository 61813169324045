import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SGF_API } from 'app/shared/api/sgf.api';
import { CaixaService } from '../caixa/caixa.service';
import { ValoresTransferenciaRequestDTO } from 'app/main/shared/model/valor-transferencia-request/valor-transferencia-request.dto';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaService {
  trasnferencia = `${SGF_API}` + 'sgf/api/public/transferencia';
  relatorioCobrancasFuncionario = `${SGF_API}` + 'financeiro/api/public/transferencia/imprimircobrancasfuncionario';
  private urlGraphQL = `${SGF_API}` + 'sgf/api/public/financeiro/graphql';
  cgeAtualId: number;
  private posto: string;
  private idPosto: any;
  private urlArquivo64 = `${SGF_API}` + 'sgf/api/public/arquivodespesa/saveForArquivoDespesaTO';
  private urlArquivoDespesa = `${SGF_API}` + 'sgf/api/public/arquivodespesa';

  constructor(
      private httpClient: HttpClient,
      ) { }

  saveArquivo64(base64List): Observable<any> {
    return this.httpClient.post(this.urlArquivo64, base64List, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  page(type: string, size: number, page: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}?type=${type}&size=${size}&page=${page}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getValorTotalPorTipoTransferencia(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<any> {
    this.checkIfHasPosto();
    valores.postoId = this.idPosto;
    return this.httpClient.post<any>(
      `${this.trasnferencia}/valor-total-por-tipo-transferencia`,
      valores,
    );
  }

  pageSobras(type: string, size: number, page: number): Observable<any> {

    this.checkIfHasPosto();

    return this.httpClient.get(`${this.trasnferencia}?type=${type}&size=${size}&page=${page}&${this.posto}&natureza=eq:1`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageSobrasFilterResponsavel(type: string, funcionario: string, size: number, page: number): Observable<any> {

    this.checkIfHasPosto();

    return this.httpClient.get(`${this.trasnferencia}?filters=%26nomeFuncionario%3D%25${funcionario}%25
      &type=${type}&size=${size}&page=${page}&${this.posto}&natureza=eq:1`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageSobrasFilterRota(type: string, rota: string, size: number, page: number): Observable<any> {

    this.checkIfHasPosto();

    return this.httpClient.get(`${this.trasnferencia}?filters=%26descricaoRota%3D%25${rota}%25
      &type=${type}&size=${size}&page=${page}&${this.posto}&natureza=eq:1`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  pageSobrasFilterByPeriodo(type: string, size: number, page: number, dataInicial: number, dataFinal: number): any {
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.trasnferencia}?type=${type}&size=${size}&page=${page}&${this.posto}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&natureza=eq:1`,
     this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  create(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.post(this.trasnferencia, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  transferirSaldoGeralControle(transferencia): Observable<any> {
    const body = JSON.stringify(transferencia);
    return this.httpClient.put(`${this.trasnferencia}/realizar-transferencia-geral-controle`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  transferirSaldoControleGeral(transferencia): Observable<any> {
    const body = JSON.stringify(transferencia);
    return this.httpClient.put(`${this.trasnferencia}/realizar-transferencia-controle-geral`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  gerarSaldoControle(saldoCaixaControleTO: any): Observable<any> {
    const body = JSON.stringify(saldoCaixaControleTO);
    return this.httpClient.put(`${this.trasnferencia}/gerar-saldo-caixa-controle`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarSaldoSobraGeral(saldoCaixaControleTO: any): Observable<any> {
    const body = JSON.stringify(saldoCaixaControleTO);
    return this.httpClient.put(`${this.trasnferencia}/gerar-saldo-sobra-caixa-geral`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(this.trasnferencia, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.trasnferencia}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancel(id: number): Observable<any> {
    return this.httpClient.post(`${this.trasnferencia}/cancelar`, id, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  listarTransferenciasProvisaoCobrancaGraphQL(size: number, page: number, caixaId: number): Observable<any> {
    const query = {
                    query: 
                    `{ listarTransferenciasProvisaoCobranca(size: ${size}, page: ${page} caixaId: ${caixaId}) 
                      { 
                        id valorPrevisto valorRealizado dataHoraCadastro
                        parcela { id situacaoParcela
                          responsavel { id } 
                          contrato {
                              id
                              visita {
                                id
                                cliente { 
                                  id
                                  apelidoCliente
                                  pessoa 
                                    { 
                                      id
                                      nomePessoa 
                                      listEndereco { bairro { id descricao}} 
                                      listTelefone{ id numero }
                                    } 
                                } 
                              } 
                          } 
                        } 
                      } 
                    }`
                  };

    return this.httpClient
      .post(`${this.urlGraphQL}`, query, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  listarCobrancasEmLancamentosDeEntradaGraphQL(size: number, page: number, caixaId: number, apelidoCliente?: string): Observable<any> {
    const query = `{
      listarCobrancasEmLancamentosDeEntrada(size: ${size}, page: ${page}, caixaId: ${caixaId}${apelidoCliente ? `, apelidoCliente: "${apelidoCliente}"` : ''}) {
        totalElements
        totalPages
        content {
          id, nomeCliente, situacao, dataCriacao
          idParcela, ordemParcela, valorParcela, valorPago, situacaoParcela, valorPagoFimDeSemana
        }
      }
    }`;

    return this.httpClient
      .post(`${this.urlGraphQL}`, { query }, this.httpOptions())
      .pipe(catchError(this.handleError));
  }


  listCobrancasEmPendenciaFuncionarioDividaFuncionarioGraphQL(size: number, page: number, caixaId: number): Observable<any> {
    const query = `{
      listarCobrancasEmPendenciaDeFuncionarioDividaFuncionario(size: ${size}, page: ${page}, caixaId: ${caixaId}) {
        id, dataHoraCadastro, valorRealizado, situacao
        parcela {
          id, valor, valorPago, situacaoParcela, ordem
          contrato { id uid 
            visita { 
              id
              cliente {
                id
                apelidoCliente
                pessoa {
                  id
                  nomePessoa
                  pessoaFisica {
                    id
                    cpf
                    nome
                  }
                  pessoaJuridica {
                    id
                    cnpj
                    razaoSocial
                    nomeFantasia
                  }
                }
              }      
            }
          } 
        }
      }
    }`;

    return this.httpClient
        .post(`${this.urlGraphQL}`, { query }, this.httpOptions())
        .pipe(catchError(this.handleError));
  }


  listarCobrancasEmRegularizacaoDePendenciasGraphQL(size: number, page: number, caixaId: number): Observable<any> {
    const query = `{
      listarCobrancasEmRegularizacaoDePendencias(size: ${size}, page: ${page}, caixaId: ${caixaId}) {
        id, dataHoraCadastro, valorRealizado, situacao
        parcela {
          id, valor, valorPago, situacaoParcela
          contrato { 
            id
            uid 
            visita { 
              id
              cliente {
                id
                apelidoCliente
                pessoa {
                  id
                  nomePessoa,
                  listTelefone { numero }
                  listEndereco { bairro { descricao } } 
                  pessoaFisica {
                    id
                    cpf
                    nome
                  }
                  pessoaJuridica {
                    id
                    cnpj
                    razaoSocial
                    nomeFantasia
                  }
                }
              }
            } 
          } 
        }
      }
    }`;

    return this.httpClient
        .post(`${this.urlGraphQL}`, { query }, this.httpOptions())
        .pipe(catchError(this.handleError));
  }

  listCobrancasEmPendenciaCobrancaDividaFuncionarioGraphQL(size: number, page: number, caixaId: number): Observable<any> {
    const query = `{
      listarCobrancasEmPendenciaDeCobrancaDividaFuncionario(size: ${size}, page: ${page}, caixaId: ${caixaId}) {
         id, dataHoraCadastro, valorRealizado, situacao
        parcela {
          id, 
          valor,
          valorPago, 
          situacaoParcela, 
          ordem
          contrato { 
            id 
            uid 
            visita { 
              id
              cliente {
                id
                apelidoCliente
                pessoa {
                  id
                  nomePessoa
                  pessoaFisica {
                    id
                    cpf
                    nome
                  }
                  pessoaJuridica {
                    id
                    cnpj
                    razaoSocial
                    nomeFantasia
                  }
                }
              }      
            }
          } 
        }
      }
    }`;

    return this.httpClient
        .post(`${this.urlGraphQL}`, { query }, this.httpOptions())
        .pipe(catchError(this.handleError));
  }

  listarCobrancasEmPendenciaGraphQL(size: number, page: number, caixaId: number, apelidoCliente?: string): Observable<any> {
    const query = `{
      listarCobrancasEmPendencias(size: ${size}, page: ${page}, caixaId: ${caixaId}${apelidoCliente ? `, apelidoCliente: "${apelidoCliente}"` : ''}) {
        totalElements,
        totalPages,
        content { id, nomeCliente, idParcela, ordemParcela, valorParcela, valorPago, situacaoParcela, dataCriacao, situacao }
      }
    }`;

    return this.httpClient
        .post(`${this.urlGraphQL}`, { query }, this.httpOptions())
        .pipe(catchError(this.handleError));
  }

  
  cancelarRepasse(cancelarRepasseTO: any): Observable<any> {

    const cancelarRepasseBody = JSON.stringify(cancelarRepasseTO);

    return this.httpClient.post(`${this.trasnferencia}/cancelar-repasse`, cancelarRepasseBody, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelarDespesa(id: number): Observable<any> {
    return this.httpClient.post(`${this.trasnferencia}/cancelar-lancamento-cobranca-despesa`, id, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelarDespesaFixa(id: number): Observable<any> {
    return this.httpClient.post(`${this.trasnferencia}/cancelar-lancamento-funcionario-despesa`, id, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  cancelarDespesaLancadaNoDiaAnterior(transferenciaId: number): Observable<any> {
    return this.httpClient.put(`${this.trasnferencia}/cancelar-pendencia-de-despesa-dia-anterior/${transferenciaId}`, null, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  findAllByCaixa(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/findAllByCaixa/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmRecebimento(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmarrecebimento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmRepasse(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmarrepassecliente`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  analisarEfetivacaoRepasse(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/analisar-efetivacao-repasse`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarTipoRepasse(transferencia: any): Observable<any> {
    const body = JSON.stringify(transferencia);
    return this.httpClient.put(`${this.trasnferencia}/alterar-tipo-repasse-liberado`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  analisarEfetivacaoRepasseDinheiro(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/analisar-efetivacao-repasse-dinheiro`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmCobrancaCliente(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmarcobrancacliente`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmCobrancaClientePendencia(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmarcobrancaclientePendencia`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findAllByCaixaDestino(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/findallbycaixalikedestino/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  confirmarTransferenciasByCaixaFuncionario(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmartransferenciasbycaixafuncionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmacaoParcial(trasnferencia: any): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmacaoparcial`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findAllByCaixaOrigem(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/findallbycaixalikeorigem/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageByCaixaDestinoAndOrigem(specification: string, size: number, page: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}?${specification}&size=${size}&page=${page}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageByCaixaDestinoAndOrigemPorTipoTransf(specification: string, type, size: number, page: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}?${specification}&type=${type}&size=${size}&page=${page}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarvalorrealizado(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/alterarvalorrealizado`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmarTransferenciaByCaixaFuncionario(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/confirmarTransferenciaByCaixaFuncionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarSituacaoTransferencia(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.put(`${this.trasnferencia}/atualizarsituacaotransferencia`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  printDocumentoCaixa(documentoCaixa): Observable<any> {
    const body = JSON.stringify(documentoCaixa);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.post(`${this.trasnferencia}/imprimirDocumentoCaixa`, body, {
      headers: httpHeaders,
      responseType: 'blob'
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardSaidas(caixaId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardsaidas/${caixaId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardSEntradas(caixaId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardentradas/${caixaId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardPendencias(cdfId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardpendencias/${cdfId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardFaltas(funcionarioId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardfaltas/${funcionarioId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardPendenciasConfirmacao(cdfId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardsaidas-confirmacao/${cdfId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardPendenciaConfirmacaoRepasse(cdfId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardsaidas-confirmacao-repasse/${cdfId}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  dashboardPendenciaConfirmacaoSangria(cdfId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardsaidas-confirmacao-sangria/${cdfId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardPendenciaConfirmacaoComplemento(cdfId: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/dashboardsaidas-confirmacao-complemento/${cdfId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  
  lancamentoDespesa(despesaTo: any): Observable<any> {
    const body = JSON.stringify(despesaTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-despesa`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoDespesaGeralTipoRoubo(despesaTo: any): Observable<any> {
    const body = JSON.stringify(despesaTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-despesa-geral-tipo-roubo`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoDespesaDividaFuncionario(despesaTo: any): Observable<any> {
    const body = JSON.stringify(despesaTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-despesa-divida-funcionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoDespesaAdministrativa(despesaTo: any): Observable<any> {
    const body = JSON.stringify(despesaTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-despesa-administrativa`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmarDespesaAdministrativa(transferenciaId: any): Observable<any> {
   
    return this.httpClient.put(`${this.trasnferencia}/confirmar-despesa-administrativa/${transferenciaId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoSobraEmLancamentoDeEntrada(sobraTO: any): Observable<any> {
    const body = JSON.stringify(sobraTO);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-sobra`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoFaltaEmLancamentoDeEntrada(faltaTO: any): Observable<any> {
    const body = JSON.stringify(faltaTO);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-falta`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmarLancamentoSobra(idTransferenciaSaldoFuncionario: number): Observable<any> {
    const body = JSON.stringify(idTransferenciaSaldoFuncionario);
    return this.httpClient.post(`${this.trasnferencia}/confirmar-lancamento-sobra`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  quitarFalta(idTransferenciaParcelaDespesaFuncionario: number): Observable<any> {
    const body = JSON.stringify(idTransferenciaParcelaDespesaFuncionario);
    return this.httpClient.post(`${this.trasnferencia}/quitar-falta`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
}

  lancamentoComplemento(complementoTo: any): Observable<any> {
    const body = JSON.stringify(complementoTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-complemento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoComplementoPendenciaParaSangria(complementoTo: any): Observable<any> {
    const body = JSON.stringify(complementoTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-complemento-pendencia-sangria`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoComplementoSaida(complementoTo: any): Observable<any> {
    const body = JSON.stringify(complementoTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-complemento-saida`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  lancamentoComplementoGeral(complementoTo: any): Observable<any> {
    const body = JSON.stringify(complementoTo);
    return this.httpClient.post(`${this.trasnferencia}/lancamento-complemento-geral`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  transferirComplemento(idTransferenciaCobrancaFuncionarioComplemento: any): Observable<any> {
    const body = JSON.stringify(idTransferenciaCobrancaFuncionarioComplemento);
    return this.httpClient.post(`${this.trasnferencia}/transferir-complemento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  transferirComplementoFuncionario(idTransferenciaRepassFuncionarioComplemento: any): Observable<any> {
    const body = JSON.stringify(idTransferenciaRepassFuncionarioComplemento);
    return this.httpClient.post(`${this.trasnferencia}/transferir-complemento-funcionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  receberComplemento(idTransferenciaFuncionarioFuncionarioComplemento: any): Observable<any> {
    const body = JSON.stringify(idTransferenciaFuncionarioFuncionarioComplemento);
    return this.httpClient.post(`${this.trasnferencia}/receber-complemento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  receberComplementoDeSangriaDePendencia(idTransferenciaDividaFuncionarioFuncionarioSangria: number): Observable<any> {
    const body = JSON.stringify(idTransferenciaDividaFuncionarioFuncionarioSangria);
    return this.httpClient.post(`${this.trasnferencia}/receber-complemento-pendencia-sangria`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  prestarContaDeSangriaNaPendencia(idFuncionario: number): Observable<any> {
    const body = JSON.stringify(idFuncionario);
    return this.httpClient.post(`${this.trasnferencia}/prestar-conta-sangria-pendencia`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  imprimirCobrancasFuncionario(value): Observable<any> {
    const body = JSON.stringify(value);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'Authorization': ''
    });
    return this.httpClient.post(`${this.relatorioCobrancasFuncionario}`, body,
      {
        headers: httpHeaders,
        responseType: 'blob'
      }).pipe(
        catchError(this.handleError)
      );

  }

  listarValoresCobrancas(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/listar-valores-cobrancas/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmarLancamentosSaidas(caixaId: number): Observable<any> {
    return this.httpClient.put(`${this.trasnferencia}/confirmarlancamentossaidas`, caixaId, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmarPendenciasNaPrestacaoDeContas(caixaId: number): Observable<any> {
    const body = JSON.stringify(caixaId);
    return this.httpClient.put(`${this.trasnferencia}/confirmar-pendencias-na-prestacao-de-contas`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  regularizarpendencias(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);

    return this.httpClient.put(`${this.trasnferencia}/regularizarpendencias`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  listPendenciaRepasse(idCaixaDividaFuncionarioID): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/listpendenciarepasse/${idCaixaDividaFuncionarioID}`, this.httpOptions());
  }

  confirmarRepassesNaoConfirmados(transferencias: any): Observable<any> {
    const body = JSON.stringify(transferencias);
    return this.httpClient.post(`${this.trasnferencia}/confirmarRepasses`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  alterarSituacaoRepasseEntrada(body): Observable<any> {
    return this.httpClient.put(`${this.trasnferencia}/alterarSituacaoRepasseEntrada`, body,  this.httpOptions()).pipe(
        catchError(this.handleError)
    );
  }


  gerarCobrancasParaFuncionario(trasnferencia): Observable<any> {
    const body = JSON.stringify(trasnferencia);
    return this.httpClient.post(`${this.trasnferencia}/gerarcobrancasparafuncionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarTransferenciaCobrancaFuncionarioDataAtual(id: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.trasnferencia}/buscar-transferencia-cobranca-funcionario-data-atual/${id}?SituacaoTransferenciaEnum=CONFIRMADA&size=${size}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelarComplemento(id: number): Observable<any> {
    return this.httpClient.get(`${this.trasnferencia}/cancelarcomplemento/${id}`, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );
  }

  cancelarSobraLancadaEmLancamentosDeEntrada(id: number): Observable<any> {
    return this.httpClient.put(`${this.trasnferencia}/cancelar-sobra-lancada-em-lancamentos-de-entrada/${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  lancarVale(despesa): Observable<any>{
    return this.httpClient.post(`${this.trasnferencia}/lancamentovale`, despesa, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );
  }

  relatorioFaltasSobras(datas: any, postoId: any, rota: any, usuario: any): Observable<any>  {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.trasnferencia}/relatorio-faltas-sobras?datas=${datas}&rota=${rota}&posto=${postoId}&usuario=${usuario}`  , { responseType: 'blob' });

  }

  baixarComprovanteDespesasDiarias(): Observable<any>  {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.urlArquivoDespesa}/comprovantes-despesas-individuais-e-administrativas/` + this.idPosto  , { responseType: 'blob' });
  }

  imprimirRelatorioDespesas(postoId: number, rota: number, datas: any, usuario: number): Observable<any>  {
      return this.httpClient.get(`${this.trasnferencia}/imprimir-relatorio-despesa?datas=${datas}&rota=${rota}&posto=${postoId}&usuario=${usuario}`, { responseType: 'blob' });

  }

  baixarComprovante(transferenciaId: number): Observable<any>  {
    return this.httpClient.get(`${this.trasnferencia}/baixar-comprovante?transferenciaId=${transferenciaId}`, { responseType: 'blob' });
  }


  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
      this.idPosto = Number.isInteger(user.idPosto) ? user.idPosto : 0;
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }


  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


}
