import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MotivoDesistenciaContrato } from 'app/main/shared/model/motivo-desistencia-contrato/motivo-desistencia-contrato.model';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MotivoDesistenciaContratoService {
    private urlServico = `${SGF_API}sgf/api/public/motivo-desistencia-contrato`;

    constructor(private httpClient: HttpClient) { }

    findAll(): Observable<any> {
        return this.httpClient.get<MotivoDesistenciaContrato[]>(this.urlServico, this.httpOptions()).pipe(catchError(this.handleError));
    }

    httpOptions(token?: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': ''
            })
        };
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a customer-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

}