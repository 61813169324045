import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-cliente-full-back-reference',
  templateUrl: './cliente-full-back-reference.component.html',
  styleUrls: ['./cliente-full-back-reference.component.scss'],
  animations:fuseAnimations
})
export class ClienteFullBackReferenceComponent implements OnInit {
  
  telephones: FormArray;
  @Input() cliente: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() { }

  get phoneForms(): FormArray {
    return this.cliente.get('listReferencia').get('referenciaBancaria').get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });
    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  onAdd(){}

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`; 
  }
}
