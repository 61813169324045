export class Segment {
    id: number;
    ativo: boolean;
    descricao: string;

    constructor(obj?: any) {
        this.id = obj ? obj.id : null;
        this.ativo = obj ? obj.ativo : true;
        this.descricao = obj ? obj.descricao : '';
    }
}
