import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Holiday } from '../../../../shared/model/holiday/holiday.model';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  
  host = `${SGF_API}` + 'sgf/api/public/feriado';
  posto: string;

  constructor(private httpClient: HttpClient) { }

  findAll(): any {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=10000&${this.posto}`)
    .pipe(
      catchError(this.handleError)
    );
    
  }

  page(size: number, page: number, postoId?: any): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`);
    
  }


  add(holiday: Holiday): Observable<any> {
    const body = JSON.stringify(holiday);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateHoliday(holiday: Holiday): Observable<any> {
    const body = JSON.stringify(holiday);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
}

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }



}
