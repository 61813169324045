import { Component, Inject, ViewEncapsulation, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { UserService } from '../../../global/components/users/services/users/users.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Employee } from '../../model/employee/employee.model';
import { takeUntil, take } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';

@Component({
  selector: 'app-selected-dialog',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.scss'],
  animations: [fuseAnimations]
})
export class SelectedDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  disabledButton:boolean;
  employee: FormGroup;
  funcionario: Array<Employee> = [];
  public responsavelFilterCtrl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);


  /**
   * Constructor
   *
   * @param {MatDialogRef<SelectedDialogComponent>} matDialogRef
   * @param _data
   */
  constructor(
    public matDialogRef: MatDialogRef<SelectedDialogComponent>,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {

    this.employee = this.fb.group({
      funcionario: null
    })
  }

  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();


  ngOnInit() { 
    this.employeeService.findAll().subscribe(data => {

      data.object.content.forEach(e => {
        if(e.cargo.descricao == 'Vendedor') {
          if(e.posto != null && e.posto.id == this._data[0].posto.id){
            this.funcionario.push(e);
          }
        }
      });

      this.filteredEmployee.next(this.funcionario.slice());
      this.responsavelFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEmployee();
        });
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setInitialValue() {
    this.filteredEmployee
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Employee, b: Employee) => a.id === b.id;
      });
  }

  private filterEmployee() {
    if (!this.funcionario) {
      return;
    }

    let search = this.responsavelFilterCtrl.value;
    if (!search) {
      this.filteredEmployee.next(this.funcionario.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.funcionario.filter(f => f.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );

  }

  closed(): void {
    this.matDialogRef.close();
  }

  closeSave(): void {
    this.disabledButton = true;
    this.matDialogRef.close(this.employee.value);
  }

  

}
