import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DespesasService } from '../../../financial/components/despesas/services/despesas.services';
import { TipoDespesa } from '../../model/enums/tipo-despesa.enum';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { CategoriaPlanoContasService } from 'app/main/financial/services/categoria-plano-contas/categoria-plano-contas.service';
import { CategoriaPlanoConta } from '../../model/categoria-plano-conta/categoria-plano-conta.model';
import { SGF_API } from 'app/shared/api/sgf.api';
import { IImage, ImageCompressService } from 'ng2-image-compress';

export namespace TiposDespesa {
  export function values(): string[] {
    return Object.keys(TipoDespesa).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export class Despesa {
  funcionarioId: number;
  postoId: number;
  tipoDespesa: string;
  categoria: string;
  categoriaPlanoConta: CategoriaPlanoConta;
  valor: number;
  nomeFuncionario: string;
}

@Component({
  selector: 'app-despesas-form',
  templateUrl: './despesas-form.component.html',
  styleUrls: ['./despesas-form.component.scss']
})
export class DespesasFormComponent implements OnInit, AfterViewInit {
  private static PLANO_SALARIO_A_PAGAR = '2.1.1.010';

  lancamento: Despesa = new Despesa();
  selectFuncionarioDestino = false;
  funcionarios: Employee[];
  caixa: any;
  dialogMessages;
  tipos = TiposDespesa;
  disabledButton: boolean;
  type: string;
  categoriaPlanoDeContas: CategoriaPlanoConta;
  filteredCategorias: CategoriaPlanoConta[] = [];
  categoriasPlanoConta: CategoriaPlanoConta[] = [];
  categoriaInput = '';

  disabledSelect = false;
  loading = false;
  hasImg = false;

  @ViewChild('valor') valor: ElementRef;

  despesas = [];

  categorias = [
    'ALIMENTACAO',
    'COMBUSTIVEL',
    'BORRACHARIA',
    'IMPRESSAO_PAPELARIA',
    'VALE',
    'OFICINA',
    'OUTRO',
  ];

  imageData: any;
  images: Array<IImage> = [];
  inputImage = '';

  url = '';

  constructor(private formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private matDialogRef: MatDialogRef<DespesasFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private despesaService: DespesasService,
    private transferenciaService: TransferenciaService,
    private utilsService: UtilsService,
    private categoriaPlanoContaService: CategoriaPlanoContasService) { 

      if ( data.type === 'ADMINISTRATIVA') {
        this.despesas = [];
        this.lancamento.tipoDespesa = data.type;
        this.despesas.push('ADMINISTRATIVA');
        this.lancamento.nomeFuncionario = '';
      } else {
        this.despesas = [];
        this.despesas.push('EMPRESA',
        'FUNCIONARIO');
      }

      if (data.categoria === 'ROUBO') {
        this.despesas = [];
        this.despesas.push('FUNCIONARIO');
        this.lancamento.tipoDespesa = 'FUNCIONARIO';
        this.categorias = [];
        this.categorias.push('ROUBO');
        this.lancamento.categoria = data.categoria;
        this.getCategoriaPlanoContas(data.categoria);
      }

      this.categoriaPlanoContaService.findAll(1000).subscribe((response: any) => {
        const categorias = response.object.content
          .filter(categoria => categoria.codigo !== DespesasFormComponent.PLANO_SALARIO_A_PAGAR);

        this.categoriasPlanoConta = categorias;
        this.filteredCategorias = this.categoriasPlanoConta;
      });

      this.lancamento.funcionarioId = this.data.funcionarioId;
      this.lancamento.postoId = this.data.postoId;

    }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  onSelectFile(event): void {
    const int = event.target.files.length;
    if (event.target.files) {
      ImageCompressService.filesToCompressedImageSource(event.target.files).then(observableImages => {
        observableImages.subscribe((image) => {
          this.images.push(image);
          const imagem = image.compressedImage.imageDataUrl;
          this.url = imagem;
          this.imageData = {
            'contentsBase64': imagem,
            'idTransferencia': null,
            'nome': image.fileName,
            'tipoDespesa': this.lancamento.tipoDespesa,
          };
          this.hasImg = true;
        });
      });
    }
  }

  public save(): void {
    // tslint:disable-next-line:triple-equals
    if (this.data.type == 2) {
        this.lancamentoDespesaDividaFuncionario(this.lancamento);
    } else if (this.data.type === 'ADMINISTRATIVA' ) {
      this.lancarDespesaAdministrativa(this.lancamento);
    } else if (this.data.origem === 'CAIXAGERAL' ) {
      this.lancamentoDespesaGeralTipoRoubo(this.lancamento);
    } else {
        this.lancamentoDespesaCobranca(this.lancamento);
    }
  }


  public lancamentoDespesaCobranca(lancamento: any): void {
    this.transferenciaService.lancamentoDespesa(lancamento).subscribe(
      (response) => {
       
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

        if (isSuccess) {
          this.matDialogRef.close();
        } else {
          this.disabledButton = false;
        }

      }
    );

    this.disabledButton = true;
  }

  public lancamentoDespesaGeralTipoRoubo(lancamento: any): void {
    
    this.transferenciaService.lancamentoDespesaGeralTipoRoubo(lancamento).subscribe(
      (response) => {
       
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

        if (isSuccess) {
          this.matDialogRef.close();
        } else {
          this.disabledButton = false;
        }

      }
    );

    this.disabledButton = true;
  }


  public lancamentoDespesaDividaFuncionario(lancamento: any): void {
    this.transferenciaService.lancamentoDespesaDividaFuncionario(lancamento).subscribe(
      (response) => {
      
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

        if (isSuccess) {
          this.matDialogRef.close();
        } else {
          this.disabledButton = false;
        }

      }
    );

    this.disabledButton = true;
  }

  public lancarDespesaAdministrativa(lancamento: any): void {

    this.transferenciaService.lancamentoDespesaAdministrativa(lancamento).subscribe((response) => {
      const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      if (isSuccess) {
        if (this.hasImg) {
          this.imageData.idTransferencia = response.object.id;
          this.imageData.contentsBase64 = this.imageData.contentsBase64.replace('jpeg', 'jpg').slice(22);
          this.uploadImageData(this.imageData);
        }
        this.matDialogRef.close();
      } else {
        this.disabledButton = false;
      }
    });
  }

  public getCategoriaPlanoContas(categoria: string): void {
    if (categoria !== 'OUTRO') {
      this.categoriaPlanoContaService.findByCategoriaDespesa(categoria).subscribe((response: any) => {
        this.lancamento.categoriaPlanoConta = response.object;
        this.categoriaInput = this.lancamento.categoriaPlanoConta.descricao;
        this.disabledSelect = true;
      });
    } else {
      this.lancamento.categoriaPlanoConta = null;
      this.disabledSelect = false;
      this.categoriaInput = '';
    }
    
  }

  onSelected(descricaoCat: string): void {
    const categoriaPlanoSelecionado = this.categoriasPlanoConta.find((categoria: CategoriaPlanoConta) => categoria.descricao === descricaoCat);
    this.lancamento.categoriaPlanoConta = categoriaPlanoSelecionado;
  }

  doFilter(value: string): void {    
    if (value === 'categoriaPlano') {
      this.filteredCategorias = this._filtrar(this.categoriaInput, this.categoriasPlanoConta, 'descricao');
    }
  }

  private _filtrar<T>(value: string, elements: T[], key: string): T[] {
    const filterValue = value.toLowerCase();
    return elements.filter((e: T) => e[key].toLowerCase().indexOf(filterValue) === 0);
  }

  private uploadImageData(imageDataParam: any): void {

    this.transferenciaService.saveArquivo64(imageDataParam).subscribe(response => {
      if (response.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Informação',
          text: 'Visita Finalizada com sucesso'
        };
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(response);
        this.disabledButton = false;
        this.loading = false;
      } else {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação',
          text: response.messages[0].text
        };
        this.feedback(this.dialogMessages);
        this.disabledButton = false;
        this.loading = false;
      }
    }, (onerror) => {
      console.log('UPDATE ARQUIVO ERROR', onerror);
    });
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
  
}
