import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { VisitasService } from '../../visitas/services/visitas.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveClienteInativoService implements Resolve<any> {

  situacao = 'EM_ANALISE_REATIVACAO';
  
  constructor(private visitasService: VisitasService) { }

  resolve(): Observable<any> {
    return this.visitasService.page(this.situacao, 10, 1).pipe(
      take(1),
      mergeMap(visita => {
      return of(visita['object']);
      })
    );
  }

}
