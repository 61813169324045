import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Segment } from 'app/main/shared/model/segment/segment.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private host = `${SGF_API}` + 'sgf/api/public/atividadecomercial';
  private disableSegment = `${SGF_API}` + 'sgf/api/public/atividadecomercial/desativar';

  constructor(
    private httpClient: HttpClient,
  ) { 
  }

  findAll(): Observable<any> {
    return this.httpClient.get(`${this.host}?size=200`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  add(segment: Segment): Observable<any> {
    const body = JSON.stringify(segment);
    if(!segment.ativo){
        console.log('ÁREA DE ATUAÇÃO DESATIVADA', segment);
        this.desativarSegment(segment.id);
      }
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSegment(segment: Segment): Observable<any> {
    const body = JSON.stringify(segment);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  desativarSegment(id: number){
    const body = JSON.stringify(id);
    return this.httpClient.put(this.disableSegment, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
