import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VisitasService} from '../../../customers/components/visitas/services/visitas.service';
import {ProdutoService} from 'app/main/global/components/produto/services/produto-service.service';
import {SGF_TYPE} from 'app/shared/api/sgf.api';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-visitas-full',
  templateUrl: './visitas-full.component.html',
  styleUrls: ['./visitas-full.component.scss']
})
export class VisitasFullComponent implements OnInit {

  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;
  fortCartao = SGF_TYPE === 'FORTALEZA-CARTAO';
  dev = SGF_TYPE === 'DEV';

  saveActionButton = 'Cadastrar';
  editActionButton = 'Atualizar';
  disabledButton: boolean;
  dialogMessages;
  imagesOrigemErro = false;
  situacaoVisitaAnterior: any;
  public loading = false;

  visit: FormGroup;
  parecer: FormGroup;
  qtdArray: any[] = [0, 0, 0, 0, 0, 0];
  galeria = '/customer/visitados/galeria/' + this._data.visit.id;

  imageData: any[] = [];
  countUpload = 1;
  countEdit = 0;
  dialogRef: any;
  produtoList: any[] = [];
  alugado = false;

  @Output() imagesDataEvent = new EventEmitter<any[]>();
  

  public validacaoVisita = false;
    novaVisita = false;


  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<VisitasFullComponent>,
    private visitaService: VisitasService,
    private matDialog: MatDialog,
    private produtoService: ProdutoService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    this.produtoService.findAll().subscribe(data => {
      this.produtoList = data.object.content;
    });
  }

  ngOnInit(): void {
    const visita = this._data.visit;

    let limiteCreditoSugerido = null;
    let valorSugerido = null;

    if (this._data.visitados) {
      limiteCreditoSugerido = this.includeDot(visita.limiteCreditoSugerido.toString());
      valorSugerido = this.includeDot(visita.valorSugerido.toString());
    }

    // tslint:disable-next-line:triple-equals
    if (visita.modalidadeEstabelecimento && visita.modalidadeEstabelecimento == 'ALUGADO') {
      this.alugado = true;
    }

    if (this._data.reagendado) {
      this.parecer = this.formBuilder.group({
        parecerReagendamento: [visita.parecerVisita]
      });
    }

    this.visit = this.formBuilder.group({
      limiteCreditoSugerido: [this._data.visit ? this._data.visit.limiteCreditoSugerido : null,  [ Validators.required, Validators.min(0) ]],
      valorSugerido: [this._data.visit ? this._data.visit.valorSugerido : null, [ Validators.required, Validators.min(0) ]],
      parecerVisita: [this._data.visit ? this._data.visit.parecerVisita : null, Validators.required],
      // PAGAMENTO
      pagamentoBoleto: [visita.pagamentoBoleto ? visita.pagamentoBoleto : false, Validators.required],
      pagamentoCartao: [visita.pagamentoCartao ? visita.pagamentoCartao : false, Validators.required],
      pagamentoDinheiro: [visita.pagamentoDinheiro ? visita.pagamentoDinheiro : false, Validators.required],
      pagamentoPix: [visita.pagamentoPix ? visita.pagamentoPix : false, Validators.required],
      // QUANTIDADE
      quantidadeParcelas: [this.validarQuantidadeParcela(visita), Validators.required],
      tipoContrato: [visita.tipoContrato ? visita.tipoContrato : (this.fortEletro === true ? 'ELETRO' : null), Validators.required],
      dataVencimento: [visita.dataVencimento ? new Date(visita.dataVencimento) : null],
      produto: [visita.produto ? visita.produto : null],
      isEletro: [visita.tipoContrato === 'ELETRO' ? true : false],
      taxaJuros: [visita.taxaJuros ? visita.taxaJuros : null],
      diasAntecipacao: [visita.diasAntecipacao ? visita.diasAntecipacao : null],
      antecipacao: [visita.antecipacao ? 'true' : 'false'],  
      // PERIODICIDADE
      periodicidadeParcela: [visita.periodicidadeParcela ? visita.periodicidadeParcela : 'DIARIA', Validators.required],
      taxaJurosMora: [visita.taxaJurosMora ? visita.taxaJurosMora : null],
      modalidadeEstabelecimento: [visita.modalidadeEstabelecimento ? visita.modalidadeEstabelecimento : null, Validators.required],
      dataInicioAtividade: [visita.dataInicioAtividade ? new Date(visita.dataInicioAtividade) : null, Validators.required],
      rota: [visita.rota ? visita.rota : null]
    });
  }

  private eventImageData(imagesData: any[]): void {
    this.imagesDataEvent.emit(imagesData);
  }

  private validarQuantidadeParcela(visita: any) {
    if (visita.quantidadeParcelas && visita.contrato2Parcelas) {
      return 2;
    }else if (visita.quantidadeParcelas) {
      return visita.quantidadeParcelas;
    } else {
      return null
    }
  }


  private uploadImageData(imageDataParam: any[] = []): void {

    this.visitaService.listaDeArquivo64(imageDataParam).subscribe(response => {
      if (response.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Informação',
          text: 'Visita Finalizada com sucesso'
        };
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(response);
        this.disabledButton = false;
        this.loading = false;
      } else {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação',
          text: response.messages[0].text
        };
        this.feedback(this.dialogMessages);
        this.disabledButton = false;
        this.loading = false;
      }
    }, (onerror) => {
      console.log('UPDATE ARQUIVO ERROR', onerror);
    });
  }

  private updateVisita(visita): void {

    visita.situacao = 'VISITADA';
    
    this.visitaService.update(visita).subscribe(visitaResponse => {

      if (visitaResponse.success) {
        this.uploadImageData(this.imageData);
      } else {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação',
          text: visitaResponse.messages[0].text
        };
        visita.situacao = 'AGENDADA';
        this.feedback(this.dialogMessages);
        this.imagesOrigemErro = true;
        this.disabledButton = false;
        this.loading = false;
      }
    });
  }

  finalizarVisita(visit: any): void {
    this.loading = true;
    this.disabledButton = true;
    
    this.situacaoVisitaAnterior = this._data.visit.situacao;

    if (visit.produto) {
      this.produtoService.findById(visit.produto).subscribe(data => {
        visit.produto = data.object;
      });
    }

    visit = Object.assign(this._data.visit, visit);

    const quantidadeRef = this.visit.get('quantidadeParcelas');
    const periodicidadeRef = this.visit.get('periodicidadeParcela');

    visit.quantidadeParcelas = quantidadeRef.value ? quantidadeRef.value : visit.quantidadeParcelas;
    visit.periodicidadeParcela = periodicidadeRef.value ? periodicidadeRef.value : visit.periodicidadeParcela;
      
    // tslint:disable-next-line:triple-equals
    if (this.imagesOrigemErro == false) {

        this.imageData.forEach(image => {
          // tslint:disable-next-line:triple-equals
          if (image.contentsBase64 != null || image.contentsBase64 != undefined ) {
            image.contentsBase64 = image.contentsBase64.replace('jpeg', 'jpg', 'png').slice(22);
          }
        });
    }
    this.updateVisita(visit);
  }

  editVisitado(value): void {
    this.disabledButton = true;
    this.loading = true;

    const finalizarErrorFeedback = (message) => {
      this.dialogRef.close();
      this.feedback(message);
      this.disabledButton = false;
    };

    this.imageData.forEach(image => {
      // tslint:disable-next-line:triple-equals
      if (image.contentsBase64 != null || image.contentsBase64 != undefined ) {
        image.contentsBase64 = image.contentsBase64.replace('jpeg', 'jpg', 'png').slice(22);
      }
    });

    const visit = Object.assign(this._data.visit, value);

    if (visit.produto != null) {
      this.produtoService.findById(visit.produto).subscribe(data => {
        visit.produto = data.object;
      });
    }

    this.updateVisita(visit);

  }

  getEvent(event): void {
    this.qtdArray = event;
  }

  getImages(event): void {
    this.imageData = event;
  }

  getContrato(event): void {
    if (event.value === 'ALUGADO') {
      this.alugado = true;
    } else {
      this.alugado = false;
    }
  }


  private feedback(message: string[]): void {
    const dialogRef = this.matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  onCancel(): void {
    if (!this._data.visitados && this._data.edit === false) {
      this._data.visit.situacao = 'AGENDADA';
      this.visitaService.update(this._data.visit).subscribe(data => {
      });
    }
    this.dialog.closeAll();
  }

  private removeDot(num: string): string {
    const tam = num.indexOf('.');
    let centavos = '';

    if (tam > 0) {
      centavos = num.substring(tam + 1);
      num = `${num.substring(0, tam + 1)}`;
    } else {
      centavos = '00';
      num = num.concat(centavos);
    }

    if (centavos.length === 1) {
      centavos = centavos.concat('0');
    }

    return num;
  }

  private includeDot(num: string): string {
    const length = num.length;
    num = `${num.substring(0, length - 2)}.${num.substring(length - 2)}`;
    return num;
  }
}
