import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { TeamService } from '../services/team.service';


@Injectable({
  providedIn: 'root'
})
export class ResolveTeamService implements Resolve<any> {

  constructor(
    private teamService: TeamService) { }

  resolve(): Observable<any> {
    return this.teamService.page(10,1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }

}
