import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { VehiclesService } from '../services/vehicles.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveVehiclesService implements Resolve<any> {
  
  constructor(
    private vehiclesService: VehiclesService) { }

  resolve(): Observable<any> {
     return this.vehiclesService.pageAll(10, 1).pipe(
       take(1),
       mergeMap(vehicles => {
        return of(vehicles['object']);
       })
     );
  }

}
