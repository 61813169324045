import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-cliente-full-fiador',
  templateUrl: './cliente-full-fiador.component.html',
  styleUrls: ['./cliente-full-fiador.component.scss'],
  animations: fuseAnimations
})
export class ClienteFullFiadorComponent implements OnInit {

  @Input() cliente: FormGroup;
  maskCpf = new Mask('000.000.000-00');

  constructor() { }

  ngOnInit(): void {  }

  get addressForms(): FormArray {
    return this.cliente.get('listReferencia').get('referenciaFiador').get('listEndereco') as FormArray;
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

}
