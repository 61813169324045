import { Component, OnInit } from '@angular/core';
import { VisitasService } from '../../../../customers/components/visitas/services/visitas.service';
import * as FileSaver from 'file-saver';
import { MatDialogRef } from '@angular/material';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

@Component({
  selector: 'app-relatorio-atividade-administrativa-main',
  templateUrl: './relatorio-acompanhamento-visita-main.component.html',
  styleUrls: ['./relatorio-acompanhamento-visita-main.component.scss'],
})
export class RelatorioAcompanhamentoVisitaMainComponent implements OnInit {
  public usuario = JSON.parse(sessionStorage.getItem('funcionario'));

  postos = [];
  filteredPostos = [];

  rotas = [];
  filteredRotas = [];

  currentPosto = null;
  currentPostoSearch = '';

  currentRota = null;
  currentRotaSearch = 'Todas as Rotas';

  idPosto: any;

  selecionarTodosOsClientes = true;
  selecionarTodosOsContratos = false;

  filtrarSomenteClientesNovos = true;
  filtrarSomenteClientesReativados = true;
  filtrarSomenteClientesRenovados = true;

  filtrarContratosRepassados = true;
  filtrarSomenteContratosCancelados = false;
  filtrarContratosNaoOperados = false;
  filtrarValorMinimo: any;
  filtrarValorMaximo: any;
  filtrarTipoPessoa: any;

  opcaoSelecionadaTipoCliente: any;

  dataInicial = new Date();
  dataFinal = new Date();

  constructor(
    private visitService: VisitasService,
    private postoService: PostsService,
    private rotaService: RotasService,
    private dialogRef: MatDialogRef<RelatorioAcompanhamentoVisitaMainComponent>,
  ) {
    this.checkIfHasPosto();
  }

  ngOnInit(): any {}

  cancel(): any {
    this.dialogRef.close();
  }

  onSave(): any {
    const acompanhamentoVisita = {
      idPosto: this.idPosto,
      idRota: this.currentRota ? this.currentRota.id : null,
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      filtrarSomenteClientesNovos: this.filtrarSomenteClientesNovos,
      filtrarSomenteClientesReativados: this.filtrarSomenteClientesReativados,
      filtrarSomenteClientesRenovados: this.filtrarSomenteClientesRenovados,
      filtrarSomenteContratosCancelados: this.filtrarSomenteContratosCancelados,
      filtrarContratosRepassados: this.filtrarContratosRepassados,
      filtrarContratosNaoOperados: this.filtrarContratosNaoOperados,
      filtrarTipoPessoa: this.opcaoSelecionadaTipoCliente.id,
      filtrarValorMinimo: this.filtrarValorMinimo,
      filtrarValorMaximo: this.filtrarValorMaximo
    };
      console.log(acompanhamentoVisita);
    this.visitService.imprimirRelatorioAcompanhamentoVisita(acompanhamentoVisita).subscribe((file: Blob) => {
      window.open(URL.createObjectURL(file), '_blank').print();
    });

    this.cancel();
  }

  async checkIfHasPosto(): Promise<void> {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.idPosto = user.idPosto ? user.idPosto : '';
    }

    if (!this.idPosto) {
      this.postos = (await this.postoService.listarPostos().toPromise()).object;
      this.filteredPostos = [...this.postos];
    } else {
      this.rotas = (await this.rotaService.findByIdPosto(parseInt(this.idPosto)).toPromise()).object;
      this.filteredRotas = [...this.rotas];
    }
  }

  onSelectRota(rota): void {
    this.currentRotaSearch = rota.descricao;
    this.currentRota = rota;
  }

  async onSelectPosto(posto): Promise<void> {
    this.currentPostoSearch = posto.descricao;
    this.currentPosto = posto;
    this.rotas = (await this.rotaService.findByIdPosto(parseInt(posto.id)).toPromise()).object;
    this.filteredRotas = [...this.rotas];
    this.onSelectRota({ id: 0, descricao: 'Todas as Rotas' });
  }

  doFilter(field: 'rota' | 'posto'): void {
    if (field === 'rota') {
      this.filteredRotas = this.rotas.filter((rota) => rota.descricao.toLowerCase().includes(this.currentRotaSearch));
    }
    if (field === 'posto') {
      this.filteredRotas = this.postos.filter((posto) => posto.descricao.toLowerCase().includes(this.currentPostoSearch));
    }
  }

  selectAll(field: 'clientes' | 'contratos'): void {
    if (field === 'clientes' && !this.selecionarTodosOsClientes) {
      this.filtrarSomenteClientesNovos = true;
      this.filtrarSomenteClientesReativados = true;
      this.filtrarSomenteClientesRenovados = true;
    }
    if (field === 'contratos' && !this.selecionarTodosOsContratos) {
      this.filtrarContratosRepassados = true;
      this.filtrarSomenteContratosCancelados = true;
      this.filtrarContratosNaoOperados = true;
    }
  }

  selectSome(field: 'clientes' | 'contratos'): void {
    if (
      field === 'clientes' &&
      ![this.filtrarSomenteClientesNovos, this.filtrarSomenteClientesReativados, this.filtrarSomenteClientesRenovados].includes(false)
    ) {
      this.selecionarTodosOsClientes = false;
    }
    if (field === 'contratos' && ![this.filtrarContratosRepassados, this.filtrarSomenteContratosCancelados, this.filtrarContratosNaoOperados].includes(false)) {
      this.selecionarTodosOsContratos = false;
    }
  }

  serviceOnSelectTipoCliente(value: any): any {
  }

  displayFnTipoCliente(opcao: any): string {
    return opcao ? opcao.descricao : 'Selecione...';
  }
}
