import {AfterViewChecked, Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Parcela} from '../../model/parcela/parcela.model';

@Component({
  selector: 'app-parcelas',
  templateUrl: './parcelas.component.html',
  styleUrls: ['./parcelas.component.scss']
})
export class ParcelasComponent implements OnInit {

rows: any[];

  constructor(
      public matDialogRef: MatDialogRef<ParcelasComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): any {
    this.rows = this.data.parcelas;
  }

  generateMask(value: number): any {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }


}
