import { Component, OnInit, Input, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-referencia-pessoal-form',
  templateUrl: './referencia-pessoal-form.component.html',
  styleUrls: ['./referencia-pessoal-form.component.scss'],
  animations: fuseAnimations
})
export class ReferenciaPessoalFormComponent implements OnInit, AfterViewInit {

  @Input() form: FormGroup;
  @Input() phoneRequired: boolean;

  @ViewChild('nome') nome: ElementRef;

  constructor(private formBuild: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }


  setForm(): void {
  }

  get phoneForms(): FormArray {
    return this.form.get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuild.group({
      ativo: new FormControl(null),
      numero: new FormControl(null, Validators.required),
      id: new FormControl(null),
      tipoSmartphone: new FormControl(null),
      possuiInternet: new FormControl(null),
      instalarAppCliente: new FormControl(null)
    });

    this.phoneForms.push(phone);
  }


  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`; 
  }

}
