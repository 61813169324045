import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class FolhaPagamentoService {
 
  private host = `${SGF_API}` + 'sgf/api/public/folhapagamento';
  posto: string;

  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  page(size: number, page: number): Observable<any>  {
   
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`)
    .pipe(
      catchError(this.handleError)
    );

  }


  create(folha: any): Observable<any> {
    const body = JSON.stringify(folha);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(folha): Observable<any> {
    const body = JSON.stringify(folha);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id: number): Observable<any> {
    return this.httpClient.get(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarPagamento(folha): Observable<any>{
    const body = JSON.stringify(folha);
    return this.httpClient.put(`${this.host}/gerarpagamento`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    )
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
}

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
