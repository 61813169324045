import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { BancoService } from 'app/main/financial/components/banco/services/banco-service.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-banco',
  templateUrl: './banco.component.html',
  styleUrls: ['./banco.component.scss']
})
export class BancoComponent implements OnInit {

  banco: FormGroup;
  dialogMessages;
  titleHeader: string;
  disabledButton: boolean;

  constructor(private formBuilder: FormBuilder,
    private bancoService: BancoService,
    private matDialogRef: MatDialogRef<BancoComponent>,
    public dialog: MatDialog,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) private _data: any) {

    // tslint:disable-next-line:triple-equals
    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Banco' : 'Edição de Banco';
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.banco = this.formBuilder.group({
      id: [this._data.banco ? this._data.banco.id : null],
      descricao: [this._data.banco ? this._data.banco.descricao : null, Validators.required],
      codigoBanco: [this._data.banco ? this._data.banco.codigoBanco : null, Validators.required]
    });
  }

  create(banco: any): void {
    this.disabledButton = true;
    this.bancoService.create(banco).subscribe(data => {
      if (data.success) {
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }
      }
    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar banco`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR BANCO', onerror);
      }
    );
  }

  update(banco: any): void {
    this.disabledButton = true;
    this.bancoService.update(banco).subscribe(data => {
      if (data.success) {
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }
      }

    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar banco`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR BANCO', onerror);
      }
    );
  }



  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onClosed(): void {
    this.matDialogRef.close();
  }
}
