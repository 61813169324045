import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ValoresTransferenciaRequestDTO } from 'app/main/shared/model/valor-transferencia-request/valor-transferencia-request.dto';

@Injectable({
  providedIn: 'root'
})
export class AnaliseSobraService {


  url = `${SGF_API}` + 'sgf/api/public/credito-sobra';

  private posto: string;
  private idPosto: number;

  constructor(private httpClient: HttpClient) { }

  save(creditoSobra: any): Observable<any> {
    const body = JSON.stringify(creditoSobra);
    return this.httpClient.post(`${this.url}/cadastrar-credito-sobra`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  reanalisar(creditoSobra: any): Observable<any> {
    const body = JSON.stringify(creditoSobra);
    return this.httpClient.post(`${this.url}/reanalisar-credito-sobra`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findCreditoSobraByTransferenciaId(transferenciaId: any): Observable<any>{
    return this.httpClient.get(`${this.url}/findCreditoSobraByTransferenciaId/${transferenciaId}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  confirmarCreditoSobra(creditoSobraId: any): Observable<any>{
    const body = JSON.stringify(creditoSobraId);
    return this.httpClient.post(`${this.url}/confirmar-credito-sobra`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  rejeitarCreditoSobra(creditoSobraId: any): Observable<any>{
    const body = JSON.stringify(creditoSobraId);
    return this.httpClient.post(`${this.url}/rejeitar-credito-sobra`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  page(size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.url}?size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }


  pageFilterNomeFuncionario(funcionario: string, size: number, page: number): Observable<any> {
  
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.url}?filters=%26nomeFuncionario%3D%25${funcionario}%25&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  
  }

  pageFilterDescricaoRota(rota: string, size: number, page: number): Observable<any> {
  
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.url}?filters=%26descricaoRota%3D%25${rota}%25&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  
  }

  pageFilterNomeCliente(cliente: string, size: number, page: number): Observable<any> {
  
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.url}?filters=%26nomeCliente%3D%25${cliente}%25&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  
  }

  pageFilterByPeriodo(size: number, page: number, dataInicial: number, dataFinal: number): any {
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.url}?size=${size}&page=${page}&${this.posto}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
     this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getValorTotal(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<any> {
    this.checkIfHasPosto();
    valores.postoId = this.idPosto;
    return this.httpClient.post<any>(
      `${this.url}/valor-total`,
      valores,
    );
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }
  
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
