import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-comercial-reference',
  templateUrl: './comercial-reference.component.html',
  styleUrls: ['./comercial-reference.component.scss'],
  animations: [fuseAnimations]
})
export class ComercialReferenceComponent implements OnInit {

  telephones: FormArray;
  @Input() visit: FormGroup;
  @Input() dadosExtrasTelefone: any;

  constructor(private formBuilder: FormBuilder) { }
  
  ngOnInit() { }

  get phoneForms(): FormArray {
    return this.visit.get('listReferencia').get('referenciaComercial').get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null),
      tipoSmartphone: new FormControl(false),
      possuiInternet: new FormControl(false),
      instalarAppCliente: new FormControl(false)
    });
    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

}
