import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ClienteService} from '../../../customers/components/cliente/services/cliente.service';
import {UtilsService} from '../../../../shared/util/utils.service';
import {TimeMensagem} from '../../model/enums/time-mensagem.enum';

@Component({
    selector: 'app-consultar-cpf-receita',
    templateUrl: './consultar-cpf-cnpj-receita.component.html',
    styleUrls: ['./consultar-cpf-cnpj-receita.component.scss']
})
export class ConsultarCpfCnpjReceitaComponent implements OnInit {

    url: string;
    idCliente: number;
    situacao: number;

    constructor(
        public dialogRef: MatDialogRef<ConsultarCpfCnpjReceitaComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private clienteService: ClienteService,
        private utilsService: UtilsService
    ) {

    }

    ngOnInit(): any {
    }

    cancel(): void {
        this.dialogRef.close();
    }

    onSave(): any {
        this.clienteService.alterarSituacaoReceita(this._data.cliente.id, this.situacao).subscribe(data => {
            this.utilsService.feedbackSuccess('Situação alterada com sucesso', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
        });
        this.cancel();
    }

    formaPagamentoSelect(number: number): any {
        if (number == 1) {
            this.situacao = 0;
        } else if (number == 2) {
            this.situacao = 1;
        }
    }
}
