import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DistrictService } from '../services/district-service.service';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveDistrictService {

  constructor(private bairroService : DistrictService) { }
  
  resolve(): Observable<any> {
    return this.bairroService.page(10,1).pipe(
       take(1),
       mergeMap(discount => {
        return of(discount['object']);
       })
     );
  }

  
}
