import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivityFieldService } from '../services/activity-field.service';


@Injectable({
  providedIn: 'root'
})
export class ResolveActivityFieldService implements Resolve<any> {

  constructor(
    private activityService: ActivityFieldService) { }

  resolve(): Observable<any> {
    return this.activityService.page(30,1,true).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }

}
