import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialRoutingModule } from './routes/financial.routing';
import { SharedMaterialComponentsModule } from '../../shared-material-components.module';

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialComponentsModule,
    FinancialRoutingModule,
  ],
  declarations: []
})
export class FinancialModule { }
