import { ModeloService } from './../services/modelo-service.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveModeloService {

  constructor(private modeloService : ModeloService) { }

  resolve(): Observable<any> {
     return this.modeloService.page(10,1,true).pipe(
       take(1),
       mergeMap(modelo => {
        return of(modelo['object']);
       })
     );
  }

}
