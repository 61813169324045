import { ProcessingService } from './../../../services/processing.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-process-releases',
  templateUrl: './process-releases.component.html',
  styleUrls: ['./process-releases.component.scss']
})
export class ProcessReleasesComponent implements OnInit {

  constructor(private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProcessReleasesComponent>,
              private processingService: ProcessingService) { }


  public disableAccept = false;

  ngOnInit(): void {
  }

  public openPopup(): void {
    this.dialogRef = this.dialog.open(ProcessReleasesComponent, {
      data: '',
    });
    setTimeout(() => this.dialogRef.close(), 8000);
  }

  public process(): void {
    this.disableAccept = true;
    const request = this.processingService.processarContratos();
    request.subscribe((data: any) => {
      this.cancel();
      this.disableAccept = false;
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
