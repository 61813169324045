import { Injectable } from '@angular/core';
import { CobrancasService } from '../../cobrancas/services/cobrancas.services';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { ConfirmacaoRecebimentoService } from '../service/confirmacao-recebimento.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveAcompanhamentoRecebimentosService {

  date = new Date();
  dataAtual: string;

  constructor(private confirmacaoRecebimentoService: ConfirmacaoRecebimentoService) {
    const dia = this.date.getDate();
    const mes = this.date.getMonth() + 1;
    const ano = this.date.getFullYear();
    this.dataAtual = 'dataVencimento=eq:' + ano + '-' + (dia <= 9 ? '0' + dia : dia) + '-' + (mes <= 9 ? '0' + mes : mes);
  }

  resolve(): Observable<any> {
    return this.confirmacaoRecebimentoService.page(1, 10).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
