import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { SharedService } from '../../../../shared/shared.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { AlterarSenhaComponent } from 'app/main/shared/components/alterar-senha/alterar-senha.component';
import { SelectPostoFormComponent } from 'app/main/shared/components/select-posto-form/select-posto-form.component';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    dialogRef: any;
    shared: SharedService;
    dialogMessages;
    usuario;
    postoId;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthenticationService,
        private _matDialog: MatDialog,
        private funcionarioService: EmployeeService
    ) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        this.shared = SharedService.getInstance();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            password: ['', Validators.required],
            username: ['', Validators.required]
        });
    }


    loginSubmit(tipo: number): void {
        this.authService.login(this.loginForm.value).subscribe(
            (data) => {
                if (data.success) {
                const permissoes = [];

                    if (data.object.superUsuario) {
                    data.object.listaPermissoes.push('SUPER_USUARIO');
                }
                data.object.listaPermissoes.push('home');
                sessionStorage.setItem('permissoes', JSON.stringify(data.object.listaPermissoes));
                sessionStorage.setItem('superUsuario', JSON.stringify(data.object.superUsuario));
                sessionStorage.setItem('idPessoa', JSON.stringify(data.object.idPessoa));
                sessionStorage.setItem('usuarioNoturno', JSON.stringify(data.object.usuarioNoturno));
                sessionStorage.setItem('usuario', this.loginForm.value.username);
                    
                this.shared.permissoes = data.object.listaPermissoes;

                // tslint:disable-next-line:triple-equals
                if ((sessionStorage.getItem('superUsuario') == 'true' && tipo == 2) || data.object.cargo == 'GESTOR'
                || data.object.cargo == 'DIRETORIA' || data.object.cargo == 'ADMINISTRADOR' || data.object.cargo == 'DIRETOR ADMINISTRATIVO'
                || data.object.cargo == 'DIRETOR FINANCEIRO') {
                    this._matDialog.open(SelectPostoFormComponent).afterClosed()
                      .subscribe(r => {
                        this.usuario = {
                            email: data.object.email,
                            nomeUser: data.object.nome,
                            cargo: data.object.cargo,
                            idFuncionario: data.object.idFuncionario ? data.object.idFuncionario : 0,
                            idPosto: r.posto.id,
                            idUsuario: data.object.idUsuario,
                            nomePosto: r.posto.descricao,
                            postoEletro: data.object.usuarioPostoEletro
                        };
                        sessionStorage.setItem('funcionario', JSON.stringify(this.usuario));

                        if (data.object.senhaExpirada) {
                            this._matDialog.open(AlterarSenhaComponent);
                        } 
                        else {
                            this.dialogRef = this._matDialog.open(MessageDialogComponent, {
                                data: {
                                    type: 'success',
                                    title: 'Sucesso!',
                                    text: `Logado com sucesso.Bem - Vindo ${data.object.login}!`
                                }
        
                            });
                            setTimeout(() => this.dialogRef.close(), 4000);
                        }
                        this.router.navigate([this.authService.redirectUrl || 'home']);

                    });
                // tslint:disable-next-line:triple-equals
                } else if (tipo == 1) {
                    this.usuario = {
                        email: data.object.email,
                        nomeUser: data.object.nome,
                        idFuncionario: data.object.idFuncionario ? data.object.idFuncionario : 0,
                        idPosto: Number.isInteger(data.object.idPosto) ? data.object.idPosto : null,
                        idUsuario: data.object.idUsuario,
                        nomePosto: data.object.descPosto,
                        cargo: data.object.cargo,
                        postoEletro: data.object.usuarioPostoEletro
                    };
                    sessionStorage.setItem('funcionario', JSON.stringify(this.usuario));

                    if (data.object.senhaExpirada) {

                        this._matDialog.open(AlterarSenhaComponent);
    
                    } 
                    else {
                        this.dialogRef = this._matDialog.open(MessageDialogComponent, {
                            data: {
                                type: 'success',
                                title: 'Sucesso!',
                                text: `Logado com sucesso.Bem - Vindo ${data.object.login}!`
                            }
    
                        });
                        setTimeout(() => this.dialogRef.close(), 4000);
                    }
                    this.router.navigate([this.authService.redirectUrl || 'home']);
                } else {
                    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
                        data: {
                            type: 'info',
                            title: 'Informação!',
                            text: `Usuário informado não pode selecionar posto`
                        }
                    });
                }
                } else {
                    if (data.messages[0].text != null) {
                        this.dialogMessages = {
                            type: 'info',
                            title: 'Informação!',
                            text: `${data.messages[0].text}`
                        };
                    } else {
                        this.dialogMessages = {
                            type: 'error',
                            title: 'Error!',
                        };
                    }
                    this.feedback(this.dialogMessages);
                }
            },
            (error) => {
                console.log(error)
                this.dialogRef = this._matDialog.open(MessageDialogComponent, {
                    data: {
                        type: 'warning',
                        title: 'Aviso!',
                        text: 'Falha ao logar. Usuario ou senha incorreta.'
                    }
                });
                setTimeout(() => this.dialogRef.close(), 4000);
            });

    }

    private feedback(message: string[]): void {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }

}
