import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthenticationService } from 'app/main/authentication/services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FuseNavigation } from '@fuse/types';
import { SharedService } from 'app/shared/shared.service';


@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    usuario;
    // Private
    private _unsubscribeAll: Subject<any>;

    private permissoes: string;

    shared: SharedService;

    /**
     * Constructor
     */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private router: Router,
        private authService: AuthenticationService,
        private _matDialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.shared = SharedService.getInstance();
        this.usuario = JSON.parse(sessionStorage.getItem('funcionario'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    private getAllPermissions(fuseNavigation: FuseNavigation[], isChildren?: boolean): Array<string> {

        let permissoes: Array<string> = [];

        fuseNavigation.forEach((permissao) => {
            if (permissao.children) {
                permissoes = permissoes.concat(this.getAllPermissions(permissao.children, true));
            }
            if (isChildren) {
                permissoes.push(permissao.id);
            }
        });
        return permissoes;
    }

    private removerPermissoes(fuseNavigations: FuseNavigation[], missing: Array<string>): FuseNavigation[] {

        return fuseNavigations.map((fuseNavigation: FuseNavigation) => {

            if (fuseNavigation.children) {
                fuseNavigation.children = this.removerPermissoes(fuseNavigation.children, missing);
            }

            if (!missing.includes(fuseNavigation.id)) {
                return fuseNavigation;
            }
            return;
        }).filter(fuseNavigation => {
            return fuseNavigation && fuseNavigation.id;
        });
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        this.permissoes = sessionStorage.getItem('permissoes');

        const permissions = this.getAllPermissions(this.navigation);

        if (this.permissoes != null) {
            setTimeout(() => {
                const missing = permissions ? permissions.filter(item => this.permissoes.indexOf(item) < 0) : [];
                this.navigation = this.removerPermissoes(this.navigation.filter(element => this.permissoes.indexOf(element.id) > 0), missing);
            }, 200);
        }

    }

    logout(): void {
        this.authService.logout();
        this.navigation = [];
        this.permissoes = '';
        this.shared.permissoes = null;
        this.router.navigate(['authentication/login']);
    }


    alterarSenha(): void {

    }
}
