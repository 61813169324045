import { Component, OnInit, Input } from '@angular/core';
import { DashBoardFaturamentoTO } from 'app/main/shared/model/dashboard-faturamento/dashboard-faturamento';

@Component({
  selector: 'app-posto-card',
  templateUrl: './posto-card.component.html',
  styleUrls: ['./posto-card.component.scss']
})
export class PostoCardComponent implements OnInit {

  @Input() dashboard: DashBoardFaturamentoTO;
  
  constructor() { }

  ngOnInit(): void {}

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
