import { Component, Inject, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { ReceberCobrancaFormComponent } from 'app/main/financial/components/cobrancas/receber-cobranca-form/receber-cobranca-form.component';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';

@Component({
  selector: 'app-receber-parcela-form',
  templateUrl: './receber-parcela-form.component.html',
  styleUrls: ['./receber-parcela-form.component.scss']
})
export class ReceberParcelaFormComponent implements OnInit, AfterViewInit {

  @ViewChild('valorPago') valorPago: ElementRef;

  cobranca: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  situacao: FormGroup;
  titulo: string;
  disabled = false;

  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));

  situacoes = [
    { enum: 'PARCELA_RECEBIDA', nome: 'Parcela Recebida' },
    { enum: 'PARCELA_NAO_RECEBIDA', nome: 'Parcela não Recebida' },
  ];

  constructor(private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<ReceberCobrancaFormComponent>,
    public dialog: MatDialog,
    private cobrancaService: CobrancasService,
    private transferenciaService: TransferenciaService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    // tslint:disable-next-line:triple-equals
    this.titulo = this._data.action == 'save' ? 'Recebimento de Cobrança' : 'Alterar Parcela';
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.valorPago.nativeElement.focus(), 1000);
  }

  setForm(): void {
    this.cobranca = this.formBuilder.group({
      // tslint:disable-next-line:triple-equals
      situacao: 'PARCELA_RECEBIDA',
      parcela: this.formBuilder.group({
        id: this._data.cobranca.parcela.id,
        ordem: [{ value: this._data.cobranca.parcela ? this._data.cobranca.parcela.ordem : null, disabled: true }],
        valor: [{ value: this._data.cobranca.parcela ? this._data.cobranca.parcela.valor : null, disabled: true }],
        valorPago: [this._data.cobranca.parcela.valorPago ? this._data.cobranca.parcela.valorPago : 0, Validators.required],
        situacaoParcela: 'COBRADA'
      })
    });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  getSituacao(value): void {
    // tslint:disable-next-line:triple-equals
    if (value == 'PARCELA_NAO_RECEBIDA') {
      this.disabled = true;
    }
  }


  onSave(value): void {
    
    this.disabledButton = true;
    
    // tslint:disable-next-line:triple-equals
    if (this._data.contrato == false) {
      
      if (this.cobranca.value.situacao === 'PARCELA_RECEBIDA' || this.cobranca.value.situacao === 'PARCELA_NAO_RECEBIDA') {
        
        const confirm = {
          id: this._data.cobranca.id,
          situacao: this.cobranca.value.situacao,
          parcela: {
            id: this._data.cobranca.parcela.id,
            valorPago: value.parcela.valorPago ? value.parcela.valorPago : 0,
            situacao: 'COBRADA'
          }
        };

        this.transferenciaService.confirmCobrancaCliente(confirm).subscribe((rest: ReturnApi) => {
          if (rest.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Parcela Recebida com Sucesso!`
            };
          } else {
            if (rest.messages != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${rest.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(rest.object);
        });
      } else {
        const confirm = {
          id: this._data.cobranca.id,
          situacao: this.cobranca.value.situacao
        };
        this.transferenciaService.confirmRecebimento(confirm).subscribe((rest: ReturnApi) => {
          if (rest.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${rest.messages[0].text}`
            };
          } else {
            if (rest.messages != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${rest.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(rest.object);
        });
      }
    } else {
      this.receberParcela(value);
    }

    this.disabledButton = true;
  }



  onSavePendencia(value: any): void {
    this.disabledButton = true;

    const confirm = {
      id: this._data.cobranca.id,
      situacao: this.cobranca.value.situacao,
      parcela: {
        id: this._data.cobranca.parcela.id,
        valorPago: value.parcela.valorPago ? value.parcela.valorPago : 0,
        situacao: 'COBRADA'
      }
    };

    this.transferenciaService.confirmCobrancaClientePendencia(confirm).subscribe((rest: ReturnApi) => {
      if (rest.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Parcela Recebida com Sucesso!`
        };
      } else {
        if (rest.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${rest.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
      }
      this.feedback(this.dialogMessages);
      this.matDialogRef.close(rest.success);
    }, () => {
      const options: any = { type: 'error', title: 'Error!' };
      this.feedback(options);
      this.matDialogRef.close(false);
    });
  }

  onUpdate(value): void {
    this.disabledButton = true;
    this._data.cobranca.parcela.valorPago = value.parcela.valorPago;

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'alteracao_parcela_confirmada_na_pendencia') {
      this.cobrancaService.alterarParcelaConfirmadaNaPendencia({ ...this._data.cobranca.parcela, contrato: null }, this._data.usuario).subscribe((rest: ReturnApi) => {
        if (rest.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${rest.messages[0].text}`
          };
        } else {
          if (rest.messages != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${rest.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(rest.success);
      });
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'alteracao_parcela_lancamento_entrada') {
      this.cobrancaService.alterarParcelaConfirmadaLancamentoEntrada({ ...this._data.cobranca.parcela, contrato: null }, this._data.usuario).subscribe((rest: ReturnApi) => {
        if (rest.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${rest.messages[0].text}`
          };
        } else {
          if (rest.messages != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${rest.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(rest.object);
      });
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'alteracao_parcela_recebida') {
      this.cobrancaService.alterarParcelaRecebidaNaCobranca({ ...this._data.cobranca.parcela, contrato: null }, this.funcionario.idUsuario).subscribe((rest: ReturnApi) => {
        if (rest.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${rest.messages[0].text}`
          };
        } else {
          if (rest.messages != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${rest.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(rest.success);
      });
    }
  } 


  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  private receberParcela(value): void {
    this._data.cobranca.parcela.valorPago = value.parcela.valorPago;
    this._data.cobranca.parcela.situacaoParcela = 'COBRADA';

    this.cobrancaService.receberParcela(this._data.cobranca.parcela).subscribe((data: ReturnApi) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
      }
      this.feedback(this.dialogMessages);
      this.matDialogRef.close(data.object);

    });
  }

}
