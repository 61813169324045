export class Telephone {
    ativo?: boolean;
    id?: number;
    numero?: string;
    tipoSmartphone?: boolean;
    possuiInternet?: boolean;
    instalarAppCliente?: boolean;

    constructor(obj?: any) {
        this.ativo = obj.ativo || '';
        this.id = obj.id || null;
        this.numero = obj.numero || '';
        this.tipoSmartphone = obj.tipoSmartphone || false;
        this.possuiInternet = obj.possuiInternet || false;
        this.instalarAppCliente = obj.instalarAppCliente || false;
    }
}
