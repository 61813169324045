import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Telephone } from 'app/main/shared/model/telephone/telephone.model';

@Component({
  selector: 'app-cliente-full-reference',
  templateUrl: './cliente-full-reference.component.html',
  styleUrls: ['./cliente-full-reference.component.scss'],
  animations: fuseAnimations
})
export class ClienteFullReferenceComponent implements OnInit {

  telephones: FormArray;
  @Input() cliente: FormGroup;
  listTelefone: Telephone[]
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.cliente.get('referenciaPessoal').value.length == 0) {
      this.add()
    }
  }

  add(required?: boolean): void {
    const reference = this.formBuilder.group({
      id: new FormControl(null),
      nome: new FormControl(null, Validators.required),
      situacao: new FormControl(null, Validators.required),
      type: new FormControl('rp'),
      listTelefone: new FormControl(this.buildPhone(['']))
    });
    this.referenciaForms.push(reference);
  };

  delete(index: number): void {
    this.referenciaForms.removeAt(index);
  }
  buildPhone(listTelefone): FormArray {
    const phone = listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: [v.ativo],
        numero: [v.numero, [Validators.required, Validators.minLength(10)]],
        id: [v.id],
        instalarAppCliente: [v.instalarAppCliente],
        possuiInternet: [v.possuiInternet],
        tipoSmartphone: [v.tipoSmartphone]

      });
    });
    return this.formBuilder.array(phone);
  }

  getErrorMessage(field: string) {
  }

  get referenciaForms(): FormArray {
    return this.cliente.get('referenciaPessoal') as FormArray;
  }

  get phoneForms(): FormArray {
    return this.cliente.get('referenciaPessoal.listTelefone') as FormArray;
  }
}


