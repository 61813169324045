import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { Cliente } from 'app/main/shared/model/cliente/cliente.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  host = `${SGF_API}` + 'sgf/api/public/cliente';
  hostCliente = `${SGF_API}` + 'cliente/api/public/cliente';
  private posto: string;

  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<Cliente[]>(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  page(size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&sort=-id&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageSitucao(size: number, page: number, situacao: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&situacaoCliente=in:${situacao}&sort=-id&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  pageDocumentoCliente(size: number, page: number, documento: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?filters=%26documentoCliente%3D%25${documento}%25&size=${size}&page=${page}&sort=-id&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageNomeCliente(size: number, page: number, nomeCliente: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&size=${size}&page=${page}&sort=-id&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  addCliente(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  editCliente(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<Cliente[]>(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  putClienteSpc(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put<Cliente>(`${this.host}/adicionaClienteSerasa`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  removeClienteSpc(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put<Cliente>(`${this.host}/removeClienteSerasa`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  relatorioInadimplencia(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.hostCliente}/imprimir-relatorio-inadimplencia`, { responseType: 'blob', headers: httpHeaders })
  }

  relatorioInativos(datas: any, tipoContrato: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.hostCliente}/imprimir-relatorio-inativos?datas=${datas}&boleto=${tipoContrato}`, { responseType: 'blob', headers: httpHeaders })
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
} 
}
