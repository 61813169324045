import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../../shared/shared.module';
import { SharedMaterialComponentsModule } from '../../../../shared-material-components.module';
import { AuthGuard } from 'app/shared/guards/auth/auth-guard.service';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AcompanhamentoContratosEntreguesMainComponent } from './acompanhamento-contratos-entregues-main/acompanhamento-contratos-entregues-main.component';
import { PostoCardComponent } from './posto-card/posto-card.component';

const routes: Routes = [
  {
    path: '',
    component: AcompanhamentoContratosEntreguesMainComponent,
    resolve: {}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})
  ],
  declarations: [AcompanhamentoContratosEntreguesMainComponent, PostoCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AcompanhamentoContratosEntreguesModule { }
