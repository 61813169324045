import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AttendanceModel } from 'app/main/shared/model/attendace/attendance.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  host: string = `${SGF_API}` + 'sgf/api/public/frequencia';
  posto: string;

  constructor(
    private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get<AttendanceModel[]>(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  
  }

  page(size: number, page: number): Observable<any>  {
   
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`)
    .pipe(
      catchError(this.handleError)
    );
     
  }

  create(benefits: any): Observable<any> {
    const body = JSON.stringify(benefits);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(benefits: any): Observable<any> {
    const body = JSON.stringify(benefits);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }


}
