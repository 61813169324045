import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Employee } from '../../model/employee/employee.model';
import { ReplaySubject } from 'rxjs';


@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss']
})
export class FilterDateComponent implements OnInit {

  form: FormGroup
  employee: Employee[]
  dialogMessages

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  constructor(private formBuild: FormBuilder,
    private dialogRef: MatDialogRef<FilterDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog) {

  }

  ngOnInit() {
    this.form = this.formBuild.group({
      data: [null, Validators.required]
    })
  }

  filtrar(value) {
    // let d = Date.parse();
    let date = new Date(value.data).toISOString().slice(0,10)
    this.dialogRef.close(date)
  }

  onCancel() {
    this.dialogRef.close();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }


}
