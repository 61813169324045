import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseWidgetModule } from '@fuse/components';
import { WidgetCardComponent } from './components/widget-card/widget-card.component';
import { ScrumboardCardComponent } from './components/scrumboard-card/scrumboard-card.component';
import { MatIconModule, MatSelectModule } from '@angular/material';
import { WidgetCard } from './data/widget-data';
import { ScrumboardCard } from './data/scrumboard-data';
import { RouterModule } from '@angular/router';
import { LineAreaChartComponent } from '../../shared/components/line-area-chart/line-area-chart.component';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    FuseWidgetModule,
    RouterModule
  ],
  declarations: [WidgetCardComponent, ScrumboardCardComponent, LineAreaChartComponent],
  exports: [WidgetCardComponent, ScrumboardCardComponent, LineAreaChartComponent],
  providers: [
    WidgetCard,
    ScrumboardCard
  ]
})
export class HomeSharedModule { }
