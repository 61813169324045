import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClienteService } from '../../cliente/services/cliente.service';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';
import { FormControl } from '@angular/forms';
import { CaptacaoService } from '../../captacao/services/captacao.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-captacoes-rejeitados-sidebar',
  templateUrl: './captacoes-rejeitados-sidebar.component.html',
  styleUrls: ['./captacoes-rejeitados-sidebar.component.scss'],
  
})
export class CaptacoesRejeitadosSidebarComponent implements OnInit {
  
  @Output() filtroEvent: EventEmitter<string> = new EventEmitter();
  @Input() pageNumber: number;
  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  searchInput: string;

  searchEntry: Subject<string> = new Subject<string>();

  all = false;
  rejected = false;
  nomeCliente = false;
  indicador = false;
  filter: string;
  filterInput: string;
  placeholder: string;
  interval;
  specification: string;
  inputDisabled = false;
  search = '';
  mask;
  constructor(private captacaoService: CaptacaoService) { }

  ngOnInit(): void {
    this.placeholder = 'Buscar Por Cliente';
    this.filter = 'NOME_CLIENTE';
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(): void{
    if (this.searchInput == null){
      this.search = '';
    }
  }

  getFiltro(event): void {
    this.filter = event;
    // tslint:disable-next-line: triple-equals
    if (event == 'all') {
      this.filtroEvent.emit(event);
      this.placeholder = 'Buscar Por Cliente';
      this.filter = 'NOME_CLIENTE';
    }
    // tslint:disable-next-line: triple-equals
    if (event == 'NOME_CLIENTE') {
      this.placeholder = 'Buscar Por Cliente';
      this.filtroEvent.emit(event);
    }
    // tslint:disable-next-line: triple-equals
    if (event == 'NOME_FANTASIA') {
      this.placeholder = 'Buscar Por Nome Fantasia';
      this.filtroEvent.emit(event);
    }
    // tslint:disable-next-line: triple-equals
    if (event == 'CPF') {
      this.placeholder = 'Buscar Por CPF';
      this.filtroEvent.emit(event);
    }
    // tslint:disable-next-line: triple-equals
    if (event == 'CNPJ') {
      this.placeholder = 'Buscar Por CNPJ';
      this.filtroEvent.emit(event);
    }
  }

  onKeyPressFilter(event: any): void {
    this.searchEvent.emit(event);
    switch (this.filter) {
      case 'NOME_CLIENTE':
        if (event === '' || event === null ) {
          this.captacaoService.pageBySituacaoCaptado(1, 10, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        } else {
          this.captacaoService.pageByNomeCliente(1, 10, event, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        }
      break;

      case 'NOME_FANTASIA':
        if (event === '' || event === null ) {
          this.captacaoService.pageBySituacaoCaptado(1, 10, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        } else {
          this.captacaoService.pageByNomeFantasia(1, 10, event, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        }
      break;

      case 'CPF':
        if (event === '' || event === null ) {
          this.captacaoService.pageBySituacaoCaptado(1, 10, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        } else {
          this.captacaoService.pageByCpf(1, 10, event, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        }
      break;

      case 'CNPJ':
        if (event === '' || event === null ) {
          this.captacaoService.pageBySituacaoCaptado(1, 10, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        } else {
          this.captacaoService.pageByCnpj(1, 10, event, 'REJEITADO').subscribe( (data: any) => {
            this.data.emit(data.object);
          });
        }
      break;
    }
  }

}
