import { Component, Inject, OnInit } from '@angular/core';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { Customer } from '../../model/customer/customer.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Contract } from '../../model/contract/contract.model';
import { AnaliseSobraService } from 'app/main/financial/services/sobra/sobra.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { AuditoriaFormComponent } from '../auditoria-form/auditoria-form.component';
import { Cliente } from '../../model/cliente/cliente.model';

@Component({
  selector: 'app-analisar-sobra-form',
  templateUrl: './analisar-sobra-form.component.html',
  styleUrls: ['./analisar-sobra-form.component.scss'],
})
export class AnalisarSobraFormComponent implements OnInit {

  clienteList: any[];
  cliente: any;

  contratoList: any[];
  contrato: any;

  parcelaList: any[];
  parcela: any;

  valorSobra = 0;

  analiseSobraForm: FormGroup;

  titleHeader: string;

  transferencia: any;

  disabledButton: boolean;

  disabledSelect: boolean;

  readonly: boolean;

  action: string;

  public clienteFilterCtrl: FormControl = new FormControl();
  public filteredCliente: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  public contratoFilterCtrl: FormControl = new FormControl();
  public filteredContrato: ReplaySubject<Contract[]> = new ReplaySubject<Contract[]>(1);

  public parcelaFilterCtrl: FormControl = new FormControl();
  public filteredParcela: ReplaySubject<Contract[]> = new ReplaySubject<Contract[]>(1);

  private _onDestroy = new Subject<void>();
  currentCliente: Cliente;
  clientes: Cliente[];


  constructor(
    private clienteService: ClienteService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AnalisarSobraFormComponent>,
    private contratoService: ContractsService,
    private analiseSobraService: AnaliseSobraService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private utilsService: UtilsService) {

    this.listarClientes();
    this.changeTitleHeader();
    this.action = this._data.action;
    this.disabledSelect = this._data.disabledSelect;

  }

  ngOnInit(): void {
    this.setFormGroup();
  }


  changeTitleHeader(): void {

    if (this._data.action === 'CREATE') {
      this.titleHeader = 'Registrar Crédito de Sobra';
      this.valorSobra = this._data.sobra.valorRealizado;
      this.transferencia = this._data.sobra;
    } else if (this._data.action === 'REANALISAR') {
      this.titleHeader = 'Reanalisar Crédito de Sobra';
      this.valorSobra = this._data.creditoSobra.valorSobra;
      this.transferencia = this._data.creditoSobra.transferencia;
    } else if (this._data.action === 'CONFIRMAR') {
      this.titleHeader = 'Confirmar Crédito de Sobra';
      this.valorSobra = this._data.creditoSobra.valorSobra;
    }
  }

  setFormGroup(): void {

    let creditoSobra = null;
    let cliente = null;
    let contrato = null;
    let parcela = null;

    if (this._data.action === 'CONFIRMAR' || this._data.action === 'REANALISAR') {
      creditoSobra = this._data.creditoSobra;
      cliente = this._data.creditoSobra.cliente;
      contrato = this._data.creditoSobra.contrato;
      parcela = this._data.creditoSobra.parcela;
      this.carregarContratosCliente(cliente.id);
      this.carregarParcelasContrato(contrato);
    }

    this.analiseSobraForm = this.formBuilder.group({
      id: creditoSobra ? creditoSobra.id : null,
      clienteId: cliente ? creditoSobra.cliente.id : null,
      contratoId: contrato ? creditoSobra.contrato.id : null,
      parcelaId: parcela ? creditoSobra.parcela.id : null,
      valorSobra: this.valorSobra ? this.valorSobra : 0,
    });


  }

  private listarClientes(): void {
  }

  carregarContratosCliente(clienteId: any): void {

    this.contratoService.contratosByIdCliente(clienteId).subscribe(response => {

      this.contratoList = response.object.content;

      this.filteredContrato.next(this.contratoList.slice());
      this.contratoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterContrato();
        });
    });
  }

  private filterContrato(): void {

    if (!this.contratoList) {
      return;
    }

    let search = this.contratoFilterCtrl.value;

    if (!search) {
      this.filteredContrato.next(this.contratoList.slice());
      return;
    } else {
      search = search;
    }

    this.filteredContrato.next(
      this.contratoList.filter(contrato => contrato.id.indexOf(search) > -1),
    );
  }

  carregarParcelasContrato(contrato: any): void {

    this.parcelaList = contrato.listParcela.filter(p => p.situacaoParcela === 'CONFIRMADA' || p.situacaoParcela === 'PRAZO_ENCERRADO');

    this.filteredParcela.next(this.parcelaList.slice());
    this.parcelaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterParcela();
      });

  }

  private filterParcela(): void {

    if (!this.parcelaList) {
      return;
    }

    let search = this.parcelaFilterCtrl.value;

    if (!search) {
      this.filteredParcela.next(this.parcelaList.slice());
      return;
    } else {
      search = search;
    }

    this.filteredParcela.next(
      this.parcelaList.filter(parcela => parcela.id.indexOf(search) > -1),
    );
  }

  cadastrar(value: any): void {
    this.disabledButton = true;
    value.transferenciaId = this.transferencia.id;
    this.analiseSobraService.save(value).subscribe((response: ReturnApi) => {
      const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }
    });
  }

  reanalisar(value: any): void {
    this.disabledButton = true;
    value.transferenciaId = this.transferencia.id;
    this.analiseSobraService.reanalisar(value).subscribe((response: ReturnApi) => {
      const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }
    });
  }

  rejeitar(value: any): void {
    this.disabledButton = true;
    this.analiseSobraService.rejeitarCreditoSobra(value.id).subscribe((response: ReturnApi) => {
      const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }
    });
  }

  confirmar(value: any): void {
    this.disabledButton = true;
    this.dialog.open(AuditoriaFormComponent, {
      data: {
        titulo: 'Autenticar Auditoria',
      },
    }).afterClosed().subscribe(r => {
      value.funcionarioId = r.idFuncionario;
      this.analiseSobraService.confirmarCreditoSobra(value).subscribe((response: ReturnApi) => {
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
        if (isSuccess) {
          this.dialogRef.close();
        } else {
          this.disabledButton = false;
        }
      });
    });
  }

  onClosed(): void {
    this.dialogRef.close();
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  buscarUsuariosFiltro(nomeBusca: string): void {
    this.clienteService.findClienteByNome(nomeBusca).subscribe(response => {
      this.clienteList = response.object.content;
      this.filteredCliente.next(this.clienteList.slice());
    });
  }

  doFilter(evento: Event): void {
    const target = evento.target as HTMLTextAreaElement;

    this.clienteService.pageNomeBusca(20, 1, target.value).subscribe(data => {
      this.clientes = data.object.content;
    });

  }

  displayFn(cliente: Cliente): string {
    return cliente ? cliente.nomeCliente : '';
  }

  serviceOnSelect(cliente: any): void {
    this.currentCliente = cliente;
    this.carregarContratosCliente(cliente.id);
    this.analiseSobraForm.get('clienteId').setValue(cliente.id);
  }
}
