import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-bordero-confirm',
  templateUrl: './bordero-confirm.component.html',
  styleUrls: ['./bordero-confirm.component.scss']
})
export class BorderoConfirmComponent implements OnInit {

  /**
   * Constructor
   *
   * @param {MatDialogRef<BorderoConfirmComponent>} matDialogRef
   * @param _data
   */
  disabledButton:boolean;
  constructor(
    public matDialogRef: MatDialogRef<BorderoConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(data?): void {
    if (data) {
      data.contrato.situacaoContrato = 'ANTECIPADO_BORDERO'
      data.contrato.valorPagoBordero = this.data.valorAPagar;
      data.contrato.adiantamento = true;
    }
    this.disabledButton = true;  
    this.matDialogRef.close(data);
  }

}
