import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter, Input, ɵConsole } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { MAT_DIALOG_DATA, MatSelect, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Mask } from '@fagnerlima/ng-mask';
import { CustomersService } from 'app/main/customers/services/customers.service';
import { Cliente } from 'app/main/shared/model/cliente/cliente.model';
import { fuseAnimations } from '@fuse/animations';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { MessageDialogComponent } from '../../message-dialog/message-dialog.component';

@Component({
  selector: 'app-captacao-forms-indicador',
  templateUrl: './captacao-forms-indicador.component.html',
  styleUrls: ['./captacao-forms-indicador.component.scss'],
  animations: [fuseAnimations]
})
export class CaptacaoFormsIndicadorComponent implements OnInit {

  @ViewChild('nome') nome: ElementRef;

  listFuncionarios: Array<Employee>;
  listClientes: Array<Cliente>;
  employeeLogged: Employee;
  telephones: FormArray;
  step = 0;

  dialogMessages;

  tipoNaoPossuiIndicador = false;
  indicadorFuncionario = false;
  indicadorCliente = false;
  novoIndicador = false;
  

  @Output() indicadorEvent: EventEmitter<any> = new EventEmitter();
  @Output() indicadorValidateEmmiter: EventEmitter<any> = new EventEmitter();

  

  tipoIndicador: any;
  
  @Output() employeeLoggedEmitter: EventEmitter<Employee> = new EventEmitter();
  @Input() customer: FormGroup;
  @Output() isIndicadorPfEvent: EventEmitter<any> = new EventEmitter();
  @Input() isEletro: boolean;
  @Input() usuario: any;

  maskCpf = new Mask('000.000.000-00');
  maskCNPJ = new Mask('00.000.000\\0000-00');
  
  public indicadorFuncionarioFilterControl: FormControl = new FormControl();
  public filteredIndicadorFuncionario: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public indicadorClienteFilterControl: FormControl = new FormControl();
  public filteredIndicadorCliente: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  
  constructor(
    private employeeService: EmployeeService,
    private customerService: CustomersService,
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      this.loadIndicador(this._data.customer.tipoIndicador);
    }

  }

  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();

 

  ngOnInit(): void {
    // tslint:disable-next-line:triple-equals
    if (this.customer.get('indicador.tipoPessoa').value == 'PESSOA_FISICA') {
      this.setCpfField(true);
    }  
    
    // tslint:disable-next-line:triple-equals
    if (this.customer.get('indicador.tipoPessoa').value == 'PESSOA_JURIDICA') {
      this.setCpfField(false);
    }
  }

  private filterIndicadorFuncionario(): void {
    if (!this.listFuncionarios) {
      return;
    }

    let search = this.indicadorFuncionarioFilterControl.value;
    if (!search) {
      this.filteredIndicadorFuncionario.next(this.listFuncionarios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredIndicadorFuncionario.next(
      this.listFuncionarios.filter(f => f.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );

  }

  private filterIndicadorCliente(): void {
    if (!this.listClientes) {
      return;
    }

    let search = this.indicadorClienteFilterControl.value;
    if (!search) {
      this.filteredIndicadorCliente.next(this.listClientes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredIndicadorCliente.next(
      this.listClientes.filter(f => f.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );

  }

  getEmployeeUserLogged(): void {
    return JSON.parse(sessionStorage.getItem('funcionario')).idFuncionario; 
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  loadIndicador(value): void {
    switch (value) {
      case 'INDICADOR_FUNCIONARIO':

        this.employeeService.findAll().subscribe(data => {
          this.listFuncionarios = data.object.content;
          // tslint:disable-next-line:triple-equals
          this.employeeLogged = data.object.content.filter(filter => filter.id == this.getEmployeeUserLogged())[0];
          this.employeeLoggedEmitter.emit(this.employeeLogged);
    
          this.filteredIndicadorFuncionario.next(this.listFuncionarios.slice());
          this.indicadorFuncionarioFilterControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterIndicadorFuncionario();
            });

            this.indicadorFuncionario = true;
            this.customer.get('indicadorCliente').setValue(null);
    
        });

      break;

      case 'INDICADOR_CLIENTE':
        this.customerService.findAll().subscribe(data => {
          this.listClientes = data.object.content;

          this.filteredIndicadorCliente.next(this.listClientes.slice());
          this.indicadorClienteFilterControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterIndicadorCliente();
            });

            this.indicadorCliente = true;
            this.customer.get('indicadorFuncionario').setValue(null);

            
    
        });

      break;

      case 'NOVO_INDICADOR':

            this.novoIndicador = true;
            // tslint:disable-next-line:triple-equals
            if (this.customer != undefined) {
              this.customer.get('indicadorFuncionario').setValue(null);
              this.customer.get('indicadorCliente').setValue(null);
            }
           
      break;

      case 'NAO_POSSUI_INDICADOR':
            this.novoIndicador = true;
      break;      
    }

    
  } 

  validacaoPessoaFisicaJaCadastrada(): void {
    this.clienteService.validarPessoaJaCadastradaNome(this.customer.get('indicador.cpf').value).subscribe(res => {
      if (res.object) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Pessoa: ${res.object.nomePessoa} já cadastrada`
        };
        this.feedback(this.dialogMessages);
        this.indicadorValidateEmmiter.emit(true);
      } else {
        this.indicadorValidateEmmiter.emit(false);
      }
    });
  }

  validacaoPessoaJuridicaJaCadastrada(): void {
    this.clienteService.validarPessoaJaCadastradaNome(this.customer.get('indicador.cnpj').value).subscribe(res => {
      if (res.object) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Pessoa: ${res.object.nomePessoa} já cadastrada`
        };
        this.feedback(this.dialogMessages);
        this.indicadorValidateEmmiter.emit(true);
      } else {
        this.indicadorValidateEmmiter.emit(false);
      }
    });
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  setIndicador(value): void {
   
    this.customer.get('tipoIndicador').setValue(value);
    this.indicadorEvent.emit(value);
    this.loadIndicador(value);

  }

  get indicadorPhoneForms(): FormArray {
    return this.customer.get('indicador.listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });

    this.indicadorPhoneForms.push(phone);
  }

  deleteIndicadorPhone(index: number): void {
    this.indicadorPhoneForms.removeAt(index);
  }

  get indicadorAddressForms(): FormArray {
    return this.customer.get('indicador.listEndereco') as FormArray;
  }

  add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: [],
      cep: new FormControl(null),
      logradouro: new FormControl(null),
      numero: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null),
      estado: new FormControl(null),
      tipoEndereco: new FormControl(null)
    });
    this.indicadorAddressForms.push(address);
  }

  delete(index: number): void {
    this.indicadorAddressForms.removeAt(index);

  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  setCpfField(isCpf: boolean): void {
    this.customer.get('indicador').get('isCpf').setValue(isCpf);
    this.isIndicadorPfEvent.emit(isCpf);
  }

}
