import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { ReportService } from 'app/main/report/services/report.service';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { Posto } from 'app/main/shared/model/posto/posto.model';
import { UtilsService } from '../../../../../shared/util/utils.service';
import { TransferenciaService } from '../../../../financial/services/transferencia/transferencia.service';
import { Rota } from '../../../../shared/model/rota/rota.model';
import * as FileSaver from 'file-saver';
import { Moment } from 'moment';
import { CobrancasService } from '../../../../financial/components/cobrancas/services/cobrancas.services';
import { TimeMensagem } from '../../../../shared/model/enums/time-mensagem.enum';
import { FolhaCobrancaService } from '../../../../financial/services/folha-cobranca/folha-cobranca.service';
import { Team } from '../../../../shared/model/team/team.model';
import { TeamService } from '../../../../global/components/teams/services/team.service';

const moment = require('moment');

@Component({
  selector: 'app-period-report',
  templateUrl: './period-report.component.html',
  styleUrls: ['./period-report.component.scss'],
})
export class PeriodReportComponent implements OnInit {
  dataInicial = '';
  dataFinal = '';
  tipoContrato = '0';
  postos: any;
  equipes: any;
  rotas: any;
  postoId = '';
  title = ' ';
  disabledButton = false;
  currentPosto: any;
  currentEquipe: any;
  currentRota: Partial<Rota>;
  currentRotaEquipe: Partial<Rota>;
  rotasEquipe: any;
  public loading = false;

  dataTypePosto = false;
  dataTypeFicha = false;
  dataTypeBoleto = false;
  dataTypeDataDe = false;
  dataTypeDataAte = false;
  dataTypeRota = false;
  dataTypeRotaEquipe = false;
  dataTypeEquipe = false;
  dataTypeInputMinimo = false;
  dataTypeInputMaximo = false;
  dataTypeCliente: any;

  dataInicialSemanal: any;
  dataTypeSemanal = false;
  dataTypeOption = false;

  valorMinimoBusca: any;
  valorMaximoBusca: any;

  inputTextLabel: string;
  inputText: string;

  valorMinimoRequired = false;
  valorMaximoRequired = false;
  dataInicialRequired = false;
  dataFinalRequired = false;
  rotaRequired: any;
  rotaEquipeRequired: any;
  dataSemanalRequired = false;
  opcoesRequired = false;
  opcaoSelecionadaSituacaoCadastral: any;
  opcaoSelecionadaTipoCliente: any;

  disabledRota = false;
  disabledRotaEquipe = false;

  valorMaximoInputView = {
    'clientes-inadimplentes-regular': 'Quantidade de Faltas Máxima',
    'clientes-inadimplentes-irregular': 'Dias em Atraso Máximo',
    'contratos-por-cliente': 'Saldo Devedor Máximo',
  };

  valorMinimoInputView = {
    'clientes-inadimplentes-regular': 'Quantidade de Faltas Mínima',
    'clientes-inadimplentes-irregular': 'Dias em Atraso Mínimo',
    'contratos-por-cliente': 'Saldo Devedor Mínimo',
    'clientes-aproximacao-de-liquidacao': 'Quantidade de Parcelas',
  };

  dataInicialInputView = {
    'clientes-inadimplentes-regular': 'Data Emissão Inicial',
    'clientes-inadimplentes-irregular': 'Data Emissão Inicial',
    'clientes-inadimplentes': 'Data Emissão Inicial',
    'contratos-por-cliente': 'Data Liberação Inicial',
  };

  dataFinalInputView = {
    'clientes-inadimplentes-regular': 'Data Emissão Final',
    'clientes-inadimplentes-irregular': 'Data Emissão Final',
    'clientes-inadimplentes': 'Data Emissão Final',
    'contratos-por-cliente': 'Data Liberação Final',
  };

  dataTypeEnderecoClienteInativos = false;
  opcaoSelecionadaEnderecoClienteInativos: any;
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortBoleto = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;

  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  public usuario = JSON.parse(sessionStorage.getItem('funcionario'));
  myDateFilterSemanal = (m: Moment | null): boolean => {
    const day = (m || moment()).day();
    return day === 1;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PeriodReportComponent>,
    private contractsService: ContractsService,
    private postoService: PostsService,
    private reportService: ReportService,
    private clienteService: ClienteService,
    private rotaService: RotasService,
    private tansferenciaService: TransferenciaService,
    private utilsService: UtilsService,
    private rotasService: RotasService,
    private parcelaService: CobrancasService,
    private folhaCobrancaService: FolhaCobrancaService,
    private transferenciaService: TransferenciaService,
    private teamService: TeamService,
  ) {}

  ngOnInit(): void {
      console.log(this.usuario);
    this.dataTypePosto =
      this._data.type === 'novo-ficha-cadastral' ||
      this._data.type === 'imprimir-dia-retroativo' ||
      this._data.type === 'processar-todas-folhas-cobrancas';

    this.dataTypeBoleto = this._data.type === 'contrato-por-periodo' || this._data.type === 'clientes-inativos-por-periodo';

    this.dataTypeDataDe =
      this._data.type === 'contrato-por-periodo' ||
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'relatorio-titulo' ||
      this._data.type === 'relatorio-titulo-geral' ||
      this._data.type === 'clientes-inativos-por-periodo' ||
      this._data.type === 'relatorio-comissao' ||
      this._data.type === 'faltas-e-sobras' ||
      this._data.type === 'repasses' ||
      this._data.type === 'reavaliacao-cadastral-clientes' ||
      this._data.type === 'contratos-por-cliente' ||
      this._data.type === 'relatorio-despesas' ||
      this._data.type === 'imprimir-dia-retroativo' ||
      this._data.type === 'sig-repasses-diarios' ||
      this._data.type === 'processar-todas-folhas-cobrancas';

    this.dataTypeDataAte =
      this._data.type === 'contrato-por-periodo' ||
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'relatorio-titulo' ||
      this._data.type === 'relatorio-titulo-geral' ||
      this._data.type === 'clientes-inativos-por-periodo' ||
      this._data.type === 'relatorio-comissao' ||
      this._data.type === 'faltas-e-sobras' ||
      this._data.type === 'repasses' ||
      this._data.type === 'reavaliacao-cadastral-clientes' ||
      this._data.type === 'contratos-por-cliente' ||
      this._data.type === 'relatorio-despesas';

    this.dataTypeRota =
      this._data.type === 'clientes-inativos-por-periodo' ||
      this._data.type === 'contrato-por-periodo' ||
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'faltas-e-sobras' ||
      this._data.type === 'repasses' ||
      this._data.type === 'cobrancas-semanal' ||
      this._data.type === 'relatorio-despesas' ||
      this._data.type === 'sig-repasses-diarios' ||
      this._data.type === 'clientes-aproximacao-de-liquidacao' ||
      this._data.type === 'contratos-por-cliente';

    this.dataTypeRotaEquipe = this._data.type === 'relatorio-comissao';

    this.dataTypeEquipe = this._data.type === 'relatorio-comissao';

    this.dataTypeInputMinimo =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'contratos-por-cliente' ||
      this._data.type === 'clientes-aproximacao-de-liquidacao';

    this.dataTypeInputMaximo =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'contratos-por-cliente';

    this.valorMinimoRequired =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-aproximacao-de-liquidacao';

    this.valorMaximoRequired =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular';

    this.dataInicialRequired =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'faltas-e-sobras' ||
      this._data.type === 'repasses' ||
      this._data.type === 'reavaliacao-cadastral-clientes';

    this.dataFinalRequired =
      this._data.type === 'clientes-inadimplentes' ||
      this._data.type === 'clientes-inadimplentes-irregular' ||
      this._data.type === 'clientes-inadimplentes-regular' ||
      this._data.type === 'faltas-e-sobras' ||
      this._data.type === 'repasses' ||
      this._data.type === 'reavaliacao-cadastral-clientes';

    this.opcoesRequired = this._data.type === 'reavaliacao-cadastral-clientes' || this._data.type === 'novo-ficha-cadastral';

    this.rotaRequired = this._data.type === 'faltas-e-sobras' || this._data.type === 'repasses';

    this.rotaEquipeRequired = this._data.type === 'comissao';

    this.dataTypeEnderecoClienteInativos = this._data.type === 'clientes-inativos-por-periodo';

    this.dataTypeSemanal = this._data.type === 'cobrancas-semanal';

    this.dataSemanalRequired = this._data.type === 'cobrancas-semanal';

    this.dataTypeOption = this._data.type === 'reavaliacao-cadastral-clientes';

    this.dataTypeCliente = this._data.type === 'contratos-por-cliente';

    this.dataTypeFicha = this._data.type === 'novo-ficha-cadastral';

    this.inputTextLabel = this._data.inputTextLabel;

    this.title = this._data.tittle;

    if (this.usuario.idPosto == null) {
      this.postoService.listarPostos().subscribe((data) => {
        this.postos = data.object;
      });
    }

    if (this.usuario.idPosto != null) {
      this.postoId = this.usuario.idPosto;

      // lista os postos
      this.postoService.listarPostos().subscribe((data) => {
        this.postos = data.object;
      });

      // seleciona o posto que esta logado
      this.postoService.findById(this.usuario.idPosto).subscribe((data) => {
        this.currentPosto = data.content;
        this.displayFnPosto(data.object);
      });

      this.rotaService.findByIdPosto(parseInt(this.postoId)).subscribe((data) => {
        this.rotas = data.object;
      });
    }
  }

  public onSave(): any {
    const datas = this.dataInicial + ',' + this.dataFinal;

    let rota;

    if (this.currentRota !== undefined) {
      rota = this.currentRota.descricao;
      if (this.currentRota.descricao === 'Todas as rotas' || this.currentPosto === 0) {
        rota = '';
      }
    }

    switch (this._data.type) {
      case 'contrato-por-periodo':
        // tslint:disable-next-line:triple-equals
        const tipo_contrato_periodo = this.tipoContrato == '0' ? false : true;
        this.contractsService
          .getRelatorioData(datas, tipo_contrato_periodo, this.currentPosto.id, rota, this.usuario.idUsuario)
          .subscribe((ondata) => {
            const objectURL = URL.createObjectURL(ondata);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'relatorio-comissao':
        if (this.currentPosto.id === 0) {
          this.utilsService.feedbackInfo('Favor selecionar um posto!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
          break;
        }

        this.reportService
          .getRelatorioComissao(datas, this.currentPosto.id, this.currentEquipe.id, this.currentRotaEquipe.id, this.usuario.idUsuario)
          .subscribe((ondata) => {
            const objectURL = URL.createObjectURL(ondata);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'relatorio-titulo':
        this.reportService.getRelatorioTitulos(datas, this.postoId).subscribe((ondata) => {
          const objectURL = URL.createObjectURL(ondata);
          window.open(objectURL, '_blank').print();
        });
        this.cancel();
        break;

      case 'relatorio-titulo-geral':
        this.reportService.getRelatorioTitulosGeral(datas, this.postoId).subscribe((ondata) => {
          const objectURL = URL.createObjectURL(ondata);
          window.open(objectURL, '_blank').print();
        });
        this.cancel();
        break;

      case 'period-filter':
        this.cancel();
        break;

      case 'clientes-inativos-por-periodo':
        if (this.currentPosto.id === 0) {
          this.utilsService.feedbackInfo('Favor selecionar um posto!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
          break;
        }
        // tslint:disable-next-line:triple-equals
        const tipo_contrato_inativo = this.tipoContrato == '0' ? false : true;
        this.clienteService
          .relatorioInativos(
            datas,
            tipo_contrato_inativo,
            this.currentPosto.id,
            rota,
            this.usuario.idUsuario,
            this.opcaoSelecionadaEnderecoClienteInativos.id,
          )
          .subscribe((data) => {
            const objectURL = URL.createObjectURL(data);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'clientes-inadimplentes':
        // tslint:disable-next-line:triple-equals
        const tipo_contrato_inadimplentes = this.tipoContrato == '0' ? false : true;
        this.contractsService
          .relatorioInadimplenciaBoleto(
            datas,
            tipo_contrato_inadimplentes,
            this.currentPosto.id,
            rota,
            this.usuario.idUsuario,
            this.valorMinimoBusca,
            this.valorMaximoBusca,
            false,
            false,
          )
          .subscribe((data) => {
            const objectURL = URL.createObjectURL(data);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'clientes-inadimplentes-irregular':
        // tslint:disable-next-line:triple-equals
        const tipo_contrato_inadimplentes_irregular = this.tipoContrato == '0' ? false : true;
        this.contractsService
          .relatorioInadimplenciaBoleto(
            datas,
            tipo_contrato_inadimplentes_irregular,
            this.currentPosto.id,
            rota,
            this.usuario.idUsuario,
            this.valorMinimoBusca,
            this.valorMaximoBusca,
            true,
            false,
          )
          .subscribe((data) => {
            const objectURL = URL.createObjectURL(data);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'clientes-inadimplentes-regular':
        // tslint:disable-next-line:triple-equals
        const tipo_contrato_inadimplentes_regular = this.tipoContrato == '0' ? false : true;
        this.contractsService
          .relatorioInadimplenciaBoleto(
            datas,
            tipo_contrato_inadimplentes_regular,
            this.currentPosto.id,
            rota,
            this.usuario.idUsuario,
            this.valorMinimoBusca,
            this.valorMaximoBusca,
            false,
            true,
          )
          .subscribe((data) => {
            const objectURL = URL.createObjectURL(data);
            window.open(objectURL, '_blank').print();
          });
        this.cancel();
        break;

      case 'clientes-aproximacao-de-liquidacao':
        this.contractsService
          .relatorioAproximacaoDeLiquidacao(this.currentPosto.id, rota, this.valorMinimoBusca, this.usuario.id)
          .subscribe((data) => {
            const objectUrl = URL.createObjectURL(data);
            window.open(objectUrl, '_blank');
          });
        break;

      case 'faltas-e-sobras':
        if (this.currentPosto.id === 0) {
          this.utilsService.feedbackInfo('Favor selecionar um posto!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
          break;
        }

        this.tansferenciaService
          .relatorioFaltasSobras(datas, this.currentPosto.id, rota, this.usuario.idUsuario)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_faltas_sobras_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'repasses':
        this.contractsService
          .relatorioRepassesRealizadas(datas, this.currentPosto.id, rota, this.usuario.idUsuario)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_repasses_realizadas_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'cobrancas-semanal':
        const postoPattern = this.currentPosto.id === 0 ? '' : `posto=${this.currentPosto.id}`;
        const rotaPattern = rota === '' ? rota : `rota=${rota}`;
        this.parcelaService
          .relatorioCobrancasRealizadas(this.dataInicialSemanal, postoPattern, rotaPattern, this.usuario.idUsuario)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_cobrancas_realizadas_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'reavaliacao-cadastral-clientes':
        this.clienteService
          .relatorioRenovacaoCadastral(datas, this.currentPosto.id, this.usuario.idUsuario, this.opcaoSelecionadaSituacaoCadastral.id)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_renovacao_cadastral_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'contratos-por-cliente':
        if (this.valorMinimoBusca <= 0) {
          this.utilsService.feedbackInfo('Valor minimo deve ser maior que 0 (zero).', TimeMensagem.TIME_MENSAGEM);
          break;
        }
        if (this.valorMaximoBusca <= 0) {
          this.utilsService.feedbackInfo('Valor maximo deve ser maior que 0 (zero).', TimeMensagem.TIME_MENSAGEM);
          break;
        }
        this.contractsService
          .relatorioContratoPorCliente(
            datas,
            this.currentPosto.id,
            this.usuario.idUsuario,
            this.opcaoSelecionadaTipoCliente.id,
            this.valorMinimoBusca,
            this.valorMaximoBusca,
            rota,
          )
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_contratos_por_cliente_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'fiadores':
        this.clienteService.relatorioClientesPorFiador().subscribe((folha: Blob | any) => {
          if (folha) {
            const hoje = new Date();
            // tslint:disable-next-line: max-line-length
            FileSaver.saveAs(
              folha,
              `Relatorio_Fiadores_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}${hoje.getFullYear()}.pdf`,
            );
          }
        });
        this.cancel();
        break;

      case 'imprimir-dia-retroativo':
        this.loading = true;
        this.folhaCobrancaService.imprimirDiaRetroativo(this.dataInicial, this.usuario.idUsuario).subscribe((folha: Blob | any) => {
          if (folha) {
            const hoje = new Date();
            // tslint:disable-next-line: max-line-length
            FileSaver.saveAs(
              folha,
              `Folha_Cobranca_retroativa_posto_${this.usuario.nomePosto}${('00' + hoje.getDate()).slice(-2)}${(
                '00' +
                (hoje.getMonth() + 1)
              ).slice(-2)}${hoje.getFullYear()}.zip`,
            );
            this.loading = false;
            this.cancel();
          }
        });

        break;

      case 'processar-todas-folhas-cobrancas':
        this.loading = true;
        this.folhaCobrancaService.processarTodas(this.dataInicial).subscribe((res) => {
          if (res.success) {
            this.utilsService.feedbackSuccess('Folhas processadas com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
            this.loading = false;
            this.cancel();
          } else {
            if (res.messages[0].text != null) {
              this.utilsService.feedbackInfo(res.messages[0].text, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
            } else {
              this.utilsService.feedbackError('Erro! Entre em contato com o suporte!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
            }
            this.loading = false;
            this.cancel();
          }
        });
        break;

      case 'relatorio-despesas':
        this.transferenciaService
          .imprimirRelatorioDespesas(this.currentPosto.id, rota, datas, this.usuario.idUsuario)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_de_despesas_${this.currentPosto.id}${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'sig-repasses-diarios':
        this.contractsService
          .imprimirRelatorioSIGRepasses(this.currentPosto.id, rota, this.dataInicial, this.usuario.idUsuario)
          .subscribe((folha: Blob | any) => {
            if (folha) {
              const hoje = new Date();
              // tslint:disable-next-line: max-line-length
              FileSaver.saveAs(
                folha,
                `Relatorio_de_prepasses_${this.currentPosto.id}${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(
                  -2,
                )}${hoje.getFullYear()}.pdf`,
              );
            }
          });
        this.cancel();
        break;

      case 'novo-ficha-cadastral':
        this.reportService.getFichaCadastral(this.opcaoSelecionadaTipoCliente, this.inputText).subscribe((ondata) => {
          const objectURL = URL.createObjectURL(ondata);
          window.open(objectURL, '_blank').print();
        });
        this.cancel();
        break;
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public serviceOnSelectPosto(posto: any): void {
    if (posto.id === 0) {
      this.currentRota = { id: 0, descricao: 'Todas as rotas' };
      this.currentRotaEquipe = { id: 0, descricao: 'Todas as Equipes' };
      this.rotaRequired = true;
      this.disabledRota = true;
    } else {
      this.currentRotaEquipe = { id: 0, descricao: 'Todas as Rotas' };
      this.currentRota = { id: 0, descricao: 'Todas as rotas' };
      this.rotaRequired = true;
      this.currentPosto = posto;
      this.disabledRota = false;

      this.rotasService.findByIdPosto(posto.id).subscribe((data) => {
        this.rotas = data.object;
      });

      this.teamService.pagePosPosto(10, 1, this.currentPosto.id).subscribe((data) => {
        this.equipes = data.object.content;
      });
    }
  }

  serviceOnSelectRota(rota: any): any {
    this.currentRota = rota;
  }

  serviceOnSelectRotaEquipe(rotaEquipe: any): any {
    this.currentRotaEquipe = rotaEquipe;
  }

  serviceOnSelectEquipe(equipe: any): any {
    this.currentEquipe = equipe;

    this.rotaService.findByIdEquipe(equipe.id).subscribe((data) => {
      this.rotasEquipe = data.object;
    });
  }

  displayFnRota(rota: { id: number; descricao: string }): string {
    return rota ? rota.descricao : 'Selecione...';
  }

  displayFnRotaEquipe(rotaEquipe: { id: number; descricao: string }): string {
    return rotaEquipe ? rotaEquipe.descricao : 'Selecione...';
  }

  displayFnPosto(posto: Posto): any {
    return posto ? posto.descricao : 'Selecione...';
  }
  displayFnEquipe(equipe: Team): any {
    return equipe ? equipe.descricao : 'Selecione...';
  }

  serviceOnSelectSituacaoCadastral(value: any): any {}

  displayFnSituacaoCadastral(opcao: any): string {
    return opcao ? opcao.descricao : 'Selecione...';
  }

  serviceOnSelectTipoCliente(value: any): any {}

  displayFnTipoCliente(opcao: any): string {
    return opcao ? opcao.descricao : 'Selecione...';
  }

  displayEnderecoClienteInativos(opcao: any): string {
    return opcao ? opcao.descricao : 'Selecione...';
  }

  serviceOnSelectEnderecoClienteInativos(opcao: any): any {}
}
