import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-generated-sidebar',
  templateUrl: './generated-sidebar.component.html',
  styleUrls: ['./generated-sidebar.component.scss']
})
export class GeneratedSidebarComponent implements OnInit, OnChanges {


  filterBy: string;
  all: boolean = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean
  filter: string;
  inputDisabled = false;
  search = '';
  placeholder: string;
  situacao: string;

  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Input()  searchInput;

  constructor(private contractService: ContractsService) {
    this.situacao = "NOVO,RENOVADO,GERADO"
    this.placeholder = "Buscar por Nome Busca Cliente"
    this.filter = 'HOJE'
  }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.searchInput == null){
      this.search = '';
    }
  }


  //Seleção de filtro
  getFilter(filter) {
    switch (filter) {
      case 'NOVO,RENOVADO,GERADO':
        this.filterEvent.emit('NOVO,RENOVADO,GERADO');
        this.situacao = "NOVO,RENOVADO,GERADO"
        this.filter = 'NOME_CLIENTE'
        this.inputDisabled = false;
        this.placeholder = "Buscar por Cliente";
        this.situacaoEvent.emit(this.situacao)
        break;
      case 'HOJE':
        this.filterEvent.emit('HOJE');
        this.situacao = "NOVO,RENOVADO,GERADO"
        this.filter = 'HOJE'
        this.inputDisabled = false;
        this.placeholder = "Buscar por Nome Busca Cliente";
        this.situacaoEvent.emit(this.situacao)
        break;
      case 'RENOVADO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = "Selecione um filtro"
        this.filterEvent.emit("RENOVADO");
        this.situacao = "RENOVADO"
        this.situacaoEvent.emit(this.situacao)
        break;
      case 'DOCUMENTO_CLIENTE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Documento Cliente";
        this.filterEvent.emit('DOCUMENTO_CLIENTE');
        break
      case 'NOME_CLIENTE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Cliente";
        this.filterEvent.emit('NOME_CLIENTE');
        break
      case 'NOME_BUSCA':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Nome Busca Cliente";
        this.filterEvent.emit('NOME_BUSCA');
        break
      case 'RESPONSAVEL_CONTRATO':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Responsável Contrato";
        this.filterEvent.emit('RESPONSAVEL_CONTRATO');
        break
      case 'RESPONSAVEL_REPASSE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Responsável Repasse";
        this.filterEvent.emit('RESPONSAVEL_REPASSE');
        break
      case 'ROTA':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Rota";
        this.filterEvent.emit('ROTA');
        break
    }
  }

  //Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any) {
    this.searchEvent.emit(this.search)
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByNomeCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
      case 'NOME_BUSCA':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
      case 'RESPONSAVEL_CONTRATO':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
      case 'RESPONSAVEL_REPASSE':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
      case 'ROTA':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
      case 'HOJE':
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
            this.data.emit(res.data.findAllPageable)
          })
        } else {
          if (this.search.length > 2 || event.code == "Enter") {
            this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, 1, new Date().getTime(), new Date().getTime()).subscribe(data => {
              this.data.emit(data.object)
            })
          }
        }
        break
    }
  };
}
