import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormControl } from '@angular/forms';
import { ContratoLancamento } from 'app/main/shared/model/contrato-lancamento/contrato-lancamento.model';
declare var jsPDF: any;

@Component({
  selector: 'app-canceled-lancamento-list',
  templateUrl: './canceled-lancamentos-list.component.html',
  styleUrls: ['./canceled-lancamentos-list.component.scss'],
  animations: fuseAnimations
})
export class CanceledLancamentoListComponent implements OnInit {

  contrato: any;
  parcelas: Parcela[];
  lancamentos: ContratoLancamento[];
  searchInput: FormControl;
  filter;
  timeout: any;
  selected: Array<any>;
  dialogMessages = [];
  count: number = 0;
  public loading = false;
  valorTotal: number = 0;
  valorAReceber: number = 0;
  selectedClear: boolean;
  valorExcedente: number = 0;
  valorEspecie: number = 0;
  valorJuros: number = 0;
  showExcedente: boolean = false;
  valorPago: number = 0;
  showJuros: boolean = false;
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  permissoes = [];
  
  constructor(private contratoService: ContractsService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<Parcela>();

    const perm = JSON.parse(sessionStorage.getItem('permissoes'))
    perm.forEach(element => {
      this.permissoes.push(element)
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {
    this.listById();
  }

  listById() {
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object;
      this.parcelas = data.object.listParcela.sort((a, b) => a.ordem - b.ordem);
      this.parcelas.filter(p => p.agendamento == true ).forEach(k => {
        this.valorEspecie = this.valorEspecie + k.valorPago;
      })
      if (this.contrato.situacaoContrato == 'FINALIZADO' || this.contrato.situacaoContrato == 'FINALIZADO_COM_DIVIDA'
        || this.contrato.situacaoContrato == 'FINALIZADO_COM_ATRASO' || this.contrato.situacaoContrato == 'FINALIZADO_COM_ANTECIPACAO'
        || this.contrato.situacaoContrato == 'ANTECIPADO_BORDERO') {
        this.showExcedente = true;
      }
      if (this.contrato.situacaoContrato == 'FINALIZADO_COM_ATRASO' || this.contrato.situacaoContrato == 'ANTECIPADO_BORDERO') {
        this.showJuros = true;
      }
      this.parcelas.forEach(k => {
        this.valorPago = this.valorPago + k.valorPago;
      })
      this.valorJuros = this.valorPago - (this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros))
      
    })
    this.contratoService.getLancamentos(this.route.snapshot.params['id']).subscribe(data => {
      this.lancamentos = data.object;

      this.lancamentos.forEach(data => {
        this.valorTotal = this.valorTotal + data.valorLancamento
      })

      this.valorExcedente = this.valorTotal - (this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros)) - this.valorJuros + this.valorEspecie + this.contrato.valorExcedenteAnterior;

      if (this.valorExcedente < 0) {
        this.valorExcedente = 0;  
      }
    })
  }


  over() {
    this.loading = true;
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object
    })
    this.contratoService.getLancamentos(this.route.snapshot.params['id']).subscribe(data => {
      this.lancamentos = data.object;
    })
  }

  ngOnChanges() {
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }


  gerarPDF() {
    let rows = []
    let dataFinalizacao = new Date(this.contrato.dataFinalizacao);
    let dataVencimento = new Date(this.contrato.dataVencimento);
    let valorExcedenteAnterior = this.contrato.valorExcedenteAnterior != null ? this.contrato.valorExcedenteAnterior : 0;
    let hoje = new Date();
    let doc = new jsPDF('p', 'pt', 'a4');

    doc.setFontSize(11);
    doc.text(7, 20, `Emissão: ${hoje.toLocaleDateString('pt-br')} Hora: ${hoje.toLocaleTimeString('pt-br').slice(0, 5)}`);
    doc.setFontSize(9);
    doc.text(7, 38, `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, Titulo: ${this.contrato.id}`);
    doc.setFontSize(9);
    doc.text(7, 48, `Valor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
    doc.setFontSize(9);
    doc.text(7, 58, `Vencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}, Situação: ${this.contrato.situacaoContrato}`)
    doc.setFontSize(9);
    doc.text(7, 68, `Finalização do Contrato: ${this.contrato.dataFinalizacao != null ?  dataFinalizacao.toLocaleDateString('pt-br') : '-'}`)
    doc.setFontSize(11);
    doc.text(7, 84, `Resumo do pagamento:`);
    doc.setFontSize(9);
    doc.text(7, 96, `Valor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
    doc.setFontSize(9);
    doc.text(7, 106, `Valor Excedente do Contrato Anterior: ${valorExcedenteAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
    doc.setFontSize(9);
    doc.text(7, 116, `Valor pago maquineta: ${this.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
    doc.setFontSize(9);
    doc.text(7, 126, `Valor pago em espécie: ${this.valorEspecie.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` );
    doc.setFontSize(9);
    doc.text(7, 136, `Valor juros: ${this.valorJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` );
    doc.setFontSize(9);
    doc.text(7, 146, `Valor excedente: ${this.valorExcedente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` );

    //Keys
    let columns = [
      { title: "Lançamento", dataKey: "dataVencimento" },
      { title: "Valor do Lançamento", dataKey: "valorLancamento" },
    ];


    //Objeto criado para utilização da tablea
    this.lancamentos.forEach(element => {
      let date = new Date(element.dataVencimento)
      let data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valorLancamento: element.valorLancamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      }
      rows.push(data)
    })

    //Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: "striped",
      margin: { horizontal: 7, top: 20 },
      startY: 0.19*doc.internal.pageSize.height,
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
    });

    doc.save(`acompanhamento-lancamentos.pdf`);
  }
}
