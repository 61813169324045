import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-mudar-situacao-contrato',
  templateUrl: './mudar-situacao-contrato.component.html',
  styleUrls: ['./mudar-situacao-contrato.component.scss']
})
export class MudarSituacaoContratoComponent implements OnInit {

  /**
   * Constructor
   *
   * @param {MatDialogRef<MudarSituacaoContratoComponent>} matDialogRef
   * @param _data
   */
  disabledButton:boolean;
  constructor(
    public matDialogRef: MatDialogRef<MudarSituacaoContratoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(data?): void {
    if (data) {
      data.contrato.situacaoContrato = 'PENDENTE'
    }
    this.disabledButton = true;  
    this.matDialogRef.close(data);
  }

}
