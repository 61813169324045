import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';
declare var jsPDF: any;

import * as FileSaver from 'file-saver';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { AgendarParcelaComponent } from 'app/main/shared/components/agendar-parcela/agendar-parcela.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlterarResponsavelContratosComponent } from 'app/main/shared/components/alterar-responsavel-contratos/alterar-responsavel-contratos.component';

@Component({
  selector: 'app-in-progress-detail',
  templateUrl: './in-progress-detail.component.html',
  styleUrls: ['./in-progress-detail.component.scss'],
  animations: fuseAnimations
})
export class InProgressDetailComponent implements OnInit {

  contrato: any;
  parcelas: Parcela[];
  parcelasCalculo: Parcela[]; 
  itensAPI: Parcela[];
  searchInput: FormControl;
  interval;
  filter;
  selected: Array<any>;
  dialogMessages;
  count =  0;
  public loading = false;
  valorPago = 0;
  valorPagoParcela = 0;
  valorAReceber = 0;
  selectedClear: boolean;
  valorParcela = 0;
  valorExcedente = 0;
  permissoes = [];
  resumoContrato: string;
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  resumoPagamento: string;
  constructor(private contratoService: ContractsService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private cobrancaService: CobrancasService,
    private contractService: ContractsService,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<Parcela>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void {
    this.listById();
  }

  clearSelectBox(): void {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  listById(): void {
    this.loading = true;
    this.valorPagoParcela = 0;
    this.valorAReceber = 0;
    this.valorParcela = 0;
    this.valorPago = 0;
    this.valorExcedente = 0;
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object;
      this.parcelas = data.object.listParcela.sort((a, b) => a.ordem - b.ordem);
      // tslint:disable-next-line:triple-equals
      this.parcelasCalculo = this.parcelas.filter(p => p.situacaoParcela != 'CANCELADA');

      this.itensAPI = data.object.listParcela;
      // tslint:disable-next-line:triple-equals
      this.parcelasCalculo.filter(p => p.situacaoParcela == 'GERADA' || p.situacaoParcela == 'COBRADA' || p.situacaoParcela == 'CONFIRMADA').forEach(k => {
        this.valorParcela = this.valorParcela + k.valor;
        this.valorPagoParcela = this.valorPagoParcela + k.valorPago;

        if (this.parcelas.length == this.itensAPI.length) {
          this.valorAReceber += k.valor - k.valorPago ;
        } else {
          this.valorAReceber = k.valor;
        }
      });

      this.parcelasCalculo.forEach(k => {
        this.valorPago = this.valorPago + k.valorPago;
      });
      this.contrato.valorContrato = this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros);
      this.valorExcedente = this.contrato.valorExcedenteAnterior ? this.contrato.valorExcedenteAnterior : 0;
      this.valorPago = this.valorPago - this.valorExcedente;
         this.loading = false;
    });
  }


  over(): void {

    this.listById();

    this.clearSelectBox();
  }


  onSelect(visit): void {
    this.selected = visit;
  }

  getEmitter(event): void {
    this.parcelas = event;
    this.itensAPI = event;
    this.searchInput.setValue('');
  }

  getSelected(selected: any): void {
    this.selected = selected;
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  mudarResponsavel(cobranca: any): void {
    this.count = 0;
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'new',
        cobranca: this.contrato
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {

          this.selected.forEach(parcela => {
            parcela.responsavel = data.funcionario;
            this.cobrancaService.alterarResponsavel(parcela).subscribe((result) => {
              if (result.success) {
                this.count++;
                // tslint:disable-next-line:triple-equals
                if (this.count == this.selected.length) {
                  this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `${result.messages[0].text}`
                  };
                  this.clearSelectBox();
                  this.feedback(this.dialogMessages);
                  this.over();
                }
              } else {
                this.count++;
                // tslint:disable-next-line:triple-equals
                if (this.count == this.selected.length) {
                  this.dialogMessages = {
                    type: 'info',
                    title: 'Informação!',
                    text: `${result.messages[0].text}`
                  };
                  this.over();
                  this.clearSelectBox();
                  this.feedback(this.dialogMessages);
                }
              }
            });
          });
        }
      });
  }


  previewContract(): void {
    this.contractService.getContract(this.route.snapshot.params['id']).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  alterarContrato(): void {
    this._matDialog.open(AlterarContratoFormComponent, {
      data: { 
        contrato : this.contrato,
        action: 'alterContrato'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.over();
      }
    });
  }

  transferirResponsavelDeTodosOsContratos(): void {
    this._matDialog.open(AlterarResponsavelContratosComponent, {
      data: { 
        contrato : this.contrato,
        action: 'alterContrato'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.over();
      }
    });
  }


  mudarResponsavelContrato(cobranca: any): void {
    const contratos = [];
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'new',
        cobranca: cobranca
      }
    }).afterClosed().subscribe(
      data => {
        if (data.funcionario) {
          this.contrato.responsavel = data.funcionario;
          contratos.push(this.contrato);

          const object = {
            contratos: contratos
          };
          this.contractService.transferirContratos(object).subscribe((rest) => {
            if (rest.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Registro atualizado com sucesso`
              };
              this.feedback(this.dialogMessages);
              this.over();
            } else {
              if (rest.message != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${rest.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
                this.feedback(this.dialogMessages);
                this.over();
              }
            }
          });
        }
      });
  }

  alterarParcela(value): void {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: { parcela: value },
        caixa: true,
        action: 'alter'
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.over();
      }
    });
  }

  receberParcela(value): void {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: { parcela: value },
        contrato: true,
        action: 'save'

      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.over();
      }
    });
  }

  confirmarParcela(parcela): void {
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: 'Deseja confirmar esta parcela?',
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'
    };
    this._matDialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {
        this.cobrancaService.confimarParcela(parcela).subscribe((result) => {
          if (result.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${result.messages[0].text}`
            };
            this.feedback(this.dialogMessages);
            this.over();
          } else {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${result.messages[0].text}`
            };
            this.feedback(this.dialogMessages);
            this.over();
          }
        });
      }
    });
  }


  getOver(event): void {
    this.over();
  }

  gerarPDF(): void {
    let rows = [];
    const dataEmissao = new Date();
    const dataLiberacao = new Date(this.contrato.dataLiberacao);
    const dataVencimento = new Date(this.contrato.dataVencimento);
    const doc = new jsPDF('p', 'pt');
    if (this.fortCartao) {
      // tslint:disable-next-line:max-line-length
      this.resumoContrato = `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, N°: ${this.contrato.id}\nData de Liberação: ${dataLiberacao.toLocaleDateString('pt-br')}\nValor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor a receber: ${this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nVencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}\nSituação: ${this.contrato.situacaoContrato}\nProduto: ${this.contrato.visita.produto ? this.contrato.visita.produto.nome : 'Nenhum produto adicionado'}`;
    } else {
      // tslint:disable-next-line:max-line-length
      this.resumoContrato = `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, N°: ${this.contrato.id}\nData de Liberação: ${dataLiberacao.toLocaleDateString('pt-br')}\nValor do Contrato: ${this.contrato.visita.valorAprovado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor a receber: ${this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nVencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}\nSituação do Contrato: ${this.contrato.situacaoContrato}\nSituação do Cliente: ${this.contrato.visita.cliente.situacaoCliente}\nProduto: ${this.contrato.visita.produto ? this.contrato.visita.produto.nome : 'Nenhum produto adicionado'}\nValor Pago: ${this.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`;
    }
    doc.setFontSize(11);
    doc.text(12, 20, `Emissão: ${dataEmissao.toLocaleDateString('pt-br')} Hora: ${dataEmissao.toLocaleTimeString('pt-br').slice(0, 5)}`);

  

    const colunas = [
      { title: 'Resumo contrato', dataKey: 'resumoContrato' },
    ];

    doc.autoTable(colunas, rows = [{
      resumoContrato: this.resumoContrato,
    }], {
      theme: 'plain',
      margin: { horizontal: 7, top: 20 },
      bodyStyles: { valign: 'top' },
    });



    // Keys
    const columns = [
      { title: 'Parcela', dataKey: 'ordem' },
      { title: 'Responsável', dataKey: 'responsavel' },
      { title: 'Vencimento', dataKey: 'dataVencimento' },
      { title: 'Valor da Parcela', dataKey: 'valor' },
      { title: 'Valor Recebido', dataKey: 'valorPago' },
    ];


    // Objeto criado para utilização da tablea
    this.parcelas.forEach(element => {
      const date = new Date(element.dataVencimento);
      const data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valor: element.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorPago: element.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        saldoParcela: (element.valor - element.valorPago).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        responsavel: element.responsavel.pessoa.nomePessoa,
        ordem: element.ordem,
        situacaoParcela: element.situacaoParcela
      };
      rows.push(data);
    });

    // Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: 'striped',
      margin: { horizontal: 7, top: 40 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
    });

    doc.save('acompanhamento-cobranças.pdf');
  }


  baixarBoleto(parcelaId: number): void {
    const observable = this.contractService.baixarBoleto(parcelaId);
    observable.subscribe((file: Blob) => {
      FileSaver.saveAs(file, 'Boleto.pdf');
    });
  }
}
