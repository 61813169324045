import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatDialog, MatRadioChange } from '@angular/material';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { Parcela } from '../../model/parcela/parcela.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Mask } from '@fagnerlima/ng-mask';
import { ContractsService } from 'app/main/contracts/services/contracts.service';

@Component({
  selector: 'app-renegociacao-form',
  templateUrl: './renegociacao-form.component.html',
  styleUrls: ['./renegociacao-form.component.scss']
})
export class RenegociacaoComponent implements OnInit {

  form: FormGroup;
  parcelas: Parcela[];
  dialogMessages;
  maskQtdParcela = new Mask('000')


  public showValor = false;
  public showQuantidadeParcela = false;

  public ParcelaControl: FormControl = new FormControl();
  public filteredParcelas: ReplaySubject<Parcela[]> = new ReplaySubject<Parcela[]>(1);

  constructor(private cobrancaService: CobrancasService,
    private contratoService: ContractsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RenegociacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog) {
    this.parcelas = this._data.contrato.listParcela;
  }


  private _onDestroy = new Subject<void>();

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      tipoAgendamento: [null, Validators.required],
      quantidadeParcela: [null, Validators.required],
      periodicidadeParcela: ['DIARIA', Validators.required]
    });
  }

  public onSelectParcela(matSelectChange: MatSelectChange): void {
    this.form.patchValue({
      antecipacao: matSelectChange.value.dataAgendamento
    });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public calcularJuros(value: any): void { 
    this.contratoService.renegociar(this._data.contrato.id, value.periodicidadeParcela, value.quantidadeParcela, true).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `O valor a pagar do contrato: R$ ${data.object}`
        };

        this.feedback(this.dialogMessages);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    })
  }

  public onSave(value: any): void {
    this.contratoService.renegociar(this._data.contrato.id, value.periodicidadeParcela, value.quantidadeParcela, false).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Contrato prorrogado com sucesso!`
        };

        this.feedback(this.dialogMessages);
        this.dialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  public dateChange(event: any): void {

    this.showValor = event.value
      .add(1, 'd')
      .isBefore(new Date());

    const valorPago = this.form.get('valorPago');

    if (this.showValor) {
      valorPago.setValidators([Validators.required]);
    } else {
      valorPago.setValidators([]);
    }

    valorPago.reset();
    valorPago.updateValueAndValidity();
  }
}
