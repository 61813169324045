import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule, MatStepperModule } from '@angular/material';

import { ContractsRoutingModule } from './routes/contracts.routing';
import { GeneratedModule } from './components/generated/generated.module';
import { InProgressModule } from './components/in-progress/in-progress.module';
import { CanceledModule } from './components/canceled/canceled.module';
import { ReleasedModule } from './components/released/released.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToCancelModule } from './components/to-cancel/to-cancel.module';
import {ReagendadosModule} from './components/reagendados/reagendados.module';
import { AcompanhamentoNovatosInativosModule } from './components/acompanhamento-novatos-inativos/acompanhamento-novatos-inativos.module';
import { AcompanhamentoContratosEntreguesModule } from './components/acompanhamento-contratos-entregues/acompanhamento-contratos-entregues.module';
import { PixModule } from './components/pix/pix.module';
import { BoletoModule } from './components/boleto/boleto.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatStepperModule,
    ContractsRoutingModule,
    GeneratedModule,
    ReleasedModule,
    InProgressModule,
    CanceledModule,
    ToCancelModule,
    PixModule,
    BoletoModule,
    AcompanhamentoNovatosInativosModule,
    ReagendadosModule,
    AcompanhamentoContratosEntreguesModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: []
})
export class ContractsModule { }
