import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Gallery} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {FormBuilder, FormGroup} from '@angular/forms';
import {VisitadosService} from '../../../../customers/components/visitados/services/visitados.services';
import {SGF_API} from '../../../../../shared/api/sgf.api';
import {IImage, ImageCompressService} from 'ng2-image-compress';
import {UtilsService} from '../../../../../shared/util/utils.service';
import {TimeMensagem} from '../../../model/enums/time-mensagem.enum';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

    @Input() id;
    @Input() reagendado: boolean;
    @Input() analisando: boolean;
    @Input() edit: boolean;
    @Input() contrato: boolean;

    files: FormGroup;
    referenciaArquivo = '';
    imageData: any[] = [];
    selected = false;
    images: Array<IImage> = [];
    urlPhoto = `${SGF_API}` + 'sgf/api/public/arquivovisita/findbycodigoincontext/';

    estabelecimentoPhotos: any[] = [];
    formularioPhotos: any[] = [];
    documentoPhotos: any[] = [];
    contratoPhotos: any[] = [];
    comprovantePhotos: any[] = [];
    selfiePhotos: any [] = [];
    selectedImage: any;
    processedImages: any = [];
    showTitle = false;


    @Output() eventEmitter: EventEmitter<number[]> = new EventEmitter();
    @Output() imagesEmitter: EventEmitter<any[]> = new EventEmitter();

    private qtdEstabelecimento = 7;
    private qtdFormulario = 5;
    private qtdDocumento = 5;
    private qtdContrato = 5;
    private qtdComprovante = 5;
    private qtdSelfie = 5;

    constructor(public gallery: Gallery,
                public lightbox: Lightbox,
                private formBuilder: FormBuilder,
                private visitadosService: VisitadosService,
                private utilService: UtilsService,
    ) {

    }

    ngOnInit(): void {


        this.files = this.formBuilder.group({
            inputField: ['']
        });


        if (this.reagendado || this.analisando || this.edit) {
            this.visitadosService.getCodeByVisit(this.id).subscribe(imgs => {
                let ref = '';
                imgs.object.forEach(data => {
                    ref = data.tipoReferenciaArquivoVisita;
                    // tslint:disable-next-line:triple-equals
                    if (ref == 'ESTABELECIMENTO') {
                        this.estabelecimentoPhotos.push(data);
                        // tslint:disable-next-line:triple-equals
                    } else if (ref == 'FORMULARIO') {
                        this.formularioPhotos.push(data);
                        // tslint:disable-next-line:triple-equals
                    } else if (ref == 'DOCUMENTO') {
                        this.documentoPhotos.push(data);
                        // tslint:disable-next-line:triple-equals
                    } else if (ref == 'CONTRATO') {
                        this.contratoPhotos.push(data);
                        // tslint:disable-next-line:triple-equals
                    } else if (ref == 'COMPROVANTE') {
                        this.comprovantePhotos.push(data);
                    } else if(ref == 'SELFIE'){
                        this.selfiePhotos.push(data);
                    }
                });

                this.eventEmitter.emit([
                    this.estabelecimentoPhotos.length,
                    this.formularioPhotos.length,
                    this.documentoPhotos.length,
                    this.contratoPhotos.length,
                    this.comprovantePhotos.length,
                    this.selfiePhotos.length
                ]);


                if (this.imageData != null || this.imageData !== [] || this.imageData.length > 0) {
                    this.imageData = [...this.estabelecimentoPhotos, ...this.formularioPhotos, ...this.documentoPhotos, ...this.contratoPhotos, ...this.comprovantePhotos, ...this.selfiePhotos];
                }


                this.imagesEmitter.emit(this.imageData);
            });
        }

    }

    changeImageRef(event, ref): void {
        this.referenciaArquivo = ref;
    }

    deletePhoto(data: any): void {
        this.deleteImageOnArray(data);
        this.files.get('inputField').setValue(null);
    }

    deleteRegistredPhoto(data: any): void {
        this.visitadosService.deleteFoto(data).subscribe(res => {
            if (res.sucess) {
                this.deleteImageOnArray(data);
                this.files.get('inputField').setValue(null);                
            }
        });
    }

    private deleteImageOnArray(data: any): void {
        const referencia = data.tipoReferenciaArquivoVisita;
        // tslint:disable-next-line:triple-equals
        if (referencia == 'ESTABELECIMENTO') {
            this.estabelecimentoPhotos.forEach(img => {
                if (data === img) {

                    const position = this.estabelecimentoPhotos.indexOf(data);
                    this.estabelecimentoPhotos.splice(position, 1);
                    this.estabelecimentoPhotos = [...this.estabelecimentoPhotos];
                    this.deleteOnImageData(data);
                }
            });
            // tslint:disable-next-line:triple-equals
        } else if (referencia == 'FORMULARIO') {
            this.formularioPhotos.forEach(img => {
                if (data === img) {
                    const position = this.formularioPhotos.indexOf(data);
                    this.formularioPhotos.splice(position, 1);
                    this.formularioPhotos = [...this.formularioPhotos];
                    this.deleteOnImageData(data);
                }
            });
            // tslint:disable-next-line:triple-equals
        } else if (referencia == 'DOCUMENTO') {
            this.documentoPhotos.forEach(img => {
                if (data === img) {
                    const position = this.documentoPhotos.indexOf(data);
                    this.documentoPhotos.splice(position, 1);
                    this.documentoPhotos = [...this.documentoPhotos];
                    this.deleteOnImageData(data);
                }
            });
            // tslint:disable-next-line:triple-equals
        } else if (referencia == 'CONTRATO') {
            this.contratoPhotos.forEach(img => {
                if (data === img) {
                    const position = this.contratoPhotos.indexOf(data);
                    this.contratoPhotos.splice(position, 1);
                    this.contratoPhotos = [...this.contratoPhotos];
                    this.deleteOnImageData(data);
                }
            });
            // tslint:disable-next-line:triple-equals
        } else if (referencia == 'COMPROVANTE') {
            this.comprovantePhotos.forEach(img => {
                if (data === img) {
                    const position = this.comprovantePhotos.indexOf(data);
                    this.comprovantePhotos.splice(position, 1);
                    this.comprovantePhotos = [...this.comprovantePhotos];
                    this.deleteOnImageData(data);
                }
            });
        } else if (referencia== 'SELFIE') {
            this.selfiePhotos.forEach(img => {
                if (data == img) {
                    const position = this.selfiePhotos.indexOf(data);
                    this.selfiePhotos.splice(position, 1);
                    this.selfiePhotos = [...this.selfiePhotos];
                    this.deleteOnImageData(data);
                }
            });
        }

        this.eventEmitter.emit([
            this.estabelecimentoPhotos.length,
            this.formularioPhotos.length,
            this.documentoPhotos.length,
            this.contratoPhotos.length,
            this.comprovantePhotos.length,
            this.selfiePhotos.length
        ]);
    }


    private deleteOnImageData(data: any): void {
        this.imageData.forEach(imagem => {
            if (data === imagem) {
                const position = this.estabelecimentoPhotos.indexOf(data);
                this.imageData.splice(position, 1);
                this.imageData = [...this.imageData];
            }
        });

        this.imageData = [...this.estabelecimentoPhotos, ...this.formularioPhotos, ...this.documentoPhotos, ...this.contratoPhotos, ...this.comprovantePhotos, ...this.selfiePhotos];
        this.imagesEmitter.emit(this.imageData);
    }


    onSelectFile(event): void {

        const int = event.target.files.length;

        // tslint:disable-next-line:triple-equals
        if (this.referenciaArquivo == 'ESTABELECIMENTO' && int > this.qtdEstabelecimento) {
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdEstabelecimento} fotos adicionadas!`, TimeMensagem.TIME_MENSAGEM);
            // tslint:disable-next-line:triple-equals
        } else if (this.referenciaArquivo == 'FORMULARIO' && int > this.qtdFormulario) {
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdFormulario} fotos adicionadas!`, TimeMensagem.TIME_MENSAGEM);
            // tslint:disable-next-line:triple-equals
        } else if (this.referenciaArquivo == 'DOCUMENTO' && int > this.qtdDocumento) {
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdContrato} fotos adicionadas!`, TimeMensagem.TIME_MENSAGEM);
            // tslint:disable-next-line:triple-equals
        } else if (this.referenciaArquivo == 'CONTRATO' && int > this.qtdContrato) {
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdContrato} fotos adicionadas!`, TimeMensagem.TIME_MENSAGEM);
            // tslint:disable-next-line:triple-equals
        } else if (this.referenciaArquivo == 'COMPROVANTE' && int > this.qtdComprovante) {
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdComprovante} fotos adicionadas!`, TimeMensagem.TIME_MENSAGEM);
        } else if (this.referenciaArquivo == 'SELFIE' && int > this.qtdSelfie){
            this.utilService.feedbackInfo(`Ultrapassou a quantidade de ${this.qtdSelfie} fotos adicionadas!`,TimeMensagem.TIME_MENSAGEM);
        } 
        else {

            if (event.target.files) {

                ImageCompressService.filesToCompressedImageSource(event.target.files).then(observableImages => {
                        observableImages.subscribe((image) => {
                            this.images.push(image);
                            const imagem = image.compressedImage.imageDataUrl;

                            // tslint:disable-next-line:triple-equals
                            if (this.referenciaArquivo == 'ESTABELECIMENTO') {
                                this.estabelecimentoPhotos.push({
                                    'contentsBase64': imagem,
                                    'idVisita': this.id,
                                    'nome': image.fileName,
                                    'tipoReferenciaArquivoVisita': this.referenciaArquivo
                                });
                                // tslint:disable-next-line:triple-equals
                            } else if (this.referenciaArquivo == 'FORMULARIO') {
                                this.formularioPhotos.push({
                                    'contentsBase64': imagem,
                                    'idVisita': this.id,
                                    'nome': image.fileName,
                                    'tipoReferenciaArquivoVisita': this.referenciaArquivo
                                });

                                // tslint:disable-next-line:triple-equals
                            } else if (this.referenciaArquivo == 'DOCUMENTO') {
                                this.documentoPhotos.push({
                                    'contentsBase64': imagem,
                                    'idVisita': this.id,
                                    'nome': image.fileName,
                                    'tipoReferenciaArquivoVisita': this.referenciaArquivo
                                });

                                // tslint:disable-next-line:triple-equals
                            } else if (this.referenciaArquivo == 'CONTRATO') {
                                this.contratoPhotos.push({
                                    'contentsBase64': imagem,
                                    'idVisita': this.id,
                                    'nome': image.fileName,
                                    'tipoReferenciaArquivoVisita': this.referenciaArquivo
                                });

                                // tslint:disable-next-line:triple-equals
                            } else if (this.referenciaArquivo == 'COMPROVANTE') {
                                this.comprovantePhotos.push({
                                    'contentsBase64': imagem,
                                    'idVisita': this.id,
                                    'nome': image.fileName,
                                    'tipoReferenciaArquivoVisita': this.referenciaArquivo
                                });
                            } else if (this.referenciaArquivo =='SELFIE') {
                                this.selfiePhotos.push({
                                    'contentsBase64' : imagem,
                                    'idVisita' : this.id,
                                    'nome' :image.fileName,
                                    'tipoReferenciaArquivoVisita' : this.referenciaArquivo
                                });
                            }

                            this.eventEmitter.emit([
                                this.estabelecimentoPhotos.length,
                                this.formularioPhotos.length,
                                this.documentoPhotos.length,
                                this.contratoPhotos.length,
                                this.comprovantePhotos.length,
                                this.selfiePhotos.length
                            ]);

                            this.imageData = [...this.estabelecimentoPhotos, ...this.formularioPhotos, ...this.documentoPhotos, ...this.contratoPhotos, ...this.comprovantePhotos, ...this.selfiePhotos];
                            this.imagesEmitter.emit(this.imageData);
                            this.files.get('inputField').setValue(null);
                        });


                    }
                );

            }
        }
    }
}


