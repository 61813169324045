import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Activity } from '../../../../shared/model/activity/activity.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class ActivityFieldService {
  private host = `${SGF_API}` + 'sgf/api/public/areaatuacao';
  private urldisableArea = `${SGF_API}` + 'sgf/api/public/areaatuacao/desativar';

  private posto: string;
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  listAll(): any {
    this.checkIfHasPosto();
    return this.httpClient.get<Activity>(`${this.host}?size=1000&${this.posto}`)
      .pipe(
        catchError(this.handleError));
  }

  page(size: number, page: number, ativo: boolean, postoId?: any): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id&ativo=${ativo}`);
    
  }

  add(activity: Activity): any {
    const body = JSON.stringify(activity);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  apdate(activity: Activity): any {
    const body = JSON.stringify(activity);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  updateActivity(activity: Activity): any {
    const body = JSON.stringify(activity);
    if (!activity.ativo){
      console.log('ÁREA DE ATUAÇÃO DESATIVADA', activity);
      this.desativarArea(activity.id);
    }
    
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  desativarArea(id: number): any{
    const body = JSON.stringify(id);
    return this.httpClient.put(this.urldisableArea, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getBairro(): any {
    return this.httpClient.get(`${SGF_API}` + 'sgf/api/public/bairro?size=1000', this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  getEstado(): any {
    return this.httpClient.get(`${SGF_API}` + 'sgf/api/public/estado', this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  getMunicipios(): any {
    return this.httpClient.get(`${SGF_API}` + 'sgf/api/public/municipio', this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  getByIdBairro(id: number): any {
    return this.httpClient.get(`${SGF_API}` + 'sgf/api/public/bairro/' + `${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  getByIdMunicipio(id: number): any {
    return this.httpClient.get(`${SGF_API}` + 'sgf/api/public/municipio/' + `${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }
  

  private httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
}

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  private extractData(res: Response): any {
    const body = res.json();
    return body as any;
  }

}
