import { Component, OnInit } from '@angular/core';
import { DashBoardFaturamentoTO } from 'app/main/shared/model/dashboard-faturamento/dashboard-faturamento';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

@Component({
  selector: 'app-acompanhamento-contratos-entregues-main',
  templateUrl: './acompanhamento-contratos-entregues-main.component.html',
  styleUrls: ['./acompanhamento-contratos-entregues-main.component.scss']
})
export class AcompanhamentoContratosEntreguesMainComponent implements OnInit {

  dashboard: DashBoardFaturamentoTO = new DashBoardFaturamentoTO();

  postoList: any = [];

  constructor(private service: ContractsService, public dialog: MatDialog, private postoService: PostsService) {
    this.postoService.listarPostos().subscribe((response) => {
      this.postoList = response.object;
      this.service.getDashboardFaturamentoTO(this.postoList).subscribe((resp) => {
        this.dashboard = resp.object;
      });
    });
   
  }

  ngOnInit(): void {}

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  over(): void {
    this.service.getDashboardFaturamentoTO(this.postoList).subscribe((resp) => {
      this.dashboard = resp.object;
    });
  }
  
}
