import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  disabledButton:boolean;
  constructor(private matDialogRef: MatDialogRef<DeleteConfirmationComponent>) { }

  ngOnInit() {}

  onConfirm(){
    this.disabledButton = true;
    this.matDialogRef.close(true)
  }

  onClosed(){
    this.matDialogRef.close(false)
  }

}
