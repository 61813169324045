import { Address } from '../address/address.model';
import { Telephone } from '../telephone/telephone.model';
import { User } from '../user/user.model';
import { Information } from '../information/information.model';
import { PersonType } from '../enums/person-type.enum';

export class Person extends Information {
    id: number;
    type?: string;
    ativo: boolean;

    listEndereco: Address[];
    listTelefone: Telephone[];
    usuario: User;

    constructor(obj?: any) {
        super();
        this.id =  obj ? obj.id : null;
        this.type = obj ? obj.type : 'pf';
        this.ativo =  obj ? obj.ativo : true;
        this.listEndereco = obj ? obj.listEndereco : [new Address({})];
        this.listTelefone =  obj ? obj.listTelefone : [new Telephone({})];
        this.usuario = obj ? obj.usuario : new User({});
    }
} 
