import { Component, Input, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cliente-full-contas-bancarias',
  templateUrl: './cliente-full-contas-bancarias.component.html',
  styleUrls: ['./cliente-full-contas-bancarias.component.scss'],
  animations: fuseAnimations
})
export class ClienteFullContasBancariasComponent implements OnInit {

  @Input() cliente: FormGroup;
  @Input() visit: FormGroup;

  get contaBancariaForms(): FormArray {
    return this.cliente.get('pessoa').get('listContaBancaria') as FormArray || this.formBuilder.array([]);
  }

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.contaBancariaForms.value.length === 0) {
      this.add();
    }
  }

  principalFieldWasChecked(conta): void {
    for (let i = 0; i < this.contaBancariaForms.length; i++) {
      if (this.contaBancariaForms.at(i) !== conta) {
        this.contaBancariaForms.at(i).get('principal').setValue(false);
      }
    }
  }

  add(): void {
    const conta = this.formBuilder.group({
      id: new FormControl(null),
      favorecido: new FormControl(null),
      cpf: new FormControl(null),
      cnpj: new FormControl(null),
      numero: new FormControl(null),
      agencia: new FormControl(null),
      banco: new FormControl(null),
      tipo: new FormControl(null),
      tipoDocumento: 'cpf',
      principal: false,
      contaTitular: true,
      parentesco: new FormControl(null),
      registrosPix: this.formBuilder.array([this.formBuilder.group({id: null, chavePix: null, tipoChavePix: null})])
    });

    this.contaBancariaForms.push(conta);
  }

  delete(index: number): void {
    this.contaBancariaForms.removeAt(index);
  }

}
