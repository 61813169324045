import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import {ListUnididadeFederacao} from '../../model/uf/ufs.model';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {Employee} from '../../model/employee/employee.model';
import {PostsService} from 'app/main/global/components/posts/services/posts.service';
import {GlobalService} from 'app/main/global/services/global.service';
import {Posto} from '../../model/posto/posto.model';
import { Estado } from '../../model/estado/estado.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import {Team} from '../../model/team/team.model';
import {TeamService} from 'app/main/global/components/teams/services/team.service';
import {ParametroPosto} from '../../model/parametro-posto/parametro-posto.model';

declare var google: any;

@Component({
  selector: 'app-posto-full',
  templateUrl: './posto-full.component.html',
  styleUrls: ['./posto-full.component.scss'],
})
export class PostoFullComponent implements OnInit {
  disabledButton: boolean;
  posto: FormGroup;
  titleHeader: string;
  bairrosList: any[] = [];
  estadosList: Estado[];
  municipiosDescriptions: any[] = [];
  ufs = ListUnididadeFederacao;
  currentPosto: Posto;
  municipio: any;
  dialogRef: any;
  dialogMessages;
  step: 0;
  segmentList: any;
  editedPosto: any;
  postos: Posto;
  endereco;
  parametroPosto;

  gestor: Employee;
  passadorDeRota: Employee;
  continuo: Employee;
  vendedor: Employee;
  equipes: Array<Team> = [];

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<PostoFullComponent>,
    public _matDialog: MatDialog,
    private teamService: TeamService,
    private postsService: PostsService,
    private globalService: GlobalService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.changeTitleHeader();

    this.teamService.listAll().subscribe(r => {
      this.equipes = r.object;
    });

  }

  ngOnInit(): void{
   this.setFormGroup();
  }

  setFormGroup(): void {

    this.endereco = new Posto({}).endereco;
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      this.endereco = this._data.posto.endereco;
      this._data.posto.equipes.forEach(p => {
        this.equipes.push(p.descricao);
      });
    }

    // tslint:disable-next-line:triple-equals
    if ( this._data.posto != undefined || this._data.posto != null ) {
      this.parametroPosto = this._data.posto.parametroPosto;
    }
    this.posto = this.formBuilder.group({
        id: this._data.posto ? this._data.posto.id : null,
        descricao : [this._data.posto ? this._data.posto.descricao : null, Validators.required],
        qrcode : [this._data.posto ? this._data.posto.qrcode : null],
        uid : [this._data.posto ? this._data.posto.uid : null],
        endereco : this.buildAddress(this.endereco),
        parametroPosto: this.buildParametroPosto(this.parametroPosto)
      }
    );
  }

  buildAddress(endereco): FormGroup {
      return this.formBuilder.group({
        id              : new FormControl(endereco ? endereco.id : null),
        cep             : new FormControl(endereco ? endereco.cep : null, Validators.required),
        logradouro      : new FormControl(endereco ? endereco.logradouro : null, Validators.required),
        numero          : new FormControl(endereco ? endereco.numero : null, Validators.required),
        latitude        : new FormControl(endereco ? endereco.latitude : null),
        longitude       : new FormControl(endereco ? endereco.longitude : null),
        bairro          : new FormControl(endereco ? (endereco.bairro ? endereco.bairro.descricao : null) : null, Validators.required),
        municipio       : new FormControl(endereco ? (endereco.municipio ? endereco.municipio.nomeMunicipio : null) : null, Validators.required),
        complemento     : new FormControl(endereco ? endereco.complemento : null),
        estado          : new FormControl(endereco ? (endereco.estado ? endereco.estado.sigla : null) : null, Validators.required),
        pontoReferencia : new FormControl(endereco ? endereco.pontoReferencia : null),
        tipoEndereco    : new FormControl(endereco ? endereco.tipoEndereco : null)
      });
  }

  buildParametroPosto(parametroPosto): FormGroup{
    return this.formBuilder.group({
      id              : new FormControl(parametroPosto ? parametroPosto.id : null),
      valorRefeicao   : new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.valorRefeicao : null) : null, Validators.required),
      portePostoEnum   : new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.portePostoEnum : null) : null, Validators.required),
      conferivel:  new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.conferivel : false) : false),
      optantePorCobrancasFDS:  new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.optantePorCobrancasFDS : false) : false),
      pixBoleto:  new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.pixBoleto : false) : false),
      habilitarDezParcelas:  new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.habilitarDezParcelas : false) : false),
      eletro: new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.eletro : false) : false),
      boleto: new FormControl(parametroPosto ? (parametroPosto ? parametroPosto.boleto : false) : false),
    });

  }

  onAdd(object: any): void {

    this.googleMaps(object.endereco);

    if (object.endereco.bairro != null) {
      if (typeof object.endereco.bairro === 'object') {
        object.endereco.municipio = object.endereco.bairro.municipio;
        object.endereco.estado = object.endereco.bairro.municipio.estado;
        this.salvarEditarPosto(object);
      } else {
        this.globalService.bairroByDescricao(object.endereco.bairro).subscribe(res => {
          const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === object.endereco.municipio)[0];
          object.endereco.bairro = bairro;
         
          object.endereco.municipio = bairro.municipio;
          object.endereco.estado = bairro.municipio.estado;
          
          this.salvarEditarPosto(object);

        });
      }
    }

  }

  private salvarEditarPosto(object: any): void {
    this.postsService.add(object).subscribe(
      (result) => {
        if (result.success) {
          this.matDialogRef.close(result.object);

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${result.messages[0].text}`
          };
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(result.object);
        } else {
          if (result.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${result.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }

  onUpdate(object: any): void {

    this.googleMaps(object.endereco);


    if (object.endereco.bairro != null) {
      if (typeof object.endereco.bairro === 'object') {
        object.endereco.municipio = object.endereco.bairro.municipio;
        object.endereco.estado = object.endereco.bairro.municipio.estado;

        this.salvarEditarPosto(object);

      } else {
        this.globalService.bairroByDescricao(object.endereco.bairro).subscribe(res => {
          const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === object.endereco.municipio)[0];
          object.endereco.bairro = bairro;
         
          object.endereco.municipio = bairro.municipio;
          object.endereco.estado = bairro.municipio.estado;
          
          this.salvarEditarPosto(object);
          
        });
      }
    }
  }

  onCancel(): void {
    this._matDialog.closeAll();

  }

  onClosed(): void {
    if (this.editedPosto) {
      this.matDialogRef.close({ posto: this.editedPosto, success: true });
    } else {
      this.matDialogRef.close();
    }
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  getErrorMessage(field: string): string {
    return this.posto.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }


  changeTitleHeader(): void {
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'new') {
      this.titleHeader = 'Novo Posto';
    } else {
      this.titleHeader = 'Editar Posto';
    }
  }

  googleMaps(endereco): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${endereco.logradouro}, ${endereco.numero}, ${endereco.municipio ? endereco.municipio.nomeMunicipio : ''}, ${endereco.estado}, ${endereco.cep}`;

    geocoder.geocode({ 'address': address }, (results, status) => {
      // tslint:disable-next-line:triple-equals
      if (status == 'OK') {
        endereco.logradouroCompleto = results[0].formatted_address;

        endereco.latitude = results[0].geometry.location.lat();
        endereco.longitude = results[0].geometry.location.lng();
      }
    });

  }

}
