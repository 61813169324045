import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ContractsService} from '../../../contracts/services/contracts.service';
import {Contract} from '../../model/contract/contract.model';


@Component({
  selector: 'app-contrato-historico',
  templateUrl: './contrato-historico.component.html',
  styleUrls: ['./contrato-historico.component.scss']
})
export class ContratoHistoricoComponent implements OnInit {

  id: number;
  contrato: any;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private matDialogRef: MatDialogRef<ContratoHistoricoComponent>,
      private _matDialog: MatDialog,
      private contratoService: ContractsService
  ) { }

  ngOnInit(): any {
    this.getConsome();
  }

  getConsome(): any{

    this.contratoService.findById(this.data)
        .subscribe(data => {
          this.contrato = data.object;
        });
  }

}
