import { PersonModel } from "../person/persons.model";
import { RegistrosPix } from "../registros-pix/registros-pix.model";

export class ContaBancaria {
    id: number;
    favorecido: string;
    tipoDocumento: string;
    cpf: string;
    cnpj: string;
    numero: string;
    agencia: string;
    banco: string;
    tipo: string;
    principal: boolean;
    pessoa: PersonModel;
    registrosPix: RegistrosPix[];

    constructor(obj?: any) {
        this.id             = obj.id            || null;
        this.favorecido     = obj.favorecido    || null;
        this.tipoDocumento  = obj.tipoDocumento || null;
        this.cpf            = obj.cpf           || null;
        this.cnpj           = obj.cnpj          || null;
        this.numero         = obj.numero        || null;
        this.agencia        = obj.agencia       || null;
        this.banco          = obj.banco         || null;
        this.tipo           = obj.tipo          || null;
        this.principal      = obj.principal     || null;
        this.pessoa         = obj.pessoa        || null;
        this.registrosPix   = obj.registrosPix  || null;
    }
}