import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-in-progress-sidebar',
  templateUrl: './in-progress-sidebar.component.html',
  styleUrls: ['./in-progress-sidebar.component.scss'],
})
export class InProgressSidebarComponent implements OnInit, OnChanges {

  filterBy: string;
  all = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean;
  filter: string;
  inputDisabled = false;
  search = '';
  dataInicial = '';
  dataFinal = '';
  placeholder: string;
  situacao: string;
  inputPeriodo = false;

  lastSearchFilter: string;
  Period: string;
  lastFilter: string;
  lastPage;

  @Input() searchInput: FormControl;

  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Output() dataPeriodoEvent: EventEmitter<string> = new EventEmitter();
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Input() action;


  constructor(private contractService: ContractsService, private route: ActivatedRoute) {
    if (!this.hasLastFilters()){
      this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
      this.filter = 'NOME_CLIENTE';
      this.placeholder = 'Buscar por Cliente';
    }
  }

  ngOnInit(): void {
    if (this.hasLastFilters()){     
      this.callLastFilters() ;
    } 
  }

  ngOnChanges(): void {
    if (this.searchInput == null) {
      this.search = '';
    }
  }

  clear(): void {
    this.filter = 'NOME_CLIENTE';
    this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    this.inputPeriodo = false;
    this.inputDisabled = false;
    this.search = null;
    this.placeholder = 'Buscar por Cliente';
  }

  // Seleção de filtro
  getFilter(filter): void {

    switch (filter) {
      case 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO');
        this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'EM_ANDAMENTO':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit('EM_ANDAMENTO');
        this.situacao = 'EM_ANDAMENTO';
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'EM_ATRASO':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit('EM_ATRASO');
        this.situacao = 'EM_ATRASO';
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'PENDENTE':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit('PENDENTE');
        this.situacao = 'PENDENTE';
        this.inputDisabled = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'DOCUMENTO_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por Documento Cliente';
        this.filterEvent.emit('DOCUMENTO_CLIENTE');
        break;
      case 'ID_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por ID Cliente';
        this.filterEvent.emit('ID_CLIENTE');
        break;
      case 'NOME_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por Cliente';
        this.filterEvent.emit('NOME_CLIENTE');
        break;

      case 'NOME_BUSCA':
        this.filter = 'NOME_BUSCA'
        this.inputDisabled = false;
        this.placeholder = "Buscar por Nome Busca";
        this.filterEvent.emit(this.filter);
  
        break;
      case 'RESPONSAVEL_CONTRATO':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por Responsável Contrato';
        this.filterEvent.emit('RESPONSAVEL_CONTRATO');
        break;
      case 'ROTA':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por Rota';
        this.filterEvent.emit('ROTA');
        break;
      case 'VENCIMENTO_HOJE':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = false;
        this.placeholder = 'Buscar por Contratos com Vencimento hoje';
        this.filterEvent.emit('VENCIMENTO_HOJE');
        break;
      case 'PERIODO':
        this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = true;
        this.search = null;
        this.filterEvent.emit('PERIODO');
        this.placeholder = 'Buscar por Cliente';
        break;
    }
  }

  // Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any): void {
    this.searchEvent.emit(this.search);
    this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          if (this.search.length > 2 || event.code === 'Enter') {
            this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'ID_CLIENTE':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          const idCliente = Number(this.search);
          if (!isNaN(idCliente) && (this.search.length > 0 || event.code === 'Enter')) {
            this.contractService.pageByIdCliente(this.situacao, idCliente, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          if (this.search.length > 2 || event.code === 'Enter') {
            this.contractService.pageByNomeCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
        case 'NOME_BUSCA':
          if (this.search === '') {
            this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
              this.data.emit(res.data.findAllPageable);
            });
          } else {
            if (this.search.length > 2 || event.code === 'Enter') {
              this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, 1).subscribe(data => {
                this.data.emit(data.object);
              });
            }
          }
        break
      case 'RESPONSAVEL_CONTRATO':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          if (this.search.length > 2 || event.code === 'Enter') {
            this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'VENCIMENTO_HOJE':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, 'dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          if (this.search.length > 2 || event.code === 'Enter') {
            this.contractService.pageDataVencimentoByNomeCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'ROTA':
        if (this.search === '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, 'dataVencimento').subscribe(res => {
            this.data.emit(res.data.findAllPageable);
          });
        } else {
          if (this.search.length > 2 || event.code === 'Enter') {
            this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
    }
  }

  filterPeriodo(): void {
    this.Period = this.dataInicial + ',' + this.dataFinal;
    this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, '+dataVencimento', this.Period).subscribe(data => {
      this.dataPeriodoEvent.emit(this.Period);
      this.data.emit(data.object);
    });
  }

  callLastFilters(): void {

    if ( this.lastPage != null && this.Period == null) {

      this.pageEvent.emit(this.lastPage);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastFilter != null && this.lastFilter !== 'PERIODO' && this.lastPage == null && this.lastSearchFilter == null) {

      this.getFilter(this.lastFilter);
      this.settingPlaceHolder(this.lastFilter);


    } else if (this.lastSearchFilter != null && this.lastFilter == null && this.lastPage == null) {

      this.filter = 'NOME_CLIENTE';
      this.getFilter(this.filter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastSearchFilter != null && this.lastFilter != null && this.lastPage == null) {

      this.getFilter(this.lastFilter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastPage != null && this.Period != null) {

      this.situacao = 'EM_ANDAMENTO,EM_ATRASO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, this.lastPage, '+dataVencimento', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    } else if ( this.Period != null && this.lastPage == null ) {

      this.situacao = 'EM_ANDAMENTO,EM_ATRASO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, '+dataVencimento', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    }

  }

  hasLastFilters(): boolean {

    if (this.route.snapshot.queryParamMap.has('filter') === false
      && this.route.snapshot.queryParamMap.has('search') === false
      && this.route.snapshot.queryParamMap.has('pageNum') === false)  
    {

      this.lastFilter = null;
      this.lastSearchFilter = null;
      this.Period = null;
      this.lastPage = null;

      return false;

    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter');
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search');
    }

    if (this.route.snapshot.queryParamMap.has('dataPeriodo')) {
      this.Period = this.route.snapshot.queryParamMap.get('dataPeriodo');
    }

    if (this.route.snapshot.queryParamMap.has('pageNum')) {
      this.lastPage = this.route.snapshot.queryParamMap.get('pageNum');
    }

    return true;

  }

  settingPlaceHolder(filter): void{

    if (filter == null || filter === undefined) {
      filter = 'NOME_CLIENTE';
    }

    this.filter = filter;
    switch (filter) {
      case 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO':
        this.placeholder = 'Buscar por Cliente';
        this.inputDisabled = false;
        break;
      case 'EM_ANDAMENTO':

        this.placeholder = 'Buscar por Cliente';
        this.inputDisabled = false;
        break;
      case 'EM_ATRASO': 
        this.placeholder = 'Buscar por Cliente';
        this.inputDisabled = false;
        break;
      case 'PENDENTE':   
        this.placeholder = 'Buscar por Cliente';
        this.inputDisabled = false;
        break;
      case 'DOCUMENTO_CLIENTE':  
        this.placeholder = 'Buscar por Documento Cliente';
        this.inputDisabled = false;
        break;
      case 'NOME_CLIENTE':
        this.placeholder = 'Buscar por Cliente';
        this.inputDisabled = false;
        break;
      case 'RESPONSAVEL_CONTRATO':

        this.placeholder = 'Buscar por Responsável Contrato';
        this.inputDisabled = false;
        break;
      case 'ROTA':
        this.placeholder = 'Buscar por Rota';
        this.inputDisabled = false;
        break;
      case 'VENCIMENTO_HOJE':
        this.placeholder = 'Buscar por Contratos com Vencimento hoje';
        this.inputDisabled = false;
        break;
      case 'PERIODO':
        this.inputDisabled = false;
        this.inputPeriodo = true;
        this.placeholder = 'Buscar por Cliente';
        break;
    }
  }

}
