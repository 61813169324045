import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { Mask } from '@fagnerlima/ng-mask';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-form-socio',
  templateUrl: './form-socio.component.html',
  styleUrls: ['./form-socio.component.scss'],
  animations: [fuseAnimations]
})
export class FormSocioComponent implements OnInit {

  @Input() form: FormGroup;
  maskCpf = new Mask('000.000.000-00')

  constructor(private formBuilder: FormBuilder) {
    console.log(this.form)
  }

  ngOnInit() {
    if (this.form.get('listEndereco').value.length == 0) {
      this.add();
    }
    if(this.form.get('listTelefone').value.length == 0){
      this.addPhone();
    }
  }

  get addressForms(): FormArray {
    return this.form.get('listEndereco') as FormArray;
  }

  get phoneForms(): FormArray {
    return this.form.get('listTelefone') as FormArray;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

  add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: [],
      cep: new FormControl(null),
      logradouro: new FormControl(null),
      numero: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null),
      estado: new FormControl(null),
      tipoEndereco: new FormControl(null)
    });
    this.addressForms.push(address);
  };

  delete(index: number): void {
    this.addressForms.removeAt(index);

  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });

    this.phoneForms.push(phone);
  };

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }
}
