import { Segment } from '../segment/segment.model';
import { PersonModel } from '../person/persons.model';
import { Posto } from '../posto/posto.model';
import { Telephone } from '../telephone/telephone.model';

export class Cliente{
    id: number;
    ativo: boolean;
    atividadeComercial: Segment;
    listReferencia: any[];
    pessoa: PersonModel;
    situacaoCliente: string;
    turno: string;
    type: string;
    posto?: Posto;
    apelidoCliente: string;
    instagram: string;
    instagramProfissional: string;
    nomeCliente: string;
    nomeConjuge: string;
    cpfConjuge: string;
    situacaoInativacao: any
    telefone: Telephone

    constructor(obj?: any){
        this.id = obj ? obj.id : null;
        this.ativo = obj ? obj.ativo : null;
        this.atividadeComercial = obj ? obj.atividadeComercial : null;
        this.listReferencia = obj ? obj.listReferencia : [];
        this.pessoa = new PersonModel(obj.pessoa) ||  new PersonModel({});
        this.situacaoCliente = obj ? obj.situacaoCliente : null;
        this.turno = obj ? obj.turno : null;
        this.type = obj ? obj.type : null;
        this.posto = obj ? obj.posto : null;
        this.apelidoCliente = obj ? obj.apelidoCliente : null;
        this.instagram = obj ? obj.instagram : null;
        this.instagramProfissional = obj ? obj.instagramProfissional : null;
        this.nomeCliente = obj ? obj.nomeCliente : null;
        this.nomeConjuge = obj ? obj.nomeConjuge : null;
        this.cpfConjuge = obj ? obj.cpfConjuge : null;
        this.situacaoInativacao = obj ? obj.situacaoInativacao: null;
    }
}
