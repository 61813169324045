import { Component, OnInit, ViewEncapsulation, Inject, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Customer } from '../../../../shared/model/customer/customer.model';
import * as Redux from 'redux';
import {
  CaptacaoState,
  getCurrentCustomer,
} from '../../../../shared/store/captacao.reducer';
import { CaptacaoStore } from '../../../../shared/store/captacao.store';
import { Information } from '../../../../shared/model/information/information.model';
import { Address } from '../../../../shared/model/address/address.model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-captacao-resume',
  templateUrl: './captacao-resume.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./captacao-resume.component.scss'],
  animations: fuseAnimations
})

export class CaptacaoResumeComponent implements OnInit {
  customer: Customer;
  customerInformationData: Information[];
  customerAddressData: Address[];
  selection = new SelectionModel<Address>(true, []);
  selectAddress: string;

  customerInformationColumns: string[] = ['id', 'nome', 'cpf', 'cnpj', 'celular', 'telefone', 'status']
  customerAddressColumns: string[] = ['select', 'kind', 'cep', 'endereco', 'numero', 'bairro', 'cidade', 'complemento', 'uf', 'referencia'];

  constructor(
    @Inject(CaptacaoStore) private store: Redux.Store<CaptacaoState>) {
    this.customer = getCurrentCustomer(this.store.getState());
  }

  onSelect(row){
    //this.selectAddress = this.selection.selected[0];
  }

  ngOnInit() {

  }

}
