import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-empresa-endereco-form',
  templateUrl: './empresa-endereco-form.component.html',
  styleUrls: ['./empresa-endereco-form.component.scss'],
  animations:fuseAnimations
})
export class EmpresaEnderecoFormComponent implements OnInit {

  @Input() empresa: FormGroup;

  step = 0;
  totalEmpresa: number;
  address: FormGroup;
  addressComercial: FormGroup;
  showPanelId: Boolean;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
      
    }

  ngOnInit() {
    if (this.empresa.get('listEndereco').value.length == 0) {
      this.add();
    }
  }

  get addressForms(): FormArray {
    return this.empresa.get('listEndereco') as FormArray;
  }

  add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: [],
      cep: new FormControl(null),
      logradouro: new FormControl(null),
      numero: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null),
      estado: new FormControl(null),
      tipoEndereco: new FormControl(null)
    });
    this.addressForms.push(address);
  };

  delete(index: number): void {
    this.addressForms.removeAt(index);

  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }


  prevStep() {
    this.step--;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }
}
