import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DistrictService } from 'app/main/global/components/district/services/district-service.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ActivityFieldService } from 'app/main/global/components/activity-field/services/activity-field.service';
import { Municipality } from '../../model/municipality/municipality.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalService } from 'app/main/global/services/global.service';
import { Estado } from '../../model/estado/estado.model';

@Component({
  selector: 'app-district-form',
  templateUrl: './district-form.component.html',
  styleUrls: ['./district-form.component.scss']
})
export class DistrictFormComponent implements OnInit {

  bairro: FormGroup
  titleHeader: string;
  dialogMessages;
  municipios: Array<Municipality>;
  bair: string;
  estados: Estado;
  municipality: Array<Municipality>;
  ufs: Array<any>;
  uf: Estado;
  disabledButton:boolean;

  public bairroFilterCtrl: FormControl = new FormControl();
  public filteredBairro: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public ufFilterCtrl: FormControl = new FormControl();
  public filteredUF: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public municipioFilterCtrl: FormControl = new FormControl();
  public filteredMunicipio: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


  constructor(private matDialogRef: MatDialogRef<DistrictFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private bairoService: DistrictService,
    private dialog: MatDialog,
    private formBuild: FormBuilder,
    private globalService: GlobalService) {

    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Bairro' : 'Edição de Bairro';

  }

  ngOnInit() {
    this.setForm();
    if (this._data.action === "update") {
      this.globalService.pageEstado().subscribe(data => {
        this.ufs = data.object.content;

        this.uf = this.ufs.filter(e => e.sigla === this.bairro.get('estado').value)[0]
        this.getMunicipios(this.uf)

        this.filteredUF.next(this.ufs.slice());
        this.ufFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUf();
          });
      })
    } else {
      this.globalService.pageEstado().subscribe(data => {
        this.ufs = data.object.content;
        this.filteredUF.next(this.ufs.slice());
        this.ufFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUf();
          });
      })
    }
  }
  private _onDestroy = new Subject<void>();

  setForm() {
    this.bairro = this.formBuild.group({
      id: this._data.bairro ? this._data.bairro.id : null,
      descricao: [this._data.bairro ? this._data.bairro.descricao : null, Validators.required],
      municipio: [this._data.bairro ? this._data.bairro.municipio.id : null, Validators.required],
      nomeMunicipio: [this._data.bairro ? this._data.bairro.municipio.nomeMunicipio : null],
      estado: [this._data.bairro ? this._data.bairro.municipio.estado.sigla : null, Validators.required]
    })
  }

  onClosed() {
    this.matDialogRef.close();
  }

  onAdd(bairro) {
    this.disabledButton = true;
    this.globalService.municipioById(bairro.municipio).subscribe(data => {
      bairro.municipio = data.object

      bairro.nomeMunicipio = bairro.municipio.nomeMunicipio;
      this.bairoService.create(bairro).subscribe((data: ReturnApi) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data.object);
        } else {
          if(data.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
        (onerror) => {
          console.log('ERROR BAIRRO', onerror);
        }
      )
    })
  }


  onUpdate(bairro) {
    this.disabledButton = true;
    this.globalService.municipioById(bairro.municipio).subscribe(data => {
      bairro.municipio = data.object


      bairro.nomeMunicipio = bairro.municipio.nomeMunicipio;
      this.bairoService.update(bairro).subscribe((data: ReturnApi) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data.object);
        } else {
          if(data.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      }
        ,
        (onerror) => {
          console.log('ERROR BAIRRO', onerror);
        }
      )
    })
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  private filterMunicipio() {
    if (!this.municipios) {
      return;
    }

    let search = this.municipioFilterCtrl.value;
    if (!search) {
      this.filteredMunicipio.next(this.municipios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMunicipio.next(
      this.municipios.filter(mu => mu.nomeMunicipio.toLowerCase().indexOf(search) > -1)
    );
  }

  getMunicipios(estado) {
    let uf;
    if (typeof estado === 'string') {
      uf = this.ufs.filter(e => e.sigla === estado)[0]
    } else {
      uf = estado
    }

    this.globalService.municipioByEstadoId(uf.id).subscribe(data => {
      this.municipios = data.object.content.map(data => data)

      this.filteredMunicipio.next(this.municipios.slice());
      this.municipioFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMunicipio();
        });
    })
  }

  private filterUf() {
    if (!this.filteredUF) {
      return;
    }

    let search = this.ufFilterCtrl.value;
    if (!search) {
      this.filteredUF.next(this.ufs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUF.next(
      this.ufs.filter(estado => estado.sigla.toLowerCase().indexOf(search) > -1)
    );
  }


}
