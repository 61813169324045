import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatDialog, MatRadioChange } from '@angular/material';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { Parcela } from '../../model/parcela/parcela.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Mask } from '@fagnerlima/ng-mask';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-agendar-parcela',
  templateUrl: './agendar-parcela.component.html',
  styleUrls: ['./agendar-parcela.component.scss']
})
export class AgendarParcelaComponent implements OnInit {

  form: FormGroup;
  parcelas: Parcela[];
  dialogMessages;
  employee = []
  public showValor = false;
  parcelaSelecionada: Parcela;
  maskQtdParcela = new Mask('000')
  public showQuantidadeParcela = false;

  public ParcelaControl: FormControl = new FormControl();
  public filteredParcelas: ReplaySubject<Parcela[]> = new ReplaySubject<Parcela[]>(1);
  responsavel: Employee;
  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  constructor(private cobrancaService: CobrancasService,
    private contratoService: ContractsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AgendarParcelaComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    private employeeService: EmployeeService) {
    this.parcelas = this._data.contrato.listParcela.sort((a, b) => a.ordem - b.ordem).filter(p => p.situacaoParcela != 'CANCELADA' && p.situacaoParcela != 'AGENDADA');

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });


    })
  }

  private _onDestroy = new Subject<void>();

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      funcionario: [this._data.contrato ? this._data.contrato.responsavel.id : null, Validators.required],
      parcela: [null, Validators.required],
      antecipacao: [null, Validators.required],
      valorPago: [null],
      tipoAgendamento: [null, Validators.required],
      parcelaUnica: [null, Validators.required],
      quantidadeParcela: [null]
    });
  }


  public onSelectParcela(matSelectChange: MatSelectChange): void {
    this.form.patchValue({
      antecipacao: matSelectChange.value.dataAgendamento
    });
  }

  public selectResponsavel(value){
    this.responsavel = value;
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onParcelaUnicaChange(matRadioChange: MatRadioChange): void {
    if (matRadioChange.value == 1) {
      this.showQuantidadeParcela = true;

      const quantidadeParcela = this.form.get('quantidadeParcela');
      quantidadeParcela.setValidators([Validators.required]);

      quantidadeParcela.reset();
      quantidadeParcela.updateValueAndValidity();

      const antecipacao = this.form.get('antecipacao');
      antecipacao.setValidators([]);

      antecipacao.reset();
      antecipacao.updateValueAndValidity();
    } else {
      this.showQuantidadeParcela = false;
      const quantidadeParcela = this.form.get('quantidadeParcela');
      quantidadeParcela.setValidators([]);

      quantidadeParcela.reset();
      quantidadeParcela.updateValueAndValidity();

      const antecipacao = this.form.get('antecipacao');
      antecipacao.setValidators([Validators.required]);

      antecipacao.reset();
      antecipacao.updateValueAndValidity();
    }
  }

  public calcularJuros(value: any): void { 
    this.contratoService.calcularValorJuros(this._data.contrato.id, value.antecipacao, value.quantidadeParcela).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `O valor a pagar do contrato: R$ ${data.object}`
        };

        this.feedback(this.dialogMessages);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    })
  }

  public onSave(value: any): void {
    value.parcela.dataAgendamento = value.antecipacao;
    value.parcela.tipoAgendamento = value.tipoAgendamento;
    value.parcela.responsavel = this.responsavel ? this.responsavel : this._data.contrato.responsavel;
    value.parcela.parcelaUnica = value.parcelaUnica;

    if (value.parcelaUnica == 1) { //multiplos
      value.parcela.quantidadeParcela = value.quantidadeParcela;
    }

    if (value.valorPago && value.valorPago !== '') {
      value.parcela.valorPago = parseFloat(value.valorPago.replace(/\,/g, '.'));
    }

    this.cobrancaService.agendarPagamento(value.parcela).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };

        if (value.tipoAgendamento == 2) {
          this.contratoService.gerarBoletosAgendados(value.parcela.contrato.id).subscribe((boleto: Blob | any) => {
            if (boleto) {
              FileSaver.saveAs(boleto, `boletos_agendados_${value.parcela.contrato.id}.pdf`);
            }
          })
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {  
        console.log('ERROR CONTRATO', onerror);
      }
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  public dateChange(event: any): void {
    this.showValor = event.value
      .add(1, 'd')
      .isBefore(new Date());

    const valorPago = this.form.get('valorPago');

    if (this.showValor) {
      valorPago.setValidators([Validators.required]);
    } else {
      valorPago.setValidators([]);
    }

    valorPago.reset();
    valorPago.updateValueAndValidity();
  }

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

}
