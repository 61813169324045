import { Telephone } from '../telephone/telephone.model';

export class ReferenciaPessoal {
    id: number;
    listTelefone: Telephone[];
    situacao: string;
    nome: string;

    constructor(obj: any) {
        this.id = obj.id || null;
        this.listTelefone = obj.listTelefone || [new Telephone({})];
        this.situacao = obj.situacao || null;
        this.nome = obj.nome || null

    }
}