import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpEvent } from '@angular/common/http';

import * as Redux from 'redux';
import { CaptacaoStore } from '../../../../shared/store/captacao.store';
import { CaptacaoState } from '../../../../shared/store/captacao.reducer';
import { catchError } from 'rxjs/operators';
import { Segment } from '../../../../shared/model/segment/segment.model';
import { CustomersModel } from 'app/main/shared/model/customers/customers-model';
import { Municipality } from 'app/main/shared/model/municipality/municipality.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class CaptacaoService {

  private posto: string;
  private host = `${SGF_API}` + 'sgf/api/public/captado';
  private urlAtividade = `${SGF_API}` + 'sgf/api/public/atividadecomercial';
  private urlCnae = `${SGF_API}` + 'sgf/api/public/cnae';
  private urlMunicipio = `${SGF_API}` + 'core/api/public/municipio';
  // private urlMunicipioFilter: string = '&sort=nomeMunicipio';
  private urlAprovar = `${SGF_API}` + 'sgf/api/public/captado/aprovar';
  private urlArquivoCaptado = `${SGF_API}cliente/api/public/arquivocaptado`;
  private arquivocaptadosgf = `${SGF_API}` + 'sgf/api/public/arquivocaptado/';

  constructor(
    private httpClient: HttpClient,
    @Inject(CaptacaoStore) private store: Redux.Store<CaptacaoState>) {

  }

  page(situacaoCaptado: string, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<CustomersModel[]>(`${this.host}?situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  listaDeArquivo64(base64List): Observable<any> {
    return this.httpClient.post(`${this.urlArquivoCaptado}/saveforlistarquivocaptadoto`, base64List, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFoto(arquivo): Observable<any> {
    const body = JSON.stringify(arquivo);
    return this.httpClient.delete(`${this.arquivocaptadosgf}delete-foto/${arquivo.id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findByCaptadoId(id): Observable<any> {
    return this.httpClient.get(`${this.urlArquivoCaptado}/findbycaptado/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findAll(): Observable<any> {
    this.checkIfHasPosto();
    
    return this.httpClient.get<CustomersModel[]>(`${this.host}?size=1000&${this.posto}` , this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }


  pageByNomeCliente(page: number, size: number, nomeCliente: string, situacaoCaptado: string): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<CustomersModel[]>(`${this.host}?nomeCliente=like:${nomeCliente}&situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  
  }

  pageByRazaoSocial(page: number, size: number, razaoSocial: string): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<CustomersModel[]>(`${this.host}?razaoSocial=like:${razaoSocial}&situacaoCaptado=eq:CAPTADO&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  pageByNomeFantasia(page: number, size: number, nomeFantasia: string, situacaoCaptado: string): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<CustomersModel[]>(`${this.host}?nomeFantasia=like:${nomeFantasia}&situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  pageByCpf(page: number, size: number, cpfCliente: string, situacaoCaptado: string): Observable<any> {
    
    this.checkIfHasPosto();

    return this.httpClient.get<CustomersModel[]>(`${this.host}?cpfCliente=like:${cpfCliente}&situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageByCnpj(page: number, size: number, cnpjCliente: string, situacaoCaptado: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get<CustomersModel[]>(`${this.host}?cnpjCliente=like:${cnpjCliente}&situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  pageByDocumentoCliente(page: number, size: number, documento: string): Observable<any> {
    return this.httpClient.get<CustomersModel[]>(`${this.host}?documentoCliente=eq:${documento}&situacaoCaptado=eq:CAPTADO
      &page=${page}&size=${size}&sort=nome` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageBySituacaoCaptado(page: number, size: number, situacaoCaptado: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?situacaoCaptado=eq:${situacaoCaptado}&page=${page}&size=${size}&${this.posto}&sort=nome` , this.httpOptions())
      .pipe( 
        catchError(this.handleError)
      );
  }


  pageByNomeIndicador(page: number, size: number, indicador: string): Observable<any> {
    return this.httpClient.get<CustomersModel[]>(`${this.host}?situacaoCaptado=eq:CAPTADO&page=${page}&size=${size}&indicador=like:${indicador}&sort=nome` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  

  pageByIndicador(): Observable<any> {
    return this.httpClient.get<CustomersModel[]>(this.host, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  

  getCaptadoByID(id: number|string): any{
    return this.httpClient.get<CustomersModel>(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getAtividade(): any{
    return this.httpClient.get<Segment[]>(`${this.urlAtividade}?size=200`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }
  getCnae(): any{
    return this.httpClient.get<Segment[]>(`${this.urlCnae}?size=5`, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );
  }

  getCnaeFilterDescricao(descricao: string): any{
    return this.httpClient.get<Segment[]>(`${this.urlCnae}?descricao=${descricao}`, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );
  }
  
  getMunicipio(): any {
    const params = {params: new HttpParams()
                              .set('size', '1000')};

    return this.httpClient.get<Municipality[]>(this.urlMunicipio, Object.assign({}, params, this.httpOptions()))
      .pipe( 
        catchError(this.handleError)
      );
  }

  create(customer: CustomersModel): any {
    const body = JSON.stringify(customer);
    return this.httpClient.post<CustomersModel[]>(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(customer: CustomersModel): any {
    const body = JSON.stringify(customer);
    return this.httpClient.put<CustomersModel[]>(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  validarCaptadoExistente(documentoPessoaCommand: any, idPosto: any): Observable<any> {
    let documentoPessoa = {
      documento: documentoPessoaCommand,
      idPosto: idPosto
    };
    const body = JSON.stringify(documentoPessoa);
    return this.httpClient.post(`${this.host}/validar-captado-existente/`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  aprovar(customer): any {
    const dataVisita = customer.dataVisita != null ? `?dataVisita=${customer.dataVisita.getTime()}` : '';
    const body = JSON.stringify(customer);
    return this.httpClient.put(this.urlAprovar + `${dataVisita}`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  rejeitar(customer): Observable<any>{
    const body = JSON.stringify(customer);
    return this.httpClient.put<CustomersModel[]>(`${this.host}/rejeitar`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  saveClienteEmPotencial(customer): Observable<any>{
    const body = JSON.stringify(customer);
    return this.httpClient.put<CustomersModel[]>(`${this.host}/save-cliente-potencial`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  recaptarRejeitado(captadoId: number): Observable<any>{
    return this.httpClient.put(`${this.host}/recaptar-rejeitado/${captadoId}`, null, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  // <TEMPORARIO>
  findEmployee(id: number | string): any {
    return this.httpClient.get(`${SGF_API}` + `sgf/api/public/funcionario/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  checkIfHasPosto(): void {
      if (sessionStorage.getItem('funcionario')) {
        const user = JSON.parse(sessionStorage.getItem('funcionario'));
        this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
      }
  } 

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8;application/json',
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError({
      message: 'Something bad happened; please try again later.',
      response: error
    });
  }
  
}
