import { Component, OnInit, Inject } from '@angular/core';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { Transferencia } from '../../model/trasnferencia/trasnferencia.model';
import { C } from '@angular/core/src/render3';

@Component({
  selector: 'app-confirmar-transferencia',
  templateUrl: './confirmar-transferencia.component.html',
  styleUrls: ['./confirmar-transferencia.component.scss'],
  animations: fuseAnimations
})
export class ConfirmarTransferenciaComponent implements OnInit {

  confirmation : any;
  dialogRef : any;
  titulo: string;
  disabledButton:boolean;


  constructor(@Inject(MAT_DIALOG_DATA) public data : any,
              private transferenciaService : TransferenciaService,
              private matDialogRef : MatDialogRef<ConfirmarTransferenciaComponent>,
              private _matDialog : MatDialog) { 
    
              this.confirmation = data;
  }

  ngOnInit() : void {
    
  }

  confirmTransfer(){
    this.disabledButton = true;

  }
  


  onCancel(): void {
    this.matDialogRef.close();
  }
  


  

}
