import { Component, OnInit, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { Chart } from 'chart.js';
import { data } from '../../data/chart-data';


@Component({
  selector: 'app-line-area-chart',
  templateUrl: './line-area-chart.component.html',
  styleUrls: ['./line-area-chart.component.scss']
})
export class LineAreaChartComponent implements OnInit {
  @Input() id;
  
  
  lineChart: any[] = data;
  
  constructor() {}
  
  ngOnInit() {}

  

}
