import { Address } from '../address/address.model';
import { Telephone } from '../telephone/telephone.model';
import { uuid } from '../../util/uuid';
import { Person } from '../person/person.model';
import { Information } from '../information/information.model';


export interface Bank {
  nome?: string;
  telefone?: string;
  credito?: string;
}

export interface Contato {
    nome?: string;
    listTelefone: Telephone[];
    confirmado: boolean;
}

export interface Fiador extends Information {
    conjuge: Information;
    telefone: Telephone;
    confirmado: boolean;
    listEndereco: Address[];
}

/**
 * Object as Customer
*/
export class Customer extends Person {

    customerId: string;
    key: string;
    schedule: boolean;
    supervisor: string;
    vendedor: string;
    
    statusCustomer: string;
    valorSugerido: string;
    limiteCredito: string;
    parecerVisita: string;
    valorAprovado: string;
    limiteAprovado: string;
    urlPhoto: Array<string>;
    isInVisit: boolean;
    visited: boolean;
    approval: boolean;
    toApproval: boolean;
    dataVisita: Date;
    fiador: Fiador;
    banco: Bank;
    referenciaPessoal: Contato;
    referenciaComercial: Contato;

    constructor(obj: any) {
        super(obj);
        this.setCustomer(obj);
    }

    private setCustomer(obj: any): void {
        this.statusCustomer = obj.statusCustomer  || '';
        this.valorAprovado  = obj.valorAprovado   || '';
        this.limiteAprovado = obj.limiteAprovado  || '';
        this.estadoCivil    =   obj.estadoCivil    || '';
        this.sexo           =   obj.sexo           || '';
        this.rg             =   obj.rg             || '';
        this.alfabetizado   =   obj.alfabetizado   || '';
        this.vendedor       =   obj.vendedor       || '';
        this.dataVisita     =   obj.dataVisita     || this.randomDate();
        this.isInVisit      =   obj.isInVisit      || false;
        this.visited        =   obj.visited        || false;
        this.approval       =   obj.approval       || false;
        this.toApproval     =   obj.toApproval     || false;
        this.valorSugerido  =   obj.valorSugerido  || '';
        this.limiteCredito  =   obj.limiteCredito  || '';
        this.parecerVisita  =   obj.parecerVisita  || '';
        this.supervisor     =   obj.supervisor     || '';
        this.descricao    =   obj.descricao    || '';
        this.customerId     =   obj.customerId ? obj.customerId : uuid();
        this.id             =   obj.id || null;
        this.key            =   obj.key  || '';
        this.schedule       =   obj.schedule || false;
        this.cpf            =   obj.cpf || '';
        this.cnpj           =   obj.cnpj || '';
        this.nome           =   obj.nome ||  '';
        this.dataNascimento =   obj.dataNascimento  || new Date();
        this.listEndereco   =   obj.listEndereco || [new Address({})];
        this.listTelefone   =   obj.listTelefone || [new Telephone({})];
        this.referenciaPessoal = obj.referenciaPessoal || {nome: '', listTelefone: [new Telephone({})], confirmado: false};
        this.referenciaComercial = obj.referenciaComercial || {nome: '', listTelefone: [new Telephone({})], confirmado: false};
        this.banco  = obj.banco  || {nome: '', telefone: '', credito: ''} as Bank;
        this.fiador = obj.fiador || {nome: '', cpf: '', conjuge: {nome: '', cpf: ''}, listEndereco: [new Address({})]};
        this.urlPhoto       = obj.urlPhoto || [];
    }

    randomDate() {
        let start = new Date(2018, 0, 1);
        let end = new Date();
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

}