import { Injectable } from '@angular/core';
import { AnaliseSobraService } from 'app/main/financial/services/sobra/sobra.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveConfirmarCreditoSobraService {

  constructor(private sobraService: AnaliseSobraService) { }

  resolve(): Observable<any> {
    return this.sobraService.page(10, 1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }

}
