import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pessoa-politica',
  templateUrl: './pessoa-politica.component.html',
  styleUrls: ['./pessoa-politica.component.scss']
})
export class PessoaPoliticaComponent implements OnInit {

  @Input() visit: FormGroup;


  constructor() { }

  ngOnInit(): void {  }

}
