export const data = [
    {
        type: 'line',
        data: {
            labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
            datasets: [{
                label: 'Chart 01',
                data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
                fill: false,
                lineTension: 0.2,
                borderColor: '#069',
                borderWidth: 1
            }]
        },
        options: {
            title: {
                text: "Line Chart",
                display: true
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },
    {
        type: 'line',
        data: {
            labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
            datasets: [{
                label: 'Chart 02',
                data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
                fill: false,
                lineTension: 0.2,
                borderColor: '#069',
                borderWidth: 1
            }]
        },
        options: {
            title: {
                text: "Line Chart",
                display: true
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },
    {
        type: 'line',
        data: {
            labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
            datasets: [{
                label: 'Chart 03',
                data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
                fill: false,
                lineTension: 0.2,
                borderColor: '#069',
                borderWidth: 1
            }]
        },
        options: {
            title: {
                text: "Line Chart",
                display: true
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },
    {
        type: 'line',
        data: {
            labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
            datasets: [{
                label: 'Chart 04',
                data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
                fill: false,
                lineTension: 0.2,
                borderColor: '#069',
                borderWidth: 1
            }]
        },
        options: {
            title: {
                text: "Line Chart",
                display: true
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },

]