import { Component, OnInit, Inject } from '@angular/core';
import { CaptacaoService } from '../../../customers/components/captacao/services/captacao.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { VisitasService } from 'app/main/customers/components/visitas/services/visitas.service';

@Component({
  selector: 'app-confirmar-rejeicao',
  templateUrl: './confirmar-rejeicao.component.html',
  styleUrls: ['./confirmar-rejeicao.component.scss']
})
export class ConfirmarRejeicaoComponent implements OnInit {
  dialogMessages;
  cancel: boolean;
  reject: FormGroup;
  disabledButton: boolean;

  constructor(private captacaoService: CaptacaoService,
    private visitaService: VisitasService,
    public matDialogRef: MatDialogRef<ConfirmarRejeicaoComponent>,
    private _matDialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cancel = data.cancel;


  }

  ngOnInit() {
    this.reject = this.formBuilder.group({
      parecer: ['', Validators.required]
    });
  }

  onConfirm(value: any) {
    this.disabledButton = true;
    Object.assign(value.customer, this.reject.value)

    this.captacaoService.rejeitar(value.customer).subscribe(data => {
      if (data.success) {
        this.matDialogRef.close(data.object);

        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Rejeitado com sucesso!`
        }
        this.feedback(this.dialogMessages);

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
        this.feedback(this.dialogMessages);
      }
      this.matDialogRef.close(data);
    })
  }

  cancelSchedule(data: any) {
    this.visitaService.cancel(data).subscribe( r => {
      this.matDialogRef.close(r);
    })
  }

  onClosed() {
    this.matDialogRef.close();
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

}
