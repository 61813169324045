import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-to-cancel-sidebar',
  templateUrl: './to-cancel-sidebar.component.html',
  styleUrls: ['./to-cancel-sidebar.component.scss']
})
export class ToCancelSidebarComponent implements OnInit, OnChanges {
  filterBy: string;
  all = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean;
  filter: string;
  inputDisabled = false;
  search = '';
  dataInicial = '';
  dataFinal = '';
  placeholder: string;
  situacao: string;
  @Input() searchInput: FormControl;
  @Input() action: string;
  datas = '';
  lastSearchFilter: string;
  Period: string;
  lastFilter: string;
  lastPage;
  inputPeriodo = false;
  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Output() periodEvent: EventEmitter<any> = new EventEmitter(); 
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataPeriodoEvent: EventEmitter<string> = new EventEmitter();

  constructor(private contractService: ContractsService, private _matDialog: MatDialog, private route: ActivatedRoute) {
    if (!this.hasLastFilters()){
      this.situacao = 'AGUARDANDO_CANCELAMENTO'; 
      this.placeholder = 'Buscar por Cliente';
      this.filter = 'NOME_CLIENTE';
    }
  }

  ngOnInit(): void {
    if (this.hasLastFilters()){     
      this.callLastFilters() ;
    } 
   }

  ngOnChanges(): void  {
    if (this.searchInput == null) {
      this.search = '';
    }
  }

  clear(): void {
    this.filter = 'NOME_CLIENTE';
    this.situacao = 'AGUARDANDO_CANCELAMENTO';
    this.inputPeriodo = false;
    this.inputDisabled = false;
    this.search = null;
    this.placeholder = 'Buscar por Cliente';
  }

  // Seleção de filtro
  getFilter(filter): void  {
    switch (filter) {
      case 'ALL':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.filterEvent.emit('all');
      case 'NOME_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.filterEvent.emit('NOME_CLIENTE');
        break;
      case 'ROTA':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Rota';
        this.filterEvent.emit('ROTA');
        break;
      case 'PERIODO':
        this.situacao = 'AGUARDANDO_CANCELAMENTO';
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = true;
        this.search = null;
        this.filterEvent.emit('PERIODO');
        this.placeholder = 'Buscar por Cliente';
        break;
    }
  }


  filterPeriodo(): void {
    this.Period = this.dataInicial + ',' + this.dataFinal;
    this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, '-dataVencimento', this.Period).subscribe(data => {
      this.dataPeriodoEvent.emit(this.Period);
      this.data.emit(data.object);
    });
  }

  // Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any): void {
    this.searchEvent.emit(this.search);
    this.situacao = 'AGUARDANDO_CANCELAMENTO';
    if (this.search === '') {
      this.contractService.pageAllCancelar(10, 1, 'dataFinalizacao').subscribe(data => {
        this.data.emit(data.object);
      });
    }
    if (this.search.length > 2 || event.code === 'Enter') {
      switch (this.filter) {
        case 'NOME_CLIENTE':
          this.contractService.pageByNomeClienteCancelar(this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object);
          });
          break;
        case 'ROTA':
          this.contractService.pageByRotaCancelar(this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object);
          });
          break;
      }
    }
  }

  callLastFilters(): void {

    if ( this.lastPage != null && this.Period == null) {
      console.log(this.lastPage);
      
      this.pageEvent.emit(this.lastPage);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastFilter != null && this.lastFilter !== 'FILTER_PERIOD' && this.lastPage == null && this.lastSearchFilter == null) {

      this.getFilter(this.lastFilter);
      this.settingPlaceHolder(this.lastFilter);


    } else if (this.lastSearchFilter != null && this.lastFilter == null && this.lastPage == null) {

      this.filter = 'NOME_CLIENTE';
      this.getFilter(this.filter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastSearchFilter != null && this.lastFilter != null && this.lastPage == null) {

      this.getFilter(this.lastFilter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastPage != null && this.Period != null) {

      this.situacao = 'AGUARDANDO_CANCELAMENTO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, this.lastPage, 'dataFinalizacao', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    } else if ( this.Period != null && this.lastPage == null ) {

      this.situacao = 'AGUARDANDO_CANCELAMENTO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, 'dataFinalizacao', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    }

  }

  hasLastFilters(): boolean {

    if (this.route.snapshot.queryParamMap.has('filter') === false
      && this.route.snapshot.queryParamMap.has('search') === false
      && this.route.snapshot.queryParamMap.has('pageNum') === false)  
    {

      this.lastFilter = null;
      this.lastSearchFilter = null;
      this.Period = null;
      this.lastPage = null;

      return false;

    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter');
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search');
    }

    if (this.route.snapshot.queryParamMap.has('dataPeriodo')) {
      this.Period = this.route.snapshot.queryParamMap.get('dataPeriodo');
    }

    if (this.route.snapshot.queryParamMap.has('pageNum')) {
      this.lastPage = this.route.snapshot.queryParamMap.get('pageNum');
    }

    return true;

  }

  settingPlaceHolder(filter): void{

    if (filter == null || filter === undefined) {
      filter = 'NOME_CLIENTE';
    }

    this.filter = filter;

    console.log(this.filter);

    switch (filter) {
      case 'NOME_CLIENTE':
        this.situacao = 'AGUARDANDO_CANCELAMENTO';
        this.inputPeriodo = false;
        this.inputDisabled = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
       
        break;
    }
  }
}
