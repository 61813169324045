import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Page } from 'app/main/shared/model/page/page.model';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-dashboard-pendencias',
  templateUrl: './dashboard-pendencias.component.html',
  styleUrls: ['./dashboard-pendencias.component.scss']
})
export class DashboardPendenciasComponent implements OnInit {

  @Input() rows: Array<any>;
  @Input() page: Page;
  @Input() title;
  @Input() showAcoes:boolean;

  @Output() regularizar: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  @Output() alterarParcelaEvent: EventEmitter<any> = new EventEmitter();
  @Output() receberParcelaEvent: EventEmitter<any> = new EventEmitter();

  selected: Array<any>;
  selectEvent: EventEmitter<any> = new EventEmitter<any>();
  timeout: any;
  permissoes = [];
  constructor(private transferenciaService: TransferenciaService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<any>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'))
    perm.forEach(element => {
      this.permissoes.push(element)
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectCostumers();
  }

  onSelectCostumers() {
    this.selectEvent.emit(this.selected);
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
    // this.transferenciaService.page("tfudf",10,event.offset + 1).subscribe(data => {
    //   this.rows = data.object.content.filter(element => element.origem.id == this.route.snapshot.params['id'])
    // })

  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  regularizarPendencia(value: any) {
    this.regularizar.emit(value)
  }

  onConfirm(value, situacao) {
   let confirmarRecebimentoTransferenciaTO = {
      value : value,
      situacao : situacao
    }
    this.confirm.emit(confirmarRecebimentoTransferenciaTO)
  }

  alterarParcela(value) {
    this.alterarParcelaEvent.emit(value)
  }

  receberParcela(value): void {
    this.receberParcelaEvent.emit(value)
  }

  buildNumber(numero: string) {
    if (numero.substring(0, 1) != '(') {
      if (numero.length == 11) {
        numero = `(${numero.substr(0, 2)})${numero.substr(2, 5)}-${numero.substr(7, 4)}`;
      } else {
        numero = `(${numero.substr(0, 2)})${numero.substr(2, 4)}-${numero.substr(6, 4)}`;
      }
    } else {
      numero
    }
    return numero;
  }

}
