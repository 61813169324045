import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODIN_API } from '../api/sgf.api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OdinService {

  constructor(private httpClient: HttpClient) {}

  listarProdutos(): Observable<{ codigo: string; dominio: string }[]> {
    return this.httpClient.get<{ codigo: string; dominio: string }[]>(`${ODIN_API}produto-sgf`);
  }
}
