import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from 'app/shared/api/sgf.api';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Bairro } from 'app/main/shared/model/activity/activity.model';
import { Estado } from 'app/main/shared/model/estado/estado.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private bairro = `${SGF_API}` + 'sgf/api/public/bairro';
  private estado = `${SGF_API}` + 'sgf/api/public/estado';
  private municipio = `${SGF_API}` + 'sgf/api/public/municipio';
  private endereco = `${SGF_API}sgf/api/public/endereco`;

  constructor(private httpClient: HttpClient) {}


  bairroByMunicipioId(municipioId:number): any {
    return this.httpClient.get(`${this.bairro}?municipio=eq:${municipioId}&size=1000`)
      .pipe(
        catchError(this.handleError));
  }

  bairroByMunicipioIdPage(municipioId:number, size:number, page:number): any {
    return this.httpClient.get(`${this.bairro}?municipio=eq:${municipioId}&size=${size}&page=${page}`)
      .pipe(
        catchError(this.handleError));
  }

  bairroByDescricao(descricao:string): any {
    return this.httpClient.get(`${this.bairro}?descricao=eq:${descricao}&size=1000`)
      .pipe(
        catchError(this.handleError));
  }

  pageEstado(): any {
    return this.httpClient.get(`${this.estado}?size=30`)
      .pipe(
        catchError(this.handleError));
  }

  estadoBySigla(sigla:string): any {
    return this.httpClient.get(`${this.estado}?sigla=eq:${sigla}`)
      .pipe(
        catchError(this.handleError));
  }

  municipioByEstadoId(estadoId:number): any {
    return this.httpClient.get(`${this.municipio}?estado=eq:${estadoId}&size=1000`)
      .pipe(
        catchError(this.handleError));
  }


  municipioByNome(nomeMunicipio:string): any {
    return this.httpClient.get(`${this.municipio}?nomeMunicipio=eq:${nomeMunicipio}`)
      .pipe(
        catchError(this.handleError));
  }

  municipioById(municipioId:number): any {
    return this.httpClient.get(`${this.municipio}/${municipioId}`)
      .pipe(
        catchError(this.handleError));
  }

  enderecoByCep(cep: string): any {
    return this.httpClient.get(`${this.endereco}/dadosporcep/${cep.replace('-','').replace(' ','')}`)
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

  private extractData(res: Response) {
    let body = res.json();
    return body as any;
  }
}
