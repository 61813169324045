import {Component, OnInit, Inject} from '@angular/core';
import {MessageDialogComponent} from '../../components/message-dialog/message-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {TransferenciaService} from 'app/main/financial/services/transferencia/transferencia.service';
import {SituationTransfer} from '../../model/enums/situation-transfer.enum';
import {FormControl, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import {Router} from '@angular/router';
import {ContractsService} from 'app/main/contracts/services/contracts.service';

export interface DialogData {
    confirmation: any;
    origem: boolean;
    situacao: string;
    id: number;
    type: string;
}

export namespace Situation {
    export function values(): any {
        return Object.keys(SituationTransfer).filter(
            (type) => isNaN(<any>type) && type !== 'values'
        );
    }
}

@Component({
    selector: 'app-confirmar-tipo-repasse',
    templateUrl: './confirmar-tipo-repasse.component.html',
    styleUrls: ['./confirmar-tipo-repasse.component.scss']
})
export class ConfirmarTipoRepasseComponent implements OnInit {

    confirmation: any;
    dialogRef: any;
    titulo: string;
    titleHeader: string;
    origem: any;
    message: string;
    situacao: string;
    disabledButton: boolean;
    transferencias: any[];
    confirmado: number;
    dialogMessages;
    form: FormGroup;
    liberarSaidas = false;
    repasseFimDeSemana = false;
    postoDigital = false;

    permitirRepasseFimDeSemana = new Date().getDay() === 5;

    t: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialogRef: MatDialogRef<ConfirmarTipoRepasseComponent>,
        private formBuilder: FormBuilder,
        private contractService: ContractsService,
        private _matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.postoDigital = this.data.contratos[0].posto.parametroPosto.pixBoleto;
        this.form = this.formBuilder.group({
            contratos: this.buildContratos()
        });
    }

    confirmTransfer(value): void {
        this.disabledButton = true;
        this.process(value.contratos);
        this.contractService.releaseContracts(value.contratos, this.liberarSaidas, this.repasseFimDeSemana).subscribe((response) => {
            if (response.success) {
                if (response.object && response.object.length > 0) {
                    const nomesCliente = response.object.map(contrato => {
                        return contrato.visita.cliente.pessoa.nomePessoa;
                      }).join(', ');
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `Alguns contratos não foram liberados, pois os clientes ${nomesCliente} já tem contrato ativo.`
                    };
                } else {
                    this.dialogMessages = {
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Contrato(s) liberado(s) com sucesso.`
                    };
                }

                if (this.data.print) {

                    const contratosNaoLiberados = response.object.map(con => con.id);

                    this.data.contratos.forEach(contrato => {
                        if (!contratosNaoLiberados.includes(contrato.id)) {
                            this.contractService.getContract(contrato.id).subscribe(ondata => {
                                const objectURL = URL.createObjectURL(ondata);
                                window.open(objectURL, '_blank').print();
                            });
                        }
                    });
                }
            } else {
                if (response.messages[0].text != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${response.messages[0].text}`
                    };
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    };
                }
            }
            this.feedback(this.dialogMessages);
            this.matDialogRef.close(true);
        });
    }

    process(contratos: any[]): void {
        contratos.forEach(contrato => {
            if (contrato.tipoRepasse === 'EFETIVACAO') {
                contrato.formaRepasse = 'COBRANCA';
            } else if (contrato.tipoRepasse === 'COMPLEMENTO') {
                contrato.formaRepasse = 'COMPLEMENTO';
                contrato.tipoRepasse = 'EFETIVACAO';
            } else if (contrato.tipoRepasse === 'PIX') {
                contrato.formaRepasse = 'PIX';
                contrato.tipoRepasse = 'PIX';
            }
        });
    }

    onCancel(): void {
        this.matDialogRef.close();
    }

    buildContratos(): any {
        const formContrato = this.data.contratos.map(contrato => {
            const cargo = contrato.responsavelRepasse.cargo.descricao;
            return this.formBuilder.group({
                contrato: new FormControl({value: contrato.id || null, disabled: true}),
                nomeCliente: new FormControl({value: contrato.visita.cliente.pessoa.nomePessoa || null, disabled: true}),
                contratoId: new FormControl(contrato.id || null),
                tipoRepasse: new FormControl(contrato.posto.parametroPosto.pixBoleto ? 'PIX' : cargo === 'CONTINUO' ? 'GERAL' : 'EFETIVACAO')
            });
        });
        return this.formBuilder.array(formContrato);

    }

    get contratoForms(): FormArray {
        return this.form.get('contratos') as FormArray;
    }

    private feedback(message: string[]): void {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }

}
