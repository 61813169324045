import { Injectable } from '@angular/core';
import { ProdutoService } from '../services/produto-service.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveProdutoService {

  constructor(private produtoService: ProdutoService) { }

  resolve(): Observable<any> {
    return this.produtoService.page(10,1, true).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste[`object`]);
      })
    )

  }
}
