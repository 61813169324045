import { Component, OnInit } from '@angular/core';
import { CustomersModel } from 'app/main/shared/model/customers/customers-model';
import { Page } from 'app/main/shared/model/page/page.model';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { CaptacaoService } from '../../captacao/services/captacao.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-captacoes-rejeitados-main',
  templateUrl: './captacoes-rejeitados-main.component.html',
  styleUrls: ['./captacoes-rejeitados-main.component.scss'],
  animations: fuseAnimations
})
export class CaptacoesRejeitadosMainComponent implements OnInit {

  rows: Array<CustomersModel>;
  itensAPI: Array<CustomersModel>;

  public loading = false;
  page = new Page();
  search;
  pageNumber: number;
  searchInput: FormControl;
  
  constructor(private _matDialog: MatDialog,
    private route: ActivatedRoute,
    private captadoService: CaptacaoService) { 

      this.searchInput = new FormControl('');

      this.route.data.subscribe(
        (res) => {
          this.rows = res.rows.content;
          this.setPage(res.rows);
          this.itensAPI = this.rows;
  
        }
      );

    }

  ngOnInit(): void {
  }

  over(): void {
    this.loading = true;
    this.search = null;
    this.captadoService.pageBySituacaoCaptado(this.pageNumber ? this.pageNumber : 1, 10, 'REJEITADO')
      .subscribe(
        (res) => {
          console.log(res);
          
          this.loading = false;
          this.rows = res.object.content;
          const page = res.object;
          page.pageNumber = this.pageNumber - 1;
          this.setPage(page);
          this.itensAPI = this.rows;
        }
      );
  }

  setPage(data): void {
    // Utilizando para o pageable
    this.pageNumber = data ? data.pageNumber : 0;
    this.page.totalPages = data ? data.totalPages : 0;
    this.page.size = data ? data.size : 0;
    this.page.totalElements = data ? data.totalElements : 0;
  }

  getDataFilter(event): void {
    this.rows = event.content;
    const page = event;
    page.pageNumber = 0;
    this.setPage(page);
  }

  getSearchFilter(event): void {
    this.search = event;
  }

  onRecaptacao(captacao): void {
    this.over();
  }

}
