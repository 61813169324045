import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CaptacaoService } from 'app/main/customers/components/captacao/services/captacao.service';
import { CustomersModel } from '../../model/customers/customers-model';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { Posto } from '../../model/posto/posto.model';
import { Employee } from '../../model/employee/employee.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-agendar-captacao',
  templateUrl: './agendar-captacao.component.html',
  styleUrls: ['./agendar-captacao.component.scss']
})
export class AgendarCaptacaoComponent implements OnInit {

  employee: any;
  postos: Array<Posto>;
  employees: Array<Employee> =  [];
  dialogMessages;
  disabledButton: boolean;
  agendamentoForm: FormGroup;
  dataVisita: Date = new Date();

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));

  private _onDestroy = new Subject<void>();

  /**
   * Constructor
   *
   * @param {MatDialogRef<AgendarCaptacaoComponent>} matDialogRef
   * @param _data
   * @param {CaptacaoService} captacaoService
   */
  constructor(
    public matDialogRef: MatDialogRef<AgendarCaptacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private captacaoService: CaptacaoService,
    private postoService: PostsService,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder) {

    // <TEMPORARIO>
    this.captacaoService.findAll().subscribe(
      (ondata) => {
        this.employee = ondata.object;
      }
    );
  }

  ngOnInit(): void {

    if (this.usuario.idPosto == null) {
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.postoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
      });
    } else {
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object; 
      });
      this.getVisitadores(this.usuario.idPosto);
    }

    this.setForm();

  }

  /**
   * Function Client Scheduling.
   * 
   * @description Function to confirm the client's schedule, where the required values ​​are passed.
   * 
   * @param {string[{}]} data `Array of objects` passed to execute client scheduling.
   * 
   * @returns void
   */
  onConfirm(value: any): void {

    const dataVisita = value.dataVisita instanceof Date ? value.dataVisita : value.dataVisita._d as Date;

    this.postos.forEach( posto => {
      if (posto.descricao === value.posto) {
        this.data.select.posto = posto;
      }
    });

    this.disabledButton = true;

    this.data.select.visitador = value.visitador;
    this.data.select.dataVisita = dataVisita;
    
    if (this.data.select.length != null) {

      this.data.select.forEach(element => {
        /**
         * @description setting up the employee and email, for Backend to work.
         */
        element.funcionario = this.employee;
        delete element.funcionario.pessoa.nomePessoa;
        delete element.funcionario.pessoa.documento;

        /**
         * @description service to approve the customer.
         */
        this.approve(element);
      });
    } else {
      this.approve(this.data.select);
    }
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  onNoClick(): void {
    this.matDialogRef.close();
  }

  private approve(element: any): void {
    this.captacaoService.aprovar(element).subscribe(
      (ondata: ReturnApi) => {
        if (ondata.success) {

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Agendado com sucesso`
          };

          this.feedback(this.dialogMessages);
        } else {
          if (ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
        }
        this.matDialogRef.close(ondata);

      }
    );
  }

  
  private filterPosto(): void {
    if (!this.postos) {
      return;
    }
    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private getVisitadores(idPosto: number): void {
    this.employeeService.findVisitadorByPosto(idPosto).subscribe(data => {
      this.employee = data.object;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterVisitador();
        });
    });
  }

  private filterVisitador(): void {
    if (!this.employee) {
      return;
    }
    const search = this.EmployeeFilterControl.value;
    this.filteredEmployee.next(
      // tslint:disable-next-line:triple-equals
      this.employee.filter(employee => employee.id == search)
    );
  }

  setForm(): void {

    if (this.data.select.posto != null ) {
      this.getVisitadores(this.data.select.posto.id);
    }

    const visitador = this.data.select.visitador ?  this.data.select.visitador.id : null;

    this.agendamentoForm = this.formBuilder.group({
      visitador: [ visitador, Validators.required],
      posto: [ this.data.select.posto ?  this.data.select.posto.descricao : null, Validators.required],
      dataVisita: [this.dataVisita, Validators.required],
    });
    
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

}
