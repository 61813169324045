import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-sobras-form',
  templateUrl: './sobras-form.component.html',
  styleUrls: ['./sobras-form.component.scss']
})
export class SobrasFormComponent implements OnInit, AfterViewInit {
    
  sobra: FormGroup;
  funcionarios: Employee[];
  disabledButton: boolean;

  @ViewChild('valor') valor: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private matDialogRef: MatDialogRef<SobrasFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transferenciaService: TransferenciaService,
    private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.setForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  setForm(): void { 
    this.sobra = this.formBuilder.group({
      idCaixaFuncionario: [this.data.idCaixaFuncionario],
      valor: [null, Validators.required]
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  save(sobra: any): void {
    this.lancarSobraEmLancamentoDeEntrada(sobra);
  }

  private lancarSobraEmLancamentoDeEntrada(sobra: any): void {
    this.transferenciaService.lancamentoSobraEmLancamentoDeEntrada(sobra).subscribe((response: ReturnApi) => {

      const isSucess = this.utilsService
        .feedbackApi(response, 'Lançamento de Sobra realizado com sucesso', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

      if (isSucess) {
        this.matDialogRef.close();
      }

    }, (error): void => {
      console.log(JSON.stringify(error));
      this.utilsService.feedbackInfo('Não foi possível lançar sobra, tente novamente.', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
    });
  }

}
