import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
  FormArray
} from '@angular/forms';
import * as _ from 'lodash';
import { Segment } from '../../model/segment/segment.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { SegmentsService } from 'app/main/global/components/segments/services/segments.service';

export interface DialogData {
  segment: Segment,
  action: string
}

@Component({
  selector: 'app-comercial-activity-form',
  templateUrl: './comercial-activity-form.component.html',
  styleUrls: ['./comercial-activity-form.component.scss']
})
export class ComercialActivityFormComponent implements OnInit {
  comercial: FormGroup;
  titleHeader: string;
  activeCheck: string;
  disabledButton: boolean;
  dialogMessages;

  @ViewChild('nome') nome: ElementRef;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private segmentService: SegmentsService,
    public matDialogRef: MatDialogRef<ComercialActivityFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
    this.activeCheck = (_data.segment.ativo) ? 'Ativo' : 'Inativo';
    this.changeTitleHeader();
    console.log(this._data.segment);

  }

  ngOnInit() {
    this.setFormGroup();
  }

  ngAfterViewInit() {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }

  changeTitleHeader() {
    if (this._data.action == 'new') {
      this.titleHeader = 'Nova Atividade Comercial';
    } else {
      this.titleHeader = 'Editar Atividade Comercial'
    }
  }


  setFormGroup() {
    this.comercial = this.formBuilder.group({
      id: this._data.segment.id || null,
      ativo: this._data.segment.ativo || true,
      descricao: [this._data.segment.descricao, Validators.required],
    })
  }

  onAdd() {
    this.disabledButton = true;
    this.segmentService.add(this.comercial.value).subscribe(seg => {
      if (seg.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${seg.messages[0].text}`
        }
      } else {
        if (seg.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${seg.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }

      this.feedback(this.dialogMessages);

      this.matDialogRef.close(seg.object);
    });
  }

  onUpdate(segment: Segment) {
    this.disabledButton = true;
    this.segmentService.updateSegment(this.comercial.value).subscribe(seg => {
      if (seg.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${seg.messages[0].text}`
        }
      } else {
        if (seg.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${seg.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }
      this.feedback(this.dialogMessages);
      this.matDialogRef.close(this.comercial.value);
    })
  }

  onClosed() {
    this.matDialogRef.close();
  }

  getErrorMessage(field: string) {
    return this.comercial.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onChange(isActive: boolean) {
    this._data.segment.ativo = !isActive;
    this.comercial.get('ativo').setValue(!isActive);
    console.log(this.comercial.get('ativo').value);

  }

}
