import { InjectionToken } from '@angular/core';
import {
  createStore,
  Store,
  compose,
  StoreEnhancer
} from 'redux';

import { 
  CaptacaoState,
  default as reducer 
} from './captacao.reducer';

export const CaptacaoStore = new InjectionToken('Captacao.store');

const devtools: StoreEnhancer<CaptacaoState> =
  window['devToolsExtension'] ?
  window['devToolsExtension']() : f => f;

export function createCaptacaoStore(): Store<CaptacaoState> {
  return createStore<CaptacaoState>(
    reducer,
    compose(devtools)
  );
}

export const captacaoStoreProviders = [
   { provide: CaptacaoStore, useFactory: createCaptacaoStore }
];
