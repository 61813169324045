import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import { SegmentsService } from '../services/segments.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveSegmentsService implements Resolve<any> {

  constructor(
    private segmentService: SegmentsService) { }

  resolve(): Observable<any> {
    return this.segmentService.findAll().pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }

}
