import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PersonModel } from 'app/main/shared/model/person/persons.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgenciaService {

  private agencia = `${SGF_API}`+"sgf/api/public/agencia"

  constructor(
    private httpClient: HttpClient) {
  }

  findAll(): Observable<any> {
    return this.httpClient.get<any[]>(this.agencia)
      .pipe(
        catchError(this.handleError)
      )
  }

  add(agencia: any): Observable<any> {
    const body = JSON.stringify(agencia);
    return this.httpClient.post(this.agencia, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(agencia: any): Observable<any> {
    const body = JSON.stringify(agencia);
    return this.httpClient.put(this.agencia, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

}
