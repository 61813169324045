import { Address } from '../address/address.model';
import { ContaBancaria } from '../conta-bancaria/conta-bancaria.model';
import { Telephone } from '../telephone/telephone.model';
import { User } from '../user/user.model';
import { PersonPf } from './person-pf.model';
export class PersonModel{

    ativo?: boolean;
    id?: number;
    type?: string;
    email?: string;
    nomeFantasia?: string;
    razaoSocial?: string;
    nome?: string;
    nomePessoa?: string;
    nomeFantasiaPessoa?: string;
    cpf?: string;
    cnpj?: string;
    listEndereco?: Array<Address>;
    listTelefone?: Array<Telephone>;
    listContaBancaria?: Array<ContaBancaria>;
    usuario?: User;
    dataVencimentoCnh?: string;
    dataNascimento?: string;
    cnh?: string;
    documento?: string;
    rg?: string;
    identificador?: string;
    sexo: any;
    listSocio: PersonPf[];
    nomeProcurador?: string;
    documentoProcurador?: string;
    cpfProcurador?: string;
    emailProcurador?: string;

    constructor(obj?: any){
        this.ativo = obj ? obj.ativo : true;
        this.id = obj ? obj.id : null;
        this.type = obj ? obj.type : 'pf';
        this.email = obj ? obj.email : null;
        this.nomeFantasia = obj ? obj.nomeFantasia : null;
        this.razaoSocial = obj ? obj.razaoSocial : null;
        this.nome = obj ? obj.nome : null;
        this.nomePessoa = obj ? obj.nomePessoa : null;
        this.nomeFantasiaPessoa = obj ? obj.nomeFantasiaPessoa : null;
        this.cpf = obj ? obj.cpf : null;
        this.cnpj = obj ? obj.cnpj : null;
        this.listEndereco = obj ? obj.listEndereco : [new Address({})];
        this.listTelefone = obj ? obj.listTelefone : [new Telephone({})];
        this.listContaBancaria = obj ? obj.listContaBancaria : [new ContaBancaria({})];
        this.usuario = obj ? obj.usuario : new User({});
        this.dataVencimentoCnh = obj ? obj.dataVencimentoCnh : null;
        this.dataNascimento = obj ? obj.dataNascimento : null;
        this.cnh = obj ? obj.cnh : null;
        this.documento = obj ? obj.documento : null;
        this.rg = obj ? obj.rg : null;
        this.identificador = obj ? obj.identificador : null;
        this.sexo = obj ? obj.sexo : null;
        this.listSocio = obj ? obj.listSocio : [new PersonPf({})];
        this.nomeProcurador = obj ? obj.nomeProcurador : null;
        this.documentoProcurador = obj ? obj.documentoProcurador : null;
        this.cpfProcurador = obj ? obj.cpfProcurador : null;
        this.emailProcurador = obj ? obj.emailProcurador : null;

    }
}
