import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Segment } from 'app/main/shared/model/segment/segment.model';
import { CaptacaoService } from 'app/main/customers/components/captacao/services/captacao.service';
import { fuseAnimations } from '@fuse/animations';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { Mask } from '@fagnerlima/ng-mask';
import { Cnae } from '../../../model/cnae/cnae';

@Component({
  selector: 'app-dados-pessoais',
  templateUrl: './dados-pessoais.component.html',
  styleUrls: ['./dados-pessoais.component.scss'],
  animations: [fuseAnimations]
})
export class DadosPessoaisComponent implements OnInit {

  @Input() visit: FormGroup;
  filteredOptions: Observable<Segment[]>;
  listFuncionarios: Array<Employee>;
  maskCpf = new Mask('000.000.000-00');
  maskCnpj = new Mask('00.000.000/0000-00');
  maskVencimento = new Mask('00/00');
  maskCartao = new Mask('0000000000000000');

  public indicadorFilterControl: FormControl = new FormControl();
  public postoFilterControl: FormControl = new FormControl();

  cnaes;
  currentCnae = null;

  constructor(
      private captacaoService: CaptacaoService,
      private formBuilder: FormBuilder
  ) {

    this.captacaoService.getAtividade().subscribe(
      (ondata) => {
        this.filteredOptions = ondata.object.content;
      }
    );

  }

  ngOnInit(): void {
    this.captacaoService.getCnae()
        .subscribe(data => {
      this.cnaes = data.object.content;
    });
  }

  public serviceOnSelect(cnae: any): void {
    this.currentCnae = cnae;
    this.visit.get('cnae').setValue(this.currentCnae);
  }

  displayFn(cnae: Cnae): string {
    return cnae ? cnae.descricao : '';
  }

  doFilter(evento: Event): any{
    const target = evento.target as HTMLTextAreaElement;

    this.captacaoService.getCnaeFilterDescricao(target.value).subscribe( data => {
      this.cnaes = data.object.content;
    });

  }

  get phoneForms(): FormArray {
    return this.visit.get('pessoa.listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null),
      tipoSmartphone: new FormControl(false),
      possuiInternet: new FormControl(false),
      instalarAppCliente: new FormControl(false)
    });
    this.phoneForms.push(phone);
  }

  get addressForms(): FormArray {
    return this.visit.get('pessoa.listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  setCpfField(isCpf: boolean): void {
    this.visit.get('pessoa.isCpf').setValue(isCpf);
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  recarregarValidators(evento: string): void {

    if (evento === 'RG'){
      this.visit.controls['pessoa'].get('rg').setValidators(Validators.required);
      this.visit.controls['pessoa'].get('rgOrgaoEmissor').setValidators(Validators.required);
      this.visit.controls['pessoa'].get('rgUf').setValidators(Validators.required);
      this.visit.controls['pessoa'].get('rgDataEmissao').setValidators(Validators.required);


      this.visit.controls['pessoa'].get('ctps').clearValidators();
      this.visit.controls['pessoa'].get('ctpsDataEmissao').clearValidators();
      this.visit.controls['pessoa'].get('ctps').updateValueAndValidity();
      this.visit.controls['pessoa'].get('ctpsDataEmissao').updateValueAndValidity();


      this.visit.controls['pessoa'].get('cnh').clearValidators();
      this.visit.controls['pessoa'].get('dataVencimentoCnh').clearValidators();
      this.visit.controls['pessoa'].get('cnh').updateValueAndValidity();
      this.visit.controls['pessoa'].get('dataVencimentoCnh').updateValueAndValidity();

    }

    if (evento === 'CTPS'){
     this.visit.controls['pessoa'].get('rg').clearValidators();
     this.visit.controls['pessoa'].get('rgOrgaoEmissor').clearValidators();
     this.visit.controls['pessoa'].get('rgUf').clearValidators();
     this.visit.controls['pessoa'].get('rgDataEmissao').clearValidators();
     this.visit.controls['pessoa'].get('rg').updateValueAndValidity();
     this.visit.controls['pessoa'].get('rgOrgaoEmissor').updateValueAndValidity();
     this.visit.controls['pessoa'].get('rgUf').updateValueAndValidity();
     this.visit.controls['pessoa'].get('rgDataEmissao').updateValueAndValidity();

     this.visit.controls['pessoa'].get('ctps').setValidators(Validators.required);
     this.visit.controls['pessoa'].get('ctpsDataEmissao').setValidators(Validators.required);


     this.visit.controls['pessoa'].get('cnh').clearValidators();
     this.visit.controls['pessoa'].get('dataVencimentoCnh').clearValidators();
     this.visit.controls['pessoa'].get('cnh').updateValueAndValidity();
     this.visit.controls['pessoa'].get('dataVencimentoCnh').updateValueAndValidity();

    }
    if (evento === 'CNH'){
      this.visit.controls['pessoa'].get('rg').clearValidators();
      this.visit.controls['pessoa'].get('rgOrgaoEmissor').clearValidators();
      this.visit.controls['pessoa'].get('rgUf').clearValidators();
      this.visit.controls['pessoa'].get('rgDataEmissao').clearValidators();
      this.visit.controls['pessoa'].get('rg').updateValueAndValidity();
      this.visit.controls['pessoa'].get('rgOrgaoEmissor').updateValueAndValidity();
      this.visit.controls['pessoa'].get('rgUf').updateValueAndValidity();
      this.visit.controls['pessoa'].get('rgDataEmissao').updateValueAndValidity();


      this.visit.controls['pessoa'].get('ctps').clearValidators();
      this.visit.controls['pessoa'].get('ctpsDataEmissao').clearValidators();
      this.visit.controls['pessoa'].get('ctps').updateValueAndValidity();
      this.visit.controls['pessoa'].get('ctpsDataEmissao').updateValueAndValidity();

      this.visit.controls['pessoa'].get('cnh').setValidators(Validators.required);
      this.visit.controls['pessoa'].get('dataVencimentoCnh').setValidators(Validators.required);
    }
  }
}
