import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})
export class ConfirmarComponent implements OnInit {

  text: string;

  constructor(private matDialogRef: MatDialogRef<ConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data) {
        this.text = data.text ? data.text : 'Você tem certeza de que deseja continuar essa ação?';
      } else {
        this.text = 'Você tem certeza de que deseja continuar essa ação?';
      }
     }

  ngOnInit(): void {
  }

  onConfirm(): void{
    this.matDialogRef.close(true);
  }

  onClosed(): void{
    this.matDialogRef.close(false);
  }

}
