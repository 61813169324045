import { Employee } from '../employee/employee.model';
import { Posto } from '../posto/posto.model';
import { Team } from '../team/team.model';
import { Rota } from '../rota/rota.model';

export class LancamentoAgenda {
    id: number;
    descricao: string;
    posto: Posto;
    equipe: Team;
    rota: Rota;
    fiscal: Employee;
    dataAgendamento: Date;
    observacao: string;

    constructor(obj?: any){
        this.setTeam(obj);
    }

    private setTeam(obj?: LancamentoAgenda): void{
        this.id = obj ? obj.id : null;
        this.descricao = obj ? obj.descricao : null;
        this.posto = obj ? obj.posto : null;
        this.equipe = obj ? obj.equipe : null;
        this.rota = obj ? obj.rota : null;
        this.fiscal = obj ? obj.fiscal : null;
        this.dataAgendamento = obj ? obj.dataAgendamento : null;
        this.observacao = obj ? obj.observacao : null;

    }
}
