import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { DiscountService } from '../services/discount.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveDiscountService implements Resolve<any> {

  
  constructor(
    private discountService: DiscountService) { }

  resolve(): Observable<any> {
    return this.discountService.findAll().pipe(
       take(1),
       mergeMap(discount => {
        return of(discount['object']);
       })
     );
  }

}
