import { Injectable } from "@angular/core";
import { SGF_API } from "app/shared/api/sgf.api";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ParameterService {
    private host = `${SGF_API}` + 'sgf/api/public/parametro'

    constructor(private httpClient: HttpClient) { }


    saveParametros(parametro: any): Observable<any> {
        const body = JSON.stringify(parametro);

        return this.httpClient.post(`${this.host}/cadastrar-parametro`, body, this.httpOptions()).pipe(
            catchError(this.handleError)
        );
    }

    findByNome(nome: string): Observable<any> {
        return this.httpClient.get(`${this.host}/recuperarValorPorNome/`+nome, this.httpOptions()).pipe(
            catchError(this.handleError)
        )
    }

    httpOptions(token?: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': ''
            })
        }
    }

    httpOptionsPdf(token?: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
                'Authorization': ''
            })
        };
    }

    private handleError(error: HttpErrorResponse): any {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(
            'Something bad happened; please try again later.');
    }
}