import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CaptacaoService } from 'app/main/customers/components/captacao/services/captacao.service';
import { CustomersModel } from '../../model/customers/customers-model';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { Posto } from '../../model/posto/posto.model';
import { Employee } from '../../model/employee/employee.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { takeUntil } from 'rxjs/operators';
import { VisitasService } from 'app/main/customers/components/visitas/services/visitas.service';

@Component({
  selector: 'app-reativar-cliente',
  templateUrl: './reativar-cliente.component.html',
  styleUrls: ['./reativar-cliente.component.scss']
})
export class ReativarClienteComponent implements OnInit {

  dialogMessages;
  disabledButton: boolean;
  visit;

  public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));

  private _onDestroy = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<ReativarClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private visitaService: VisitasService) {

      this.visit = this.data.select;
  }

  ngOnInit(): void {

  }

  onConfirm(): void {

    this.disabledButton = true;

    this.approve(this.visit);
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  onNoClick(): void {
    this.matDialogRef.close();
  }

  private approve(element: any): void {
    this.visitaService.reativarCliente(element).subscribe(
      (ondata: ReturnApi) => {
        if (ondata.success) {

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Agendado com sucesso`
          };

          this.feedback(this.dialogMessages);
        } else {
          if (ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
        }
        this.matDialogRef.close(ondata);

      }
    );
  }

}
