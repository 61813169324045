import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize, GalleryRef } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ActivatedRoute } from '@angular/router';
import { VisitadosService } from '../../../customers/components/visitados/services/visitados.services';
import { SGF_API } from '../../../../shared/api/sgf.api';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Contract } from '../../model/contract/contract.model';
import { ContractsService } from 'app/main/contracts/services/contracts.service';

export interface DialogData {
  contrato: Contract;
  action: string;
}

@Component({
  selector: 'app-contrato-gallery',
  templateUrl: './contrato-gallery.component.html',
  styleUrls: ['./contrato-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContratoGalleryComponent implements OnInit {

  idContrato: number;
  urlPhoto = `${SGF_API}` + 'sgf/api/public/arquivocontrato/findbycodigoincontext/';

  items: ImageItem[];
  imageData;
  constructor(public gallery: Gallery,
    public lightbox: Lightbox,
    private contratoService: ContractsService,
    private route: ActivatedRoute) {
      
    this.idContrato = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    const lightboxRef = this.gallery.ref('lightbox');

    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Bottom,
      gestures: false,
      counter: false,
      zoomOut: 3,

    });

    this.contratoService.getCodeByVisit(this.idContrato).subscribe(data => {
      lightboxRef.load(data.object.map(fotos => {
        return new ImageItem({ src: this.urlPhoto + fotos.codigo, thumb: this.urlPhoto + fotos.codigo })
      }));
    });
  }
}
