export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HOME'        : {
                'TITLE': 'Home',
                'BADGE': '25'
            },
            'CUSTOMERMANAGEMENT'        : {
                'TITLE': 'Customer Management',
                'ITEMA': 'Customers Collect',
                'ITEMB': 'Scheduled Visits',
                'ITEMC': 'Visited Customers',
                'ITEMD': 'Credit Aprovation',
                'BADGE': ''
            },
            'CONTRACTMANAGEMENT'        : {
                'TITLE': 'Contract Management',
                'ITEMA': 'Generated Contracts',
                'ITEMB': 'Released Contracts',
                'ITEMC': 'Contracts In Progress',
                'ITEMD': 'Canceled Contracts',
                'BADGE': ''
            },
            'GLOBALSERVICES'        : {
                'TITLE': 'Global services',
                'ITEMA': 'Users Management',
                'ITEMB': 'Teams Management',
                'ITEMC': 'Segments Management',
                'ITEMD': 'User Profiles Management',
                'ITEME': 'Comercial Activities Management',
                'BADGE': ''
            }
        }
    }
};