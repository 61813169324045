import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VisitModel } from '../../model/visit/visit-model';
import { CaptacaoService } from '../../../customers/components/captacao/services/captacao.service';
import { TeamService } from '../../../global/components/teams/services/team.service';
import { Team } from '../../model/team/team.model';

@Component({
  selector: 'app-atendimento-detalhe',
  templateUrl: './atendimento-detalhe.component.html',
  styleUrls: ['./atendimento-detalhe.component.scss']
})
export class AtendimentoDetalheComponent {

  displayedColumns: string[] = ['cliente', 'vendedor', 'dataVisita'];
  visitado: VisitModel[];
  teams: Array<Team> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _captadoService: CaptacaoService,
    private teamService: TeamService,
    public matDialogRef: MatDialogRef<AtendimentoDetalheComponent>,
  ) {

    this.visitado = [
      Object.assign({}, new VisitModel(_data.visit), { dataCaptado: '' })
    ];
    this.buscaDataCaptado();
  }

  buscaDataCaptado() {
    this.visitado.forEach(v => {
      this._captadoService.getCaptadoByID(v.id).subscribe(
        data => {
          v['dataCaptado'] = data.object.dataHoraCadastro;
        }
      )
    })
  }

  getSupervisor(vendedor: any) {
    let supervisor: any;
    let exists: boolean = false;
    // this.teams.forEach(data => {
    //   data.listFuncionarios.forEach(func => {
    //     if (func.funcao == 'VENDEDOR' && func.funcionario.pessoa.id == vendedor.pessoa.id) {
    //       exists = true;
    //     }

    //     if (exists && func.funcao == 'SUPERVISOR') {
    //       supervisor = func.funcionario.pessoa.nomePessoa;
    //     }
    //   })
    // })

    return supervisor;

  }

  onClosed() {
    this.matDialogRef.close();
  }

}
