export const locale = {
    lang: 'pt_br',
    data: {
        'APPLICATIONS': 'Aplicação',
        'HOME': {
            'TITLE': 'Início',
            'BADGE': '25'
        },
        'CUSTOMERMANAGEMENT': {
            'TITLE': 'Gestão de Clientes',
            'ITEMA': 'Captar Cliente',
            'ITEMB': 'Clientes Captados',
            'ITEMC': 'Visitas Agendadas',
            'ITEMD': 'Visitados',
            'ITEME': 'Aprovação de Crédito',
            'ITEMF': 'Clientes',
            'ITEMG': 'Captações Rejeitadas',
            'ITEMH': 'Acompanhamento de Visitas',
            'ITEMI': 'Planejamento de Cliente',
            'ITEMJ': 'Clientes Reativados',
 
            'BADGE': '25'
        },
        'CONTRACTMANAGEMENT': {
            'TITLE': 'Gestão de Contratos',
            'ITEMA': 'Contratos Gerados',
            'ITEMB': 'Contratos Liberados',
            'ITEMC': 'Contratos em Andamento',
            'ITEMD': 'Acompanhamento de Contratos',
            'ITEME': 'Contratos a Cancelar',
            'ITEMF': 'Contratos Reagendados',
            'ITEMG': 'Acompanhamento Novos Renovados',
            'ITEMJ': 'Acomp. Contratos Entregues/Não Entregues',
            'ITEMH': 'Contratos Liberados para Assinatura',
            'ITEMK': 'Contratos Assinados',
            'BADGE': ''
        },
        'FiNANCEMANAGEMENT': {
            'TITLE': 'Gestão Financeira',
            'ITEMA': {
                'TITLE': 'Caixas',
                'ITEMA': 'Caixas Abertos',
                'ITEMB': 'Caixa de Cobrança',
                'ITEMC': 'Caixa de Controle',
                'ITEMD': 'Caixa de Despesas',
                'ITEME': 'Caixa Depósito Cobrança',
                'ITEMF': 'Caixa de Folha de Pagamento',
                'ITEMG': 'Caixa Gastos Gerais',
                'ITEMH': 'Caixa Geral',
                'ITEMI': 'Caixa Provisão',
                'ITEMJ': 'Caixa de Repasse',
                'ITEML': 'Caixa de Sangria',
                'ITEMM': 'Conferência de Caixa Geral',
            },
            'ITEMB': {
                'TITLE': 'Caixas Funcionário',
                'ITEMA': 'Caixa Dívida Funcionário',
                'ITEMB': 'Caixa Funcionário',
                'ITEMC': 'Caixa Despesa Funcionário',
                'ITEMD': 'Caixa Pagamento Funcionário',
            },
            'ITEMD': 'Acompanhamento de Recebimento',
            'ITEME': 'Despesas',
            'ITEMF': 'Pendências',
            'ITEMG': 'Lançamentos Saídas',
            'ITEMH': 'Lançamentos Entradas',
            'ITEMI': 'Confirmar Recebimentos',
            'ITEMJ': 'Confirmação Pendência',
            'ITEMK': 'Arquivo de retorno',
            'ITEML': 'Análise de Sobras',
            'ITEMM': 'Confirmar Crédito de Sobra',
            'ITEMN': 'Faltas',
            'ITEMO': 'Folha Cobrança',
            'ITEMW': 'Motivo Contrato Cancelado',
            'ITEMP': 'Repasses',

            'BADGE': '25'
        },
        'HUMANRESOURCES': {
            'TITLE': 'Gestão Administrativa',
            'ITEMA': 'Benefício',
            'ITEMB': 'Comissões',
            'ITEMC': 'Descontos',
            'ITEMD': 'Feriados',
            'ITEME': 'Folha de Pagamento',
            'ITEMF': 'Frequência',
            //'ITEMG': 'Gestão de Cargos',
            'ITEMH': 'Gestão de Postos',
            'ITEMI': 'Gestão de Equipes',
            'ITEMJ': 'Gestão de Funcionários',
            'ITEML': 'Impostos',
            'ITEMM': 'Gestão de Rotas',
            'BADGE': ''
        },

        'GLOBALSERVICES': {
            'TITLE': 'Serviços Globais',
            'ITEMA': {
                'TITLE': 'Gestão de Usuários',
                'ITEMA': 'Usuários',
                'ITEMB': 'Gestão de Perfis',
                'ITEMC': 'Gestão de Veículos',
            },
            'ITEMB': 'Atividade Comercial',
            'ITEMC': 'Área de Atuação',
            'ITEMD': 'Bairros',
            'ITEME': 'Empresas',
            'ITEMF': 'Agências',
            'ITEMG': 'Bancos',
            'ITEMH': 'Contas',
            'ITEMI': 'Máquina de Cartão',
            'ITEMJ': 'Produtos',
            'ITEMK': 'Marcas',
            'ITEML': 'Modelos',
            'ITEMM': 'Lançamento Agenda',
            'ITEMN': 'Agenda',
            'BADGE': '25'
        },

        // 'GERACAORELATORIO': {
        //     'TITLE': 'Geração de Relatórios',
    
        // },

        'PROCESSAMENTOS': {
            'TITLE': 'Processamentos',
            'ITEMA': 'Processar Lançamentos'
        },

        'RELATORIOS': {
            'TITLE': 'Relatórios',
            'ITEMA': 'Relatório por Período'
        },
        'ROTA': {
            'TITLE': 'Gerar Rota',
            'ITEMA': 'Download'
        },
        'PARAMETRO': {
            'TITLE': 'Sistema',
            'ITEMA': 'Cadastro de Parâmetro'
        }
    }
};
