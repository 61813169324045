import {Component, Inject, OnInit} from '@angular/core';
import {ClienteService} from '../../../customers/components/cliente/services/cliente.service';
import {Cliente} from '../../model/cliente/cliente.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ContractsService} from '../../../contracts/services/contracts.service';
import {UtilsService} from '../../../../shared/util/utils.service';
import {TimeMensagem} from '../../model/enums/time-mensagem.enum';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';


@Component({
    selector: 'app-cliente-select-form',
    templateUrl: './cliente-select-form.component.html',
    styleUrls: ['./cliente-select-form.component.scss']
})
export class ClienteSelectFormComponent implements OnInit {

    dialogMessages;
    currentCliente: any;

    clientes: any;

    constructor(
        public dialog: MatDialog,
        private clienteService: ClienteService,
        private contratoService: ContractsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ClienteSelectFormComponent>,
        private utilService: UtilsService
    ) {
    }

    ngOnInit(): any {
    }

    onCancel(): any {
        this.dialogRef.close(false);
    }

    doFilter(evento: Event): any {
        const target = evento.target as HTMLTextAreaElement;
        this.clienteService.findClienteByNome(target.value).subscribe(data => {
            this.clientes = data.object.content;
        });
    }

    serviceOnSelect(value: any): any {

    }

    displayFn(cliente: Cliente): string {
        return cliente ? cliente.pessoa.nome : '';
    }


    // @ts-ignore
    save(cliente: any): any {
        this.contratoService.alterarTitularidade(cliente.value.id, this.data.contrato.id).subscribe(
            ondata => {
                if (ondata.success) {
                    this.utilService.feedbackSuccess('Titularidade alterada com sucesso...', TimeMensagem.TIME_MENSAGEM);
                } else {
                    if (ondata.messages[0].text != null) {
                        this.utilService.feedbackInfo(ondata.messages[0].text, TimeMensagem.TIME_MENSAGEM);
                    }
                    this.onCancel();
                }
            },
            (onerror) => {
                this.utilService.feedbackInfo('Erro ao localizar cliente! ', TimeMensagem.TIME_MENSAGEM);
                this.onCancel();
            }
        );

        this.onCancel();

    }

}
