import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../../shared/shared.module';
import { SharedMaterialComponentsModule } from '../../../../shared-material-components.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToCancelMainComponent } from './to-cancel-main/to-cancel-main.component';
import { ToCancelSidebarComponent } from './to-cancel-sidebar/to-cancel-sidebar.component';
import { ToCancelListComponent } from './to-cancel-list/to-cancel-list.component';

const routes: Routes = [
  {
    path: '',
    component: ToCancelMainComponent,
    resolve: {}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxPermissionsModule.forRoot({})
  ],
  declarations: [ToCancelMainComponent, ToCancelListComponent, ToCancelSidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ToCancelModule { }
