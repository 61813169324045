import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { Employee } from '../../model/employee/employee.model';

export interface DialogData {
  employee: Employee;
  action: string;
}

@Component({
  selector: 'app-employee-deactivate',
  templateUrl: './employee-deactivate.component.html',
  styleUrls: ['./employee-deactivate.component.scss'],
})
export class EmployeeDeactivateComponent implements OnInit {
  titleHeader: string;
  action: string;
  funcionario: Employee;
  dialogMessages;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private utilsService: UtilsService,
    public matDialogRef: MatDialogRef<EmployeeDeactivateComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData,
  ) {
    this.funcionario = this._data.employee;
  }

  ngOnInit() {}

  changeTitleHeader() {
    if (this._data.employee.ativo == true) {
      this.titleHeader = 'Desativar Funcionário';
      this.action = 'desativar';
    } else {
      this.titleHeader = 'Ativar Funcionário';
      this.action = 'ativar';
    }
  }

  onClosed() {
    this.matDialogRef.close();
  }

  update(object) {
    if (object) {
      this.funcionario.ativo = !this.funcionario.ativo;
      delete this.funcionario.rota
      this.employeeService.updateEmployee(this.funcionario).subscribe((data) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`,
          };

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data.object);
        } else {
          if (data.messages != null) {
            let message = data.messages[0].text;
            if (message.includes('messageTemplate')) {
              message = message.substring(
                message.lastIndexOf('=') + 2,
                // tslint:disable-next-line:quotemark
                message.lastIndexOf("''"),
              );
            } else {
              message = message.includes(' - ') ? `${message.split(' - ', 2)[1]}` : message;
            }
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: message,
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }
      });
    }
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 5000);
  }
}
