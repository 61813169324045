import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements OnInit {

  @Input() phone: FormControl;
  @Input() required: boolean;
  @Input() checkedSmartphone: FormControl;
  @Input() checkedPossuiInternet: FormControl;
  @Input() checkedInstalarAppCliente: FormControl;
  @Input() dadosExtrasTelefone;
  constructor(
      @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
  }


  ngOnInit(): void {}



}

