import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-forma-pagamento-selecao',
  templateUrl: './forma-pagamento-selecao.component.html',
  styleUrls: ['./forma-pagamento-selecao.component.scss']
})
export class FormaPagamentoSelecaoComponent implements OnInit {

  formaPagamento = 'TED';

  constructor(private dialogRef: MatDialogRef<FormaPagamentoSelecaoComponent>) { }

  ngOnInit(): void {
    // do stuff
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.formaPagamento);
  }

}
