import { SGF_API } from "app/shared/api/sgf.api";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { ContratoLancamento } from "app/main/shared/model/contrato-lancamento/contrato-lancamento.model";
import { catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ContratoLancamentoService {
    private host = `${SGF_API}` + 'sgf/api/public/contrato-lancamento';

    constructor(private httpClient: HttpClient){
    }

    update(lancamento: any): Observable<any> {
        const body = JSON.stringify(lancamento);
        return this.httpClient.put<ContratoLancamento[]>(`${this.host}/editar-lancamento`, body, this.httpOptions()).pipe(
            catchError(this.handleError)
        )
    }

    httpOptions(token?: string): any {
        return {
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': ''
            })
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
        } else {
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        return throwError(
          'Something bad happened; please try again later.');
    }
}