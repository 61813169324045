import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnChanges } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { Page } from 'app/main/shared/model/page/page.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-in-progress-list',
  templateUrl: './in-progress-list.component.html',
  styleUrls: ['./in-progress-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InProgressListComponent implements OnInit, OnChanges {

  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortBoletos = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;

  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  // tslint:disable-next-line:triple-equals
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;

  lastPage;

  @Input() rows: any;
  @Input() page: Page;
  @Input() pageNum: any;
  @Input() filter: any;
  @Input() dataPeriodo: any;
  @Input() search: any;
  @Input() selectedClear;
  timeout: any;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() alterarContratoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() alterarMaquinaEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() agendarPagamentoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() desvincularContratoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() calcularJurosEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() mudarSituacaoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() vincularMaquinaEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() gerarBoletosEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pdfComodataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() confissaoDividaBoletoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() alterarProdutoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() renegociarEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() renegociarDividaEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() repactuarDividaEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelarBoletosEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() imprimirRelatorioRecebidosEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() imprimirRelatorioRecebimentoEvent: EventEmitter<any> = new EventEmitter<any>();


  selected: Array<Contract>;
  permissoes = [];
  constructor(private permissionsService: NgxPermissionsService,
    private router: Router, private route: ActivatedRoute ) {
    this.selected = new Array<Contract>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void { 
    
  }

  goToDetails(id: any): void {
    this.router.navigate(['/contracts/em-andamento/detail/' + id], 
      {queryParams: {filter: this.filter, search: this.search, pageNum: this.pageNum, dataPeriodo: this.dataPeriodo}});
  }

  ngOnChanges(): void {
    if (this.selectedClear) {
      this.selected = new Array<Contract>();
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
    this.pageNumber.emit(event.offset + 1);
    this.pageNum = (event.offset + 1);
  }

  onSelect({ selected }): void {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectCostumers();
  }

  onSelectCostumers(): void {
    this.selectEvent.emit(this.selected);
  }

  alterarContrato(value): void {
    this.alterarContratoEvent.emit(value);
  }

  alterarMaquina(value): void {
    this.alterarMaquinaEvent.emit(value);
  }

  agendarPagamento(value): void {
    this.agendarPagamentoEvent.emit(value);
  }

  desvincularContrato(value): void {
    this.desvincularContratoEvent.emit(value);
  }

  calcularJuros(value): void {
    this.calcularJurosEvent.emit(value);
  }

  mudarSituacao(value): void {
    this.mudarSituacaoEvent.emit(value);
  }

  vincularMaquina(value): void {
    this.vincularMaquinaEvent.emit(value);
  }

  gerarBoletos(value): void {
    this.gerarBoletosEvent.emit(value);
  }

  printPDFComodata(value): void {
    this.pdfComodataEvent.emit(value);
  }

  confissaoDividaBoleto(value: any): void {
    this.confissaoDividaBoletoEvent.emit(value);
  }

  
  alterarProduto(value): void{
    this.alterarProdutoEvent.emit(value);
  }

  renegociar(value): void {
    this.renegociarEvent.emit(value);
  }

  renegociarDivida(value): void {
    this.renegociarDividaEvent.emit(value);
  }

  repactuarDivida(value): void {
    this.repactuarDividaEvent.emit(value);
  }

  cancelarBoletos(value): void {
    this.cancelarBoletosEvent.emit(value);
  }

  getId(row): void {
    return row.id;
  }

  imprimirRecebimentosvalue(idContrato: number): any {
    this.imprimirRelatorioRecebidosEvent.emit(idContrato);
  }

    imprimirRelatorioContrato(idContrato: any): any {
      this.imprimirRelatorioRecebimentoEvent.emit(idContrato);
    }
}
