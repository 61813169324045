import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MaquinaCartaoService } from 'app/main/financial/components/maquina-cartao/services/maquina-cartao.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { MaquinaCartao } from '../../model/maquina-cartao/maquina-cartao.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { DialogData } from '../holiday-form/holiday-form.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface DataDilog {
  action: string;
  maquina: MaquinaCartao
}

@Component({
  selector: 'app-maquina-cartao-form',
  templateUrl: './maquina-cartao-form.component.html',
  styleUrls: ['./maquina-cartao-form.component.scss']
})
export class MaquinaCartaoFormComponent implements OnInit, AfterViewInit {

  maquina: FormGroup;
  dialogMessages;
  titleHeader: string;
  disabledButton: boolean;
  posto: Posto;
  postos: Array<any>;
  postoList: any[] = [];

  public postoFilterCtrl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('descricao') descricao: ElementRef;

  constructor(private maquinaCartaoService: MaquinaCartaoService,
    @Inject(MAT_DIALOG_DATA) public _data: DataDilog,
    private formBuild: FormBuilder,
    private postoService: PostsService,
    private matDialogRef: MatDialogRef<MaquinaCartaoFormComponent>,
    private dialog: MatDialog) {

    this.titleHeader = (this._data.action == "new") ? "Cadastro de Máquina Cartão" : "Edição de Máquina Cartão"
  }

  ngOnInit() {
    this.postoLista();
    this.setForm();
  }

  ngAfterViewInit() {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }

  private _onDestroy = new Subject<void>();

  setForm() {
    let posto = null;
    if (this._data.action == 'update') {    
      posto = this._data.maquina.posto  
    }
    this.maquina = this.formBuild.group({
      id: [this._data.maquina ? this._data.maquina.id : null],
      descricao: [this._data.maquina ? this._data.maquina.descricao : null, Validators.required],
      marca: [this._data.maquina ? this._data.maquina.marca : null],
      numeroSerie: [this._data.maquina ? this._data.maquina.numeroSerie : null, Validators.required],
      modelo: [this._data.maquina ? this._data.maquina.modelo : null],
      posto: [posto ? this._data.maquina.posto.descricao : null, Validators.required]
    })
  }

  create(maquina: any) {
    this.postos.forEach(posto => {
      if(posto.descricao == maquina.posto) {
        maquina.posto = posto;
      }      
    })
    this.disabledButton = true;
    this.maquinaCartaoService.create(maquina).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR MÁQUINA CARTÃO', onerror);
      }
    )
  }

  update(maquina: any) {
    this.postos.forEach(posto => {
      if(posto.descricao == maquina.posto) {
        maquina.posto = posto;
      }      
    })
    this.disabledButton = true;
    this.maquinaCartaoService.update(maquina).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      }

    },
      (onerror) => {
        console.log('ERROR MÁQUINA CARTÃO', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private postoLista(){
    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() =>{
        this.filterPosto();
      })
    })
  }

  private filterPosto() {
    if (!this.filteredPosto) {
      return;
    }
    let search = this.postoFilterCtrl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }
}
