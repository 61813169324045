import { MarcaService } from './../../../global/components/marca/services/marca.service';
import { Modelo } from './../../model/modelo/modelo.model';
import { MessageDialogComponent } from './../message-dialog/message-dialog.component';
import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ModeloService } from 'app/main/global/components/modelo/services/modelo-service.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modelo-form',
  templateUrl: './modelo-form.component.html',
  styleUrls: ['./modelo-form.component.scss']
})
export class ModeloFormComponent implements OnInit, AfterViewInit {

  modelo: FormGroup;
  disabledButton: boolean;
  dialogMessages;
  marcas = [];
  titleHeader: string;
  marcaList: any[] = [];

  public marcaFilterControl: FormControl = new FormControl();
  public filteredMarca: ReplaySubject<Modelo[]> = new ReplaySubject<Modelo[]>(1);

  @ViewChild('descricao') descricao: ElementRef;

  private _onDestroy = new Subject<void>();

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private formBuild: FormBuilder,
    private matDialogRef: MatDialogRef<ModeloFormComponent>,
    private modeloService: ModeloService,
    private marcaService: MarcaService,
  ) {

    // tslint:disable-next-line: triple-equals
    this.titleHeader = this._data.action == 'new' ? 'Cadastro de Modelo' : 'Edição de Modelo';

    this.marcaService.findAll().subscribe(data => {
      this.marcaList = data.object.content;
    });

    this.marcaService.findAll().subscribe(data => {
      this.marcas = data.object.content;
      this.filteredMarca.next(this.marcas.slice());
      this.marcaFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMarca();
        });
    });
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }

  setForm(): void {
    this.modelo = this.formBuild.group({
      id: [this._data.modelo ? this._data.modelo.id : null],
      nome: [this._data.modelo ? this._data.modelo.nome : null],
      marca: [this._data.modelo ? (this._data.modelo.marca ? this._data.modelo.marca.id : null) : null],

    });
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  onSave(value): void  {
    this.disabledButton = true;

    // tslint:disable-next-line: triple-equals
    value.marca = this.marcaList.filter(marca => marca.id == value.marca)[0];

    this.modeloService.create(value).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        };
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR MODELO', onerror);
      }
    );
  }

  onUpdate(value): void  {
    this.disabledButton = true;

    // tslint:disable-next-line: triple-equals
    value.marca = this.marcaList.filter(marca => marca.id == value.marca)[0];
    value.ativo = true;
    this.modeloService.update(value).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR MODELO', onerror);
      }
    );
  }

  private filterMarca(): void  {
    if (!this.marcas) {
      return;
    }

    let search = this.marcaFilterControl.value;
    if (!search) {
      this.filteredMarca.next(this.marcas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMarca.next(
      this.marcas.filter(marca => marca.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private feedback(message: string[]): void  {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
}
