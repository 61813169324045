import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Telephone } from 'app/main/shared/model/telephone/telephone.model';

@Component({
  selector: 'app-personal-reference',
  templateUrl: './personal-reference.component.html',
  styleUrls: ['./personal-reference.component.scss'],
  animations: [fuseAnimations]
})
export class PersonalReferenceComponent implements OnInit {

  telephones: FormArray;
  @Input() visit: FormGroup;
  listTelefone: Telephone[];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    // tslint:disable-next-line:triple-equals
    if (this.visit.get('referenciaPessoal').value.length == 0) {
      this.add();
    }
  }

  add(required?: boolean): void {
    const reference = this.formBuilder.group({
      id: new FormControl(null),
      nome: new FormControl(null, Validators.required),
      situacao: new FormControl(null, Validators.required),
      type: new FormControl('rp'),
      listTelefone: new FormControl(this.buildPhone(['']))
    });
    this.referenciaForms.push(reference);
  };

  delete(index: number): void {
    this.referenciaForms.removeAt(index);
  }
  buildPhone(listTelefone): FormArray {
    const phone = listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: [v.ativo],
        numero: [v.numero, Validators.required],
        id: [v.id],
        instalarAppCliente: [v.instalarAppCliente],
        possuiInternet: [v.possuiInternet],
        tipoSmartphone: [v.tipoSmartphone]
      });
    });
    return this.formBuilder.array(phone);
  }

  getErrorMessage(field: string): void {
  }

  get referenciaForms(): FormArray {
    return this.visit.get('referenciaPessoal') as FormArray;
  }

  get phoneForms(): FormArray {
    return this.visit.get('referenciaPessoal.listTelefone') as FormArray;
  }
}
