import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {Mask} from '@fagnerlima/ng-mask';
import {BancoService} from '../../../financial/components/banco/services/banco-service.service';
import {Banco} from '../../model/banco/banco.model';

@Component({
  selector: 'app-conta-bancaria-form',
  templateUrl: './conta-bancaria-form.component.html',
  styleUrls: ['./conta-bancaria-form.component.scss']
})
export class ContaBancariaFormComponent implements OnInit {
  
  @Input() conta: FormGroup;
  @Output() principalFieldWasChecked = new EventEmitter<any>();

  readonly numeroDaContaMask = new Mask('#0-0', { reverse: true });
  readonly maskCpf = new Mask('000.000.000-00');
  readonly maskCnpj = new Mask('00.000.000/0000-00');

  bancos: any;
  banco: any;
  currentBanco: any;
  getBanco: any;

  constructor(private bancoService: BancoService, private formBuilder: FormBuilder) { }

  get registrosPixForms(): FormArray {
    return this.conta.get('registrosPix') as FormArray || this.formBuilder.array([]);
  }
  
  ngOnInit(): void {
    this.getBanco = this.conta.get('banco').value ? this.conta.get('banco').value.descricao : '';

    this.conta.valueChanges.subscribe(value => {
      if (value.principal) {
        this.principalFieldWasChecked.emit(this.conta);
      }
    });
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  doFilter(evento: Event): any{
    const target = evento.target as HTMLTextAreaElement;

    this.bancoService.findByDescricao(target.value).subscribe( data => {
      this.bancos = data.object.content;
    });

  }

  displayFn(banco: Banco): string {
    return banco ? banco.descricao : '';
  }

  serviceOnSelect(banco: any): any {
    this.currentBanco = banco;
    this.conta.get('banco').setValue(this.currentBanco);
  }

  addRegistroPix(): void {
    const novoRegistro = this.formBuilder.group({
      id: new FormControl(null),
      chavePix: new FormControl(null),
      tipoChavePix: new FormControl(null)
    });

    this.registrosPixForms.push(novoRegistro);
  }

  deleteRegistroPix(index: number): void {
    this.registrosPixForms.removeAt(index);
  }
}
