import { Cliente } from './../../model/cliente/cliente.model';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-relatorio-cliente',
  templateUrl: './relatorio-cliente.component.html',
  styleUrls: ['./relatorio-cliente.component.scss']
})
export class RelatorioClienteComponent implements OnInit {

  clientes = []
  public clienteFilterControl: FormControl = new FormControl();
  public filteredCliente: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);

  form: FormGroup

  constructor(private formBuild: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RelatorioClienteComponent>,
    private clienteService: ClienteService) {

    this.clienteService.findAll().subscribe(data => {
      this.clientes = data.object.content;
      this.filteredCliente.next(this.clientes.slice());
      this.clienteFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCliente();
        });
    })
  }

  private _onDestroy = new Subject<void>();


  ngOnInit() {
    this.setForm()
  }

  setForm() {
    this.form = this.formBuild.group({
      cliente: []
    })
  }

  gerar(value) {
    this.clienteService.relatorioInadimplencia().subscribe(data => {
      let objectURL = URL.createObjectURL(data);
      window.open(objectURL, '_blank').print()
      this.dialogRef.close();
    })
  }

  private filterCliente() {
    if (!this.clientes) {
      return;
    }

    let search = this.clienteFilterControl.value;
    if (!search) {
      this.filteredCliente.next(this.clientes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCliente.next(
      this.clientes.filter(cliente => cliente.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  onClosed(): void {
    this.dialogRef.close();
  }

}
