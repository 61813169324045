import { Injectable } from '@angular/core';
import { SGF_API, ODIN_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Posto } from 'app/main/shared/model/posto/posto.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  private host = `${SGF_API}` + 'global/api/public/posto';
  private posto: string;
  constructor(private httpClient: HttpClient) { }

  pageByPosto(): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=100&${this.posto}&short-id`)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  listarPostos(): Observable<any> {
    return this.httpClient.get<Posto>(this.host + '/listar-postos')
      .pipe(
        catchError(this.handleError)
      );
  }
  
  listarPostosSemDD(): Observable<any> {
    return this.httpClient.get<Posto>(this.host + '/listar-postos-sem-dd')
      .pipe(
        catchError(this.handleError)
      );
  }

  listarPostosDeOutroEstado(url: string): Observable<any> {
    return this.httpClient.get<Posto>(url + 'global/api/public/posto/listar-postos')
      .pipe(catchError(this.handleError));
  }

  filtarPostosPorDescicao(descricao: string): Observable<any> {
    return this.httpClient.get<Posto>(`${this.host}?descricao=${descricao}`)
        .pipe(
            catchError(this.handleError)
        );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<Posto>(`${this.host}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  add(posto: Posto): Observable<any> {
    return this.httpClient.post<Posto[]>(this.host, posto, this.httpOptions())
      .pipe(
      catchError(this.handleError)
    );
  }

  updatePosto(posto: Posto): Observable<any> {
    return this.httpClient.post<Posto>(this.host, posto, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(id: any): Observable<any> {
    return this.httpClient.delete(`${this.host}/${id}`);
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
