import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { ArquivoUploadParcela } from '../../model/parcela/arquivo-upload-parcela.model';

@Component({
  selector: 'app-upload-comprovante-parcela',
  templateUrl: './upload-comprovante-parcela.component.html',
  styleUrls: ['./upload-comprovante-parcela.component.scss']
})
export class UploadComprovanteParcelaComponent implements OnInit {

  arquivoComprovanteParcelaForm: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  disabled = false;
  documento: File;
  nomeDocumento = '';
  documentoBase64 = '';
  arquivoComprovanteParcela: ArquivoUploadParcela = new ArquivoUploadParcela();
  public loading = false;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<UploadComprovanteParcelaComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private service: ContractsService
  ) { }

  ngOnInit(): void {
    this.setForm();
    console.log(this._data);
  }

  setForm(): void {
    this.arquivoComprovanteParcelaForm = this.formBuilder.group({
      tipo: new FormControl(null),
      fileName: new FormControl(null)
    });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(arquivo: any): void {
    this.arquivoComprovanteParcela.tipo = arquivo.tipo;
    this.arquivoComprovanteParcela.transferenciaId = this._data.transferencia.id;
    this.arquivoComprovanteParcela.parcelaId = this._data.transferencia.idParcela;
    this.service.anexarComprovante(this.arquivoComprovanteParcela).subscribe(rest => {
      if (rest.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Upload realizado com Sucesso!`
        };
      } else {
        if (rest.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${rest.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
            text: 'Erro ao realizar upload'
          };
        }
      }
      this.feedback(this.dialogMessages);
      this.dialog.closeAll();
    });
  }

  onFileSelected(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files.item(0);
    this.documento = file;
    this.nomeDocumento = file.name;

    const reader = new FileReader();
    const future = new Promise((resolve) => {
      reader.addEventListener('loadend', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });

    this.arquivoComprovanteParcelaForm.get('fileName').setValue(this.nomeDocumento);
    
    future.then(data => {
      this.transformBlobTo64(data);
      this.arquivoComprovanteParcela.documentoBase64 = this.documentoBase64;
      this.arquivoComprovanteParcela.fileName = this.nomeDocumento;
    });
   
  }

  transformBlobTo64(result: any): void {
    this.documentoBase64 = result;
  }

  private feedback(message: string[]): void  {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
}
