import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FolhaCobrancaService } from 'app/main/financial/services/folha-cobranca/folha-cobranca.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-folha-cobranca-transferencia-edit',
  templateUrl: './folha-cobranca-transferencia-edit.component.html',
  styleUrls: ['./folha-cobranca-transferencia-edit.component.scss']
})
export class FolhaCobrancaTransferenciaEditComponent implements OnInit {
  
  @Output() overEvent = new EventEmitter<any>();
  disabledButton: boolean;
  form: FormGroup;
  employee = [];
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  public EmployeeFilterControl: FormControl = new FormControl();
  responsavel: Employee;

  dialogMessages: any;
  constructor(
    private _matDialog: MatDialog,
    private matDialogRef: MatDialogRef<FolhaCobrancaTransferenciaEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {row: any},
    private formBuild: FormBuilder,
    private folhaCobrancaService: FolhaCobrancaService,
    private employeeService: EmployeeService) {
      this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    }) }

  ngOnInit() {
    this.setForm();
  }

  private _onDestroy = new Subject<void>();

  setForm(): void {
    this.form = this.formBuild.group({
      valorPago: this.data.row ? this.data.row.valorPago : 0,
      indicador: this.data.row.folhaCobranca.funcionario.id,
      data: this.data.row.folhaCobranca.data
    })
  }

  onAlterarCobrancaTransferencia(value) {
    const dto = {
      fctID: this.data.row.id,
      valorPago: this.form.value.valorPago,
      indicador: this.form.value.indicador,
      data: this.form.value.data
    }
    this.alterarCobrancaTransferencia(dto)
  }

  public alterarCobrancaTransferencia (dto: any): void {
    this.folhaCobrancaService.alterarCobrancaTransferencia(dto).subscribe(
      (data) => {
        if (data.success) {
          this.disabledButton = true;
          this.matDialogRef.close(data.success);

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Alteração realizada com Sucesso`
          }
          this.feedback(this.dialogMessages);
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            }
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }
          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClose() {
    this.matDialogRef.close()
  }

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  public selectResponsavel(value){
    this.responsavel = value;
  }

}
