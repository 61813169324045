import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-inativar-renovacao-automatica',
  templateUrl: './inativar-renovacao-automatica.component.html',
  styleUrls: ['./inativar-renovacao-automatica.component.scss']
})
export class InativarRenovacaoAutomaticaComponent implements OnInit {

  public clienteFilterControl: FormControl = new FormControl();

  cliente: any;
  disabledButton: boolean;
  titleHeader: string;
  text: string;
  situacaoInativacao: number = null;

   /**
   * Constructor
   *
   * @param {MatDialogRef<InativarRenovacaoAutomaticaComponent>} matDialogRef
   * @param _data
   */
  constructor(
    private matDialogRef: MatDialogRef<InativarRenovacaoAutomaticaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.titleHeader = 'Inativar a renovação automática do contrato';
        this.text = 'Você realmente deseja inativar a renovação automática do contrato?';
    }
  

  ngOnInit() {
    this.cliente = this.data['data'];
  }

  onClick(data?): void {    
    this.disabledButton = true;
    if (data) {
      let cliente = data.data;
      cliente.situacaoInativacao = this.situacaoInativacao;
      this.matDialogRef.close(cliente);
    } else {
      this.matDialogRef.close();
    }
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
