import { Injectable } from "@angular/core";
import { FolhaCobrancaService } from "app/main/financial/services/folha-cobranca/folha-cobranca.service";
import { Observable, of } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ResolveFolhaCobrancaService {

    constructor(private folhaCobrancaService: FolhaCobrancaService) {}

    resolve(): Observable<any> {
        return this.folhaCobrancaService.findAll(10, 1).pipe(
            take(1),
            mergeMap(rest => {
                return of(rest['object']);
            })
        )
    }
}