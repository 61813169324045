import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Product } from 'app/main/shared/model/product/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {
  private host = `${SGF_API}` + 'core/api/public/produto';
  private modelo = `${SGF_API}` + 'core/api/public/modelo';
  private posto: string;
  constructor(
    private httpClient: HttpClient) { 
  }

  findAll(): any {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=10000&ativo=true&${this.posto}`)
    .pipe(
      catchError(this.handleError)
    );
 
  }

  page(size: number, page: number, ativo: boolean, postoId?: any): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id&ativo=${ativo}`)
    
  }

  filtroProduto(filter: string, page: number,  size: number, ativo: boolean, postoId?: any): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?nome=like:${filter}&page=${page}&${this.posto}&size=${size}&sort=-id&ativo=${ativo}`);
    
  }

  filtroProdutoMarca(filter: string, page: number,  size: number, ativo: boolean, postoId?: any): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?nome=like:${filter}&page=${page}&${this.posto}&size=${size}&sort=-id&ativo=${ativo}`);

  }

  add(product: any): Observable<any> {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const idPosto = user.idPosto;
      if (idPosto) {
        product.posto = {
          id: idPosto
        }
      }
    }
    const body = JSON.stringify(product);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(product: any): Observable<any> {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const idPosto = user.idPosto;
      if (idPosto) {
        product.posto = {
          id: idPosto
        }
      }
    }
    const body = JSON.stringify(product);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get(`${this.host}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  pageModelo(): any {
    return this.httpClient.get(`${this.modelo}?size=10000`)
      .pipe(
        catchError(this.handleError));
  }

  modeloByMarcaId(marcaId: number): any {
    return this.httpClient.get(`${this.modelo}?marcaId=${marcaId}&size=1000&sort=id`)
      .pipe(
        catchError(this.handleError));
  }

  findModeloById(modeloId: number): any{
    return this.httpClient.get(`${this.modelo}/${modeloId}`)
      .pipe(
        catchError(this.handleError));
  }
  
  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }
  
  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
