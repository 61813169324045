import { Injectable } from '@angular/core';
import { MaquinaCartaoService } from '../services/maquina-cartao.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveMaquinaCartaoService {

  constructor(private maquinaCartaoService : MaquinaCartaoService) { }


  resolve(): Observable<any> {
    return this.maquinaCartaoService.page(10, 1).pipe(
      take(1),
      mergeMap(desp => {
      return of(desp['object']);
      })
    );
  }

}
