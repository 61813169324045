import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { FormControl } from '@angular/forms';
import { Contract } from '../../../../shared/model/contract/contract.model';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Page } from 'app/main/shared/model/page/page.model';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';
import { CancelContractComponent } from 'app/main/shared/dialogs/cancel-contract/cancel-contract.component';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { data } from 'app/main/shared/data/chart-data';
import { SelectMaquinaComponent } from 'app/main/shared/components/select-maquina/select-maquina.component';
import { AgendarParcelaComponent } from 'app/main/shared/components/agendar-parcela/agendar-parcela.component';
import { DesvincularContratoComponent } from 'app/main/shared/dialogs/desvincular-contrato/desvincular-contrato.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { CalcularJurosComponent } from 'app/main/shared/components/calcular-juros-form/calcular-juros.component';
import { MudarSituacaoContratoComponent } from 'app/main/shared/dialogs/mudar-situacao-contrato/mudar-situacao-contrato.component';
import { EditMaquinaComponent } from 'app/main/shared/components/edit-maquina/edit-maquina.component';
import { MaquinaCartao } from 'app/main/shared/model/maquina-cartao/maquina-cartao.model';
import { RenegociacaoComponent } from 'app/main/shared/components/renegociacao-form/renegociacao-form.component';
import * as FileSaver from 'file-saver';
import { LiquidarContratoComponent } from 'app/main/shared/dialogs/liquidar-contrato/liquidar-contrato.component';
import { InProgressSidebarComponent } from '../in-progress-sidebar/in-progress-sidebar.component';
import { RenegociacaoDividaFormComponent } from 'app/main/shared/components/renegociacao-divida-form/renegociacao-divida-form.component';
import { RepactuacaoDividaFormComponent } from 'app/main/shared/components/repactuacao-divida-form/repactuacao-divida-form.component';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { AlterarResponsavelContratosComponent } from 'app/main/shared/components/alterar-responsavel-contratos/alterar-responsavel-contratos.component';
import {UtilsService} from '../../../../../shared/util/utils.service';

@Component({
  selector: 'app-in-progress-main',
  templateUrl: './in-progress-main.component.html',
  styleUrls: ['./in-progress-main.component.scss'],
  animations: fuseAnimations
})
export class InProgressMainComponent implements OnInit, OnDestroy {

  rows: Array<Contract> = [];
  itensAPI: Array<Contract>;

  searchInput: FormControl;
  interval;
  selected: Array<any>;
  dialogMessages;
  count = 0;
  page = new Page();
  public loading = false;
  selectedClear: boolean;
  search = '';
  dataPeriodo;
  filter: string = 'NOME_CLIENTE';
  pageNumber;
  isPeriodo = false;
  situacao;
  sort;
  permissoes = [];
  parcelasOrdered = [];
  valorTotalContratos = 0;
  valorTotalAReceber = 0;
  superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;

  lastFilter: string;
  lastSearchFilter;

  @ViewChild(InProgressSidebarComponent)
  private sidebar: InProgressSidebarComponent;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private contractService: ContractsService,
    private _matDialog: MatDialog,
    private permissionsService: NgxPermissionsService,
    private contratoService: ContractsService,
    private utilsService: UtilsService
  ) {
    this.selected = new Array<any>();
    this.sort = '+dataVencimento';

    if (!this.hasLastFilters()){
      this.route.data.subscribe(
        (res) => {
          this.rows = res.rows.content;
          this.itensAPI = this.rows;
          this.itensAPI.forEach(contrato => {

            // tslint:disable-next-line:triple-equals
            if  (contrato.listParcela.length > 0)  {
              contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
              contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
            }
            if (this.fortCartao) {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');
                this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
                  contrato.lancamento = result.object;
                });
              } else {
                contrato.maquinas = [];
              }
            }
            const page = res.rows;
            page.pageNumber = 0;
            this.setPage(page);
          });
        }
      );
    }
    
    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  atualizarValorTotalDosContratos(): void {
    const periodo = (this.dataPeriodo || '').split(',');
    const periodoDTO = {
      dataInicial: periodo.length > 0 ? parseInt(periodo[0]) : null,
      dataFinal: periodo.length > 1 ? parseInt(periodo[1]) : null,
    };
    this.contractService.getValorBrutoTodosContratos({
      ...periodoDTO,
      nome: this.filter === 'NOME_CLIENTE' ? this.search : '',
      descricaoRota: this.filter === 'ROTA' ? this.search : '',
      situacoesContrato: (this.situacao || 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO').split(',')
    }).subscribe((response) => {
      if (response.success) {
        this.valorTotalContratos = response.object;
      }
    });
  }

  over(): void {
    this.search = '';
    this.loading = true;
    this.atualizarValorTotalDosContratos();
    this.contractService.pageContratosGraphQL('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO', 10, 1, '+dataVencimento')
      .subscribe(
        (res) => {
          this.loading = false;
          this.rows = res.data.findAllPageable.content;
          this.itensAPI = this.rows;
          this.itensAPI.forEach(contrato => {
            contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
          
            // tslint:disable-next-line:triple-equals
            if  (contrato.listParcela.length > 0)  {
              contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
              contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
            }

            if (contrato.maquinas != null && contrato.maquinas.length > 0) {
              contrato.numeroSeries = contrato.maquinas.map(maquina => {
                return maquina.numeroSerie;
              }).join(', ');

            }else {
              contrato.maquinas = [];
            }

            this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
              contrato.lancamento = result.object;
            });
          });
          const page = res.data.findAllPageable;
          page.pageNumber = 0;
          this.setPage(page);
        }
      );
    this.clearSelectBox();
  }


  ngOnInit(): void {
    this.hasLastFilters();
    this.atualizarValorTotalDosContratos();
  }

  clearSelectBox(): void {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getSelected(selected: any): void {
    this.selected = selected;
  }

  transferirResponsavelDeTodosOsContratos(): void {
    this._matDialog.open(AlterarResponsavelContratosComponent, {
      data: { 
        contrato : null,
        action: 'alterContrato'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.over();
      }
    });
  }

  transferirCobrancaContrato(): void {
    const contratos = [];
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'transferir',
        cobranca: null
      }
    }).afterClosed().subscribe(
      result => {
        if (result.funcionario) {
          this.count = 0;
          this.selected.forEach(c => {
            c.responsavel = result.funcionario;
            contratos.push(c);
          });
          const object = {
            contratos: contratos,
            transferirResponsavel: true
          };
          this.contractService.transferirCobrancaContrato(object).subscribe((rest) => {
            if (rest.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Registro atualizado com sucesso`
              };
            } else {
              if (rest.messages != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${rest.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
                this.feedback(this.dialogMessages);
                this.over();
              }
            }
            this.feedback(this.dialogMessages);
            this.over();
          });
        }
      });
  }

  mudarResponsavel(): void {
    const contratos = [];
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'new',
        cobranca: null
      }
    }).afterClosed().subscribe(
      result => {
        if (result.funcionario) {
          this.count = 0;
          this.selected.forEach(c => {
            c.responsavel = result.funcionario;
            contratos.push(c);
          });
          const object = {
            contratos: contratos,
            transferirResponsavel: false
          };
          this.contractService.alterarResponsavelContrato(object).subscribe((rest) => {
            if (rest.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Registro atualizado com sucesso`
              };
            } else {
              if (rest.messages != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${rest.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
            }
            this.feedback(this.dialogMessages);
            this.over();
          });
        }
      });
  }

  cancelContract(select: any): void {
    const dialogRef = this.dialog.open(CancelContractComponent, {
      data: { data: select, schedule: false }
    });

    dialogRef.afterClosed().subscribe(contract => {
      this.contractService.cancelContract(contract).subscribe((result: ReturnApi) => {
        if (result.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Contrato Cancelado Com Sucesso`
          };
          this.feedback(this.dialogMessages);
          this.over();
        } else {
          if (result.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${result.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
          this.over();
        }
      });
      this.clearSelectBox();
    });
  }

  liquidarContrato(select: any): void {
    this.dialog.open(LiquidarContratoComponent, { data: { data: select[0] } })
    .afterClosed().subscribe(contrato => {
      if (contrato) {
        this.contractService.liquidaContrato(contrato).subscribe(response => {

          if (response.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Contrato Liquidado com Sucesso`
            };
          } else if (response.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${response.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
    
          this.feedback(this.dialogMessages);
          this.over();

        });
      }
    });
  }

  alterarContrato(value): void {
    this.dialog.open(AlterarContratoFormComponent, {
      data: {
        contrato: value,
        action: 'alterContrato'
      }
    }).afterClosed().subscribe(res => {
      if (res.success) {
        this.over();
      }
    });
  }

  alterarProduto(value): void {
    this.dialog.open(AlterarContratoFormComponent, {
      data: {
        contrato: value,
        action: 'alterarProduto'
      }
    }).afterClosed().subscribe(res => {
      if (res.success) {
        this.over();
      }
    });
  }

  public gerarBoletos(contratoId: number): void {
    this.contractService.gerarBoletos(contratoId).subscribe((boleto: Blob | any) => {
      if (boleto) {
        FileSaver.saveAs(boleto, `boletos_${contratoId}.pdf`);
      }
    });
  }

  public cancelarBoletos(contratoId: number): void {
    this.contratoService.cancelarBoletos(contratoId).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  vincularMaquina(value): void {
    this.dialog.open(SelectMaquinaComponent, {
      data: {
        action: 'alterar',
        contrato: value
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        value.maquinaCartao = result.maquina;
        this.contractService.vincularMaquina(value).subscribe(res => {
          if (res.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Máquina alterada com Sucesso`
            };
            this.feedback(this.dialogMessages);
            this.over();
          } else {
            if (res.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${res.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
            this.feedback(this.dialogMessages);
            this.over();
          }
        });
      }
    });
  }

  alterarMaquina(value): void {
    this.dialog.open(EditMaquinaComponent, {
      data: {
        action: 'alterar',
        contrato: value
      }
    }).afterClosed().subscribe(result => {
      if (result) {
       
        const valueArr = result.listMaquina.map(item => item.maquina);
        // tslint:disable-next-line:typedef
        const isDuplicate = valueArr.some(function (item, idx) {
          // tslint:disable-next-line:triple-equals
          return valueArr.indexOf(item) != idx;
        });

        if (isDuplicate) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `Maquina duplicada`
          };
          this.feedback(this.dialogMessages);
          this.over();
        } else {
          const maquinasCartao: Array<MaquinaCartao> = [];

          result.listMaquina.forEach(maquinaCartao => {
            const maquina: MaquinaCartao = new MaquinaCartao();
            console.log(maquina);
            maquina.id = maquinaCartao.maquina;
            maquinasCartao.push(maquina);
          });

          value.maquinas = maquinasCartao;
          this.contractService.editarMaquinasImpressao(value).subscribe(res => {
            if (res.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Máquina alterada com Sucesso`
              };
              this.feedback(this.dialogMessages);
              if (res.object.desvinculadas.length > 0 && res.object.vinculadas.length > 0) {
                this.pdfComodataVinculo(res.object);
              }
              this.over();
            } else {
              if (res.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${res.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages);
              this.over();
            }
          });
        }
      }
    });
  }

  desvincularContrato(value): void {
    this.dialog.open(DesvincularContratoComponent, {
      data: { contrato: value }
    }).afterClosed().subscribe(result => {
      if (data) {
        this.contractService.desvincularMaquina(value).subscribe(res => {
          if (res.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Máquina desvinculada com Sucesso`
            };
            this.feedback(this.dialogMessages);
            if (res.object.desvinculadas.length > 0 && res.object.vinculadas.length > 0) {
              this.pdfComodataVinculo(res.object);
            }
            this.over();
          } else {
            if (res.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${res.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
            this.feedback(this.dialogMessages);
            this.over();
          }
        });
      }
    });
  }

  agendarPagamento(contrato: any): void {
    this._matDialog.open(AgendarParcelaComponent, {
      disableClose: true,
      data: {
        contrato: contrato
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.over();
      }
    });
  }

  calcularJuros(contrato: any): void {
    this._matDialog.open(CalcularJurosComponent, {
      disableClose: true,
      data: {
        contrato: contrato
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.over();
      }
    });
  }

  mudarSituacao(contrato: any): void {
    this._matDialog.open(MudarSituacaoContratoComponent, {
      disableClose: true,
      data: {
        contrato: contrato
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.contratoService.updateContract(res.contrato).subscribe(result => {
          if (result.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Contrato alterado com Sucesso`
            };
            this.feedback(this.dialogMessages);
            this.over();
          } else {
            if (result.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${result.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
            this.feedback(this.dialogMessages);
            this.over();
          }
        });
      }
    });
  }

  renegociar(contrato: any): void {
    this._matDialog.open(RenegociacaoComponent, {
      disableClose: true,

      data: {
        contrato: contrato
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.over();
      }
    });
  }

  renegociarDivida(contrato: any): void {
    this._matDialog.open(RenegociacaoDividaFormComponent, {
      disableClose: true,
      data: { contrato }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.over();
      }
    });
  }

  repactuarDivida(contrato: any): void {
    this._matDialog.open(RepactuacaoDividaFormComponent, {
      disableClose: true,
      data: { contrato }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.over();
      }
    });
  }


  pdfComodata(contrato: any): void {
    this.contractService.getComodata(contrato.id).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  pdfComodataVinculo(maquinaTO: any): void {
    this.contractService.getComodataVinculo(maquinaTO).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  confissaoDividaBoleto(contrato: any): void {
    this.contractService.confissaoDividaBoleto(contrato.id).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  setPage(list: any): void {
    // Utilizando para o pageable
    this.page.pageNumber = list ? list.pageNumber : 0;
    this.page.totalPages = list ? list.totalPages : 0;
    this.page.size = list ? list.size : 0;
    this.page.totalElements = data ? list.totalElements : 0;
  }


  getDataFilter(event): void {
    setTimeout(() => {
      this.rows = event.content;
      this.rows.forEach(contrato => {
        contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

        // this.valorTotalContratos = this.valorTotalContratos + contrato.valorContrato;

        // tslint:disable-next-line:triple-equals
        if  (contrato.listParcela.length > 0)  {
          contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
          contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
  
          // this.valorTotalAReceber = this.valorTotalAReceber + contrato.saldoAReceber;
        }

        if (contrato.maquinas != null && contrato.maquinas.length > 0) {
          contrato.numeroSeries = contrato.maquinas.map(maquina => {
            return maquina.numeroSerie;
          }).join(', ');
        } else {
          contrato.maquinas = [];
        }

        this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
          contrato.lancamento = result.object;
        });
      });
      const page = event;
      page.pageNumber = 0;
      this.setPage(page);
    }, 1000);

    this.atualizarValorTotalDosContratos();
  }

  getSearchFilter(event): void {
    this.search = event;
  }

  getSituacao(event): void {
    this.situacao = event;
  }

  getPeriodoFilter(event): void {
    this.isPeriodo = true;
    this.dataPeriodo = event;
  }

  getFilter(event): void {
    this.isPeriodo = false;
    this.filter = event;
    // tslint:disable-next-line:triple-equals
    if (event == 'EM_ANDAMENTO' || event == 'EM_ATRASO') {
      this.situacao = event;
      this.loading = true;
      this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento')
        .subscribe(
          (res) => {
            this.loading = false;
            this.rows = res.data.findAllPageable.content;
            this.itensAPI = this.rows;
            this.itensAPI.forEach(contrato => {
              contrato.saldoPago = 0;
              contrato.valorJuros = 0;

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                
                contrato.listParcela.forEach(parcela => {
                  contrato.saldoPago = contrato.saldoPago + parcela.valorPago;
                  contrato.valorJuros = contrato.valorJuros + parcela.juros;
                });
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');
              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
                contrato.lancamento = result.object;
              });
            });
            const page = res.data.findAllPageable;
            page.pageNumber = 0;
            this.setPage(page);
          }
        );
    // tslint:disable-next-line:triple-equals
    } else if (event == 'VENCIMENTO_HOJE') {
      this.loading = true;
      this.contractService.pageContratosGraphQL('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO', 10, 1, 'dataVencimento')
        .subscribe(
          (res) => {
            this.loading = false;
            this.rows = res.data.findAllPageable.content;
            this.itensAPI = this.rows;
            this.itensAPI.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {

                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
  
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
                contrato.lancamento = result.object;
              });
            });
            const page = res.data.findAllPageable;
            page.pageNumber = 0;
            this.setPage(page);
          }
        );
    // tslint:disable-next-line:triple-equals
    } else if (event == 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO') {
      this.over();
      this.situacao = event;
    }

    this.atualizarValorTotalDosContratos();

  }

  // Para Cada página ser de acordo com o filtro
  getPage(event): void {
    this.isPeriodo = false;

    this.isPeriodo = false;
    this.loading = true;
    this.pageNumber = event ? event : 1;
    
    if (this.search != null && this.filter == null) {
      this.filter = 'NOME_CLIENTE';
      this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    }
    // tslint:disable-next-line:triple-equals
    if (['PERIODO', 'RESPONSAVEL_CONTRATO', 'NOME_CLIENTE', 'DOCUMENTO_CLIENTE', 'VENCIMENTO_HOJE', 'ROTA'].includes(this.filter)) {
      this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    } else {
      this.situacao = this.filter;
    }


    switch (this.filter) {

     case 'DOCUMENTO_CLIENTE':

        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
              
                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;

              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
            this.rows = result.object.content;
            this.rows.forEach(contrato => {
              
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = result.object;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
      case 'NOME_CLIENTE':

        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByNomeCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
            this.rows = result.object.content;
            this.rows.forEach(contrato => {
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = result.object;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;

        case 'NOME_BUSCA':

          // tslint:disable-next-line:triple-equals
          if (this.search == '') {
            this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
              this.rows = res.data.findAllPageable.content;
              this.rows.forEach(contrato => {
  
                contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
                
                // tslint:disable-next-line:triple-equals
                if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
                }
  
                if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                  contrato.numeroSeries = contrato.maquinas.map(maquina => {
                    return maquina.numeroSerie;
                  }).join(', ');
  
                } else {
                  contrato.maquinas = [];
                }
  
                this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                  contrato.lancamento = res.object;
                });
              });
              this.loading = false;
  
              const page = res.data.findAllPageable;
              page.pageNumber = this.pageNumber - 1;
              this.setPage(page);
            });
          } else {
            this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
              this.rows = result.object.content;
              this.rows.forEach(contrato => {
                contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
                
                 // tslint:disable-next-line:triple-equals
                 if  (contrato.listParcela.length > 0)  {
                    contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                    contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
                 }
  
                if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                  contrato.numeroSeries = contrato.maquinas.map(maquina => {
                    return maquina.numeroSerie;
                  }).join(', ');
  
                } else {
                  contrato.maquinas = [];
                }
  
                this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                  contrato.lancamento = res.object;
                });
              });
              this.loading = false;
  
              const page = result.object;
              page.pageNumber = this.pageNumber - 1;
              this.setPage(page);
            });
          }
          
      break;

      case 'RESPONSAVEL_CONTRATO':

        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
            this.rows = result.object.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = result.object;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
      
      case 'ROTA':

        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
            this.rows = result.object.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = result.object;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;

      case 'VENCIMENTO_HOJE':
 
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, 'dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, this.pageNumber).subscribe(result => {
            this.rows = result.object.content;
            this.rows.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);

              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = result.object;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;

      case 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO':


        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              // tslint:disable-next-line:triple-equals
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }
              

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
      case 'EM_ANDAMENTO':

        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {


              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
               // tslint:disable-next-line:triple-equals
               if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
               }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
      case 'EM_ATRASO':


        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {


              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {


              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
      case 'PENDENTE':


        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {


              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;

      case 'PERIODO':
        this.isPeriodo = true;
        this.loading = true;
        this.valorTotalAReceber = 0;
        // this.valorTotalContratos = 0;
        // tslint:disable-next-line:triple-equals
        if (this.dataPeriodo == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
            this.loading = false;
            this.rows = res.data.findAllPageable.content;
            this.itensAPI = this.rows;
            this.itensAPI.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              // this.valorTotalContratos = this.valorTotalContratos + contrato.valorContrato;
              this.valorTotalAReceber = this.valorTotalAReceber + contrato.saldoAReceber;

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
                contrato.lancamento = result.object;
              });
            });
            const page = res.data.findAllPageable;
            page.pageNumber = 0;
            this.setPage(page);
          });

        } else {
          this.contractService.pageContratoByPeriodo(this.situacao, 10, this.pageNumber, '+dataVencimento', this.dataPeriodo).subscribe(res => {
            this.loading = false;
            this.rows = res.object.content;
            this.itensAPI = this.rows;
            this.itensAPI.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              // this.valorTotalContratos = this.valorTotalContratos + contrato.valorContrato;
              this.valorTotalAReceber = this.valorTotalAReceber + contrato.saldoAReceber;

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(result => {
                contrato.lancamento = result.object;
              });
            });
            const page = res.object;
            page.pageNumber = 0;
            this.setPage(page);
          });
        }
        break;

      case null:
        
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO', 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {

              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        } else {
          this.contractService.pageContratosGraphQL('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO', 10, this.pageNumber, '+dataVencimento').subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              
              if  (contrato.listParcela.length > 0)  {
                  contrato.listParcela.sort((a, b) => a.ordem - b.ordem);
                  contrato.saldoAReceber = contrato.listParcela[contrato.listParcela.length - 1].valor - contrato.listParcela[contrato.listParcela.length - 1].valorPago;
              }

              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }

              this.contratoService.getLancamentosDiarios(contrato.id).subscribe(res => {
                contrato.lancamento = res.object;
              });
            });
            this.loading = false;

            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber - 1;
            this.setPage(page);
          });
        }
        break;
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  gerarArquivoTxt(): void {
  }

  clearFilter(): void { 
    this.filter = null;
    this.search = null;
    this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    this.sidebar.clear();
    this.over();
  }

  hasLastFilters(): boolean {

    if (this.route.snapshot.queryParamMap.get('filter') == null
      && this.route.snapshot.queryParamMap.get('search') == null) 
    {

      this.lastFilter = null;
      this.lastSearchFilter = null;
      return false;

    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter');
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search');
    }
    this.checkingLastFilters();
    return true;

  }


  checkingLastFilters(): void {

    if (this.lastFilter == null &&  this.lastSearchFilter == null){
      this.search = '';
      this.filter = 'NOME_CLIENTE';
    } else if ( this.lastFilter != null &&  this.lastSearchFilter == null ) {
      this.filter = this.lastFilter;
      this.search = '' ;
    } else if (this.lastFilter != null &&  this.lastSearchFilter != null) {
      this.filter = this.lastFilter;
      this.search = this.lastSearchFilter;
    }

    // tslint:disable-next-line:triple-equals
    if (this.filter == 'PERIODO' || this.filter == 'RESPONSAVEL_CONTRATO' || this.filter == 'NOME_CLIENTE' || this.filter == 'DOCUMENTO_CLIENTE'){
     
      this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO';
    } else {
      this.situacao = this.filter;
    }

  }

    imprimirRelatorioRecebidos(idContrato: number): any {
      this.contractService.imprimirRecebimentos(idContrato).subscribe((folha: Blob | any) => {
        if (folha) {
          // tslint:disable-next-line: max-line-length
          FileSaver.saveAs(folha, `Recebimentos_contrato_${idContrato}.pdf`);
        }
      });
    }

  imprimirRelatorioRecebimento(idContrato: any): any {
    this.contractService.imprimirRelatorioRecebimento(idContrato).subscribe((folha: Blob | any) => {
      if (folha) {
        // tslint:disable-next-line: max-line-length
        FileSaver.saveAs(folha, `Recebimentos_contrato_${idContrato}.pdf`);
      }
    });
  }
}
