import { Injectable } from "@angular/core";

@Injectable()
export class WidgetCard{

    getWidgetCards(captados: number, agendados: number, visitados: number): any{
        return [
            {icon: 'person_pin',color: 'light-blue-fg', quantity: captados, title: 'CAPTADOS', description: 'Todos os clientes captados.'},
            {icon: 'watch_later',color: 'red-fg', quantity: agendados, title: 'AGENDADOS', description: 'Todos os clientes Agendados para uma visita.'},
            {icon: 'check_circle',color: 'orange-fg', quantity: visitados, title: 'VISITADOS', description: 'Todos os clientes visitados e com o cadastro finalizado com sucesso.'},
        ];
    }

    getBottomWidgetCards(repasses: number, cobrancas: number): any {
        return [
            { icon: 'report_problem',color: 'orange-900-fg', quantity: repasses, title: 'PENDÊNCIAS DE REPASSE', description: 'Quantidade de pendências de repasse.', link: '/financial/pendencias' },
            { icon: 'report_problem',color: 'orange-900-fg', quantity: cobrancas, title: 'PENDÊNCIAS DE COBRANÇA', description: 'Quantidade de pendências de cobrança.', link: '/financial/pendencias' },
        ];
    }
}