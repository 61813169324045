import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { DespesasService } from '../services/despesas.services';


@Injectable({
  providedIn: 'root'
})

export class ResolveDespesasService implements Resolve<any> {

  
  constructor(
    private despesaService: DespesasService) { }

  resolve(): Observable<any> {
    return this.despesaService.findAll().pipe(
      take(1),
      mergeMap(desp => {
      return of(desp['object']);
      })
    );
  }

}
