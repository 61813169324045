import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../../../shared/api/sgf.api';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';

@Injectable({
  providedIn: 'root'
})
export class CobrancasService {


  private parcela = `${SGF_API}` + 'sgf/api/public/parcela';
  private boleto = `${SGF_API}` + 'sgf/api/public/boleto';
  private login = `${SGF_API}` + 'sgf/api/public/login';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  findAll(): Observable<any> {
    return this.httpClient.get(this.parcela, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  page(size: number, page: number): Observable<any> {
    return this.httpClient.get(`${this.parcela}?size=${size}&page=${page}&sort=id,ordem`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageSpecification(size: number, page: number, specification: string): Observable<any> {
    return this.httpClient.get(`${this.parcela}?size=${size}&page=${page}&${specification}&sort=id,ordem`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getBoletos(id: number): Observable<any> {
    return this.httpClient.get<Parcela[]>(`${this.parcela}/getBoletos?contratoId=${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  update(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(this.parcela, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  receberParcela(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/receberparcela`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  getCodeByVisit(id: number): Observable<any> {
    return this.httpClient.get(`${this.parcela}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarResponsavel(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/alterarresponsavel`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarParcelaConfirmadaNaPendencia(customer, idUsuairo: number): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/alterarParcelaConfirmadaNaPendencia/` + idUsuairo, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  alterarParcelaConfirmadaLancamentoEntrada(customer, idUsuairo: number): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/alterarParcelaConfirmadaLancamentoEntrada/` + idUsuairo, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  alterarParcelaRecebidaNaCobranca(customer, idUsuairo: number): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/alterarParcelaRecebidaNaCobranca/` + idUsuairo, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  agendarPagamento(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/agendarpagamento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  confimarParcela(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(`${this.parcela}/confirmarparcela`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  public quitarParcela(numeroDocumento: string, codigoLiquidacao: string): Observable<any> {

    const httpOptions = Object.assign(this.httpOptions(), { params: { codigoLiquidacao } });

    return this.httpClient.put(`${this.parcela}/quitar-parcela/${numeroDocumento}`, null, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarBoleto(parcelaId: number): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
      }),
      responseType: 'blob' as 'json'
    };

    return this.httpClient.post<any>(`${SGF_API}financeiro/api/public/boleto/gerar-boleto/` + parcelaId, null, httpOptions).pipe(
      catchError(this.handlePdfError)
    );
  }

  cancelarParcela(idParcela): Observable<any> {
    return this.httpClient.put(`${this.parcela}/cancelarparcela/${idParcela}`, null, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelarBoleto(idParcela): Observable<any> {
    return this.httpClient.get(`${this.boleto}/cancela-parcela-boletos/${idParcela}`,  { responseType: 'blob' });
  }

  liquidarParcela(idParcela): Observable<any> {
    return this.httpClient.put(`${this.parcela}/liquida`, idParcela, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  loginAlteracaoFinanceiro(usuario: any): Observable<any> {
    const body = JSON.stringify(usuario);
    return this.httpClient.post(`${this.login}/login-financeiro`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  relatorioCobrancasRealizadas(data: any, posto: any, rota: any, usuario: any): Observable<any> {
    return this.httpClient.get(`${this.parcela}/imprimir-historico-cobrancas?data=${data}&${posto}&${rota}&usuario=${usuario}`,  { responseType: 'blob' });
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }

  private handlePdfError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


}
