import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalRoutingModule } from './routes/global.routing';
import { SharedMaterialComponentsModule } from '../../shared-material-components.module';
import { GlobalService } from './services/global.service';

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialComponentsModule,
    GlobalRoutingModule
  ],
  declarations: [],
  providers: [GlobalService]
})
export class GlobalModule { }
