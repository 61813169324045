import { Component, OnInit, Input, Inject } from '@angular/core';
import { Information } from '../../model/information/information.model';
import * as Redux from 'redux';
import {
  CaptacaoState,
  getCurrentCustomer,
} from '../../../shared/store/captacao.reducer';
import { CaptacaoStore } from '../../store/captacao.store';
import { Customer } from '../../model/customer/customer.model';

@Component({
  selector: 'app-customer-information-table',
  templateUrl: './customer-information-table.component.html',
  styleUrls: ['./customer-information-table.component.scss']
})
export class CustomerInformationTableComponent implements OnInit {
  customer: Customer;
  customerInformationColumns: string[] = ['id', 'nome', 'cpf', 'cnpj', 'celular', 'telefone', 'status'];


  @Input() customerInformationData: Information[];

  constructor(
    @Inject(CaptacaoStore) private store: Redux.Store<CaptacaoState>) {
    this.customer = getCurrentCustomer(this.store.getState());
  }

  ngOnInit() {
  }

}
