import { Component, Inject, OnInit } from '@angular/core';
import { ClienteService } from '../../../customers/components/cliente/services/cliente.service';
import { Cliente } from '../../model/cliente/cliente.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { UtilsService } from '../../../../shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { ReplaySubject, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Employee } from '../../model/employee/employee.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-indicador-select-form',
  templateUrl: './indicador-select-form.component.html',
  styleUrls: ['./indicador-select-form.component.scss'],
})
export class IndicadorSelectFormComponent implements OnInit {
  dialogMessages;

  form: FormGroup;
  employee = [];
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  public EmployeeFilterControl: FormControl = new FormControl();
  responsavel: Employee;

  constructor(
    public dialog: MatDialog,
    private clienteService: ClienteService,
    private contratoService: ContractsService,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IndicadorSelectFormComponent>,
    private utilService: UtilsService,
    private formBuild: FormBuilder,
  ) {
    this.employeeService.findAll().subscribe((data) => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterPerfils();
      });
    });
  }

  ngOnInit(): any {
    this.setForm();
  }

  onCancel(): any {
    this.dialogRef.close(false);
  }

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(this.employee.filter((employee) => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1));
  }

  public selectResponsavel(value) {
    this.responsavel = value;
  }

  // @ts-ignore
  save(): any {
    this.contratoService.alterarIndicador(this.responsavel.id, this.data.contrato.id).subscribe(
      (ondata) => {
        if (ondata.success) {
          this.utilService.feedbackSuccess('Indicador alterado com sucesso...', TimeMensagem.TIME_MENSAGEM);
        } else {
          if (ondata.messages[0].text != null) {
            this.utilService.feedbackInfo(ondata.messages[0].text, TimeMensagem.TIME_MENSAGEM);
          }
          this.onCancel();
        }
      },
      (onerror) => {
        this.utilService.feedbackInfo('Erro ao alterar indicador! ', TimeMensagem.TIME_MENSAGEM);
        this.onCancel();
      },
    );

    this.onCancel();
  }

  private _onDestroy = new Subject<void>();

  setForm(): void {
    this.form = this.formBuild.group({
      indicador: [this.data.contrato.indicador ? this.data.contrato.indicador.id : null],
    });
  }
}
