import { MatSelectChange } from '@angular/material';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { ProdutoService } from 'app/main/global/components/produto/services/produto-service.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Employee } from '../../model/employee/employee.model';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Rota } from '../../model/rota/rota.model';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { Team } from '../../model/team/team.model';
import { CalculoValorLiquidoTO } from '../../model/calculo-valor-liquido/calculo-valor-liquido.to';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

@Component({
  selector: 'app-creditos-aprovacao',
  templateUrl: './creditos-aprovacao.component.html',
  styleUrls: ['./creditos-aprovacao.component.scss']
})
export class CreditosAprovacaoComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('limite') limite: ElementRef;
  @Input() aprovacao: FormGroup;
  @Input() visit: FormGroup;
  @Input() rota: FormGroup;

  kitsBoleto = [
    {id: 1, quantidade: 1, configuracao: 'semanal', label: '1 Parcela - em 7 Dias'},
    {id: 2, quantidade: 1, configuracao: 'mensal', label: '1 Parcela - em 30 Dias'},
    {id: 3, quantidade: 2, configuracao: 'semanal', label: '2 Parcela - a cada 7 Dias'},
    {id: 4, quantidade: 2, configuracao: 'quinzenal', label: '2 Parcela - a cada 15 Dias'},
    {id: 5, quantidade: 3, configuracao: 'tridiario', label: '3 Parcela - a cada 3 Dias'},
    {id: 6, quantidade: 4, configuracao: 'semanal', label: '4 Parcela - a cada 7 Dias'},
  ]

  public showDataVencimento = false;
  produtos;
  public rotas: Array<Rota> = [];
  public equipe: Team;
  currentRota: any;
  getRota = '';

  isPixOuDinheiro = false;
  postoId: any;
  posto: any;
  isPostoPix: false;
  isPostoEletro: false;
  habilitarDezParcelas: boolean;

  public configuracaoParcelasBoleto: FormControl = new FormControl();

  private CARTAO_KIT_LIST = ['CARTAO_KIT_1', 'CARTAO_KIT_2', 'CARTAO_KIT_3'];
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortValores = `${SGF_TYPE}` == 'FORTALEZA-VALORES' ? true : false;
  // tslint:disable-next-line:triple-equals
  salvValores = `${SGF_TYPE}` == 'SALVADOR-VALORES' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortBoletos = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;
  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  private keyUpSubject: Subject<void> = new Subject<void>();

  public produtoFilterCtrl: FormControl = new FormControl();
  public filteredProduto: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public rotaFilterCtrl: FormControl = new FormControl();
  public filteredRota: ReplaySubject<Rota[]> = new ReplaySubject<Rota[]>(1);
  calculoValorLiquidoTO = new CalculoValorLiquidoTO();
  constructor(
      private rotasService: RotasService,
      private productService: ProdutoService,
      private contratoService: ContractsService,
      private rotaService: RotasService,
      private postoService: PostsService) {

    this.productService.findAll().subscribe(data => {
      this.produtos = data.object.content;
      this.filteredProduto.next(this.produtos.slice());
      this.produtoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProduto();
        });
    });

    this.checkIfHasPosto();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.aprovacao && changes.aprovacao.currentValue) {
      if (this.aprovacao.get('pagamentoBoleto').value) {
        this.configuracaoParcelasBoleto.setValue(this.kitsBoleto.find(o => o.quantidade === parseInt(this.aprovacao.get('quantidadeParcelas').value, null) && o.configuracao === this.aprovacao.get('periodicidadeParcela').value.toString().toLowerCase()));
      }
    }    
  }

  private _onDestroy = new Subject<void>();

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.postoId = user.idPosto ? user.idPosto : null;
    }

    if(this.postoId != null) {
      this.postoService.findById(this.postoId).subscribe((resp: any) => {
        this.posto = resp.object;
        this.habilitarDezParcelas = this.posto.parametroPosto.habilitarDezParcelas;
        this.isPostoPix = this.posto.parametroPosto.pixBoleto;
        this.isPostoEletro = this.posto.parametroPosto.eletro;
      })
    }
  }


  ngOnInit(): void {
    this.getRota = this.aprovacao.get('rota').value.descricao;
    this.equipe = this.visit.get('equipe').value;

    this.rotaService.findByIdEquipe(this.equipe.id).subscribe(data => {
      this.rotas = data.object;
      this.filteredRota.next(this.rotas.slice());
      this.rotaFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterRota();
        });
    });

    const tipoContrato = this.aprovacao.get('tipoContrato');
    if (tipoContrato && tipoContrato.value) {
      this.showDataVencimento = this.CARTAO_KIT_LIST.includes(tipoContrato.value);
    }

    this.rotasService.listAll().subscribe(data => { this.rotas = data.object.content });

    this.isPixOuDinheiro = 
    (this.aprovacao.get('pagamentoPix').value || this.aprovacao.get('pagamentoDinheiro').value);

    this.aprovacao.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(value => {
      this.calculoValorLiquidoTO.quantidadeParcelas = value.quantidadeParcelas;
      this.calculoValorLiquidoTO.pagamentoBoleto = value.pagamentoBoleto;
      this.calculoValorLiquidoTO.pagamentoCartao = value.pagamentoCartao;
      this.calculoValorLiquidoTO.pagamentoDinheiro = value.pagamentoDinheiro;
      this.calculoValorLiquidoTO.pagamentoPix = value.pagamentoPix;
      this.calculoValorLiquidoTO.valorAprovado = value.valorAprovado;
      this.calculoValorLiquidoTO.tipoContrato = value.tipoContrato;
    });

    this.keyUpSubject.pipe(debounceTime(500))
    .subscribe(() => {
      this.wrapper();
    });

  }

  valorKeyup() {
    this.keyUpSubject.next();
  }

  wrapper(): void {
    if(this.calculoValorLiquidoTO.quantidadeParcelas != null 
      && this.calculoValorLiquidoTO.valorAprovado != null
      && this.calculoValorLiquidoTO.tipoContrato != null) {
        this.contratoService.calcularValorLiquido(this.calculoValorLiquidoTO).subscribe(resp => {
          if(resp.object) {
            this.aprovacao.get('limiteCreditoAprovado').setValue(resp.object);
          } else {
            this.aprovacao.get('limiteCreditoAprovado').setValue('0,00');
          }
        });        
      }
  }

  public formaPagamentoSelect(value: number): void {
    if (value == 1) {
      this.aprovacao.get('pagamentoBoleto').setValue(true);
      this.aprovacao.get('pagamentoCartao').setValue(false);
      this.aprovacao.get('pagamentoDinheiro').setValue(false);
      this.aprovacao.get('pagamentoPix').setValue(false);

      this.aprovacao.get('tipoContrato').setValue('BOLETO');
    // tslint:disable-next-line:triple-equals
    } else if (value == 2) {
      this.aprovacao.get('pagamentoCartao').setValue(true);
      this.aprovacao.get('pagamentoBoleto').setValue(false);
      this.aprovacao.get('pagamentoDinheiro').setValue(false);
      this.aprovacao.get('pagamentoPix').setValue(false);
    } else if (value == 3) {
      this.aprovacao.get('pagamentoDinheiro').setValue(true);
      this.aprovacao.get('pagamentoCartao').setValue(false);
      this.aprovacao.get('pagamentoBoleto').setValue(false);
      this.aprovacao.get('pagamentoPix').setValue(false);

      this.aprovacao.get('tipoContrato').setValue('DINHEIRO');
    } else {
      this.aprovacao.get('pagamentoPix').setValue(true);
      this.aprovacao.get('pagamentoDinheiro').setValue(false);
      this.aprovacao.get('pagamentoCartao').setValue(false);
      this.aprovacao.get('pagamentoBoleto').setValue(false);

      this.aprovacao.get('tipoContrato').setValue('DINHEIRO');
    }

    //REGRA COMPLEMENTAR PARA PAGAMENTO EM BOLETO (1) OU PIX (4)
    //TODO: POSSIVEL MODIFICAÇÃO NO VALUE DA QUANTIDADE PARA STRING 
    //REF ANTERIOR REALIZAVA ISSO
    const quantidadeRef = this.aprovacao.get('quantidadeParcelas');
    const periodicidadeRef = this.aprovacao.get('periodicidadeParcela');
    if (value == 1) {
      this.isPixOuDinheiro = false;
      quantidadeRef.setValue(2);
      quantidadeRef.enable();
      periodicidadeRef.setValue(null);
      periodicidadeRef.disable();
    } else if (value == 3 || value == 4) {
      this.isPixOuDinheiro = true;
      periodicidadeRef.setValue('DIARIA');
      periodicidadeRef.disable();
    } else {
      this.isPixOuDinheiro = false;
      quantidadeRef.setValue(null);
      quantidadeRef.enable();

      periodicidadeRef.setValue(null);
      periodicidadeRef.enable();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.limite.nativeElement.focus(), 1000);
  }

  public onSelectTipoContrato(matSelectChange: MatSelectChange): void {

    this.showDataVencimento = this.CARTAO_KIT_LIST.includes(matSelectChange.value);

    const dataVencimento = this.aprovacao.get('dataVencimento');

    if (this.showDataVencimento) {
      dataVencimento.setValidators([Validators.required]);
    } else {
      dataVencimento.setValidators([]);
    }
    dataVencimento.reset();
    dataVencimento.updateValueAndValidity();

    this.formaPagamentoSelect(1);
  }

  getErrorMessage(field: string): String {
    return `O campo ${field} é obrigatório`;
  }

  private filterProduto(): void {
    if (!this.produtos) {
      return;
    }

    let search = this.produtoFilterCtrl.value;
    if (!search) {
      this.filteredProduto.next(this.produtos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredProduto.next(
      this.produtos.filter(p => p.nome.toLowerCase().indexOf(search) > -1)
    );

  }

  private filterRota(): void {
    if (!this.rotas) {
      return;
    }

    let search = this.rotaFilterCtrl.value;
    if (!search) {
      this.filteredRota.next(this.rotas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRota.next(
      this.rotas.filter(p => p.cobrador.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  serviceOnSelect(rota: Rota): any {
    this.currentRota = rota;
    this.aprovacao.get('rota').setValue(this.currentRota);
  }

  doFilter(evento: Event): any {
    const target = evento.target as HTMLTextAreaElement;
    this.rotasService.listAll().subscribe( data => {
      this.rotas = data.object.content;
    });
  }

  displayFn(rota: Rota): string {
    return rota ? rota.descricao : '';
  }

  onSelectConfiguracaoBoleto(event: {quantidade: number, configuracao: string}): void {
    const quantidadeRef = this.aprovacao.get('quantidadeParcelas');
    quantidadeRef.setValue(event.quantidade);

    const periodicidadeRef = this.aprovacao.get('periodicidadeParcela');
    switch(event.configuracao) {
      case 'tridiario':
        periodicidadeRef.setValue('TRIDIARIO');
      break;
      case 'semanal':
        periodicidadeRef.setValue('SEMANAL');
      break;
      case 'quinzenal':
        periodicidadeRef.setValue('QUINZENAL');
      break;
      case 'mensal':
        periodicidadeRef.setValue('MENSAL');
      break;
      default:
        console.log('periodicidade not allowed')

    }
  }

}
