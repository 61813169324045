import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { PeriodReportComponent } from 'app/main/report/components/period-report/period-report-main/period-report.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-canceled-sidebar',
  templateUrl: './canceled-sidebar.component.html',
  styleUrls: ['./canceled-sidebar.component.scss']
})
export class CanceledSidebarComponent implements OnInit, OnChanges {
  filterBy: string;
  all = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean;
  filter: string;
  inputDisabled = false;
  search = '';
  placeholder: string;
  situacao: string;
  @Input() searchInput: FormControl;
  @Input() action: string;
  datas = '';
  lastSearchFilter: string;
  Period: string;
  lastFilter: string;
  lastPage;
  inputPeriodo = false;
  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Output() periodEvent: EventEmitter<any> = new EventEmitter(); 
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataPeriodoEvent: EventEmitter<string> = new EventEmitter();

  constructor(private contractService: ContractsService, private _matDialog: MatDialog, private route: ActivatedRoute) {
    if (!this.hasLastFilters()){
      this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
      this.placeholder = 'Buscar por Cliente';
      this.filter = 'NOME_CLIENTE';
    }
  }

  openPeriodFilter(): any{
    this._matDialog.open(PeriodReportComponent, {
      data: {
          type: 'period-filter',
          title: 'Periodo Desejado',
      }
    }).afterClosed().subscribe( data => { 
      this.datas = data; 
      this.getFilter('FILTER_PERIOD'); 
      this.filterPeriodo();
    });
  }

  ngOnInit(): any {
    if (this.hasLastFilters()){     
      this.callLastFilters(); 
    } 
   }

  ngOnChanges(): any {
    if (this.searchInput == null) {
      this.search = '';
    }
  }

  clear(): void{
    this.filter = 'NOME_CLIENTE';
    this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO';
    this.inputPeriodo = false;
    this.inputDisabled = false;
    this.search = null;
    this.placeholder = 'Buscar por Cliente';
  }

  getFilter(filter): any {
    switch (filter) {
      case 'all':
        this.filter = 'NOME_CLIENTE';
        this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
        this.search = null;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('all');
        break;
      case 'NOVO,RENOVADO,GERADO':
        this.filter = filter;
        this.situacao = 'NOVO,RENOVADO,GERADO';
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('NOVO,RENOVADO,GERADO');
        break;
      case 'LIBERADO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'LIBERADO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('LIBERADO');
        break;
      case 'EM_ANDAMENTO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'EM_ANDAMENTO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('EM_ANDAMENTO');
        break;
      case 'CANCELADO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'CANCELADO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('CANCELADO');
        break;
      case 'EM_ATRASO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'EM_ATRASO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('EM_ATRASO');
        break;
      case 'FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = filter;
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit(filter);
        break;
      case 'LIQUIDADO_ADMINISTRACAO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'LIQUIDADO_ADMINISTRACAO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('LIQUIDADO_ADMINISTRACAO');
        break;
      case 'REPACTUADO':
        this.filter = filter;
        this.inputDisabled = true;
        this.search = null;
        this.placeholder = 'Selecione um filtro';
        this.situacao = 'REPACTUADO';
        this.situacaoEvent.emit(this.situacao);
        this.filterEvent.emit('REPACTUADO');
        break;

      case 'DOCUMENTO_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Documento Cliente';
        this.filterEvent.emit('DOCUMENTO_CLIENTE');
        break;
      case 'ID_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por ID Cliente';
        this.filterEvent.emit('ID_CLIENTE');
        break;
      case 'NOME_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.filterEvent.emit('NOME_CLIENTE');
        break;
      case 'NOME_BUSCA':
        this.filter = 'NOME_BUSCA';
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Nome Busca';
        this.filterEvent.emit(this.filter);
        break;
      case 'RESPONSAVEL_CONTRATO':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Contrato';
        this.filterEvent.emit('RESPONSAVEL_CONTRATO');
        break;
      case 'ROTA':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Rota';
        this.filterEvent.emit('ROTA');
        break;
      case 'RESPONSAVEL_REPASSE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Repasse';
        this.filterEvent.emit('RESPONSAVEL_REPASSE');
        break;
      case 'FILTER_PERIOD':
        this.filter = filter;
        this.search = null;
        this.filterEvent.emit('FILTER_PERIOD');
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'TODOS_HOJE':
        this.filter = filter;
        this.search = null;
        this.filterEvent.emit('TODOS_HOJE');
        this.placeholder = 'Contratos de hoje';
        break;
    }
  }

  // Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any): any {
    this.searchEvent.emit(this.search);
    this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          // tslint:disable-next-line:triple-equals
          if (this.search.length > 2 || event.code == 'Enter') {
            this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'ID_CLIENTE':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          const idCliente = Number(this.search);
          // tslint:disable-next-line:triple-equals
          if (!isNaN(idCliente) && (this.search.length > 0 || event.code == 'Enter')) {
            this.contractService.pageByIdCliente(this.situacao, idCliente, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'NOME_CLIENTE':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          // tslint:disable-next-line:triple-equals
          if (this.search.length > 2 || event.code == 'Enter') {
            this.contractService.pageByNomeCliente(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;

      case 'NOME_BUSCA':
          if (this.search === '') {
            this.contractService.page(this.situacao, 10, 1, '+dataVencimento').subscribe(data => {
              this.data.emit(data.object);
            });
          } else {
            if (this.search.length > 2 || event.code === 'Enter') {
              this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, 1).subscribe(data => {
                this.data.emit(data.object);
              });
            }
          }
        break
      case 'RESPONSAVEL_CONTRATO':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          // tslint:disable-next-line:triple-equals
          if (this.search.length > 2 || event.code == 'Enter') {
            this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'ROTA':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          // tslint:disable-next-line:triple-equals
          if (this.search.length > 2 || event.code == 'Enter') {
            this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        // tslint:disable-next-line:triple-equals
        if (this.search == '') {
          this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          // tslint:disable-next-line:triple-equals
          if (this.search.length > 2 || event.code == 'Enter') {

            this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, 1).subscribe(data => {
              this.data.emit(data.object);
            });
          }
        }
        break;
    }
  }

  filterPeriodo(): any {
    this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, 'dataFinalizacao', this.datas).subscribe(data => {
      this.periodEvent.emit(this.datas);
      this.data.emit(data.object);
    });
  }

  callLastFilters(): any {

    if ( this.lastPage != null && this.Period == null) {

      this.pageEvent.emit(this.lastPage);
      this.settingPlaceHolder(this.lastFilter);

      // tslint:disable-next-line:triple-equals
    } else if (this.lastFilter != null && this.lastFilter != 'FILTER_PERIOD' && this.lastPage == null && this.lastSearchFilter == null) {

      this.getFilter(this.lastFilter);
      this.settingPlaceHolder(this.lastFilter);


    } else if (this.lastSearchFilter != null && this.lastFilter == null && this.lastPage == null) {

      this.filter = 'NOME_CLIENTE';
      this.getFilter(this.filter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastSearchFilter != null && this.lastFilter != null && this.lastPage == null) {

      this.getFilter(this.lastFilter);
      this.onKeyPressFilter(this.lastSearchFilter);
      this.settingPlaceHolder(this.lastFilter);

    } else if (this.lastPage != null && this.Period != null) {

      this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,GERADO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, this.lastPage, 'dataFinalizacao', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    } else if ( this.Period != null && this.lastPage == null ) {

      this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,GERADO';
      this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, 'dataFinalizacao', this.Period).subscribe(data => {
        this.dataPeriodoEvent.emit(this.Period);
        this.data.emit(data.object);
      });
      this.settingPlaceHolder(this.lastFilter);

    }

  }

  hasLastFilters(): boolean {

    // tslint:disable-next-line:triple-equals
    if (this.route.snapshot.queryParamMap.has('filter') == false
        // tslint:disable-next-line:triple-equals
      && this.route.snapshot.queryParamMap.has('search') == false
        // tslint:disable-next-line:triple-equals
      && this.route.snapshot.queryParamMap.has('pageNum') == false)
    {

      this.lastFilter = null;
      this.lastSearchFilter = null;
      this.Period = null;
      this.lastPage = null;

      return false;

    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter');
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search');
    }

    if (this.route.snapshot.queryParamMap.has('dataPeriodo')) {
      this.Period = this.route.snapshot.queryParamMap.get('dataPeriodo');
    }

    if (this.route.snapshot.queryParamMap.has('pageNum')) {
      this.lastPage = this.route.snapshot.queryParamMap.get('pageNum');
    }

    return true;

  }

  settingPlaceHolder(filter): any {

    // tslint:disable-next-line:triple-equals
    if (filter == null || filter == undefined) {
      filter = 'NOME_CLIENTE';
    }

    this.filter = filter;

    switch (filter) {
      case 'all':      
        this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
        this.search = null;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        break;
      case 'PENDENTE':
        this.filter = 'NOME_CLIENTE'; 
        this.situacao = 'PENDENTE';
        this.inputDisabled = false;
        this.search = null;
        this.placeholder = 'Buscar por Cliente';
        break;

      case 'DOCUMENTO_CLIENTE':
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Documento Cliente';
       
        break;
      case 'NOME_CLIENTE':
        this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
        this.inputPeriodo = false;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
       
        break;
      case 'RESPONSAVEL_CONTRATO':
        this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
        this.inputPeriodo = false;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Contrato';
        
        break;
      case 'RESPONSAVEL_REPASSE':
        this.situacao = 'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,RENEGOCIADO,GERADO';
        this.inputPeriodo = false;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Repasse';        
        break;
    }
  }
}
