import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArquivoClienteService {

  host = `${SGF_API}sgf/api/public/arquivocliente`;

  constructor(private httpClient: HttpClient) { }

  findByCliente(clienteId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/findbycliente/${clienteId}`, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  save(arquivoCliente: any): Observable<any> {
    const body = JSON.stringify(arquivoCliente);
    return this.httpClient.post(`${this.host}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(idArquivo: number): Observable<any> {
    return this.httpClient.delete(`${this.host}/${idArquivo}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  baixarArquivo(idArquivo: number): Observable<any> {
    return this.httpClient.get(`${this.host}/baixar-arquivo?idArquivo=${idArquivo}`, { responseType: 'blob' });
  }

  pageByCliente(size: number, page: number, clienteId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/findbycliente?size=${size}&page=${page}&idCliente=${clienteId}`, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
