import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Bairro } from 'app/main/shared/model/activity/activity.model';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  private bairro: string = `${SGF_API}` + 'core/api/public/bairro';

  constructor(private httpClient : HttpClient) { }

  findAll(): any {
    return this.httpClient.get<Bairro[]>(this.bairro, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  page(size:number, page:number): any {
    return this.httpClient.get(`${this.bairro}?size=${size}&page=${page}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  create(benefits: any): any {
    const body = JSON.stringify(benefits);
    return this.httpClient.post(this.bairro, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(benefits: any): any {
    const body = JSON.stringify(benefits);
    return this.httpClient.put(this.bairro, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  bairroByLikeDescricao(descricao: string, page: number, size: number): any {
    return this.httpClient.get(`${this.bairro}?descricao=like:${descricao}&page=${page}&size=${size}`)
      .pipe(
        catchError(this.handleError));
  }



  httpOptions(token?: string): any {
    return {headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };
}
