import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { CommissionService } from 'app/main/global/components/commission/services/commission.service';

export interface DialogData {
  action: string,
  data: any
}

@Component({
  selector: 'app-commission-form',
  templateUrl: './commission-form.component.html',
  styleUrls: ['./commission-form.component.scss']
})
export class CommissionFormComponent implements OnInit, AfterViewInit {

  commission: FormGroup;
  titleHeader: string;
  disabledButton:boolean;
  dialogMessages;

  @ViewChild('tipo') tipo: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private commissionService: CommissionService,
    public matDialogRef: MatDialogRef<CommissionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
      
      this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Comissão' : 'Edição de Comissão';

      this.setFormGroup();

  }

  setFormGroup() {

    let percentual    = this._data.data.percentual ? this._data.data.percentual.toString() : null;
    let tamPercentual = this._data.data.percentual ? percentual.length : null;
    percentual        = this._data.data.percentual ? `${percentual.substring(0, tamPercentual - 2)}.${percentual.substring(tamPercentual - 2)}` : null;

    this.commission = this.formBuilder.group({
      id          : [ this._data.data.id         || null ],
      descricao   : [ this._data.data.descricao  || null, Validators.required ],
      percentual  : [ percentual || null, Validators.required ]
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.tipo.nativeElement.focus(), 1000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

  onAdd(value) {
    this.disabledButton = true;
    value.percentual = value.percentual.toString().replace(/[.,]/g, "");

    this.commissionService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    value.percentual = value.percentual.toString().replace(/[.,]/g, "");
    
    this.commissionService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
