import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';



@Injectable({
  providedIn: 'root'
})
export class ResolveReleasedService implements Resolve<any> {

  constructor(
    private contractService: ContractsService) { }

    resolve(): Observable<any> {
      return this.contractService.page('LIBERADO', 10, 1).pipe(
        take(1),
        mergeMap(res => {
          return of(res['object']);
        })
      );
    }

}
