import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { UtilsService } from '../../../../shared/util/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-reagendar-contrato-form',
  templateUrl: './reagendar-contrato-form.component.html',
  styleUrls: ['./reagendar-contrato-form.component.scss'],
})
export class ReagendarContratoFormComponent implements OnInit {
  form: FormGroup;
  dialogMessages;
  currentCliente: any;

  clientes: any;

  constructor(
    public dialog: MatDialog,
    private contratoService: ContractsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReagendarContratoFormComponent>,
    private utilService: UtilsService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): any {
    this.form = this.formBuilder.group({
      antecipacao: [null, Validators.required],
    });
  }

  onCancel(): any {
    this.dialogRef.close(false);
  }

  save(contratoForm: any): any {
    const contrato = {
        id: this.data.contrato.id,
        dataEmissao: contratoForm.antecipacao
    };
    this.contratoService.reagendaContract(contrato).subscribe(
        ondata => {
            if (ondata.success) {
                this.utilService.feedbackSuccess('Contrato reagendado com sucesso...', TimeMensagem.TIME_MENSAGEM);
            } else {
                if (ondata.messages[0].text != null) {
                    this.utilService.feedbackInfo(ondata.messages[0].text, TimeMensagem.TIME_MENSAGEM);
                }
                this.onCancel();
            }
        },
        (onerror) => {
            this.utilService.feedbackInfo('Erro ao reagendar contrato! ', TimeMensagem.TIME_MENSAGEM);
            this.onCancel();
        }
    );

    this.onCancel();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }
}
