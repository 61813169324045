import { Segment } from '../segment/segment.model';

export class Information extends Segment{

    cpf?: string;
    cnpj?: string;
    email?: string;
    nome?: string;
    razaoSocial?: string;
    rg?: string;
    sexo?: string;
    estadoCivil: string;
    dataNascimento: Date;
    alfabetizado: boolean;

    constructor(obj?: any) {
        super();
        this.cpf = obj ? obj.cpf : null;
        this.rg  = obj ? obj.rg : null;
        this.cnpj = obj ? obj.cnpj : null;
        this.email = obj ? obj.email : null;
        this.nome = obj ? obj.nome : null;
        this.sexo = obj ? obj.sexo : null;
        this.estadoCivil = obj ? obj.estadoCivil : null;
        this.dataNascimento = obj ? obj.dataNascimento : null;
    }
}
