import { httpInterceptorProviders } from './shared/index';
import 'hammerjs';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

import { HomeModule } from './main/home/home.module';
import { CustomersModule } from './main/customers/customers.module';
import { ContractsModule } from './main/contracts/contracts.module';
import { GlobalModule } from './main/global/global.module';
import { AppRoutingModule } from './app.routing.module';
import { AuthenticationModule } from './main/authentication/authentication.module';
import { SharedMaterialComponentsModule } from './shared-material-components.module';
import { AuthenticationService } from './main/authentication/services/authentication.service';
import { AuthGuard } from './shared/guards/auth/auth-guard.service';
import { FinancialModule } from './main/financial/financial.module';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { KeysPipe } from '@fuse/pipes/keys.pipe';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgChatModule } from 'ng-chat';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgChatModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        SharedMaterialComponentsModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
        CustomersModule,
        ContractsModule,
        FinancialModule,
        GlobalModule,
        AuthenticationModule,
        FusePipesModule,
        NgxLoadingModule.forRoot({}),
        NgxPermissionsModule.forRoot()
    ],
    exports: [KeysPipe],
    bootstrap   : [
        AppComponent,
    ],
    providers: [
        httpInterceptorProviders,
        AuthenticationService,
        AuthGuard,

    ],
})
export class AppModule
{
}
