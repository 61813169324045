import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { DiscountService } from 'app/main/global/components/discount/services/discount.service';

export interface DialogData {
  action: string,
  data: any
}

@Component({
  selector: 'app-discount-form',
  templateUrl: './discount-form.component.html',
  styleUrls: ['./discount-form.component.scss']
})
export class DiscountFormComponent implements OnInit, AfterViewInit {

  discount: FormGroup;
  titleHeader: string;
  disabledButton:boolean;
  dialogMessages;
  @ViewChild('descricao') descricao: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private discountService: DiscountService,
    public matDialogRef: MatDialogRef<DiscountFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

      this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Desconto' : 'Edição de Desconto';

      this.setFormGroup();
  }

  setFormGroup() {

    let aliquota    = this._data.data.aliquota ? this._data.data.aliquota.toString() : null;
    let tamAliquota = this._data.data.aliquota ? aliquota.length : null;
    aliquota        = this._data.data.aliquota ? `${aliquota.substring(0, tamAliquota - 2)}.${aliquota.substring(tamAliquota - 2)}` : null;

    this.discount = this.formBuilder.group({
      id        : [ this._data.data.id        || null ],
      descricao : [ this._data.data.descricao || null, Validators.required ],
      aliquota  : [ aliquota                  || null, Validators.required ]
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

  onAdd(value) {
    this.disabledButton = true;
    value.aliquota = value.aliquota.toString().replace(/[.,]/g, "");

    this.discountService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR DESCONTO', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    value.aliquota = value.aliquota.toString().replace(/[.,]/g, "");
    
    this.discountService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
