import { MarcaService } from '../../../global/components/marca/services/marca.service';
import { ModeloFormComponent } from '../modelo-form/modelo-form.component';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { LancamentoAgenda } from '../../model/lancamento-agenda/lancamento-agenda.model';
import { LancamentoAgendaService } from 'app/main/global/components/lancamento-agenda/services/lancamento-agenda.service';
import { Posto } from '../../model/posto/posto.model';
import { Team } from '../../model/team/team.model';
import { Rota } from '../../model/rota/rota.model';
import { TeamService } from 'app/main/global/components/teams/services/team.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { Employee } from '../../model/employee/employee.model';
import { forIn } from 'lodash';

@Component({
  selector: 'app-lancamento-agenda-form',
  templateUrl: './lancamento-agenda-form.component.html',
  styleUrls: ['./lancamento-agenda-form.component.scss']
})
export class LancamentoAgendaFormComponent implements OnInit {

  lancamentoAgenda: LancamentoAgenda;
  disabledButton: boolean;
  dialogMessages;
  lancamentosAgenda = [];
  titleHeader: string;

  fiscalPosto: Posto;
  public fiscais: Array<Employee> = [];

  public postos: Array<Posto> = [];
  public equipes: Array<Team> = [];
  public rotas: Array<Rota> = [];

  @ViewChild('nome') nome: ElementRef;

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: { lancamentoAgenda: LancamentoAgenda; action: string },
    private matDialogRef: MatDialogRef<LancamentoAgendaFormComponent>,
    private lancamentoAgendaService: LancamentoAgendaService,
    private teamService: TeamService,
    private postoService: PostsService,
    private rotaService: RotasService
  ) {

    if (this._data.lancamentoAgenda != null) {
      this.lancamentoAgenda = this._data.lancamentoAgenda;
      this.fiscalPosto = this.lancamentoAgenda.fiscal.posto
      this.getEquipesFiscal(this.fiscalPosto);
      this.getEquipes(this.lancamentoAgenda.posto);
      this.gerRotas(this.lancamentoAgenda.equipe);

    } else {
      this.lancamentoAgenda = new LancamentoAgenda();
    }

    // tslint:disable-next-line:triple-equals
    this.titleHeader = this._data.action == 'new' ? 'Realizar Lançamento de Agenda' : 'Editar Lançamento de Agenda';
  }

  ngOnInit(): void {

    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
    });

  }

  get lancamentoInvalido(): boolean {
    return Object.entries(this.lancamentoAgenda)
      .map(([ prop, value ]) => ['id', 'observacao'].includes(prop) || value || false)
      .includes(false);
  }

  getEquipes(posto: Posto): void {
    this.teamService.findByPostoId(posto.id).subscribe(data => {
      this.equipes = data.object;
    });
  }

  getEquipesFiscal(posto: Posto): void {
    this.teamService.findByPostoId(posto.id).subscribe(data => {
      this.fiscais = data.object.map(equipe => equipe.fiscal);
    });
  }

  gerRotas(equipe: Team): void {
    this.rotaService.findByIdEquipe(equipe.id).subscribe(data => {
      this.rotas = data.object;
    });
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  onSave(): void {
    this.disabledButton = true;
    this.lancamentoAgendaService.create(this.lancamentoAgenda).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR LANÇAMENTO AGENDA', onerror);
      }
    );
  }

  onUpdate(): void {
    this.disabledButton = true;
    this.lancamentoAgendaService.update(this.lancamentoAgenda).subscribe((ondata: ReturnApi) => {
      if (ondata.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${ondata.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(ondata.object);
      } else {
        if (ondata.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${ondata.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR LANÇAMENTO AGENDA', onerror);
      }
    );
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

}
