import { Component, OnInit, Inject } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { formatDateTime } from '../../util/format-date-time';

@Component({
  selector: 'app-alterar-responsavel-contratos',
  templateUrl: './alterar-responsavel-contratos.component.html',
  styleUrls: ['./alterar-responsavel-contratos.component.scss']
})
export class AlterarResponsavelContratosComponent implements OnInit {

  form: FormGroup;
  employee: Array<Employee> =  [];
  newEmployee: Array<Employee>;
  title: string;
  disabledButton: boolean;
  dialogMessages;

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public NewEmployeeFilterControl: FormControl = new FormControl();
  public filteredNewEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  
  constructor(private employeeService: EmployeeService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<AlterarResponsavelContratosComponent>,
              @Inject(MAT_DIALOG_DATA) public _data: any,
              private contractService: ContractsService) { 
    
    this.employeeService.findAll().subscribe(data => {
      let listEmp = [];
      listEmp = data.object.content;
      listEmp.forEach(r => {
        if (r.posto != null) {
          this.employee.push(r);
        }
      }); 
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    });
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void{
    this.form = this.formBuilder.group({
      dataInicialParcela : [null],
      dataFinalParcela: [null],
      idAtualResponsavel : [null, Validators.required],
      idNovoResponsavel: [null, Validators.required]
    });
  }

  // tslint:disable-next-line:member-ordering
  private _onDestroy = new Subject<void>();

  private filterPerfils(): any {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterNewPerfils(): any {
    if (!this.newEmployee) {
      return;
    }

    let search = this.NewEmployeeFilterControl.value;
    if (!search) {
      this.filteredNewEmployee.next(this.newEmployee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredNewEmployee.next(
      this.newEmployee.filter(newEmployee => newEmployee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  filterNovoResponsavel(emp: any): void {
    
    this.newEmployee = [];
    this.employee.forEach( e => {
      // tslint:disable-next-line:triple-equals
      if (e.posto.id == emp.posto.id && e.id != emp.id) {
        this.newEmployee.push(e);
      }
    });

    this.filteredNewEmployee.next(this.newEmployee.slice());
        this.NewEmployeeFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterNewPerfils();
          });
  }


  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(value): void {
        
    this.contractService.transferirResponsavelDeTodosOsContratos(value).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.dialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }

        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }

    },
      (onerror) => {
        console.log('ERROR BANCO', onerror);
      }
    );
  }
  
  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }


}
