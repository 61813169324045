import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { VisitadosService } from 'app/main/customers/components/visitados/services/visitados.services';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  videoUrl;
  idVisita: number;
  videoData: string;
  videoBlob: any;

  constructor(private visitadosService: VisitadosService,
    private route: ActivatedRoute, private sanitizer: DomSanitizer) { 
      this.idVisita = parseInt(this.route.snapshot.paramMap.get('id'));
    }

  ngOnInit() {
    this.visitadosService.getCodeByVisit(this.idVisita).subscribe(data => {
      data.object.filter(fotos => {
        let extensionFile =  fotos.nome.split(".").pop();
        if(extensionFile === 'mp4') {
          return fotos;
        }
      }).map(fotos => {
        this.visitadosService.findbycodigoincontext(fotos.codigo).subscribe((data : Blob | any) => {
          this.videoBlob = data;
          
          this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.videoBlob));
        });
      });
    });
  }

}
