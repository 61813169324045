import { Component, EventEmitter, Inject, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatSelectChange} from '@angular/material';
import {Employee} from 'app/main/shared/model/employee/employee.model';
import {ReplaySubject, Subject} from 'rxjs';
import {Visitado} from 'app/main/shared/model/visitado/visitado.model';
import {takeUntil, debounceTime} from 'rxjs/operators';
import {Mask} from '@fagnerlima/ng-mask';
import {Product} from 'app/main/shared/model/product/product.model';
import {ProdutoService} from 'app/main/global/components/produto/services/produto-service.service';
import {SGF_TYPE} from 'app/shared/api/sgf.api';
import {Rota} from '../../../model/rota/rota.model';
import {RotasService} from '../../../../global/components/rotas/services/rotas.service';
import { CalculoValorLiquidoTO } from 'app/main/shared/model/calculo-valor-liquido/calculo-valor-liquido.to';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

@Component({
  selector: 'app-visita-parecer-form',
  templateUrl: './visita-parecer-form.component.html',
  styleUrls: ['./visita-parecer-form.component.scss']
})
export class VisitaParecerFormComponent implements OnInit, OnChanges {

  mask = new Mask('000');

  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortValores = `${SGF_TYPE}` == 'FORTALEZA-VALORES' ? true : false;
  // tslint:disable-next-line:triple-equals
  salvValores = `${SGF_TYPE}` == 'SALVADOR-VALORES' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortBoletos = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;
  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  isPixOuDinheiro = false;

  habilitarDezParcelas:boolean;

  public produtoFilterCtrl: FormControl = new FormControl();
  public filteredProduto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private keyUpSubject: Subject<void> = new Subject<void>();

  @Input() visit: FormGroup;
  @Input() parecer: FormGroup;

  visitTelefone: FormGroup;
  visitados: FormGroup;
  visitadosList: Visitado[];
  listFuncionarios: Array<Employee>;
  produtos: Array<Product>;
  produto: Product;
  listIndicador: Array<Employee> = [];
  @Input() novaVisita;
  calculoValorLiquidoTO = new CalculoValorLiquidoTO();

  public disabledButton = false;

  public indicadorFilterControl: FormControl = new FormControl();
  public filteredIndicadores: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  private _onDestroy = new Subject<void>();

  public showDataVencimento = false;

  private CARTAO_KIT_LIST = ['CARTAO_KIT_1', 'CARTAO_KIT_2', 'CARTAO_KIT_3'];

  public showAntecipacao = false;

  private CARTAO_KIT_LIST_ANTECIPACAO = ['CARTAO_KIT_2', 'CARTAO_KIT_3'];

  public showDiasAntecipacao = false;

  public configuracaoParcelasBoleto: FormControl = new FormControl();

  @Output() contratoEmitter: EventEmitter<any> = new EventEmitter();
  rotas: any;
  currentRota: any;
  postoId: any;
  posto: any;
  isPostoPix: false;
  isPostoEletro: false;

  constructor(
    private rotasService: RotasService,
    private productService: ProdutoService,
    private contratoService: ContractsService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private postoService: PostsService) { 
      this.checkIfHasPosto();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parecer && changes.parecer.currentValue) {
      this.isPixOuDinheiro =  (this.parecer.get('pagamentoDinheiro').value || this.parecer.get('pagamentoPix').value);
    }
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.postoId = user.idPosto ? user.idPosto : null;
    }

    if(this.postoId != null) {
      this.postoService.findById(this.postoId).subscribe((resp: any) => {
        this.posto = resp.object;
        this.habilitarDezParcelas = this.posto.parametroPosto.habilitarDezParcelas;
        this.isPostoPix = this.posto.parametroPosto.pixBoleto;
        this.isPostoEletro = this.posto.parametroPosto.eletro;
      })
    }
  }

  ngOnInit(): void {
    this.productService.findAll().subscribe(data => {
      this.produtos = data.object.content;
      this.filteredProduto.next(this.produtos.slice());
      
      this.produtoFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProduto();
      });
    });

    this.parecer.get('produto').valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterProduto();
    });

    const tipoContrato = this.parecer.get('tipoContrato');
    const antecipacao = this.parecer.get('antecipacao');
    if (tipoContrato && tipoContrato.value) {
      this.showDataVencimento = this.CARTAO_KIT_LIST.includes(tipoContrato.value);
      this.showAntecipacao = this.CARTAO_KIT_LIST_ANTECIPACAO.includes(tipoContrato.value);
      if (antecipacao && antecipacao.value === 'true') {
        this.showDiasAntecipacao = true;
      }
    }

    this.parecer.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(value => {
      this.calculoValorLiquidoTO.quantidadeParcelas = value.quantidadeParcelas;
      this.calculoValorLiquidoTO.pagamentoBoleto = value.pagamentoBoleto;
      this.calculoValorLiquidoTO.pagamentoCartao = value.pagamentoCartao;
      this.calculoValorLiquidoTO.pagamentoDinheiro = value.pagamentoDinheiro;
      this.calculoValorLiquidoTO.pagamentoPix = value.pagamentoPix;
      this.calculoValorLiquidoTO.valorAprovado = value.valorSugerido;
      this.calculoValorLiquidoTO.tipoContrato = value.tipoContrato;
    });

    this.rotasService.listAll()
    .subscribe(data => {
      this.rotas = data.object.content;
    });

    this.keyUpSubject.pipe(debounceTime(500))
    .subscribe(() => {
      this.wrapper();
    });
  }

  valorKeyup() {
    this.keyUpSubject.next();
  }

  wrapper(): void {
    if(this.calculoValorLiquidoTO.quantidadeParcelas != null 
      && this.calculoValorLiquidoTO.valorAprovado != null
      && this.calculoValorLiquidoTO.tipoContrato != null) {
        this.contratoService.calcularValorLiquido(this.calculoValorLiquidoTO).subscribe(resp => {
          if(resp.object) {
            this.parecer.get('limiteCreditoSugerido').setValue(resp.object);
          } else {
            this.parecer.get('limiteCreditoSugerido').setValue('0,00');
          }
        });        
      }
  }

  public onSelectTipoContrato(matSelectChange: MatSelectChange): void {
    this.showDataVencimento = this.CARTAO_KIT_LIST.includes(matSelectChange.value);
    this.showAntecipacao = this.CARTAO_KIT_LIST_ANTECIPACAO.includes(matSelectChange.value);

    const dataVencimento = this.parecer.get('dataVencimento');

    if (this.showDataVencimento) {
      dataVencimento.setValidators([Validators.required]);
    } else {
      dataVencimento.setValidators([]);
    }
    dataVencimento.reset();
    dataVencimento.updateValueAndValidity();

    if (matSelectChange.value === 'BOLETO') {
      this.formaPagamentoSelect(1);
    }
  }

  public onSelectAntecipacao(matSelectChange: MatSelectChange): void {
    // tslint:disable-next-line:triple-equals
    if (matSelectChange.value == 'true') {
      this.showDiasAntecipacao = true;
    } else {
      this.showDiasAntecipacao = false;
    }
  }

  public formaPagamentoSelect(value: number): void {
    // tslint:disable-next-line:triple-equals
    if (value == 1) {
      this.parecer.get('pagamentoBoleto').setValue(true);
      this.parecer.get('pagamentoCartao').setValue(false);
      this.parecer.get('pagamentoDinheiro').setValue(false);
      this.parecer.get('pagamentoPix').setValue(false);

      this.parecer.get('tipoContrato').setValue('BOLETO');
    // tslint:disable-next-line:triple-equals
    } else if (value == 2) {
      this.parecer.get('pagamentoCartao').setValue(true);
      this.parecer.get('pagamentoBoleto').setValue(false);
      this.parecer.get('pagamentoDinheiro').setValue(false);
      this.parecer.get('pagamentoPix').setValue(false);
    } else if (value == 3) {
      this.parecer.get('pagamentoDinheiro').setValue(true);
      this.parecer.get('pagamentoCartao').setValue(false);
      this.parecer.get('pagamentoBoleto').setValue(false);
      this.parecer.get('pagamentoPix').setValue(false);

      this.parecer.get('tipoContrato').setValue('DINHEIRO');
    } else {
      this.parecer.get('pagamentoPix').setValue(true);
      this.parecer.get('pagamentoDinheiro').setValue(false);
      this.parecer.get('pagamentoCartao').setValue(false);
      this.parecer.get('pagamentoBoleto').setValue(false);

      this.parecer.get('tipoContrato').setValue('DINHEIRO');
    }

    //REGRA COMPLEMENTAR PARA PAGAMENTO EM BOLETO (1) OU PIX (4)
    //TODO: POSSIVEL MODIFICAÇÃO NO VALUE DA QUANTIDADE PARA STRING 
    //REF ANTERIOR REALIZAVA ISSO
    const quantidadeRef = this.parecer.get('quantidadeParcelas');
    const periodicidadeRef = this.parecer.get('periodicidadeParcela');
    if (value == 1) {
      //BOLETO
      this.isPixOuDinheiro = false;
      quantidadeRef.setValue(null);
      quantidadeRef.enable();
      periodicidadeRef.setValue(null);
      periodicidadeRef.enable();
    } else if (value == 3 || value == 4) {
      this.isPixOuDinheiro = true;
      periodicidadeRef.setValue('DIARIA');
      periodicidadeRef.disable();
    } else {
      this.isPixOuDinheiro = false;
      quantidadeRef.setValue(null);
      quantidadeRef.enable();
      periodicidadeRef.setValue(null);
      periodicidadeRef.enable();
    }
  }


  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  setProdutoField(isProduto: boolean): void {
    this.parecer.get('isEletro').setValue(isProduto);
  }

  public onSelectModalidade(matSelectChange: MatSelectChange): void {
    this.contratoEmitter.emit(matSelectChange);
  }

  private filterProduto(): void {
    if (!this.produtos) {
      return;
    }

    let search = this.produtoFilterCtrl.value;
    if (!search) {
      this.filteredProduto.next(this.produtos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredProduto.next(
      this.produtos.filter(p => p.nome.toLowerCase().indexOf(search) > -1)
    );

  }

  serviceOnSelect(rota: Rota): any {
    this.currentRota = rota;
    this.visit.get('rota').setValue(this.currentRota);
  }

  doFilter(evento: Event): any {
    const target = evento.target as HTMLTextAreaElement;
    this.rotasService.listAll().subscribe( data => {
      this.rotas = data.object.content;
    });
  }

  displayFn(rota: Rota): string {
    return rota ? rota.descricao : '';
  }

  onSelectConfiguracaoBoleto(event: {quantidade: number, configuracao: string}): void {
    const quantidadeRef = this.parecer.get('quantidadeParcelas');
    quantidadeRef.setValue(event.quantidade);

    const periodicidadeRef = this.parecer.get('periodicidadeParcela');
    switch(event.configuracao) {
      case 'tridiario':
        periodicidadeRef.setValue('TRIDIARIO');
      break;
      case 'semanal':
        periodicidadeRef.setValue('SEMANAL');
      break;
      case 'quinzenal':
        periodicidadeRef.setValue('QUINZENAL');
      break;
      case 'mensal':
        periodicidadeRef.setValue('MENSAL');
      break;
      default:
        console.log('periodicidade not allowed')

    }
  }

}
