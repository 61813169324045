import { Injectable } from '@angular/core';
import {ContractsService} from '../../../services/contracts.service';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveReagendadoService implements Resolve<any>{

  constructor(
      private contractService: ContractsService
  ) { }

  resolve(): Observable<any> {
    return this.contractService.getListContratosReagendados(10, 1).pipe(
        take(1),
        mergeMap( data => {
          return of(data['object']);
        })
    );
  }
}
