import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { NgxPermissionsService } from 'ngx-permissions';
declare var jsPDF: any;


@Component({
  selector: 'app-canceled-detail',
  templateUrl: './canceled-detail.component.html',
  styleUrls: ['./canceled-detail.component.scss'],
  animations: fuseAnimations
})
export class CanceledDetailComponent implements OnInit {

  contrato: Contract;
  parcelas: Parcela[];
  parcelasCalculo: Parcela[]; 
  itensAPI: Parcela[];
  searchInput: FormControl;
  interval;
  filter;
  selected: Array<any>;
  dialogMessages;
  count: number = 0;
  public loading = false;
  valorPago: number = 0;
  valorPagoParcela: number = 0;
  valorAReceber: number = 0;
  selectedClear: boolean;
  valorParcela: number = 0;
  valorExcedente: number = 0;
  valorExcedenteAnterior: number = 0;
  valorJuros: number = 0;
  valorContrato: number = 0;
  showExcedente: boolean = false;
  showJuros: boolean = false;
  showImprimir = true;
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  permissoes = [];
  resumoContrato;
  constructor(private contratoService: ContractsService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private cobrancaService: CobrancasService,
    private contractService: ContractsService,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<Parcela>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void {
    this.listById();
  }

  clearSelectBox(): void {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  listById(): void {
    this.valorPagoParcela = 0;
    this.valorAReceber = 0;
    this.valorParcela = 0;
    this.valorPago = 0;
    this.valorExcedenteAnterior = 0;
    this.valorExcedente = 0;
    this.valorContrato = 0;
    this.valorJuros = 0;
    this.contratoService.findById(this.route.snapshot.params['id']).subscribe(data => {
      this.contrato = data.object;
      if (this.contrato.situacaoContrato == 'CANCELADO' || this.contrato.situacaoContrato == 'NOVO' || this.contrato.situacaoContrato == 'GERADO' || this.contrato.situacaoContrato == 'RENOVADO') {
        this.showImprimir = false;
      }
      this.contrato.valorContrato = 0;
      this.parcelas = data.object.listParcela.sort((a, b) => a.ordem - b.ordem);
      this.parcelasCalculo = this.parcelas.filter(p => p.situacaoParcela !== 'CANCELADA');
      this.itensAPI = data.object.listParcela;
      this.parcelas.filter(p => ['GERADA', 'COBRADA', 'CONFIRMADA'].includes(p.situacaoParcela)).forEach(k => {
        this.valorParcela = this.valorParcela + k.valor;
        this.valorPagoParcela = this.valorPagoParcela + k.valorPago;
        this.valorExcedente = this.valorExcedente + k.valorExcedente;
      });
      this.parcelas.filter(p => p.situacaoParcela === 'LIQUIDADA').forEach(k => {
        this.valorExcedente = this.valorExcedente + k.valorExcedente;
      });
      this.parcelas.forEach(k => {
        this.valorPago = this.valorPago + k.valorPago;
      });
      if (this.contrato.situacaoContrato === 'FINALIZADO' || this.contrato.situacaoContrato === 'FINALIZADO_COM_DIVIDA' 
       || this.contrato.situacaoContrato === 'FINALIZADO_COM_ATRASO' || this.contrato.situacaoContrato === 'FINALIZADO_COM_ANTECIPACAO'
       || this.contrato.situacaoContrato === 'ANTECIPADO_BORDERO') {
        this.showExcedente = true;
      }

      this.contrato.valorContrato = this.contrato.valorLiquido * 100 / (100 - this.contrato.taxaJuros);

      if (this.contrato.situacaoContrato === 'FINALIZADO_COM_ATRASO' || this.contrato.situacaoContrato === 'ANTECIPADO_BORDERO') {
        this.showJuros = true;
      }
      this.valorContrato = this.contrato.valorContrato;
      this.valorJuros = this.valorPago - this.valorContrato;
      this.valorExcedenteAnterior = this.contrato.valorExcedenteAnterior;
      this.valorAReceber = this.valorParcela - this.valorPagoParcela;

      if (this.contrato.situacaoContrato === 'REPACTUADO') {
        this.valorAReceber = 0;
      }

      if (this.fortCartao) {
        this.valorPago = this.valorPago - this.valorExcedenteAnterior + this.valorExcedente;
      }

    });
  }


  over() {
    this.listById();
    this.clearSelectBox();
  }

  onSelect(visit): void {
    this.selected = visit;
  }

  getEmitter(event): void {
    this.parcelas = event;
    this.itensAPI = event;
    this.searchInput.setValue('');
  }

  getSelected(selected: any) {
    this.selected = selected
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  mudarResponsavel(cobranca: any): void {
    let parcelas = []
    this.count = 0;
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'new',
        cobranca: this.contrato
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {

          this.selected.forEach(parcela => {
            parcela.responsavel = data.funcionario;
            this.cobrancaService.alterarResponsavel(parcela).subscribe((data) => {
              if (data.success) {
                this.count++
                if (this.count == this.selected.length) {
                  this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `${data.messages[0].text}`
                  }
                  this.clearSelectBox();
                  this.feedback(this.dialogMessages);
                  this.over();
                }
              } else {
                this.count++
                if (this.count == this.selected.length) {
                  this.dialogMessages = {
                    type: 'info',
                    title: 'Informação!',
                    text: `${data.messages[0].text}`
                  }
                  this.clearSelectBox();
                  this.feedback(this.dialogMessages);
                  this.over();
                }
              }
            })
          });
        }
      });
  }


  previewContract() {
    this.contractService.getContract(this.route.snapshot.params['id']).subscribe(ondata => {
      let objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print()
    });
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  alterarContrato() {
    this._matDialog.open(AlterarContratoFormComponent, {
      data:{ 
        contrato :this.contrato,
        action:"alterContrato"
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.over();
      }
    })
  }



  alterarParcela(value): void {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: { parcela: value },
        caixa: true,
        action: "alter"
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.over()
      }
    });
  }

  receberParcela(value) {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: { parcela: value },
        contrato: true,
        action: "save"

      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.over()
      }
    });
  }

  confirmarParcela(parcela) {
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: 'Deseja confirmar esta parcela?',
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'

    }
    this._matDialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {
        this.cobrancaService.confimarParcela(parcela).subscribe((data) => {
          if (data.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${data.messages[0].text}`
            }
            this.feedback(this.dialogMessages);
            this.over()
          } else {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            }
            this.feedback(this.dialogMessages);
            this.over()
          }
        })
      }
    })
  }

  getOver(event) {
    this.over();
  }

  gerarPDF() {
    let rows = []
    let dataEmissao = new Date()
    let dataLiberacao = new Date(this.contrato.dataLiberacao)
    let dataVencimento = new Date(this.contrato.dataVencimento)
    let doc = new jsPDF('p', 'pt');
    if (this.fortCartao) {
      this.resumoContrato = `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, N°: ${this.contrato.id}\nData de Liberação: ${dataLiberacao.toLocaleDateString('pt-br')}\nValor do Contrato: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor a receber: ${this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nVencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}\nSituação: ${this.contrato.situacaoContrato}\nProduto: ${this.contrato.visita.produto ? this.contrato.visita.produto.nome : 'Nenhum produto adicionado'}`
    } else {
      this.resumoContrato = `Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, N°: ${this.contrato.id}\nData de Liberação: ${dataLiberacao.toLocaleDateString('pt-br')}\nValor do Contrato: ${this.contrato.visita.valorAprovado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nValor a receber: ${this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\nVencimento do Contrato: ${dataVencimento.toLocaleDateString('pt-br')}\nSituação: ${this.contrato.situacaoContrato}\nProduto: ${this.contrato.visita.produto ? this.contrato.visita.produto.nome : 'Nenhum produto adicionado'}\nValor Pago: ${this.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
    }
    doc.setFontSize(11);
    doc.text(12, 20, `Emissão: ${dataEmissao.toLocaleDateString('pt-br')} Hora: ${dataEmissao.toLocaleTimeString('pt-br').slice(0, 5)}`);

    //Keys

    let colunas = [
      { title: "Resumo contrato", dataKey: "resumoContrato" },
    ];

    doc.autoTable(colunas, rows = [{
      resumoContrato: this.resumoContrato,
    }], {
      theme: "plain",
      margin: { horizontal: 7, top: 20 },
      bodyStyles: { valign: 'top' },
    });



    //Keys
    let columns = [
      { title: "Parcela", dataKey: "ordem" },
      { title: "Responsável", dataKey: "responsavel" },
      { title: "Vencimento", dataKey: "dataVencimento" },
      { title: "Valor da Parcela", dataKey: "valor" },
      { title: "Valor Recebido", dataKey: "valorPago" },
    ];


    //Objeto criado para utilização da tablea
    this.parcelas.forEach(element => {
      let date = new Date(element.dataVencimento)
      let data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valor: element.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorPago: element.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        saldoParcela: (element.valor - element.valorPago).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        responsavel: element.responsavel.pessoa.nomePessoa,
        ordem: element.ordem,
        situacaoParcela: element.situacaoParcela
      }
      rows.push(data)
    })

    //Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: "striped",
      margin: { horizontal: 7, top: 40 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
    });


    // //Tabela exibida no pdf
    // doc.autoTable(columns = [
    //   { title: "Valor a Receber", dataKey: "valorAReceber" },
    //   { title: "Total Pago", dataKey: "valorPago" }], rows = [{
    //     valorAReceber: this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    //     valorPago: this.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    //   }], {
    //     theme: "plain",
    //     margin: { horizontal: 7, top: 70 },
    //     bodyStyles: { valign: 'top' },
    //   });


    doc.save('acompanhamento-cobranças.pdf');
  }
}
