import { FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-pix-sidebar',
  templateUrl: './pix-sidebar.component.html',
  styleUrls: ['./pix-sidebar.component.scss']
})
export class PixSidebarComponent implements OnInit, OnChanges {
  filterBy: string;
  all = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean;
  filter: string;
  inputDisabled = false;
  search = '';
  placeholder: string;
  @Input() situacao: string;
  inputPeriodo = false;
  dataInicial = '';
  dataFinal = '';
  period: string;

  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Output() dataPeriodoEvent: EventEmitter<string> = new EventEmitter();
  @Input() searchInput: FormControl;

  constructor(private contractService: ContractsService) {
    this.placeholder = 'Buscar por Cliente';
    this.filter = 'NOME_CLIENTE';
  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    if (this.searchInput == null){
      this.search = '';
    }
  }

  getFilter(filter): void {
    switch (filter) {
      case 'ALL':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit(this.situacao);
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.situacaoEvent.emit(this.situacao);
        break;
      case 'DOCUMENTO_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Documento Cliente';
        this.filterEvent.emit('DOCUMENTO_CLIENTE');
        break;
      case 'NOME_CLIENTE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Cliente';
        this.filterEvent.emit('NOME_CLIENTE');
        break;
      case 'RESPONSAVEL_CONTRATO':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Contrato';
        this.filterEvent.emit('RESPONSAVEL_CONTRATO');
        break;
      case 'RESPONSAVEL_REPASSE':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Responsável Repasse';
        this.filterEvent.emit('RESPONSAVEL_REPASSE');
        break;
      case 'ROTA':
        this.filter = filter;
        this.inputDisabled = false;
        this.placeholder = 'Buscar por Rota';
        this.filterEvent.emit('ROTA');
        break;
      case 'PERIODO':
        this.filter = filter;
        this.inputDisabled = false;
        this.inputPeriodo = true;
        this.search = null;
        this.filterEvent.emit('PERIODO');
        break;  
    }
  }

  onKeyPressFilter(event: any): void {
    this.searchEvent.emit(this.search);
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search === '') {
          this.contractService.page(this.situacao, 10, 1, null, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, 1, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search === '') {
          this.contractService.page(this.situacao, 10, 1, null, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          this.contractService.pageByNomeCliente(this.situacao, this.search, 10, 1, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_CONTRATO':
        if (this.search === '') {
          this.contractService.page(this.situacao, 10, 1, null, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, 1, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        if (this.search === '') {
          this.contractService.page(this.situacao, 10, 1, null, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, 1, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        }
        break;
      case 'ROTA':
        if (this.search === '') {
          this.contractService.page(this.situacao, 10, 1, null, null, null, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        } else {
          this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, 1, true, false).subscribe(data => {
            this.data.emit(data.object);
          });
        }
        break;
    }
  }

  filterPeriodo(): void {
    this.period = this.dataInicial + ',' + this.dataFinal;
    this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataLiberacao', null, null, true, false, this.dataInicial, this.dataFinal)
    .pipe(map(({data, errors}) => data.findAllPageable))
    .subscribe(pageable => { 
      console.log(pageable);
      
      this.dataPeriodoEvent.emit(this.period);
      this.data.emit(pageable);
    });
  }
}
