import {ProcessReleasesComponent} from './../processing/components/process-releases/process-releases-main/process-releases.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CpfCnpjModule} from 'ng2-cpf-cnpj';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {captacaoStoreProviders} from './store/captacao.store';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ChartsModule} from 'ng2-charts';
import {FuseWidgetModule} from '@fuse/components/widget/widget.module';


import {CustomerInformationTableComponent} from './components/customer-information-table/customer-information-table.component';
import {GeradorCobrancaComponent} from './components/gerador-cobranca/gerador-cobranca.component';
import {DatatableHeaderComponent} from './components/datatable-header/datatable-header.component';
import {SelectedDialogComponent} from './dialogs/selected/selected.component';
import {GmapsComponent} from './components/gmaps/gmaps.component';
import {AgmCoreModule} from '@agm/core';
import {CaptacaoFullComponent} from './components/captacao-full/captacao-full.component';
import {CaptacaoFormsEnderecoComponent} from './components/captacao-full/captacao-forms-endereco/captacao-forms-endereco.component';
import {CaptacaoFormsPessoaComponent} from './components/captacao-full/captacao-forms-pessoa/captacao-forms-pessoa.component';
import {CaptacaoResumeComponent} from './components/captacao-full/captacao-resume/captacao-resume.component';
import {VisitadosFullComponent} from './components/visitados-full/visitados-full.component';
import {PersonalReferenceComponent} from './components/visitados-full/personal-reference/personal-reference.component';
import {ComercialReferenceComponent} from './components/visitados-full/comercial-reference/comercial-reference.component';
import {BankReferenceComponent} from './components/visitados-full/bank-reference/bank-reference.component';
import {FiadorComponent} from './components/visitados-full/fiador/fiador.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {VisitaParecerFormComponent} from './components/visitas-full/visita-parecer-form/visita-parecer-form.component';
import {VisitasFullComponent} from './components/visitas-full/visitas-full.component';
import {ImageGalleryComponent} from './components/visitas-full/image-gallery/image-gallery.component';
import {AddressFormComponent} from './components/address-form/address-form.component';
import {CreditosResumeComponent} from './components/creditos-resume/creditos-resume.component';
import {CreditosHistoricoComponent} from './components/creditos-historico/creditos-historico.component';
import {CreditosAprovacaoComponent} from './components/creditos-aprovacao/creditos-aprovacao.component';
import {SharedMaterialComponentsModule} from '../../shared-material-components.module';
import {CaptacaoFormsTelephoneComponent} from './components/captacao-full/captacao-forms-telephone/captacao-forms-telephone.component';
import {VisitasService} from '../customers/components/visitas/services/visitas.service';
import {ConfirmationContractComponent} from './dialogs/confirmation-contract/confirmation-contract.component';
import {CancelContractComponent} from './dialogs/cancel-contract/cancel-contract.component';
import {LiquidarContratoComponent} from './dialogs/liquidar-contrato/liquidar-contrato.component';
import {AgendarCaptacaoComponent} from './dialogs/agendar-captacao/agendar-captacao.component';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GalleryModule} from '@ngx-gallery/core';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {NgxGalleryModule} from 'ngx-gallery';
import {ActuationAreaFormComponent} from './components/actuation-area-form/actuation-area-form.component';
import {ComercialActivityFormComponent} from './components/comercial-activity-form/comercial-activity-form.component';
import {UserFormComponent} from './components/user-form/user-form.component';
import {TeamFormComponent} from './components/team-form/team-form.component';
import {UserProfileFormComponent} from './components/user-profile-form/user-profile-form.component';
import {VisitGalleryComponent} from './components/visit-gallery/visit-gallery.component';
import {ReagendamentoVisitaComponent} from './dialogs/reagendamento-visita/reagendamento-visita.component';
import {PhoneFieldComponent} from './components/phone-field/phone-field.component';
import {ConfirmationChangesComponent} from './dialogs/confirmation-changes/confirmation-changes.component';
import {ConfirmationReleaseComponent} from './dialogs/confirmation-release/confirmation-release.component';
import {AtendimentoDetalheComponent} from './components/atendimento-detalhe/atendimento-detalhe.component';
import {RevisaoAprovacaoComponent} from './components/revisao-aprovacao/revisao-aprovacao.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatInputModule} from '@angular/material';
import {EmployeePersonalFormComponent} from './components/employee-full/employee-pessoal-form/employee-pessoal-form.component';
import {EmployeeFullComponent} from './components/employee-full/employee-full.component';
import {EmployeeFolhaPagamentoFormComponent} from './components/employee-full/employee-folha-pagamento-form/employee-folha-pagamento-form.component';
import {EmployeeVeiculoFormComponent} from './components/employee-full/employee-veiculo-form/employee-veiculo-form.component';
import {EmployeeEnderecoFormComponent} from './components/employee-full/employee-endereco-form/employee-endereco-form.component';
import {HolidayFormComponent} from './components/holiday-form/holiday-form.component';
import {RoleFormComponent} from './components/role-form/role-form.component';
import {AttendanceFormComponent} from './components/attendance-form/attendance-form.component';
import {BenefitsFormComponent} from './components/benefits-form/benefits-form.component';
import {ImpostFormComponent} from './components/impost-form/impost-form.component';
import {DiscountFormComponent} from './components/discount-form/discount-form.component';
import {CommissionFormComponent} from './components/commission-form/commission-form.component';
import {VehiclesFormComponent} from './components/vehicles-form/vehicles-form.component';
import {ConfirmarRejeicaoComponent} from './dialogs/confirmar-rejeicao/confirmar-rejeicao.component';
import {ClienteFullComponent} from './components/cliente-full/cliente-full.component';
import {DadosPessoaisComponent} from './components/visitados-full/dados-pessoais/dados-pessoais.component';
import {DeleteConfirmationComponent} from './dialogs/delete-confirmation/delete-confirmation.component';
import {DadosClienteComponent} from './components/cliente-full/dados-cliente/dados-cliente.component';
import {ClienteFormEnderecoComponent} from './components/cliente-full/cliente-form-endereco/cliente-form-endereco.component';
import {TransferenciaFormComponent} from './components/transferencia-form/transferencia-form.component';
import {TransferenciaService} from 'app/main/financial/services/transferencia/transferencia.service';
import {ConfirmarTransferenciaComponent} from './dialogs/confirmar-transferencia/confirmar-transferencia.component';
import {TransferenciaListComponent} from './components/transferencia-list/transferencia-list.component';
import {ConfirmarRecebimentoComponent} from './dialogs/confirmar-recebimento/confirmar-recebimento.component';
import {CaixaFormComponent} from './components/caixa-form/caixa-form.component';
import {EmployeeFullPerfilComponent} from './components/employee-full/employee-full-perfil/employee-full-perfil.component';
import {AgendarVisitaComponent} from './components/agendar-visita/agendar-visita.component';
import {FolhaPagamentoFormComponent} from './components/folha-pagamento-form/folha-pagamento-form.component';
import {ClienteFullReferenceComponent} from './components/cliente-full/cliente-full-reference/cliente-full-reference.component';
import {ClienteFullBackReferenceComponent} from './components/cliente-full/cliente-full-back-reference/cliente-full-back-reference.component';
import {ClienteFullComercialReferenceComponent} from './components/cliente-full/cliente-full-comercial-reference/cliente-full-comercial-reference.component';
import {ClienteFullContasBancariasComponent} from './components/cliente-full/cliente-full-contas-bancarias/cliente-full-contas-bancarias.component';
import {ClienteFullFiadorComponent} from './components/cliente-full/cliente-full-fiador/cliente-full-fiador.component';
import {ClienteListContratosComponent} from './components/cliente-full/cliente-list-contratos/cliente-list-contratos.component';
import {ReceberParcelaFormComponent} from './components/receber-parcela-form/receber-parcela-form.component';
import {DistrictFormComponent} from './components/district-form/district-form.component';
import {MudarResponsavelFormComponent} from './components/mudar-responsavel-form/mudar-responsavel-form.component';
import {LoadProgressComponent} from './dialogs/load-progress/load-progress.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from './model/date/MY_FORMATS.model';
import {BancoComponent} from './components/banco/banco.component';
import {AgenciaFormComponent} from './components/agencia-form/agencia-form.component';
import {EmpresaFormComponent} from './components/empresa-form/empresa-form.component';
import {ContaFormComponent} from './components/conta-form/conta-form.component';
import {EmpresaDadosFormComponent} from './components/empresa-form/empresa-dados-form/empresa-dados-form.component';
import {EmpresaEnderecoFormComponent} from './components/empresa-form/empresa-endereco-form/empresa-endereco-form.component';
import {MaquinaCartaoFormComponent} from './components/maquina-cartao-form/maquina-cartao-form.component';
import {NgMaskModule} from '@fagnerlima/ng-mask';
import {NgxPhoneMaskBrModule} from 'ngx-phone-mask-br';
import {DeclaracaoCaixaFormComponent} from './components/declaracao-caixa-form/declaracao-caixa-form.component';
import {DespesasFormComponent} from './components/despesas-form/despesas-form.component';
import {SobrasFormComponent} from './components/sobras-form/sobras-form.component';
import {AlterarValorFormComponent} from './components/alterar-valor-form/alterar-valor-form.component';
import {FilterBairroComponent} from './components/filter-bairro/filter-bairro.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatListModule} from '@angular/material/list';
import {FilterDateComponent} from './components/filter-date/filter-date.component';
import {AlterarContratoFormComponent} from './components/alterar-contrato-form/alterar-contrato-form.component';
import { FolhaCobrancaTransferenciaEditComponent } from '../financial/components/folha-cobranca/folha-cobranca-transferencia-edit/folha-cobranca-transferencia-edit.component';
import {ImageCompressService, ResizeOptions} from 'ng2-image-compress';
import {ReferenciaPessoalFormComponent} from './components/referencia-pessoal-form/referencia-pessoal-form.component';
import {ProdutoFormComponent} from './components/produto-form/produto-form.component';
import {ModeloFormComponent} from './components/modelo-form/modelo-form.component';
import {MarcaFormComponent} from './components/marca-form/marca-form.component';
import {RelatorioPeriodoComponent} from './components/relatorio-periodo/relatorio-periodo.component';
import {RelatorioClienteComponent} from './components/relatorio-cliente/relatorio-cliente.component';
import {UserDeactivateComponent} from './dialogs/user-deactivate/user-deactivate.component';
import {ParcelaListComponent} from './components/parcela-list/parcela-list.component';
import {SelectMaquinaComponent} from './components/select-maquina/select-maquina.component';
import {AlterarResponsavelContratosComponent} from './components/alterar-responsavel-contratos/alterar-responsavel-contratos.component';
import {AlterarSenhaComponent} from './components/alterar-senha/alterar-senha.component';
import {AgendarParcelaComponent} from './components/agendar-parcela/agendar-parcela.component';
import {DesvincularContratoComponent} from './dialogs/desvincular-contrato/desvincular-contrato.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {RelatorioContratoCobrancasComponent} from './components/relatorio-contrato-cobrancas/relatorio-contrato-cobrancas.component';
import {VisitadosEnderecoFormComponent} from './components/visitados-full/visitados-endereco-form/visitados-endereco-form.component';
import {ContratoLancamentoFormComponent} from './components/contrato-lancamento-form/contrato-lancamento-form.component';
import {CalcularJurosComponent} from './components/calcular-juros-form/calcular-juros.component';
import {MudarSituacaoContratoComponent} from './dialogs/mudar-situacao-contrato/mudar-situacao-contrato.component';
import {BorderoFormComponent} from './components/bordero-form/bordero-form.component';
import {BorderoConfirmComponent} from './dialogs/bordero-confirm/bordero-confirm.component';
import {EditMaquinaComponent} from './components/edit-maquina/edit-maquina.component';
import {DashboardPendenciasComponent} from './components/dashboard-pendencias/dashboard-pendencias.component';
import {ContratoRetroativoFormComponent} from './components/contrato-retroativo-form/contrato-retroativo-form.component';
import {RenegociacaoComponent} from './components/renegociacao-form/renegociacao-form.component';
import {RenegociacaoDividaFormComponent} from './components/renegociacao-divida-form/renegociacao-divida-form.component';
import {RepactuacaoDividaFormComponent} from './components/repactuacao-divida-form/repactuacao-divida-form.component';
import {PeriodReportComponent} from '../report/components/period-report/period-report-main/period-report.component';
import {LancamentosComponent} from './components/lancamentos/lancamentos.component';
import {FormSocioComponent} from './components/form-socio/form-socio.component';
import {ContaBancariaFormComponent} from './components/conta-bancaria-form/conta-bancaria-form.component';
import {FormaPagamentoSelecaoComponent} from './components/forma-pagamento-selecao/forma-pagamento-selecao.component';
import {ClienteSociosComponent} from './components/cliente-full/cliente-socios/cliente-socios.component';
import {SociosComponent} from './components/visitados-full/socios/socios.component';
import {ParameterFormComponent} from '../parameter/components/parameter-form/parameter-form-main/parameter-form.component';
import {ClientePlanejamentoFormComponent} from './components/cliente-planejamento-form/cliente-planejamento-form.component';
import {ConfirmarCaptacaoComponent} from './dialogs/confirmar-captacao/confirmar-captacao.component';
import {ClienteDevedorComponent} from './components/cliente-spc/cliente-spc.component';
import {ClienteRemoveSpcComponent} from './components/cliente-spc/cliente-remove-from-spc/cliente-remove-from-spc.component';
import {TaxaFormComponent} from '../taxa/components/taxa-form/taxa-form.main/taxa-form.component';
import {ConfirmarRepasseComponent} from './components/confirmar-repasse/confirmar-repasse.component';
import {ComplementoFormComponent} from './components/complemento-form/complemento-form.component';
import {AlterarParcelaConfirmadaPendenciaFormComponent} from './components/alterar-parcela-confirmada-pendencia-form/alterar-parcela-confirmada-pendencia-form.component';
import {CobrancasService} from '../financial/components/cobrancas/services/cobrancas.services';
import {PostoFullComponent} from './components/posto-full/posto-full.component';
import {PostoFormComponent} from './components/posto-full/posto-form-main/posto-form-main.component';
import {PostoFormEnderecoComponent} from './components/posto-full/posto-form-endereco/posto-form-endereco.component';
import {RotaFormComponent} from './components/rota-form/rota-form.component';
import {DeleteSharedComponent} from './dialogs/delete-shared/delete-shared/delete-shared.component';
import {CaptacaoFormsIndicadorComponent} from './components/captacao-full/captacao-forms-indicador/captacao-forms-indicador.component';
import {SelectPostoFormComponent} from './components/select-posto-form/select-posto-form.component';
import {AnalisarEfetivacaoComponent} from './dialogs/analisar-efetivacao/analisar-efetivacao.component';
import {LancamentoAgendaFormComponent} from './components/lancamento-agenda-form/lancamento-agenda-form.component';
import {AcompanhamentoLancamentoAgendaComponent} from './dialogs/acompanhamento-lancamento-agenda/acompanhamento-lancamento-agenda.component';
import {CaixaControleEditComponent} from './components/caixa-controle-edit/caixa-controle-edit.component';
import {UppercaseDirective} from 'app/shared/diretivas/uppercase.directive';
import {UtilsService} from 'app/shared/util/utils.service';
import {ContratoHistoricoComponent} from './dialogs/contrato-historico/contrato-historico.component';
import {AnalisarSobraFormComponent} from './components/analisar-sobra-form/analisar-sobra-form.component';
import {AuditoriaFormComponent} from './components/auditoria-form/auditoria-form.component';
import {NgxLoadingModule} from 'ngx-loading';
import {ConfirmarComponent} from './dialogs/confirmar/confirmar.component';
import {LancarFaltaComponent} from './components/lancar-falta/lancar-falta.component';
import {QuitarFaltaComponent} from './components/quitar-falta/quitar-falta.component';
import {PostoFormParametrosComponent} from './components/posto-full/posto-form-parametros/posto-form-parametros.component';
import {ValeFormComponent} from './components/vale-form/vale-form.component';
import {ConfirmarTipoRepasseComponent} from './components/confirmar-repasse-contrato/confirmar-tipo-repasse.component';
import {ClienteSelectFormComponent} from './components/cliente-select-form/cliente-select-form.component';
import {ContratoGalleryComponent} from './components/contrato-gallery/contrato-gallery.component';
import {DailyCaixaReportComponent} from '../report/components/daily-caixa-report/daily-caixa-report-main/daily-caixa-report.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {ListCobrancaComponent} from './components/lista-cobranca/lista-cobranca.component';
import {DataUnicaFormComponent} from './components/data-unica-form/data-unica-form.component';
import {ReativarClienteComponent} from './dialogs/reativar-cliente/reativar-cliente.component';
import { ParcelasComponent } from './dialogs/parcelas/parcelas.component';
import { ConsultarCpfCnpjReceitaComponent } from './dialogs/consultar-cpf-cnpj-receita/consultar-cpf-cnpj-receita.component';
import { PDFViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import {
  RelatorioAcompanhamentoVisitaMainComponent
} from '../report/components/relatorio-acompanhamento-visita/relatorio-acompanhamento-visita-main/relatorio-acompanhamento-visita-main.component';
import { OdinService } from 'app/shared/util/odin.service';
import { PessoaPoliticaComponent } from './components/visitados-full/pessoa-politica/pessoa-politica.component';
import { ConferenciaCaixaGeralFormComponent } from './components/conferencia-caixa-geral-form/conferencia-caixa-geral-form.component';
import { MotivoDesistenciaContratoService } from '../financial/services/motivo-desistencia-contrato/motivo-desistencia-contrato.service';
import { EmployeeDeactivateComponent } from './dialogs/employee-deactivate/employee-deactivate.component';
import { ReagendadosNaoOperadosDialogComponent } from './dialogs/reagendados-nao-operados-dialog/reagendados-nao-operados-dialog.component';
import { AssinaturaDigitalFormComponent } from './components/assinatura-digital-form/assinatura-digital-form.component';
import { RepassePixFormComponent } from './components/repasse-pix-form/repasse-pix-form.component';
import { ContratoLiberadoDialogComponent } from './dialogs/contrato-liberado-dialog/contrato-liberado-dialog.component';
import { IndicadorSelectFormComponent } from './components/indicador-select-form/indicador-select-form.component';
import { ReferenciasPixFormComponent } from './components/referencias-pix-form/referencias-pix-form.component';
import { ReferenciaPixFormConfirmacaoComponent } from './components/referencia-pix-form-confirmacao/referencia-pix-form-confirmacao.component';
import { RepasseBoletoFormComponent } from './components/repasse-boleto-form/repasse-boleto-form.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { InativarRenovacaoAutomaticaComponent } from './components/inativar-renovacao-automatica/inativar-renovacao-automatica.component';
import { ReativarRenovacaoAutomaticaComponent } from './components/inativar-renovacao-automatica/reativar-renovacao-automatica/reativar-renovacao-automatica.component';
import { UploadDocumentoComponent } from './components/upload-documento/upload-documento.component';
import { UploadComprovanteParcelaComponent } from './dialogs/upload-comprovante-parcela/upload-comprovante-parcela.component';
import { ReagendarContratoFormComponent } from './components/reagendar-contrato-form/reagendar-contrato-form.component';
import { CaptacaoFormsImagensComponent } from './components/captacao-full/captacao-forms-imagens/captacao-forms-imagens.component';
import { FolhaCobrancaTransferenciaListComponent } from '../financial/components/folha-cobranca/folha-cobranca-transferencia-list/folha-cobranca-transferencia-list.component';


@NgModule({
  imports: [
    AgmCoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgxDatatableModule,
    FlexLayoutModule,
    SharedMaterialComponentsModule,
    NgxGalleryModule,
    GalleryModule.forRoot(),
    LightboxModule.forRoot(),
    GallerizeModule,
    ChartsModule,
    FuseSharedModule,
    FuseWidgetModule,
    CpfCnpjModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    NgMaskModule,
    NgxPhoneMaskBrModule,
    MatChipsModule,
    MatListModule,
    MatInputModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot(),
    PdfJsViewerModule
  ],
  declarations: [
    AddressFormComponent,
    CustomerInformationTableComponent,
    GeradorCobrancaComponent,
    DatatableHeaderComponent,
    SelectedDialogComponent,
    GmapsComponent,
    CaptacaoFullComponent,
    CaptacaoFormsEnderecoComponent,
    CaptacaoFormsPessoaComponent,
    CaptacaoResumeComponent,
    CaptacaoFormsTelephoneComponent,
    VisitadosFullComponent,
    PersonalReferenceComponent,
    ComercialReferenceComponent,
    BankReferenceComponent,
    FiadorComponent,
    VisitaParecerFormComponent,
    VisitasFullComponent,
    ImageGalleryComponent,
    CreditosResumeComponent,
    CreditosHistoricoComponent,
    CreditosAprovacaoComponent,
    RevisaoAprovacaoComponent,
    ConfirmationContractComponent,
    CancelContractComponent,
    LiquidarContratoComponent,
    MessageDialogComponent,
    AgendarCaptacaoComponent,
    ActuationAreaFormComponent,
    ComercialActivityFormComponent,
    UserFormComponent,
    TeamFormComponent,
    UserProfileFormComponent,
    VisitGalleryComponent,
    ReagendamentoVisitaComponent,
    PhoneFieldComponent,
    PDFViewerComponent,
    ConfirmationChangesComponent,
    ConfirmationReleaseComponent,
    AtendimentoDetalheComponent,
    EmployeePersonalFormComponent,
    EmployeeFullComponent,
    EmployeeFolhaPagamentoFormComponent,
    EmployeeVeiculoFormComponent,
    EmployeeEnderecoFormComponent,
    HolidayFormComponent,
    RoleFormComponent,
    AttendanceFormComponent,
    BenefitsFormComponent,
    ImpostFormComponent,
    DiscountFormComponent,
    CommissionFormComponent,
    VehiclesFormComponent,
    ConfirmarRejeicaoComponent,
    ClienteFullComponent,
    ClienteDevedorComponent,
    ClienteRemoveSpcComponent,
    DadosPessoaisComponent,
    DeleteConfirmationComponent,
    DadosClienteComponent,
    ClienteFormEnderecoComponent,
    TransferenciaFormComponent,
    ConfirmarTransferenciaComponent,
    TransferenciaListComponent,
    ConfirmarRecebimentoComponent,
    CaixaFormComponent,
    EmployeeFullPerfilComponent,
    AgendarVisitaComponent,
    ComercialReferenceComponent,
    FolhaPagamentoFormComponent,
    ClienteFullReferenceComponent,
    ClienteFullBackReferenceComponent,
    ClienteFullComercialReferenceComponent,
    ClienteFullFiadorComponent,
    ClienteFullContasBancariasComponent,
    ContaBancariaFormComponent,
    FormaPagamentoSelecaoComponent,
    ClienteListContratosComponent,
    ReceberParcelaFormComponent,
    DistrictFormComponent,
    MudarResponsavelFormComponent,
    LoadProgressComponent,
    BancoComponent,
    AgenciaFormComponent,
    EmpresaFormComponent,
    ContaFormComponent,
    EmpresaDadosFormComponent,
    EmpresaEnderecoFormComponent,
    MaquinaCartaoFormComponent,
    DeclaracaoCaixaFormComponent,
    DespesasFormComponent,
    SobrasFormComponent,
    ComplementoFormComponent,
    AlterarValorFormComponent,
    FilterBairroComponent,
    FilterDateComponent,
    AlterarContratoFormComponent,
    FolhaCobrancaTransferenciaEditComponent,
    ReferenciaPessoalFormComponent,
    ProdutoFormComponent,
    ModeloFormComponent,
    MarcaFormComponent,
    RelatorioPeriodoComponent,
    RelatorioClienteComponent,
    UserDeactivateComponent,
    ParcelaListComponent,
    FolhaCobrancaTransferenciaListComponent,
    SelectMaquinaComponent,
    AlterarResponsavelContratosComponent,
    AlterarSenhaComponent,
    AgendarParcelaComponent,
    DesvincularContratoComponent,
    ProcessReleasesComponent,
    RelatorioContratoCobrancasComponent,
    VisitadosEnderecoFormComponent,
    ContratoLancamentoFormComponent,
    CalcularJurosComponent,
    MudarSituacaoContratoComponent,
    BorderoFormComponent,
    BorderoConfirmComponent,
    EditMaquinaComponent,
    DashboardPendenciasComponent,
    ContratoRetroativoFormComponent,
    RenegociacaoComponent,
    RenegociacaoDividaFormComponent,
    RepactuacaoDividaFormComponent,
    PeriodReportComponent,
    RelatorioAcompanhamentoVisitaMainComponent,
    LancamentosComponent,
    FormSocioComponent,
    ClienteSociosComponent,
    SociosComponent,
    ParameterFormComponent,
    ClientePlanejamentoFormComponent,
    ConfirmarCaptacaoComponent,
    TaxaFormComponent,
    ConfirmarRepasseComponent,
    AlterarParcelaConfirmadaPendenciaFormComponent,
    PostoFullComponent,
    PostoFormComponent,
    PostoFormEnderecoComponent,
    RotaFormComponent,
    DeleteSharedComponent,
    CaptacaoFormsIndicadorComponent,
    SelectPostoFormComponent,
    AnalisarEfetivacaoComponent,
    LancamentoAgendaFormComponent,
    AcompanhamentoLancamentoAgendaComponent,
    CaixaControleEditComponent,
    UppercaseDirective,
    ContratoHistoricoComponent,
    AnalisarSobraFormComponent,
    AuditoriaFormComponent,
    ConfirmarComponent,
    LancarFaltaComponent,
    QuitarFaltaComponent,
    PostoFormParametrosComponent,
    ValeFormComponent,
    ConfirmarTipoRepasseComponent,
    ClienteSelectFormComponent,
    ContratoGalleryComponent,
    DailyCaixaReportComponent,
    ListCobrancaComponent,
    DataUnicaFormComponent,
    ReativarClienteComponent,
    ParcelasComponent,
    ParcelasComponent,
    ConsultarCpfCnpjReceitaComponent,
    PessoaPoliticaComponent,
    ConferenciaCaixaGeralFormComponent,
    EmployeeDeactivateComponent,
    ReagendadosNaoOperadosDialogComponent,
    AssinaturaDigitalFormComponent,
    RepassePixFormComponent,
    ContratoLiberadoDialogComponent,
    IndicadorSelectFormComponent,
    ReferenciasPixFormComponent,
    ReferenciaPixFormConfirmacaoComponent,
    RepasseBoletoFormComponent,
    VideoPlayerComponent,
    InativarRenovacaoAutomaticaComponent,
    ReativarRenovacaoAutomaticaComponent,
    UploadDocumentoComponent,
    UploadComprovanteParcelaComponent,
    ReagendarContratoFormComponent,
    CaptacaoFormsImagensComponent,
  ],
  entryComponents: [
    SelectedDialogComponent,
    CaptacaoFormsEnderecoComponent,
    CaptacaoFormsPessoaComponent,
    VisitaParecerFormComponent,
    CaptacaoResumeComponent,
    CaptacaoFullComponent,
    VisitadosFullComponent,
    VisitasFullComponent,
    ConfirmarRejeicaoComponent,
    ConfirmationContractComponent,
    PDFViewerComponent,
    ConfirmationChangesComponent,
    ConfirmationReleaseComponent,
    CancelContractComponent,
    LiquidarContratoComponent,
    MessageDialogComponent,
    AgendarCaptacaoComponent,
    ActuationAreaFormComponent,
    ComercialActivityFormComponent,
    UserFormComponent,
    TeamFormComponent,
    UserProfileFormComponent,
    ReagendamentoVisitaComponent,
    AtendimentoDetalheComponent,
    BenefitsFormComponent,
    ImpostFormComponent,
    DiscountFormComponent,
    CommissionFormComponent,
    VehiclesFormComponent,
    DeleteConfirmationComponent,
    DadosPessoaisComponent,
    TransferenciaFormComponent,
    ConfirmarTransferenciaComponent,
    ConfirmarRecebimentoComponent,
    CaixaFormComponent,
    AgendarVisitaComponent,
    ClienteFullReferenceComponent,
    FolhaPagamentoFormComponent,
    ClienteFullBackReferenceComponent,
    ClienteFullComercialReferenceComponent,
    ClienteFullContasBancariasComponent,
    ContaBancariaFormComponent,
    FormaPagamentoSelecaoComponent,
    ClienteListContratosComponent,
    ClienteRemoveSpcComponent,
    ReceberParcelaFormComponent,
    DistrictFormComponent,
    MudarResponsavelFormComponent,
    LoadProgressComponent,
    BancoComponent,
    AgenciaFormComponent,
    EmpresaFormComponent,
    ContaFormComponent,
    EmpresaDadosFormComponent,
    EmpresaEnderecoFormComponent,
    MaquinaCartaoFormComponent,
    EmployeeFullComponent,
    EmployeeFolhaPagamentoFormComponent,
    EmployeeVeiculoFormComponent,
    EmployeeEnderecoFormComponent,
    DeclaracaoCaixaFormComponent,
    DespesasFormComponent,
    SobrasFormComponent,
    ComplementoFormComponent,
    AlterarValorFormComponent,
    FilterBairroComponent,
    FilterDateComponent,
    AlterarContratoFormComponent,
    FolhaCobrancaTransferenciaEditComponent,
    ReferenciaPessoalFormComponent,
    ProdutoFormComponent,
    ModeloFormComponent,
    MarcaFormComponent,
    RelatorioClienteComponent,
    RelatorioPeriodoComponent,
    UserDeactivateComponent,
    SelectMaquinaComponent,
    GeradorCobrancaComponent,
    AlterarResponsavelContratosComponent,
    AlterarSenhaComponent,
    AgendarParcelaComponent,
    DesvincularContratoComponent,
    ProcessReleasesComponent,
    RelatorioContratoCobrancasComponent,
    ContratoLancamentoFormComponent,
    CalcularJurosComponent,
    MudarSituacaoContratoComponent,
    BorderoFormComponent,
    BorderoConfirmComponent,
    EditMaquinaComponent,
    DashboardPendenciasComponent,
    ContratoRetroativoFormComponent,
    RenegociacaoComponent,
    RenegociacaoDividaFormComponent,
    RepactuacaoDividaFormComponent,
    PeriodReportComponent,
    RelatorioAcompanhamentoVisitaMainComponent,
    ParameterFormComponent,
    ClientePlanejamentoFormComponent,
    ConfirmarCaptacaoComponent,
    TaxaFormComponent,
    ConfirmarRepasseComponent,
    AlterarParcelaConfirmadaPendenciaFormComponent,
    PostoFullComponent,
    PostoFormComponent,
    PostoFormEnderecoComponent,
    RotaFormComponent,
    DeleteSharedComponent,
    CaptacaoFormsIndicadorComponent,
    SelectPostoFormComponent,
    AnalisarEfetivacaoComponent,
    LancamentoAgendaFormComponent,
    AcompanhamentoLancamentoAgendaComponent,
    CaixaControleEditComponent,
    ContratoHistoricoComponent,
    AnalisarSobraFormComponent,
    AuditoriaFormComponent,
    ConfirmarComponent,
    LancarFaltaComponent,
    QuitarFaltaComponent,
    ValeFormComponent,
    ConfirmarTipoRepasseComponent,
    ClienteSelectFormComponent,
    ContratoGalleryComponent,
    DailyCaixaReportComponent,
    ListCobrancaComponent,
    DataUnicaFormComponent,
    ReativarClienteComponent,
    ParcelasComponent,
    ConsultarCpfCnpjReceitaComponent,
    ParcelasComponent,
    PessoaPoliticaComponent,
    ConferenciaCaixaGeralFormComponent,
    EmployeeDeactivateComponent,
    ReagendadosNaoOperadosDialogComponent,
    AssinaturaDigitalFormComponent,
    RepassePixFormComponent,
    ContratoLiberadoDialogComponent,
    IndicadorSelectFormComponent,
    ReferenciaPixFormConfirmacaoComponent,
    RepasseBoletoFormComponent,
    VideoPlayerComponent,
    InativarRenovacaoAutomaticaComponent,
    ReativarRenovacaoAutomaticaComponent,
    UploadDocumentoComponent,
    UploadComprovanteParcelaComponent,
    ReagendarContratoFormComponent,
    CaptacaoFormsImagensComponent,
  ],
  exports: [
    AddressFormComponent,
    GmapsComponent,
    CaptacaoFullComponent,
    SelectedDialogComponent,
    CreditosResumeComponent,
    CreditosHistoricoComponent,
    CreditosAprovacaoComponent,
    RevisaoAprovacaoComponent,
    ConfirmationContractComponent,
    CancelContractComponent,
    LiquidarContratoComponent,
    BenefitsFormComponent,
    ImpostFormComponent,
    DiscountFormComponent,
    CommissionFormComponent,
    VehiclesFormComponent,
    TransferenciaListComponent,
    ParcelaListComponent,
    FolhaCobrancaTransferenciaListComponent,
    LoadProgressComponent,
    DashboardPendenciasComponent,
    LancamentosComponent,
    AlterarParcelaConfirmadaPendenciaFormComponent,
    PostoFullComponent,
    DeleteSharedComponent,
    CaptacaoFormsIndicadorComponent,
    ContratoHistoricoComponent,
    AnalisarSobraFormComponent,
    ConfirmarComponent,
    ValeFormComponent,
    ClienteSelectFormComponent,
    ContratoGalleryComponent,
    DataUnicaFormComponent,
    ParcelasComponent,
    PessoaPoliticaComponent,
    ConferenciaCaixaGeralFormComponent,
    ReagendadosNaoOperadosDialogComponent,
    IndicadorSelectFormComponent,
    ReferenciasPixFormComponent,
    VideoPlayerComponent,
    UploadDocumentoComponent
  ],
  providers: [captacaoStoreProviders, VisitasService, TransferenciaService, CobrancasService, UtilsService, OdinService, MotivoDesistenciaContratoService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    ImageCompressService, ResizeOptions],

})
export class SharedModule { }
