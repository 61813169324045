import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';


@Injectable({
  providedIn: 'root'
})
export class ResolveGeneratedService implements Resolve<any> {

  constructor(
    private contractService: ContractsService) { }

  resolve(): Observable<any> {
    const data = new Date();
    return this.contractService.pageContratosGraphQL('NOVO,RENOVADO,GERADO', 10, 1, 'responsavelRepasse.rota.descricao', data.getTime(), data.getTime()).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['data']['findAllPageable']);
      })
    );
  }

}
