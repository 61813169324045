import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatDialog } from '@angular/material';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { Parcela } from '../../model/parcela/parcela.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ContractsService } from 'app/main/contracts/services/contracts.service';

@Component({
  selector: 'app-calcular-juros',
  templateUrl: './calcular-juros.component.html',
  styleUrls: ['./calcular-juros.component.scss']
})
export class CalcularJurosComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  parcelas: Parcela[];
  dialogMessages;

  public showValor = false;

  public ParcelaControl: FormControl = new FormControl();
  public filteredParcelas: ReplaySubject<Parcela[]> = new ReplaySubject<Parcela[]>(1);

  @ViewChild('data') data: ElementRef;

  constructor(private cobrancaService: CobrancasService,
    private contratoService: ContractsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CalcularJurosComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog) {
  }


  private _onDestroy = new Subject<void>();

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      antecipacao: [null],
      valorPago: [null]
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.data.nativeElement.focus(), 1000);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onSave(value: any): void {
    value.parcela.dataAgendamento = value.antecipacao;

    if (value.valorPago && value.valorPago !== '') {
      value.parcela.valorPago = parseFloat(value.valorPago.replace(/\,/g, '.'));
    }

    this.cobrancaService.agendarPagamento(value.parcela).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          };

        this.feedback(this.dialogMessages);
        this.dialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  public calcular(value: any): void {
    this.contratoService.calcularValorJuros(this._data.contrato.id, value.antecipacao).subscribe(data => {
      if (data.success) {
        this.showValor = true;

        this.form = this.formBuilder.group({
          antecipacao: [value.antecipacao],
          valorPago: [data.object]
        });

        const valorPago =  this.form.get('valorPago');

        valorPago.updateValueAndValidity();
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.dialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    })    
  }
}
