import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatOption } from '@angular/material';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
  FormArray
} from '@angular/forms';

import * as _ from 'lodash';
import { Activity, Bairro } from '../../model/activity/activity.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ActivityFieldService } from 'app/main/global/components/activity-field/services/activity-field.service';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Estado } from '../../model/estado/estado.model';
import { Municipality } from '../../model/municipality/municipality.model';
import { GlobalService } from 'app/main/global/services/global.service';
import { Posto } from '../../model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

export interface DialogData {
  activity: any,
  action: string
}

@Component({
  selector: 'app-actuation-area-form',
  templateUrl: './actuation-area-form.component.html',
  styleUrls: ['./actuation-area-form.component.scss']
})
export class ActuationAreaFormComponent implements OnInit {
  areaDeAtuacao: FormGroup;
  titleHeader: string;
  isActivate = true;
  activeCheck: string;
  dialogMessages;
  filteredOptions: any;
  listBairro: any[] = [];
  listBairroDescriptions = [];
  municipios: Array<Municipality>;
  bairro: string;
  estados: Estado;
  municipality: Array<Municipality>;
  ufs: Array<any>;
  uf: Estado;
  selectedMunicipio: any;
  disabledButton: boolean;
  posto: Posto;
  postos: Array<Posto>;
  postoList: any[] = [];

  bairros = [];
  bairrosSelecteds = [];
  bai = []

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  @ViewChild('allSelected') private allSelected: MatOption;


  public bairroFilterCtrl: FormControl = new FormControl();
  public filteredBairro: ReplaySubject<Bairro[]> = new ReplaySubject<Bairro[]>(1);

  public ufFilterCtrl: FormControl = new FormControl();
  public filteredUF: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public municipioFilterCtrl: FormControl = new FormControl();
  public filteredMunicipio: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public postoFilterCtrl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _onDestroy = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private postoService: PostsService,
    private activityFieldService: ActivityFieldService,
    public matDialogRef: MatDialogRef<ActuationAreaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
    this.activeCheck = (_data.activity ? _data.activity.ativo : null) ? 'Ativo' : 'Inativo';
    // tslint:disable-next-line:triple-equals
    this.titleHeader = (this._data.action == 'new') ? 'Nova Área de Atuação' : 'Editar Área de Atuação';
  }

  ngOnInit(): void {
    this.setFormGroup();
    this.postoLista();
    if (this._data.action === 'update') {
      this.globalService.pageEstado().subscribe(data => {
        this.ufs = data.object.content;

        this.uf = this.ufs.filter(e => e.sigla === this.areaDeAtuacao.get('estado').value)[0]
        this.getMunicipios(this.uf);

        this.filteredUF.next(this.ufs.slice());
        this.ufFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUf();
          });
      })
    } else {
      this.globalService.pageEstado().subscribe(data => {
        this.ufs = data.object.content;
        this.filteredUF.next(this.ufs.slice());
        this.ufFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUf();
          });
      });
    }

  }


  setFormGroup(): void {
    this.listBairro = this._data.activity ? this._data.activity.listBairro : [];

    if (this.listBairro != null) {
      this.listBairroDescriptions = this.listBairro.map(bairro => bairro.id) || null;
      this.bairrosSelecteds = this.listBairro.map(bairro => bairro) || null;

    }

    let posto = null;
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {    
      posto = this._data.activity.posto  ;
    }

    this.areaDeAtuacao = this.formBuilder.group({
      id: this._data.activity ? this._data.activity.id : null,
      ativo: this._data.activity ? this._data.activity.ativo : true,
      nome: [this._data.activity ? this._data.activity.nome : null, Validators.required],
      municipio: [this._data.activity ? this._data.activity.listBairro[0].municipio.nomeMunicipio : null,Validators.required],
      listBairro: [this._data.activity ? this.listBairroDescriptions : null,Validators.required],
      estado: [this._data.activity ? this._data.activity.listBairro[0].municipio.estado.sigla : null,Validators.required],
      posto: [posto ? this._data.activity.posto.descricao : null,Validators.required]
    });
  }

  onAdd(data) {
    this.listBairro = [];
    data.listBairro = this.bairrosSelecteds;
    this.disabledButton = true;
    this.postos.forEach(posto => {
      if(posto.descricao == data.posto) {
        data.posto = posto;
      }      
    })
    this.activityFieldService.add(data).subscribe(area => {
      if (area.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${area.messages[0].text}`
        }
      } else {
        if (area.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${area.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }


      this.feedback(this.dialogMessages);

      this.matDialogRef.close(area.object);
    });
  }

  onUpdate(data) {
    this.disabledButton = true;
    let listBairro = [];
    data.municipio = this.municipios;

    data.listBairro = this.bairrosSelecteds
    this.postos.forEach(posto => {
      if(posto.descricao == data.posto) {
        data.posto = posto;
      }      
    })
    this.activityFieldService.apdate(data).subscribe(area => {
      if (area.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${area.messages[0].text}`
        }
      } else {
        if (area.messages != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${area.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }
      this.feedback(this.dialogMessages);

      this.matDialogRef.close(area.object);
    });
  }

  private filterBairro() {
    if (!this.filteredOptions) {
      return;
    }

    let search = this.bairroFilterCtrl.value;
    if (!search) {
      this.filteredBairro.next(this.filteredOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBairro.next(
      this.filteredOptions.filter(bairro => bairro.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  onClosed() {
    this.matDialogRef.close();
  }

  getErrorMessage(field: string) {
    return this.areaDeAtuacao.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onChange(isActive: boolean) {
    this._data.activity.ativo = !isActive;
    this.areaDeAtuacao.get('ativo').setValue(!isActive);
    console.log(this.areaDeAtuacao.get('ativo').value);

  }

  getMunicipios(estado) {
    let uf;
    this.bairros = [];
    if (typeof estado === 'string') {
      uf = this.ufs.filter(e => e.sigla === estado)[0]
    } else {
      uf = estado
    }

    this.globalService.municipioByEstadoId(uf.id).subscribe(data => {
      this.municipios = data.object.content.map(data => data)

      this.filteredMunicipio.next(this.municipios.slice());
      this.municipioFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMunicipio();
        });
      if (this._data.action == 'update') {
        this.selectedMunicipio = this.municipios.filter(data => data.nomeMunicipio == this._data.activity.listBairro[0].municipio.nomeMunicipio)[0];
        this.getBairros(this.selectedMunicipio)
      }
    })
  }

  getBairros(municipio) {
    let mun;

    if (municipio != null) {
      if (typeof municipio === 'string') {
        mun = this.municipios.filter(m => m.nomeMunicipio === municipio)[0]
      } else {
        mun = municipio
      }
      this.globalService.bairroByMunicipioId(mun.id).subscribe(data => {
        this.filteredOptions = data.object.content;
        if (this.filteredOptions.length == this.listBairro.length) {
          this.allSelected.select();
          this.areaDeAtuacao.controls.listBairro
            .patchValue([...this.filteredOptions.map(item => item.descricao), 0]);
        } else {
          this.allSelected.deselect()
        }
        this.filteredBairro.next(this.filteredOptions.slice());
        this.bairroFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBairro();
          });
      })
    } else {
      this.filteredOptions = []
      this.filteredBairro.next(this.filteredOptions.slice());
      this.bairroFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBairro();
        });
    }



  }

  private filterMunicipio() {
    if (!this.filteredMunicipio) {
      return;
    }

    let search = this.municipioFilterCtrl.value;
    if (!search) {
      this.filteredMunicipio.next(this.municipios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMunicipio.next(
      this.municipios.filter(municipio => municipio.nomeMunicipio.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterUf() {
    if (!this.filteredUF) {
      return;
    }

    let search = this.ufFilterCtrl.value;
    if (!search) {
      this.filteredUF.next(this.ufs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUF.next(
      this.ufs.filter(estado => estado.sigla.toLowerCase().indexOf(search) > -1)
    );
  }

  onSelectAll() {
    //Seleciona todos os itens
    if (this.allSelected.selected) {
      if (this._data.action == 'update') {
        //Apaga os ja selecionados e seleciona todos novamente
        this.filteredOptions.forEach(b => {
          const index = this.bairrosSelecteds.indexOf(b.id);

          this.bairrosSelecteds.splice(index, 1);
          this.listBairroDescriptions.splice(index, 1)
        })

        this.areaDeAtuacao.controls.listBairro.patchValue([...this.filteredOptions.map(item => item.descricao), 0]);
        this.filteredOptions.forEach(b => { this.bairrosSelecteds.push(b) })

      } else {
        this.areaDeAtuacao.controls.listBairro.patchValue([...this.filteredOptions.map(item => item.descricao), 0]);
        this.filteredOptions.forEach(b => { this.bairrosSelecteds.push(b) })
      }
    } else {
      //Disseleciona retirando todos os itens 
      this.areaDeAtuacao.controls.listBairro.patchValue([]);
      this.filteredOptions.forEach(b => {
        const index0 = this.bairrosSelecteds.indexOf(b.id);
        const index1 = this.bairrosSelecteds.indexOf(b.descricao);

        this.bairrosSelecteds.splice(index0, 1);
        this.listBairroDescriptions.splice(index1, 1)
      })

    }
  }

  selected(event) {
    const index = this.bairrosSelecteds.filter(b => b.id == event.id)[0];
    if (index != null) {
      this.bairrosSelecteds.splice(this.bairrosSelecteds.indexOf(index), 1)
      this.listBairroDescriptions.splice(index.descricao, 1)
    } else {
      this.bairrosSelecteds.push(event)
      this.listBairroDescriptions.push(event.descricao)
    }

  }

  private postoLista(){
    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() =>{
        this.filterPosto();
      })
    })
  }

  private filterPosto() {
    if (!this.filteredPosto) {
      return;
    }
    let search = this.postoFilterCtrl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }
}
