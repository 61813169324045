import { Team } from '../team/team.model';
import { Address } from '../address/address.model';
import {ParametroPosto} from '../parametro-posto/parametro-posto.model';
import { CaixaDespesaFuncionario } from 'app/main/financial/components/caixa-despesa-funcionario/caixa-despesa-funcionario.module';
import { Employee } from '../employee/employee.model';

export class Posto {
    id?: number;
    descricao?: string;
    qrcode?: string;
    uid?: string;
    endereco: Address;
    ativo: boolean;
    
    equipes?: Team[];

    gestor?: Employee;

    parametroPosto: ParametroPosto;

    constructor(posto: Partial<Posto>) {
        Object.assign(this, posto);
    }

}
