import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';

import { Contract } from '../../shared/model/contract/contract.model';
import { User } from 'app/main/shared/model/user/user.model';
import { catchError } from 'rxjs/operators';
import { SGF_API } from '../../../shared/api/sgf.api';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { AuditoriaRegularizacaoContratosDTO } from 'app/main/shared/model/auditoria-regularizacao-contratos/auditoria-regularizacao-contratos.dto';
import { CalculoValorLiquidoTO } from 'app/main/shared/model/calculo-valor-liquido/calculo-valor-liquido.to';
import { UploadAssinaturaDigital } from 'app/main/shared/components/assinatura-digital-form/assinatura-digital-form.component';
import { UploadRepassePix } from 'app/main/shared/components/repasse-pix-form/repasse-pix-form.component';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  private posto: string;
  private host = `${SGF_API}` + 'sgf/api/public/contrato';
  private pdfContracts = `${SGF_API}` + 'sgf/api/public/contrato/relatorio/';
  private pdfContratos = `${SGF_API}` + 'sgf/api/public/contrato/imprimir-contratos';
  private financeiroContracts = `${SGF_API}` + 'financeiro/api/public/contrato';
  private pdfComodata = `${SGF_API}` + 'financeiro/api/public/contrato/comodata/';
  private URI_CONFISSAO_DIVIDA = `${SGF_API}` + 'financeiro/api/public/contrato/confissao-divida-boleto/';
  private pdfRelatorioPeriodo = `${SGF_API}` + 'sgf/api/public/contrato/relatorioPeriodo';
  private urlParcelaService = `${SGF_API}` + 'financeiro/api/public/parcela';
  private urlBoletoService = `${SGF_API}` + 'financeiro/api/public/boleto';
  private urlArquivoContrato = `${SGF_API}` + 'sgf/api/public/arquivocontrato';
  private urlGraphQL = `${SGF_API}` + 'sgf/api/public/financeiro/graphql';
  private urlComprovantePix = `${SGF_API}` + 'sgf/api/public/arquivocomprovantepix';
  private urlAssinaturaDigital = `${SGF_API}` + 'sgf/api/public/assinatura-digital';

  constructor(private httpClient: HttpClient) {}

  findAll(): any {
    return this.httpClient.get<Contract[]>(this.host).pipe(catchError(this.handleError));
  }

  page(situacao: string, size: number, page: number, sort?: string, dataInicial?: any, dataFinal?: any, pix?: boolean, boleto?: boolean): any {
    this.checkIfHasPosto();
    if (!sort) {
      sort = '-id';
    }

    let dataIni = '';
    let dataFin = '';

    if (dataInicial) {
      dataIni = `&dataInicial=${dataInicial}`;
    }

    if (dataFinal) {
      dataFin = `&dataFinal=${dataFinal}`;
    }

    let contratoPix = '';

    if (pix) {
      contratoPix = `pix=true`;
    }

    let contratoBoleto = '';
    if (boleto) {
      contratoBoleto = `boleto=true`;
    }

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacaoContrato=in:${situacao}&size=${size}` +
          `&page=${page}${dataIni}${dataFin}&${this.posto}&sort=${sort}&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageDataVencimento(situacao: string, size: number, page: number, sort?: string): any {
    if (!sort) {
      sort = 'id';
    }
    const dataVencimento = new Date().toLocaleDateString();

    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacaoContrato=in:${situacao}&size=${size}&
                                  page=${page}&${this.posto}&sort=-${sort}&data=btn:${dataVencimento}&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageDataVencimentoByNomeCliente(situacao: string, nomeCliente: string, size: number, page: number, sort?: string): any {
    if (!sort) {
      sort = 'id';
    }
    const dataVencimento = new Date().toLocaleDateString();
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&data=btn:${dataVencimento}&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageOrderByDataFinalizacao(situacao: string, size: number, page: number): any {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-dataFinalizacao&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageAll(size: number, page: number, sort?: string): Observable<any> {
    if (!sort) {
      sort = 'id';
    }
    this.checkIfHasPosto();

    return this.httpClient
      .get<Contract[]>(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-${sort}&cancelar=neq:true`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  pageAllContratoHoje(size: number, page: number, situacao?: string, hoje?: Date): Observable<any> {
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacao=${situacao}&size=${size}&page=${page}&${this.posto}&dataPeriodo=btn:${hoje.getTime()},${hoje.getTime()}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageAllCancelar(size: number, page: number, sort?: string): Observable<any> {
    if (!sort) {
      sort = 'id';
    }
    this.checkIfHasPosto();

    return this.httpClient
      .get<Contract[]>(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-${sort}&cancelar=eq:true`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getListContratosReagendados(size: number, page: number, specs?: { [key: string]: string }): any {
    this.checkIfHasPosto();
    const params = Object.entries(specs ? specs : {}).reduce((p, [param, value]) => (value !== null ? `${p}&${param}=${value}` : ''), '');
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacaoContrato=in:GERADO&size=${size}&page=${page}&${this.posto}&naturezaContrato=in:REAGENDAMENTO${params}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  // id do contrato origem
  getsomaReagendados(id: number): any {
    this.checkIfHasPosto();

    return this.httpClient
      .get<Contract[]>(`${this.host}/auditoria/qtdreagendamento/${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getValorBrutoTodosContratos(valorContratosDTO: any): Observable<any> {
    this.checkIfHasPosto();
    if (this.posto) {
      valorContratosDTO = { ...valorContratosDTO, postoId: parseInt(this.posto.split('=')[1]) };
    }
    return this.httpClient
      .post(`${this.host}/valor-bruto-total-contratos`, valorContratosDTO, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getVendedores(): Observable<User[]> | any {
    return null;
  }

  updateContract(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(this.host, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  vincularMaquina(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(`${this.host}/vincularmaquina`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  editarMaquinas(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(`${this.host}/editarmaquinas`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  editarMaquinasImpressao(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put(`${this.host}/editarmaquinasimpressao`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  bordero(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.post<Contract[]>(`${this.host}/bordero`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  renegociar(idContrato: number, periodicidade: string, quantidadeParcelas: number, calcular: boolean): any {
    const body = {
      contratoId: idContrato,
      periodicidade: periodicidade,
      quantidadeParcelas: quantidadeParcelas,
      calcular: calcular,
    };
    return this.httpClient.post(`${this.host}/renegociar`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  renegociarDivida(contratoId: number, valorAcordado: number, dataVencimento: number): any {
    const body = { contratoId, valorAcordado, dataVencimento };
    return this.httpClient.post(`${this.host}/renegociardivida`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  repactuarDivida(
    contratoId: number,
    quantidadeParcelas: number,
    valorAcordado: number,
    periodicidade: number,
    dataLiberacao: number,
  ): any {
    const body = { contratoId, quantidadeParcelas, valorAcordado, periodicidade, dataLiberacao };
    return this.httpClient.post(`${this.host}/repactuardivida`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  desvincularMaquina(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(`${this.host}/desvincularmaquina`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  cancelContract(contract: any): Observable<any> {
    const contratoDTO = {
      contratoId: contract.id,
      parecerCancelamento: contract.parecerCancelamento,
    };

    const body = JSON.stringify(contratoDTO);
    return this.httpClient.put<Contract[]>(`${this.host}/cancela`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  liquidaContrato(contrato: any): Observable<any> {
    const body = JSON.stringify(contrato);
    return this.httpClient.put<Contract>(`${this.host}/liquida`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  confirmContract(id: number): Observable<any> {
    return this.httpClient.put<Contract[]>(`${this.host}/confirma`, id, this.httpOptions()).pipe(catchError(this.handleError));
  }

  releaseContracts(idBulk: any[], liberarSaidas: boolean = false, repasseFimDeSemana: boolean = false): Observable<any> {
    return this.httpClient
      .put<Contract[]>(
        `${this.host}/liberar-contratos?liberarSaidas=${liberarSaidas}&repasseFimDeSemana=${repasseFimDeSemana}`,
        idBulk,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  reagendaContract(contract: any): Observable<any> {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(`${this.host}/reagenda`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  liquidarContrato(contrato: any): Observable<any> {
    const body = JSON.stringify(contrato);
    return this.httpClient.put<Contract[]>(`${this.host}/liquidar-contrato`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<Contract[]>(`${this.host}/${id}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  gerarBoletos(contratoId: number): any {
    return this.httpClient.get(`${this.urlBoletoService}/download/boletobycontrato/${contratoId}`, { responseType: 'blob' });
  }

  cancelarBoletos(contratoId: number): any {
    return this.httpClient.get(`${this.host}/cancelarBoletos/${contratoId}`, { responseType: 'blob' });
  }

  gerarBoletosAgendados(contratoId: number): any {
    return this.httpClient.get(`${this.urlBoletoService}/download/boletobycontratoagendado/${contratoId}`, { responseType: 'blob' });
  }

  getContract(id: number): any {
    return this.httpClient.get(this.pdfContracts + id, { responseType: 'blob' });
  }

  getPDFContratos(ids: number[]): any {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: '',
    });

    return this.httpClient
      .post(this.pdfContratos, ids, { responseType: 'blob', headers: httpHeaders })
      .pipe(catchError(this.handlePdfError));
  }

  getComodata(id: number): any {
    return this.httpClient.get(this.pdfComodata + id, { responseType: 'blob' });
  }

  confissaoDividaBoleto(id: number): any {
    return this.httpClient.get(this.URI_CONFISSAO_DIVIDA + id, { responseType: 'blob' });
  }

  getComodataVinculo(maquinaTO: any): any {
    const body = JSON.stringify(maquinaTO);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: '',
    });
    return this.httpClient
      .post(`${this.pdfComodata}vinculo`, body, { responseType: 'blob', headers: httpHeaders })
      .pipe(catchError(this.handlePdfError));
  }

  getRelatorioData(datas: string, tipoContrato: boolean, postoId: any, rotaDescricao: string, usuario: number): any {
    return this.httpClient.get(
      `${this.pdfRelatorioPeriodo}?datas=${datas}&boleto=${tipoContrato}&rota=${rotaDescricao}&posto=${postoId}&usuario=${usuario}`,
      { responseType: 'blob' },
    );
  }

  alterarResponsavelContrato(contract: any): Observable<any> {
    // const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/alterarresponsavel`, contract, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  transferirContratos(contract: any): Observable<any> {
    // const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/alterarresponsavelcontratos`, contract, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  alterarVendedor(contract: any): Observable<any> {
    const body = JSON.stringify(contract);
    return this.httpClient.put(`${this.host}/alterarvendedor`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  transferirCobrancaContrato(contract: any): Observable<any> {
    // const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/transferir-cobranca`, contract, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  alterarResponsavelRepasse(contract: any): Observable<any> {
    const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/alterarresponsavelrepasse`, body, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  alterarContrato(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient.put<Contract[]>(`${this.host}/alterarcontrato`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  pageByNomeCliente(
    situacao: string,
    nomeCliente: string,
    size: number,
    page: number,
    dataInicial?: any,
    dataFinal?: any,
    pix?: boolean,
    boleto?: boolean
  ): any {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    let dataIni = '';
    let dataFin = '';

    if (dataInicial) {
      dataIni = `&dataInicial=${dataInicial}`;
    }

    if (dataFinal) {
      dataFin = `&dataFinal=${dataFinal}`;
    }

    const contratoPix = pix ? 'pix=true' : '';
    const contratoBoleto = boleto ? 'boleto=true' : '';

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}${dataIni}${dataFin}&${this.posto}&sort=+dataVencimento&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByNomeBuscaCliente(situacao: string, nomeBuscaCliente: string, size: number, page: number, dataInicial?: any, dataFinal?: any): any {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    let dataIni = '';
    let dataFin = '';

    if (dataInicial) {
      dataIni = `&dataInicial=${dataInicial}`;
    }

    if (dataFinal) {
      dataFin = `&dataFinal=${dataFinal}`;
    }

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26nomeBuscaCliente%3D%25${nomeBuscaCliente}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}${dataIni}${dataFin}&${this.posto}&sort=+dataVencimento&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  contratosByIdCliente(idCliente: number): any {
    this.checkIfHasPosto();
    return this.httpClient
      .get<Contract[]>(`${this.host}?idCliente=${idCliente}&${this.posto}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  pageByIdCliente(situacao: string, idCliente: number, size: number, page: number): any {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?idCliente=${idCliente}&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByDocumentoCliente(situacao: string, documentoCliente: string, size: number, page: number, pix?: boolean, boleto?: boolean): any {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    const contratoPix = pix ? 'pix=true' : '';
    const contratoBoleto = boleto ? 'boleto=true' : '';

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26documentoCliente%3D%25${documentoCliente}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByResponsavelContrato(situacao: string, responsavelContrato: string, size: number, page: number, pix?: boolean, boleto?: boolean): any {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    const contratoPix = pix ? 'pix=true' : '';
    const contratoBoleto = boleto ? 'boleto=true' : '';

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26responsavelContrato%3D%25${responsavelContrato}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByResponsavelRepasse(situacao: string, responsavelRepasse: string, size: number, page: number, pix?: boolean, boleto?: boolean): any {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();

    const contratoPix = pix ? 'pix=true' : '';
    const contratoBoleto = boleto ? 'boleto=true' : '';

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26responsavelRepasse%3D%25${responsavelRepasse}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByResponsavelRota(situacao: string, rota: string, size: number, page: number, pix?: boolean, boleto?: boolean): any {
    this.checkIfHasPosto();

    const contratoPix = pix ? 'pix=true' : '';
    const contratoBoleto = boleto ? 'boleto=true' : '';

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26descricaoRota%3D%25${rota}%25&situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true&${contratoPix}&${contratoBoleto}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageContratoByNomeCliente(clienteId: number, size: number, page: number): any {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26idCliente=${clienteId}&size=${size}&page=${page}&${this.posto}&sort=+dataVencimento&cancelar=neq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageContratoByPeriodo(situacao: string, size: number, page: number, sort?: string, periodo?: string): any {
    if (!sort) {
      sort = '-id';
    }

    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?situacaoContrato=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=${sort}&dataPeriodo=btn:${periodo}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  findLastContratoByIdCliente(idCliente: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient
      .get(`${this.host}?idCliente=${idCliente}&${this.posto}&page=1&size=1&sort=-id`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  pageCancelar(size: number, page: number, sort?: string): Observable<any> {
    if (!sort) {
      sort = '-id';
    }

    this.checkIfHasPosto();

    return this.httpClient
      .get<Contract[]>(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=${sort}&cancelar=eq:true`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  pageByNomeClienteCancelar(nomeCliente: string, size: number, page: number): any {
    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&size=${size}&page=${page}&sort=+dataVencimento&cancelar=eq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  pageByRotaCancelar(descricaoRota: string, size: number, page: number): any {
    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .get<Contract[]>(
        `${this.host}?filters=%26descricaoRota%3D%25${descricaoRota}%25&size=${size}&page=${page}&sort=+dataVencimento&cancelar=eq:true`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  getBoletos(id: number): Observable<any> {
    return this.httpClient
      .get<Contract[]>(`${this.host}/getBoletos?contratoId=${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getLancamentos(id: number): Observable<any> {
    return this.httpClient
      .get<Contract[]>(`${this.host}/contrato-lancamento?contratoId=${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getLancamentosDiarios(id: number): Observable<any> {
    return this.httpClient
      .get<Contract[]>(`${this.host}/contrato-lancamento-diario?contratoId=${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getDataFinalizacaoContratoAnterior(visitaId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/data-finalizacao-anterior/${visitaId}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  getValorExcedenteContratoAnterior(visitaId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/valor-excedente-contrato/${visitaId}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  transferirResponsavelDeTodosOsContratos(contract: any): any {
    const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/transferir-contratos`, body, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  calcularValorJuros(idContrato: number, data?: Date, quantidadeDias?: number): Observable<any> {
    const body = {
      contratoId: idContrato,
      data: data,
      quantidadeDias: quantidadeDias,
    };
    return this.httpClient.post(`${this.host}/calcula-juros`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }

  liberarContratoRetroativo(contract: any): Observable<any> {
    const body = JSON.stringify(contract);
    return this.httpClient
      .put<Contract[]>(`${this.host}/liberar-contrato-retroativo`, body, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  imprimirContratosCobrancas(idResponsavel: number, dataVencimento: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      Authorization: '',
    });

    return this.httpClient
      .post(
        `${this.financeiroContracts}/imprimircontratoscobrancas?idResponsavel=${idResponsavel}&dataVencimento=${dataVencimento}`,
        null,
        {
          headers: httpHeaders,
          responseType: 'blob' as 'json',
        },
      )
      .pipe(catchError(this.handleError));
  }

  imprimirRecebimentos(idContrato: any): Observable<any> {
    return this.httpClient.get(`${this.host}/imprimir-relatorio-recebimento/` + idContrato, { responseType: 'blob' });
  }

  imprimirUltimoRecebimento(idCliente: any): Observable<any> {
    return this.httpClient.get(`${this.host}/imprimir-ultimo-relatorio-recebimento/` + idCliente, { responseType: 'blob' });
  }

  imprimirRecebimentosCliente(idCliente: any): Observable<any> {
    return this.httpClient.get(`${this.host}/imprimir-relatorio-recebimento-cliente/` + idCliente, { responseType: 'blob' });
  }

  downloadRotaContrato(): any {
    return this.httpClient.get(`${this.financeiroContracts}/download/rotacontrato`).pipe(catchError(this.handleError));
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: '',
      }),
    };
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        Authorization: '',
      }),
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  baixarBoleto(parcelaId: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'json',
    };

    return this.httpClient
      .post<any>(`${this.urlBoletoService}/gerar-boleto/` + parcelaId, null, httpOptions)
      .pipe(catchError(this.handleError));
  }

  relatorioInadimplencia(): any {
    return this.httpClient.get(`${this.host}/imprimir-relatorio-inadimplencia`, { responseType: 'blob' });
  }

  relatorioInadimplenciaBoleto(
    datas: any,
    tipoContrato: any,
    posto: any,
    rota: any,
    usuario: any,
    valorMinimoBusca: number,
    valorMaximoBusca: number,
    irregular: boolean,
    regular: boolean,
  ): any {
    return this.httpClient.get(
      `${this.host}/imprimir-relatorio-inadimplencia-boleto?posto=${posto}&rota=${rota}&usuario=${usuario}&datas=${datas}&${
        regular ? 'falhasMinimo' : 'valorMinimo'
      }=${valorMinimoBusca}&${regular ? 'falhasMaximo' : 'valorMaximo'}=${valorMaximoBusca}&irregular=${irregular}&regular=${regular}`,
      { responseType: 'blob' },
    );
  }

  imprimirRelatorioRecebimento(idContrato: any): any {
    return this.httpClient.get(`${this.host}/imprimir-historico-contrato/` + idContrato, { responseType: 'blob' });
  }

  setToCancel(id: number): any {
    return this.httpClient.get(`${this.host}/setToCancel/` + id, this.httpOptions());
  }

  printAllContract(): any {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?${this.posto}`);
  }

  relatorioRepassesRealizadas(datas: any, idPosto: any, idRota: any, idUsuario: any): Observable<any> {
    return this.httpClient.get(
      `${this.host}/imprimir-historico-repasse?datas=${datas}&posto=${idPosto}&rota=${idRota}&usuario=${idUsuario}`,
      { responseType: 'blob' },
    );
  }

  relatorioContratoPorCliente(
    datas: string,
    idPosto: any,
    usuario: any,
    opcao: any,
    valorMinimo: any,
    valorMaximo: any,
    rota: string,
  ): Observable<any> {
    return this.httpClient.get(
      `${this.host}/relatorio-contratos-por-cliente?datas=${datas}&posto=${idPosto}&usuario=${usuario}&opcao=${opcao}&valorMinimo=${valorMinimo}&valorMaximo=${valorMaximo}&rota=${rota}`,
      { responseType: 'blob' },
    );
  }

  private handlePdfError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  pageContratosGraphQL(
    situacao: string,
    size: number,
    page: number,
    sort?: string,
    dataInicial?: any,
    dataFinal?: any,
    pix?: boolean,
    boleto?: boolean,
    dataInicialLiberacao?: any,
    dataFinalLiberacao?: any,
  ): Observable<any> {
    this.checkIfHasPosto();
    if (!sort) {
      sort = '-id';
    }

    let dataIni = '';
    let dataFin = '';
    let dataIniLiberacao = '';
    let dataFinLiberacao = '';

    if (dataInicial) {
      dataIni = `&dataInicial=${dataInicial}`;
    }

    if (dataFinal) {
      dataFin = `&dataFinal=${dataFinal}`;
    }

    if (dataInicialLiberacao) {
      dataIniLiberacao = `&dataInicialLiberacao=${dataInicialLiberacao}`;
    }

    if (dataFinalLiberacao) {
      dataFinLiberacao = `&dataFinalLiberacao=${dataFinalLiberacao}`;
    }

    let pagamentoPix = '';
    if (pix) {
      pagamentoPix = `&pix=${pix}`;
    }

    let pagamentoBoleto = '';
    if (boleto) {
      pagamentoBoleto = `&boleto=${boleto}`;
    }

    // tslint:disable-next-line: max-line-length
    const query = {
      query: `{ findAllPageable(args: "situacaoContrato=in:${situacao}&size=${size}&page=${page}${dataIni}${dataFin}${dataIniLiberacao}${dataFinLiberacao}&${this.posto}&sort=${sort}&cancelar=neq:true${pagamentoPix}${pagamentoBoleto}") {
                      numberOfElements
                      number
                      size
                      totalElements
                      totalPages
                      content {
                        id, uid, valorBruto, valorContratoBruto, dataEmissao, 
                        dataLiberacao, dataVencimento, situacaoContrato, assinadoDigital, valorLiquido,
                        posto {
                          id,
                          uid,
                          descricao,
                          parametroPosto {
                            id,
                            pixBoleto
                          }
                        }
                        responsavel {
                          id
                          rota { id descricao }
                          pessoa {
                            id
                            nomePessoa,
                            listTelefone { numero }
                            listEndereco { bairro { descricao } }
                          }
                        }
                        responsavelRepasse {
                          id
                          cargo { descricao }
                          rota { id descricao }
                          pessoa {
                            id
                            nomePessoa,
                            listTelefone { numero }
                            listEndereco { bairro { descricao } }
                          }
                        }  
                        indicador {
                          id
                          rota { id descricao }
                          pessoa {
                            id
                            nomePessoa,
                            listTelefone { numero }
                            listEndereco { bairro { descricao } }
                          }
                        }
                        visita {
                          valorAprovado,
                          quantidadeParcelas,
                          cliente {
                            id
                            apelidoCliente
                            pessoa {
                              id
                              nomePessoa,
                              pessoaFisica {
                                id
                                cpf
                                nome
                              }
                              pessoaJuridica {
                                id
                                cnpj
                                razaoSocial
                                nomeFantasia
                              }
                              listTelefone { numero }
                              listEndereco { bairro { descricao } }
                            }
                          } 

                          fiscal {
                            id
                            rota { id descricao }
                            pessoa {
                              id
                              nomePessoa,
                              pessoaFisica {
                                id
                                cpf
                                nome
                              }
                              pessoaJuridica {
                                id
                                cnpj
                                razaoSocial
                                nomeFantasia
                              }
                              listTelefone { numero }
                              listEndereco { bairro { descricao } }
                            }
                          } 
                        }
                        listParcela { id valor valorPago }
                        analiseRenovacao {
                          valorContrato, quantidadeParcelas, parecerTecnico, arquivos {
                              id, codigo
                          }
                        }
                      }
                    }}`,
    };

    return this.httpClient.post(`${this.urlGraphQL}`, query, this.httpOptions()).pipe(catchError(this.handleError));
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  alterarTitularidade(idCliente: number, idContrato: number): Observable<any> {
    return this.httpClient
      .put<any>(`${this.host}/transferirtitularidade/${idCliente}/${idContrato}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  getCodeByVisit(id: number): Observable<any> {
    return this.httpClient
      .get(`${this.urlArquivoContrato}/findbycontratoorigem/${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  qtdContratoCliente(idCliente: any): Observable<any> {
    return this.httpClient.get(`${this.host}/qtdContratosCliente/${idCliente}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  gerarNovoContratoParaClienteInativo(dto): Observable<any> {
    return this.httpClient
      .post(`${this.host}/gerar-novo-contrato-cliente-inativo`, dto, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  imprimirRelatorioSIGRepasses(idPosto, idRota, dataInicial: string, idUsuario): Observable<any> {
    return this.httpClient.get(`${this.host}/sig-repasse?datas=${dataInicial}&posto=${idPosto}&rota=${idRota}&usuario=${idUsuario}`, {
      responseType: 'blob',
    });
  }

  getTotalNovoInativos(postoId, dataInicial, dataFinal): Observable<any> {
    return this.httpClient
      .get(`${this.host}/get-total-novo-inativos/${postoId}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
      .pipe(catchError(this.handleError));
  }

  motivoContratoCancelado(dataInicial, dataFinal): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient
      .get(
        `${this.host}/relatorio-de-motivo-desistencia?${this.posto}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  getDashboardFaturamentoTO(postoList: any): Observable<any> {
    this.checkIfHasPosto();
    const dataAtual = new Date();
    return this.httpClient
      .post(
        `${this.host}/get-dashboard-de-faturamento?` + `dataInicial=${dataAtual.getTime()}&dataFinal=${dataAtual.getTime()}`,
        postoList,
        this.httpOptions(),
      )
      .pipe(catchError(this.handleError));
  }

  relatorioAproximacaoDeLiquidacao(postoId: number, rota: string, quantidade: number, usuarioId: number): Observable<Blob> {
    const usuario = usuarioId ? `usuarioId=${usuarioId}` : '';
    return this.httpClient.get(
      `${this.host}/gerar-relatorio-aproximacao-de-liquidacao?postoId=${postoId}&rota=${rota}&quantidade=${quantidade}&${usuario}`,
      { responseType: 'blob' },
    );
  }

  listarContratosReagendadosNaoOperadosPorPosto(postoId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.host}/listar-contratos-reagendados-nao-operados?postoId=${postoId}`);
  }

  regularizarContratosReagendadosNaoOperados(contratos: AuditoriaRegularizacaoContratosDTO): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/regularizar-contratos-reagendados-nao-operados`, contratos, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  calcularValorLiquido(calculoValorLiquidoTO: CalculoValorLiquidoTO): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/calculo-liquido-contrato`, calculoValorLiquidoTO, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  confirmarAssinaturaDigital(dto: UploadAssinaturaDigital): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/confirmar-assinatura-digital`, dto);
  }

  confirmarRepasseContratoPix(dto: UploadRepassePix): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/confirmar-repasse-pix`, dto);
  }

  confirmarRepasseContratoBoleto(dto: UploadRepassePix): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/confirmar-repasse-boleto`, dto);
  }

  imprimirComprovanteAssinaturaDigital(contratoId: number): Observable<any> {
    return this.httpClient.get(`${this.urlAssinaturaDigital}/storage/contrato/${contratoId}`, { responseType: 'blob' });
  }

  imprimirComprovanteRepassePix(contratoId: number): Observable<any> {
    return this.httpClient.get(`${this.urlComprovantePix}/storage/contrato/${contratoId}`, { responseType: 'blob' });
  }

  anexarComprovante(dto: any): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.urlComprovantePix}/anexo`, dto);
  }

  hasContratoLiberadoPix(postoId: number): Observable<any> {
    return this.httpClient.get(`${this.host}/has-contrato-liberado-pix/${postoId}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  alterarIndicador(indicadorId: number, contratoId: number): any {
    const body = {contratoId,  indicadorId: indicadorId};
    return this.httpClient.post(`${this.host}/alterar-indicador`, body, this.httpOptions()).pipe(catchError(this.handleError));
  }
}
