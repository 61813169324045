import { OnInit, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-renegociacao-divida-form',
  templateUrl: './renegociacao-divida-form.component.html',
  styleUrls: ['./renegociacao-divida-form.component.scss']
})
export class RenegociacaoDividaFormComponent implements OnInit {

  renegociacao: FormGroup;
  isConfirmButtonEnabled = true;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<RenegociacaoDividaFormComponent>,
    public dialog: MatDialog,
    public contratoService: ContractsService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  ngOnInit(): void {
    this.renegociacao = this.formBuilder.group({
       valorAcordado: this._data.contrato.saldoAReceber,
       dataVencimento: new Date()
    });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(value): void {
    this.isConfirmButtonEnabled = false;
    const dataVencimento = value.dataVencimento.getTime ? value.dataVencimento.getTime() : value.dataVencimento.valueOf()
    
    this.contratoService.renegociarDivida(this._data.contrato.id, value.valorAcordado, dataVencimento)
      .subscribe(data => {
        let dialogMessages;
        if (data.success) {
          dialogMessages = { type: 'success', title: 'Sucesso!', text: `Contrato prorrogado com sucesso!` };
          this.feedback(dialogMessages);
          this.matDialogRef.close(data.object);
        } else {
          dialogMessages = (data.messages[0].text != null)
            ? { type: 'info', title: 'Informação!', text: `${data.messages[0].text}` }
            : { type: 'error', title: 'Error!' };
          this.feedback(dialogMessages);
          this.isConfirmButtonEnabled = true;
        }
      })
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

}
