import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Employee } from '../../../../shared/model/employee/employee.model';
import { Municipality } from '../../../../shared/model/municipality/municipality.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private host = `${SGF_API}` + 'sgf/api/public/funcionario';
  private hostEmployee = `${SGF_API}` + 'global/api/public/funcionario';
  private pdfList = `${SGF_API}` + 'global/api/public/funcionario/pdf/';
  private disableEmployee = `${SGF_API}` + 'sgf/api/public/funcionario/desativar';
  private dadosFolhaPagamentoUrl = `${SGF_API}` + 'global/api/public/dadosfolhapagamento';
  private urlMunicipio = `${SGF_API}` + 'core/api/public/municipio';
  private posto: string;
  private idPosto: number;

  constructor(
    private httpClient: HttpClient) {
  }

  findAll(): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<Employee[]>(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  page(size: number, page: number): Observable<any>  { 
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`)
    .pipe(
      catchError(this.handleError)
    );
  }

  add(employee: any): any {
    const body = JSON.stringify(employee);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEmployee(employee: Employee): any{
    const body = JSON.stringify(employee);
    if (!employee.ativo) {
      this.desativarFuncionario(employee.id);
    }

    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getPdfList(employeeList: any): any {
    return this.httpClient.get(this.pdfList + employeeList, { responseType: 'blob' });
  }

  desativarFuncionario(id: number): any {
    const body = JSON.stringify(id);
    return this.httpClient.put(this.disableEmployee, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getMunicipio(uf: string): any {

    const params = {params: new HttpParams()
                              .set('siglaUF', `eq:${uf}`)
                              .set('size', '1000')};

    return this.httpClient.get<Municipality[]>(this.urlMunicipio, Object.assign({}, params, this.httpOptions()))
      .pipe( 
        catchError(this.handleError)
      );
  }


  addDadosFolhaPagamento(folhaPagamento: any): any{
    const body = JSON.stringify(folhaPagamento);
    return this.httpClient.post(this.dadosFolhaPagamentoUrl, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): any{
    return this.httpClient.get<Employee[]>(`${this.host}/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  findAllByCargo(idCargo: number): any{
    return this.httpClient.get<Employee[]>(`${this.hostEmployee}/lista-funcionario-cargo/${idCargo}`)
    .pipe(
      catchError(this.handleError)
    );
  }


  findAllByCargoAndPosto(idCargo: number, idPosto: number): any {

    // tslint:disable-next-line:triple-equals
    if (idPosto != null && idPosto != undefined) {
        this.idPosto = idPosto;
    }

    return this.httpClient.get<Employee[]>(`${this.hostEmployee}/lista-funcionario-cargo-posto/${idCargo}/${this.idPosto}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  findGestorByPosto(idPosto: number): any{
    return this.httpClient.get<Employee[]>(`${this.hostEmployee}/findGestorByPosto/${idPosto}`)
    .pipe(
      catchError(this.handleError)
    );
  }  

  findNomeGestorByPosto(idPosto: number): any{
    return this.httpClient.get<Employee[]>(`${this.hostEmployee}/findNomeGestorByPosto/${idPosto}`)
    .pipe(
      catchError(this.handleError)
    );
  } 
  
  findVisitadorByPosto(idPosto: number): any{
    return this.httpClient.get<Employee[]>(`${this.hostEmployee}/lista-funcionario-visitador-posto/${idPosto}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  getFuncionarioFilterDescricao(value: string): any {
    return this.httpClient.get<Employee[]>(`${this.hostEmployee}?nomeFuncionario=${value}&postoId=${this.idPosto}`)
        .pipe(
            catchError(this.handleError)
        );
  }


  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  pageNomeFuncionario(size: number, page: number, nomeFuncionario: string): Observable<any> {
    return this.httpClient.get(`${this.host}?filters=%26nomeFuncionario%3D%25${nomeFuncionario}%25&size=${size}&${this.posto}&page=${page}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageFuncionarioFalta(size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}/lista-funcionario-falta?filters=size=${size}&page=${page}&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageFaltaRota(size: number, page: number, rota: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}/lista-funcionario-falta?filters=%26descricaoRota%3D%25${rota}%25&size=${size}&page=${page}&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  checkIfHasPosto(): void {
      if (sessionStorage.getItem('funcionario')) {
        const user = JSON.parse(sessionStorage.getItem('funcionario'));
        this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
        this.idPosto = user.idPosto;
      }
  }

}
