import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Turno } from '../../model/enums/turno.enum';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { User } from '../../model/user/user.model';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Employee } from '../../model/employee/employee.model';
import { takeUntil } from 'rxjs/operators';
import { LoadProgressComponent } from '../../dialogs/load-progress/load-progress.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';

export namespace Turnos {
  export function values(): any {
    return Object.keys(Turno).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

@Component({
  selector: 'app-caixa-form',
  templateUrl: './caixa-form.component.html',
  styleUrls: ['./caixa-form.component.scss']
})

export class CaixaFormComponent implements OnInit {
  private static CARGOS_FUNCIONARIO_DO_CAIXA_GERAL = ['DIRETOR FINANCEIRO','GESTOR', 'PRESTADOR DE CONTA', 'TESOUREIRO', 'ADMINISTRADOR'];

  caixa: FormGroup;
  type: any;
  dialogMessages;
  funcionarioId: any;
  funcionarioCargo: string;
  funcionario: User;
  turnos = Turnos;
  disabledButton: boolean;
  employee: Employee[];
  operadorPosto: any;
  postos: Array<Posto>;
  selectedTuno = 'MANHA';

  public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));
  private _onDestroy = new Subject<void>();

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  constructor(private formBiulder: FormBuilder,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<CaixaFormComponent>,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private postoService: PostsService,
    private caixaService: CaixaService) {
    this.type = this.data;

    const funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    this.funcionarioId = funcionario.idFuncionario;
    this.funcionarioCargo = funcionario.cargo;

    this.checkIfHasPosto();

    this.employeeService.findAll().subscribe(result => {
      this.employee = result.object.content;
      if (this.type === 'cge') {
        this.employee = this.employee.filter(employee => CaixaFormComponent.CARGOS_FUNCIONARIO_DO_CAIXA_GERAL.includes(employee.cargo.descricao));
      }
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    });
  }

  ngOnInit(): void {
    if (this.usuario.idPosto == null) {
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.postoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
      });
    }

    this.setForm();
  }

  setForm(): void {
    this.caixa = this.formBiulder.group({
      type: [this.type],
      turno: [null, Validators.required],
      valor: [null],
      funcionario: this.formBiulder.group({
        id: [null, this.data === 'cfu' || this.data === 'cge' ? Validators.required : null]
      }),
      posto: [this.operadorPosto ? this.operadorPosto : null]
    });

    if (this.funcionarioCargo === 'PRESTADOR DE CONTA') {
      this.caixa.get('funcionario.id').setValue(this.funcionarioId);
    }
  }

  openCaixa(caixa: any): void {
    // Caixas referentes ao funcionário 
    if (this.type === 'cfu') {
      this.createCaixaFuncionario(caixa);
    } else if (this.type === 'cct'){
      this.openCaixaControle(caixa);
    } else if (this.type === 'cge'){
      this.abrirCaixaGeral(caixa);
    } else {
      this.create(caixa);
    }
  }

  create(caixa: any): void {

    const matDialog = this._matDialog.open(LoadProgressComponent, {
      disableClose: true
    });


    if (this.usuario.idPosto != null) {
        caixa.posto = this.operadorPosto;
    }

    this.caixaService.create(caixa).subscribe((data: ReturnApi) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CAIXA', onerror);
      };

    });
    
    this.disabledButton = true;

  }

  abrirCaixaGeral(caixa: any): void {
    const matDialog = this._matDialog.open(LoadProgressComponent, { disableClose: true });
    if (this.usuario.idPosto != null) {
        caixa.posto = this.operadorPosto;
    }

    const { funcionario, ...caixaGeral } = caixa;
    caixaGeral.funcionarioVinculado = funcionario;
    if (this.funcionarioId > 0) {
      caixaGeral.funcionarioOperador = { id: this.funcionarioId };
    }

    this.caixaService.abrirCaixaGeral(caixaGeral).subscribe((data: ReturnApi) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CAIXA', onerror);
      };

    });
    
    this.disabledButton = true;

  }

  createCaixaFuncionario(caixa: any): void {
    
    const matDialog = this._matDialog.open(LoadProgressComponent, {
      disableClose: true
    });

    if (this.usuario.idPosto != null) {
      caixa.posto = this.operadorPosto;
    }


    this.caixaService.createCaixaFuncionario(caixa).subscribe((data: ReturnApi) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CAIXA', onerror);
      };

    });
    this.disabledButton = true;

  }

  openCaixaControle(caixa: any): void {

    const matDialog = this._matDialog.open(LoadProgressComponent, {
      disableClose: true
    });

    if (this.usuario.idPosto != null) {
      caixa.posto = this.operadorPosto;
    }

    this.caixaService.openCaixaControle(caixa).subscribe((data: ReturnApi) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.matDialogRef.close(data.object);
        this.feedback(this.dialogMessages);
        matDialog.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CAIXA', onerror);
      };

    });
    
    this.disabledButton = true;
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  private filterPerfils(): void {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => {
        const nameFilter = employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1;
        if (this.type === 'cge') {
          return nameFilter && CaixaFormComponent.CARGOS_FUNCIONARIO_DO_CAIXA_GERAL.includes(employee.cargo.descricao);
        }
        return nameFilter;
      })
    );
  }

  checkIfHasPosto(): any {
  
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const postoId = Number.isInteger(user.idPosto) ? user.idPosto : null;
      this.postoService.findById(postoId).subscribe(r => {
        this.operadorPosto = r.object;
      }) ;
    }
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }
    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
