import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-reativar-renovacao-automatica',
  templateUrl: './reativar-renovacao-automatica.component.html',
  styleUrls: ['./reativar-renovacao-automatica.component.scss']
})
export class ReativarRenovacaoAutomaticaComponent implements OnInit {

  public clienteFilterControl: FormControl = new FormControl();

  cliente:any;
  disabledButton: boolean;
  titleHeader: string;
  text: string;

   /**
   * Constructor
   *
   * @param {MatDialogRef<ReativarRenovacaoAutomaticaComponent>} matDialogRef
   * @param _data
   */
  constructor(
    private matDialogRef: MatDialogRef<ReativarRenovacaoAutomaticaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.titleHeader = 'Reativar a renovação automática de contrato?';
        this.text = 'Você realmente deseja reativar a renovação automática de contrato?';
    }

  ngOnInit() {
    this.cliente = this.data['data'];
  }

  onClick(data?): void {
    this.disabledButton = true;
    if (data) {
      let cliente = data.data;
      this.matDialogRef.close(cliente);
    } else {
      this.matDialogRef.close();
    }
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
