import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PersonModel } from 'app/main/shared/model/person/persons.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private host = `${SGF_API}` + 'core/api/public/pessoa';
  private posto: string;

  constructor(
    private httpClient: HttpClient) {
  }

  findAll(size: number, page: number): Observable<any> {
    return this.httpClient.get(`${this.host}?type=pj&size=${size}&page=${page}&sort=-id`)
      .pipe(
        catchError(this.handleError)
      );
  }

  add(employee: any): Observable<any> {
    const body = JSON.stringify(employee);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(employee: PersonModel): Observable<any> {
    const body = JSON.stringify(employee);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  findById(id: number): Observable<any> {
    return this.httpClient.get(`${this.host}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
