import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RolesService } from '../../../global/components/roles/services/roles.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { RoleModel } from '../../model/role/role.model';
import { ReturnApi } from '../../model/return-api/returnApi-model';

export interface DialogData {
  role: RoleModel,
  action: string
}

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {

  titleHeader: string;
  role: FormGroup;
  dialogMessages;
  disabledButton:boolean;

  @ViewChild('descricao') descricao: ElementRef;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private roleService: RolesService,
    public matDialogRef: MatDialogRef<RoleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

      this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Cargo' : 'Edição de Cargo';
    
  }

  ngOnInit() {
    this.setFormGroup();
  }

  ngAfterViewInit() {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }

  setFormGroup() {
    this.role = this.formBuilder.group({
      id        : [ this._data.role.id || null ],
      descricao : [ this._data.role.descricao || null, Validators.required ],
      salario   : [ this._data.role.salario || null, Validators.required ]
    })
  }



  onAdd(value) {
    this.disabledButton = true;
    value.salario = value.salario.toString().replace(/[.,]/g, "");

    this.roleService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )

  }

  onUpdate(value) {
    this.disabledButton = true;
    value.salario = value.salario.toString().replace(/[.,]/g, "");

    this.roleService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
