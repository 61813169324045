import { Component, OnInit, Input, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { data } from '../../data/chart-data';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'app/main/global/components/users/services/users/users.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { PessoaService } from 'app/main/infinitylabs/core/pessoa/pessoa.service';
import { EmpresaService } from 'app/main/global/components/empresa/services/empresa-service.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { User } from '../../model/user/user.model';
import { ReturnApi } from '../../model/return-api/returnApi-model';


@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit, AfterViewInit {
  @Input() id;
  form: FormGroup
  user;
  usuario;
  typeUser: string;
  senhaAtualInvalid;
  senhasInvalid;
  funcionario;
  userId;
  dialogMessages;
  pessoa;
  type;
  cnpj;
  cpf;

  @ViewChild('senha') senha: ElementRef;

  constructor(private formBuild: FormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pessoaService: EmpresaService,
    private dialogRef: MatDialogRef<AlterarSenhaComponent>,
    private matDialog: MatDialog,
    private funcionarioService: EmployeeService) {

    this.funcionario = JSON.parse(sessionStorage.getItem('funcionario'))
    this.userId = sessionStorage.getItem('userId')

  }

  ngOnInit() {
    this.setForm()
  }



  ngAfterViewInit() {
    setTimeout(() => this.senha.nativeElement.focus(), 1000);
  }


  setForm() {
    this.form = this.formBuild.group({
      senha: [null, Validators.required],
      repeat_senha: [null, Validators.required],
      ativo: [true],
      type: [this.typeUser]
    })
  }

  onSave(value) {
    this.checkPassword(value)
    if (value.senha == value.repeat_senha) {
      this.userService.alterarSenha(value.senha).subscribe((data: ReturnApi) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Sua senha foi atualizado com sucesso`
          }

          this.feedback(this.dialogMessages);
          this.dialogRef.close(data.object);

        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            }
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }
          this.feedback(this.dialogMessages);

          this.dialogRef.close();
        }
        (onerror) => {
          console.log('ERROR ATUALIZAR USUÁRIO', onerror);
        }
      })
    }
  }

  checkPassword(value) {
    if (value.senha != value.repeat_senha) {
      this.senhasInvalid = true;
    } else {
      this.senhasInvalid = false;
    }
  }

  private feedback(message: string[]) {
    const dialogRef = this.matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.dialogRef.close();
  }

  getErrorMessage(field: string): String {
    return `O campo ${field} é obrigatório`;
  }


}
