import {
    Action,
    ActionCreator
  } from 'redux';
import { Customer } from './customer.model';
import { Information } from '../information/information.model';
import { Address } from '../address/address.model';
import { AddressType } from '../enums/address-type.enum';
import { uuid } from '../../util/uuid';
import { Telephone } from '../telephone/telephone.model';

/**
 * CustomerAction specifies action creators concerning Customers
 */
export const SET_CURRENT_CUSTOMER = '[Customer] Set Current';
export interface SetCurrentCustomerAction extends Action {
  customer: Customer;
}
export const setCurrentCustomer: ActionCreator<SetCurrentCustomerAction> =
  (customer) => ({
    type: SET_CURRENT_CUSTOMER,
    customer: customer
  });

/**
 * CustomerAction Add initial customer
 */

export const ADD_CUSTOMER = '[Customer] Add';
export interface AddCustomerAction extends Action {
  customer: Customer;
}
export const addCustomer: ActionCreator<AddCustomerAction> =
  (customer: Customer): AddCustomerAction => {

    const defaults = {
      customerId: uuid()
    }

    const newCustomer: Customer = Object.assign({}, defaults, customer)

    return {
      type: ADD_CUSTOMER,
      customer: newCustomer}
  };

export const DEL_CUSTOMER = '[Customer] Del';
export interface DelCustomerAction extends Action {
  customer: Customer;
}
export const deleteCustomer: ActionCreator<DelCustomerAction> =
  (customer: Customer): DelCustomerAction => {

    const defaults = customer

    return {
      type: DEL_CUSTOMER,
      customer: customer}
};
  

/**
 * CustomerAction Add Personal Data
 */

export const ADD_INFORMATIONS = '[Customer] Add informations';
export interface AddInformationsAction extends Action {
  customer: Customer;
  information: Information;
}
export const addInformations: ActionCreator<AddInformationsAction> =
  (customer: Customer, informationsArgs: Information): AddInformationsAction => {

    const defaults = new Information();

    const information: Information = Object.assign(
      {},
      defaults,
      informationsArgs
    );

    return {
      type: ADD_INFORMATIONS,
      customer: customer,
      information: information
    }
  }

/**
 * CustomerAction Add Address Data
 */

export const ADD_ADDRESS = '[Customer] Add address';
export interface AddAddressAction extends Action {
  customer: Customer;
  address: Address;
}
export const addAddress: ActionCreator<AddAddressAction> =
  (customer: Customer, addressArgs: Address): AddAddressAction => {

    const defaults: Address = new Address();

    const address: Address = Object.assign(
      {},
      defaults,
      addressArgs
    );
    
    return {
      type: ADD_ADDRESS,
      customer: customer,
      address: address
    }
  }

/**
 * CustomerAction Add Telephone Data
 */

export const ADD_TELEPHONE = '[Customer] Add telephone';
export interface AddTelephoneAction extends Action {
  customer: Customer;
  telephone: Telephone;
}
export const addTelephone: ActionCreator<AddTelephoneAction> =
  (customer: Customer, telephoneArgs: Telephone): AddTelephoneAction => {

    const defaults: Telephone = new Telephone();

    const telephone: Telephone = Object.assign(
      {},
      defaults,
      telephoneArgs
    );
    
    return {
      type: ADD_TELEPHONE,
      customer: customer,
      telephone: telephone
    }
  }

/**
 * CustomerAction Update Telephone Data
 */

export const UPDATE_TELEPHONE = '[Customer] Update telephone';
export interface UpdateTelephoneAction extends Action {
  customer: Customer;
  telephone: Telephone;
}
export const updateTelephone: ActionCreator<UpdateTelephoneAction> =
  (customer: Customer, telephoneArgs: Telephone): UpdateTelephoneAction => {

    const defaults: Telephone = new Telephone();

    const telephone: Telephone = Object.assign(
      {},
      defaults,
      telephoneArgs
    );
    
    return {
      type: UPDATE_TELEPHONE,
      customer: customer,
      telephone: telephone
    }
  }

/**
 * CustomerAction Select
 */

export const SELECT_CUSTOMER = '[Customer] Select';
export interface SelectCustomerAction extends Action {
  customer: Customer;
}
export const selectCustomer: ActionCreator<SelectCustomerAction> =
  (customer) => ({
    type: SELECT_CUSTOMER,
    customer: customer
  });

