export const ListUnididadeFederacao =  [
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 11,  nomeMunicipio: '', nomeUf: 'Rondônia',             siglaUF: 'RO' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 12,  nomeMunicipio: '', nomeUf: 'Acre',                 siglaUF: 'AC' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 13,  nomeMunicipio: '', nomeUf: 'Amazonas',             siglaUF: 'AM' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 14,  nomeMunicipio: '', nomeUf: 'Roraima',              siglaUF: 'RR' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 15,  nomeMunicipio: '', nomeUf: 'Pará',                 siglaUF: 'PA' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 16,  nomeMunicipio: '', nomeUf: 'Amapá',                siglaUF: 'AP' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 17,  nomeMunicipio: '', nomeUf: 'Tocantins',            siglaUF: 'TO' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 21,  nomeMunicipio: '', nomeUf: 'Maranhão',             siglaUF: 'MA' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 22,  nomeMunicipio: '', nomeUf: 'Piauí',                siglaUF: 'PI' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 23,  nomeMunicipio: '', nomeUf: 'Ceará',                siglaUF: 'CE' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 24,  nomeMunicipio: '', nomeUf: 'Rio Grande do Norte',  siglaUF: 'RN' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 25,  nomeMunicipio: '', nomeUf: 'Paraíba',              siglaUF: 'PB' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 26,  nomeMunicipio: '', nomeUf: 'Pernambuco',           siglaUF: 'PE' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 27,  nomeMunicipio: '', nomeUf: 'Alagoas',              siglaUF: 'AL' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 28,  nomeMunicipio: '', nomeUf: 'Sergipe',              siglaUF: 'SE' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 29,  nomeMunicipio: '', nomeUf: 'Bahia',                siglaUF: 'BA' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 31,  nomeMunicipio: '', nomeUf: 'Minas Gerais',         siglaUF: 'MG' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 32,  nomeMunicipio: '', nomeUf: 'Espírito Santo',       siglaUF: 'ES' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 33,  nomeMunicipio: '', nomeUf: 'Rio de Janeiro',       siglaUF: 'RJ' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 35,  nomeMunicipio: '', nomeUf: 'São Paulo',            siglaUF: 'SP' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 41,  nomeMunicipio: '', nomeUf: 'Paraná',               siglaUF: 'PR' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 42,  nomeMunicipio: '', nomeUf: 'Santa Catarina',       siglaUF: 'SC' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 43,  nomeMunicipio: '', nomeUf: 'Rio Grande do Sul',    siglaUF: 'RS' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 50,  nomeMunicipio: '', nomeUf: 'Mato Grosso do Sul',   siglaUF: 'MS' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 51,  nomeMunicipio: '', nomeUf: 'Mato Grosso',          siglaUF: 'MT' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 52,  nomeMunicipio: '', nomeUf: 'Goiás',                siglaUF: 'GO' },
    { ativo: '', codigoMunicipio: '', codigoMunicipioDigito: '', codigoUf: 53,  nomeMunicipio: '', nomeUf: 'Distrito Federal',     siglaUF: 'DF' }
  ];
