import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-alterar-valor-form',
  templateUrl: './alterar-valor-form.component.html',
  styleUrls: ['./alterar-valor-form.component.scss']
})
export class AlterarValorFormComponent implements OnInit {

  form: FormGroup;
  dialogMessages;
  title = '';
  disabled = false;
  enableFormaPagamentoSelection = false;

  valorTotal = Infinity;
  verificarDespesa = false;

  @ViewChild('valor') valor: ElementRef;

  constructor(private dialogRef: MatDialogRef<AlterarValorFormComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'saidas') {
      this.title = 'Alterar Valor';
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'confirmarValor') {
      this.title = 'Confirmado';
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'regularizarPendencia') {
      this.title = 'Confirmar Valor';
      this.verificarDespesa = this._data.verificarDespesa || false;
      this.enableFormaPagamentoSelection = true;
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'confirmacaoParcial') {
      this.title = 'Confirmação Parcial';
    } else {
      this.title = 'Valor Recebido';
    }
  }

  ngOnInit(): void {
    this.setForm();
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'regularizarPendencia') {
      this.valorTotal = this._data.valorDespesaFuncionario;
      this.form.get('valor').setValue(this._data.valor);
      this.form.get('valorDespesaFuncionario').setValue(this._data.valorDespesaFuncionario);
      this.disabled = true;
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  setForm(): void {
    this.form = this.formBuilder.group({
      id: [this._data.id],
      valor: [null, Validators.required],
      valorDespesaFuncionario: [null, Validators.required],
      formaPagamento: 0,
      destinoPagamento: 0
    });
  }

  onCancel(): void {
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'regularizarPendencia') {
      
    }
    this.dialogRef.close();
  }

  onSave(value): void {
    this.dialogRef.close(value);
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  generateMask(value: number): any {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
