import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cliente-remove-from-spc',
  templateUrl: './cliente-remove-from-spc.component.html',
  styleUrls: ['./cliente-remove-from-spc.component.scss']
})
export class ClienteRemoveSpcComponent implements OnInit {

  public clienteFilterControl: FormControl = new FormControl();

  devedor: FormGroup;
  cliente:any;
  disabledButton: boolean;
  titleHeader: string;

   /**
   * Constructor
   *
   * @param {MatDialogRef<ClienteRemoveSpcComponent>} matDialogRef
   * @param _data
   */
  constructor(
    private matDialogRef: MatDialogRef<ClienteRemoveSpcComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }



  ngOnInit() {
    this.cliente = this.data['data'];
    this.titleHeader = 'Removido(a) do SPC.';
  }

  onNoClick(data?): void {
    this.disabledButton = true;
    if (data) {
      let cliente = data.data;
      this.matDialogRef.close(cliente);
    } else {
      this.matDialogRef.close();
    }
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
