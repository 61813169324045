import { Component, OnInit, Input } from '@angular/core';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-cliente-list-contratos',
  templateUrl: './cliente-list-contratos.component.html',
  styleUrls: ['./cliente-list-contratos.component.scss'],
  animations:fuseAnimations
})
export class ClienteListContratosComponent implements OnInit {
  displayedColumns = ['situacaoContrato', 'dataEmissao', 'dataVencimento', 'valorLiquido'];
  @Input() rows: any;
  contratos: Contract[] ;

  selected: Array<any>;
  timeout: any;

  interval;

  constructor() {
    this.selected = new Array<any>();

    this.contratos = this.rows;
  }

  ngOnInit(){}

 

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    
  }
  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
  }

  generateMask(value: number){
    return value.toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' });
  }

}
