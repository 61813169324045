export enum SituationTransfer {
    GERADA  = 0,
    CONFIRMADA = 1,
    CANCELADA = 2,
    CONFIRMACAO_PENDENTE = 3,
    REPASSADA_AO_CLIENTE = 4,
    NAO_REPASSADA_AO_CLIENTE = 5,
    REPASSE_PENDENTE = 6,
    REPASSE_CONFIRMADO = 7,
    REPASSE_NAO_CONFIRMADO = 8,
    PARCELA_RECEBIDA = 9,
    PARCELA_NAO_RECEBIDA = 10,
    PARCELA_PENDENTE = 11,
    PARCELA_CONFIRMADA = 12,
    RECEBIMENTO_DEPOSITO = 13,
    REGULARIZADA = 14,
    COMPLEMENTO_TRANSFERIDO = 17

}
