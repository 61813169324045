import { Component, OnInit, Inject, ViewChild, Input, Renderer, AfterViewInit } from '@angular/core';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { SituationTransfer } from '../../model/enums/situation-transfer.enum';

export interface DialogData {
  confirmation: any;
  origem: boolean;
  situacao: string;
  id: number;
  type: string;
  title: string;
}
export namespace Situation {
  export function values(): any {
    return Object.keys(SituationTransfer).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

@Component({
  selector: 'app-analisar-efetivacao',
  templateUrl: './analisar-efetivacao.component.html',
  styleUrls: ['./analisar-efetivacao.component.scss']
})
export class AnalisarEfetivacaoComponent implements OnInit, AfterViewInit {

  situaces = Situation;
  confirmation: any;
  dialogRef: any;
  titulo: string;
  titleHeader: string;
  origem: any;
  message: string;
  situacao: string;
  disabledButton: boolean;
  dataRest;
  public formaRepasse: number;
  title = '';
  formaRepasseTransf: number;

  @ViewChild('radio') radio;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private transferenciaService: TransferenciaService,
    private matDialogRef: MatDialogRef<AnalisarEfetivacaoComponent>,
    private _matDialog: MatDialog,
    private renderer: Renderer) {

    this.origem = data.origem;
    this.title = data.title;
  }

  ngAfterViewInit(): void {
  
  }

  ngOnInit(): void {
    // tslint:disable-next-line:triple-equals
    if (this.data.confirmation.formaRepasse == 'COBRANCA') {
      this.formaRepasse = 0;
      this.formaRepasseTransf = 0;
    // tslint:disable-next-line:triple-equals
    } else if (this.data.confirmation.formaRepasse == 'COMPLEMENTO') {
      this.formaRepasse = 1;
      this.formaRepasseTransf = 1;
    } else {
      this.formaRepasse = 2;
      this.formaRepasseTransf = 2;
    }
  
  }

  confirmTransfer(): void {
    this.disabledButton = true;
    
    this.confirmation = {
      id: this.data.confirmation.id,
      formaRepasse: this.formaRepasse
    };

    if( this.data.confirmation.tipo === 'TGERE') {
      this.transferenciaService.analisarEfetivacaoRepasseDinheiro(this.confirmation).subscribe(
        (data) => {
          this.rest(data);
        }
      );
    } else if (this.data.confirmation.tipo === 'TREFU') {
      console.log(this.confirmation);
      
      this.transferenciaService.alterarTipoRepasse(this.confirmation).subscribe(
        (data) => {
          this.rest(data);
        }
      );
    }
     else {
      this.transferenciaService.analisarEfetivacaoRepasse(this.confirmation).subscribe(
        (data) => {
          this.rest(data);
        }
      );
    }
  }

  cancelar(): void {
    this.matDialogRef.close(this.data.confirmation.id);

  }

  rest(data: any): void {
    if (data.success === true) {
      this.matDialogRef.close(data);
      this.messageDialogSucess('Análise realizada com sucesso!');
    } else {
      this.matDialogRef.close();
      this.messageDialogError(data);
    }
    // tslint:disable-next-line:no-unused-expression
    (onerror) => {
      console.log(onerror);
    };
  }

  messageDialogSucess(mensagem: any): void {
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: {
        type: 'success',
        title: 'Sucesso!',
        text: `${mensagem}`
      }
    });
    setTimeout(() => this.dialogRef.close(), 2700);
  }

  messageDialogError(data: any): void {
    if (data.messages[0].text == null) {
      this.dataRest = {
        type: 'error',
        title: 'Error!',
      };
    } else {
      this.dataRest = {
        type: 'info',
        title: 'Informação!',
        text: `${data.messages[0].text}`
      };
    }
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: this.dataRest
    });
    setTimeout(() => this.dialogRef.close(), 2700);
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

}
