import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../../../shared/model/user/user.model';
import { SGF_API } from '../../../../../../shared/api/sgf.api';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private host = `${SGF_API}` + 'sgf/api/public/usuario';
  private posto: string;
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  findAll(): Observable<any> | any{
    return this.httpClient.get<User[]>(this.host)
    .pipe(
      catchError(this.handleError)
    );
  }

  pageNomeUsuario(size: number, page: number, nomeUsuario: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?filters=%26nomeUsuario%3D%25${nomeUsuario}%25&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  page(size: number, page: number, ativo: boolean): Observable<any> {
    
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&ativo=${ativo}&${this.posto}`)
    .pipe(
      catchError(this.handleError)
    );

  }

  pageAll(size: number, page: number): Observable<any>  {
    
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}`)
    .pipe(
      catchError(this.handleError)
    );
  
  }

  add(user: any): Observable<any> | any {   
    const body = JSON.stringify(user);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUser(user: User): Observable<any> | any{
    const body = JSON.stringify(user);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  ativar(id: number): Observable<any>{
    return this.httpClient.put(`${this.host}/ativar/${id}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  desativar(id: number): Observable<any>{
    return this.httpClient.put(`${this.host}/desativar/${id}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findById(id: number): Observable<any>{
    return this.httpClient.get(`${this.host}/${id}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  alterarSenha(senha: string): Observable<any>{
    const token = sessionStorage.getItem('Authorization')
    return this.httpClient.put(`${this.host}/${token}/alteracaosenha`, senha, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsPdf(token?: string): any {
      return {headers: new HttpHeaders({
          'Content-Type': 'application/pdf',
          'Authorization': ''
        })
      };
    }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

}
