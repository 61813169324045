import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../shared/api/sgf.api';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private pdfRelatorioComissao = `${SGF_API}` + 'global/api/public/funcionario/imprimir-relatorio-comissao';
  private pdfRelatorioTitulos = `${SGF_API}` + 'global/api/public/funcionario/relatorio_titulos_receber_funcionario';
  private pdfRelatorioTitulosGeral = `${SGF_API}` + 'global/api/public/funcionario/relatorio_titulos_receber_geral';
  private pdfRelatorioCaixaDiarioGeral = `${SGF_API}` + 'sgf/api/public/caixa/relatorio_caixa_diario_geral';
  private pdfRelatorioCaixaDiarioGeralResumido = `${SGF_API}` + 'sgf/api/public/caixa/relatorio_caixa_diario_geral_resumido';
  private pdfRelatorioCaixaDiarioFuncionario = `${SGF_API}` + 'sgf/api/public/caixa/relatorio_caixa_diario_funcionario';
  private pdfFichaCadastral = `${SGF_API}sgf/api/public/visita/gerar-ficha-cadastral`;

  constructor(private httpClient: HttpClient) { }

  getRelatorioComissao(datas: string, postoId: any, equipe: number, rotaDescricao: number, usuario: any): any {
    return this.httpClient.get(`${this.pdfRelatorioComissao}?posto=${postoId}&datas=${datas}&equipe=${equipe}&rota=${rotaDescricao}&usuario=${usuario}`, { responseType: 'blob' });
  }

  getRelatorioTitulos(datas: string, postoId: any): any {
    return this.httpClient.get(`${this.pdfRelatorioTitulos}/${postoId}/${datas}`, { responseType: 'blob' });
  }

  getRelatorioTitulosGeral(datas: string, postoId: any): any {
    return this.httpClient.get(`${this.pdfRelatorioTitulosGeral}/${postoId}/${datas}`, { responseType: 'blob' });
  }

  getRelatorioCaixaDiarioGeral(data: number, postoId: any): any {
    return this.httpClient.get(`${this.pdfRelatorioCaixaDiarioGeral}?postoId=${postoId}&data=${data}`, { responseType: 'blob' });
  }

  getRelatorioCaixaDiarioGeralResumido(data: number, postoId: any): any {
    return this.httpClient.get(`${this.pdfRelatorioCaixaDiarioGeralResumido}?postoId=${postoId}&data=${data}`, { responseType: 'blob' });
  }

  getRelatorioCaixaDiarioFuncionario(data: number, postoId: any, funcionarioId: number): any {

    let funcId = '';
    if(funcionarioId != null && funcionarioId != 0) {
      funcId = `&funcionarioId=${funcionarioId}`;
    } else if (funcionarioId == 0)  {
      funcId = `&somenteRotas=true`;
    }
    return this.httpClient.get(`${this.pdfRelatorioCaixaDiarioFuncionario}?postoId=${postoId}&data=${data}${funcId}`, { responseType: 'blob' });
  }

  getFichaCadastral(pf: any, nome: string): Observable<Blob> {
    return this.httpClient.get(`${this.pdfFichaCadastral}?nome=${nome}&pf=${pf.id === 0}`, {responseType: 'blob'});
  }
}
