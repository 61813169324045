import { Injectable } from '@angular/core';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveCaixasAbertosService {

  constructor(private caixaService: CaixaService) { }

  situacao = 'ABERTO,LIBERADO';

  resolve(): Observable<any> {
    return this.caixaService.pageSituacao(this.situacao, '', 10, 1).pipe(
      take(1),
      mergeMap(transf => {
        return of(transf['object']);
      })
    );
  }
}
