import { Injectable } from '@angular/core';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolvePendenciaService {

  constructor(private caixaService: CaixaService) { }

  resolve(): Observable<any> {
    return this.caixaService.pageSituacao('PENDENTE,PENDENTE_PARCIAL,NAO_REGULARIZADO', 'cdf', 10 , 1, { caixaAberto: true }).pipe(
      take(1),
      mergeMap(rest => {
      return of(rest['object']);
      })
    );
  }
}
