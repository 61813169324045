import { ResolveConfirmacaoPedenciaService } from './../components/confirmacao-pendencia/resolve/resolve-confirmacao-pedencia.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResolveDespesasService } from '../components/despesas/resolve/resolve-despesas.service';
import { ResolveCaixaControleService } from '../components/caixa-controle/resolve/resolve-caixa-controle.service';
import { ResolveCaixaCobrancaService } from '../components/caixa-cobranca/resolve/resolve-caixa-cobranca.service';
import { ResolveCaixaDividaFuncionarioService } from '../components/caixa-divida-funcionario/resolve/resolve-caixa-divida-funcionario.service';
import { ResolveCaixaFolhaPagamentoService } from '../components/caixa-folha-pagamento/resolve/resolve-caixa-folha-pagamento.service';
import { ResolveCaixaFuncionarioService } from '../components/caixa-funcionario/resolve/resolve-caixa-funcionario.service';
import { ResolveCaixaPagamentoFuncionarioService } from '../components/caixa-pagamento-funcionario/resolve/resolve-caixa-pagamento-funcionario.service';
import { ResolveCaixaProvisaoService } from '../components/caixa-provisao/resolve/resolve-caixa-provisao.service';
import { ResolveCaixaRepasseService } from '../components/caixa-repasse/resolve/resolve-caixa-repasse.service';
import { ResolveCaixaDepositoCobrancaService } from '../components/caixa-deposito-cobranca/resolve/resolve-caixa-deposito-cobranca.service';
import { ResolveCaixaGastosGeraisService } from '../components/caixa-gastos-gerais/resolve/resolve-caixa-gastos-gerais.service';
import { ResolveDespesaFuncionarioService } from '../components/caixa-despesa-funcionario/resolve/resolve-despesa-funcionario.service';
import { ResolveFolhaPagamentoService } from '../components/folha-pagamento/resolve/resolve-folha-pagamento.service';
import { ResolveCaixasAbertosService } from '../components/caixas-abertos/resolve/resolve-caixas-abertos.service';
import { ResolveAgenciaService } from '../components/agencia/resolve/resolve-agencia.service';
import { ResolveBancoService } from '../components/banco/resolve/resolve-banco.service';
import { ResolveContaService } from '../components/conta/resolve/resolve-conta.service';
import { ResolveMaquinaCartaoService } from '../components/maquina-cartao/resolve/resolve-maquina-cartao.service';
import { ResolvePendenciaService } from '../components/pendencia/resolve/resolve-pendencia.service';
import { ResolveLiberacaoSaidasService } from '../components/liberacoes-saidas/resolve/resolve-liberacao-saidas.service';
import { ResolveConfirmarRecebimentosService } from '../components/confimar-recebimentos/resolve/resolve-confirmar-recebimentos.service';
import { ResolveLancamentoEntradasService } from '../components/lancamentos-entradas/resolve/resolve-lancamento-entradas.service';
import { ResolveCaixaGeralDashboardService } from '../components/caixa-geral/resolve/resolve-caixa-geral-dashboard.service';
import { ResolveAcompanhamentoRecebimentosService } from '../components/acompanhamento-recebimentos/resolve/resolve-acompanhamento-recebimentos.service';
import { ResolveAnaliseSobraService } from '../components/analise-sobra/resolve/resolve-analise-sobra.service';
import { ResolveConfirmarCreditoSobraService } from '../components/confirmar-credito-sobra/resolve/resolve-confirmar-credito-sobra.service';
import { ResolveCaixaSangriaService } from '../components/caixa-sangria/resolve/resolve-caixa-sangria.service';
import { ResolveCaixaDespesasService } from '../components/caixa-despesas/resolve/resolve-caixa-despesas.service';
import { ResolveFaltaService } from '../components/falta/resolve/resolve-falta.service';
import { ResolveFolhaCobrancaService } from '../components/folha-cobranca/resolve/resolve-folha-cobranca.service';
import { ResolveRepasseService } from '../components/repasses/resolve/resolve-repasse.service';
import { ResolveMotivoContratoCanceladoService } from '../components/motivo-contrato-cancelado/resolve/resolve-motivo-contrato-cancelado.service';

const FinancialModuleRoutes: Routes = [

  {
    path: 'despesas',
    loadChildren: '../components/despesas/despesas.module#DespesasModule',
    resolve: { rows: ResolveDespesasService },
    runGuardsAndResolvers: 'always',

  },

  {
    path: 'folhas-pagamentos',
    loadChildren: '../components/folha-pagamento/folha-pagamento.module#FolhaPagamento',
    resolve: { rows: ResolveFolhaPagamentoService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-geral',
    loadChildren: '../components/caixa-geral/caixa-geral.module#CaixaGeral',
    resolve: {
      dashboard: ResolveCaixaGeralDashboardService,
    },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-funcionario',
    loadChildren: '../components/caixa-funcionario/caixa-funcionario.module#CaixaFuncionario',
    resolve: { rows: ResolveCaixaFuncionarioService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-controle',
    loadChildren: '../components/caixa-controle/caixa-controle.module#CaixaControle',
    resolve: { rows: ResolveCaixaControleService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-repasse',
    loadChildren: '../components/caixa-repasse/caixa-repasse.module#CaixaRepasse',
    resolve: { rows: ResolveCaixaRepasseService },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'caixa-cobranca',
    loadChildren: '../components/caixa-cobranca/caixa-cobranca.module#CaixaCobranca',
    resolve: { rows: ResolveCaixaCobrancaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-sangria',
    loadChildren: '../components/caixa-sangria/caixa-sangria.module#CaixaSangria',
    resolve: { rows: ResolveCaixaSangriaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-divida-funcionario',
    loadChildren: '../components/caixa-divida-funcionario/caixa-divida-funcionario.module#CaixaDividaFuncionario',
    resolve: { rows: ResolveCaixaDividaFuncionarioService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-folha-pagamento',
    loadChildren: '../components/caixa-folha-pagamento/caixa-folha-pagamento.module#CaixaFolhaPagamento',
    resolve: { rows: ResolveCaixaFolhaPagamentoService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-pagamento-funcionario',
    loadChildren: '../components/caixa-pagamento-funcionario/caixa-pagamento-funcionario.module#CaixaPagamentoFuncionario',
    resolve: { rows: ResolveCaixaPagamentoFuncionarioService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-provisao',
    loadChildren: '../components/caixa-provisao/caixa-provisao.module#CaixaProvisao',
    resolve: { rows: ResolveCaixaProvisaoService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-deposito-cobranca',
    loadChildren: '../components/caixa-deposito-cobranca/caixa-deposito-cobranca.module#CaixaDepositoCobranca',
    resolve: { rows: ResolveCaixaDepositoCobrancaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-gastos-gerais',
    loadChildren: '../components/caixa-gastos-gerais/caixa-gastos-gerais.module#CaixaGastosGerais',
    resolve: { rows: ResolveCaixaGastosGeraisService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-despesa',
    loadChildren: '../components/caixa-despesas/caixa-despesas.module#CaixaDespesas',
    resolve: { rows: ResolveCaixaDespesasService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixa-despesa-funcionario',
    loadChildren: '../components/caixa-despesa-funcionario/caixa-despesa-funcionario.module#CaixaDespesaFuncionario',
    resolve: { rows: ResolveDespesaFuncionarioService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'repasses',
    loadChildren: '../components/repasses/repasses.module#RepassesModule',
    resolve: { rows: ResolveRepasseService },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pendencias',
    loadChildren: '../components/pendencia/pendencia.module#PendenciaModule',
    resolve: { rows: ResolvePendenciaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'caixas-abertos',
    loadChildren: '../components/caixas-abertos/caixas-abertos.module#CaixasAbertosModule',
    resolve: { rows: ResolveCaixasAbertosService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'agencias',
    loadChildren: '../components/agencia/agencia.module#AgenciaModule',
    resolve: { rows: ResolveAgenciaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'bancos',
    loadChildren: '../components/banco/banco.module#BancoModule',
    resolve: { rows: ResolveBancoService }
  },
  {
    path: 'contas',
    loadChildren: '../components/conta/conta.module#ContaModule',
    resolve: { rows: ResolveContaService },

  },
  {
    path: 'maquina-cartao',
    loadChildren: '../components/maquina-cartao/maquina-cartao.module#MaquinaCartaoModule',
    resolve: { rows: ResolveMaquinaCartaoService }
  },
  {
    path: 'liberacoes-saidas',
    loadChildren: '../components/liberacoes-saidas/liberacoes-saidas.module#LiberacoesSaidasModule',
    resolve: { rows: ResolveLiberacaoSaidasService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'lancamentos-entradas',
    loadChildren: '../components/lancamentos-entradas/lancamentos-entradas.module#LancamentosEntradasModule',
    resolve: { rows: ResolveLancamentoEntradasService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'confirmar-recebimentos',
    loadChildren: '../components/confimar-recebimentos/confirmar-recebimentos.module#ConfirmarRecebimentosModule',
    resolve: { rows: ResolveConfirmarRecebimentosService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'acompanhamento-recebimentos',
    loadChildren: '../components/acompanhamento-recebimentos/acompanhamento-recebimentos.module#AcompanhamentoRecebimentosModule',
    resolve: { rows: ResolveAcompanhamentoRecebimentosService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'confirmacao-pendencia',
    loadChildren: '../components/confirmacao-pendencia/confirmacao-pendencia.module#ConfirmacaoPendenciaModule',
    resolve: { rows: ResolveConfirmacaoPedenciaService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'arquivo-retorno',
    loadChildren: '../components/arquivo-retorno/arquivo-retorno.module#ArquivoRetornoModule',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'analise-sobra',
    loadChildren: '../components/analise-sobra/analise-sobra.module#AnaliseSobraModule',
    resolve: { rows: ResolveAnaliseSobraService },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'confirmar-credito-sobra',
    loadChildren: '../components/confirmar-credito-sobra/confirmar-credito-sobra.module#ConfirmarCreditoSobraModule',
    resolve: { rows: ResolveConfirmarCreditoSobraService },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'faltas',
    loadChildren: '../components/falta/falta.module#FaltaModule',
    resolve: { rows: ResolveFaltaService },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'folha-cobranca',
    loadChildren: '../components/folha-cobranca/folha-cobranca.module#FolhaCobrancaModule',
    resolve: { rows: ResolveFolhaCobrancaService },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'motivo-contrato-cancelado',
    loadChildren: '../components/motivo-contrato-cancelado/motivo-contrato-cancelado.module#MotivoContratoCanceladoModule',
    resolve: { rows: ResolveMotivoContratoCanceladoService },
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(FinancialModuleRoutes)
  ],
  exports: [RouterModule],
  providers: [

  ]
})
export class FinancialRoutingModule { }
