import { Component, OnInit, Inject } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';

@Component({
  selector: 'app-mudar-responsavel-form',
  templateUrl: './mudar-responsavel-form.component.html',
  styleUrls: ['./mudar-responsavel-form.component.scss']
})
export class MudarResponsavelFormComponent implements OnInit {

  form: FormGroup;
  employee: Array<Employee>;
  title: string;
  disabledButton: boolean;
  private _onDestroy = new Subject<void>();

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  
  constructor(private employeeService: EmployeeService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<MudarResponsavelFormComponent>,
              @Inject(MAT_DIALOG_DATA) public _data: any) { 

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    });

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'repasse') {
    this.title =  'Alterar Responsável Repasse';
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'contrato'){
      this.title =  'Alterar Responsável Contrato';
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'filter'){
      this.title = 'Filtrar Por Responsável';
    // tslint:disable-next-line:triple-equals
    } else if (this._data.action == 'transferir'){
      this.title = 'Transferir Responsável';
    } else{
      this.title = 'Alterar Responsável';
    }
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.form = this.formBuilder.group({
      funcionario : [null]
    });
  }

  private filterPerfils(): void {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(value): void {
    this.disabledButton = true;
    this.dialogRef.close(value);
  }
}
