import { Injectable } from '@angular/core';
import { FolhaCobrancaService } from '../../../services/folha-cobranca/folha-cobranca.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResolveMotivoContratoCanceladoService {


    constructor(private folhaCobrancaService: FolhaCobrancaService) {}

    resolve(): Observable<any> {
        return this.folhaCobrancaService.findAll(10, 1).pipe(
          take(1),
          mergeMap(rest => {
              return of(rest['object']);
          })
        )
    }
}
