import { PersonModel } from '../person/persons.model';
import { RoleModel } from '../role/role.model';
import { BenefitsModel } from '../benefits/benefits.model';
import { CommissionModel } from '../commission/commission.module';
import { DiscountModel } from '../discount/discount.module';
import { ImpostModel } from '../impost/impost.model';
import { EmployeeTeam } from '../employee-team/employee-team.model';
import { VehiclesModel } from '../vehicles/vehicles.model';
import { Posto } from '../posto/posto.model';
import { Rota } from '../rota/rota.model';
import { RegistrosPix } from '../registros-pix/registros-pix.model';


export class Employee{
    id: number;
    isCpf: boolean;
    ativo?: boolean;
    cargo?: RoleModel;
    pessoa: PersonModel;
    listBeneficio: Array<BenefitsModel>;
    listComissao: Array<CommissionModel>;
    listDesconto: Array<DiscountModel>;
    listImposto: Array<ImpostModel>;
    listEquipes: Array<EmployeeTeam>;
    listVeiculo: Array<VehiclesModel>
    posto: Posto;
    rota: Rota;
    registroPix: RegistrosPix;


    constructor(obj?: any){
        this.id = obj.id || null;
        this.isCpf = obj.isCpf || true;
        this.ativo = obj.ativo || true;
        this.cargo = obj.cargo || null;
        this.pessoa = new PersonModel(obj.pessoa) ||  new PersonModel({});
        this.listBeneficio = obj ? obj.listBeneficio : null;
        this.listComissao = obj ? obj.listComissao : null;
        this.listDesconto = obj ? obj.listDesconto : null;
        this.listImposto = obj ? obj.listImposto : null;
        this.listEquipes = obj ? obj.listEquipes : null;
        this.listVeiculo = obj ? obj.listVeiculo : null;
        this.posto = obj ? obj.posto : null;
        this.rota = obj ? obj.rota : null;
        this.registroPix = obj ? obj.registroPix : null;

    }
}
