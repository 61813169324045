import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';



@Injectable({
  providedIn: 'root'
})
export class ResolveInProgressService implements Resolve<any> {

  constructor(
    private contractService: ContractsService) { }

  resolve(): Observable<any> {
    return this.contractService.pageContratosGraphQL('EM_ANDAMENTO,EM_ATRASO,PENDENTE,RENEGOCIADO',10,1,'+dataVencimento').pipe(
      take(1),
      mergeMap(res => {
        return of(res['data']['findAllPageable']);
      })
    );
  }

}
