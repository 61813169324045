import { ContractType } from '../enums/contract-type.enum';
import { Employee } from '../employee/employee.model';
import { CustomersModel } from '../customers/customers-model';
import { MaquinaCartao } from '../maquina-cartao/maquina-cartao.model';
import { Parcela } from '../parcela/parcela.model';
import { VisitModel } from '../visit/visit-model';

export class Contract {

    id: number;
    cobrador: Employee;
    dataEmissao: Date;
    dataCancelamento: Date
    situacaoContrato: string;
    valorLiquido: number;
    dataVencimento: Date;
    vendedor: Employee;
    visita: VisitModel;
    responsavel: Employee;
    responsavelRepasse: Employee;
    taxaJuros: number;
    taxaJurosMora: number;
    parecerCancelamento: string;
    parecerLiquidacao: string;
    maquinaCartao: MaquinaCartao;
    saldoPago: number;
    saldoAReceber: number;
    listParcela: Parcela[];
    maquinas: MaquinaCartao[];
    numeroSeries: string;
    lancamento: boolean;
    valorExcedenteAnterior: number;
    contratoOrigem: number;
    adiantamento: boolean;
    valorPagoBordero: number;
    valorJuros: number;
    valorContrato: number;
    dataFinalizacao: string;
    dataLiberacao: string;
    valorBruto: number;
    dataReagendamento: Date;

    somaReagendamentos?: any;
    diferencaDias?: number;
    isSelected?: boolean;

    constructor(obj?: any){
        this.setAtributes(obj);
    }

    setAtributes(obj: Contract) {
        this.id = obj.id || null;
        this.cobrador = obj.cobrador || null;
        this.dataEmissao = obj.dataEmissao || new Date()
        this.dataCancelamento = obj.dataCancelamento || null;
        this.situacaoContrato = obj.situacaoContrato || 'NOVO';
        this.valorLiquido = obj.valorLiquido || null;
        this.dataVencimento = obj.dataVencimento || null;
        this.vendedor = obj.vendedor || null;
        this.visita = obj.visita || new VisitModel({});
        this.responsavel = obj.responsavel || null;
        this.responsavelRepasse = obj.responsavelRepasse || null;
        this.taxaJuros = obj.taxaJuros || null;
        this.parecerCancelamento = obj.parecerCancelamento || null;
        this.parecerLiquidacao = obj.parecerLiquidacao || null;
        this.numeroSeries = '';
        this.lancamento = false;
        this.dataFinalizacao = obj.dataFinalizacao || null;
        this.dataLiberacao = obj.dataLiberacao || null;
        this.somaReagendamentos = obj.somaReagendamentos || null;
        this.diferencaDias = obj.diferencaDias || null;
    }

}
