import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Employee } from '../../model/employee/employee.model';
import { takeUntil } from 'rxjs/operators';
import { SituationTransfer } from '../../model/enums/situation-transfer.enum';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import * as FileSaver from 'file-saver';
import { LoadProgressComponent } from '../../dialogs/load-progress/load-progress.component';

@Component({
  selector: 'app-relatorio-periodo',
  templateUrl: './relatorio-periodo.component.html',
  styleUrls: ['./relatorio-periodo.component.scss']
})
export class RelatorioPeriodoComponent implements OnInit {

  form: FormGroup
  funcionarios;
  situacoes = SituationTransfer
  isTodos: boolean;
  dialogMessages;
  disabledButton = false;
  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  constructor(private formBuild: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RelatorioPeriodoComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private funcionarioService : EmployeeService,
    private transferenciaService: TransferenciaService) {
    this.isTodos = false;
    this.funcionarioService.findAll().subscribe(data => {
      this.funcionarios = data.object.content;
      this.filteredEmployee.next(this.funcionarios.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEmployee();
        });
    })
  }

  ngOnInit() {
    console.log(this.isTodos)
    this.setForm();
  }


  private _onDestroy = new Subject<void>();

  private filterEmployee() {
    if (!this.funcionarios) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.funcionarios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.funcionarios.filter(func => func.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  getTodos(value) {
    this.setForm()

  }

  setForm() {
    this.form = this.formBuild.group({
      idResponsavel: [null, Validators.required],
      dataInicial: [null],
      dataFinal: [null],
      situacao: [null]
    })
  }

  gerar(value) {
    this.dialog.open(LoadProgressComponent, {
      disableClose: true
    })
    console.log(value.dataInicial)

    this.disabledButton = true;
    let dateInicio = value.dataInicial ? Date.parse(value.dataInicial) : null;
    value.dataInicial = value.dataInicial ? this.formatDate(new Date(dateInicio), '00:00:00') : null
  

    let dateFinal = value.dataFinal ? Date.parse(value.dataFinal) : null;
    value.dataFinal = value.dataFinal ? this.formatDate(new Date(dateFinal),'23:59:59') : null;
    
    parseInt(value.situacao)
    this.transferenciaService.imprimirCobrancasFuncionario(value).subscribe((file: Blob) => {
      FileSaver.saveAs(file, 'relatorioCobrancas.pdf');
      this.dialog.closeAll();

    },
      (onerror) => {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Nenhuma cobrança encontrada`
        }
        this.dialog.closeAll();
        this.feedback(this.dialogMessages)
      })
  }
  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.dialogRef.close();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private formatDate(date: Date, hour: string): string {
    var curr_date = date.getDate();
    var curr_month = date.getMonth() + 1; //Months are zero based
    var curr_year = date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date + "T" + hour + ".000Z";
  }

}
