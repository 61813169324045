import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-cliente-full-comercial-reference',
  templateUrl: './cliente-full-comercial-reference.component.html',
  styleUrls: ['./cliente-full-comercial-reference.component.scss'],
  animations:fuseAnimations
})
export class ClienteFullComercialReferenceComponent implements OnInit {

  telephones: FormArray;
  @Input() cliente: FormGroup;

  constructor(private formBuilder: FormBuilder) { }
  
  ngOnInit() { }

  get phoneForms(): FormArray {
    return this.cliente.get('listReferencia').get('referenciaComercial').get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });
    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  getErrorMessage(field: string) {
  }

}
