import { Injectable } from '@angular/core';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveFaltaService {

  constructor(private caixaService: CaixaService,
              private funcionarioService: EmployeeService) { }

  resolve(): Observable<any> {
    return this.funcionarioService.pageFuncionarioFalta(10 , 1).pipe(
      take(1),
      mergeMap(rest => {
      return of(rest['object']);
      })
    );
  }
}
