import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scrumboard-card',
  templateUrl: './scrumboard-card.component.html',
  styleUrls: ['./scrumboard-card.component.scss']
})
export class ScrumboardCardComponent implements OnInit {

  @Input() data;

  constructor(private route: Router) { }

  ngOnInit() {}

}
