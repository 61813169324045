import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Mask } from '@fagnerlima/ng-mask';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ClientePlanejamentoService } from 'app/main/customers/components/cliente-planejamento/services/cliente-planejamento.service';
import { Subject, ReplaySubject } from 'rxjs';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Team } from '../../model/team/team.model';
import { takeUntil } from 'rxjs/operators';
import { TeamService } from 'app/main/global/components/teams/services/team.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-cliente-planejamento-form',
  templateUrl: './cliente-planejamento-form.component.html',
  styleUrls: ['./cliente-planejamento-form.component.scss']
})
export class ClientePlanejamentoFormComponent implements OnInit {

  fortCartao = `${SGF_TYPE}` === 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` === 'DEV' ? true : false;
  form: FormGroup;
  mask = new Mask('00');
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  dialogMessages;

  title: string;
  button: string;

  loggedEmployee: Employee;
  equipes: Array<Team>;
  postos: Array<Posto>;
  funcionarioPosto: Posto;
  tipoPessoa: string;


  public equipeFilterControl: FormControl = new FormControl();
  public filteredEquipe: ReplaySubject<Team[]> = new ReplaySubject<Team[]>(1);

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  private _onDestroy = new Subject<void>();
  
  constructor(private formBuild: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ClientePlanejamentoFormComponent>,
    private clientePlanejamentoService: ClientePlanejamentoService,
    private employeeService: EmployeeService,
    private teamService: TeamService,
    private postoService: PostsService,
    private _matDialog: MatDialog,
    private utilsService: UtilsService) {
    this.title = this.data.action === 'alterarCliente' ? 'Alterar Cliente' : 'Adicionar Cliente';
    this.button = this.data.action === 'alterarCliente' ? 'Editar' : 'Salvar';
    
    if (this.funcionario.idFuncionario > 0) {
      // tslint:disable-next-line:radix
      this.employeeService.findById(parseInt(this.funcionario.idFuncionario) ).subscribe(result => {
        this.loggedEmployee = result.object;
      });
    }

    this.equipeFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterEquipe();
          });
  }

  ngOnInit(): void {

    this.setForm();

    let idPosto = null;

    this.tipoPessoa = this.data.cliente ? this.data.cliente.tipoPessoa : null ;

    // tslint:disable-next-line:triple-equals
    if (this.funcionario.idPosto != undefined) {
      idPosto = this.funcionario.idPosto;
    } else {
      idPosto = this.data.cliente ? this.data.cliente.posto.id : null;
    }

    if (this.funcionario.idPosto == null) {
      
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.postoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
      });

      this.getEquipes(idPosto);  

    } else {

      this.postoService.findById(this.funcionario.idPosto).subscribe(response => {
        this.funcionarioPosto = response.object;
      });

      this.getEquipes(idPosto);      
    }

  }


  getEquipes(idPosto: number): void {
    this.teamService.findByPostoId(idPosto).subscribe(data => {
      this.equipes = data.object;
      this.filteredEquipe.next(this.equipes.slice());
      this.equipeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEquipe();
        });
    });
  }

  setForm(): void {
    let posto = null;
    let equipe = null;

    if (this.data.action === 'alterarCliente') {
      posto = this.data.cliente.posto;
      equipe = this.data.cliente.equipe;
    }
    
    this.form = this.formBuild.group({
      nomeCliente: [this.data.cliente ? this.data.cliente.nomeCliente : null, Validators.required],
      equipe: equipe ? this.data.cliente.equipe.descricao : null,
      posto: posto ? this.data.cliente.posto.descricao : this.funcionarioPosto,
      endereco: [this.data.cliente ? this.data.cliente.endereco : null, Validators.required],
      data: [this.data.cliente ? new Date(this.data.cliente.data) : null, Validators.required],
      observacao: [this.data.cliente ? this.data.cliente.observacao : null]
    });
  }

  onClose(): void {
    this.matDialogRef.close();
  }

  onUpdate(value): void {
    delete value.cliente;
    if (this.data.action === 'alterarCliente') {
      this.updateContrato(value);
    } else {
      this.saveCliente(value);
    }

  }

  validarTipoPessoa(): boolean {
    if (this.tipoPessoa == null) {
      this.utilsService.feedbackInfo('Tipo Pessoa Obrigatório !', TimeMensagem.TIME_MENSAGEM);
      return true;
    }
    return false;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  private updateContrato(cliente): void {

    if (this.validarTipoPessoa()){
      return;
    }

    this.data.cliente.nomeCliente = cliente.nomeCliente;
    this.data.cliente.endereco = cliente.endereco;
    this.data.cliente.data = cliente.data;
    this.data.cliente.observacao = cliente.observacao;
    this.data.cliente.tipoPessoa = this.tipoPessoa;

    if (this.funcionarioPosto == null) {
      this.postos.forEach(att => {
        // tslint:disable-next-line:triple-equals
        if (att.descricao == cliente.posto) {
          this.data.cliente.posto = att;
        }
      });
    } else {
      this.data.cliente.posto = this.funcionarioPosto;
    }

    this.clientePlanejamentoService.editCliente(this.data.cliente).subscribe((data) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(data.object);

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CLIENTE', onerror);
      };
    });
  }

  private saveCliente(cliente): void {

    if (this.validarTipoPessoa()){
      return;
    }
    
    this.data.cliente = cliente;
    this.data.cliente.situacao = 'PLANEJADO';
    this.data.cliente.funcionario = this.loggedEmployee;
    this.data.cliente.tipoPessoa = this.tipoPessoa;

    if (this.funcionarioPosto == null) {
      this.postos.forEach(att => {
        // tslint:disable-next-line:triple-equals
        if (att.descricao == cliente.posto) {
          this.data.cliente.posto = att;
        }
      });
    } else {
      this.data.cliente.posto = this.funcionarioPosto;
    }

    this.equipes.forEach( att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == cliente.equipe) {
        this.data.cliente.equipe = att;
      }
    });

    this.clientePlanejamentoService.addCliente(this.data.cliente).subscribe((data) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(data.object);

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR CLIENTE', onerror);
      };
    });
  }

  getEquipesEVisitadores(posto: Posto): void {
    this.teamService.findByPostoId(posto.id).subscribe(data => {
      this.equipes = data.object;
      this.filteredEquipe.next(this.equipes.slice());
      this.equipeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEquipe();
        });
    });
  }


  private filterEquipe(): void {
    if (!this.equipes) {
      return;
    }
    let search = this.equipeFilterControl.value;
    if (!search) {
      this.filteredEquipe.next(this.equipes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEquipe.next(
      this.equipes.filter(equipe => equipe.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }
    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
