import {Component, OnInit, Inject} from '@angular/core';
import {MessageDialogComponent} from '../../components/message-dialog/message-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {TransferenciaService} from 'app/main/financial/services/transferencia/transferencia.service';
import {SituationTransfer} from '../../model/enums/situation-transfer.enum';
import {CobrancasService} from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import {UtilsService} from 'app/shared/util/utils.service';
import {TimeMensagem} from '../../model/enums/time-mensagem.enum';
import {DebitoFaltaService} from 'app/main/financial/services/debito-falta/debito-falta.service';
import { MotivoDesistenciaContratoService } from 'app/main/financial/services/motivo-desistencia-contrato/motivo-desistencia-contrato.service';
import { MotivoDesistenciaContrato } from '../../model/motivo-desistencia-contrato/motivo-desistencia-contrato.model';

export interface DialogData {
    funcionarioId: number;
    confirmation: any;
    origem: boolean;
    situacao: string;
    id: number;
    type: string;
}

export namespace Situation {
    export function values(): any {
        return Object.keys(SituationTransfer).filter(
            (type) => isNaN(<any>type) && type !== 'values'
        );
    }
}

@Component({
    selector: 'app-confirmar-recebimento',
    templateUrl: './confirmar-recebimento.component.html',
    styleUrls: ['./confirmar-recebimento.component.scss']
})
export class ConfirmarRecebimentoComponent implements OnInit {

    situaces = Situation;
    confirmation: any;
    dialogRef: any;
    titulo: string;
    titleHeader: string;
    origem: any;
    message: string;
    situacao: string;
    disabledButton: boolean;
    dataRest;
    mensagem: any;

    tipoRepasseNaoRepassado: number;
    motivoDesistencia = null;
    motivos = [];
    motivosDesistencia = [];
    motivosReagendamento = [];


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private transferenciaService: TransferenciaService,
        private debitoFaltaService: DebitoFaltaService,
        private matDialogRef: MatDialogRef<ConfirmarRecebimentoComponent>,
        private _matDialog: MatDialog,
        private cobrancaService: CobrancasService,
        private motivoDesistenciaContratoService: MotivoDesistenciaContratoService,
        private utilsService: UtilsService) {

        this.origem = data.origem;
    }

    ngOnInit(): void {
        this.changeTitleHeader();
        this.motivoDesistenciaContratoService.findAll().subscribe(response => {
            if (response.success) {
                this.motivos = response.object;
                this.motivosDesistencia = this.motivos.filter((motivo: MotivoDesistenciaContrato) => motivo.tipo === 'DESISTENCIA');
                this.motivosReagendamento = this.motivos.filter((motivo: MotivoDesistenciaContrato) => motivo.tipo === 'REAGENDAMENTO');
            }
        });
    }

    confirmTransfer(): any {
        this.disabledButton = true;

        if (this.data.situacao === 'NAO_REPASSADA_AO_CLIENTE') {
            if (this.tipoRepasseNaoRepassado == null) {
                this.utilsService.feedbackInfo(`Tipo de Repasse Obrigatório!`, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                return;
            }

            // tslint:disable-next-line:triple-equals
            if (this.motivoDesistencia == null) {
                this.utilsService.feedbackInfo(`Motivo Desistência Obrigatório!`, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                return;
            }
        }

        if (this.data.situacao === 'NAO_REPASSADA_AO_CLIENTE_ENTRADA') {
            if (this.tipoRepasseNaoRepassado == null) {
                this.utilsService.feedbackInfo(`Tipo de Repasse Obrigatório!`, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                return;
            }

            // tslint:disable-next-line:triple-equals
            if (this.motivoDesistencia == null) {
                this.utilsService.feedbackInfo(`Motivo Desistência Obrigatório!`, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                return;
            }
        }

        let desistencia = false;
        // tslint:disable-next-line:triple-equals
        if (this.tipoRepasseNaoRepassado != null && this.tipoRepasseNaoRepassado == 1) {
            desistencia = true;
        }

        let reagendamento = false;
        // tslint:disable-next-line:triple-equals
        if (this.tipoRepasseNaoRepassado != null && this.tipoRepasseNaoRepassado == 2) {
            reagendamento = true;
        }

        this.confirmation = {
            id: this.data.confirmation.id,
            situacao: this.data.situacao,
            desistencia: desistencia,
            reagendamento: reagendamento,
            motivoDesistenciaContrato: this.motivoDesistencia
        };

        // tslint:disable-next-line:triple-equals
        if (this.confirmation.situacao === 'COMPLEMENTO_TRANSFERIDO') {
            this.transferenciaService.transferirComplemento(this.confirmation.id).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Complemento transferido com sucesso');
                    }
                }
            );
        }


        // tslint:disable-next-line:triple-equals
        if (this.confirmation.situacao === 'COMPLEMENTO_RECEBIDO') {
            this.transferenciaService.receberComplemento(this.confirmation.id).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Complemento Recebido com sucesso');
                    }
                }
            );
        }

        if (this.confirmation.situacao === 'PENDENTE_ANALISE_SANGRIA') {
            this.transferenciaService.receberComplementoDeSangriaDePendencia(this.confirmation.id).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Sangria recebida com sucesso');
                    }
                }
            );
        }

        if (this.confirmation.situacao === 'SOBRA_CONFIRMADA') {
            this.transferenciaService.confirmarLancamentoSobra(this.confirmation.id).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Sobra confirmada com sucesso');
                    }
                }
            );
        }

        if (this.confirmation.situacao === 'FALTA_CONFIRMADA') {
            this.debitoFaltaService.confirmarDebitoFalta({funcionarioId: this.data.funcionarioId, transferenciaId: this.confirmation.id}).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Falta confirmada com sucesso');
                    }
                }
            );
        }

        if (this.data.confirmation.natureza === 'COMPLEMENTO') {
            this.transferenciaService.transferirComplementoFuncionario(this.confirmation.id).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Complemento transferido com sucesso');
                    }
                }
            );
        }

        if (this.data.situacao === 'REPASSADA_AO_CLIENTE_ENTRADA' || this.data.situacao === 'NAO_REPASSADA_AO_CLIENTE_ENTRADA') {

            this.transferenciaService.alterarSituacaoRepasseEntrada(this.confirmation).subscribe(
                data => {
                    this.rest(data, 'Situação alterada com sucesso');
                }
            );

        } else if (this.data.situacao === 'REPASSADA_AO_CLIENTE' || this.data.situacao === 'NAO_REPASSADA_AO_CLIENTE') {
            // tslint:disable-next-line:triple-equals
            if (this.data.type == 'pendencia') {
                this.transferenciaService.atualizarSituacaoTransferencia(this.confirmation).subscribe(
                    (data) => {
                        this.rest(data);
                    }
                );
            } else {
                this.transferenciaService.confirmRepasse(this.confirmation).subscribe(
                    (data) => {
                        this.rest(data);
                    }
                );
            }
        } else if (this.data.situacao === 'PARCELA_NAO_RECEBIDA') {
            const parcela = {
                id: this.data.confirmation.parcela.id,
                ordem: this.data.confirmation.parcela.ordem,
                valor: this.data.confirmation.parcela.valor,
                valorPago: 0,
                dataVecimento: this.data.confirmation.parcela.dataVencimento,
                contrato: {id: this.data.confirmation.parcela.contrato.id},
                responsavel: {id: this.data.confirmation.parcela.responsavel.id},
                turno: this.data.confirmation.parcela.turno,
                situacaoParcela: 'COBRADA'
            };
            this.cobrancaService.update(parcela).subscribe(data => {
                if (data.success) {
                    this.transferenciaService.confirmCobrancaCliente(this.confirmation).subscribe(
                        (result) => {
                            this.rest(result);
                        }
                    );
                }
            });
        } else if (['COMPLEMENTO_TRANSFERIDO', 'COMPLEMENTO_RECEBIDO', 'PENDENTE_ANALISE_SANGRIA', 'SOBRA_CONFIRMADA', 'FALTA_CONFIRMADA', 'FALTA_REJEITADA']
            .includes(this.confirmation.situacao) === false) {
            this.transferenciaService.confirmRecebimento(this.confirmation).subscribe(
                (result) => {
                    this.rest(result);
                }
            );
        }
    }

    cancelar(): void {

        if (this.data.situacao === 'FALTA_REJEITADA') {
            this.debitoFaltaService.rejeitarDebitoFalta({funcionarioId: this.data.funcionarioId, transferenciaId: this.data.confirmation.id}).subscribe(
                (result) => {
                    if (result.success === true) {
                        this.matDialogRef.close(result);
                        this.messageDialogSucess('Falta rejeitada com sucesso');
                    } else {
                        this.rest(result);
                    }
                }
            );
        } else if (this.data.confirmation.contrato != null) {

            const cancelarRepasseTO = {
                idTransferencia: this.data.confirmation.id,
                idContrato: this.data.confirmation.contrato
            };

            this.transferenciaService.cancelarRepasse(cancelarRepasseTO).subscribe(
                (result: any) => {
                    if (result.success === true) {
                        this.utilsService.feedbackSuccess('Cancelamento de Repasses Realizado com sucesso', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                    } else {
                        this.utilsService.feedbackSuccess(result.messagens[0].text, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
                    }
                });
        }

        this.matDialogRef.close(this.data.confirmation.id);
    }

    rest(data: any, successMessage?: string): void {
        if (data.success === true) {
            this.matDialogRef.close(data.object);
            this.messageDialogSucess(successMessage ? successMessage : data.messages[0].text);
        } else {
            this.matDialogRef.close();
            this.messageDialogError(data);
        }
        // tslint:disable-next-line:no-unused-expression
        (onerror) => {
            console.log(onerror);
        };
    }

    messageDialogSucess(mensagem: any): void {
        // tslint:disable-next-line:triple-equals
        if (this.data.origem == false) {
            this.message = 'Recebimento de transferência confirmado!';
        } else {
            this.message = 'Transferência confirmada!';
        }

        this.dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: {
                type: 'success',
                title: 'Sucesso!',
                text: `${mensagem}`
            }
        });
        setTimeout(() => this.dialogRef.close(), 5000);
    }

    messageDialogError(data: any): void {
        if (data.messages[0].text == null) {
            this.dataRest = {
                type: 'error',
                title: 'Error!',
            };
        } else {
            this.dataRest = {
                type: 'info',
                title: 'Informação!',
                text: `${data.messages[0].text}`
            };
        }
        this.dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: this.dataRest
        });
        setTimeout(() => this.dialogRef.close(), 5000);
    }

    onCancel(): void {
        this.matDialogRef.close();
    }


    changeTitleHeader(): void {
        // tslint:disable-next-line:triple-equals
        if (this.data.origem == false) {
            this.addTitulo(this.data.confirmation.origem.type);
            this.titleHeader = 'Recebimento';
        } else {
            this.addTitulo(this.data.confirmation.destino.type);
            this.titleHeader = 'Transferência';
        }
    }

    addTitulo(type): void {
        switch (type) {
            case 'CaixaControle':
                this.titulo = 'Caixa de Controle';
                break;
            case 'CaixaCobranca':
                this.titulo = 'Caixa de Cobrança';
                break;
            case 'CaixaDespesa':
                this.titulo = 'Caixa de Despesa';
                break;
            case 'CaixaFolhaPagamento':
                this.titulo = 'Caixa de Folha de Pagamento';
                break;
            case 'CaixaFuncionario':
                this.titulo = 'Caixa de Funcionário';
                break;
            case 'CaixaGeral':
                this.titulo = 'Caixa Geral';
                break;
            case 'CaixaRepasse':
                this.titulo = 'Caixa de Repasse';
                break;
            case 'CaixaPagamentoFuncionario':
                this.titulo = 'Caixa de Pedência Funcionário';
                break;
            case 'CaixaProvisao':
                this.titulo = 'Caixa de Provisão';
                break;
            case 'CaixaDepositoCobranca':
                this.titulo = 'Caixa de Depósito Cobrança';
                break;
            case 'CaixaGastosGerais':
                this.titulo = 'Caixa de Gastos Gerais';
                break;
            case 'CaixaGastosFuncionario':
                this.titulo = 'Caixa de Gastos Funcionário';
                break;
            case 'CaixaDividaFuncionario':
                this.titulo = 'Caixa de Gastos Funcionário';
                break;
        }
    }

}
