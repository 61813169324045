import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Parcela } from '../../model/parcela/parcela.model';

@Component({
  selector: 'app-liquidar-contrato',
  templateUrl: './liquidar-contrato.component.html',
  styleUrls: ['./liquidar-contrato.component.scss']
})
export class LiquidarContratoComponent implements OnInit {

  titleHeader: string;
  liquidacao: FormGroup;
  contract: any;
  disabledButton: boolean;
  valorALiquidar = 0;
  parcelas: Parcela[];

  /**
  * Constructor
  *
  * @param {MatDialogRef<CancelContractComponent>} matDialogRef
  * @param _data
  */
  constructor(
    public matDialogRef: MatDialogRef<LiquidarContratoComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.contract = this.data['data'];
    this.titleHeader = 'Liquidar Contrato';
    this.parcelas = this.contract.listParcela.sort((a, b) => a.ordem - b.ordem);
    if (this.contract.visita.quantidadeParcelas <= this.contract.listParcela.length ) {
      this.parcelas.forEach(parcela => {
        this.valorALiquidar = this.valorALiquidar + (parcela.valor - parcela.valorPago);
      });
    } else {
      this.valorALiquidar = this.parcelas[this.parcelas.length - 1].valor - this.parcelas[this.parcelas.length - 1].valorPago;
    }

    this.liquidacao = this.formBuilder.group({
      valorLiquidacao: ['', Validators.required],
      parecerLiquidacao: ['', Validators.required]
    });
  }

  onNoClick(data?): void {
    this.disabledButton = true;
    if (data) {
      const contract = data.data;
      contract.parecerLiquidacao = this.liquidacao.get('parecerLiquidacao').value;
      contract.valorLiquidacao = this.liquidacao.get('valorLiquidacao').value;
      this.matDialogRef.close(contract);
    } else {
      this.matDialogRef.close();
    }
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

}
