import { MarcaService } from './../../../global/components/marca/services/marca.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ActivityFieldService } from 'app/main/global/components/activity-field/services/activity-field.service';
import { GlobalService } from 'app/main/global/services/global.service';
import { ProdutoService } from 'app/main/global/components/produto/services/produto-service.service';
import { Product } from '../../model/product/product.model';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { ReplaySubject, Subject } from 'rxjs';
import { Marca } from '../../model/marca/marca.model';
import { Modelo } from '../../model/modelo/modelo.model';
import { takeUntil } from 'rxjs/operators';
import { ValueTransformer } from '@angular/compiler/src/util';
import { Posto } from '../../model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
interface DATA {
  produto: Product;
  action: string;
}
declare var google: any;

@Component({
  selector: 'app-produto-form', 
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.scss'],
  animations: fuseAnimations
})

export class ProdutoFormComponent implements OnInit {

  produto: FormGroup;
  titleHeader: string;
  maska: string;
  dialogMessages:any;
  disabledButton:boolean;
  marcas: Array<any>;
  marca: Marca;
  modelos: Array<any>;
  modelo: Modelo;
  marcaList: any[] = [];
  modeloList: any[] = [];

  public marcaFilterCtrl: FormControl = new FormControl();
  public filteredMarca: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public modeloFilterCtrl: FormControl = new FormControl();
  public filteredModelo: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public postoFilterCtrl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('nome') nome: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public _data: DATA,
    private matDialogRef: MatDialogRef<ProdutoFormComponent>,
    private produtoService: ProdutoService,
    private marcaService : MarcaService,
    private dialog: MatDialog,
    private postoService: PostsService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private activityFieldService: ActivityFieldService) {

    this.marcaService.findAll().subscribe(data => {
      this.marcaList = data.object.content;
    })

    this.produtoService.pageModelo().subscribe(data => {
      this.modeloList = data.object.content;
    })

    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Produto' : 'Edição de Produto';
   
  }

  ngOnInit() {
    this.setForm();
    this.marcaService.findAll().subscribe(data => {
      this.marcas = data.object.content;

      if (this._data.action == 'update') {
        if (this.produto.get('marca').value != null) {
          this.getModelo(data.object.content.filter(m => m.id === this.produto.get('marca').value)[0])
        }
      }

      this.filteredMarca.next(this.marcas.slice());
      this.marcaFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMarca();
        });
    });

  }

  ngAfterViewInit() {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }

  private _onDestroy = new Subject<void>();

  setForm() {
    let marca = null;
    if(this._data.action == 'update') {
      marca = this._data.produto.marca;
    }

    let modelo = null;
    if(this._data.action == 'update') {
      modelo = this._data.produto.modelo;
    }

    this.produto = this.formBuilder.group({
      id: this._data.produto ? this._data.produto.id : null,
      nome: [this._data.produto ? this._data.produto.nome : null],
      valor: [this._data.produto ? this._data.produto.valor : null],
      marca: [marca ? this._data.produto.marca.id : null, Validators.required],
      modelo: [modelo ? this._data.produto.modelo.id : null],
      descricao: [this._data.produto ? this._data.produto.descricao : null],
      quantidade: [this._data.produto ? this._data.produto.quantidade : null]
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onAdd(produto: any) {
    this.disabledButton = true;

    produto.marca = this.marcaList.filter(marca => marca.id == produto.marca)[0]
  
    produto.modelo = this.modeloList.filter(modelo => modelo.id == produto.modelo)[0]

    this.produtoService.add(produto).subscribe(
      (ondata: ReturnApi) => {
        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }
      },
      (onerror) => {
        console.log('ERROR PRODUTO', onerror);
      }
    )
  }

  onUpdate(produto: any) {
    this.disabledButton = true;

    produto.marca = this.marcaList.filter(marca => marca.id == produto.marca)[0]
  
    produto.modelo = this.modeloList.filter(modelo => modelo.id == produto.modelo)[0]

    this.produtoService.add(produto).subscribe(
      (ondata: ReturnApi) => {
        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }
      },
      (onerror) => {
        console.log('ERROR PRODUTO', onerror);
      }
    )
  }

  getModelo(marca) {
    if (typeof marca === 'number') {
      marca === this.marcaList.filter(m => m.id === marca)[0]
    }

    this.produtoService.modeloByMarcaId(marca.id).subscribe(data => {
      this.modelos = data.object.content.map(data => data);

      this.filteredModelo.next(this.modelos.slice());
      this.modeloFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterModelo();
        });
    });
  
  }

  private filterMarca() {
    if (!this.filteredMarca) {
      return;
    }

    let search = this.marcaFilterCtrl.value;
    if (!search) {
      this.filteredMarca.next(this.marcas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMarca.next(
      this.marcas.filter(marca => marca.nome.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterModelo() {
    if (!this.filteredModelo) {
      return;
    }

    let search = this.modeloFilterCtrl.value;
    if (!search) {
      this.filteredModelo.next(this.modelos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredModelo.next(
      this.modelos.filter(modelo => modelo.nome.toLowerCase().indexOf(search) > -1)
    );
  }  

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }
}
