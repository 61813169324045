import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { CommissionService } from '../services/commission.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveCommissionService implements Resolve<any> {

  
  constructor(
    private commissionService: CommissionService) { }

  resolve(): Observable<any> {
    return this.commissionService.findAll().pipe(
      take(1),
      mergeMap(commission => {
        return of(commission['object']);
      })
    );
  }

}
