import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from '../../../../shared/model/contract/contract.model';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Page } from 'app/main/shared/model/page/page.model';
import { BorderoFormComponent } from 'app/main/shared/components/bordero-form/bordero-form.component';
import { BorderoConfirmComponent } from 'app/main/shared/dialogs/bordero-confirm/bordero-confirm.component';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { CanceledSidebarComponent } from '../canceled-sidebar/canceled-sidebar.component';

@Component({
  selector: 'app-canceled-main',
  templateUrl: './canceled-main.component.html',
  styleUrls: ['./canceled-main.component.scss'],
  animations: fuseAnimations,
})
export class CanceledMainComponent implements OnInit {
  rows: Array<Contract> = [];
  itensAPI: Array<Contract>;
  filter: string;

  interval;
  page = new Page();
  public loading = false;
  dialogMessages;
  search = '';
  pageNumber;
  situacaoDefault = 
    'NOVO,RENOVADO,REPACTUADO,LIBERADO,EM_ANDAMENTO,FINALIZADO,CANCELADO,FINALIZADO_COM_DIVIDA,' +
    'FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO,EM_ATRASO,ANTECIPADO_BORDERO,PENDENTE,LIQUIDADO_ADMINISTRACAO,' +
    'RENEGOCIADO,CANCELADO,AGUARDANDO_CANCELAMENTO,GERADO';
  situacao = this.situacaoDefault;
  dataPeriodo;
  isPeriodo = false;
  valorTotalContratos = 0;

  lastFilter: string;
  lastSearchFilter;

  @ViewChild(CanceledSidebarComponent)
  private sidebar: CanceledSidebarComponent;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private contractService: ContractsService) {
    if (!this.hasLastFilters()) {
      this.route.data.subscribe((res) => {
        this.rows = res.rows.content;
        this.rows.forEach((contrato) => {
          contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
        });
        this.itensAPI = this.rows;
        const page = res.rows;
        page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
        this.setPage(page);
      });
    }
  }

  ngOnInit(): void {
    this.hasLastFilters();
    this.atualizarValorTotalDosContratos();
  }

  atualizarValorTotalDosContratos(): void {
    const periodo = (this.dataPeriodo || '').split(',');
    const periodoDTO = {
      dataInicial: periodo.length > 0 ? parseInt(periodo[0]) : null,
      dataFinal: periodo.length > 1 ? parseInt(periodo[1]) : null,
    };
    this.contractService
      .getValorBrutoTodosContratos({
        ...periodoDTO,
        nome: this.filter === 'NOME_CLIENTE' ? this.search : '',
        descricaoRota: this.filter === 'ROTA' ? this.search : '',
        situacoesContrato: (this.situacao || '').split(','),
      })
      .subscribe((response) => {
        if (response.success) {
          this.valorTotalContratos = response.object;
        }
      });
  }

  over(): void {
    this.loading = true;
    this.search = null;
    this.atualizarValorTotalDosContratos();
    this.contractService.pageAll(10, 1, 'dataFinalizacao').subscribe((res) => {
      this.rows = res.object.content;
      const page = res.object;
      page.pageNumber = 0;
      this.setPage(page);
      this.itensAPI = this.rows;
      this.loading = false;
      this.rows.forEach((contrato) => {
        contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
      });
    });
  }

  setPage(data): void {
    // Utilizando para o pageable
    this.page.pageNumber = data ? data.pageNumber : 0;
    this.page.totalPages = data ? data.totalPages : 0;
    this.page.size = data ? data.size : 0;
    this.page.totalElements = data ? data.totalElements : 0;
  }

  getDataFilter(event): void {
    this.atualizarValorTotalDosContratos();
    const page = event;
    page.pageNumber = 0;
    this.setPage(page);
    this.rows = event.content;
    this.rows.forEach((contrato) => {
      contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
    });
  }

  getSearchFilter(event): void {
    this.search = event;
  }

  getSituacao(event): void {
    this.situacao = event;
  }

  bordero(event): void {
    this.dialog
      .open(BorderoFormComponent, {
        data: {
          contrato: event,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.dialog
            .open(BorderoConfirmComponent, {
              data: {
                contrato: event,
                valorAPagar: data,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              this.contractService.bordero(res.contrato).subscribe((result) => {
                if (result) {
                  this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `Borderô Contrato atualizado com sucesso`,
                  };
                  this.feedback(this.dialogMessages);
                  this.over();
                } else {
                  if (result.messages[0].text != null) {
                    this.dialogMessages = {
                      type: 'info',
                      title: 'Informação!',
                      text: `${result.messages[0].text}`,
                    };
                  } else {
                    this.dialogMessages = {
                      type: 'error',
                      title: 'Error!',
                    };
                  }
                  this.feedback(this.dialogMessages);
                  this.over();
                }
              });
            });
        }
      });
  }

  getPeriodoFilter(event): void {
    this.isPeriodo = true;
    this.dataPeriodo = event;
  }

  getFilter(event): void {
    this.filter = event;
    if (
      event === 'NOVO,RENOVADO,GERADO' ||
      event === 'LIBERADO' ||
      event === 'EM_ANDAMENTO' ||
      event === 'CANCELADO' ||
      event === 'EM_ATRASO' ||
      event === 'REPACTUADO' ||
      event === 'LIQUIDADO_ADMINISTRACAO'
    ) {
      this.loading = true;
      this.isPeriodo = false;
      this.contractService.page(event, 10, 1, '-dataFinalizacao').subscribe((data) => {
        this.rows = data.object.content;
        this.rows.forEach((contrato) => {
          contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
        });
        this.loading = false;
        this.situacao = event;
        const page = data.object;
        page.pageNumber = 0;
        this.setPage(page);
      });
    } else if (event === 'FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO') {
      this.contractService.pageOrderByDataFinalizacao(event, 10, 1).subscribe((data) => {
        this.rows = data.object.content;
        this.rows.forEach((contrato) => {
          contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
        });
        this.loading = false;
        const page = data.object;
        page.pageNumber = 0;
        this.setPage(page);
      });
    } else if (event === 'all') {
      this.isPeriodo = false;
      this.filter = 'NOME_CLIENTE';
      this.situacao = this.situacaoDefault;
      this.over();
    } else if (event === 'TODOS_HOJE') {
      this.isPeriodo = false;
      this.contractService.pageAllContratoHoje(10, 1, this.situacao, new Date()).subscribe((res) => {
        this.loading = false;
        this.rows = res.object.content;
        this.itensAPI = this.rows;
        const page = res.object;
        page.pageNumber = 0;
        this.setPage(page);
      });
    }
    this.atualizarValorTotalDosContratos();
  }

  // Para Cada página ser de acordo com o filtro
  getPage(event): void {
    this.isPeriodo = false;
    this.loading = true;
    this.pageNumber = event ? event : 1;
    if (this.filter == null && this.search != null) {
      this.filter = 'NOME_CLIENTE';
      this.situacao = this.situacaoDefault;
    }

    if (['PERIODO', 'RESPONSAVEL_CONTRATO', 'NOME_CLIENTE', 'DOCUMENTO_CLIENTE', 'ROTA'].includes(this.filter)) {
      this.situacao = this.situacaoDefault;
    } else {
      this.situacao = this.filter;
    }

    switch (this.filter) {
      case 'NOVO,RENOVADO,GERADO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'LIBERADO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'EM_ATRASO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'EM_ANDAMENTO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });
        break;
      case 'CANCELADO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'FINALIZADO,FINALIZADO_COM_DIVIDA,FINALIZADO_COM_ATRASO,FINALIZADO_COM_ANTECIPACAO':
        this.contractService.pageOrderByDataFinalizacao(this.filter, 10, this.pageNumber ? this.pageNumber : 1).subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'LIQUIDADO_ADMINISTRACAO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });
        break;
      case 'REPACTUADO':
        this.contractService.page(this.filter, 10, this.pageNumber ? this.pageNumber : 1, '-dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;
          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });

        break;
      case 'DOCUMENTO_CLIENTE':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByNomeCliente(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;

      case 'NOME_BUSCA':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'RESPONSAVEL_CONTRATO':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'ROTA':
        if (this.search === '' || this.search == null) {
          this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.rows.forEach((contrato) => {
              contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
            });
            this.loading = false;

            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;

      case 'FILTER_PERIOD':
        this.isPeriodo = true;
        this.loading = true;
        if (this.dataPeriodo === '') {
          this.contractService.pageContratoByPeriodo(this.situacao, 10, 1, 'dataVencimento').subscribe((res) => {
            this.loading = false;
            this.rows = res.object.content;
            this.itensAPI = this.rows;
            const page = res.object;
            page.pageNumber = 0;
            this.setPage(page);
          });
        } else {
          this.contractService
            .pageContratoByPeriodo(this.situacao, 10, this.pageNumber, '+dataVencimento', this.dataPeriodo)
            .subscribe((res) => {
              this.loading = false;
              this.rows = res.object.content;
              this.itensAPI = this.rows;
              const page = res.object;
              page.pageNumber = 0;
              this.setPage(page);
            });
        }
        break;

      case 'TODOS_HOJE':
        this.isPeriodo = true;
        this.loading = true;
        if (this.dataPeriodo === '') {
          this.contractService.pageAllContratoHoje(10, 1, this.situacao, new Date()).subscribe((res) => {
            this.loading = false;
            this.rows = res.object.content;
            this.itensAPI = this.rows;
            const page = res.object;
            page.pageNumber = 0;
            this.setPage(page);
          });
        }
        break;

      case null:
        this.contractService.pageAll(10, this.pageNumber, 'dataFinalizacao').subscribe((data) => {
          this.rows = data.object.content;
          this.rows.forEach((contrato) => {
            contrato.valorContrato = (contrato.valorLiquido * 100) / (100 - contrato.taxaJuros);
          });
          this.loading = false;

          const page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });
        break;
    }
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  clearFilter(): void {
    this.filter = null;
    this.search = null;
    this.situacao = 'EM_ANDAMENTO,EM_ATRASO,PENDENTE';
    this.sidebar.clear();
    this.over();
  }

  hasLastFilters(): boolean {
    if (this.route.snapshot.queryParamMap.get('filter') == null && this.route.snapshot.queryParamMap.get('search') == null) {
      this.lastFilter = null;
      this.lastSearchFilter = null;
      return false;
    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter');
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search');
    }
    this.checkingLastFilters();
    return true;
  }

  checkingLastFilters(): void {
    if (this.lastFilter == null && this.lastSearchFilter == null) {
      this.search = '';
      this.filter = 'NOME_CLIENTE';
    } else if (this.lastFilter != null && this.lastSearchFilter == null) {
      this.filter = this.lastFilter;
      this.search = '';
    } else if (this.lastFilter != null && this.lastSearchFilter != null) {
      this.filter = this.lastFilter;
      this.search = this.lastSearchFilter;
    }

    if (
      this.filter === 'PERIODO' ||
      this.filter === 'RESPONSAVEL_CONTRATO' ||
      this.filter === 'NOME_CLIENTE' ||
      this.filter === 'DOCUMENTO_CLIENTE'
    ) {
      this.situacao = this.situacaoDefault;
    } else {
      this.situacao = this.filter;
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
