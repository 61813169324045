import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/main/shared/shared.module';
import { SharedMaterialComponentsModule } from 'app/shared-material-components.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ContractsService } from '../../services/contracts.service';
import { PixListComponent } from './pix-list/pix-list.component';
import { PixMainComponent } from './pix-main/pix-main.component';
import { PixSidebarComponent } from './pix-sidebar/pix-sidebar.component';


const routes: Routes = [
  {
    path: '',
    component: PixMainComponent,
    data: {situacao: 'LIBERADOS'}
  },
  {
    path: 'repassados',
    component: PixMainComponent,
    data: {situacao: 'REPASSADOS'}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})
  ],
  declarations: [PixMainComponent, PixSidebarComponent, PixListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContractsService]
})
export class PixModule { }
