import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VisitModel } from '../../model/visit/visit-model';

@Component({
  selector: 'app-revisao-aprovacao',
  templateUrl: './revisao-aprovacao.component.html',
  styleUrls: ['./revisao-aprovacao.component.scss']
})
export class RevisaoAprovacaoComponent implements OnInit {

  @Input() aprovacao: FormGroup;

  constructor() {}

  ngOnInit() {}

}
