import { Injectable } from '@angular/core';
import { ConfirmacaoRecebimentoService } from '../../acompanhamento-recebimentos/service/confirmacao-recebimento.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveAnaliseSobraService {

  type = 'tcoge';

  constructor(private transferenciaService: TransferenciaService) { }

  resolve(): Observable<any> {
    return this.transferenciaService.pageSobras(this.type, 10, 1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
