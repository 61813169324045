import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Holiday } from '../../model/holiday/holiday.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HolidayService } from '../../../global/components/holidays/services/holiday.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { data } from '../../data/chart-data';
import { Posto } from '../../model/posto/posto.model';
import { ReplaySubject, Subject } from 'rxjs';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { takeUntil } from 'rxjs/operators';

export interface DialogData {
  holiday: Holiday,
  action: any
}

@Component({
  selector: 'app-holiday-form',
  templateUrl: './holiday-form.component.html',
  styleUrls: ['./holiday-form.component.scss']
})
export class HolidayFormComponent implements OnInit {

  titleHeader: string;
  holiday: FormGroup;
  dialogMessages;
  disabledButton: boolean;

  @ViewChild('titulo') titulo: ElementRef;


  postos: Array<Posto>

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private holidayService: HolidayService,
    private postoService: PostsService,
    public matDialogRef: MatDialogRef<HolidayFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
    this.changeTitleHeader()
  }

  ngOnInit() {
    this.setFormGroup();
  }

  ngAfterViewInit() {
    setTimeout(() => this.titulo.nativeElement.focus(), 1000);
  }

  private _onDestroy = new Subject<void>();

  changeTitleHeader() {
    if (this._data.action == 'new') {
      this.titleHeader = 'Novo Feriado';
    } else {
      this.titleHeader = 'Editar Feriado'
    }
  }


  setFormGroup() {
    this.listaPostos()
    let posto = null;
    if (this._data.action == 'update') {    
      posto = this._data.holiday.posto  
    }
    this.holiday = this.formBuilder.group({
      id: [this._data.holiday.id],
      titulo: [this._data.holiday.titulo, Validators.required],
      descricao: [this._data.holiday.descricao, Validators.required],
      data: [new Date(this._data.holiday.data), Validators.required],
      posto:[posto ? this._data.holiday.posto.descricao : null, Validators.required]
    })
  }



  onAdd(object: any) {
    this.disabledButton = true;
    this.postos.forEach(posto => {
      if(posto.descricao == object.posto) {
        object.posto = posto;
      }      
    })
    this.holidayService.add(object).subscribe(seg => {
      if (seg.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${seg.messages[0].text}`
        }
      } else {
        if(seg.messages != null){
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${seg.messages[0].text}`
            }
        }else{
         this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }

      this.feedback(this.dialogMessages);

      this.matDialogRef.close(seg.object);
    });
  }

  onUpdate(object: any) {
    this.disabledButton = true;
    this.postos.forEach(posto => {
      if(posto.descricao == object.posto) {
        object.posto = posto;
      }      
    })
    this.holidayService.updateHoliday(object).subscribe((data: ReturnApi) => {

      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        }

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
        this.feedback(this.dialogMessages);

        this.matDialogRef.close();
      }

    })
  }

  listaPostos(){
    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPosto();
        });
    })
  }

  private filterPosto() {
    if (!this.postos) {
      return;
    }

    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }
}
