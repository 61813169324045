import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParticipantResponse, Message } from 'ng-chat';

@Injectable({
    providedIn: 'root'
})
export class AlertaPendenciaService {

    private posto = '';

    private alerta = `${SGF_API}` + 'sgf/api/public/alertapendencia'

    constructor(private httpClient: HttpClient) { }

    findAll(): any {
        return this.httpClient.get(this.alerta)
            .pipe(
                catchError(this.handleError)
            )
    }

    getGrupoMensagem(): Observable<ParticipantResponse[]> {
        this.checkIfHasPosto();
        return this.httpClient.get(`${this.alerta}/grupo-mensagem${this.posto}`, this.httpOptions())
            .pipe(
                map((res: any) => res.object),
                catchError(this.handleError)
            )
    }

    getMessageHistory(id: number): Observable<Message[]> {
        this.checkIfHasPosto();
        return this.httpClient.get(`${this.alerta}/mensagens-historico/${id}${this.posto}`, this.httpOptions())
            .pipe(
                map((res: any) => res.object),
                catchError(this.handleError)
            )
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(
            'Something bad happened; please try again later.');
    }

    httpOptions(token?: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': ''
            })
        }
    }

    checkIfHasPosto(): void {
        if (sessionStorage.getItem('funcionario')) {
          const user = JSON.parse(sessionStorage.getItem('funcionario'));
          this.posto = user.idPosto ? '/' + user.idPosto : '';
        }
    }
}