import { Injectable } from '@angular/core';
import { CaptacaoService } from '../../captacao/services/captacao.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveCaptacoesRejeitadosService {

  constructor(private captadoService: CaptacaoService) { }

  resolve(): Observable<any> {
    return this.captadoService.pageBySituacaoCaptado(1, 10, 'REJEITADO').pipe(
      take(1),
      mergeMap(teste => {
       return of(teste['object']);
      })
    );
 }
}
