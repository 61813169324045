import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class VisitadosService {

  private posto: string;
  private cliente = `${SGF_API}` + 'sgf/api/public/cliente';
  private arquivovisita = `${SGF_API}` + 'cliente/api/public/arquivovisita/';
  private arquivovisitasgf = `${SGF_API}` + 'sgf/api/public/arquivovisita/';
  private visita = `${SGF_API}` + 'sgf/api/public/visita';


  constructor(
    private httpClient: HttpClient,
  ) {
  }

  findAll(): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.visita}?size=1000&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  page(situacao: any, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.visita}?situacao=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  create(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.post(this.visita, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(this.visita, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCliente(customer): Observable<any> {
    const body = JSON.stringify(customer);
    return this.httpClient.put(this.cliente, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  reagendarVisita(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.put(`${this.visita}/reagenda`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateVisit(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.put(`${this.visita}/aprova`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getCodeByVisit(id: number): Observable<any> {
    return this.httpClient.get(`${this.arquivovisita}findbyvisita/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findbycodigoincontext(codigo: string): Observable<any> {
    return this.httpClient.get(`${this.arquivovisita}findbycodigoincontext/${codigo}`, { responseType: 'blob' });
  }

  deleteImage(id: number): Observable<any> {
    return this.httpClient.delete(`${this.arquivovisita + id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  reprova(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.put(`${this.visita}/reprovarVisita`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFoto(arquivo): Observable<any> {
    const body = JSON.stringify(arquivo);
    return this.httpClient.delete(`${this.arquivovisitasgf}delete-foto/${arquivo.id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
