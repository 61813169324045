import { ChatAdapter, IChatGroupAdapter, ChatParticipantType, ChatParticipantStatus, ParticipantResponse, IChatParticipant, Message, Group } from 'ng-chat';
import { of, Observable, from } from 'rxjs';
import { AlertaPendenciaService } from './main/alerta-pendencia/services/alerta-pendencia.service';
import { AuthenticationService } from './main/authentication/services/authentication.service';

export class AppAdapter extends ChatAdapter implements IChatGroupAdapter {

    constructor(private alertaPendeciaService: AlertaPendenciaService,
        private authenticationService: AuthenticationService) {
        super();
        // this.initializeListeners();
    }

    listFriends(): Observable<ParticipantResponse[]> {
        return this.alertaPendeciaService.getGrupoMensagem();
    }

    getMessageHistory(destinataryId: any): Observable<Message[]> {
        return this.alertaPendeciaService.getMessageHistory(destinataryId);
    }

    private initializeListeners(): void {
        const stompClient = this.authenticationService.connectToWebsocketWithStomp();
        stompClient.connect({}, frame => {

            // Subscribe to notification topic
            stompClient.subscribe('/alerta/novo', messageReceived => {
                // Update notifications attribute with the recent messsage sent from the server
                this.onMessageReceived(messageReceived.participant, messageReceived.messages[0])
            })
        });
    }

    sendMessage(message: Message): void {
    }

    groupCreated(group: Group): void {
    }

    getDisplayName(tipoPendenciaAlerta: number): string {
        if (tipoPendenciaAlerta == 0) {
            return 'Pendência';
        } else if (tipoPendenciaAlerta == 1) {
            return 'Repasse';
        } else if (tipoPendenciaAlerta == 2) {
            return 'Cobrança';
        } else if (tipoPendenciaAlerta == 3) {
            return 'Complemento';
        } else if (tipoPendenciaAlerta == 4) {
            return 'Despesa';
        } else {
            return null;
        }

    }
}
