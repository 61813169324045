import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Telephone } from 'app/main/shared/model/telephone/telephone.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-cliente-socios',
  templateUrl: './cliente-socios.component.html',
  styleUrls: ['./cliente-socios.component.scss'],
  animations:[fuseAnimations]
})
export class ClienteSociosComponent implements OnInit {

  telephones: FormArray;
  @Input() cliente: FormGroup;
  listTelefone: Telephone[]
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.cliente.get('pessoa.listSocio').value.length == 0) {
      this.add()
    }
  }

  add(required?: boolean): void {
    const socio = this.formBuilder.group({
      id              :  new FormControl(),
      nome            :  new FormControl(),
      sexo            :  new FormControl(),
      dataNascimento  :  new FormControl(),
      cpf             :  new FormControl(),
      rg              :  new FormControl(),
      estadoCivil     :  new FormControl(),
      listTelefone    :  this.buildPhone(['']),
      listEndereco    :  this.buildAddress(['']),
      type            :  "pf"
    });
    this.socioForms.push(socio);
  };

  delete(index: number): void {
    this.socioForms.removeAt(index);
  }
  
  buildPhone(listTelefone): FormArray {
    const phone = listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: [v.ativo],
        numero: [v.numero, [Validators.required, Validators.minLength(10)]],
        id: [v.id]
      });
    });
    return this.formBuilder.array(phone);
  }

  buildAddress(listEndereco): FormArray {
    let address = listEndereco.map(a => {
      return this.formBuilder.group({
        id              : new FormControl(listEndereco ? a.id : null),
        cep             : new FormControl(listEndereco ? a.cep : null, Validators.required),
        logradouro      : new FormControl(listEndereco ? a.logradouro : null),
        numero          : new FormControl(listEndereco ? a.numero : null, Validators.required),
        latitude        : new FormControl(listEndereco ? a.latitude : null),
        longitude       : new FormControl(listEndereco ? a.longitude : null),
        bairro          : new FormControl(listEndereco ? (a.bairro ? a.bairro.descricao : null) : null, Validators.required),
        municipio       : new FormControl(listEndereco ? (a.municipio ? a.municipio.nomeMunicipio : null) : null, Validators.required),
        complemento     : new FormControl(listEndereco ? a.complemento : null),
        estado          : new FormControl(listEndereco ? (a.estado ? a.estado.sigla : null) : null, ),
        pontoReferencia : new FormControl(listEndereco ? a.pontoReferencia : null),
        tipoEndereco    : new FormControl(listEndereco ? a.tipoEndereco : null)
      });
    });
    return this.formBuilder.array(address);

  }

  getErrorMessage(field: string) {
  }

  get socioForms(): FormArray {
    return this.cliente.get('pessoa.listSocio') as FormArray;
  }

}
