export class CalculoValorLiquidoTO {
    
    quantidadeParcelas: number;

    taxaJuros: number;

	pagamentoCartao: boolean;

	pagamentoBoleto: boolean;

	pagamentoDinheiro: boolean;

	pagamentoPix: boolean;

	valorAprovado: number;

	tipoContrato: string;
}