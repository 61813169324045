import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FolhaCobrancaService } from 'app/main/financial/services/folha-cobranca/folha-cobranca.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

interface DataDialog {
    action: string;
    folhaCobranca: any;
}

@Component({
    selector: 'app-lista-cobranca-form',
    templateUrl: './lista-cobranca.component.html',
    styleUrls: ['./lista-cobranca.component.scss']
})
export class ListCobrancaComponent implements OnInit {

    transferenciasFolhaCobranca: any[] = [];
    folhaCobranca: any;
    dialogMessages;

    constructor(private folhaCobrancaService: FolhaCobrancaService,
        @Inject(MAT_DIALOG_DATA) public _data: DataDialog,
        private matDialogRef: MatDialogRef<ListCobrancaComponent>,
        private dialog: MatDialog) {
        folhaCobrancaService.findTransferenciaByFolhaCobranca(this._data.folhaCobranca.id).subscribe(data => {
            if (data.success) {
                this.transferenciasFolhaCobranca = data.object;
            }
        });

        this.folhaCobranca = this._data.folhaCobranca;
    }

    ngOnInit() {
    }

    onClosed() {
        this.matDialogRef.close();
    }

    moveItem(item) {
        this.transferenciasFolhaCobranca.splice(this.transferenciasFolhaCobranca.indexOf(item), 1);
    }

    save() {
        this.folhaCobrancaService.processarOrdemFilaCobranca(this.transferenciasFolhaCobranca).subscribe(ondata => {
            if (ondata.success) {
                this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `Alteração realizada com Sucesso`
                }

                this.feedback(this.dialogMessages);
                this.matDialogRef.close(ondata.object);
            } else {
                if (ondata.messages[0].text != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${ondata.messages[0].text}`
                    }
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    }
                }

                this.feedback(this.dialogMessages);
                this.matDialogRef.close(ondata.object);
            }

        }, (onerror) => { console.log('ERROR LISTA COBRANCA', onerror); });
    }

    private feedback(message: string[]) {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
            data: message,
        })
        setTimeout(() => dialogRef.close(), 8000);
    }
}
