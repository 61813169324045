import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize, GalleryRef } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ActivatedRoute } from '@angular/router';
import { VisitadosService } from '../../../customers/components/visitados/services/visitados.services';
import { SGF_API } from '../../../../shared/api/sgf.api';

@Component({
  selector: 'app-visit-gallery',
  templateUrl: './visit-gallery.component.html',
  styleUrls: ['./visit-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitGalleryComponent implements OnInit {

  idVisita: number;
  urlPhoto = `${SGF_API}` + 'sgf/api/public/arquivovisita/findbycodigoincontext/';



  items: ImageItem[];
  imageData;
  constructor(public gallery: Gallery,
    public lightbox: Lightbox,
    private visitadosService: VisitadosService,
    private route: ActivatedRoute) {

    this.idVisita = parseInt(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    const lightboxRef = this.gallery.ref('lightbox');

    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Bottom,
      gestures: false,
      counter: false,
      zoomOut: 3,
      
    });

    this.visitadosService.getCodeByVisit(this.idVisita).subscribe(data => {
      lightboxRef.load(data.object.filter(fotos => {
        let extensionFile =  fotos.nome.split(".").pop();
        if(extensionFile !== 'mp4') {
          return fotos;
        }
      }).map(fotos => {
        return new ImageItem({ src: this.urlPhoto + fotos.codigo, thumb: this.urlPhoto + fotos.codigo })
      }));
    });
  }  
}
