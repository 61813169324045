import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-contrato-retroativo-form',
  templateUrl: './contrato-retroativo-form.component.html',
  styleUrls: ['./contrato-retroativo-form.component.scss']
})
export class ContratoRetroativoFormComponent implements OnInit {

  form: FormGroup
  nomeCliente: string;
  disabledButton:boolean;
  dialogMessages:any;
  constructor(private formBuild: FormBuilder,
    private contratoService: ContractsService,
    private matDialogRef: MatDialogRef<ContratoRetroativoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private _matDialog : MatDialog) {

    this.nomeCliente = this.data.contrato.visita.cliente.pessoa.nomePessoa

  }


  ngOnInit() {
    this.form = this.formBuild.group({
      contratoId: [this.data.contrato.id],
      data: [null, Validators.required]
    })
  }

  gerarContrato(value) {
    this.contratoService.liberarContratoRetroativo(value).subscribe(
      (data) => {
        if (data.success) {
          this.disabledButton = true;
          this.matDialogRef.close(data.object);

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          }
          this.feedback(this.dialogMessages);
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            }
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }
          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    )
  }

  onCancel() {
    this.matDialogRef.close();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }


}
