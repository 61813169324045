import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { VehiclesService } from 'app/main/global/components/vehicles/services/vehicles.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Mask } from '@fagnerlima/ng-mask';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';

export interface DialogData {
  action: string;
  data: any;
}

@Component({
  selector: 'app-vehicles-form',
  templateUrl: './vehicles-form.component.html',
  styleUrls: ['./vehicles-form.component.scss']
})
export class VehiclesFormComponent implements OnInit {

  vehicles: FormGroup;
  titleHeader: string;
  maskPlaca = new Mask('AAA-AAAA');

  dialogMessages;
  disabledButton: boolean;
  
  private _onDestroy = new Subject<void>();

  @ViewChild('placa') placa: ElementRef;

  postos: Array<Posto>;

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private postoService: PostsService,
    private vehiclesService: VehiclesService,
    public matDialogRef: MatDialogRef<VehiclesFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPosto();
        });
    });

    // tslint:disable-next-line: triple-equals
    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Veículo' : 'Edição de Veículo';

  }

  setFormGroup(): void {

    let posto = null;
    // tslint:disable-next-line: triple-equals
    if (this._data.action == 'update') {    
      posto = this._data.data.posto;  
    }

    this.vehicles = this.formBuilder.group({
      id: [this._data.data.id],
      chassi: [this._data.data.chassi, Validators.required],
      codigoRastreio: [this._data.data.codigoRastreio, Validators.required],
      cor: [this._data.data.cor, Validators.required],
      posto: [ posto ? this._data.data.posto.descricao : null, Validators.required],
      modelo: [this._data.data.modelo, Validators.required],
      placa: [this._data.data.placa, Validators.required],
      renavan: [this._data.data.renavan, Validators.required]
    });
  }

  ngOnInit(): void { 
    this.setFormGroup();
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => this.placa.nativeElement.focus(), 1000);
  }

  


  onAdd(value): void {
    this.disabledButton = true;
    const placa = value.placa.replace('-', '');
    value.placa = placa;

    this.postos.forEach(posto => {
      // tslint:disable-next-line: triple-equals
      if (posto.descricao == value.posto) {
        value.posto = posto;
      }      
    });

    this.vehiclesService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          };
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if (ondata.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR VEÍCULO', onerror);
      }
    );
  }

  onUpdate(value): void {
    this.disabledButton = true;
    const placa = value.placa.replace('-', '');
    value.placa = placa;

    this.postos.forEach(posto => {
      // tslint:disable-next-line: triple-equals
      if (posto.descricao == value.posto) {
        value.posto = posto;
      }      
    });

    this.vehiclesService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          };
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);

        } else {
          if (ondata.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR VEÍCULO', onerror);
      }
    );
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }

    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

}
