import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../../shared/api/sgf.api';

import { Caixa } from 'app/main/shared/model/caixa/caixa.model';
import { UtilsService } from 'app/shared/util/utils.service';
import { AuditoriaConferenciaCaixaGeral} from 'app/main/shared/model/conferencia-caixa/auditoria-conferencia-caixa';

@Injectable({
  providedIn: 'root'
})
export class CaixaService {

  private posto: string;

  private idPosto: number;

  private caixa = `${SGF_API}` + 'sgf/api/public/caixa';

  constructor(private httpClient: HttpClient) {}


  conferenciaCaixaGeral(dto: AuditoriaConferenciaCaixaGeral): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.post(`${this.caixa}/conferencia-caixa-geral`, dto, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findAll(type: string, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}?type=${type}&size=${size}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    ); 
  }

  moverCaixaParaSaida(caixaId: number): Observable<any> {
    return this.httpClient.post(`${this.caixa}/mover-para-saida/${caixaId}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findCaixaControleOpen():  Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}/findCaixaControleOpen/${this.idPosto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findCaixaGeralOpen():  Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}/findCaixaGeralOpen/${this.idPosto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  page(type: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}?type=${type}&size=${size}&page=${page}&${this.posto}&orderByRota=descricao&caixaAberto=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  pageTodosCaixasGeral(type: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}?type=${type}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  pageOpens(type: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}?situacao=eq:ABERTO&type=${type}&size=${size}&page=${page}&${this.posto}&orderByRota=descricao`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
 
  }

  pageSituacao(situacao: string, type: string, size: number, page: number, extraParams?: { [key: string]: any } ): Observable<any> {
    this.checkIfHasPosto();
    const params = UtilsService.requestParams(extraParams);
    return this.httpClient.get(`${this.caixa}?situacao=in:${situacao}&type=${type}&size=${size}&page=${page}&${this.posto}&orderByRota=descricao${params}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  dashboardLancamentoEntrada(filters?: { [key: string]: string }): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.caixa}/dashboard-lancamento-entrada/${this.idPosto}?${UtilsService.requestParams(filters)}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  pageFilterCaixaFuncionario(type: string, situacao: string, funcionario: string, size: number, page: number, extraParams?: { [key: string]: any } ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();
    const params = UtilsService.requestParams(extraParams);
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}?filters=%26nomeFuncionario%3D%25${funcionario}%25&situacao=in:${situacao}&type=${type}&size=${size}&page=${page}&${this.posto}&orderByRota=descricao${params}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  
  }

  filtrarCaixaPorNomeFuncionario(type: string, situacao: string, funcionario: string, size: number, page: number, extraParams?: { [key: string]: any } ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    this.checkIfHasPosto();
    const params = UtilsService.requestParams(extraParams);
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}?filters=%26nomeFuncionario%3D%25${funcionario}%25&situacao=in:${situacao}&type=${type}&size=${size}&page=${page}&${this.posto}`, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );

  }

  pageFilterCaixaRota(type: string, rota: string, size: number, page: number, extraParams?: { [key: string]: any } ): Observable<any> {
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}?filters=%26descricaoRota%3D%25${rota}&type=${type}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  pageFilterCaixaSituacaoRota(type: string, rota: string, situacao: string, size: number, page: number, extraParams?: { [key: string]: any } ): Observable<any> {
    this.checkIfHasPosto();
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}?filters=%26descricaoRota%3D%25${rota}%25&situacao=in:${situacao}&type=${type}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  pageFilterCaixaDF(type: string, funcionario: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}?filters=%26nomeFuncionario%3D%25${funcionario}&type=${type}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
   
  }

  pageCaixaFuncionario(funcionarioId: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.caixa}/findAllCaixaFuncionario?funcionarioId=${funcionarioId}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  pageCaixaDividaFuncionario(funcionarioId: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.caixa}/findAllDividaFuncionario?funcionarioId=${funcionarioId}&size=${size}&page=${page}&${this.posto}&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }


  pageCaixaDespesaFuncionarioAberto(typeFuncionarioId: string): Observable<any> {
    return this.httpClient.get(`${this.caixa}/findAllDespesaFuncionario?situacao=eq:ABERTO&funcionarioId=${typeFuncionarioId}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageCaixaDividaFuncionarioAberto(funcionarioId: string, situacao): Observable<any> {
    this.checkIfHasPosto();
      return this.httpClient.get(`${this.caixa}/findAllDividaFuncionario?situacao=in:${situacao}&funcionarioId=${funcionarioId}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  pageCaixaDespesaFuncionario(typeFuncionarioId: string, size: number, page: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.caixa}/findAllDespesaFuncionario?situacao=eq:ABERTO&funcionarioId=${typeFuncionarioId}&size=${size}&page=${page}&sort=-id,-saldo`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  caixasAbetosAll(type: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.caixa}?situacao=eq:ABERTO&type=${type}&${this.posto}&size=1000&sort=-id,-saldo`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  abrirCaixaGeral(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.post(`${this.caixa}/abrir-caixa-geral`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  create(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.post(this.caixa, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  createCaixaFuncionario(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.post(`${this.caixa}/abrir-caixa-funcionario`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  openCaixaControle(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.post(`${this.caixa}/abrir-caixa-controle`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.put(this.caixa, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.caixa}/${id}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  }

  findByIdCaixa(id: number): Observable<any>{
    return this.httpClient.get(`${this.caixa}/${id}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  }

  liberarTodosOsCaixasFuncionarioAbertos(): Observable<any> {
    this.checkIfHasPosto();
    const postoId = this.posto.split('=')[1] || '';
    return this.httpClient.put(`${this.caixa}/liberar-todos-caixas-funcionario`, postoId, this.httpOptions())
      .pipe(catchError(this.handleError));
  }
  
  confirmarPrestacaoDeConta(caixa: Caixa): Observable<any>{
    const body = JSON.stringify(caixa);
    return this.httpClient.put(`${this.caixa}/confirmarprestacaodeconta`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  }

  confirmarPrestacaoDeContaPendencias(caixa: Caixa): Observable<any>{
    const body = JSON.stringify(caixa);
    return this.httpClient.put(`${this.caixa}/confirmar-prestacao-contas-pendencias`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  }  

  OpenCaixa(caixa): Observable<any>{
    const body = JSON.stringify(caixa);
    return this.httpClient.put(`${this.caixa}/abrir`, body, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  }

  dashboard(type: string): Observable<any>{
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.caixa}/dashboard?type=${type}&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
      );
  
  }

  fecharCaixa(id: number): Observable<any>{
    return this.httpClient.put(`${this.caixa}/fechar`, id, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findAllCfuOpens(type: string, size: number, situacao: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.caixa}?type=${type}&${this.posto}&size=${size}&situacao=eq:${situacao}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
 
  }

  atualizarSituacaoCaixa(caixa): Observable<any> {
    const body = JSON.stringify(caixa);
    return this.httpClient.put(`${this.caixa}/updatesituacaocaixa`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findFuncionariosComPendencias(tipo: string): Observable<any> {
    return this.httpClient.get(`${this.caixa}/funcionarioscompendencias?tipo=${tipo}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  processarRotaNoturna(caixaFuncionarioId: number): Observable<any> {
    return this.httpClient.post(`${this.caixa}/processar-operacoes-noturnas/${caixaFuncionarioId}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }


  processarEFecharCaixaDeFimDeSemana(caixaFuncionarioId: number): Observable<any> {
    return this.httpClient
      .put(`${this.caixa}/processar-e-fechar-caixa-funcionario-fim-de-semana/${caixaFuncionarioId}`, null, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsFiles(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Authorization': ''
      })
    };
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
      this.idPosto = Number.isInteger(user.idPosto) ? user.idPosto : 0;
    }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


}
