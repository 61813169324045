import { Injectable } from '@angular/core';
import { VisitadosService } from '../../visitados/services/visitados.services';
import { take, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { VisitasService } from '../../visitas/services/visitas.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveAcompanhamentoVisitasService {

  situcao: string = 'AGENDADA,REAGENDADA,EM_VISITA,VISITADA,EM_ANALISE,FINALIZADA,APROVADO,REPROVADO,CANCELADO,ENCERRADA'

  
  constructor(
    private visitasService: VisitasService) { }

  resolve(): Observable<any> {
    return this.visitasService.page(this.situcao,10,1).pipe(
      take(1), 
      mergeMap(visita => {
      return of(visita['object']);
      })
    );
  }
}
