import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contract } from '../../model/contract/contract.model';

@Component({
  selector: 'app-reagendados-nao-operados-dialog',
  templateUrl: './reagendados-nao-operados-dialog.component.html',
  styleUrls: ['./reagendados-nao-operados-dialog.component.scss']
})
export class ReagendadosNaoOperadosDialogComponent implements OnInit {

  rows: Contract[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: Contract[], private matDialogRef: MatDialogRef<ReagendadosNaoOperadosDialogComponent>) { 
    this.rows = data;
  }

  ngOnInit() { }

  onConfirm(value: boolean): void {
    this.matDialogRef.close(value)
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  generateDateMask(value: number): string {
    return new Date(value).toLocaleDateString('pt-br');
  }
}
