import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  title: string
}

@Component({
  selector: 'app-delete-shared',
  templateUrl: './delete-shared.component.html',
  styleUrls: ['./delete-shared.component.scss']
})


export class DeleteSharedComponent implements OnInit {

  titleHeader: string;
  disabledButton:boolean;
  constructor(private matDialogRef: MatDialogRef<DeleteSharedComponent>) {}

  ngOnInit() {}

  onConfirm(){
    this.disabledButton = true;
    this.matDialogRef.close(true)
  }

  onClosed(){
    this.matDialogRef.close(false)
  }


}
