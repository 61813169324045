import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { data } from '../../data/chart-data';

@Component({
  selector: 'app-complemento-form',
  templateUrl: './complemento-form.component.html',
  styleUrls: ['./complemento-form.component.scss']
})
export class ComplementoFormComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  employee: Array<Employee>;
  title: string;
  disabledButton: boolean;
  private _onDestroy = new Subject<void>();
  @ViewChild('valor') valor: ElementRef;
  dialogMessages;

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  
  constructor(private employeeService: EmployeeService,
              private transferenciaService: TransferenciaService,
              private utilsService: UtilsService,
              private formBuilder: FormBuilder,
              private _matDialog: MatDialog,
              public dialogRef: MatDialogRef<ComplementoFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { 

    this.employeeService.findAll().subscribe(result => {
      this.employee = result.object.content;
      this.employee = this.employee.filter((employee) => employee.id !== data.idFuncionarioOrigem);
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    });
    
    this.title = this.data.sangriaDePendencia ? 'Sangria de Pendência' : 'Complemento';
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.form = this.formBuilder.group({
      idFuncionarioOrigem : this.data.idFuncionarioOrigem,
      idFuncionarioDestino     : [null],
      idCaixaFuncionarioOrigem : this.data.idCaixaFuncionarioOrigem,
      valor: [null, Validators.required],
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  private filterPerfils(): void {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  onSave(complemento: any): void {
    if (this.data.action === 'entrada') {
      if (this.data.sangriaDePendencia) {
        this.lancarComplementoDeSangriaEmPendencia(complemento);
      } else {
        this.lancarComplementoEmLancamentosDeEntrada(complemento);
      }
    } else if (this.data.action === 'saida') {
      this.lancarComplementoEmLancamentosDeSaida(complemento);
    } else if (this.data.action === 'geral') { 
      this.lancarComplementoPrestadorCaixaGeral(complemento);
    }
  }

  lancarComplementoEmLancamentosDeEntrada(complemento: any): void {
    this.disabledButton = true;

    this.transferenciaService.lancamentoComplemento(complemento).subscribe((data: ReturnApi) => {

      const isSuccess = this.utilsService.feedbackApi(data, 'Complemento realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }

    }, _ => this.disabledButton = false);
  }

  lancarComplementoDeSangriaEmPendencia(complemento: any): void {
    this.disabledButton = true;

    this.transferenciaService.lancamentoComplementoPendenciaParaSangria(complemento).subscribe((data: ReturnApi) => {

      const isSuccess = this.utilsService.feedbackApi(data, 'Sangria realizada com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }

    }, _ => this.disabledButton = false);
  }

  lancarComplementoEmLancamentosDeSaida(complemento: any): void {
    this.disabledButton = true;

    this.transferenciaService.lancamentoComplementoSaida(complemento).subscribe((data: ReturnApi) => {

      const isSuccess = this.utilsService.feedbackApi(data, 'Complemento realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }

    }, _ => this.disabledButton = false);
  }

  lancarComplementoPrestadorCaixaGeral(complemento: any): void {
    this.disabledButton = true;

    this.transferenciaService.lancamentoComplementoGeral(complemento).subscribe((data: ReturnApi) => {

      const isSuccess = this.utilsService.feedbackApi(data, 'Complemento realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

      if (isSuccess) {
        this.dialogRef.close();
      } else {
        this.disabledButton = false;
      }

    }, _ => this.disabledButton = false);
  }

}
