import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, delay } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { NgxPermissionsService } from "ngx-permissions";
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService,
        private permissionsService: NgxPermissionsService) {
        // Serviço para validação de todas as permissões do usuário
        const perm = JSON.parse(sessionStorage.getItem('permissoes'))
        perm.forEach(element => {
            this.permissionsService.addPermission(element);
        })
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        {
            this.loaderService.show();

            return next.handle(req).pipe(
                delay(100),
                finalize(() => this.loaderService.hide())
            );

        }
    }
}