import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BenefitsModel } from 'app/main/shared/model/benefits/benefits.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  private host: string = `${SGF_API}` + 'sgf/api/public/beneficio';

  constructor(
    private httpClient: HttpClient) {}

  findAll(): any {
    return this.httpClient.get<BenefitsModel[]>(this.host, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  create(benefits: any): any {
    const body = JSON.stringify(benefits);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(benefits: any): any {
    const body = JSON.stringify(benefits);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

}
