import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import 'rxjs/add/operator/map';

import { catchError } from 'rxjs/operators';
import { SGF_API } from '../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class ProcessingService {
  private host = `${SGF_API}` + 'sgf/api/public/processamentos';


  constructor(private httpClient: HttpClient) {
  }

  public processarContratos(): any {

    return this.httpClient.post(this.host + '/processar-lancamentos', {}, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
