import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriaPlanoContasService {

  private BASE_URL = `${SGF_API}` + 'sgf/api/public/categoria-plano-conta';

  constructor(private httpClient: HttpClient) { }

  findAll(size: number): Observable<any> {
    return this.httpClient.get(`${this.BASE_URL}?size=${size}&sort=-id`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }

  findByCategoriaDespesa(categoriaDespesa?: string): Observable<any> {
    return this.httpClient.get(`${this.BASE_URL}/findByCategoriaDespesa${categoriaDespesa ? `?categoria=${categoriaDespesa}` : ''}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }
  
  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
