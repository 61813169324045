import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, Subject, } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as Redux from 'redux';
import { CaptacaoStore } from '../../../../shared/store/captacao.store';
import { CaptacaoState } from '../../../../shared/store/captacao.reducer';
import { catchError } from 'rxjs/operators';
import { SGF_API } from '../../../../../shared/api/sgf.api';

@Injectable({
  providedIn: 'root'
})
export class VisitasService {

  private posto: string;
  private host = `${SGF_API}` + 'sgf/api/public/visita';
  private urlArquivo = `${SGF_API}` + 'cliente/api/public/arquivovisita/saveforvisita';
  private urlArquivo64 = `${SGF_API}` + 'cliente/api/public/arquivovisita/saveforarquivovisitato';
  private urlArquivo64List = `${SGF_API}cliente/api/public/arquivovisita/saveforlistarquivovisitato`;

  picturesRef: Array<File>;
  onFilterChanged: Subject<any>;
  filterBy = 'all';


  constructor(
    private httpClient: HttpClient,
    @Inject(CaptacaoStore) private store: Redux.Store<CaptacaoState>
  ) {

  }

  findAll(situacao: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?situacao=in:${situacao}&size=1000&${this.posto}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  page(situacao: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.host}?situacao=in:${situacao}&size=${size}&page=${page}&${this.posto
      }&somenteNaoAprovados=true&sort=+dataVisita`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  findAllVisitaByPosto(situacao: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?situacao=in:${situacao}&size=${size}&page=${page}&${this.posto
      }&sort=-dataAprovacaoCredito&somenteNaoAprovados=true`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  create(visit): Observable<any> {
    return this.httpClient.post(this.host, visit, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  arquivo(id: number | string, formData: FormData): Observable<any> {
    // const request = new HttpRequest('POST', `${this.urlArquivo}/${id}`, formData);
    // request(request);
    return this.httpClient.post(`${this.urlArquivo}/${id}`, formData, this.httpOptionsArquivo())
      .pipe(
        catchError(this.handleError)
      );
  }

  listaDeArquivo64(base64List): Observable<any> {
    return this.httpClient.post(this.urlArquivo64List, base64List, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteArquivoVisita(id: number): Observable<any> {
    return this.httpClient.delete(`${this.urlArquivo64}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(visit): Observable<any> {
    return this.httpClient.put(this.host, visit, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarAuditoriaVisita(idVisita: number, idFuncionarioLogado): Observable<any> {
    return this.httpClient.post(`${this.host}/gerarauditoriapreaprovado/${idVisita}/${idFuncionarioLogado}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  cancel(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.put(`${this.host}/cancela`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  changeStatus(id: number): any {
    return this.httpClient.get(`${this.host}/iniciarvisita/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  agendarVisita(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.post(`${this.host}/agendarvisitabyclienteid`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarVisitaByClienteId(id): Observable<any> {

    return this.httpClient.post(`${this.host}/agendarnovavisitabyclienteid`, id, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  pageByVendedor(situacao: string, vendedor: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?filters=%26vendedor%3D%25${vendedor}%25&situacao=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  pageByNomeCliente(situacao: string, nomeCliente: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&situacao=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  pageByDocumentoCliente(situacao: string, documentoCliente: string, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?documentoCliente=eq:${documentoCliente}&situacao=in:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );

  }

  reativarCliente(visit): Observable<any> {
    const body = JSON.stringify(visit);
    return this.httpClient.put(`${this.host}/reativar-cliente`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  imprimirRelatorioAcompanhamentoVisita(relatorioAcompanhamentoVisita: any): Observable<any> {
    return this.httpClient.post(`${this.host}/imprimir-relatorio-atividade-administrativa`, relatorioAcompanhamentoVisita, { responseType: 'blob' });
  }

  buscarIdUltimaVisitaCliente(idCliente): Observable<any> {
    return this.httpClient.get(`${this.host}?size=1&clienteId=${idCliente}&sort=-id`, this.httpOptions());
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }


  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsArquivo(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a customer-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
