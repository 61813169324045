import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ContractsService } from "app/main/contracts/services/contracts.service";
import { ReportService } from "app/main/report/services/report.service";
import { ClienteService } from "app/main/customers/components/cliente/services/cliente.service";
import { SGF_TYPE } from "app/shared/api/sgf.api";
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Subject, ReplaySubject } from "rxjs";
import { Posto } from "app/main/shared/model/posto/posto.model";
import { FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Employee } from "app/main/shared/model/employee/employee.model";
import { EmployeeService } from "app/main/global/components/employees/services/employee.service";
import { Moment } from "moment";
import * as FileSaver from 'file-saver';
import { CaixaService } from "app/main/financial/services/caixa/caixa.service";
import { Caixa } from "app/main/shared/model/caixa/caixa.model";

@Component({
    selector: 'app-daily-caixa-report',
    templateUrl: './daily-caixa-report.component.html',
    styleUrls: ['./daily-caixa-report.component.scss']
})
export class DailyCaixaReportComponent implements OnInit {

    data: Moment;
    tipoContrato = '0';
    dataTypeFuncionario = false;
    dataTypeData = true;
    postos: Array<Posto>;
    public loading = false;
    postoId = '';
    funcionarios: Array<Employee> = [];
    funcionarioId: number = null;
    disabledButton;
    caixaGeral: Caixa;

    funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    fortEletro = this.funcionario.postoEletro;
    fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
    fortBoleto = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;
    dev = `${SGF_TYPE}` == 'DEV' ? true : false;

    public usuario = JSON.parse(sessionStorage.getItem('funcionario'));

    public postoFilterControl: FormControl = new FormControl();
    public funcionarioFilterControl: FormControl = new FormControl();
    public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);
    public filteredFuncionario: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

    private _onDestroy = new Subject<void>();

    constructor(private dialog: MatDialog,
        private dialogRef: MatDialogRef<DailyCaixaReportComponent>,
        private employeeService: EmployeeService,
        private postoService: PostsService,
        private caixaService: CaixaService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private reportService: ReportService, private clienteService: ClienteService) {
            this.caixaService.findCaixaGeralOpen().subscribe(response => {
                if(response.object) {
                    this.caixaGeral = response.object;
                    this.disabledButton = !(this.caixaGeral.conferido || this.usuario.cargo != 'PRESTADOR DE CONTA');
                }
            });
        }

    public disableAccept = false;

    ngOnInit(): void {
        this.dataTypeFuncionario = this._data.type === 'caixa-funcionario' ? true : false;

        if (this.usuario.idPosto == null) {
            this.postoService.listarPostos().subscribe(data => {
                this.postos = data.object;
                this.filteredPosto.next(this.postos.slice());
                this.postoFilterControl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterPosto();
                    });
            });
        } else {
            this.postoId = this.usuario.idPosto;
        }

        this.employeeService.findAll().subscribe(data => {
            this.funcionarios = data.object.content;
            this.filteredFuncionario.next(this.funcionarios.slice());
            this.funcionarioFilterControl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterPosto();
                    });
        });


    }

    public openPopup(): void {
        this.dialogRef = this.dialog.open(DailyCaixaReportComponent, {
            data: '',
        });
        setTimeout(() => this.dialogRef.close());
    }

    public onSave() {
        this.disabledButton = true;
        switch (this._data.type) {
            case 'caixa-geral':
                this.reportService.getRelatorioCaixaDiarioGeral(this.data.toDate().getTime(), this.postoId).subscribe(ondata => {
                    let objectURL = URL.createObjectURL(ondata);
                    window.open(objectURL, '_blank').print();
                    this.disabledButton = false;
                });
                this.cancel();
                break;
            case 'caixa-geral-resumido':
                this.reportService.getRelatorioCaixaDiarioGeralResumido(this.data.toDate().getTime(), this.postoId).subscribe(ondata => {
                    let objectURL = URL.createObjectURL(ondata);
                    window.open(objectURL, '_blank').print();
                    this.disabledButton = false;
                });
                this.cancel();
                break;
            case 'caixa-funcionario':
                this.reportService.getRelatorioCaixaDiarioFuncionario(this.data.toDate().getTime(), this.postoId, this.funcionarioId).subscribe(ondata => {
                    if(this.funcionarioId != null && this.funcionarioId != 0 ) {
                        let objectURL = URL.createObjectURL(ondata);
                        window.open(objectURL, '_blank').print();
                        this.disabledButton = false;
                    } else {
                        FileSaver.saveAs(ondata, `relatorio-caixas-individuais-funcionario-${('00' + this.data.toDate().getDate()).slice(-2)}${('00' + (this.data.toDate().getMonth() + 1)).slice(-2)}${this.data.toDate().getFullYear()}.zip`);
                        this.dialogRef.close();
                    }
                });
                this.cancel();
            break;
        }
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    private filterPosto(): void {
        if (!this.postos) {
            return;
        }
        let search = this.postoFilterControl.value;
        if (!search) {
            this.filteredPosto.next(this.postos.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredPosto.next(
            this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
        );
    }

    private filterFuncionario(): void {
        if (!this.postos) {
            return;
        }
        let search = this.funcionarioFilterControl.value;
        if (!search) {
            this.filteredFuncionario.next(this.funcionarios.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredFuncionario.next(
            this.funcionarios.filter(funcionario => funcionario.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
        );
    }
}
