
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { UserService } from 'app/main/global/components/users/services/users/users.service';
import { User } from '../../model/user/user.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { UserProfile } from '../../model/user-profile/user-profile.model';
import { UserProfileService } from '../../../global/components/profiles/services/profile.service';
import { Profile } from '../../model/profile/profile.model';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { Cliente } from '../../model/cliente/cliente.model';
import { EmpresaService } from 'app/main/global/components/empresa/services/empresa-service.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Mask } from '@fagnerlima/ng-mask';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  user: User,
  action: string
}

@Component({
  selector: 'app-user-deactivate',
  templateUrl: './user-deactivate.component.html',
  styleUrls: ['./user-deactivate.component.scss']
})

/** 
 * @name UserDeactivateComponent
 * @description 
 * Representação da confirmação de ativar ou desativar usuario
 * 
 */

export class UserDeactivateComponent implements OnInit {
  titleHeader: string;
  action: string;
  user: FormGroup;
  dialogMessages;
  listPermissions: Profile[] = []
  employees: Array<Employee>;
  profiles: Array<UserProfile> = [];
  listPerfisDescription = []
  clientes: Array<Cliente> = []
  isCpf: boolean;
  cnpj: string;
  cpf: string;
  type: string;
  pessoa: any;
  maskCpf = new Mask('000.000.000-00')
  maskCnpj = new Mask('00.000.000/0000-00')
  disabledButton = false;
  typeUser;
  public funcionarioFilterControl: FormControl = new FormControl();
  public filteredFuncionario: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public perfilFilterControl: FormControl = new FormControl();
  public filteredPerfil: ReplaySubject<UserProfile[]> = new ReplaySubject<UserProfile[]>(1);

  public clienteFilterControl: FormControl = new FormControl();
  public filteredCliente: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private employeeService: EmployeeService,
    private profileService: UserProfileService,
    public matDialogRef: MatDialogRef<UserDeactivateComponent>,
    private clienteService: ClienteService,
    private pessoaService: EmpresaService,
    private funcionarioService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    this.employeeService.findAll().subscribe(data => {
      this.employees = data.object.content
      this.filteredFuncionario.next(this.employees.slice());
      this.funcionarioFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterFuncionario();
        });
    })

    this.profileService.findAll().subscribe(data => {
      this.profiles = data.object
      this.filteredPerfil.next(this.profiles.slice());
      this.perfilFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfil();
        });
    })

    this.clienteService.findAll().subscribe(data => {
      this.clientes = data.object.content
      this.filteredCliente.next(this.clientes.slice());
      this.clienteFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCliente();
        });
    })

    if (this._data.action == 'update') {
      if (this._data.user != null && this._data.user.pessoa != null && this._data.user.pessoa.documento.length > 14) {
        this.isCpf = false;
        this.cnpj = this._data.user.pessoa.documento;
        this.type = 'pj';
      } else {
        this.isCpf = true
        this.cpf = this._data.user.pessoa ? this._data.user.pessoa.documento : null ;
        this.type = 'pf';
      }
    }

    this.changeTitleHeader()
  }

  ngOnInit() {
    this.setFormGroup();
    if (this._data.action == 'update') {
      this.pessoaService.findById(this._data.user.pessoa.id).subscribe(data => {
        this.pessoa = data.object;
      })
    }
  }

  private _onDestroy = new Subject<void>();

  changeTitleHeader() {
    if (this._data.user.ativo == true) {
      this.titleHeader = 'Desativar Usuário';
      this.action = 'desativar';
    } else {
      this.titleHeader = 'Ativar Usuário'
      this.action = 'ativar';
    }
  }


  setFormGroup() {
    let funcionario = null;
    let pessoa = null;
    let cliente = null;
    if (this._data.user != null && this._data.user.administrador != null) {
      this.typeUser = 'ua'
      pessoa = this._data.user.pessoa

    } else if (this._data.user != null && this._data.user.cliente != null) {
      this.typeUser = 'uc'
      cliente = this._data.user.cliente;
    } else {
      this.typeUser = 'uf'
      funcionario = this._data.user.funcionario
    }
    
    this.user = this.formBuilder.group({
      id: [this._data.user ? this._data.user.id : null],
      funcionario: [funcionario ? this._data.user.funcionario.id : null],
      cliente: [cliente ? this._data.user.cliente : null],
      administrador: [pessoa ? this._data.user.administrador : null],
      login: [this._data.user ? this._data.user.login : null, Validators.required],
      senha: [this._data.user ? this._data.user.senha : null, Validators.required],
      repeat_senha: ['', Validators.required],
      pessoa: this.formBuilder.group({
        id: this._data.user ? this._data.user.pessoa.id : null,
        isCpf: this._data.user ? this.isCpf : true,
        nome: [pessoa ? this._data.user.pessoa.nomePessoa : null],
        nomeFantasia: [this._data.user ? this._data.user.pessoa.identificador : null, Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        razaoSocial: [this._data.user ? this._data.user.pessoa.razaoSocial : null, Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        cpf: [this._data.user ? this.cpf : null, Validators.compose([this.isCpf == true && this.typeUser == 'ua' ? Validators.required : null])],
        cnpj: [this._data.user ? this.cnpj : null, Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        rg: [pessoa ?this._data.user.pessoa.identificador : null, Validators.compose([this.isCpf == true && this.typeUser == 'ua' ? Validators.required : null])],
        type: [this.type ? this.type : 'pf']
      }),
      listPerfils: [this.listPerfisDescription || null, Validators.required],
      type: [this.typeUser || null, Validators.required],
      superUsuario: [null],
      ativo:[this._data.user.ativo]
    })
  }

  selectPermission(obj: any) {

  }

  setTypeUser(type: string) {
    this.typeUser = type;
  }

  onUpdate(object: any) {
    this.disabledButton = true;

    if (object.ativo === true) {
      object.ativo = false;
    } else {
      object.ativo = true;
    }

    if (object.type === 'ua') {
      delete object.cliente
      delete object.funcionario
      object.pessoa.listTelefone = [];
      object.pessoa.listEndereco = []
      this.pessoaService.update(object.pessoa).subscribe(data => {
        this.pessoa = data.object;
        object.administrador = this.pessoa;
        object.administrador.type = object.pessoa.type;
        object.pessoa = this.pessoa;
        object.superUsuario = true;
        this.update(object)
      })
    }

    if (object.type == 'uc') {
      this.update(object)
    }

    if (object.type == 'uf') {
      this.funcionarioService.findById(this.user.value.funcionario).subscribe(data => {
        object.funcionario = data.object;
        if (object.funcionario.ativo == true) {
          object.funcionario.ativo = false;
        } else {
          object.funcionario.ativo = true;
        }
        this.update(object)
      })
    }
  }

  private filterFuncionario() {
    if (!this.employees) {
      return;
    }

    let search = this.funcionarioFilterControl.value;
    if (!search) {
      this.filteredFuncionario.next(this.employees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredFuncionario.next(
      this.employees.filter(funcionario => funcionario.pessoa.nome.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPerfil() {
    if (!this.profiles) {
      return;
    }

    let search = this.perfilFilterControl.value;
    if (!search) {
      this.filteredPerfil.next(this.profiles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPerfil.next(
      this.profiles.filter(perfil => perfil.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCliente() {
    if (!this.clientes) {
      return;
    }

    let search = this.clienteFilterControl.value;
    if (!search) {
      this.filteredCliente.next(this.clientes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCliente.next(
      this.clientes.filter(cliente => cliente.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  update(object) {
    
    if(this.action == 'desativar') {
      this.desativar(object.id)
    } else {
      this.ativar(object.id)
    }
    
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

  ativar(id: any) {
    this.userService.ativar(id).subscribe(seg => {
      if (seg.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(seg.object);

      } else {
        if(seg.messages != null){
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',

            }
        }else{
         this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }

      this.feedback(this.dialogMessages);
      this.matDialogRef.close();

    })
  }

  desativar(id: any) {
    this.userService.desativar(id).subscribe(seg => {
      if (seg.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(seg.object);

      } else {
        if(seg.messages != null){
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',

            }
        }else{
         this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
      }

      this.feedback(this.dialogMessages);
      this.matDialogRef.close();

    })
  }

}
