import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { DeleteConfirmationComponent } from '../../../dialogs/delete-confirmation/delete-confirmation.component';
import { ListUnididadeFederacao } from '../../../model/uf/ufs.model';
import { Customer } from '../../../model/customer/customer.model';
import { MatSnackBar, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CaptacaoService } from '../../../../customers/components/captacao/services/captacao.service';
import { fuseAnimations } from '@fuse/animations';


@Component({
  selector: 'app-cliente-form-endereco',
  templateUrl: './cliente-form-endereco.component.html',
  styleUrls: ['./cliente-form-endereco.component.scss'],
  animations:fuseAnimations
})
export class ClienteFormEnderecoComponent implements OnInit {

  @Input() customerId;
  @Input() cliente: FormGroup;

  step = 0;
  totalCustomer: number;
  address: FormGroup;
  addressComercial: FormGroup;
  showPanelId: Boolean;
  ufs = ListUnididadeFederacao;
  currentCustomer: Customer;

  constructor(
    private formBuilder: FormBuilder,
    private captacaoService: CaptacaoService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    public snackBar: MatSnackBar) {
      
    }

  ngOnInit() {
    if (this.cliente.value.pessoa.listEndereco.length == 0) {
      this.add();
    }
  }

  get addressForms(): FormArray {
    return this.cliente.get('pessoa').get('listEndereco') as FormArray;
  }

 add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: [],
      cep: new FormControl(null),
      logradouro: new FormControl(null),
      numero: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null),
      estado: new FormControl(null),
      tipoEndereco: new FormControl(null)
    });
    this.addressForms.push(address);
  };

  delete(index: number): void {
    this.addressForms.removeAt(index);

  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

  private feedbackSnack(message: string) {
    this.snackBar.open(message, '', {
      verticalPosition: 'bottom',
      duration: 2700,
    });
  }
}
