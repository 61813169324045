import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-creditos-resume',
  templateUrl: './creditos-resume.component.html',
  styleUrls: ['./creditos-resume.component.scss']
})
export class CreditosResumeComponent implements OnInit {

  @Input() visit: FormGroup;

  constructor() { }

  ngOnInit() {}
  
  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`; 
  }

}
