import { ReferenciaPixFormConfirmacaoComponent } from './../../../../shared/components/referencia-pix-form-confirmacao/referencia-pix-form-confirmacao.component';
import { Component, OnInit } from '@angular/core';
import { Contract } from '../../../../shared/model/contract/contract.model';

import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from '../../../services/contracts.service';
import { SelectedDialogComponent } from '../../../../shared/dialogs/selected/selected.component';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageDialogComponent } from '../../../../shared/components/message-dialog/message-dialog.component';
import { CancelContractComponent } from '../../../../shared/dialogs/cancel-contract/cancel-contract.component';
import { ConfirmationChangesComponent } from '../../../../shared/dialogs/confirmation-changes/confirmation-changes.component';
import { Page } from 'app/main/shared/model/page/page.model';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { SelectMaquinaComponent } from 'app/main/shared/components/select-maquina/select-maquina.component';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { EditMaquinaComponent } from 'app/main/shared/components/edit-maquina/edit-maquina.component';
import { MaquinaCartao } from 'app/main/shared/model/maquina-cartao/maquina-cartao.model';
import { ContratoRetroativoFormComponent } from 'app/main/shared/components/contrato-retroativo-form/contrato-retroativo-form.component';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { ConfirmarTipoRepasseComponent } from 'app/main/shared/components/confirmar-repasse-contrato/confirmar-tipo-repasse.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';


@Component({
  selector: 'app-generated-main',
  templateUrl: './generated-main.component.html',
  styleUrls: ['./generated-main.component.scss'],
  animations: fuseAnimations
})
export class GeneratedMainComponent implements OnInit {

  private readonly defaultSort = 'responsavelRepasse.rota.descricao';

  rows: Contract[];
  itensAPI: Array<Contract>;

  selected: Array<Contract>;
  contract: Contract = new Contract({});
  saveActionButton = 'new';
  
  contractValue: any;
  editActionButton = 'update';

  situacao = 'NOVO,RENOVADO,GERADO';

  dialogMessages;

  habilitarVinculo: boolean;

  searchInput: FormControl;
  interval;
  count = 0;
  page = new Page();
  public loading = false;
  selectedClear: boolean;
  search;
  filter = null;
  pageNumber;
  permissoes = [];
  // tslint:disable-next-line: triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line: triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  isPostoPix: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private contractService: ContractsService,
    private permissionsService: NgxPermissionsService,
    private postoService: PostsService) {
    this.selected = new Array<Contract>();
    this.route.data.subscribe(
      (res) => {
        this.rows = res.rows.content;
        this.itensAPI = this.rows;
        this.page = res.rows
        this.itensAPI.forEach(contrato => {
          if (contrato.maquinas != null && contrato.maquinas.length > 0) {
            contrato.numeroSeries = contrato.maquinas.map(maquina => {
              return maquina.numeroSerie;
            }).join(', ');

          } else {
            contrato.maquinas = [];
          }
        });
        this.page.pageNumber = this.page.pageNumber ? this.page.pageNumber : 0;
        this.setPage(this.page);
      }
    );


    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);

    this.checkIfHasPosto();
  }

  ngOnInit(): void {}

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const postoId = user.idPosto ? user.idPosto : null;

      if(postoId != null) {
        this.postoService.findById(postoId).subscribe((resp: any) => {
          this.isPostoPix = resp.object.parametroPosto.pixBoleto;
        });
      }
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  over(): void {
    this.search = null;
    this.loading = true;
    this.contractService.pageContratosGraphQL(this.situacao, 10, 1, this.defaultSort, new Date().getTime(), new Date().getTime())
      .subscribe(
        (res) => {
          this.loading = false;
          this.rows = res.data.findAllPageable.content;
          this.itensAPI = this.rows;
          this.itensAPI.forEach(contrato => {
            if (contrato.maquinas != null && contrato.maquinas.length > 0) {
              contrato.numeroSeries = contrato.maquinas.map(maquina => {
                return maquina.numeroSerie;
              }).join(', ');
            } else {
              contrato.maquinas = [];
            }
          });
          const page = res.data.findAllPageable;
          page.pageNumber = 0;
          this.setPage(page);
          this.situacao = 'NOVO,RENOVADO,GERADO';
        }
      );
    this.clearSelectBox();
  }

  onSelected(event): void {
    this.selected = event;
    // tslint:disable-next-line: triple-equals
    if (this.selected.length == 1) {
      this.habilitarVinculo = true;
    } else {
      this.habilitarVinculo = false;
    }
  }

  vincularMarquina(): void  {
    const value: any = this.selected[0];
    this.dialog.open(SelectMaquinaComponent, {
      data: { 
        action: 'alterar',
        contrato: value
     }
    }).afterClosed().subscribe(data => {
      if (data) {
        value.maquinaCartao = data.maquina;
        // tslint:disable-next-line: no-shadowed-variable
        this.contractService.vincularMaquina(value).subscribe(data => {
          if (data.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `Máquina alterada com Sucesso`
            };
            this.feedback(this.dialogMessages);
            this.over();
          } else {
            if (data.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${data.messages[0].text}`
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
            this.feedback(this.dialogMessages);
            this.over();
          }
        });
      }
    });
  }

  alterarMaquina(): void  {
    const value: any = this.selected[0];
    this.dialog.open(EditMaquinaComponent, {
      data: {
        action: 'alterar',
        contrato: value
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        // tslint:disable-next-line: arrow-return-shorthand
        const valueArr = data.listMaquina.map(item => { return item.maquina; });
        // tslint:disable-next-line: typedef
        const isDuplicate = valueArr.some(function (item, idx) {
          // tslint:disable-next-line: triple-equals
          return valueArr.indexOf(item) != idx;
        });

        if (isDuplicate) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `Maquina duplicada`
          };
          this.feedback(this.dialogMessages);
          this.over();
        } else {
          const maquinasCartao: Array<MaquinaCartao> = [];

          data.listMaquina.forEach(maquinaCartao => {
            const maquina: MaquinaCartao = new MaquinaCartao();
            maquina.id = maquinaCartao.maquina;
            maquinasCartao.push(maquina);
          });

          value.maquinas = maquinasCartao;
          // tslint:disable-next-line: no-shadowed-variable
          this.contractService.editarMaquinas(value).subscribe(data => {
            if (data.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Máquina alterada com Sucesso`
              };
              this.feedback(this.dialogMessages);
              this.over();
            } else {
              if (data.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${data.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages);
              this.over();
            }
          });
        }
      }
    });
  }

  clearSelectBox(): void  {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  onSelectResponsible(): void  {
    this.dialog.open(
      SelectedDialogComponent, {
      data: this.rows
    }).afterClosed().subscribe(
      data => {
        if (data) {
          this.clearSelectBox();
          this.selected.forEach(contract => {
            contract.cobrador = data.funcionario;
            this.contractService.updateContract(contract).subscribe((apiReturn) => {
              if (apiReturn) {
                this.over();
              }
            });
          });

          if (this.selected.length > 1) {
            const dialogRef = this.dialog.open(ConfirmationChangesComponent, {
              data: this.selected
            });

            dialogRef.afterClosed().subscribe(() => {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: 'Alterações Realizadas Com Sucesso!'

              };
              this.feedback(this.dialogMessages);
            });
          } else {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: 'Vendedor Alterado Com Sucesso!'

            };
            this.feedback(this.dialogMessages);
          }
        }
      });
      this.clearSelectBox();

  }

  cancelContract(select: any): void  {
    this.count = 0;
    const dialogRef = this.dialog.open(CancelContractComponent, {
      data: { data: select, schedule: false }
    });
    dialogRef.afterClosed().subscribe(contract => {
      if (contract) {
        this.selected.forEach(cont => {
          const array = [];
          array.push(contract);
          this.count++;
          cont.parecerCancelamento = contract.parecerCancelamento;
          this.contractService.cancelContract(cont).subscribe(data => {
            // tslint:disable-next-line: triple-equals
            if (this.count == array.length) {
              if (data.success) {
                this.dialogMessages = {
                  type: 'success',
                  title: 'Sucesso!',
                  text: `Contrato(s) cancelado(s) com sucesso.`
                };
              } else {
                if (data.messages[0].text != null) {
                  this.dialogMessages = {
                    type: 'info',
                    title: 'Informação!',
                    text: `${data.messages[0].text}`
                  };
                } else {
                  this.dialogMessages = {
                    type: 'error',
                    title: 'Error!',
                  };
                }
              }
              this.feedback(this.dialogMessages);
              this.over();

            }
          });
        });
      }
    });
  }

  previewContract(): void {
    if (this.isPostoPix) {
      this.dialog.open(ReferenciaPixFormConfirmacaoComponent, {
        width: '1100px',
        data: {
          contrato: this.selected[0]
        }
      }).afterClosed().subscribe((response) => {
        if (response) {
          this.dialog.open(ConfirmarTipoRepasseComponent, {
            data: {
              contratos: this.selected,
              print: true
            }
          }).afterClosed().subscribe(data => {
            if (data) {
              this.over();
            }
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmarTipoRepasseComponent, {
        data: {
          contratos: this.selected,
          print: true
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.over();
        }
      });
    }
  }

  releaseContract (): void {
    if (this.isPostoPix) {
      this.dialog.open(ReferenciaPixFormConfirmacaoComponent, {
        width: '1100px',
        data: {
          contrato: this.selected[0]
        }
      }).afterClosed().subscribe((response) => {
        if (response) {
          this.dialog.open(ConfirmarTipoRepasseComponent, {
            data: {
              contratos: this.selected,
              print: true
            }
          }).afterClosed().subscribe(data => {
            if (data) {
              this.over();
            }
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmarTipoRepasseComponent, {
        data: {
          height: '400px',
          contratos: this.selected,
          print: false
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.over();
        }
      });
    }
  }

  mudarResponsavel(cobranca: any): void {
    const contratos = [];
    this.dialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'new',
        cobranca: cobranca
      }
    }).afterClosed().subscribe(
      data => {
        if (data.funcionario) {
          this.count = 0;
          this.selected.forEach(c => {
            c.responsavel = data.funcionario;
            contratos.push(c);
          });
          const object = {
            contratos: contratos
          };
          this.contractService.alterarResponsavelContrato(object).subscribe((rest) => {
            if (rest.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Registro atualizado com sucesso`
              };
            } else {
              if (rest.messages != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${rest.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
            }
            this.feedback(this.dialogMessages);
            this.over();
          });
        }
      });
  }

  mudarResponsavelRepasse(cobranca: any): void {
    this.dialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'repasse',
        cobranca: cobranca
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          this.selected.forEach(contrato => {
            this.contractService.findById(contrato.id).subscribe(res => {
             contrato = res.object
             contrato.responsavelRepasse = data.funcionario;
              this.alterarResponsavelRepasse(contrato);
            })
          });
        }
      });
  }

  alterarResponsavelRepasse(contrato: any): void {
    this.contractService.alterarResponsavelRepasse(contrato).subscribe((rest) => {
      this.count++;
      // tslint:disable-next-line: triple-equals
      if (this.count == this.selected.length) {
        if (rest.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Registro atualizado com sucesso`
          };
        } else {
          if (rest.messages != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${rest.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
        }
        this.feedback(this.dialogMessages);
        this.count = 0;
        this.over();
      } else {
        if (rest.message[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${rest.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
          this.feedback(this.dialogMessages);
          this.over();
        }
      }
    });
  }

  modalGerarContratoRetroativo(value): void  {
    this.dialog.open(ContratoRetroativoFormComponent, {
      data: {
        contrato: value
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.over();
      }
    });
  }

  alterarProduto(value): void  {
    this.dialog.open(AlterarContratoFormComponent, {
      data: {
        contrato: value,
        action: 'alterarProduto'
      }
    }).afterClosed().subscribe(res => {
      
      this.over();
      
    });
  }

  private feedback(message: string[]): void  {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  setPage(data): void  {
    // Utilizando para o pageable
    this.page.pageNumber = data ? data.pageNumber : 0;
    this.page.totalPages = data ? data.totalPages : 0;
    this.page.size = data ? data.size : 0;
    this.page.totalElements = data ? data.totalElements : 0;
  }

  getDataFilter(event): void  {
    this.rows = event.content;
    const page = event;
    page.pageNumber = 0;
    this.setPage(page);
  }

  getSearchFilter(event): void  {
    this.search = event;
  }

  getSituacao(event): void {
    this.situacao = event;
  }

  getFilter(event): void {
    this.filter = event;
    // tslint:disable-next-line: triple-equals
    if (event == 'NOVO,RENOVADO,GERADO') {
      this.filter = 'NOME_CLIENTE';
      this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id').subscribe(res => {
        this.rows = res.data.findAllPageable.content;
        this.loading = false;
        this.situacao = event;
        const page = res.data.findAllPageable;
        page.pageNumber = 0;
        this.setPage(page);
      });
    }

    if(event == 'HOJE'){
      this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '-id', new Date().getTime(), new Date().getTime()).subscribe(res => {
        this.rows = res.data.findAllPageable.content;
        this.loading = false;
        this.situacao = event;
        const page = res.data.findAllPageable;
        page.pageNumber = 0;
        this.setPage(page);
      });
    };

    // tslint:disable-next-line: triple-equals
    if (event == 'RENOVADO') {
      this.loading = true;
      this.contractService.pageContratosGraphQL(event, 10, 1, this.defaultSort).subscribe(res => {
        this.rows = res.data.findAllPageable.content;
        this.loading = false;
        this.situacao = event;
        const page = res.data.findAllPageable;
        page.pageNumber = 0;
        this.setPage(page);
      });
    }
  }

  // Para Cada página ser de acordo com o filtro
  getPage(event): void {
    this.loading = true;
    this.pageNumber = event;
    this.search = this.search || '';
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        // tslint:disable-next-line: triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'NOME_CLIENTE':
        // tslint:disable-next-line: triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByNomeCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;

      case 'NOME_BUSCA':
        // tslint:disable-next-line: triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByNomeBuscaCliente(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'RESPONSAVEL_CONTRATO':
        // tslint:disable-next-line: triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        // tslint:disable-next-line: triple-equals
        if (this.search == '') {
          this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
            this.rows = res.data.findAllPageable.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = res.data.findAllPageable;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        } else {
          this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                contrato.numeroSeries = contrato.maquinas.map(maquina => {
                  return maquina.numeroSerie;
                }).join(', ');

              } else {
                contrato.maquinas = [];
              }
            });
            this.loading = false;
            const page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page);
          });
        }
        break;
        case 'ROTA':
          // tslint:disable-next-line: triple-equals
          if (this.search == '') {
            this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort).subscribe(res => {
              this.rows = res.data.findAllPageable.content;
              this.rows.forEach(contrato => {
                if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                  contrato.numeroSeries = contrato.maquinas.map(maquina => {
                    return maquina.numeroSerie;
                  }).join(', ');
  
                } else {
                  contrato.maquinas = [];
                }
              });
              this.loading = false;
              const page = res.data.findAllPageable;
              page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
              this.setPage(page);
            });
          } else {
            this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, this.pageNumber).subscribe(data => {
              this.rows = data.object.content;
              this.rows.forEach(contrato => {
                if (contrato.maquinas != null && contrato.maquinas.length > 0) {
                  contrato.numeroSeries = contrato.maquinas.map(maquina => {
                    return maquina.numeroSerie;
                  }).join(', ');
  
                } else {
                  contrato.maquinas = [];
                }
              });
              this.loading = false;
              const page = data.object;
              page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
              this.setPage(page);
            });
          }
          break;
      case null:
        this.contractService.pageContratosGraphQL(this.situacao, 10, this.pageNumber, this.defaultSort, new Date().getTime(), new Date().getTime()).subscribe(res => {
          this.rows = res.data.findAllPageable.content;
          this.rows.forEach(contrato => {
            if (contrato.maquinas != null && contrato.maquinas.length > 0) {
              contrato.numeroSeries = contrato.maquinas.map(maquina => {
                return maquina.numeroSerie;
              }).join(', ');

            } else {
              contrato.maquinas = [];
            }
          });
          this.loading = false;
          const page = res.data.findAllPageable;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page);
        });
        break;
    }
  }

  printContract(): void {
    const ids: number[] = [];
    this.selected.forEach(contract => {
      ids.push(contract.id);
    });

    this.contractService.getPDFContratos(ids).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });

    this.clearSelectBox();
  }

  reloadPage($event: any): any {
    this.over();
  }

  getTotalContractValue(event: number): void {
    this.contractValue = event;
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
