import { UserProfile } from '../user-profile/user-profile.model';
import { PersonModel } from '../person/persons.model';
import { Employee } from '../employee/employee.model';
import { Cliente } from '../cliente/cliente.model';
import { Pessoa } from 'app/main/infinitylabs/core/pessoa/pessoa';

export class User {
    id: number;
    funcionario?: Employee;
    cliente?:Cliente;
    administrador?:Pessoa;
    login: string;
    senha: string;
    pessoa: PersonModel;
    superUsuario: boolean;
    listPerfils: UserProfile[];
    ativo: boolean;
    usuarioNoturno: boolean;
    type;
    constructor(obj?: any) {
        this.buildUser(obj);
    }

    buildUser(user?: User){
        this.id = user instanceof User ? user.id : null;
        this.login = user instanceof User ? user.login : null;
        this.senha = user instanceof User ? user.senha : null;
        this.superUsuario = user instanceof User ? user.superUsuario : null;
        this.pessoa = user instanceof User ? user.pessoa : null;
        this.listPerfils = user instanceof User ? user.listPerfils : [new UserProfile({})];
        this.ativo = user instanceof User ? user.ativo : null;
        this.usuarioNoturno = user instanceof User ? user.usuarioNoturno : null;
    }
}
