import { Component, OnInit, Input, ViewChild, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Customer } from '../../model/customer/customer.model';
import { Municipality } from '../../model/municipality/municipality.model';
import { Subject, ReplaySubject } from 'rxjs';
import { MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Estado } from '../../model/estado/estado.model';
import { GlobalService } from 'app/main/global/services/global.service';
import { Mask } from '@fagnerlima/ng-mask';

declare var google: any;

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {

  @Input() address: FormGroup;
  @Input() required: boolean;
  @Input() tipoEndereco: boolean;

  @Output() bairroEvent: EventEmitter<any> = new EventEmitter();
  maskCep = new Mask('00000-000');
  step = 0;
  totalCustomer: number;
  showPanelId: Boolean;
  municipios: Array<any>;
  bairro: string;
  estados: Array<Estado>;
  municipality: Array<Municipality>;
  filteredOptions = [];
  ufs: Array<Estado>;
  selectedMunicipio: any;
  currentCustomer: Customer;

  public bairroFilterCtrl: FormControl = new FormControl();
  public filteredBairro: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public ufFilterCtrl: FormControl = new FormControl();
  public filteredUF: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public municipioFilterCtrl: FormControl = new FormControl();
  public filteredMunicipio: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private debounce = 400;

  constructor(
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {


    this.globalService.pageEstado().subscribe(data => {
      this.ufs = data.object.content;
      // tslint:disable-next-line:triple-equals
      if (this._data.action == 'update') {
        if (this.address.get('estado').value != null) {
          this.getMunicipios(data.object.content.filter(e => e.sigla === this.address.get('estado').value)[0]);
        }
      }
      this.filteredUF.next(this.ufs.slice());
      this.ufFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterUf();
        });
    });

  }

  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();


  private lastCep = '';
  ngOnInit(): void {

    this.address.valueChanges.subscribe(value => {
      // tslint:disable-next-line:triple-equals
      if (value.cep && value.cep.length === 9 && value.cep != this.lastCep) {
        this.lastCep = value.cep;
        this.searchAddress(null);
      }
    });
  }

  getMunicipios(estado): void {
    let uf;
    if (typeof estado === 'string') {
      uf = this.ufs.filter(e => e.sigla === estado)[0];
    } else {
      uf = estado;
    }

    this.globalService.municipioByEstadoId(uf.id).subscribe(data => {
      this.municipios = data.object.content;


      this.filteredMunicipio.next(this.municipios.slice());
      this.municipioFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMunicipio();
        });
      // tslint:disable-next-line:triple-equals
      if (this._data.action == 'update') {
        // tslint:disable-next-line:triple-equals
        this.selectedMunicipio = this.municipios.filter(result => result.nomeMunicipio == this.address.get('municipio').value)[0];
        this.getBairros(this.selectedMunicipio);

      }
    });
  }

  getBairros(municipio): void {
    let mun;
    if (municipio != null) {
      if (typeof municipio === 'string'){
        mun = this.municipios.filter(m => m.nomeMunicipio === municipio)[0];
      }else{
        mun = municipio;
      }
      this.globalService.bairroByMunicipioId(mun.id).subscribe(data => {
        this.filteredOptions = data.object.content;

        if (this._data.action === 'update'){
          this.setBairro(this.filteredOptions.filter(b => b.descricao === this.address.get('bairro').value)[0]); 
        }

        this.filteredBairro.next(this.filteredOptions.slice());
        this.bairroFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBairro();
          });
      });
    } else {
      this.filteredOptions = [];
      this.filteredBairro.next(this.filteredOptions.slice());
      this.bairroFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBairro();
        });
    }

  }

  setBairro(event): void {
    let bairro;
    if (typeof event === 'string') {
      bairro = this.filteredOptions.filter(b => b.descricao === event)[0];
      this.address.value.bairro = bairro;
    }else{
      bairro = event;
      this.address.value.bairro = bairro;
    }
  }

  private fetchMunicipiosByEstadoId(id: number, onfetch: () => void = null): void {
    this.globalService.municipioByEstadoId(id).subscribe(data => {
      this.municipios = data.object.content || [];
      this.municipioFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterMunicipio());
      this.filteredMunicipio.next(this.municipios.slice());
      if (onfetch) {
        onfetch();
      }
    });
  }

  private fetchBairrosByMunicipioId(id: number, onfetch: () => void = null): void {
    this.globalService.bairroByMunicipioId(id).subscribe(data => {
      this.filteredOptions = data.object.content;
      this.filteredBairro.next(this.filteredOptions.slice());
      this.bairroFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterBairro());
      if (onfetch) {
        onfetch();
      }
    });
  }

  private setAddressSelectOption(address): void {
    const uf = address.uf;
    const municipio = address.cidade;
    const bairro = address.bairro;

    const selectBairro = (municipioId: number) => {
      this.fetchBairrosByMunicipioId(municipioId, () => {
        const bairros = this.filteredOptions.filter(b => this.compareNormalized(b.descricao, bairro));
        this.address.get('bairro').setValue(bairros.length > 0 ? bairros[0].descricao : null);
        this.setBairro(bairros[0]);
      });
    };

    const selectMunicipio = (estadoId: number) => {
      this.fetchMunicipiosByEstadoId(estadoId, () => {
        const cidades = this.municipios.filter(m => this.compareNormalized(m.nomeMunicipio, municipio.toLowerCase()));
        if (cidades.length > 0) {
          this.address.get('municipio').setValue(cidades[0].nomeMunicipio);
          selectBairro(cidades[0].id);
        } else {
          this.address.get('municipio').setValue(null);
        }
      });
    };

    if (typeof uf === 'string') {
      const estados = this.ufs.filter(u => u.sigla === uf);
      if (estados.length > 0) {
        this.address.get('estado').setValue(estados[0].sigla);
        selectMunicipio(estados[0].id);
      } else {
        this.address.get('estado').setValue(null);
      }
    }
  }

  private compareNormalized(str1: string, str2: string): boolean {
    const normalize = (str: string) => str.normalize('NFD').replace(/[^a-zA-Zs]/g, '');
    return normalize(str1.toLowerCase()) === normalize(str2.toLowerCase());
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  searchAddress(event: any): void {
    // this.googleMaps(this.address.get('cep').value)
    this.globalService.enderecoByCep(this.address.get('cep').value)
      .subscribe(data => {
        if (data.success) {
          this.address.get('logradouro').setValue(data.object.logradouro);
          this.address.get('complemento').setValue(data.object.complemento);
          this.setAddressSelectOption(data.object);
        }
      });
  }

  private googleMaps(customer): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${customer}`;
    let municipio: string;
    geocoder.geocode({ 'address': 'Godofredo Maciel' }, (results, status) => {
      if (status === 'OK') {
        municipio = results[0].address_components[2].short_name;

        this.address.get('bairro').setValue(results[0].address_components[1].long_name);
        this.address.get('estado').setValue(results[0].address_components[3].short_name);
        setTimeout(() => {
          this.address.get('municipio').setValue(municipio.toUpperCase());
        }, 200);

      }
    });

  }

  private filterBairro(): void {
    if (!this.filteredOptions) {
      return;
    }

    let search = this.bairroFilterCtrl.value;
    if (!search) {
      this.filteredBairro.next(this.filteredOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBairro.next(
      this.filteredOptions.filter(bairro => bairro.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterUf(): void {
    if (!this.filteredUF) {
      return;
    }

    let search = this.ufFilterCtrl.value;
    if (!search) {
      this.filteredUF.next(this.ufs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUF.next(
      this.ufs.filter(estado => estado.sigla.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterMunicipio(): void {
    if (!this.filteredMunicipio) {
      return;
    }

    let search = this.municipioFilterCtrl.value;
    if (!search) {
      this.filteredMunicipio.next(this.municipios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
 
    this.filteredMunicipio.next(
      this.municipios.filter(municipio => municipio.nomeMunicipio.toLowerCase().indexOf(search) > -1)
    );
  }

  getErrorMessage(field: string): string {
    return this.address.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

}
