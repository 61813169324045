import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CaptacaoService } from 'app/main/customers/components/captacao/services/captacao.service';
import { SGF_API } from 'app/shared/api/sgf.api';
import { IImage, ImageCompressService } from 'ng2-image-compress';

@Component({
  selector: 'app-captacao-forms-imagens',
  templateUrl: './captacao-forms-imagens.component.html',
  styleUrls: ['./captacao-forms-imagens.component.scss']
})
export class CaptacaoFormsImagensComponent implements OnInit {

  @Input() customerId;
  @Input() customer: FormGroup;

  files: FormGroup;
  images: Array<IImage> = [];
  estabelecimentoPhotos: any[] = [];
  referenciaArquivo = 'ESTABELECIMENTO';
  imageData: any[] = [];
  urlPhoto = `${SGF_API}` + 'sgf/api/public/arquivocaptado/findbycodigoincontext/';
  edit = false

  @Output() imagesEmitter: EventEmitter<any[]> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private captacaoService: CaptacaoService
  ) { }

  ngOnInit() {

    this.files = this.formBuilder.group({
      inputField: ['']
    });
    console.log(this.customerId)
    if (this.customerId) {
      this.edit = true
      this.findArquivoByCaptado(this.customerId);
    }

  }

  findArquivoByCaptado(id): void {
    this.captacaoService.findByCaptadoId(id).subscribe((data) => {
      if (data.object) {
        this.imageData = data.object;
      }

    })
  }

  deleteRegistredPhoto(data: any) {
    this.captacaoService.deleteFoto(data).subscribe(res => {
      if (res.sucess) {
        this.deletePhoto(data);
        this.files.get('inputField').setValue(null);
      }
    });
  }

  deletePhoto(data: any): void {
    this.imageData.forEach(imagem => {
      if (data === imagem) {
        const position = this.imageData.indexOf(data);
        this.imageData.splice(position, 1);
      }
    });

    this.imagesEmitter.emit(this.imageData);
  }

  onSelectFile(event): void {
    if (event.target.files) {
      ImageCompressService.filesToCompressedImageSource(event.target.files).then(observableImages => {
        observableImages.subscribe((image) => {
          this.images.push(image);
          const imagem = image.compressedImage.imageDataUrl;

          this.estabelecimentoPhotos.push({
            'contentsBase64': imagem,
            'nome': image.fileName,
            'tipoReferenciaArquivoCaptado': this.referenciaArquivo
          });

          this.imageData = [...this.estabelecimentoPhotos];
          this.imagesEmitter.emit(this.imageData);
          this.files.get('inputField').setValue(null);
        });
      });
    }
  }
}

