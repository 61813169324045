import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ValoresTransferenciaRequestDTO } from 'app/main/shared/model/valor-transferencia-request/valor-transferencia-request.dto';

@Injectable({
  providedIn: 'root'
})
export class ConfirmacaoRecebimentoService {

  private posto: string;
  private sgf = `${SGF_API}` + "sgf/api/public/confirmacaorecebimento"
  private financeiro = `${SGF_API}` + "financeiro/api/public/confirmacaorecebimento"
  private idPosto: any;

  constructor(private httpClient: HttpClient) { }

  page(page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?page=${page}&size=${size}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  create(value: any): Observable<any> {
    const body = JSON.stringify(value);
    return this.httpClient.post(this.sgf, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(value: any): Observable<any> {
    const body = JSON.stringify(value);
    return this.httpClient.put(this.sgf, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getValorTotal(valores: Partial<ValoresTransferenciaRequestDTO>): Observable<any> {
    this.checkIfHasPosto();
    valores.postoId = this.idPosto;
    return this.httpClient.post<any>(
      `${this.sgf}/valor-total`,
      valores,
    );
  }

  pageFuncionario(funcionario: string, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?filters=%26nomeFuncionario%3D%25${funcionario}%25&&size=${size}&page=${page}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  pageConfirmacaoResponsavel(funcionario: string, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?filters=%26nomeResponsavelConfirmacao%3D%25${funcionario}%25&&size=${size}&page=${page}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  pageRota(rota: string, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?filters=%26descricaoRota%3D%25${rota}%25&&size=${size}&page=${page}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  pageSituacao(situacao: string, page: number, size: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?tipoConfirmacao=eq:${situacao}&size=${size}&page=${page}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  pageFilterByPeriodo( dataInicial: number, dataFinal: number, size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get<any[]>(`${this.sgf}?size=${size}&page=${page}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&${this.posto}&sort=-dataConfirmacao`)
      .pipe(
        catchError(this.handleError)
      )
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<any[]>(`${this.sgf}/${id}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
      this.idPosto = Number.isInteger(user.idPosto) ? user.idPosto : null;
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };
}
