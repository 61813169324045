import { Component, OnInit, Input, Inject, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../../message-dialog/message-dialog.component';
import { ReplaySubject, Subject } from 'rxjs';
import { RoleModel } from '../../../model/role/role.model';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { VehiclesModel } from 'app/main/shared/model/vehicles/vehicles.model';
import { DiscountModel } from 'app/main/shared/model/discount/discount.module';
import { ImpostModel } from 'app/main/shared/model/impost/impost.model';
import { BenefitsModel } from 'app/main/shared/model/benefits/benefits.model';
import { Mask } from '@fagnerlima/ng-mask';
import { Posto } from 'app/main/shared/model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

interface DialogData {
  employee: Employee;
  action: string;
  veiculos: Array<VehiclesModel>;
  cargos: Array<RoleModel>;
  descontos: Array<DiscountModel>;
  impostos: Array<ImpostModel>;
  beneficios: Array<BenefitsModel>;
}


@Component({
  selector: 'app-employee-pessoal-form',
  templateUrl: './employee-pessoal-form.component.html',
  styleUrls: ['./employee-pessoal-form.component.scss'],
  animations: fuseAnimations
})
export class EmployeePersonalFormComponent implements OnInit, AfterViewInit {

  @Input() employee: FormGroup;
  maska: string;
  activeCheck: string;
  dialogRef: any;
  step = 0;

  @Output() isCpfEvent: EventEmitter<any> = new EventEmitter();

  cargos: Array<RoleModel>;
  postos: Array<Posto>;

  maskCpf = new Mask('000.000.000-00');
  maskCnpj = new Mask('00.000.000/0000-00');

  @ViewChild('nome') nome: ElementRef;

  public cargoFilterControl: FormControl = new FormControl();
  public filteredCargo: ReplaySubject<RoleModel[]> = new ReplaySubject<RoleModel[]>(1);

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private postoService: PostsService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
    this.activeCheck = (_data.employee.ativo) ? 'Ativo' : 'Inativo';

    this.cargos = this._data.cargos;

    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPosto();
        });
    });

    this.filteredCargo.next(this.cargos.slice());
    this.cargoFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCargos();
      });

  }

  ngOnInit(): void {
    // tslint:disable-next-line:triple-equals
    if (this.employee.get('pessoa').get('listTelefone').value.length == 0) {
      this.addPhone();
    }
  }

  

  ngAfterViewInit(): void {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }

  // tslint:disable-next-line:member-ordering
  private _onDestroy = new Subject<void>();

  get phoneForms(): FormArray {
    return this.employee.get('pessoa').get('listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null, Validators.required),
      id: new FormControl(null)
    });

    this.phoneForms.push(phone);
  }

  private filterCargos(): void {
    if (!this.cargos) {
      return;
    }

    let search = this.cargoFilterControl.value;
    if (!search) {
      this.filteredCargo.next(this.cargos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCargo.next(
      this.cargos.filter(cargo => cargo.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  setSalario(value: number): void {
    this.employee.get('salarioBase').setValue(value);
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  setCpfField(isCpf: boolean): void {
    this.employee.get('isCpf').setValue(isCpf);
    this.isCpfEvent.emit(isCpf);
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }

    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  messageDialog(msg: string, icon: string): void {
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: [msg, icon]
    });
  }

}
