import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../main/authentication/services/authentication.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService
  ) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const authToken = sessionStorage.getItem('Authorization');

    if(sessionStorage.getItem('usuarioNoturno') === 'true') {
      const today = new Date();
      if (today.getHours() < 18 || today.getHours() > 22){
        this.authService.logout();
      }
    }
    
    const authRequest = httpRequest.clone({
      headers: httpRequest.headers.set('Authorization', authToken)
    });

    return next.handle(authRequest);
  }
}
