import { Component, OnInit, Inject, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSelect } from '@angular/material';
import {
    Validators,
    FormBuilder,
    FormControl,
} from '@angular/forms';
import * as _ from 'lodash';
import { Team } from '../../model/team/team.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ActivityFieldService } from '../../../global/components/activity-field/services/activity-field.service';
import { Subject, ReplaySubject } from 'rxjs';
import { Activity } from '../../model/activity/activity.model';
import { takeUntil } from 'rxjs/operators';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { EmployeeAssignment } from '../../model/enums/employee-assignment.enum';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { Rota } from '../../model/rota/rota.model';
import { data } from '../../data/chart-data';

export interface DialogData {
    rota: Rota;
    team: Team;
    action: string;
}

@Component({
    selector: 'app-rota-form',
    templateUrl: './rota-form.component.html',
    styleUrls: ['./rota-form.component.scss']
})

/**
 * @name RotaFormComponent
 * @description
 * Representação do formulário da Equipe com suas validações
 *
 */

export class RotaFormComponent implements OnInit, OnDestroy, AfterViewInit {
    rota: any;
    titleHeader: string;
    fucaoEnum: EmployeeAssignment;

    @ViewChild('descricao') descricao: ElementRef;

    public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));

    listAreaAtuacao: any[] = [];
    areaAtuacaoList: Array<Activity>;
    supervisor: any;
    cobrador: any;
    equipe: any;
    listDescricaoArea = [];
    listFuncionarios = [];
    listCobrador = [];
    listEquipe = [];
    listVendCob = [];
    dialogMessages;
    areaAtuacao: string;
    areasSelecionadas = [];
    passadorName = [];
    vendedoresName = [];
    cobradoresName = [];
    supervisorName = [];
    areaDeAtuacaoName = [];
    funcinarios: Employee[];
    disabledButton: boolean;
    selectedTuno = 'MANHA';

    public actuationAreaFilterCtrl: FormControl = new FormControl();
    public filteredActuationArea: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    public cobradorFilterCtrl: FormControl = new FormControl();
    public filteredCobrador: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

    private IDENTIFICADOR_COBRADOR = 3;

    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private employeeService: EmployeeService,
        private rotaService: RotasService,
        private activityFieldService: ActivityFieldService,
        public matDialogRef: MatDialogRef<RotaFormComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
        this.changeTitleHeader();

        this.activityFieldList();
        
        if (this.usuario.idPosto != null) {
            this.employeerList(this.usuario.idPosto);
        }

    }

    @ViewChild('singleSelect') singleSelect: MatSelect;
    @ViewChild('multiSelect') multiSelect: MatSelect;
    private _onDestroy = new Subject<void>();

    ngOnInit(): void {
       
        this.setFormGroup();
       
    }

    changeTitleHeader(): void {
        // tslint:disable-next-line:triple-equals
        if (this._data.action == 'new') {
            this.titleHeader = 'Nova Rota';
        } else {
            this.titleHeader = 'Editar Rota';
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    ngAfterViewInit(): void {
        setTimeout(() => this.descricao.nativeElement.focus(), 1000);
      }

    setFormGroup(): void {
        this.listAreaAtuacao = this._data.rota ? this._data.rota.listAreaAtuacao : [];
        let cobrador = null;
        let area = null;
        // tslint:disable-next-line:triple-equals
        if (this._data.action == 'update') {
            cobrador = this._data.rota.cobrador;
            this._data.rota.listAreaAtuacao.forEach(p => {
                area = this._data.rota.listAreaAtuacao;
              });
        }

        if (this.listAreaAtuacao != null){
            // tslint:disable-next-line:no-shadowed-variable
            this.listDescricaoArea = this.listAreaAtuacao.map(area => area.nome) || null;
            // tslint:disable-next-line:no-shadowed-variable
            this.areasSelecionadas = this.listAreaAtuacao.map(area => area) || null;
        }

        this.rota = this.formBuilder.group({
            id: this._data.rota.id || null,
            descricao: [this._data.rota.descricao, Validators.required],
            turno: [this._data.rota.turno, Validators.required],
            listAreaAtuacao: [this._data.rota ? this.listDescricaoArea : null, Validators.required],
            cobrador: [cobrador ? this._data.rota.cobrador.id : null],
            noturna: [this._data.rota.noturna || false, Validators.required],
        });
    }

    /**
     * @description Método de Inserção de Equipe no banco de dados,
     * É iterado os array do FormGroup e inseridos em um array de Funcionarios,
     * depois é instanciado um objeto do Tipo Team passando o valor do FormGroup
     * @param data - Objeto do tipo any.
     * @return void
     *
     */


    onAdd(res): void {
        this.disabledButton = true;
        const areaAtuacao = [];
        
        this.areaAtuacaoList.forEach(area => {
            res.listAreaAtuacao.forEach(a => {
                // tslint:disable-next-line:triple-equals
                if (area.nome == a) {
                    areaAtuacao.push(area);
                }
            });
        });

        this.employeeService.findById(res.cobrador).subscribe(r => {
            res.cobrador = r.object;

            res.listAreaAtuacao = areaAtuacao;

            this.saveRota(res);

        });
    }

    /**
     * @description Método de Atualização de Equipe no banco de dados,
     * É iterado os array do FormGroup e inseridos em um array de Funcionarios,
     * depois é instanciado um objeto do Tipo Team passando o valor do FormGroup
     *
     * @param data - Objeto do tipo any.
     * @return void
     *
     */

    onUpdate(res): void {
        
        this.disabledButton = true;
        const areaAtuacao = [];

        this.areaAtuacaoList.forEach(area => {
            res.listAreaAtuacao.forEach(a => {
                // tslint:disable-next-line:triple-equals
                if (area.nome == a) {
                    areaAtuacao.push(area);
                }
            });
        });

        this.employeeService.findById(res.cobrador).subscribe(r => {
          
            res.cobrador = r.object;
            res.listAreaAtuacao = areaAtuacao;
          
            this.saveRota(res);
            
        });
    }

    private saveRota(rota: any): void {

        this.rotaService.add(rota).subscribe(reuslt => {

            if (reuslt.success) {
                this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `${reuslt.messages[0].text}`
                };

                this.feedback(this.dialogMessages);

                this.matDialogRef.close(reuslt);
            } else {
                if (reuslt.messages != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${reuslt.messages[0].text}`
                    };
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    };
                }
                this.feedback(this.dialogMessages);
            }
        });
    }



    private employeerList(idPosto: number): void {

        this.employeeService.findAllByCargoAndPosto(this.IDENTIFICADOR_COBRADOR, idPosto).subscribe(result => {
            this.listFuncionarios = result.object;
            this.listCobrador = result.object;
            this.listCobrador = this.listCobrador.filter(cobrador => cobrador.rota == null);
            this.filteredCobrador.next(this.listCobrador.slice());

            this.cobradorFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterCobrador();
                });

        });
    }

    private activityFieldList(): void {
        this.activityFieldService.listAll().subscribe(a => {
            
            this.areaAtuacaoList = a.object.content;

            this.filteredActuationArea.next(this.areaAtuacaoList.slice());
           
            this.actuationAreaFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterActuationArea();
                });
        });
    }

    onClosed(): void {
        this.matDialogRef.close();
    }

    getErrorMessage(field: string): any {
        return `O campo ${field} é obrigatório`;
    }

    onChange(event): void {  // event will give you full breif of action
        const newVal = event.target.value;
    }

    private feedback(message: string[]): void {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }

    private filterActuationArea(): void {    

        if (!this.areaAtuacaoList) {
            return;
        }

        let search = this.actuationAreaFilterCtrl.value;
        if (!search) {
            this.filteredActuationArea.next(this.areaAtuacaoList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredActuationArea.next(
            this.areaAtuacaoList.filter(area => { 
                return area.nome.toLowerCase().indexOf(search) > -1;
            })
        );
    }

    private filterCobrador(): void {
        if (!this.listCobrador) {
            return;
        }

        let search = this.cobradorFilterCtrl.value;
        if (!search) {
            this.filteredCobrador.next(this.listCobrador.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCobrador.next(
            this.listCobrador.filter(cobrador => cobrador.pessoa.nome.toLowerCase().indexOf(search) > -1)
        );
    }
}
