import { Municipality } from '../municipality/municipality.model';
import { Estado } from '../estado/estado.model';

export class Address {
    ativo?: boolean;
    bairro?: string | any;
    cep?: string;
    complemento?: string;
    id?: number;
    latitude?: number;
    logradouro?: string;
    logradouroCompleto?: string;
    longitude?: number;
    municipio?: Municipality;
    numero?: string;
    pontoReferencia: string;
    estado?: Estado;
    cidade?: string;
    tipoEndereco: string;

    constructor(obj?: any) {
        this.ativo = obj.ativo || true;
        this.bairro = obj.bairro || null;
        this.cep = obj.cep || null;
        this.complemento = obj.complemento || null;
        this.latitude = obj.latitude || null;
        this.logradouro = obj.logradouro || null;
        this.logradouroCompleto = obj.logradouroCompleto || null;
        this.longitude = obj.longitude || null;
        this.municipio = obj.municipio || null;
        this.numero = obj.numero   || null;
        this.pontoReferencia = obj.pontoReferencia || null;
        this.estado     = obj.estado       || null;
        this.cidade     = obj.cidade       || null;
        this.tipoEndereco = obj.tipoEndereco || null;
    }
}
