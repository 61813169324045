import { ReferenciaPix } from './../../../../shared/model/referencia-pix/referencia-pix.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenciaPixServiceService {
  host = `${SGF_API}` + 'sgf/api/public/referencia-pix';

  constructor(private httpClient: HttpClient) { }

  findAllByClienteIdAndSituacao(clienteId: number, situacao: boolean): Observable<ResponseAPI> {
    const ativo = `ativo=${situacao}`
    const cliente = `clienteId=${clienteId.toString()}`;
    return this.httpClient.get<ResponseAPI>(`${this.host}/get-by-cliente-situacao?${cliente}&${ativo}`);
  }

  saveByCliente(clienteId: number, referencias: ReferenciaPix[]): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/${clienteId.toString()}`, referencias);
  }
}
