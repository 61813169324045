import { RouterModule, Routes } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoletoMainComponent } from './boleto-main/boleto-main.component';
import { BoletoListComponent } from './boleto-list/boleto-list.component';
import { BoletoSidebarComponent } from './boleto-sidebar/boleto-sidebar.component';
import { SharedMaterialComponentsModule } from 'app/shared-material-components.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/main/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ContractsService } from '../../services/contracts.service';

const routes: Routes = [
  {
    path: '',
    component: BoletoMainComponent,
    data: {situacao: 'LIBERADOS'}
  },
  {
    path: 'repassados',
    component: BoletoMainComponent,
    data: {situacao: 'REPASSADOS'}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})
  ],
  declarations: [BoletoMainComponent, BoletoListComponent, BoletoSidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContractsService]
})
export class BoletoModule { }
