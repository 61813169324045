import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { Page } from 'app/main/shared/model/page/page.model';
import { AcaoPixMain, PixMainComponent } from '../pix-main/pix-main.component';

@Component({
  selector: 'app-pix-list',
  templateUrl: './pix-list.component.html',
  styleUrls: ['./pix-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class PixListComponent implements OnInit {

  @Input() page: Page = new Page();
  @Input() rows: Contract[] = [];

  @Output() acaoEmitter: EventEmitter<AcaoPixMain> = new EventEmitter<AcaoPixMain>();

  timeout: any;
  @Output() pageNumber = new EventEmitter<any>();

  selected: Contract[] = [];

  constructor() { }

  ngOnInit(): void { }

  onPage(event): void{
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
    this.pageNumber.emit(event.offset + 1);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  confirmarAssinaturaDigital(contrato: Contract): void {
    this.acaoEmitter.emit({acao: PixMainComponent.ACAO_CONFIRMAR_ASSINATURA_DIGITAL, contrato});
  }

  confirmarRepassePix(contrato: Contract): void {
    this.acaoEmitter.emit({acao: PixMainComponent.ACAO_CONFIRMAR_REPASSE_PIX, contrato});
  }

  imprimirContrato(contrato: Contract): void {
    this.acaoEmitter.emit({acao: PixMainComponent.ACAO_IMPRIMIR_CONTRATO, contrato});
  }

  imprimirComprovante(contrato: Contract): void {
    this.acaoEmitter.emit({acao: PixMainComponent.ACAO_IMPRIMIR_COMPROVANTE_PIX, contrato});
  }

  imprimirAssinaturaDigital(contrato: Contract): void {
    this.acaoEmitter.emit({acao: PixMainComponent.ACAO_IMPRIMIR_ASSINATURA_DIGITAL, contrato});
  }
}
