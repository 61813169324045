import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { SGF_TYPE, SGF_API } from 'app/shared/api/sgf.api';
import { NgxPermissionsService } from 'ngx-permissions';
import * as FileSaver from 'file-saver';
import { worker } from 'cluster';

@Component({
  selector: 'app-parcela-list',
  templateUrl: './parcela-list.component.html',
  styleUrls: ['./parcela-list.component.scss'],
  animations: fuseAnimations
})
export class ParcelaListComponent implements OnInit {
  @Input() rows: any;
  timeout: any;
  @Input() selectedClear;
  @Input() contrato;

  @Output() selectEvent = new EventEmitter<any>();
  selected: Array<Parcela>;
  @Output() alterarResponsavelEvent = new EventEmitter<any>();
  @Output() alterarParcelaEvent = new EventEmitter<any>();
  @Output() receberParcelaEvent = new EventEmitter<any>();
  @Output() confirmParcelaEvent = new EventEmitter<any>();
  @Output() overEvent = new EventEmitter<any>();
  @Output() boletoEvent = new EventEmitter<any>();
  permissoes = [];
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  fortBoletos = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;

  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  host = `${SGF_API}financeiro/api/public/boleto/gerar-boleto`;
  superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));

  dialogMessages: any;
  constructor(private _matDialog: MatDialog,
    private cobrancaService: CobrancasService,
    private permissionsService: NgxPermissionsService) {
    this.selected = new Array<Parcela>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'))
    perm.forEach(element => {
      this.permissoes.push(element)
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {
    console.log(this.superUsuario)
  }

  ngOnChanges() {
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectCostumers();
  }

  onSelectCostumers() {
    this.selectEvent.emit(this.selected);
  }

  getId(row) {
    return row.id;
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
  alterarResponsavel(parcela) {
    this._matDialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'contrato',
        cobranca: this.contrato
      }
    }).afterClosed().subscribe(
      data => {
        parcela.responsavel = data.funcionario;
        this.cobrancaService.alterarResponsavel(parcela).subscribe((data) => {
          if (data.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${data.messages[0].text}`
            };
            this.feedback(this.dialogMessages);
            this.overEvent.emit(true)
          } else {
            if (data.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${data.messages[0].text}`
              }
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              }
            }
            this.feedback(this.dialogMessages);
            this.overEvent.emit(true)
          }
        })
      });
  }

  cancelarParcela(idParcela) {
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: 'Deseja cancelar esta parcela?',
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'
    }
    this._matDialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {
        this.cobrancaService.cancelarParcela(idParcela).subscribe((data) => {
          if (data.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${data.messages[0].text}`
            };
            this.feedback(this.dialogMessages);
            this.overEvent.emit(true)
          } else {
            if (data.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${data.messages[0].text}`
              }
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              }
            }
            this.feedback(this.dialogMessages);
            this.overEvent.emit(true)
          }
        })
      }
    });
  }

  cancelarBoleto(idParcela) {
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: 'Deseja cancelar este boleto?',
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'
    }
    this._matDialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {
        this.cobrancaService.cancelarBoleto(idParcela).subscribe((data: Blob | any) => {
          if (data) {
            FileSaver.saveAs(data, `remessa_${idParcela}.txt`);
          }
          this.overEvent.emit(true)
        })
      }
    });
  }

  alterarParcela(value): void {
    this.alterarParcelaEvent.emit(value)
  }

  receberParcela(value) {
    this.receberParcelaEvent.emit(value)
  }
  confirmarParcela(parcela) {
    this.confirmParcelaEvent.emit(parcela)
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  public gerarBoleto(parcelaId: number): void {
    this.cobrancaService.gerarBoleto(parcelaId).subscribe((data: Blob | any) => {
      if (data && data instanceof Blob) {
        FileSaver.saveAs(data, 'boleto.pdf');
      } else {
        this.dialogMessages = {
          type: 'error',
          title: 'Error!',
          text: data ? `${data.messages[0].text}` : 'Erro desconhecido.'
        };
        this.feedback(this.dialogMessages);
        this.overEvent.emit(true);
      }
    });
  }
}
