import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cliente-spc',
  templateUrl: './cliente-spc.component.html',
  styleUrls: ['./cliente-spc.component.scss']
})
export class ClienteDevedorComponent implements OnInit {

  public clienteFilterControl: FormControl = new FormControl();

  devedor: FormGroup;
  cliente:any;
  disabledButton: boolean;
  titleHeader: string;
  text: string;

   /**
   * Constructor
   *
   * @param {MatDialogRef<ClienteDevedorComponent>} matDialogRef
   * @param _data
   */
  constructor(
    private matDialogRef: MatDialogRef<ClienteDevedorComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(this.data.action === 'inativar') {
        this.titleHeader = 'Atualizar Situação do Cliente.';
        this.text = 'Você realmente deseja colocar o cliente como inativo?';
      } else if(this.data.action === 'cobranca judicial') {
        this.titleHeader = 'Atualizar Situação do Cliente.';
        this.text = 'Você realmente deseja colocar o cliente como Cobrança Judicial?';
      } else {
        this.titleHeader = 'Adicionado(a) ao SPC.';
        this.text = 'Você realmente deseja colocar o cliente como devedor?';
      }
  }

  ngOnInit() {
    this.cliente = this.data['data'];
  }

  onNoClick(data?): void {
    this.disabledButton = true;
    if (data) {
      let cliente = data.data;
      this.matDialogRef.close(cliente);
    } else {
      this.matDialogRef.close();
    }
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
