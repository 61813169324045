import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Employee } from '../../model/employee/employee.model';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-relatorio-contrato-cobrancas',
  templateUrl: './relatorio-contrato-cobrancas.component.html',
  styleUrls: ['./relatorio-contrato-cobrancas.component.scss']
})
export class RelatorioContratoCobrancasComponent implements OnInit {

  form: FormGroup
  employee: Employee[]
  dialogMessages

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  constructor(private formBuild: FormBuilder,
    private dialogRef: MatDialogRef<RelatorioContratoCobrancasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService,
    private contratoService: ContractsService,
    private dialog: MatDialog) {

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    })
  }

  private _onDestroy = new Subject<void>();


  ngOnInit() {
    this.form = this.formBuild.group({
      idResponsavel: [null, Validators.required],
      dataVencimento: [new Date() || null, Validators.required]
    })

  }

  gerarRelatorio(value) {
    let dataVencimento = Date.parse(value.dataVencimento);
    let date = new Date(dataVencimento)
    this.contratoService.imprimirContratosCobrancas(value.idResponsavel, date.toLocaleDateString('pt-br').toString()).subscribe((file: Blob) => {
      FileSaver.saveAs(file, 'relatorioContratosCobrancas.pdf');
      this.dialogRef.close();
    },
      (onerror) => {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Nenhuma cobrança encontrada`
        }
        this.dialogRef.close();
        this.feedback(this.dialogMessages)
      })
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }


}
