import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material';
import { FolhaCobrancaTransferenciaEditComponent } from '../folha-cobranca-transferencia-edit/folha-cobranca-transferencia-edit.component';

@Component({
  selector: 'app-folha-cobranca-transferencia-list',
  templateUrl: './folha-cobranca-transferencia-list.component.html',
  styleUrls: ['./folha-cobranca-transferencia-list.component.scss'],
  animations: fuseAnimations
})
export class FolhaCobrancaTransferenciaListComponent implements OnInit {
  @Input() rows: any;
  timeout: any;
  @Output() overEvent = new EventEmitter<any>();

  constructor(private _matDialog: MatDialog,) {
  }

  ngOnInit() {
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }

  getId(row) {
    return row.id;
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  public editarTransferencia(row: any): void {
    this._matDialog.open(FolhaCobrancaTransferenciaEditComponent, {
      data: {row}
    }).afterClosed().subscribe(res => {
      if (res) {
        this.overEvent.emit(true);
      }
    });
  }
}
