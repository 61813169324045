import { Injectable } from '@angular/core';
import { BancoService } from '../services/banco-service.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveBancoService {

  constructor(private bancoService: BancoService) { }


  resolve(): Observable<any> {
    return this.bancoService.findAll().pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
