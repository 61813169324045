import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from '../../../../shared/model/contract/contract.model';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Page } from 'app/main/shared/model/page/page.model';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { ToCancelSidebarComponent } from '../to-cancel-sidebar/to-cancel-sidebar.component';

@Component({
  selector: 'app-to-cancel-main',
  templateUrl: './to-cancel-main.component.html',
  styleUrls: ['./to-cancel-main.component.scss'],
  animations: fuseAnimations
})
export class ToCancelMainComponent implements OnInit {

  rows: Array<Contract> = [];
  itensAPI: Array<Contract>;
  filter: string;
  contractValue: any;

  interval;
  page = new Page();
  public loading = false;
  dialogMessages;
  search = '';
  pageNumber;
  situacao = 'AGUARDANDO_CANCELAMENTO';
  dataPeriodo;
  isPeriodo: boolean = false;

  lastFilter: string;
  lastSearchFilter;

  @ViewChild(ToCancelSidebarComponent)
  private sidebar: ToCancelSidebarComponent;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private contractService: ContractsService) {

    if (!this.hasLastFilters()) {
      this.route.data.subscribe(
        (res) => {
          this.rows = res.rows.content;
          this.rows.forEach(contrato => {
            contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
          })
          this.itensAPI = this.rows;
          let page = res.rows;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page)
        }
      );
    }

  }

  ngOnInit() { this.hasLastFilters() }

  over() {
    this.loading = true;
    this.search = null;
    this.contractService.pageCancelar(10, 1, 'dataFinalizacao')
      .subscribe(
        (res) => {
          this.rows = res.object.content
          let page = res.object;
          page.pageNumber = 0;
          this.setPage(page)
          this.itensAPI = this.rows;
          this.loading = false;
          this.rows.forEach(contrato => {
            contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
          })
        }
      )
  }

  cancelarContrato(contrato: any): void {
    contrato.parecerCancelamento = 'Repasse não efetuado';
    
    this.contractService.cancelContract(contrato).subscribe(data => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `Contrato cancelado com Sucesso`
        }
        this.feedback(this.dialogMessages);
        this.over();
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
        this.feedback(this.dialogMessages);
        this.over();
      }
    })
  }

  setPage(data) {
    //Utilizando para o pageable
    this.page.pageNumber = data ? data.pageNumber : 0;
    this.page.totalPages = data ? data.totalPages : 0
    this.page.size = data ? data.size : 0
    this.page.totalElements = data ? data.totalElements : 0
  }

  getDataFilter(event) {
    let page = event;
    page.pageNumber = 0;
    this.setPage(page);
    this.rows = event.content;
    this.rows.forEach(contrato => {
      contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
    })
  }

  getPeriodoFilter(event): void {
    this.isPeriodo = true;
    this.dataPeriodo = event;
    console.log(this.dataPeriodo);
    
  }

  getSearchFilter(event) {
    this.search = event;
  }

  getSituacao(event) {
    this.situacao = event;
  }

  getFilter(event) {
    this.filter = event;
    if (event == 'all') {
      this.isPeriodo = false;
      this.over();
      this.filter = 'NOME_CLIENTE'
      this.situacao = 'AGUARDANDO_CANCELAMENTO'
    }
  }

  // Para Cada página ser de acordo com o filtro
  getPage(event) {
    this.isPeriodo = false;
    this.loading = true;
    this.pageNumber = event ? event : 1
    if (this.filter == null && this.search != null) {
      this.filter = 'NOME_CLIENTE'
      this.situacao = 'AGUARDANDO_CANCELAMENTO';
    }

    if (this.filter == 'PERIODO' || this.filter == 'RESPONSAVEL_CONTRATO' || this.filter == 'NOME_CLIENTE' || this.filter == 'DOCUMENTO_CLIENTE') {

      this.situacao = 'AGUARDANDO_CANCELAMENTO'
    } else {

      this.situacao = this.filter

    }

    switch (this.filter) {

      case 'NOME_CLIENTE':
        if (this.search == '' || this.search == null) {
          this.contractService.pageCancelar(10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
            })
            this.loading = false;

            let page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page)
          })
        } else {
          this.contractService.pageCancelar(10, this.pageNumber).subscribe(data => {
            this.rows = data.object.content;
            this.rows.forEach(contrato => {
              contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
            })
            this.loading = false;

            let page = data.object;
            page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
            this.setPage(page)
          })
        }
        break

        case 'ROTA':
          if (this.search == '' || this.search == null) {
            this.contractService.pageCancelar(10, this.pageNumber).subscribe(data => {
              this.rows = data.object.content;
              this.rows.forEach(contrato => {
                contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              })
              this.loading = false;
  
              let page = data.object;
              page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
              this.setPage(page)
            })
          } else {
            this.contractService.pageByRotaCancelar(this.search, 10, this.pageNumber).subscribe(data => {
              this.rows = data.object.content;
              this.rows.forEach(contrato => {
                contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
              })
              this.loading = false;
  
              let page = data.object;
              page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
              this.setPage(page)
            })
          }
          break

          case 'PERIODO':
            this.isPeriodo = true;
            this.loading = true;
            // this.valorTotalContratos = 0;
            // tslint:disable-next-line:triple-equals
            if (this.dataPeriodo == '') {
              this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataVencimento').subscribe(res => {
                this.loading = false;
                this.rows = res.data.findAllPageable.content;
                this.itensAPI = this.rows;
                const page = res.data.findAllPageable;
                page.pageNumber = 0;
                this.setPage(page);
              });
    
            } else {
              this.contractService.pageContratoByPeriodo(this.situacao, 10, this.pageNumber, '+dataVencimento', this.dataPeriodo).subscribe(res => {
                this.loading = false;
                this.rows = res.object.content;
                this.itensAPI = this.rows;
                const page = res.object;
                page.pageNumber = 0;
                this.setPage(page);
              });
            }
            break;

      case null:
        this.contractService.pageCancelar(10, this.pageNumber).subscribe(data => {
          this.rows = data.object.content;
          this.rows.forEach(contrato => {
            contrato.valorContrato = contrato.valorLiquido * 100 / (100 - contrato.taxaJuros);
          })
          this.loading = false;

          let page = data.object;
          page.pageNumber = this.pageNumber ? this.pageNumber - 1 : 0;
          this.setPage(page)
        })
        break
    }
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  clearFilter() {
    this.filter = null;
    this.search = null;
    this.situacao = 'AGUARDANDO_CANCELAMENTO'
    this.sidebar.clear();
    this.over();
  }

  hasLastFilters(): boolean {

    if (this.route.snapshot.queryParamMap.get('filter') == null
      && this.route.snapshot.queryParamMap.get('search') == null) {

      this.lastFilter = null;
      this.lastSearchFilter = null
      return false

    }

    if (this.route.snapshot.queryParamMap.has('filter')) {
      this.lastFilter = this.route.snapshot.queryParamMap.get('filter')
    }

    if (this.route.snapshot.queryParamMap.has('search')) {
      this.lastSearchFilter = this.route.snapshot.queryParamMap.get('search')
    }
    this.checkingLastFilters()
    return true

  }


  checkingLastFilters() {

    if (this.lastFilter == null && this.lastSearchFilter == null) {
      this.search = ''
      this.filter = 'NOME_CLIENTE'
    } else if (this.lastFilter != null && this.lastSearchFilter == null) {
      this.filter = this.lastFilter
      this.search = ''
    } else if (this.lastFilter != null && this.lastSearchFilter != null) {
      this.filter = this.lastFilter
      this.search = this.lastSearchFilter
    }

    if (this.filter == 'PERIODO' || this.filter == 'RESPONSAVEL_CONTRATO' || this.filter == 'NOME_CLIENTE' || this.filter == 'DOCUMENTO_CLIENTE') {

      this.situacao = 'AGUARDANDO_CANCELAMENTO'
    } else {
      this.situacao = this.filter

    }

  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  getTotalContractValue(event: number) {
    this.contractValue = event;
  }
}
