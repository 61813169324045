import { Injectable } from '@angular/core';
import { HolidayService } from '../services/holiday.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResolveHolidayService implements Resolve<any>{

  constructor(
    private holidayService: HolidayService) { }

  resolve(): Observable<any> {
    return this.holidayService.findAll().pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
