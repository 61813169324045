import { Component, OnInit, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-acompanhamento-novatos-inativos-main',
  templateUrl: './acompanhamento-novatos-inativos-main.component.html',
  styleUrls: ['./acompanhamento-novatos-inativos-main.component.scss'],
  animations: fuseAnimations
})
export class AcompanhamentoNovatosInativosMainComponent implements OnInit {

  posto: number;
  acompanhamentoNovoInativo: any;
  dialogMessages;
  dataInicial: number = new Date().getTime();
  dataFinal: number = new Date().getTime();

  constructor(private contratoService: ContractsService,
    public dialog: MatDialog) {
    const user = JSON.parse(sessionStorage.getItem('funcionario'));
    this.posto = user.idPosto ? user.idPosto : null;
  }
  
  ngOnInit() {
    this.over();
  }

  private feedback(message: string[]): void  {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  over() {
    this.contratoService.getTotalNovoInativos(this.posto, this.dataInicial, this.dataFinal).subscribe(data => {
      if (data.success) {
        this.acompanhamentoNovoInativo = data.object;
        this.acompanhamentoNovoInativo.totalInativos = this.acompanhamentoNovoInativo.totalEntregueInativo + this.acompanhamentoNovoInativo.totalNaoEntregueInativo
        this.acompanhamentoNovoInativo.valorInativos = this.acompanhamentoNovoInativo.valorEntregueInativo + this.acompanhamentoNovoInativo.valorNaoEntregueInativo
        this.acompanhamentoNovoInativo.totalNovos = this.acompanhamentoNovoInativo.totalEntregueNovo + this.acompanhamentoNovoInativo.totalNaoEntregueNovo
        this.acompanhamentoNovoInativo.valorNovos = this.acompanhamentoNovoInativo.valorEntregueNovo + this.acompanhamentoNovoInativo.valorNaoEntregueNovo
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
      } 
    });
  }

  getFilter(event) {
    this.dataInicial = new Date(event.dateInit).getTime();
    this.dataFinal = new Date(event.dateEnd).getTime();
    this.over();
  }
}
