import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output, ElementRef, AfterViewInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatSelect, MatDialog } from '@angular/material';
import { Subject, ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { MessageDialogComponent } from '../../message-dialog/message-dialog.component';
import { Segment } from '../../../model/segment/segment.model';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { Information } from 'app/main/shared/model/information/information.model';
import { Customer } from 'app/main/shared/model/customer/customer.model';
import { CaptacaoService } from 'app/main/customers/components/captacao/services/captacao.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { ObservacaoCliente } from 'app/main/shared/model/observacao-cliente/observacao-cliente.model';

export interface UploadSPC {
  clienteId: number;
  nomeDocumento: string;
  documentoBase64: string;
}

@Component({
  selector: 'app-dados-cliente',
  templateUrl: './dados-cliente.component.html',
  styleUrls: ['./dados-cliente.component.scss'],
  animations: fuseAnimations,
})
export class DadosClienteComponent implements OnInit, AfterViewInit {
  @ViewChild('nome') nome: ElementRef;

  indicadores = [];

  @Input() customerId;
  @Input() cliente: FormGroup;
  @Output() employeeLoggedEmitter: EventEmitter<Employee> = new EventEmitter();
  @Output() isCpfEvent: EventEmitter<any> = new EventEmitter();

  maskCpf = new Mask('000.000.000-00');
  maskCnpj = new Mask('00.000.000/0000-00');

  maska: string;
  dialogRef: any;
  snackbarRef: any;
  isCpf = true;

  step = 0;
  totalCustomer: number;
  showPanelId: Boolean;
  currentCustomer: Customer;
  customerInformation: Information;
  telephones: FormArray;
  filteredOptions: Array<Segment>;
  listFuncionarios: Array<Employee>;
  listObservacoes: Array<ObservacaoCliente>;
  employeeLogged: Employee;

  documento: File;
  nomeDocumento = '';
  documentoBase64 = '';
  showAnexoSPC = false;

  public indicadorFilterControl: FormControl = new FormControl();
  public filteredIndicadores: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public segmentFilterCtrl: FormControl = new FormControl();
  public filteredSegment: ReplaySubject<Segment[]> = new ReplaySubject<Segment[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private captacaoService: CaptacaoService,
    private employeeService: EmployeeService,
    private clienteService: ClienteService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    this.captacaoService.getAtividade().subscribe((ondata) => {
      this.filteredOptions = ondata.object.content;
      this.filteredSegment.next(this.filteredOptions.slice());
      this.segmentFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterAtividadeComercial();
      });
    });

    this.employeeService.findAll().subscribe((data) => {
      this.listFuncionarios = data.object.content;
      // tslint:disable-next-line:triple-equals
      this.employeeLogged = data.object.content.filter((filter) => filter.id == this.getEmployeeUserLogged())[0];
      this.employeeLoggedEmitter.emit(this.employeeLogged);

      this.filteredIndicadores.next(this.listFuncionarios.slice());
      this.indicadorFilterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterIndicador();
      });
    });

    this.clienteService.findAllObservacaoCliente().subscribe((ondata) => {
      this.listObservacoes = ondata.object;
    });

    if (this._data.customer && this._data.customer.situacaoCliente === 'NOVO') {
      this.showAnexoSPC = true;
    }
  }

  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }

  get phoneForms(): FormArray {
    return this.cliente.get('pessoa').get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null),
    });

    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  setCpfField(isCpf: boolean, type: string): void {
    this.cliente.get('pessoa').get('isCpf').setValue(isCpf);
    this.cliente.get('pessoa').get('type').setValue(type);
    this.isCpfEvent.emit(isCpf);
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  private filterIndicador(): void {
    if (!this.listFuncionarios) {
      return;
    }

    let search = this.indicadorFilterControl.value;
    if (!search) {
      this.filteredIndicadores.next(this.listFuncionarios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredIndicadores.next(this.listFuncionarios.filter((indicador) => indicador.pessoa.nome.toLowerCase().indexOf(search) > -1));
  }

  private filterAtividadeComercial(): void {
    if (!this.filteredOptions) {
      return;
    }

    let search = this.segmentFilterCtrl.value;
    if (!search) {
      this.filteredSegment.next(this.filteredOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSegment.next(this.filteredOptions.filter((segment) => segment.descricao.toLowerCase().indexOf(search) > -1));
  }

  getEmployeeUserLogged(): void {
    return JSON.parse(sessionStorage.getItem('funcionario')).idFuncionario;
  }

  messageDialog(msg: string, icon: string): void {
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: [msg, icon],
    });
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onFileSelected(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files.item(0);
    this.documento = file;
    this.nomeDocumento = file.name;

    const reader = new FileReader();
    const future = new Promise((resolve) => {
      reader.addEventListener('loadend', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });

    this.cliente.get('fileName').setValue(this.nomeDocumento);
    
    future.then(data => {
      this.transformBlobTo64(data);

      const upload: UploadSPC = {clienteId: this._data.customer.id, nomeDocumento: this.nomeDocumento, documentoBase64: this.documentoBase64};
    
      this.cliente.get('file').setValue(upload);
    });
  }

  transformBlobTo64(result: any): void {
    this.documentoBase64 = result;
  }

  changeNome(nome: any) {
    this.cliente.get('pessoa').get('nome').setValue(nome);
  }
}
