export class DashBoardFaturamentoTO {
    quantidadeEntregue: number;
    quantidadeNaoEntregue: number;
    percentualEntregue: number;
    percentualNaoEntregue: number;
    quantidadeTotal: number;
    valorTotalEntregue: number;
    valorTotalNaoEntregue: number;
    valorTotal: number;
    descricaoPosto: string;
    listDashboard: DashBoardFaturamentoTO[];
}
