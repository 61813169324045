import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserProfile } from 'app/main/shared/model/user-profile/user-profile.model';
import { ReplaySubject, Subject } from 'rxjs';
import { UserProfileService } from 'app/main/global/components/profiles/services/profile.service';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-employee-full-perfil',
  templateUrl: './employee-full-perfil.component.html',
  styleUrls: ['./employee-full-perfil.component.scss'],
  animations:fuseAnimations
})
export class EmployeeFullPerfilComponent implements OnInit {

  @Input() employee: FormGroup;
  perfils: Array<UserProfile>

  public PerfilFilterControl: FormControl = new FormControl();
  public filteredPerfil: ReplaySubject<UserProfile[]> = new ReplaySubject<UserProfile[]>(1);
  
  constructor(private userProfileService : UserProfileService) { 

    this.userProfileService.findAll().subscribe(data => {
      this.perfils = data.object;
      this.filteredPerfil.next(this.perfils.slice());
      this.PerfilFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    })

  }

  ngOnInit() {
  }


  private _onDestroy = new Subject<void>();

  private filterPerfils() {
    if (!this.perfils) {
      return;
    }

    let search = this.PerfilFilterControl.value;
    if (!search) {
      this.filteredPerfil.next(this.perfils.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPerfil.next(
      this.perfils.filter(perfil => perfil.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
