import { SGF_API } from './../../../../../shared/api/sgf.api';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {

  
  private host = `${SGF_API}` + 'core/api/public/modelo';

  constructor(
    private httpClient: HttpClient) {}

  page(size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&ativo=${ativo}&sort=id` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  create(impost: any): any {
    const body = JSON.stringify(impost);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(impost: any): any {
    const body = JSON.stringify(impost);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  filterModelo(filter: string, size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.host}?nome=like:${filter}&size=${size}&page=${page}&ativo=${ativo}` , this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
