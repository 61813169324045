import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CaptacaoService } from '../services/captacao.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResolveCaptacaoService implements Resolve<any> {

  
  constructor(
    private captacaoService: CaptacaoService) { }

  resolve(): Observable<any> {
     return this.captacaoService.page('CAPTADO', 1, 10).pipe(
       take(1),
       mergeMap(teste => {
        return of(teste['object']);
       })
     );
  }

}
