import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FolhaCobranca } from 'app/main/shared/model/folha-cobranca/folha-cobranca.model';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FolhaCobrancaService {


    private posto: string;

    private idPosto: number;

    private folhaCobranca = `${SGF_API}sgf/api/public/folha-cobranca`;
    private folhaCobrancaTransferencia = `${SGF_API}sgf/api/public/folha-cobranca-transferencia`;

    constructor(private httpClient: HttpClient) { }

    findAll(size: number, page: number): Observable<any> {
        this.checkIfHasPosto();
        return this.httpClient.get(`${this.folhaCobranca}?size=${size}&page=${page}&${this.posto}&sort=-id`, this.httpOptions())
            .pipe(
                catchError(this.handleError)
            );
    }

    findById(id: number): Observable<any> {
        return this.httpClient.get<FolhaCobranca[]>(`${this.folhaCobranca}/${id}`, this.httpOptions()).pipe(catchError(this.handleError));
      }

    imprimirFolhaCobranca(value: any, idUsuario: any): any {
        return this.httpClient.get(`${this.folhaCobranca}/imprimirDocumentoCaixa/${value.id}?usuario=${idUsuario}`, { responseType: 'blob' });
    }

    processarFolhaCobranca(id: number): Observable<any> {
        return this.httpClient.post(`${this.folhaCobranca}/processar-folha-cobranca`, id, this.httpOptions()).pipe(
            catchError(this.handleError)
        );
    }

    processarFolhaCobrancaRetroativa(data: any, idFuncionario: any, idUsuario: any): Observable<any> {
        return this.httpClient.get(`${this.folhaCobranca}/imprimir-folha-cobranca-retroativa?funcionario=${idFuncionario}&data=${data}&usuario=${idUsuario}` , { responseType: 'blob' });
    }

    findTransferenciaByFolhaCobranca(id: number): Observable<any> {
        return this.httpClient.get(`${this.folhaCobrancaTransferencia}/list-folha-cobranca-transferencia/${id}`, this.httpOptions()).pipe(
            catchError(this.handleError)
        );
    }

    processarOrdemFilaCobranca(transferencias): Observable<any> {
        return this.httpClient.post(`${this.folhaCobrancaTransferencia}/processar-ordem-fila-cobranca`, transferencias , this.httpOptions())
            .pipe(
                catchError(this.handleError)
            );
    }

    processarTodas(dataInicial: any): Observable<any>  {
        this.checkIfHasPosto();
        return this.httpClient.get(`${this.folhaCobranca}/processar-todas-folha-cobranca/${this.idPosto}?data=${dataInicial}`, this.httpOptions())
            .pipe(
                catchError(this.handleError)
            );
    }

    imprimirTodas(idUsuario: any): Observable<any>  {
        return this.httpClient.get(`${this.folhaCobranca}/imprimir-todas-folha-cobranca/${this.idPosto}?usuario=${idUsuario}`  , { responseType: 'blob' });
    }

    imprimirDiaRetroativo(dataInicial: any, idUsuario: any): Observable<any> {
        this.checkIfHasPosto();
        return this.httpClient.get(`${this.folhaCobranca}/imprimir-folha-retroativa?posto=${this.idPosto}&data=${dataInicial}&usuario=${idUsuario}`, { responseType: 'blob' });

    }

    checkIfHasPosto(): void {
        if (sessionStorage.getItem('funcionario')) {
            const user = JSON.parse(sessionStorage.getItem('funcionario'));
            this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
            this.idPosto = Number.isInteger(user.idPosto) ? user.idPosto : 0;
        }
    }

    httpOptions(token?: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': ''
            })
        };
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a customer-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    pageFolhaCobrancaByPeriodo(size: number, page: number, sort?: string, periodo?: string): any {
        if (!sort) {
          sort = '-id';
        }
    
        this.checkIfHasPosto();
        // tslint:disable-next-line:max-line-length
        return this.httpClient
          .get(
            `${this.folhaCobranca}?size=${size}&page=${page}&${this.posto}&sort=${sort}&dataPeriodo=${periodo}`,
            this.httpOptions(),
          )
          .pipe(catchError(this.handleError));
    }

    alterarCobrancaTransferencia(cliente: any): Observable<any> {
        return this.httpClient.put<any>(`${this.folhaCobrancaTransferencia}/alterar-cobranca-transferencia`, cliente, this.httpOptions()).pipe(
          catchError(this.handleError)
        );
    }

}
