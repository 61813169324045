import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SGF_TYPE} from '../../../../../shared/api/sgf.api';
import {Page} from '../../../../shared/model/page/page.model';
import {Contract} from '../../../../shared/model/contract/contract.model';
import {NgxPermissionsService} from 'ngx-permissions';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '../../../../../../@fuse/animations';

@Component({
  selector: 'app-reagendados-detail-list',
  templateUrl: './reagendados-detail-list.component.html',
  styleUrls: ['./reagendados-detail-list.component.scss'],
  animations: fuseAnimations
})
export class ReagendadosDetailListComponent implements OnInit {


  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO';

   dev = `${SGF_TYPE}` == 'DEV';
   funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
   fortEletro = this.funcionario.postoEletro;

  lastPage;

  @Input() rows: any;
  @Input() page: Page;
  @Input() pageNum: any;
  @Input() filter: any;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() pageNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() data: EventEmitter<any> = new EventEmitter();
  timeout: any;

  selected: Array<Contract>;
  permissoes = [];

  constructor(
      private permissionsService: NgxPermissionsService,
  ) {
    this.selected = new Array<Contract>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });

    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void {

  }

   generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
    this.pageNumber.emit(event.offset + 1);
    this.pageNum = (event.offset + 1);
    
  }

  onSelect({ selected }): void {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectCostumers();
  }

  onSelectCostumers(): void {
    this.selectEvent.emit(this.selected);
  }
}
