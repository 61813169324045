import { Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { BenefitsService } from 'app/main/global/components/benefits/services/benefits.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';

export interface DialogData {
  action: string,
  data: any
}

@Component({
  selector: 'app-benefits-form',
  templateUrl: './benefits-form.component.html',
  styleUrls: ['./benefits-form.component.scss']
})
export class BenefitsFormComponent implements AfterViewInit {

  benefits: FormGroup;
  titleHeader: string;
  disabledButton: boolean;
  dialogMessages;

  @ViewChild('descricao') descricao: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BenefitsFormComponent>,
    private benefitsService: BenefitsService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Benefício' : 'Edição de Benefício';

    this.setFormGroup();
  }

  setFormGroup() {
    let valor = this._data.data.valor ? this._data.data.valor.toString() : null;
    let tam = this._data.data.valor ? valor.length : null;

    valor = this._data.data.valor ? `${valor.substring(0, tam - 2)}.${valor.substring(tam - 2)}` : null;

    this.benefits = this.formBuilder.group({
      id: [this._data.data.id || null],
      descricao: [this._data.data.descricao || null, Validators.required],
      valor: [valor || null, Validators.required]
    })
  }

  onClosed() {
    this.matDialogRef.close();
  }

  ngAfterViewInit() {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }

  onAdd(value) {
    this.disabledButton = true;
    value.valor = value.valor.toString().replace(/[.,]/g, "");
    this.benefitsService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if (ondata.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
            }
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    value.valor = value.valor.toString().replace(/[.,]/g, "");
    this.benefitsService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
