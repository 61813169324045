import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cliente } from '../../model/cliente/cliente.model';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { Turno } from '../../model/enums/turno.enum';
import { VisitasService } from 'app/main/customers/components/visitas/services/visitas.service';
import { VisitModel } from '../../model/visit/visit-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Posto } from '../../model/posto/posto.model';
import { Employee } from '../../model/employee/employee.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Team } from '../../model/team/team.model';
import { TeamService } from 'app/main/global/components/teams/services/team.service';

export namespace Turnos {
  export function values(): any {
    return Object.keys(Turno).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

@Component({
  selector: 'app-agendar-visita',
  templateUrl: './agendar-visita.component.html',
  styleUrls: ['./agendar-visita.component.scss']
})
export class AgendarVisitaComponent implements OnInit {

  public clienteFilterControl: FormControl = new FormControl();
  public filteredCliente: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);

  clientes: Array<Cliente>;
  turnos = Turnos;
  visita: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  postos: Array<Posto>;
  equipes: Array<Team>;
  employee: Array<Employee> =  [];
  title: string;
  action: number;
  selectedTuno = 'MANHA';

  public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));

  private _onDestroy = new Subject<void>();

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public equipeFilterControl: FormControl = new FormControl();
  public filteredEquipe: ReplaySubject<Team[]> = new ReplaySubject<Team[]>(1);

  constructor(private matDialogRef: MatDialogRef<AgendarVisitaComponent>,
    private clienteService: ClienteService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private visitaService: VisitasService,
    private postoService: PostsService,
    private employeeService: EmployeeService,
    private teamService: TeamService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    if (this._data.cliente == null) {
      this.clienteService.findAll().subscribe(data => {
        this.clientes = data.object.content;
        this.filteredCliente.next(this.clientes.slice());
        this.clienteFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterCliente();
          });
      });
    } else {
      this.clienteService.findById(this._data.cliente.id).subscribe(data => {
        this.clientes = [data.object];
        this.filteredCliente.next(this.clientes.slice());
        this.clienteFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterCliente();
          });
      });
    }

     // tslint:disable-next-line:triple-equals
     if (this._data.action == 1) {
      this.title = 'Adicionar Visita';
      this.action = 1;
    } else {
      this.title = 'Agendar Visita';
      this.action = 2;
    }


  }

  ngOnInit(): void {

    if (this.usuario.idPosto == null) {
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.postoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
      });
    } else {
      this.getVisitadores(this.usuario.idPosto);
      this.getEquipesByPosto(this.usuario.idPosto);
    }

    this.setForm();
  }

  private getVisitadores(idPosto: number): void {
    this.employeeService.findVisitadorByPosto(idPosto).subscribe(data => {
      this.employee = data.object;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterVisitador();
        });
    });
  }

  
  private filterVisitador(): void {
    if (!this.employee) {
      return;
    }
    const search = this.EmployeeFilterControl.value;
    this.filteredEmployee.next(
      // tslint:disable-next-line:triple-equals
      this.employee.filter(employee => employee.id == search)
    );
  }


  private filterPosto(): void {
    if (!this.postos) {
      return;
    }
    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  getEquipesByPosto(idPosto: number): void {
    this.teamService.findByPostoId(idPosto).subscribe(data => {
      this.equipes = data.object;
      this.filteredEquipe.next(this.equipes.slice());
      this.equipeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEquipe();
        });
    });
  }


  private filterEquipe(): void {
    if (!this.equipes) {
      return;
    }
    let search = this.equipeFilterControl.value;
    if (!search) {
      this.filteredEquipe.next(this.equipes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEquipe.next(
      this.equipes.filter(equipe => equipe.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  setForm(): void {
    this.visita = this.formBuilder.group({
      idCliente: [this._data.cliente ? this._data.cliente.id : null, Validators.required],
      data: [null],
      turno: [null],
      visitador: [null, Validators.required],
      equipe: [null, Validators.required]
    });
  }

  private filterCliente(): void {
    if (!this.clientes) {
      return;
    }

    let search = this.clienteFilterControl.value;
    if (!search) {
      this.filteredCliente.next(this.clientes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCliente.next(
      this.clientes.filter(cliente => cliente.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  onClosed(): void {
    this.matDialog.closeAll();
  }


  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  agendar(obj: any): void {

    this.disabledButton = true;

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 1) {
          this.visitaService.adicionarVisitaByClienteId(obj).subscribe(res => {
            if (res.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `${res.messages[0].text}`
              };
  
              this.feedback(this.dialogMessages);
              this.matDialogRef.close(res.object);
            } else {
              if (res.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${res.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages);

              this.matDialogRef.close();
            }
            // tslint:disable-next-line:no-unused-expression
            (onerror) => {
              console.log('ERROR CAIXA', onerror);
            };
          });
    } else {
      this.visitaService.agendarVisita(obj).subscribe(data => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          };
  
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data.object);
  
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
  
          this.matDialogRef.close();
        }
        // tslint:disable-next-line:no-unused-expression
        (onerror) => {
          console.log('ERROR CAIXA', onerror);
        };
      });
      this.disabledButton = true;
    }
  }

  private feedback(message: string[]): void {
    const dialogRef = this.matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
}
