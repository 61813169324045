import { Component, Inject, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../navigation/i18n/en';
import { locale as portuguese } from '../../navigation/i18n/pt-br';
import { WidgetCardModel } from './shared/model/widget-card-model.model';
import { ScrumboardCardModel } from './shared/model/scrumboard-card-model.model';
import { WidgetCard } from './shared/data/widget-data';
import { ScrumboardCard } from './shared/data/scrumboard-data';
import { fuseAnimations } from '@fuse/animations';
import * as shape from 'd3-shape';

import * as Redux from 'redux';
import { CaptacaoStore } from '../shared/store/captacao.store';
import { CaptacaoState } from '../shared/store/captacao.reducer';
import { HomeService } from './shared/home.service';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { User } from '../shared/model/user/user.model';
import { CaptacaoService } from '../customers/components/captacao/services/captacao.service';
import { VisitasService } from '../customers/components/visitas/services/visitas.service';
import { VisitadosService } from '../customers/components/visitados/services/visitados.services';
import { CaixaService } from '../financial/services/caixa/caixa.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: fuseAnimations
})
export class HomeComponent implements OnInit {
    widgetCardData: WidgetCardModel[];
    scrumboardCardData: ScrumboardCardModel[];
    bottomWidgetCardData: WidgetCardModel[];
    user: User;
    token: string;

    widget5: any = {};

    captado;
    visitas;
    visitados;

    numberCaptado: number;
    numberVisitas: number;
    numberVisitados: number;

    numberPendenciasRepasse: number = 0;
    numberPendenciasCobranca: number = 0;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public widgetCard: WidgetCard,
        public scrumboardCard: ScrumboardCard,
        @Inject(CaptacaoStore) private store: Redux.Store<CaptacaoState>,
        private captacaoService: CaptacaoService,
        private visitaService: VisitasService,
        private visitadosService: VisitadosService,
        private caixaService: CaixaService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, portuguese);
        this.scrumboardCardData = scrumboardCard.getAllScrumboardCardsPT();

        this.captacaoService.findAll().subscribe(
            (ondata) => {
                this.captado = ondata['object']['content'].filter(
                    (filter) => filter.situacaoCaptado == 'CAPTADO');
                this.numberCaptado = this.captado.length
                this.updateState();
            }
        );

        this.visitaService.findAll('AGENDADA,REAGENDADA').subscribe(
            (ondata) => {
                this.visitas = ondata['object']['content']
                this.numberVisitas = this.visitas.length;
                this.updateState();
            }
        );

        this.visitaService.findAll('VISITADA,EM_ANALISE').subscribe(
            (ondata) => {
                this.visitados = ondata['object']['content']
                this.numberVisitados = this.visitados.length;
                this.updateState();
            }
        );

        this.caixaService.findFuncionariosComPendencias('REPASSE')
            .subscribe(data => {
                const pendencias = data.object
                if (pendencias) {
                    this.numberPendenciasRepasse = Object.values(pendencias).reduce<number>((a: number, c: number) => a + c, 0)
                }
            })

        this.caixaService.findFuncionariosComPendencias('COBRANCA')
            .subscribe(data => {
                const pendencias = data.object
                if (pendencias) {
                    this.numberPendenciasCobranca = Object.values(pendencias).reduce<number>((a: number, c: number) => a + c, 0)
                }
            })

    }
    ngOnInit() {}


    updateState() {
        this.widgetCardData = this.widgetCard.getWidgetCards(
            (this.numberCaptado), (this.numberVisitas), (this.numberVisitados));

        this.bottomWidgetCardData = this.widgetCard.getBottomWidgetCards(
            this.numberPendenciasRepasse,
            this.numberPendenciasCobranca
        );
    }



}
