import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserProfile } from '../../model/user-profile/user-profile.model';
import { UserProfileService } from 'app/main/global/components/profiles/services/profile.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Permission } from '../../model/permission/permission.model';

export interface DialogData {
  profile: UserProfile,
  action: string
}

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})


export class UserProfileFormComponent implements OnInit {

  profile: FormGroup;
  titleHeader: string;
  permissao: Array<Permission>;
  activeCheck: string = '';

  dialogMessages;
  listPermissao = []
  listPermissaoDescription = [];
  disabledButton:boolean;

  public permissaoFilterControl: FormControl = new FormControl();
  public filteredPermissao: ReplaySubject<Permission[]> = new ReplaySubject<Permission[]>(1);

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService,
    public matDialogRef: MatDialogRef<UserProfileFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
    this.activeCheck = (_data.profile.ativo) ? 'Ativo' : 'Inativo';
    this.userProfileService.getPermissao().subscribe(data => {
      this.permissao = data.object
      this.filteredPermissao.next(this.permissao.slice());
      this.permissaoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPermissao();
        });
    })

    this.titleHeader = this._data.action == 'new' ? 'Cadastro de Perfil' : 'Edição de Perfil';
  }

  ngOnInit() {
    this.setFormGroup();
  }

  private _onDestroy = new Subject<void>();


  setFormGroup() {
    if (this._data.action == 'update') {
      this._data.profile.listPermissao.forEach(p => {
        this.listPermissaoDescription.push(p.descricao)
      })
    }

    this.profile = this.formBuilder.group({
      id: [this._data.profile.id || null],
      ativo: [this._data.profile.ativo],
      descricao: [this._data.profile.descricao || null, Validators.required],
      permissoes: [this.listPermissaoDescription || null, Validators.required],
      listPermissao: [],
      nivelPerfil: [this._data.profile.nivelPerfil || null, Validators.required]
    })
  }

  onAdd(value) {
    this.disabledButton = true;
    value.permissoes.forEach(element => {
      this.listPermissao.push(this.permissao.filter((filter) => element == filter.descricao)[0])
    });
    delete value.permissoes;
    value.listPermissao = this.listPermissao;

    this.userProfileService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    value.permissoes.forEach(element => {
      this.listPermissao.push(this.permissao.filter((filter) => element == filter.descricao)[0])
    });
    delete value.permissoes;
    value.listPermissao = this.listPermissao;

    this.userProfileService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR BENEFICIOS', onerror);
      }
    )
  }

  private filterPermissao() {
    if (!this.permissao) {
      return;
    }

    let search = this.permissaoFilterControl.value;
    if (!search) {
      this.filteredPermissao.next(this.permissao.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPermissao.next(
      this.permissao.filter(permissao => permissao.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

  onChange(isActive: boolean) {
    this._data.profile.ativo = !isActive;
    this.profile.get('ativo').setValue(!isActive);
  }

}
