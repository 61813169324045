import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResolveGeneratedService } from '../components/generated/resolve/resolve-generated.service';
import { ResolveReleasedService } from '../components/released/resolve/resolve-released.service';
import { ResolveInProgressService } from '../components/in-progress/resolve/resolve-in-progress.service';
import { ResolveCanceledService } from '../components/canceled/resolve/resolve-canceled.service';
import { ResolveToCancelService } from '../components/to-cancel/resolve/resolve-to-cancel.service';
import {ResolveReagendadoService} from '../components/reagendados/resolve/resolve-reagendado.service';

const ContractsModuleRoutes: Routes = [
  {
    path: 'gerados',
    loadChildren: '../components/generated/generated.module#GeneratedModule',
    resolve: { rows: ResolveGeneratedService },
  },
  {
    path: 'liberados',
    loadChildren: '../components/released/released.module#ReleasedModule',
    resolve: { rows: ResolveReleasedService }
  },
  {
    path: 'pix',
    loadChildren: '../components/pix/pix.module#PixModule',
    resolve: {}
  },
  {
    path: 'boleto',
    loadChildren: '../components/boleto/boleto.module#BoletoModule',
    resolve: {}
  },
  {
    path: 'em-andamento',
    loadChildren: '../components/in-progress/in-progress.module#InProgressModule',
    resolve: { rows: ResolveInProgressService },
    runGuardsAndResolvers: "always",
  },
  {
    path: 'cancelados',
    loadChildren: '../components/canceled/canceled.module#CanceledModule',
    resolve: { rows: ResolveCanceledService }
  },
  {
    path: 'a-cancelar',
    loadChildren: '../components/to-cancel/to-cancel.module#ToCancelModule',
    resolve: { rows: ResolveToCancelService }
  },
  {
    path: 'reagendados',
    loadChildren: '../components/reagendados/reagendados.module#ReagendadosModule',
    resolve: { rows: ResolveReagendadoService }
  },
  {
    path: 'acompanhamento-novatos-inativos',
    loadChildren: '../components/acompanhamento-novatos-inativos/acompanhamento-novatos-inativos.module#AcompanhamentoNovatosInativosModule',
  },
  {
    path: 'acompanhamento-entregues-nao-entregues',
    loadChildren: '../components/acompanhamento-contratos-entregues/acompanhamento-contratos-entregues.module#AcompanhamentoContratosEntreguesModule',
  }
];

@NgModule({
  imports: [RouterModule.forChild(ContractsModuleRoutes)],
  exports: [RouterModule],
  declarations: [],
  providers: [
    ResolveGeneratedService,
    ResolveReleasedService,
    ResolveInProgressService,
    ResolveCanceledService,
    ResolveToCancelService
  ]
})
export class ContractsRoutingModule { }
