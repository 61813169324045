import { Injectable } from '@angular/core';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveDespesaFuncionarioService {

  type: 'cdef';
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    funcionarioId = this.funcionario.idFuncionario;

  constructor(private caixaService: CaixaService) {
  }

  resolve(): Observable<any> | any {
      return this.caixaService.page('cdef', 10, 1).pipe(
        take(1),
        mergeMap(caixa => {
          return of(caixa['object']);
        })
      );
    }
}
