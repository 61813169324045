import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-fiador',
  templateUrl: './fiador.component.html',
  styleUrls: ['./fiador.component.scss'],
  animations: [fuseAnimations]
})
export class FiadorComponent implements OnInit {

  @Input() visit: FormGroup;
  maskCpf = new Mask('000.000.000-00');
  maskVencimento = new Mask('00/00');
  maskCartao = new Mask('0000000000000000');
  constructor() { }

  ngOnInit(): void { }

  get addressForms(): FormArray {
    return this.visit.get('listReferencia').get('referenciaFiador').get('listEndereco') as FormArray;
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

}
