import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from './../message-dialog/message-dialog.component';
import { UtilsService } from './../../../../shared/util/utils.service';
import { ReferenciaPix } from './../../model/referencia-pix/referencia-pix.model';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChildren, QueryList, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ReferenciaPixServiceService } from 'app/main/infinitylabs/core/referencia-pix/service/referencia-pix-service.service';
import { Cliente } from '../../model/cliente/cliente.model';
import { Observable, Subject } from 'rxjs';
import { Mask } from '@fagnerlima/ng-mask';
import { debounceTime } from 'rxjs/operators';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-referencias-pix-form',
  templateUrl: './referencias-pix-form.component.html',
  styleUrls: ['./referencias-pix-form.component.scss']
})
export class ReferenciasPixFormComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChildren('form', { read: NgForm }) forms: QueryList<NgForm>;
  @Output() referenciaPixFormValidEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cliente: Cliente;
  
  clienteSubject: Subject<Cliente> = new Subject();
  referencias: ReferenciaPix[] = [];

  changeCPFSubject: Subject<{cpf: string, index: number}> = new Subject();
  maskCpf = new Mask('000.000.000-00');
  telefone: string;

  constructor(private service: ReferenciaPixServiceService, private utilsService: UtilsService, private dialog: MatDialog) {
    this.clienteSubject.pipe(debounceTime(1500))
    .subscribe((response: Cliente) => {
      this.service.findAllByClienteIdAndSituacao(response.id, true)
      .subscribe((data: ResponseAPI) => {
        if ((data.object as ReferenciaPix[]).length) {
          this.referencias = (data.object as ReferenciaPix[]);
          this.referencias.forEach(referencia => {
            referencia.telefone = referencia.cliente.telefone.numero;
          })
        } else {
          this.addReferencia();
        }

        this.checkFormulariosInvalidos()
        .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
      });
    });

    this.changeCPFSubject.pipe(debounceTime(1500))
    .subscribe((response: {cpf: string, index: number}) => {
      const responseCPFFormatado = (response.cpf).replace('.', '').replace('-', '');
      const clienteCPFFormatado = this.cliente.pessoa.cpf.replace('.', '').replace('-', '');
      if (response.cpf && responseCPFFormatado === clienteCPFFormatado) {
        Object.assign(this.referencias.find((_, idx) => idx === response.index), 
        {
          ...this.referencias.find((_, idx) => idx === response.index),
          nomeBeneficiario: this.cliente.nomeCliente,
          cpf: this.cliente.pessoa.cpf,
          rg: this.cliente.pessoa.rg,
          tipoReferencia: 'TITULAR',
          parentescoTerceiro: null
        })
      }

      this.checkFormulariosInvalidos()
      .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
    });
  }

  ngAfterViewInit(): void {
    this.checkFormulariosInvalidos()
    .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cliente && changes.cliente.currentValue) {
      this.clienteSubject.next(this.cliente);
    }
  }

  ngOnDestroy(): void {
    this.clienteSubject.unsubscribe();
    this.changeCPFSubject.unsubscribe();
  }

  changeNomeBeneficiario(value: string, index: number): void {
    Object.assign(this.referencias.find((_, idx) => idx === index), 
      {
        ...this.referencias.find((_, idx) => idx === index),
        nomeBeneficiario: value.toUpperCase()
      }
    );

    this.checkFormulariosInvalidos()
    .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  changeTelefone(value: string, index: number): void {
    Object.assign(this.referencias.find((_, idx) => idx === index), 
      {
        ...this.referencias.find((_, idx) => idx === index),
        telefone: value
      }
    );

    this.checkFormulariosInvalidos()
    .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  changeReferenciaRepasse(value: boolean, index: number): void {
    if (value) {
      this.referencias.forEach((referencia, idx) => {
        if (idx !== index) {
          referencia.referenciaRepasse = false;
        }
      });
    }
    this.checkFormulariosInvalidos()
    .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  changeTipoReferencia(tipo: string, index: number): void {
    if (tipo === 'TITULAR') {
      Object.assign(this.referencias.find((_, idx) => idx === index), 
        {
          ...this.referencias.find((_, idx) => idx === index),
          nomeBeneficiario: this.cliente.nomeCliente,
          cpf: this.cliente.pessoa.cpf,
          rg: this.cliente.pessoa.rg,
          parentescoTerceiro: null
        }
      );
    }

    setTimeout(() => this.checkFormulariosInvalidos().then((invalido) => this.referenciaPixFormValidEvent.next(invalido)), 500);
  }

  changeParentescoTerceiro(): void {
    this.checkFormulariosInvalidos().then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  changeCPF(cpf: string, index: number): void {
    this.changeCPFSubject.next({cpf, index});
  }

  changeChavePix(): void {
    this.checkFormulariosInvalidos().then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  addReferencia(): void {
    if (this.referencias.length < 2) {
      const referencia: ReferenciaPix = {
        id: null,
        ativo: true,
        tipoReferencia: 'TITULAR',
        parentescoTerceiro: null,
        referenciaRepasse: false,
        nomeBeneficiario: this.cliente.nomeCliente,
        cpf: this.cliente.pessoa.cpf,
        rg: this.cliente.pessoa.rg,
        tipoChavePix: null,
        chavePix: null,
        cliente: this.cliente,
        telefone: this.cliente.telefone.numero
      }
  
      this.referencias.push(referencia);
    }

    this.checkFormulariosInvalidos()
    .then((invalido) => this.referenciaPixFormValidEvent.next(invalido));
  }

  removeReferencia(index: number): void {
    this.referencias.splice(index, 1);
  }

  checkExisteReferenciaRepasse(): boolean {
    return this.referencias.map((ref) => ref.referenciaRepasse).includes(true);
  }

  public async checkFormulariosInvalidos(): Promise<boolean> {
    let invalido: boolean = false;

   await this.forms.forEach((form: NgForm) => {
    for (let control of Object.entries(form.form.controls)) {
      if (control[1].errors) {
        invalido = true;
      }
    }
   });

   const existeReferenciaRepasse = await this.checkExisteReferenciaRepasse();
    
   return invalido || !existeReferenciaRepasse;
  }

  saveReferencias(): Observable<ResponseAPI> {
    return this.service.saveByCliente(this.cliente.id, this.referencias);
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  compareCPFTitular(index: number): boolean {
    if (this.cliente && this.cliente.pessoa && this.cliente.pessoa.cpf) {
      const clienteCPFFormatado = 
        this.cliente.pessoa.cpf
        .replace(new RegExp('[^0-9]'), '')
        .replace(new RegExp('[^0-9]'), '')
        .replace(new RegExp('[^0-9]'), '');

      const responseCPFFormatado = 
        this.referencias.find((_, idx) => idx === index).cpf
        .replace(new RegExp('[^0-9]'), '')
        .replace(new RegExp('[^0-9]'), '')
        .replace(new RegExp('[^0-9]'), '');

      return clienteCPFFormatado === responseCPFFormatado;
    }
    return false;
  }
}
