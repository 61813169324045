import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReleasedMainComponent } from './released-main/released-main.component';
import { ReleasedListComponent } from './released-list/released-list.component';
import { ReleasedSidebarComponent } from './released-sidebar/released-sidebar.component';
import { SharedModule } from '../../../shared/shared.module';
import { SharedMaterialComponentsModule } from '../../../../shared-material-components.module';
import { ContractsService } from '../../services/contracts.service';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ContratoGalleryComponent } from 'app/main/shared/components/contrato-gallery/contrato-gallery.component';
import { VideoPlayerComponent } from 'app/main/shared/components/video-player/video-player.component';

const routes: Routes = [
  {
    path: '',
    component: ReleasedMainComponent,
    resolve: {}
  }, 
  {
    path: 'galeria/:id',
    component: ContratoGalleryComponent,
  }, 
  {
    path: 'video/:id',
    component: VideoPlayerComponent,
  }
  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})


  ],
  declarations: [ReleasedMainComponent, ReleasedListComponent, ReleasedSidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContractsService]
})
export class ReleasedModule { }
