import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/main/global/services/global.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Municipality } from '../../model/municipality/municipality.model';
import { Estado } from '../../model/estado/estado.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-filter-bairro',
  templateUrl: './filter-bairro.component.html',
  styleUrls: ['./filter-bairro.component.scss']
})
export class FilterBairroComponent implements OnInit {
  form: FormGroup;
  dialogMessages;
  filteredOptions: any;
  listBairro = [];
  listBairroDescriptions = []
  municipios: Array<Municipality>;
  bairro: string;
  estados: Estado;
  municipality: Array<Municipality>;
  ufs: Array<any>;
  uf:Estado;
  selectedMunicipio:any;

  public ufFilterCtrl: FormControl = new FormControl();
  public filteredUF: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public municipioFilterCtrl: FormControl = new FormControl();
  public filteredMunicipio: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private globalService : GlobalService,
              private matDialogRef : MatDialogRef<FilterBairroComponent>,
              private _matDialog : MatDialog,
              private formBuilder : FormBuilder) { }

  ngOnInit() {
    this.globalService.pageEstado().subscribe(data => {
      this.ufs = data.object.content;
      this.filteredUF.next(this.ufs.slice());
      this.ufFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterUf();
        });
    })
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      municipio: [null, Validators.required],
      estado:[null, Validators.required]
    })
  }

  private _onDestroy = new Subject<void>();

  onClosed() {
    this.matDialogRef.close();
  }

  onSave(value){
    delete value.estado
    this.matDialogRef.close(value)
  }

  getErrorMessage(field: string) {
    return this.form.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  getMunicipios(estado) {
    let uf;
    if (typeof estado === 'string') {
      uf = this.ufs.filter(e => e.sigla === estado)[0]
    } else {
      uf = estado
    }

    this.globalService.municipioByEstadoId(uf.id).subscribe(data => {
      this.municipios = data.object.content.map(data => data)

      this.filteredMunicipio.next(this.municipios.slice());
      this.municipioFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMunicipio();
        });
    })
  }
  

  private filterMunicipio() {
    if (!this.filteredMunicipio) {
      return;
    }

    let search = this.municipioFilterCtrl.value;
    if (!search) {
      this.filteredMunicipio.next(this.municipios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMunicipio.next(
      this.municipios.filter(municipio => municipio.nomeMunicipio.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterUf() {
    if (!this.filteredUF) {
      return;
    }

    let search = this.ufFilterCtrl.value;
    if (!search) {
      this.filteredUF.next(this.ufs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUF.next(
      this.ufs.filter(estado => estado.sigla.toLowerCase().indexOf(search) > -1)
    );
  }
}
