import { Address } from '../address/address.model';
import { Telephone } from '../telephone/telephone.model';
import { Employee } from '../employee/employee.model';
import { ReferenciaPessoal } from '../referencia-pessoal/referencia-pessoal.model';
import { Product } from '../product/product.model';
import { Team } from '../team/team.model';
import {Cnae} from '../cnae/cnae';
import {Rota} from '../rota/rota.model';

export class VisitModel {

    cliente: any;
    dataAprovacaoCredito: string;
    dataVisita:	string;
    atividadeComercial: any;
    id:	number;
    limiteCreditoAprovado: number;
    limiteCreditoSugerido: number;
    listArquivoVisita: Array<any>;
    parecerVisita: string;
    situacao: any;
    valorAprovado: number;
    valorSugerido: number;
    vendedor: any;
    atendente: Employee;
    turno: string;
    listEndereco: Address[];
    listTelefone: Telephone[];
    pagamentoCartao: boolean;
    pagamentoBoleto: boolean;
    pagamentoDinheiro: boolean;
    pagamentoPix: boolean;
    tipoContrato: string;
    quantidadeParcelas: number;
    referenciaPessoal: ReferenciaPessoal[];
    dataVencimento: Date;
    taxaJuros: number;
    taxaJurosMora: number;
    produto: Product;
    isEletro: boolean;
    antecipacao: boolean;
    diasAntecipacao: number;
    periodicidadeParcela: string;
    diaSemana: string;
    equipe: Team;
    modalidadeEstabelecimento: string;
    cnae: Cnae;
    dataInicioAtividade: Date;
    rota: Rota;

    constructor(obj: any) {

        this.id                         = obj.id                        || null;
        this.cliente                    = obj.cliente                   || null;
        this.atividadeComercial         = obj.atividadeComercial        || null;
        this.dataAprovacaoCredito       = obj.dataAprovacaoCredito      || null;
        this.dataVisita                 = obj.dataVisita                || null;
        this.limiteCreditoAprovado      = obj.limiteCreditoAprovado     || null;
        this.limiteCreditoSugerido      = obj.limiteCreditoSugerido     || null;
        this.listArquivoVisita          = obj.listArquivoVisita         || null;
        this.parecerVisita              = obj.parecerVisita             || null;
        this.situacao                   = obj.situacao                  || null;
        this.valorAprovado              = obj.valorAprovado             || null;
        this.valorSugerido              = obj.valorSugerido             || null;
        this.vendedor                   = obj.vendedor                  || null;
        this.turno                      = obj.turno                     || null;
        this.atendente                  = obj.atendente                 || new Employee({});
        this.pagamentoBoleto            = obj.pagamentoBoleto           || false;
        this.pagamentoCartao            = obj.pagamentoCartao           || false;
        this.pagamentoDinheiro          = obj.pagamentoDinheiro         || false;
        this.tipoContrato               = obj.tipoContrato              || null;
        this.quantidadeParcelas         = obj.quantidadeParcelas        || false;
        this.referenciaPessoal          = obj.referenciaPessoal         || [new ReferenciaPessoal({})];
        this.dataVencimento             = obj.dataVencimento            || null;
        this.taxaJuros                  = obj.taxaJuros                 || null;
        this.produto                    = obj.produto                   || null;
        this.isEletro                   = obj.isEletro                  || null;
        this.antecipacao                = obj.antecipacao               || false;
        this.diasAntecipacao            = obj.diasAntecipacao           || null;
        this.periodicidadeParcela       = obj.periodicidadeParcela      || null;
        this.taxaJurosMora              = obj.taxaJurosMora             || null;
        this.diaSemana                  = obj.diaSemana                 || null;
        this.equipe                     = obj.equipe                    || null;
        this.modalidadeEstabelecimento  = obj.modalidadeEstabelecimento || null;
        this.cnae                       = obj.cnae                      || null;
        this.rota                       = obj.rota                      || null;
        this.dataInicioAtividade        = obj.dataInicioAtividade       || null;
    }

}
