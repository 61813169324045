import {Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Contract} from 'app/main/shared/model/contract/contract.model';
import {Page} from 'app/main/shared/model/page/page.model';
import {SGF_TYPE} from 'app/shared/api/sgf.api';
import {MatDialog} from '@angular/material';
import {ClienteSelectFormComponent} from '../../../../shared/components/cliente-select-form/cliente-select-form.component';
import { IndicadorSelectFormComponent } from 'app/main/shared/components/indicador-select-form/indicador-select-form.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { ReagendarContratoFormComponent } from 'app/main/shared/components/reagendar-contrato-form/reagendar-contrato-form.component';

@Component({
  selector: 'app-generated-list',
  templateUrl: './generated-list.component.html',
  styleUrls: ['./generated-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class GeneratedListComponent implements OnInit, OnChanges {

  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
  @Input() rows: Contract[];
  @Input() page: Page;
  @Output() editContract: EventEmitter<any> = new EventEmitter();
  @Input() selectedClear;
  @Output() pageNumber: EventEmitter<any> = new EventEmitter();
  @Output() contratoRetroativoEvent: EventEmitter<any> = new EventEmitter();
  @Output() alterarProdutoEvent: EventEmitter<any> = new EventEmitter();
  @Output() reloadPage: EventEmitter<any> = new EventEmitter();


  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  superUsuario: boolean;
  situacao = 'NOVO,RENOVADO,GERADO';

  selected: Array<any>;
  timeout: any;

  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  fortEletro = this.funcionario.postoEletro;

  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  interval;
  private contrato: any;

  isPostoPix: boolean = false;
  
  @Output() getTotalContractValue = new EventEmitter<any>();

  constructor(
    private _matDialog: MatDialog, 
    private postoService: PostsService)  {
    this.superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));
    this.selected = new Array<any>();
    this.checkIfHasPosto();
  }


  ngOnInit(): void {
    this.getTotalValue();
  }

  ngOnChanges(): any {
    this.getTotalValue();
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const postoId = user.idPosto ? user.idPosto : null;

      if(postoId != null) {
        this.postoService.findById(postoId).subscribe((resp: any) => {
          this.isPostoPix = resp.object.parametroPosto.pixBoleto;
        });
      }
    }
  }

  // PARA POSTO TIPO PIX, HÁ CONFIRMAÇÃO DE REFERENCIA PIX
  // ENTÃO LIBERAÇÃO PARA MAIS DE UM CONTRATO AO MESMO TEMPO É BLOQUEADA
  async selectRow(row, index): Promise<void> {
    if (this.isPostoPix) {
      await this.rows.forEach((contract, idx) => {
        if (idx !== index) {
          contract.isSelected = false;
        }
      });
  
      await this.selected.splice(0, this.selected.length);
      await this.selected.push(...this.rows.filter(contract => contract.isSelected));
      this.editContract.emit(this.selected);
    }
  }

  // PARA POSTO NÃO PIX ESSE É O METODO DE SELEÇÃO
  // PERMITE LIBERAR MAIS DE UM CONTRATO AO MESMO TEMPO
  onSelect({ selected }): any {
    if (!this.isPostoPix) {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      this.editContract.emit(this.selected);
    }
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
    this.pageNumber.emit(event.offset + 1);
  }

  generateMask(value: number): any {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  gerarContratoRetroativo(value): any {
    this.contratoRetroativoEvent.emit(value);
  }

  alterarProduto(value): any {
    this.alterarProdutoEvent.emit(value);
  }

  getId(row): any {
    return row.id;
  }

  reagendarContrato(value): any {
    this._matDialog.open(ReagendarContratoFormComponent, {
      data: {
         contrato: value
      }
    }).afterClosed().subscribe(data => {
          this.reloadPage.emit(true);
    });
  }

  alterarTitularidade(value): any {
    this._matDialog.open(ClienteSelectFormComponent, {
      data: {
         contrato: value
      }
    }).afterClosed().subscribe(data => {
          this.reloadPage.emit(true);
    });
  }

  alterarIndicador(value): any {
    this._matDialog.open(IndicadorSelectFormComponent, {
      data: {
         contrato: value
      }
    }).afterClosed().subscribe(data => {
          this.reloadPage.emit(true);
    });
  }

  getTotalValue(){
    let totalValue:number = 0;
    this.rows.forEach(contract =>{
      totalValue = contract.valorBruto + totalValue;
    }) 
    this.getTotalContractValue.emit(totalValue);
  }
}
