import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-acompanhamento-novatos-inativos-search',
  templateUrl: './acompanhamento-novatos-inativos-search.component.html',
  styleUrls: ['./acompanhamento-novatos-inativos-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcompanhamentoNovatosInativosSearchComponent implements OnInit {

  private dateInit: Date = new Date();
  private dateEnd: Date = new Date();

  @Output() filterEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  keyDownPressed(event): void {
    if (event.keyCode === 13) { 
      this.emit();
    }
  }

  emit(): void{
    if (this.dateInit && this.dateEnd) {
      const filter = {dateInit: this.dateInit, dateEnd: this.dateEnd};
      this.filterEvent.emit(filter);
    }
  }
}
