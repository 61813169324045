import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Form, FormArray } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MaquinaCartao } from '../../model/maquina-cartao/maquina-cartao.model';
import { MaquinaCartaoService } from 'app/main/financial/components/maquina-cartao/services/maquina-cartao.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-maquina',
  templateUrl: './edit-maquina.component.html',
  styleUrls: ['./edit-maquina.component.scss']
})
export class EditMaquinaComponent implements OnInit {

  maquinas: MaquinaCartao[];
  form: FormGroup;
  title: string;

  public MaquinaFilterControl: FormControl = new FormControl();
  public filteredMaquina: ReplaySubject<MaquinaCartao[]> = new ReplaySubject<MaquinaCartao[]>(1);

  constructor(private maquinaService: MaquinaCartaoService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditMaquinaComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    this.title = "Vincular Máquina";

    this.maquinaService.listarMaquinas().subscribe(data => {
      this.maquinas = data.object;
      this.filteredMaquina.next(this.maquinas.slice());
      this.MaquinaFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMaquinas()
        });
    })
  }


  private _onDestroy = new Subject<void>();

  ngOnInit() {
    this.form = this.formBuilder.group({
      listMaquina: this.buildMaquina()
    })
  }

  buildMaquina() {
    if (this._data.contrato.maquinas.length > 0) {
      const maquina = this._data.contrato.maquinas.map(v => {
        return this.formBuilder.group({
          maquina: new FormControl(v.id || null)
        });
      });
      return this.formBuilder.array(maquina);
    } else {
      let maquina = [];
      maquina.push(this.formBuilder.group({
        maquina: new FormControl(null)
      }));
      return this.formBuilder.array(maquina);
    }
  }

  get maquinaForms(): FormArray {
    return this.form.get('listMaquina') as FormArray;
  }

  onCancel() {
    this.dialogRef.close(false)
  }

  onSave(value) {
    this.dialogRef.close(value)
  }

  addMaquina(required?: boolean): void {
    const phone = this.formBuilder.group({
      maquina: new FormControl(null)
    });

    this.maquinaForms.push(phone);
  };

  deleteMaquina(index: number): void {
    this.maquinaForms.removeAt(index);
  }

  private filterMaquinas() {
    if (!this.maquinas) {
      return;
    }

    let search = this.MaquinaFilterControl.value;
    if (!search) {
      this.filteredMaquina.next(this.maquinas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMaquina.next(
      this.maquinas.filter(m => m.numeroSerie.toLowerCase().indexOf(search) > -1)
    );
  }


}
