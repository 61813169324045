import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FolhaPagamentoService } from 'app/main/financial/components/folha-pagamento/services/folha-pagamento.services';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';
import { FolhaPagamento } from '../../model/folha-pagamento/folha-pagamento.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
interface DATA{
  folha: FolhaPagamento;
}

@Component({
  selector: 'app-folha-pagamento-form',
  templateUrl: './folha-pagamento-form.component.html',
  styleUrls: ['./folha-pagamento-form.component.scss']
})
export class FolhaPagamentoFormComponent implements OnInit, AfterViewInit {

  folha: FormGroup
  disabledButton: boolean;
  dialogMessages;
  postos: Array<any>;
  posto: Posto;
  postoList: any[] = [];

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


  @ViewChild('dataInicio') dataInicio: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public _data: DATA,
  private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<FolhaPagamentoFormComponent>,
    public dialog: MatDialog,
    private folhaPagamentoServce: FolhaPagamentoService,
    private postoService: PostsService) { }

  ngOnInit() {
    this.setForm();
    this.postoLista();
  }

  ngAfterViewInit() {
    setTimeout(() => this.dataInicio.nativeElement.focus(), 1000);
  }

  private _onDestroy = new Subject<void>();

  setForm() {
    let posto = null;
    this.folha = this.formBuilder.group({
      inicioCompentencia: ['', Validators.required],
      fimCompentencia: ['', Validators.required],
      posto: [ posto ? this._data.folha.posto.descricao : null],
    })
  }

  create(value: any) {
    this.disabledButton = true;

    this.postos.forEach(posto => {
      if(posto.descricao == value.posto){
        value.posto = posto;
      }
    })

    this.folhaPagamentoServce.create(value).subscribe((data: ReturnApi) => {

      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close(data.object);

      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }

        this.feedback(this.dialogMessages);

        this.matDialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CAIXA', onerror);
      }

    })
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onCancel(): void {
    this.dialog.closeAll()
  }

  private postoLista(){
    this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object
      this.filteredPosto.next(this.postos.slice());
      this.postoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPosto();
        });
    })
  }

  private filterPosto() {
    if (!this.postos) {
      return;
    }

    let search = this.postoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
