import { Injectable } from '@angular/core';
import { EmpresaService } from '../services/empresa-service.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveEmpresaService {

  constructor(private empresaService: EmpresaService) { }


  resolve(): Observable<any> {
    return this.empresaService.findAll(10,1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
