import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersRoutingModule } from './routes/customers.routing';
import { SharedMaterialComponentsModule } from '../../shared-material-components.module';
import { CaptacoesRejeitadosModule } from './components/captacoes-rejeitados/captacoes-rejeitados.module';

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialComponentsModule,
    CustomersRoutingModule,
    CaptacoesRejeitadosModule,
  ],
})
export class CustomersModule { }
