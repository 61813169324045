import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';

@Component({
  selector: 'app-alterar-parcela-confirmada-pendencia-form',
  templateUrl: './alterar-parcela-confirmada-pendencia-form.component.html',
  styleUrls: ['./alterar-parcela-confirmada-pendencia-form.component.scss']
})
export class AlterarParcelaConfirmadaPendenciaFormComponent implements OnInit {

  @ViewChild('password') password: ElementRef;

  loginForm: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  titulo: string;
  disabled = false;
  superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  idPessoa = JSON.parse(sessionStorage.getItem('idPessoa'));

  constructor(private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<AlterarParcelaConfirmadaPendenciaFormComponent>,
    public dialog: MatDialog,
    private cobrancaService: CobrancasService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    // tslint:disable-next-line:triple-equals
    this.titulo = 'ALTERAR PARCELA';
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      username: null,
      idFuncionario: this.funcionario.idFuncionario,
      idPessoa: this.idPessoa,
      superUsuario: this.superUsuario
  });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  onSave(value: any): void {

    let action = 'alteracao_parcela_confirmada_na_pendencia';
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'alteracao_parcela_lancamento_entrada'){
      action = 'alteracao_parcela_lancamento_entrada';
    }

    this.cobrancaService.loginAlteracaoFinanceiro(value).subscribe(
      (result) => {
        if (result.success) {
          this.dialog.open(ReceberParcelaFormComponent, {
            data: {
              usuario: result.object.idUsuario,
              cobranca: this._data.cobranca,
              action: action
            }
          }).afterClosed().subscribe(data => { 
            this.matDialogRef.close(data);
          });
        } else {
          if (result.messages != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${result.messages[0].text}`
            };
            this.feedback(this.dialogMessages);
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
        }
      }
    );    
  } 

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
}
