import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptacoesRejeitadosListComponent } from './captacoes-rejeitados-list/captacoes-rejeitados-list.component';
import { CaptacoesRejeitadosMainComponent } from './captacoes-rejeitados-main/captacoes-rejeitados-main.component';
import { CaptacoesRejeitadosSidebarComponent } from './captacoes-rejeitados-sidebar/captacoes-rejeitados-sidebar.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedMaterialComponentsModule } from 'app/shared-material-components.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/main/shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';

const routes: Routes = [
  {
    path     : '',
    component: CaptacoesRejeitadosMainComponent,
    resolve  : {}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    NgxLoadingModule.forRoot({}),
    NgxPermissionsModule.forRoot({})
  ],
  declarations: [CaptacoesRejeitadosMainComponent,
    CaptacoesRejeitadosListComponent,
    CaptacoesRejeitadosSidebarComponent
  ],
  providers: [
  ]
})
export class CaptacoesRejeitadosModule { }
