import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Mask } from '@fagnerlima/ng-mask';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { ProdutoService } from 'app/main/global/components/produto/services/produto-service.service';
import { Product } from '../../model/product/product.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { VisitasService } from 'app/main/customers/components/visitas/services/visitas.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { Rota } from '../../model/rota/rota.model';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Posto } from '../../model/posto/posto.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';

@Component({
  selector: 'app-alterar-contrato-form',
  templateUrl: './alterar-contrato-form.component.html',
  styleUrls: ['./alterar-contrato-form.component.scss']
})
export class AlterarContratoFormComponent implements OnInit, AfterViewInit {

  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  form: FormGroup;
  mask = new Mask('00');
  produtoList: Product[]
  @ViewChild('valor') valor: ElementRef;
  dialogMessages;
  public usuario = JSON.parse(sessionStorage.getItem('funcionario'));
  postos: any;
  rotas: any;
  postoId = '';
  currentPosto: any;
  currentRota: Partial<Rota>;
  responsavel: Employee;
  habilitarDezParcelas: boolean;

  isPixOuDinheiro = false;

  title: string;
  public produtoFilterCtrl: FormControl = new FormControl();
  public filteredProduto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  readonly valorContratoAnterior = 0;
  readonly aprovacaoAnteriorCemPorCento = false;

  desabilitarBotao = false;

  postoDigital = false;


  employee = [];
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  public EmployeeFilterControl: FormControl = new FormControl();

  constructor(private formBuild: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<AlterarContratoFormComponent>,
    private contractService: ContractsService,
    private utilsService: UtilsService,
    private _matDialog: MatDialog,
    private produtoService: ProdutoService,
    private rotaService: RotasService,
    private postoService: PostsService,
    private visitaService: VisitasService,
    private employeeService: EmployeeService) {
    this.title = this.data.action == "alterContrato" ? "Alterar Contrato" : "Alterar Produto"
    if (this.data.action === 'gerar_novo_contrato') {
      this.title = 'Gerar Contrato para Cliente';
    }
    if (data.contrato ) {
      this.postoDigital = data.contrato.posto.parametroPosto.pixBoleto; 
      this.isPixOuDinheiro = (this.data.contrato.visita.pagamentoPix || this.data.contrato.visita.pagamentoDinheiro)
    } else if (data.cliente) {
      this.postoDigital = data.cliente.posto.parametroPosto.pixBoleto;
      this.isPixOuDinheiro = false;
    } else {
      this.postoDigital = false;
      this.isPixOuDinheiro = false;
    }
    this.postoDigital = data.contrato ? data.contrato.posto.parametroPosto.pixBoleto : data.cliente ? data.cliente.posto.parametroPosto.pixBoleto : false;

    this.valorContratoAnterior = this.data.contrato ? this.data.contrato.visita.valorAprovado : 0;
    this.aprovacaoAnteriorCemPorCento = this.data.contrato ? this.data.contrato.aprovacaoAnteriorCemPorCento : false;
    

    this.produtoService.findAll().subscribe(data => {
      if (data.object) {
        this.produtoList = data.object.content;
        this.filteredProduto.next(this.produtoList.slice());
        this.produtoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProduto();
        });
      }
    })

    if (this.usuario.idPosto != null) {
      this.postoId = this.usuario.idPosto;

      // lista os postos
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
      });

      // seleciona o posto que esta logado
      this.postoService.findById(this.usuario.idPosto).subscribe(data => {
        this.currentPosto = data.object;
        this.habilitarDezParcelas = this.currentPosto.parametroPosto.habilitarDezParcelas;
        this.displayFnPosto(data.object);
      });

      this.rotaService.findByIdPosto(parseInt(this.postoId)).subscribe(data => {
        this.rotas = data.object;
      });
    }

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    })
  }

  ngOnInit() {
    this.setForm();
    this.changeFormaPagamento(4);
  }

  private _onDestroy = new Subject<void>();

  ngAfterViewInit() {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  changeFormaPagamento(value: number) {
    const boleto = this.form.get('pagamentoBoleto');
    const cartao = this.form.get('pagamentoCartao');
    const dinheiro = this.form.get('pagamentoDinheiro');
    const pix = this.form.get('pagamentoPix');

    const mapped = new Map<number, AbstractControl>();
    mapped.set(1, boleto);
    mapped.set(2, cartao);
    mapped.set(3, dinheiro);
    mapped.set(4, pix);

    mapped.get(value).setValue(true);
    mapped.delete(value);
    mapped.forEach(control => control.setValue(false));

   

    if (value === 1) {
      this.form.get('quantidadeParcelas').setValue(2);
    } else if (value === 4) {
      this.form.get('quantidadeParcelas').setValue(10);
    } else {
      this.form.get('quantidadeParcelas').setValue(20);
    }
  }

  setForm(): void {
    if (this.data.action == "alterContrato") {
      this.form = this.formBuild.group({
        cliente: [this.data.contrato ? this.data.contrato.visita.cliente.pessoa.nomePessoa : null],
        valorAprovado: [this.data.contrato ? this.data.contrato.visita.valorAprovado : null],
        quantidadeParcelas: [this.data.contrato ? this.data.contrato.visita.quantidadeParcelas : null],
        dataVencimento: [new Date(this.data.contrato ? this.data.contrato.dataVencimento : null)]
      });
    } else if (this.data.action === 'gerar_novo_contrato') {
      if (this.data.tem_rota) {
        this.form = this.formBuild.group({
          contratoId: [this.data.contrato.id],
          cliente: [this.data.contrato.visita.cliente.pessoa.nomePessoa],
          valorAprovado: [this.data.contrato ? this.data.contrato.visita.valorAprovado : null],
          quantidadeParcelas: [this.data.contrato ? this.data.contrato.visita.quantidadeParcelas : null],
          pagamentoBoleto: [this.data.contrato.visita.pagamentoBoleto ? this.data.contrato.visita.pagamentoBoleto : false],
          pagamentoPix: [this.data.contrato.visita.pagamentoPix ? this.data.contrato.visita.pagamentoPix : true],
          pagamentoDinheiro: [this.data.contrato.visita.pagamentoDinheiro ? this.data.contrato.visita.pagamentoDinheiro : false],
          pagamentoCartao: [this.data.contrato.visita.pagamentoCartao ? this.data.contrato.visita.pagamentoCartao : false],
          indicador: [this.data.contrato.indicador ? this.data.contrato.indicador.id : null],
          periodicidadeParcela: [this.data.contrato.visita.periodicidadeParcela ? this.data.contrato.visita.periodicidadeParcela : null],
          taxaJuros: [this.data.contrato.visita.taxaJuros ? this.data.contrato.visita.taxaJuros : null]
        });
      } else {
        this.form = this.formBuild.group({
          contratoId: [null],
          cliente: [this.data.cliente.pessoa.nomePessoa],
          valorAprovado: [this.data.contrato ? this.data.contrato.visita.valorAprovado : null],
          quantidadeParcelas: [this.data.contrato ? this.data.contrato.visita.quantidadeParcelas : null],
          indicador: [this.data.contrato && this.data.contrato.indicador ? this.data.contrato.indicador.id : null],
          pagamentoBoleto: [false],
          pagamentoPix: [false],
          pagamentoDinheiro: [false],
          pagamentoCartao: [false]
        });
      }

    } else {
      this.form = this.formBuild.group({
        cliente: [this.data.contrato.visita.cliente.pessoa.nomePessoa],
        id: [this.data.contrato.visita.id || null],
        produto: [this.data.contrato.visita.produto ? this.data.contrato.visita.produto.id : null],
      });
    }
  }

  onClose() {
    this.matDialogRef.close()
  }

  onUpdate(value) {
    delete value.cliente
    if (this.data.action == "alterContrato") {
      this.updateContrato(value);
    } else if (this.data.action === 'gerar_novo_contrato') {
      this.gerarNovoContratoParaClienteInativo(value);
    } else {
      this.alterarProdutoVisita(value);
    }

  }

  private feedback(message: string[]) {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  private filterProduto() {
    if (!this.produtoList) {
      return;
    }

    let search = this.produtoFilterCtrl.value;
    if (!search) {
      this.filteredProduto.next(this.produtoList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredProduto.next(
      this.produtoList.filter(p => p.nome.toLowerCase().indexOf(search) > -1)
    );

  }

  private updateContrato(contrato) {
    this.data.contrato.visita.valorAprovado = contrato.valorAprovado;
    this.data.contrato.visita.quantidadeParcelas = parseInt(contrato.quantidadeParcelas);
    this.data.contrato.dataVencimento = contrato.dataVencimento;

    this.contractService.alterarContrato(this.data.contrato).subscribe((data) => {
      if (data.success) {
        this.dialogMessages = {
          type: 'success',
          title: 'Sucesso!',
          text: `${data.messages[0].text}`
        };

        this.feedback(this.dialogMessages);
        this.matDialogRef.close(data.object);

        this.contractService.getContract(data.object.id).subscribe(ondata => {
          let objectURL = URL.createObjectURL(ondata);
          window.open(objectURL, '_blank').print()
        });
      } else {
        if (data.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${data.messages[0].text}`
          }
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          }
        }
        this.feedback(this.dialogMessages);
        this.matDialogRef.close();
      }
      (onerror) => {
        console.log('ERROR CONTRATO', onerror);
      }
    });
  }

  private gerarNovoContratoParaClienteInativo(form): void {
    this.desabilitarBotao = true;
    const funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    const superUsuario = sessionStorage.getItem('superUsuario') === 'true';
    let taxaJuros = null;

    if (typeof form.taxaJuros === 'string') {
      taxaJuros = parseFloat(form.taxaJuros.replace('%', ''));
  }
    const dto = {
      contratoId: form.contratoId,
      valorBruto: form.valorAprovado,
      quantidadeParcelas: form.quantidadeParcelas,
      validarAumentoCemPorCento: !superUsuario && funcionario.cargo !== 'DIRETOR FINANCEIRO',
      rotaId: this.data.tem_rota ? null : this.currentRota.id,
      clienteId: this.data.tem_rota ? null : this.data.cliente.id,
      pagamentoCartao: form.pagamentoCartao,
      pagamentoBoleto: form.pagamentoBoleto,
      pagamentoPix: form.pagamentoPix,
      pagamentoDinheiro: form.pagamentoDinheiro,
      indicadorId: this.responsavel ? this.responsavel.id : null,
      periodicidadeParcela: form.periodicidadeParcela,
      taxaJuros: form.taxaJuros
    };

    const gerarContrato = () => {
      this.contractService.gerarNovoContratoParaClienteInativo(dto).subscribe(data => {
        if (!data.success) {
          this.desabilitarBotao = false;
        }
        this.utilsService.feedbackApi(data, 'Contrato gerado com sucesso', TimeMensagem.TIME_MENSAGEM_FINANCEIRO, () => {
          this.matDialogRef.close(data.object);
        });
      }, () => this.desabilitarBotao = false);
    };
    if (dto.validarAumentoCemPorCento === false && (dto.valorBruto >= this.valorContratoAnterior * 2 || this.aprovacaoAnteriorCemPorCento) && this.data.tem_rota) {
      this.utilsService.confirmAction({
        text: 'Deseja realmente aprovar crédito igual ou superior a 100% do valor anterior aprovado?',
        onConfirm: () => gerarContrato(),
        onCancel: () => this.desabilitarBotao = false
      });
    } else {
      gerarContrato();
    }
  }

  private alterarProdutoVisita(value) {
    let visita = this.data.contrato.visita;
    this.produtoService.findById(value.produto).subscribe(res => {
      visita.produto = res.object;

      this.visitaService.update(visita).subscribe(data => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          };

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data.object);
        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            }
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }
          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }
        (onerror) => {
          console.log('ERROR CONTRATO', onerror);
        }
      })
    })
  }

  displayFnPosto(posto: Posto): any {
    return posto ? posto.descricao : 'Selecione...';
  }

  displayFnRota(rota: { id: number; descricao: string }): string {
    return rota ? rota.descricao : 'Selecione...';
  }

  serviceOnSelectRota(rota: any): any {
    this.currentRota = rota;
  }

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  public selectResponsavel(value){
    this.responsavel = value;
  }
}
