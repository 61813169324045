import {ProcessReleasesComponent} from './../../../app/main/processing/components/process-releases/process-releases-main/process-releases.component';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {FuseNavigationItem} from '@fuse/types';
import {MatDialog} from '@angular/material';
import {SharedService} from 'app/shared/shared.service';
import {ClienteService} from 'app/main/customers/components/cliente/services/cliente.service';
import {SGF_API, SGF_TYPE} from 'app/shared/api/sgf.api';
import {PeriodReportComponent} from 'app/main/report/components/period-report/period-report-main/period-report.component';
import {ContractsService} from 'app/main/contracts/services/contracts.service';
import {MessageDialogComponent} from 'app/main/shared/components/message-dialog/message-dialog.component';
import {ParameterFormComponent} from 'app/main/parameter/components/parameter-form/parameter-form-main/parameter-form.component';
import {DailyCaixaReportComponent} from 'app/main/report/components/daily-caixa-report/daily-caixa-report-main/daily-caixa-report.component';
import {RelatorioAcompanhamentoVisitaMainComponent} from '../../../app/main/report/components/relatorio-acompanhamento-visita/relatorio-acompanhamento-visita-main/relatorio-acompanhamento-visita-main.component';
import { ConferenciaCaixaGeralFormComponent } from 'app/main/shared/components/conferencia-caixa-geral-form/conferencia-caixa-geral-form.component';

@Injectable({
    providedIn: 'root'
})
export class FuseNavigationService {
    onItemCollapsed: Subject<any>;
    onItemCollapseToggled: Subject<any>;

    // Private
    private _onNavigationChanged: BehaviorSubject<any>;
    private _onNavigationRegistered: BehaviorSubject<any>;
    private _onNavigationUnregistered: BehaviorSubject<any>;

    private _currentNavigationKey: string;
    private _registry: { [key: string]: any } = {};
    shared: SharedService;
    host = `${SGF_API}`

    // tslint:disable-next-line:triple-equals
    fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
    // tslint:disable-next-line:triple-equals
    fortBoleto = `${SGF_TYPE}` == 'FORTALEZA-BOLETOS' ? true : false;
    // tslint:disable-next-line:triple-equals
    fortValores = `${SGF_TYPE}` == 'FORTALEZA-VALORES' ? true : false;
    dev = `${SGF_TYPE}` == 'DEV' ? true : false;
    dialogMessages: any;
    /**
     * Constructor
     */
    constructor(private _matDialog: MatDialog,
        private clienteService: ClienteService,
        private contratoService: ContractsService
    ) {
        // Set the defaults
        this.onItemCollapsed = new Subject();
        this.onItemCollapseToggled = new Subject();

        // Set the private defaults
        this._currentNavigationKey = null;
        this._onNavigationChanged = new BehaviorSubject(null);
        this._onNavigationRegistered = new BehaviorSubject(null);
        this._onNavigationUnregistered = new BehaviorSubject(null);

        this.shared = SharedService.getInstance();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get onNavigationChanged
     *
     * @returns {Observable<any>}
     */
    get onNavigationChanged(): Observable<any> {
        return this._onNavigationChanged.asObservable();
    }

    /**
     * Get onNavigationRegistered
     *
     * @returns {Observable<any>}
     */
    get onNavigationRegistered(): Observable<any> {
        return this._onNavigationRegistered.asObservable();
    }

    /**
     * Get onNavigationUnregistered
     *
     * @returns {Observable<any>}
     */
    get onNavigationUnregistered(): Observable<any> {
        return this._onNavigationUnregistered.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register the given navigation
     * with the given key
     *
     * @param key
     * @param navigation
     */
    register(key, navigation): void {
        // Check if the key already being used
        if (this._registry[key]) {
            console.error(`The navigation with the key '${key}' already exists. Either unregister it first or use a unique key.`);

            return;
        }

        // Add to the registry
        this._registry[key] = navigation;

        // Notify the subject
        this._onNavigationRegistered.next([key, navigation]);
    }

    /**
     * Unregister the navigation from the registry
     * @param key
     */
    unregister(key): void {
        // Check if the navigation exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);
        }

        // Unregister the sidebar
        delete this._registry[key];

        // Notify the subject
        this._onNavigationUnregistered.next(key);
    }

    /**
     * Get navigation from registry by key
     *
     * @param key
     * @returns {any}
     */
    getNavigation(key): any {
        // Check if the navigation exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Return the sidebar
        return this._registry[key];
    }

    /**
     * Get flattened navigation array
     *
     * @param navigation
     * @param flatNavigation
     * @returns {any[]}
     */
    getFlatNavigation(navigation, flatNavigation: FuseNavigationItem[] = []): any {



        for (const item of navigation) {
            if (item.type === 'item') {
                flatNavigation.push(item);

                continue;
            }

            if (item.type === 'collapsable' || item.type === 'group') {
                if (item.children) {
                    this.getFlatNavigation(item.children, flatNavigation);
                }
            }
        }

        return flatNavigation;
    }

    /**
     * Get the current navigation
     *
     * @returns {any}
     */
    getCurrentNavigation(): any {
        if (!this._currentNavigationKey) {
            console.warn(`The current navigation is not set.`);

            return;
        }

        return this.getNavigation(this._currentNavigationKey);
    }

    /**
     * Set the navigation with the key
     * as the current navigation
     *
     * @param key
     */
    setCurrentNavigation(key): void {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Set the current navigation key
        this._currentNavigationKey = key;

        // Notify the subject
        this._onNavigationChanged.next(key);
    }

    /**
     * Get navigation item by id from the
     * current navigation
     *
     * @param id
     * @param {any} navigation
     * @returns {any | boolean}
     */
    getNavigationItem(id, navigation = null): any | boolean {

        if (!navigation) {
            navigation = this.getCurrentNavigation();
        }

        for (const item of navigation) {
            if (item.id === id) {
                return item;
            }

            if (item.children) {
                const childItem = this.getNavigationItem(id, item.children);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return false;
    }

    /**
     * Get the parent of the navigation item
     * with the id
     *
     * @param id
     * @param {any} navigation
     * @param parent
     */
    getNavigationItemParent(id, navigation = null, parent = null): any {
        if (!navigation) {
            navigation = this.getCurrentNavigation();
            parent = navigation;
        }

        for (const item of navigation) {
            if (item.id === id) {
                return parent;
            }

            if (item.children) {
                const childItem = this.getNavigationItemParent(id, item.children, item);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return false;
    }

    /**
     * Add a navigation item to the specified location
     *
     * @param item
     * @param id
     */
    addNavigationItem(item, id): void {
        // Get the current navigation
        const navigation: any[] = this.getCurrentNavigation();

        // Add to the end of the navigation
        if (id === 'end') {
            navigation.push(item);

            return;
        }

        // Add to the start of the navigation
        if (id === 'start') {
            navigation.unshift(item);
        }

        // Add it to a specific location
        const parent: any = this.getNavigationItem(id);

        if (parent) {
            // Check if parent has a children entry,
            // and add it if it doesn't
            if (!parent.children) {
                parent.children = [];
            }

            // Add the item
            parent.children.push(item);
        }
    }

    /**
     * Remove navigation item with the given id
     *
     * @param id
     */

    addNavItemWithCustomFunction(navigation: any): void {

        const newNavItem5 = {
            id: 'REPORT_PROCESSAR_LACAMENTOS',
            type: 'item',
            title: 'Processar Lançamentos',
            function: () => {
                this._matDialog.open(ProcessReleasesComponent).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem6 = {
            id: 'REPORT_CONTRATO_POR_PERIODO',
            type: 'item',
            title: 'Contrato por período',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                    data: {
                        type: 'contrato-por-periodo',
                        tittle: 'Contratos por período'
                    }
                }).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem7 = {
            id: 'SUPER_USUARIO',
            type: 'item',
            title: 'Download',
            function: () => {
                window.open(`${this.host}financeiro/api/public/contrato/download/rotacontrato`);
            },
        };

        const newNavItem8 = {
            id: 'MENU_PARAMETRO',
            type: 'item',
            title: 'Parametro',
            function: () => {
                this._matDialog.open(ParameterFormComponent).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem9 = {
            id: 'REPORT_FUNCIONARIO',
            title: 'Funcionario',
            type: 'collapsable',
            children: [
                {
                    id: 'REPORT_FUNCIONARIO_COMISSAO',
                    type: 'item',
                    title: 'Comissão',

                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'relatorio-comissao',
                                tittle: 'Comissão dos funcionários'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                },
                {
                    id: 'REPORT_FUNCIONARIO_TITULOS_RECEBER',
                    type: 'item',
                    title: 'Títulos a receber',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'relatorio-titulo',
                                tittle: 'Títulos a receber'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                },
                {
                    id: 'REPORT_FUNCIONARIO_TITULOS_RECEBER_GERAL',
                    type: 'item',
                    title: 'Títulos a receber geral',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'relatorio-titulo-geral',
                                tittle: 'Títulos a receber geral'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                },
                {
                    id: 'REPORT_FUNCIONARIO_DESPESAS',
                    type: 'item',
                    title: 'Relatório de despesas',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'relatorio-despesas',
                                tittle: 'Relatório de despesas'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                }
            ]
        };

        const newNavItem10 = {
            id: 'REPORT_CLIENTE',
            title: 'Cliente',
            type: 'collapsable',
            children: [
                {
                    id: 'REPORT_CLIENTE_INATIVO',
                    type: 'item',
                    title: 'Inativos',
                    function: () => {

                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'clientes-inativos-por-periodo',
                                tittle: 'Relatório de Clientes Inativos'
                            }
                        }).afterClosed().subscribe(data => { });
                    }
                },
                {
                    id: 'REPORT_CLIENTE_INADIMPLENTE_GERAL',
                    type: 'item',
                    title: 'Inadimplentes (Geral)',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'clientes-inadimplentes',
                                tittle: 'Relatório de Clientes Inadimplentes'
                            }
                        }).afterClosed().subscribe(data => { });
                    }
                },
                {
                    id: 'REPORT_CLIENTE_INADIMPLENTE_REGULAR',
                    type: 'item',
                    title: 'Inadimplentes Regulares',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'clientes-inadimplentes-regular',
                                tittle: 'Relatório de Clientes Inadimplentes Regulares'
                            }
                        }).afterClosed().subscribe(data => { });
                    }
                },
                {
                    id: 'REPORT_CLIENTE_INADIMPLENTE_IRREGULAR',
                    type: 'item',
                    title: 'Inadimplentes Irregulares',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'clientes-inadimplentes-irregular',
                                tittle: 'Relatório de Clientes Inadimplentes Irregulares'
                            }
                        }).afterClosed().subscribe(data => { });
                    }
                },
                {
                    id: 'REPORT_APROXIMACAO_DE_LIQUIDACAO',
                    type: 'item',
                    title: 'Próximos a Liquidação',
                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'clientes-aproximacao-de-liquidacao',
                                tittle: 'Relatório de Clientes Próximos a Liquidação'
                            }
                        }).afterClosed().subscribe(data => { });
                    }
                },
            ]
        };

        const newNavItem12 = {
            id: 'REPORT_FALTAS_SOBRAS',
            type: 'item',
            title: 'Relatório de Faltas e Sobras',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                      data: {
                          type: 'faltas-e-sobras',
                          tittle: 'Relatório de faltas e sobras'
                      }
                  }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem13 = {
            id: 'REPORT_CAIXAS',
            title: 'Caixa Diário',
            type: 'collapsable',
            children: [
                {
                    id: 'REPORT_CAIXAS_GERAL_ANALITICO',
                    type: 'item',
                    title: 'Geral Analítico',

                    function: () => {
                        this._matDialog.open(DailyCaixaReportComponent, {
                            data: {
                                type: 'caixa-geral'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                },
                {
                    id: 'REPORT_CAIXA_SINTETICO',
                    type: 'item',
                    title: 'Geral sintético',

                    function: () => {
                        this._matDialog.open(DailyCaixaReportComponent, {
                            data: {
                                type: 'caixa-geral-resumido'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                },
                {
                    id: 'REPORT_CAIXA_FUNCIONARIO',
                    type: 'item',
                    title: 'Funcionário',
                    function: () => {
                        this._matDialog.open(DailyCaixaReportComponent, {
                            data: {
                                type: 'caixa-funcionario'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                }
            ]
        };

        const newNavItem17 = {
            id: 'REPORT_ACOMPANHAMENTO_OPERACIONAL',
            type: 'item',
            title: 'Acompanhamento Operacional',
            function: () => {
                this._matDialog.open(RelatorioAcompanhamentoVisitaMainComponent, {
                    data: {}
                }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem18 = {
            id: 'REPORT_COBRANCA_SEMANAL',
            type: 'item',
            title: 'Cobranças Realizadas Semanal',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                        data: {
                            type: 'cobrancas-semanal',
                            tittle: 'Relatório de Cobranças semanal'
                        }
                }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem19 = {
            id: 'REPORT_REPASSES',
            type: 'item',
            title: 'Relatorio de Repasses',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                    data: {
                        type: 'repasses',
                        tittle: 'Relatório de repasses'
                    }
                }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem20 = {
            id: 'REPORT_REAVALIACAO_CADASTRAL_CLIENTES',
            type: 'item',
            title: 'Reavaliação Cadastral de Clientes',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                        data: {
                            type: 'reavaliacao-cadastral-clientes',
                            tittle: 'Reavaliação Cadastral de Clientes'
                        }
                    }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem21 = {
            id: 'REPORT_CONTRATOS_POR_CLIENTE',
            type: 'item',
            title: 'Relatório de Contratos por Cliente',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                        data: {
                            type: 'contratos-por-cliente',
                            tittle: 'Relatório de contratos por cliente'
                        }
                    }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem22 = {
            id: 'REPORT_FIADORES',
            type: 'item',
            title: 'Relatório de Fiadores',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                        data: {
                            type: 'fiadores',
                            tittle: 'Relatório de Fiadores'
                        }
                    }
                ).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem30 = {
            id: 'REPORT_GERENCIAL',
            title: 'Relatório Gerencial',
            type: 'collapsable',
            children: [
                {
                    id: 'REPORT_GERENCIAL_REPASSE_DIARIO',
                    type: 'item',
                    title: 'Repasse Diário',

                    function: () => {
                        this._matDialog.open(PeriodReportComponent, {
                            data: {
                                type: 'sig-repasses-diarios',
                                tittle: 'Repasse diário'
                            }
                        }).afterClosed().subscribe(data => { });
                    },
                }
            ]
        };


        const newNavItem31 = {
            id: 'REPORT_CONFERENCIA_CAIXA_GERAL',
            type: 'item',
            title: 'Conferência de Caixa Geral',
            function: () => {
                this._matDialog.open(ConferenciaCaixaGeralFormComponent, {
                    data: {
                        type: 'teste',
                    }
                }).afterClosed().subscribe(data => { });
            },
        };

        const newNavItem32 = {
            id: 'REPORT_NOVO_FICHA_CADASTRAL',
            type: 'item',
            title: 'Ficha Cadastral',
            function: () => {
                this._matDialog.open(PeriodReportComponent, {
                    data: { 
                        type: 'novo-ficha-cadastral',
                        tittle: 'Ficha Cadastral',
                        inputTextLabel: 'Nome do Cliente'
                    }
                }).afterClosed().subscribe(data => {});
            }
        };


        navigation[3].children = [newNavItem31, ...navigation[3].children];
        navigation[6].children = [newNavItem5, ...navigation[6].children];
        navigation[7].children = [newNavItem30, newNavItem6, newNavItem9, newNavItem10, newNavItem13, newNavItem12, newNavItem17,
            newNavItem18, newNavItem19, newNavItem20, newNavItem21, newNavItem22, newNavItem32,  ...navigation[7].children];
        navigation[8].children = [newNavItem7, ...navigation[8].children];
        navigation[9].children = [newNavItem8, ...navigation[9].children];

        return navigation;

    }
    removeNavigationItem(id): void {
        const item = this.getNavigationItem(id);

        // Return, if there is not such an item
        if (!item) {
            return;
        }

        // Get the parent of the item
        let parent = this.getNavigationItemParent(id);

        // This check is required because of the first level
        // of the navigation, since the first level is not
        // inside the 'children' array
        parent = parent.children || parent;

        // Remove the item
        parent.splice(parent.indexOf(item), 1);
    }

    // tslint:disable-next-line:typedef
    private feedback(message: string[]) {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }
}
