import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';

@Component({
  selector: 'app-contrato-lancamento-form',
  templateUrl: './contrato-lancamento-form.component.html',
  styleUrls: ['./contrato-lancamento-form.component.scss']
})
export class ContratoLancamentoFormComponent implements OnInit {

  form: FormGroup;
  employee: Array<Employee>
  disabledButton:boolean;

  public LancamentoFilterControl: FormControl = new FormControl();
  public mask = '#0,00?reverse=true';
  
  @ViewChild('valorPago') valorPago: ElementRef;

  constructor(private formBuilder : FormBuilder,
              public dialog : MatDialog,
              public dialogRef : MatDialogRef<ContratoLancamentoFormComponent>,
              @Inject(MAT_DIALOG_DATA) public _data: any) { 
  }

  ngOnInit() {
    this.setForm();
  }

  ngAfterViewInit() {
    setTimeout(() => this.valorPago.nativeElement.focus(), 1000);
  }

  setForm(){
    this.form = this.formBuilder.group({
      valor : this._data.lancamento.valorLancamento
    })
  }

  onCancel(): void {
    this.dialog.closeAll()
  }

  onSave(value){
    this.disabledButton = true;
    this.dialogRef.close(value);
  }

}
