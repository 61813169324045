import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Page } from 'app/main/shared/model/page/page.model';
import { CustomersModel } from 'app/main/shared/model/customers/customers-model';
import { NgxPermissionsService } from 'ngx-permissions';
import { UtilsService } from 'app/shared/util/utils.service';
import { CaptacaoService } from '../../captacao/services/captacao.service';
import { TimeMensagem } from 'app/main/shared/model/enums/time-mensagem.enum';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';

@Component({
  selector: 'app-captacoes-rejeitados-list',
  templateUrl: './captacoes-rejeitados-list.component.html',
  styleUrls: ['./captacoes-rejeitados-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CaptacoesRejeitadosListComponent implements OnInit {

  @Input() rows: any;
  @Input() page: Page;
  @Output() recaptacao = new EventEmitter();

  selected: Array<CustomersModel>;
  timeout: any;
  index = 0;
  current: CustomersModel;
  dialogRef: any;
  interval;
  permissoes = [];

  constructor(private permissionsService: NgxPermissionsService, private utilsService: UtilsService, private captadoService: CaptacaoService) {
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void {
  }

  onRecaptarRejeitado(value): void {
    this.utilsService.confirmAction({
      title: 'Recaptar Rejeitado',
      text: 'A captação ficará novamente disponível para agendamento de visita em Clientes Captados. Deseja recaptar o cliente?',
      type: 'info',
      onConfirm: () => {
        const feedback = (response: ReturnApi) => this.utilsService
          .feedbackApi(response, 'Recaptação efetuado com sucesso', TimeMensagem.TIME_MENSAGEM_FINANCEIRO, () => {
            this.recaptacao.emit(value);
          });
        this.captadoService.recaptarRejeitado(value.id).subscribe(feedback);
      }
    });
  }

  buildNumber(numero: string): any {
    // tslint:disable-next-line:triple-equals
    if (numero.substring(0, 1) != '(') {
      // tslint:disable-next-line:triple-equals
      if (numero.length == 11) {
        numero = `(${numero.substr(0, 2)})${numero.substr(2, 5)}-${numero.substr(7, 4)}`;
      } else {
        numero = `(${numero.substr(0, 2)})${numero.substr(2, 4)}-${numero.substr(6, 4)}`;
      }
    } else {
      // tslint:disable-next-line:no-unused-expression
      numero;
    }
    return numero;
  }

}
