import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResolveCaptacaoService } from '../components/captacao/resolve/resolve-captacao.service';
import { ResolveVisitaService } from '../components/visitas/resolve/resolve-visita.service';
import { ResolveVisitadosService } from '../components/visitados/resolve/resolve-visitados.service';
import { ResolveCreditoService } from '../components/creditos/resolve/resolve-credito.service';
import { ResolveClienteService } from '../components/cliente/resolve/resolve-cliente.service';
import { ResolveAcompanhamentoVisitasService } from '../components/acompanhamento-visitas/resolve/resolve-acompanhamento-visitas.service';
import { ResolveClientePlanejamentoService } from '../components/cliente-planejamento/resolve/resolve-cliente-planejamento.service';
import { ResolveCaptacoesRejeitadosService } from '../components/captacoes-rejeitados/resolve/resolve-captacoes-rejeitados.service';
import { ResolveClienteInativoService } from '../components/cliente-inativo/resolve/resolve-cliente-inativo.service';

const CustomerModuleRoutes: Routes = [
  {
    path: 'clientes',
    loadChildren: '../components/cliente/cliente.module#ClienteModule',
    resolve: { rows: ResolveClienteService },
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'captacao',
    loadChildren: '../components/captacao/captacao.module#CaptacaoModule',
    resolve: { rows: ResolveCaptacaoService }
  },
  {
    path: 'visitas',
    loadChildren: '../components/visitas/visitas.module#VisitasModule',
    resolve: { rows: ResolveVisitaService }
  },
  {
    path: 'visitados',
    loadChildren: '../components/visitados/visitados.module#VisitadosModule',
    resolve: { rows: ResolveVisitadosService }
  },
  {
    path: 'creditos',
    loadChildren: '../components/creditos/creditos.module#CreditosModule',
    resolve: { rows: ResolveCreditoService }
  },
  {
    path: 'acompanhamento-visitas',
    loadChildren: '../components/acompanhamento-visitas/acompanhamento-visitas.module#AcompanhamentoVisitasModule',
    resolve: { rows: ResolveAcompanhamentoVisitasService }
  },
  {
    path: 'cliente-planejamento',
    loadChildren: '../components/cliente-planejamento/cliente-planejamento.module#ClientePlanejamentoModule',
    resolve: { rows: ResolveClientePlanejamentoService }
  },
  {
    path: 'captacoes-rejeitadas',
    loadChildren: '../components/captacoes-rejeitados/captacoes-rejeitados.module#CaptacoesRejeitadosModule',
    resolve: { rows: ResolveCaptacoesRejeitadosService }
  },
  {
    path: 'clientes-inativos',
    loadChildren: '../components/cliente-inativo/cliente-inativo.module#ClienteInativoModule',
    resolve: { rows: ResolveClienteInativoService }
  }

];

@NgModule({
  imports: [RouterModule.forChild(CustomerModuleRoutes)],
  exports: [RouterModule],
  providers: [
    ResolveCaptacaoService,
    ResolveVisitaService,
    ResolveVisitadosService,
    ResolveCreditoService,
    ResolveCaptacoesRejeitadosService
  ]
})
export class CustomersRoutingModule { }
