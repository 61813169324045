import { Permission } from '../permission/permission.model';

export class UserProfile {
    
    id: number;
    ativo: boolean;
    descricao: string;
    listPermissao: Array<Permission>;
    nivelPerfil: string;

    constructor(obj?){

        this.id             = obj ? obj.id              : null;
        this.ativo          = obj ? obj.ativo           : true;
        this.descricao      = obj ? obj.descricao       : null;
        this.listPermissao  = obj ? obj.listPermissao   : null;
        this.nivelPerfil    = obj ? obj.nivelPerfil     : null;
    }

}
