import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';
import { Titulo } from 'app/main/shared/model/titulo/titulo.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { NgxPermissionsService } from 'ngx-permissions';
declare var jsPDF: any;

@Component({
  selector: 'app-in-progress-boleto-detail',
  templateUrl: './in-progress-boleto-detail.component.html',
  styleUrls: ['./in-progress-boleto-detail.component.scss'],
  animations: fuseAnimations
})
export class InProgressBoletoDetailComponent implements OnInit {

  contrato: any;
  titulos: Titulo[];
  searchInput: FormControl;
  interval;
  filter;
  selected: Array<any>;
  dialogMessages = [];
  count: number = 0;
  public loading = false;
  valorPago: number = 0;
  valorAReceber: number = 0;
  selectedClear: boolean;
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  permissoes = [];
  constructor(private contratoService: ContractsService,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService) {

    this.selected = new Array<Parcela>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'))
    perm.forEach(element => {
      this.permissoes.push(element)
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {
    this.listById();
  }

  clearSelectBox() {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  listById() {
    this.contratoService.findById(this.route.snapshot.params['id'])
    this.contratoService.getBoletos(this.route.snapshot.params['id']).subscribe(data => {
      if (data.object.length > 0) {
        this.contrato = data.object[0].contrato;
      }
      this.titulos = data.object;
    })
  }


  over() {
    this.loading = true;
    this.contratoService.getBoletos(this.route.snapshot.params['id']).subscribe(data => {
      if (data.object.length > 0) {
        this.contrato = data.object[0].contrato;
      }
      this.titulos = data.object;
    })
    this.clearSelectBox();
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  gerarPDF() {
    let rows = []
    let dataEmissao = new Date(this.contrato.dataEmissao)
    let dataVencimento = new Date(this.contrato.dataVencimento)
    let doc = new jsPDF('p', 'pt');

    doc.text(7, 20, `${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}`);
    doc.setFontSize(10);
    doc.text(7, 40, `Emissão: ${dataEmissao.toLocaleDateString('pt-br')}, Vencimento: ${dataVencimento.toLocaleDateString('pt-br')}, Situação: ${this.contrato.situacaoContrato}`);
    doc.setFontSize(10);
    doc.text(7, 60, `Valor do Contrato: ${this.contrato.visita.valorAprovado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, Responsável: ${this.contrato.responsavel.pessoa.nome}`);


    //Keys
    let columns = [
      { title: "Parcela", dataKey: "ordem" },
      { title: "Responsável", dataKey: "responsavel" },
      { title: "Vencimento", dataKey: "dataVencimento" },
      { title: "Valor da Parcela", dataKey: "valor" },
      { title: "Valor Recebido", dataKey: "valorPago" },
    ];


    //Objeto criado para utilização da tablea
    this.contrato.listParcela.forEach(element => {
      let date = new Date(element.dataVencimento)
      let data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valor: element.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorPago: element.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        saldoParcela: (element.valor - element.valorPago).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        responsavel: element.responsavel.pessoa.nomePessoa,
        ordem: element.ordem,
        situacaoParcela: element.situacaoParcela
      }
      rows.push(data)
    })

    //Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: "striped",
      margin: { horizontal: 7, top: 70 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
    });


    //Tabela exibida no pdf
    doc.autoTable(columns = [
      { title: "Valor a Receber", dataKey: "valorAReceber" },
      { title: "Total Pago", dataKey: "valorPago" }], rows = [{
        valorAReceber: this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorPago: this.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }], {
        theme: "plain",
        margin: { horizontal: 7, top: 70 },
        bodyStyles: { valign: 'top' },
      });


    doc.save('acompanhamento-cobranças.pdf');
  }
}
