import { LancamentoAgendaService } from '../services/lancamento-agenda.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgendaResolveService {

  constructor(private lancamentoAgendaService: LancamentoAgendaService) { }

  resolve(): Observable<any> {
     return this.lancamentoAgendaService.findLancamentosPorMes(new Date()).pipe(
       take(1),
       mergeMap(modelo => {
        return of(modelo['object']);
       })
     );
  }
}
