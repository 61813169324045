import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RolesService } from '../services/roles.service';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveRolesService implements Resolve<any> {

  constructor(private roleService: RolesService) { }

  resolve(): Observable<any> {
    return this.roleService.findAll().pipe(
      take(1),
      mergeMap(roles => {
        return of(roles['object']);
      })
    );
  }
  
}
