import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cancel-contract',
  templateUrl: './cancel-contract.component.html',
  styleUrls: ['./cancel-contract.component.scss']
})
export class CancelContractComponent implements OnInit {
  titleHeader: string;
  repasse: FormGroup;
  cancel: FormGroup;
  contract: any;
  schedule: boolean;
  disabledButton:boolean;


  /**
  * Constructor
  *
  * @param {MatDialogRef<CancelContractComponent>} matDialogRef
  * @param _data
  */
  constructor(
    public matDialogRef: MatDialogRef<CancelContractComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.contract = this.data['data'];
    this.schedule = this.data['schedule'];

    this.titleHeader = !this.schedule ? 'Cancelamento de Contrato' : 'Reagendar Contrato';    

    this.repasse = this.formBuilder.group({
      dataFinalizacao: ['', Validators.required]
    });

    this.cancel = this.formBuilder.group({
      parecerCancelamento: ['', Validators.required]
    });


  }

  onNoClick(data?): void {
    this.disabledButton = true;
    if (data) {

      if (this.schedule) {
        this.contract.dataFinalizacao = this.repasse.value.date.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
        this.matDialogRef.close(data);
      } else {
        data.data[0].parecerCancelamento = this.cancel.get('parecerCancelamento').value
        let contract = data.data[0];

        this.matDialogRef.close(contract);
      }

    } else {
      this.matDialogRef.close();
    }


  }

  onClosed() {
    this.matDialogRef.close();
  }

}
