import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ParameterService } from "app/main/parameter/services/parameter.service";
import { MessageDialogComponent } from "app/main/shared/components/message-dialog/message-dialog.component";
import { Mask } from "@fagnerlima/ng-mask";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { TaxaService } from "app/main/taxa/services/taxa.service";

@Component({
    selector: 'app-taxa-form',
    templateUrl: './taxa-form.component.html',
    styleUrls: ['./taxa-form.component.scss']
})
export class TaxaFormComponent implements OnInit {

    idCancelamento;
    idGeracao;
    idMulta;
    idMultaDia;
    id_taxa_cancelamento = 0;
    id_taxa_geracao = 1;
    id_taxa_valor_multa = 2;
    id_taxa_valor_multa_dia = 3;
    valor_taxa_cancelamento = '';
    valor_taxa_geracao = '';
    valor_multa = '';
    valor_multa_dia = '';
    tipo_taxa_cancelamento = '';
    tipo_taxa_geracao = '';
    tipo_taxa_valor_multa = '';
    tipo_taxa_valor_multa_dia = '';
    taxaCancelamento;
    taxaGeracao;
    taxaValorMulta;
    taxaValorMultaDia;
    dialogMessages;
    mask = new Mask('00000000');

    constructor(private dialog: MatDialog,
        private dialogRef: MatDialogRef<TaxaFormComponent>,
        private _matDialog: MatDialog,
        private taxaService: TaxaService) { }

    public disableAccept = false;

    ngOnInit(): void {
        this.taxaService.findByIdTaxa(this.id_taxa_cancelamento).subscribe(taxaCancelamento => {
            if(taxaCancelamento.object) {
                this.valor_taxa_cancelamento = taxaCancelamento.object.valorTaxa
                this.tipo_taxa_cancelamento = taxaCancelamento.object.tipoValorTaxa
            }
        });

        this.taxaService.findByIdTaxa(this.id_taxa_geracao).subscribe(taxaGeracao => {
            if (taxaGeracao.object) {
                this.valor_taxa_geracao = taxaGeracao.object.valorTaxa
                this.tipo_taxa_geracao = taxaGeracao.object.tipoValorTaxa
            }
        });

        this.taxaService.findByIdTaxa(this.id_taxa_valor_multa).subscribe(taxaValorMulta => {
            if (taxaValorMulta.object) {
                this.valor_multa = taxaValorMulta.object.valorTaxa
                this.tipo_taxa_valor_multa = taxaValorMulta.object.tipoValorTaxa
            }
        });

        this.taxaService.findByIdTaxa(this.id_taxa_valor_multa_dia).subscribe(taxaValorMultaDia => {
            if (taxaValorMultaDia.object) {
                this.valor_multa_dia = taxaValorMultaDia.object.valorTaxa
                this.tipo_taxa_valor_multa_dia = taxaValorMultaDia.object.tipoValorTaxa
            }
        });
    }

    public openPopup(): void {
        this.dialogRef = this.dialog.open(TaxaFormComponent, {
            data: '',
        });
        setTimeout(() => this.dialogRef.close());
    }

    public onSave() {
        let taxas: any[] = [];

        let taxa_cancelamento = {
            id: this.idCancelamento,
            tipoTaxa: this.id_taxa_cancelamento,
            valorTaxa: this.valor_taxa_cancelamento,
            tipoValorTaxa: this.tipo_taxa_cancelamento
        }

        taxas.push(taxa_cancelamento)

        let taxa_geracao = {
            id: this.idGeracao,
            tipoTaxa: this.id_taxa_geracao,
            valorTaxa: this.valor_taxa_geracao,
            tipoValorTaxa: this.tipo_taxa_geracao
        }

        taxas.push(taxa_geracao)

        let taxa_multa = {
            id: this.idMulta,
            tipoTaxa: this.id_taxa_valor_multa,
            valorTaxa: this.valor_multa,
            tipoValorTaxa: this.tipo_taxa_valor_multa
        }

        taxas.push(taxa_multa)

        let taxa_multa_dia = {
            id: this.idMultaDia,
            tipoTaxa: this.id_taxa_valor_multa_dia,
            valorTaxa: this.valor_multa_dia,
            tipoValorTaxa: this.tipo_taxa_valor_multa_dia
        }

        taxas.push(taxa_multa_dia)

        this.taxaService.save(taxas).subscribe(rest => {
            if (rest.success) {
                this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `Registro atualizado com sucesso`
                }
                this.feedback(this.dialogMessages);
            } else {
                if (rest.message != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${rest.messages[0].text}`
                    }
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    }
                    this.feedback(this.dialogMessages);
                }
            }
        });
    }

    private feedback(message: string[]) {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        })
        setTimeout(() => dialogRef.close(), 8000);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
