import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { UtilsService } from 'app/shared/util/utils.service';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { ReturnApi } from '../../model/return-api/returnApi-model';

@Component({
  selector: 'app-auditoria-form',
  templateUrl: './auditoria-form.component.html',
  styleUrls: ['./auditoria-form.component.scss']
})
export class AuditoriaFormComponent implements OnInit {

  @ViewChild('password') password: ElementRef;

  loginForm: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  titulo: string;
  disabled = false;
  superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  idPessoa = JSON.parse(sessionStorage.getItem('idPessoa'));

  hasSuccessFeedback = false;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AuditoriaFormComponent>,
    public dialog: MatDialog,
    private utilsService: UtilsService,
    private cobrancaService: CobrancasService,
    @Inject(MAT_DIALOG_DATA) public _data: any) { 
      this.titulo = this._data.titulo;
      this.hasSuccessFeedback = this._data.hasSuccessFeedback || false;
    }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      username: null,
      idFuncionario: this.funcionario.idFuncionario,
      idPessoa: this.idPessoa,
      superUsuario: this.superUsuario
    });
  }

  onCancel(): void {
    this.dialog.closeAll();
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onSave(value: any): void {
    this.cobrancaService.loginAlteracaoFinanceiro(value).subscribe( (response: ReturnApi) => {
      let isSuccess = response.success;
      if (this.hasSuccessFeedback || !isSuccess) {
        isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      }
      if (isSuccess) {
        this.dialogRef.close(value);
      } else {
        this.disabledButton = false;
      }
    });
  }

}
