import { MessageDialogComponent, MessageDialogComponentType } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material';
import { TimeMensagem } from 'app/main/shared/model/enums/time-mensagem.enum';
import { Injectable } from '@angular/core';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  
  constructor(public _matDialog: MatDialog) {}

  static requestParams(filters: { [key: string]: any }): string {
    return filters ? Object.entries(filters).reduce((prev, [key, value]) => `${prev}&${key}=${value}`, '') : '';
  }

  feedback(message: string | { type: string; title: string, text: string }, timeMensagem: TimeMensagem, title: string = 'Info'): void {
    const data = typeof message === 'object' ? message : {
      title,
      text: message,
      type: 'info'
    };
    const dialogRef = this._matDialog.open(MessageDialogComponent, { data });
    if (timeMensagem) {
      setTimeout(() => dialogRef.close(), timeMensagem);
    }
  }

  feedbackApi(returnApi: ReturnApi, mensagemSucesso: string, timeMensagem: TimeMensagem, afterClosed?: () => void): boolean {
    if (returnApi.success) {
      this.feedbackSuccess(mensagemSucesso, timeMensagem, afterClosed);
      return true;
    } else if (returnApi.messages[0].text != null) {
      this.feedbackInfo(`${returnApi.messages[0].text}`, timeMensagem, afterClosed);
    } else {
      this.feedbackError('Houve um erro ao executar a operação!', timeMensagem);
    }

    return false;
  }

  feedbackSuccess(message: string, timeMensagem: TimeMensagem, afterClosed?: () => void): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: {
        type: 'success',
        title: 'Sucesso!',
        text: message
      }
    });  
    dialogRef.afterClosed().subscribe(afterClosed);
    setTimeout(() => dialogRef.close(), timeMensagem);
  }

  feedbackInfo(message: string, timeMensagem: TimeMensagem, afterClosed?: () => void): void {
    let mensagemErro = message;
    
    if (message.includes('br.com.sgf.exception.BusinessException:')) {
      mensagemErro = message.replace('br.com.sgf.exception.BusinessException:', '');
    }

    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: {
        type: 'info',
        title: 'Informação!',
        text: mensagemErro
      }
    });
    dialogRef.afterClosed().subscribe(afterClosed);
    setTimeout(() => dialogRef.close(), timeMensagem);

  }

  feedbackError(message: string, timeMensagem: TimeMensagem): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: {
        type: 'error',
        title: 'Error!',
        text: message
      }
    });  
    setTimeout(() => dialogRef.close(), timeMensagem);
  }

  confirmAction(options?: { title?: string, text?: string, type?: MessageDialogComponentType, onConfirm?: () => void, onCancel?: () => void }): void {
    this._matDialog.open(MessageDialogComponent, {
      disableClose: true,
      data: {
        type: options && options.type ? options.type : 'warning',
        title: options && options.title ? options.title : 'Confirmar?',
        text: options && options.text ? options.text : 'Deseja confirmar a operação?',
        showCancelButton: true,
        showConfirmButton: true,
        textCancelButton: 'Não',
        textConfirmButton: 'Sim'
      }
    }).afterClosed().subscribe(data => {
      if (data && options && options.onConfirm) {
        options.onConfirm();
      }
      if (!data && options && options.onCancel) {
        options.onCancel();
      }
    });
  }
}
