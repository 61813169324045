import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Employee } from '../../model/employee/employee.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ListUnididadeFederacao } from '../../model/uf/ufs.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { RoleModel } from '../../model/role/role.model';
import { BenefitsModel } from '../../model/benefits/benefits.model';
import { DiscountModel } from '../../model/discount/discount.module';
import { VehiclesModel } from '../../model/vehicles/vehicles.model';
import { UserProfile } from '../../model/user-profile/user-profile.model';
import { ImpostModel } from '../../model/impost/impost.model';
import { Estado } from '../../model/estado/estado.model';
import { GlobalService } from 'app/main/global/services/global.service';
import { Posto } from '../../model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { UtilsService } from 'app/shared/util/utils.service';

declare var google: any;

interface DialogData {
  employee: Employee;
  action: string;
  veiculos: Array<VehiclesModel>;
  cargos: Array<RoleModel>;
  descontos: Array<DiscountModel>;
  impostos: Array<ImpostModel>;
  beneficios: Array<BenefitsModel>;
  estados: Array<Estado>;
}

@Component({
  selector: 'app-employee-full',
  templateUrl: './employee-full.component.html',
  styleUrls: ['./employee-full.component.scss']
})
export class EmployeeFullComponent implements OnInit {
  titleHeader: string;

  dialogRef: any;
  snackbarRef: any;
  maska: string;
  selected: any;
  dialogMessages;
  disabledButton: boolean;
  // Validar cpf e cnpj 
  cpfCnpj = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/ ;


  step = 0;
  employee: FormGroup;

  currentEmployee: Employee;
  segmentList: any[] = [];
  bairrosList: any[] = [];
  estadosList: any[] = [];

  municipiosDescriptions: any[] = [];
  ufs = ListUnididadeFederacao;
  objectMunicipio: any;
  municipio: any;

  perfils: Array<UserProfile>;

  listBeneficioDescription = [];
  listImpostoDescription = [];
  listDescontoDescription = [];
  listVeiculosDescription = [];
  dataVencimentoCnh: any;
  postos: Array<Posto>;
  isCpf = true;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<EmployeeFullComponent>,
    private _matDialog: MatDialog,
    private postoService: PostsService,
    private employeeService: EmployeeService,
    private globalService: GlobalService,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
      
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
      });
    
      // tslint:disable-next-line:triple-equals
      this.titleHeader = this._data.action == 'new' ? 'Adicionar Funcionário' : 'Editar Funcionário';
      this.currentEmployee = this.loadFormControlDefaults();

      if (this._data.action === 'update' && this._data.employee.pessoa.type === 'pj') {
        this.isCpf = false;
      } else {
        this.isCpf = true;
      }
  }

  ngOnInit(): void {
    this.setFormGroup();
  }


  private loadFormControlDefaults(): Employee {
    let currentEmployee: Employee;
    switch (this._data.action) {
      case 'new': {
        currentEmployee = new Employee({});
        break;
      }
      case 'update': {
        currentEmployee = new Employee(this._data.employee);
        break;
      }
    }

    return currentEmployee;
  }

  setFormGroup(): void {
    let salario = null;
    let posto = null;

    if (this._data.employee.cargo) {
      salario = this._data.employee.cargo.salario;
    }

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      this._data.employee.listBeneficio.forEach(p => {
        this.listBeneficioDescription.push(p.descricao);
      });
      this._data.employee.listDesconto.forEach(p => {
        this.listDescontoDescription.push(p.descricao);
      });
      this._data.employee.listImposto.forEach(p => {
        this.listImpostoDescription.push(p.descricao);
      });
      this._data.employee.listVeiculo.forEach(v => {
        this.listVeiculosDescription.push(v.placa);
      });

      posto = this._data.employee.posto;
    }

    this.employee = this.formBuilder.group({
      id: this._data.employee ? this._data.employee.id : null,
      isCpf: this.isCpf,
      ativo: this._data.employee ? this._data.employee.ativo : true,
      cnh: [],
      vencimentoCnh: [],
      registroPix: this.buildRegistrosPix(),
      listVeiculo: [this._data.employee.listVeiculo ? this.listVeiculosDescription : ['']],
      salarioBase: [salario ? salario : null],
      pessoa: this.buildPessoa(),
      cargo: [this._data.employee.cargo ? this._data.employee.cargo.descricao : null, Validators.required],
      listBeneficio: [this._data.employee.listBeneficio ? this.listBeneficioDescription : ['']],
      listDesconto: [this._data.employee.listDesconto ? this.listDescontoDescription : ['']],
      listImposto: [this._data.employee.listImposto ? this.listImpostoDescription : ['']],
      posto: [ posto ? this._data.employee.posto.descricao : null],
      dadosFolhaPagamento: []
    });

  }

  buildRegistrosPix(): any{
    const registrosPix = this._data.employee.registroPix;  
    return this.formBuilder.group({
        id: new FormControl(registrosPix ? registrosPix.id : null),
        chavePix: new FormControl(registrosPix ? registrosPix.chavePix : null),
        tipoChavePix: new FormControl(registrosPix ? registrosPix.tipoChavePix : null)
      });
  }


  getIsCpfEvent(value): void {
    this.isCpf = value;
    this.setFormGroup();

    console.log(this.employee.get('pessoa'));
  }

  private buildPessoa(): any {
    const pessoa = this._data.employee.pessoa;
    return this.formBuilder.group({
      ativo: pessoa ? pessoa.ativo : true,
      id: pessoa ? pessoa.id : null,
      type: [],
      email: new FormControl(pessoa ? pessoa.email : null, Validators.compose([Validators.required, Validators.email])),
      // tslint:disable-next-line:triple-equals
      nomeFantasia: new FormControl(pessoa ? pessoa.nomeFantasia : null, this.isCpf == false ? Validators.required : null),
      // tslint:disable-next-line:triple-equals
      razaoSocial: new FormControl(pessoa ? pessoa.razaoSocial : null,  this.isCpf == false ? Validators.required : null),
      // tslint:disable-next-line:triple-equals
      nome: new FormControl(pessoa ? pessoa.nome : null, this.isCpf == true ? Validators.required : null ),
      // tslint:disable-next-line:triple-equals
      cpf: new FormControl(pessoa ? pessoa.cpf : null, this.isCpf == true ? Validators.required : null),
      // tslint:disable-next-line:triple-equals
      cnpj: new FormControl(pessoa ? pessoa.cnpj : null,  this.isCpf == false ? Validators.required : null),
      cnh: new FormControl(pessoa ? pessoa.cnh : null),
      dataVencimentoCnh: new FormControl(pessoa.dataVencimentoCnh ? new Date(pessoa.dataVencimentoCnh) : null),
      listEndereco: this.buildAddress(),
      listTelefone: this.buildPhone(),
      dataNascimento: new FormControl(pessoa.dataNascimento ? new Date(pessoa.dataNascimento) : null, Validators.required),
      sexo: new FormControl(pessoa ? pessoa.sexo : null, Validators.required)
    });
  }



  buildPhone(): FormArray {
    const phone = this._data.employee.pessoa.listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: new FormControl(v.ativo || true),
        numero: new FormControl(v.numero || null, Validators.required),
        id: new FormControl(v.id || null)
      });
    });
    return this.formBuilder.array(phone);
  }

  buildAddress(): FormArray {
    const address = this.currentEmployee.pessoa.listEndereco.map(a => {
      return this.formBuilder.group({
        id: new FormControl(a.id ? a.id : null),
        cep: new FormControl(a.cep ? a.cep : null, Validators.required),
        logradouro: new FormControl(a.logradouro ? a.logradouro : null, Validators.required),
        numero: new FormControl(a.numero ? a.numero : null, Validators.required),
        bairro: new FormControl(a.bairro ? a.bairro.descricao : null,  Validators.required ),
        municipio: new FormControl(a.municipio ? a.municipio.nomeMunicipio : null, Validators.required),
        complemento: new FormControl(a.complemento ? a.complemento : null),
        estado: new FormControl(a.estado ? a.estado.sigla : null, Validators.required),
        pontoReferencia: new FormControl(null),
        tipoEndereco: new FormControl(a.tipoEndereco ? a.tipoEndereco : null)
      });
    });

    return this.formBuilder.array(address);
  }

  get phoneForms(): FormArray {
    return this.employee.get('pessoa').get('listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });
    this.phoneForms.push(phone);
  }

  onAdd(object: any): void {
    this.disabledButton = true;
    const ben: any = [];
    const desc: any = [];
    const impost: any = [];
    const veiculos: any = [];
    let UF = '';
    let municipio: any;

    console.log(object.posto);

    this.postos.forEach(posto => {
      // tslint:disable-next-line:triple-equals
      if (posto.descricao == object.posto) {
        object.posto = posto;
      }      
    });

    object.pessoa.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });


    let cargoDescricao = '';
    if (typeof object.cargo === 'object') {
      cargoDescricao = object.cargo.descricao;
    } else {
      cargoDescricao = object.cargo
    }

    // tslint:disable-next-line:triple-equals
    object.cargo = this._data.cargos.filter(cargo => cargo.descricao == cargoDescricao)[0];  

    if (object.listBeneficio != null) {
      object.listBeneficio.forEach(element => {
        // tslint:disable-next-line:triple-equals
        ben.push(this._data.beneficios.filter(beneficio => beneficio.descricao == element)[0]);
      });

      object.listBeneficio = ben;
    }
    if (object.listDesconto != null) {
      object.listDesconto.forEach(element => {
        // tslint:disable-next-line:triple-equals
        desc.push(this._data.descontos.filter(desconto => desconto.descricao == element)[0]);
      });

      object.listDesconto = desc;
    }

    if (object.listImposto != null) {
      object.listImposto.forEach(element => {
        // tslint:disable-next-line:triple-equals
        impost.push(this._data.impostos.filter(imposto => imposto.descricao == element)[0]);
      });

      object.listImposto = impost;
    }


    if (object.listVeiculo != null) {
      object.listVeiculo.forEach(element => {
        // tslint:disable-next-line:triple-equals
        veiculos.push(this._data.veiculos.filter(veiculo => veiculo.placa == element)[0]);
      });

      object.listVeiculo = veiculos;
    }

    if (!object.pessoa.listContaBancaria) {
      object.pessoa.listContaBancaria = [];
    }

    object.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            // tslint:disable-next-line:no-shadowed-variable
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    // tslint:disable-next-line:triple-equals
    if (this.isCpf == true){
      object.pessoa.type = 'pf';
    }else{
      object.pessoa.type = 'pj';
    }

    delete object.isCpf;

    const employee = new Employee(object);
    setTimeout(() => {

      this.disabledButton = true;
      this.employeeService.add(employee).subscribe(seg => {
        if (seg.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${seg.messages[0].text}`
          };

          this.utilsService.feedback(this.dialogMessages, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close(seg.object);
        } else {
          if (seg.messages != null){
            let message = seg.messages[0].text;
            if (message.includes('messageTemplate')) {
              message = message.substring(
                message.lastIndexOf('=') + 2,
                // tslint:disable-next-line:quotemark
                message.lastIndexOf("''")
              );
            } else {
              message = message.includes(' - ') ? `${message.split(' - ', 2)[1]}` : message;
            }
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: message
              };
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }

          this.utilsService.feedback(this.dialogMessages, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        }
      });
    }, 2000);

  }

  onUpdate(object: any): void {
    this.disabledButton = true;
    const ben: any = [];
    const desc: any = [];
    const impost: any = [];
    const veiculos: any = [];
    let UF = '';
    let municipio: any;


    this.postos.forEach(posto => {
      // tslint:disable-next-line:triple-equals
      if (posto.descricao == object.posto) {
        object.posto = posto;
      }      
    });

    
    object.pessoa.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });

    // tslint:disable-next-line:triple-equals

    let cargoDescricao = '';
    if (typeof object.cargo === 'object') {
      cargoDescricao = object.cargo.descricao;
    } else {
      cargoDescricao = object.cargo;
    }

    object.cargo = this._data.cargos.filter(cargo => cargo.descricao == cargoDescricao)[0];

    if (object.cargo == null || object.cargo == undefined) {
      return;
    }

    if (object.listBeneficio != null) {
      object.listBeneficio.forEach(element => {
        // tslint:disable-next-line:triple-equals
        ben.push(this._data.beneficios.filter(beneficio => beneficio.descricao == element)[0]);
      });

      object.listBeneficio = ben;
    }
    if (object.listDesconto != null) {
      object.listDesconto.forEach(element => {
        // tslint:disable-next-line:triple-equals
        desc.push(this._data.descontos.filter(desconto => desconto.descricao == element)[0]);
      });

      object.listDesconto = desc;
    }

    if (object.listImposto != null) {
      object.listImposto.forEach(element => {
        // tslint:disable-next-line:triple-equals
        impost.push(this._data.impostos.filter(imposto => imposto.descricao == element)[0]);
      });

      object.listImposto = impost;
    }
    
    if (!object.pessoa.listContaBancaria) {
      object.pessoa.listContaBancaria = [];
    }

    if (object.listVeiculo != null) {
      object.listVeiculo.forEach(element => {
        // tslint:disable-next-line:triple-equals
        veiculos.push(this._data.veiculos.filter(veiculo => veiculo.placa == element)[0]);
      });

      object.listVeiculo = veiculos;
    }

    // tslint:disable-next-line:triple-equals
    if (this.isCpf == true){
      object.pessoa.type = 'pf';
    }else{
      object.pessoa.type = 'pj';
    }

    delete object.isCpf;

    object.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    object.pessoa.listEndereco.forEach(element => {

      this.googleMaps(element);

      const employee = new Employee(object);

      setTimeout(() => {
        this.employeeService.updateEmployee(employee).subscribe(seg => {
          if (seg.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${seg.messages[0].text}`
            };

            this.utilsService.feedback(this.dialogMessages, TimeMensagem.TIME_MENSAGEM);
            this.matDialogRef.close(seg.object);

          } else {
            if (seg.messages != null){
              let message = seg.messages[0].text;
              if (message.includes('messageTemplate')) {
                message = message.substring(
                  message.lastIndexOf('=') + 2,
                  // tslint:disable-next-line:quotemark
                  message.lastIndexOf("''")
                );
              } else {
                message = message.includes(' - ') ? `${message.split(' - ', 2)[1]}` : message;
              }
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: message
                };
            }else{
             this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }

            this.utilsService.feedback(this.dialogMessages, TimeMensagem.TIME_MENSAGEM);
            this.disabledButton = false;
          }
        });
      }, 2000);
    });
  }



  googleMaps(customer): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${customer.logradouro}, ${customer.numero}, ${customer.municipio.nomeMunicipio}, ${customer.estado}, ${customer.cep}`;

    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        customer.logradouroCompleto = results[0].formatted_address;
        customer.latitude = results[0].geometry.location.lat();
        customer.longitude = results[0].geometry.location.lng();
      }
    });

  }

  onClosed(): void {
    this._matDialog.closeAll();
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 2000);
  }

}
