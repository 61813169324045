import { Segment } from '../segment/segment.model';

export class InformationsModel{

    cpf?: string;
    isCpf?: boolean;
    cnpj?: string;
    email?: string;
    nome?: string;
    rg?: string;
    dataNascimento: Date | any;
    atividadeComercial: Segment;
    pontoReferencia: string;

    nomeFantasia: string;
    razaoSocial: string;

    constructor(obj?: any) {

        this.cpf                = obj.cpf                   || null;
        this.isCpf              = obj.isCpf                 || true;
        this.rg                 = obj.rg                    || null;
        this.cnpj               = obj.cnpj                  || null;
        this.email              = obj.email                 || null;
        this.nome               = obj.nome                  || null;
        this.nomeFantasia       = obj.nomeFantasia          || null;
        this.razaoSocial        = obj.razaoSocial           || null;
        this.dataNascimento     = obj.dataNascimento        || null;
        this.atividadeComercial = obj.atividadeComercial    || null;
        this.pontoReferencia    = obj.pontoReferencia       || null;
    }
}
