import { SituacaoCaptadoType } from './../situacao-captado/situacao-captado.enum';
import { Address } from '../address/address.model';
import { Telephone } from '../telephone/telephone.model';
import { Person } from '../person/person.model';
import { InformationsModel } from '../informations/informations-model';
import { Segment } from '../segment/segment.model';
import { Employee } from '../employee/employee.model';
import { ReferenciaPessoal } from '../referencia-pessoal/referencia-pessoal.model';
import { Pessoa } from 'app/main/infinitylabs/core/pessoa/pessoa';
import { PersonModel } from '../person/persons.model';
import { IndicadorModel } from '../indicador/indicador-model';
import { Customer } from '../customer/customer.model';
import { Cliente } from '../cliente/cliente.model';
import { Posto } from '../posto/posto.model';

export class CustomersModel extends InformationsModel {

    id: number;

    cliente: any;
    dataCadastroEmpresa: Date | any;
    dataHoraCadastro: Date | any;
    situacaoCaptado: SituacaoCaptadoType | any;
    inscricao: string;
    indicador: IndicadorModel;
    indicadorFuncionario: Employee;
    indicadorCliente: Cliente;
    atividadeComercial: Segment;
    turnoAtendimento: string;
    listEndereco: Address[];
    listTelefone: Telephone[];
    pessoa: PersonModel;
    referenciaPessoal: ReferenciaPessoal[];
    isCpf: boolean;
    possuiIndicador: boolean;
    tipoIndicador?: any;
    valorAprovado: number;
    situacaoCliente: string;
    posto: Posto;
    turno: string;
    instagram: string;
    instagramProfissional: string;
    nomeDoComercio: string;
    inicioFuncionamento: string;
    fimFuncionamento: string;
    tempoComercial: string;
    canalAquisicao: string;
    nivelInteresse: string;
    detalhesInteracao: string;
    dataRegistro: Date | any;

    constructor(obj: any) {
        super(obj);

        this.id                     = obj.id                    || null;
        this.cliente                = obj.cliente               || null;
        this.dataCadastroEmpresa    = obj.dataCadastroEmpresa   || null;
        this.dataHoraCadastro       = obj.dataHoraCadastro      || null;
        this.situacaoCaptado        = obj.situacaoCaptado       || null;
        this.inscricao              = obj.inscricao             || null;
        this.listEndereco           = obj.listEndereco          || [new Address({})];
        this.listTelefone           = obj.listTelefone          || [new Telephone({})];
        this.indicador              = obj.indicador             || new IndicadorModel({});
        this.indicadorFuncionario   = obj.indicadorFuncionario  || new Employee({});
        this.indicadorCliente       = obj.indicadorCliente      || new Cliente({});
        this.atividadeComercial     = obj.atividadeComercial    || new Segment({});
        this.turnoAtendimento       = obj.turnoAtendimento      || null;
        this.referenciaPessoal      = obj.referenciaPessoal     || [new ReferenciaPessoal({})];
        this.isCpf                  = obj.isCpf                 || false;
        this.possuiIndicador        = obj.possuiIndicador       || false;
        this.tipoIndicador          = obj.tipoIndicador         || null;
        this.situacaoCliente        = obj.situacaoCliente       || null;
        this.pessoa                 = obj.pessoa                || new PersonModel({});
        this.posto                  = obj.posto                 || new Posto({});
        this.turno                  = obj.turno                 || null;
        this.instagram              = obj.instagram             || null;
        this.instagramProfissional  = obj.instagramProfissional || null;
        this.nomeDoComercio         = obj.nomeDoComercio        || null;
        this.inicioFuncionamento    = obj.inicioFuncionamento   || null;
        this.fimFuncionamento       = obj.fimFuncionamento      || null;
        this.tempoComercial         = obj.tempoComercial        || null;
        this.canalAquisicao         = obj.canalAquisicao        || null;
        this.nivelInteresse         = obj.nivelInteresse        || null;
        this.detalhesInteracao      = obj.detalhesInteracao     || null;
        this.dataRegistro           = obj.dataRegistro          || null;

    }

}
