import { Component, OnInit, Inject, Input } from '@angular/core';
import { ListUnididadeFederacao } from '../../../../shared/model/uf/ufs.model';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Customer } from '../../../../shared/model/customer/customer.model';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { CaptacaoService } from '../../../../customers/components/captacao/services/captacao.service';
import { DeleteConfirmationComponent } from '../../../dialogs/delete-confirmation/delete-confirmation.component';
import { Estado } from 'app/main/shared/model/estado/estado.model';

@Component({
  selector: 'app-visitados-endereco-form',
  templateUrl: './visitados-endereco-form.component.html',
  styleUrls: ['./visitados-endereco-form.component.scss'],
  animations: fuseAnimations
})
export class VisitadosEnderecoFormComponent implements OnInit {

  @Input() customerId;
  @Input() customer: FormGroup;

  step = 0;
  totalCustomer: number;
  address;
  addressComercial: FormGroup;
  showPanelId: Boolean;
  currentCustomer: Customer;

  constructor(
    private formBuilder: FormBuilder,
    private captacaoService: CaptacaoService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    public snackBar: MatSnackBar) {
    }

  ngOnInit() {
    if (this.customer.value.listEndereco.length == 0) {
      this.add();
    }
  }

  get addressForms(): FormArray {
    return this.customer.get('listEndereco') as FormArray;
  }

  add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: [],
      cep: new FormControl(null,Validators.required),
      logradouro: new FormControl(null,Validators.required),
      numero: new FormControl(null,Validators.required),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null, Validators.required),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null,Validators.required),
      estado: new FormControl(null,Validators.required),
      tipoEndereco: new FormControl(null)
    });
    this.addressForms.push(address);
  };

  delete(index: number): void {
    this.addressForms.removeAt(index);

  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  private openConfirmModal(index: number) {
    this._matDialog.open(DeleteConfirmationComponent, {
      panelClass: 'captacao-form-dialog',
      hasBackdrop: false
    }).afterClosed().subscribe(data => {
      if (data) {
        let msg = 'Endereço Excluido Com Sucesso!';
        this.feedbackSnack(msg);
        this.addressForms.removeAt(index);
      } else {
        let msg = 'Erro ao Excluir Endereço!';
        this.feedbackSnack(msg);
      }
    })
  }

  prevStep() {
    this.step--;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

  private feedbackSnack(message: string) {
    this.snackBar.open(message, '', {
      verticalPosition: 'bottom',
      duration: 2700,
    });
  }

}
