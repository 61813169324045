import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class SharedService {
  public static instance: SharedService = null;
  profile: string;
  showTemplate = new EventEmitter<boolean>();
  permissoes: string[];

  constructor() {
    return SharedService.instance = SharedService.instance || this;
  }

  public static getInstance(): any {
    if (this.instance === null) {
      this.instance = new SharedService();
    }
    return this.instance;
  }
}
