import { Component, OnInit, Inject } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-gerador-cobranca',
  templateUrl: './gerador-cobranca.component.html',
  styleUrls: ['./gerador-cobranca.component.scss']
})
export class GeradorCobrancaComponent implements OnInit {
  form: FormGroup;
  employee: Array<Employee>
  title: string;
  disabledButton: boolean;
  dialogMessages;

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  constructor(private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GeradorCobrancaComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private transferenciaService: TransferenciaService) {

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });


    })
  }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      funcionario: [null, Validators.required]
    })
  }

  private _onDestroy = new Subject<void>();

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }


  onCancel(): void {
    this.dialog.closeAll()
  }

  onSave(value) {
    let funcionario = value.funcionario.pessoa.nomePessoa
    this.disabledButton = true;
    this.dialogMessages = {
      type: 'warning',
      title: 'Aviso!',
      text: "Deseja gerar cobrança para o funcionário: " + funcionario.toUpperCase(),
      showCancelButton: true,
      showConfirmButton: true,
      textCancelButton: 'Não',
      textConfirmButton: 'Sim'
    }
    this.dialog.open(MessageDialogComponent, {
      disableClose: true,
      data: this.dialogMessages
    }).afterClosed().subscribe(data => {
      if (data) {

        this.transferenciaService.gerarCobrancasParaFuncionario(value.funcionario.id).subscribe(res => {
          if (res.success) {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${res.messages[0].text}`
            }
            this.feedback(this.dialogMessages);
            this.dialogRef.close(res);
          } else {
            if (res.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${res.messages[0].text}`
              }
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              }
            }
            this.feedback(this.dialogMessages);
            this.dialogRef.close();
          }

        },
          (onerror) => {
            console.log('ERROR BANCO', onerror);
          }
        )
      } else {
        this.dialogRef.close();
      }
    })
  }
  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }
  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }
}
