import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormBuilder, NgForm} from '@angular/forms';
import {UtilsService} from '../../../../shared/util/utils.service';
import {TimeMensagem} from '../../model/enums/time-mensagem.enum';
import {TransferenciaService} from '../../../financial/services/transferencia/transferencia.service';


class Lancamento {
  valor: number;
  descricao: string;
  nParcelas: number;
  funcionarioId: number;
}


@Component({
  selector: 'app-vale-form',
  templateUrl: './vale-form.component.html',
  styleUrls: ['./vale-form.component.scss']
})
export class ValeFormComponent implements OnInit {

  lancamento = new Lancamento();


  constructor(
      private formBuilder: FormBuilder,
      private matDialogRef: MatDialogRef<ValeFormComponent>,
      // tslint:disable-next-line:no-shadowed-variable
      @Inject(MAT_DIALOG_DATA) public data: any,
      private transferenciaService: TransferenciaService,
      private utilService: UtilsService
  ) { }


  ngOnInit(): void {
  }

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  lancamentoDeVale(): any {

    this.lancamento.funcionarioId = this.data.funcionarioId;

    this.transferenciaService.lancarVale(this.lancamento).subscribe(obj => {
      if (obj.success) {
        this.utilService.feedbackSuccess('Despesa Lançada Com Sucesso!', TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close();
      } else {
        if (obj.messages[0].text != null) {
          this.utilService.feedbackInfo(obj.messages[0].text, TimeMensagem.TIME_MENSAGEM);
        } else {
          this.utilService.feedbackError('Erro, Contate o suporte', TimeMensagem.TIME_MENSAGEM);
        }
      }
    });
  }


}
