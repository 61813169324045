import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-bank-reference',
  templateUrl: './bank-reference.component.html',
  styleUrls: ['./bank-reference.component.scss'],
  animations: [fuseAnimations]
})
export class BankReferenceComponent implements OnInit {

  telephones: FormArray;
  @Input() visit: FormGroup;
  maskCredito = new Mask('#.##0,00', { reverse: true })
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() { }

  get phoneForms(): FormArray {
    return this.visit.get('listReferencia').get('referenciaBancaria').get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });
    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  onAdd(){}

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`; 
  }

}
