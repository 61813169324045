import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Caixa } from '../../model/caixa/caixa.model';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material';
import { ConfirmarRecebimentoComponent } from '../../dialogs/confirmar-recebimento/confirmar-recebimento.component';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { ReceberParcelaFormComponent } from '../receber-parcela-form/receber-parcela-form.component';
import { Page } from '../../model/page/page.model';
import { ActivatedRoute } from '@angular/router';
import { MudarResponsavelFormComponent } from '../mudar-responsavel-form/mudar-responsavel-form.component';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { ConfirmarComponent } from '../../dialogs/confirmar/confirmar.component';
import { QuitarFaltaComponent } from '../quitar-falta/quitar-falta.component';

@Component({
  selector: 'app-transferencia-list',
  templateUrl: './transferencia-list.component.html',
  styleUrls: ['./transferencia-list.component.scss'],
  animations: fuseAnimations
})
export class TransferenciaListComponent implements OnInit, OnChanges {
  timout: any;
  selected: Array<any>;
  @Input() rows: Caixa[];
  @Output() overEvent = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelectEvent = new EventEmitter<any>();
  @Output() pageNumber = new EventEmitter<any>();
  @Output() loadingEvent = new EventEmitter<any>();
  @Output() alterarTipoRepasseEvent = new EventEmitter<any>();

  @Input() origem: boolean;
  @Input() page: Page;
  @Input() selectedClear;
  @Input() typeCaixa: string;
  @Input() caixaId: string;
  @Input() tipoTransf: string;

  selectedIds: Array<number>;
  dialogMessages;
  type: string;
  titulo: string;
  permissoes = [];


  constructor(private dialog: MatDialog,
    private transferenciaService: TransferenciaService,
    private _matDialog: MatDialog,
    private permissionsService: NgxPermissionsService,
    private contractService: ContractsService,
    private utilsService: UtilsService) {
    this.selected = new Array<Caixa>();
    this.selectedIds = new Array<number>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit(): void {
   }

  ngOnChanges(): void {
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }

  onAlterarTipoRepasse(value: any): void {
    this.alterarTipoRepasseEvent.emit(value);
  }

  onPage(event): void {
    this.loadingEvent.emit(true);
    clearTimeout(this.timout);
    this.timout = setTimeout(() => { }, 100);
    // tslint:disable-next-line:triple-equals
    if (this.origem == true) {
      this.transferenciaService.pageByCaixaDestinoAndOrigem('origem=eq:' + this.caixaId, 10, event.offset + 1).subscribe(data => {
        this.rows = data.object.content;
        this.page.pageNumber = event.offset;
        this.loadingEvent.emit(false);
      });
    } else {
      this.transferenciaService.pageByCaixaDestinoAndOrigem('destino=eq:' + this.caixaId, 10, event.offset + 1).subscribe(data => {
        this.rows = data.object.content;
        this.page.pageNumber = event.offset;
        this.loadingEvent.emit(false);
      });
    }
  }


  onSelect({ selected }): void {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

    this.selectedIds.splice(0, this.selectedIds.length);
    selected.forEach(element => {
      this.selectedIds.push(element.id);
    });

    this.emitSelected(this.selectedIds);
  }

  emitSelected(ids: number[]): void {
    this.onSelectEvent.emit(ids);
  }


  onCancel(value: any): void {
    this.dialog.open(ConfirmarRecebimentoComponent, {
      data: {
        action: this.origem,
        type: 'cancelar',
        confirmation: value,
      }
    }).afterClosed().subscribe(res => {
      if (res) {
          // tslint:disable-next-line:triple-equals
          if (value.tipo == 'TCODEF') {
            this.cancelarDespesa(res);
          } else {
            this.cancelar(res);
          }
      }
    });

  }

  private cancelar(res: any): void {
    this.transferenciaService.cancel(res).subscribe(
      (data: ReturnApi) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Registro cancelado com sucesso`
          };
          this.feedback(this.dialogMessages, data);
          const resp = {
            origem: this.origem,
            pageNumber: this.page.pageNumber + 1
          };
          this.overEvent.emit(resp);

        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages, data);
        }
      }),
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR AO FECHAR CAIXA', onerror);
      };
  }

  private cancelarDespesa(res: any): void {
    this.transferenciaService.cancelarDespesa(res).subscribe(
      (data: ReturnApi) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Registro cancelado com sucesso`
          };
          this.feedback(this.dialogMessages, data);
          const resp = {
            origem: this.origem,
            pageNumber: this.page.pageNumber + 1
          };
          this.overEvent.emit(resp);

        } else {
          if (data.messages[0].text != null) {
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages, data);
        }
      }),
      // tslint:disable-next-line:no-unused-expression
      (onerror) => {
        console.log('ERROR AO FECHAR CAIXA', onerror);
      };
  }


  onConfirm(value: any, situacao: string): void {
    this.dialog.open(ConfirmarRecebimentoComponent, {
      data: {
        confirmation: value,
        action: this.origem,
        situacao: situacao,
        type: 'confirmar',

      }
    }).afterClosed().subscribe(data => {
      const resp = {
        origem: this.origem,
        pageNumber: this.page.pageNumber + 1
      };
      this.overEvent.emit(resp);
    });
  }

  receberParcela(value): void {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: value,
        caixa: true,
        action: 'save',
        contrato: false
      }
    }).afterClosed().subscribe(data => {
      const resp = {
        origem: this.origem,
        pageNumber: this.page.pageNumber + 1
      };
      this.overEvent.emit(resp);
    });
  }


  transferirComplemento(value: any, situacao: string): void {
    this.dialog.open(ConfirmarRecebimentoComponent, {
      data: {
        confirmation: value,
        action: this.origem,
        situacao: situacao,
        type: 'confirmar',
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        const resp = {
          origem: this.origem,
          pageNumber: this.page.pageNumber + 1
        };
        this.overEvent.emit(resp);
      }
    });
  }

  confirmarTransferenciaEAtualizarSituacao(value: any, situacao: string): void {
    this.dialog.open(ConfirmarRecebimentoComponent, {
      data: {
        confirmation: value,
        action: this.origem,
        situacao: situacao,
        type: 'confirmar',

      }
    }).afterClosed().subscribe(data => {
      if (data) {
        const resp = {
          origem: this.origem,
          pageNumber: this.page.pageNumber + 1
        };
        this.overEvent.emit(resp);
      }
    });
  }

  quitarFalta(value: any): void {
    this.dialog.open(QuitarFaltaComponent, {
      data: {
        confirmation: value,
        action: this.origem,
        type: 'confirmar',

      }
    }).afterClosed().subscribe(data => {
      if (data) {
        const resp = {
          origem: this.origem,
          pageNumber: this.page.pageNumber + 1
        };
        this.overEvent.emit(resp);
      }
    });
  }

  alterarParcela(value): void {
    this._matDialog.open(ReceberParcelaFormComponent, {
      data: {
        cobranca: value,
        caixa: true,
        action: 'update'
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        const resp = {
          origem: this.origem,
          pageNumber: this.page.pageNumber + 1
        };
        this.overEvent.emit(resp);
      }
    });
  }

  alterarResponsavelRepasse(value): void {
    this.dialog.open(MudarResponsavelFormComponent, {
      data: {
        action: 'repasse',
        cobranca: value
      }
    }).afterClosed().subscribe(
      data => {
        if (data) {
          value.contrato.responsavelRepasse = data.funcionario;
          const contrato = value.contrato;
          this.contractService.alterarResponsavelRepasse(contrato).subscribe((rest) => {
            if (rest.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `${rest.messages[0].text}`
              };
              this.feedback(this.dialogMessages, rest);
            } else {
              if (rest.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${rest.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages, rest);
            }

          });
        }
      });
  }


  confirmarDespesa(transferencia: any): void {

    this.dialog.open(ConfirmarComponent).afterClosed().subscribe((result) => {
      
      if (result) {
        this.transferenciaService.confirmarDespesaAdministrativa(transferencia.id).subscribe((response) => {
          const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
        });
      }

      this.overEvent.emit();
    });
    
  }


  getType(value): void {
    this.type = value.type;
  }

  private feedback(messages: string[], data: any): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: messages
    });
    setTimeout(() => dialogRef.close(data.object), 8000);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const resp = {
          origem: this.origem,
          pageNumber: this.page.pageNumber + 1
        };
        this.overEvent.emit(resp);
      }
    });

  }

  getOrigem(caixa): any {

    // tslint:disable-next-line:triple-equals
    if (caixa == null || caixa == undefined) {
      return '-----';
    }

    let rota = '';

    if ( caixa.funcionario ) {
      rota = caixa.funcionario.rota ? caixa.funcionario.rota.descricao : '';
    }

    switch (caixa.type) {

      case 'CaixaControle':
        this.titulo = 'Caixa de Controle';
        break;
      case 'CaixaDespesa':
        this.titulo = 'Caixa de Despesa';
        break;
      case 'CaixaCobranca':
        this.titulo = 'Caixa de Cobrança';
        break;
      case 'CaixaFuncionario':
        this.titulo = `Caixa Funcionario - ${rota} - ${caixa.funcionario.pessoa.nomePessoa}`;
        break;
      case 'CaixaDespesaFuncionario':
        this.titulo = `Caixa Despesa Funcionario - ${rota} - ${caixa.funcionario.pessoa.nomePessoa}`;
        break;
      case 'CaixaDividaFuncionario':
        this.titulo = `Caixa Divida Funcionario - ${rota} - ${caixa.funcionario.pessoa.nomePessoa}`;
        break;
      case 'CaixaGeral':
        this.titulo = 'Caixa Geral';
        break;
      case 'CaixaRepasse':
        this.titulo = 'Caixa de Repasse';
        break;
      case 'CaixaProvisao':
        this.titulo = 'Caixa de Provisão';
        break;
      case 'CaixaSangria':
          this.titulo = 'Caixa de Sangria';
          break;
    }

    return this.titulo ? this.titulo : '--';

  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
