import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { RoleModel } from '../../model/role/role.model';
import { takeUntil } from 'rxjs/operators';
import { AgenciaService } from 'app/main/financial/components/agencia/services/agencia-service.service';
import { BancoService } from 'app/main/financial/components/banco/services/banco-service.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-agencia-form',
  templateUrl: './agencia-form.component.html',
  styleUrls: ['./agencia-form.component.scss']
})
export class AgenciaFormComponent implements OnInit {

  agencia: FormGroup;
  dialogMessages;
  titleHeader: string;
  bancos: any[];
  disabledButton: boolean;
  public bancoFilterControl: FormControl = new FormControl();
  public filteredBanco: ReplaySubject<RoleModel[]> = new ReplaySubject<RoleModel[]>(1);
  private _onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
    private bancoService: BancoService,
    private agenciaService: AgenciaService,
    private matDialogRef: MatDialogRef<AgenciaFormComponent>,
    public dialog: MatDialog,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) private _data: any) {

    // tslint:disable-next-line:triple-equals
    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Agência' : 'Edição de Agência';

    this.bancoService.findAll().subscribe(data => {
      this.bancos = data.object.content;
      this.filteredBanco.next(this.bancos.slice());
      this.bancoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBancos();
        });
    });
  }
  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.agencia = this.formBuilder.group({
      id: [this._data.agencia ? this._data.agencia.id : null],
      identificador: [this._data.agencia ? this._data.agencia.identificador : null, Validators.required],
      banco: [this._data.agencia ? this._data.agencia.banco.descricao : null, Validators.required]
    });
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  create(agencia): void {
    this.disabledButton = true;
    // tslint:disable-next-line:triple-equals
    agencia.banco = this.bancos.filter(banco => banco.descricao == agencia.banco)[0];

    this.agenciaService.add(agencia).subscribe(data => {
      if (data.success) {
        
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);

      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`Não foi possível criar agência`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }  
      }
    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar agência`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR BANCO', onerror);
      }
    );
  }

  update(agencia): void {
    this.disabledButton = true;
    // tslint:disable-next-line:triple-equals
    agencia.banco = this.bancos.filter(banco => banco.descricao == agencia.banco)[0];

    this.agenciaService.add(agencia).subscribe(data => {
      if (data.success) {
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }
      }
    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar agência`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR BANCO', onerror);
      }
    );
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private filterBancos(): any {
    if (!this.bancos) {
      return;
    }

    let search = this.bancoFilterControl.value;
    if (!search) {
      this.filteredBanco.next(this.bancos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBanco.next(
      this.bancos.filter(banco => banco.descricao.toLowerCase().indexOf(search) > -1)
    );
  }
}
