import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Posto } from '../../model/posto/posto.model';
import { ReplaySubject, Subject } from 'rxjs';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-posto-form',
  templateUrl: './select-posto-form.component.html',
  styleUrls: ['./select-posto-form.component.scss']
})

export class SelectPostoFormComponent implements OnInit {

  postos: Posto[];
  form: FormGroup;
  title: string;

  public PostoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  constructor(
    private postoService: PostsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SelectPostoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this.title = 'Selecionar Posto';
    const usuario = sessionStorage.getItem('usuario');
    if (usuario === 'jbl') {
      this.postoService.listarPostos().subscribe(data => {
      this.postos = data.object;
      this.filteredPosto.next(this.postos.slice());
      this.PostoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPosto();
        });
      });
    } else {
        this.postoService.listarPostosSemDD().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.PostoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
      });
    
    }

  }

  private _onDestroy = new Subject<void>();
  
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      posto: [null]
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSave(value): void {
    this.dialogRef.close(value);
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }

    let search = this.PostoFilterControl.value;
    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(m => m.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
