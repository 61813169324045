import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PostsService } from '../services/posts.service';
@Injectable({
  providedIn: 'root'
})

export class ResolvePostsService implements Resolve<any> {

  constructor(private service: PostsService) { }

  public loading: boolean = false;

  resolve(): Observable<any> {
    this.loading = true;
    return this.service.pageByPosto().pipe( 
      take(1),
      mergeMap(teste => {
        this.loading = false;
        return of(teste['object']);
      })
    );
  }
}
