import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MaquinaCartao } from '../../model/maquina-cartao/maquina-cartao.model';
import { MaquinaCartaoService } from 'app/main/financial/components/maquina-cartao/services/maquina-cartao.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-maquina',
  templateUrl: './select-maquina.component.html',
  styleUrls: ['./select-maquina.component.scss']
})
export class SelectMaquinaComponent implements OnInit {

  maquinas: MaquinaCartao[];
  form: FormGroup;
  title: string;

  public MaquinaFilterControl: FormControl = new FormControl();
  public filteredMaquina: ReplaySubject<MaquinaCartao[]> = new ReplaySubject<MaquinaCartao[]>(1);

  constructor(private maquinaService: MaquinaCartaoService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SelectMaquinaComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    this.title = "Vincular Máquina";

    this.maquinaService.findDesvinculadas(_data.contrato.visita.antecipacao).subscribe(data => {
      this.maquinas = data.object;
      this.filteredMaquina.next(this.maquinas.slice());
      this.MaquinaFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterMaquinas()
        });
    })
  }


  private _onDestroy = new Subject<void>();

  ngOnInit() {
    this.form = this.formBuilder.group({
      maquina: [null]
    })
  }

  onCancel() {
    this.dialogRef.close(false)
  }

  onSave(value) {
    this.dialogRef.close(value)
  }

  private filterMaquinas() {
    if (!this.maquinas) {
      return;
    }

    let search = this.MaquinaFilterControl.value;
    if (!search) {
      this.filteredMaquina.next(this.maquinas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMaquina.next(
      this.maquinas.filter(m => m.descricao.toLowerCase().indexOf(search) > -1)
    );
  }


}
