import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ListUnididadeFederacao } from '../../../model/uf/ufs.model';
import { MatSnackBar, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { Posto } from '../../../model/posto/posto.model';
import { PostsService } from '../../../../global/components/posts/services/posts.service';

@Component({
  selector: 'app-posto-form-endereco',
  templateUrl: './posto-form-endereco.component.html',
  styleUrls: ['./posto-form-endereco.component.scss'],
  animations: [ fuseAnimations ]
})
export class PostoFormEnderecoComponent implements OnInit {

  @Input() posto: FormGroup;

  step = 0;
  totalCustomer: number;
  address;
  showPanelId: Boolean;
  ufs = ListUnididadeFederacao;
  currentPosto: Posto;


  constructor(
    private formBuilder: FormBuilder,
    private postoService: PostsService,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog,
    public snackBar: MatSnackBar) {
      
    }

  ngOnInit(): void {
    if (this.posto.value.endereco == null) {
      this.add();
    }

    this.address = this.posto.get('endereco') as FormGroup;
  }

  get addressForms(): FormGroup {
    return this.posto.get('endereco') as FormGroup;
  }

 add(required?: boolean): void {
    const address = this.formBuilder.group({
      id: new FormControl(null),
      cep: new FormControl(null),
      logradouro: new FormControl(null),
      numero: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      bairro: new FormControl(null),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null),
      estado: new FormControl(null),
      tipoEndereco: new FormControl(null)
    });
    this.addressForms.value(address);
  }

  setStep(index: number): any {
    this.step = index;
  }

  nextStep(): any {
    this.step++;
  }

  prevStep(): any {
    this.step--;
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }
}
