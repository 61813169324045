/* tslint:disable:typedef */

import {
  Reducer,
  combineReducers
} from 'redux';

import { 
  CustomersState,
  CustomersReducer
} from '../model/customer/customer.reducer';

export * from '../model/customer/customer.reducer';
   
export interface CaptacaoState {
  customers: CustomersState;
}
   
const rootReducer: Reducer<CaptacaoState> = combineReducers<CaptacaoState>({
  customers: CustomersReducer,
});
   
export default rootReducer;