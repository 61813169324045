import { CobrancasService } from './../../../../financial/components/cobrancas/services/cobrancas.services';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnChanges } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Page } from 'app/main/shared/model/page/page.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'app-to-cancel-list',
  templateUrl: './to-cancel-list.component.html',
  styleUrls: ['./to-cancel-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ToCancelListComponent implements OnInit, OnChanges  {

  @Input() rows: any[];
  @Input() page: Page;
  @Input() filter: any;
  @Input() pageNum: any
  @Input() dataPeriodo: any;
  @Input() search: any;
  @Output() pageNumber : EventEmitter<any> = new EventEmitter();
  @Output() cancelarEvent : EventEmitter<any> = new EventEmitter();

  @Output() getTotalContractValue = new EventEmitter<any>();

  timeout: any;
  
  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;

  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  constructor(private contractsService: ContractsService,
              private cobrancaService: CobrancasService, private router: Router) {
  }

  ngOnInit(): void {
    this.getTotalValue();
  }

  ngOnChanges(): void {
    this.getTotalValue();
  }

  onPage(event): void{
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
    this.pageNumber.emit(event.offset + 1);
    this.pageNum = (event.offset + 1);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  cancelContract(value: any) {
    this.cancelarEvent.emit(value)
  }

  getTotalValue(){
    let totalValue:number = 0;
    this.rows.forEach(contract =>{
      totalValue = contract.visita.valorAprovado + totalValue;
    }) 
    this.getTotalContractValue.emit(totalValue);
  }

}
