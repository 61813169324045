import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { VehiclesService } from '../../../../global/components/vehicles/services/vehicles.service';
import { VehiclesModel } from '../../../model/vehicles/vehicles.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { RoleModel } from 'app/main/shared/model/role/role.model';
import { DiscountModel } from 'app/main/shared/model/discount/discount.module';
import { ImpostModel } from 'app/main/shared/model/impost/impost.model';
import { BenefitsModel } from 'app/main/shared/model/benefits/benefits.model';


interface DialogData {
  employee: Employee;
  action: string;
  veiculos: Array<VehiclesModel>;
  cargos: Array<RoleModel> ;
  descontos: Array<DiscountModel>;
  impostos: Array<ImpostModel>;
  beneficios: Array<BenefitsModel>;
}

@Component({
  selector: 'app-employee-veiculo-form',
  templateUrl: './employee-veiculo-form.component.html',
  styleUrls: ['./employee-veiculo-form.component.scss'],
  animations: fuseAnimations
})
export class EmployeeVeiculoFormComponent implements OnInit {

  @Input() employee: FormGroup;
  veiculos: Array<VehiclesModel>;

  public veiculoFilterControl: FormControl = new FormControl();
  public filteredVeiculo: ReplaySubject<VehiclesModel[]> = new ReplaySubject<VehiclesModel[]>(1);
  
  constructor(private veiculoService: VehiclesService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

      this.veiculos = this._data.veiculos;
  
      this.filteredVeiculo.next(this.veiculos);
      this.veiculoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterVeiculos();
        });
  
  }

  ngOnInit(): void {}

  // tslint:disable-next-line:member-ordering
  private _onDestroy = new Subject<void>();

  private filterVeiculos(): void {
    if (!this.veiculos) {
      return;
    }
    let search = this.veiculoFilterControl.value;
   
    if (!search) {
      this.filteredVeiculo.next(this.veiculos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredVeiculo.next(
      this.veiculos.filter(veiculo => veiculo.placa.toLowerCase().indexOf(search) > -1)
    );
    console.log(this.filteredVeiculo);

  }

}
