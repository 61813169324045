import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-captacao-forms-telephone',
  templateUrl: './captacao-forms-telephone.component.html',
  styleUrls: ['./captacao-forms-telephone.component.scss']
})
export class CaptacaoFormsTelephoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
