import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LancamentoAgendaService {

  private agenda = `${SGF_API}` + 'global/api/public/lancamento-agenda';
  private agendaSgf = `${SGF_API}` + 'sgf/api/public/lancamento-agenda';
  private posto: string;
  private idPosto: any;

  constructor(private httpClient: HttpClient) { }

  findMarcaById(marcaId: number): any {
    return this.httpClient.get(`${this.agenda}/${marcaId}`)
      .pipe(
        catchError(this.handleError));
  }

  create(value): any {
    const body = JSON.stringify(value);
    return this.httpClient.post(`${this.agenda}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }
  
  update(value): any {
    const body = JSON.stringify(value);
    return this.httpClient.put(`${this.agenda}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  findAll(): any {
    return this.httpClient.get(`${this.agenda}?size=1000&ativo=true`)
      .pipe(
        catchError(this.handleError));
  }
  page(size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.agenda}?size=${size}&page=${page}&ativo=${ativo}`)
      .pipe(
        catchError(this.handleError));
  }

  findLancamentosPorMes(dataBase: Date): any {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.agendaSgf}/listar-lancamentos-por-mes?dataBase=${dataBase.getTime()}&${this.posto}`)
      .pipe(catchError(this.handleError));
  }

  filterMarca(filter: string, size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.agenda}?nome=like:${filter}&size=${size}&page=${page}&ativo=${ativo}`)
      .pipe(
        catchError(this.handleError));
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = Number.isInteger(user.idPosto) ? 'postoId=' + user.idPosto : '';
      this.idPosto = Number.isInteger(user.idPosto) ? user.idPosto : 0;
    }
  }
}
