import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CanceledMainComponent } from './canceled-main/canceled-main.component';
import { CanceledListComponent } from './canceled-list/canceled-list.component';
import { CanceledSidebarComponent } from './canceled-sidebar/canceled-sidebar.component';
import { SharedModule } from '../../../shared/shared.module';
import { SharedMaterialComponentsModule } from '../../../../shared-material-components.module';
import { CanceledDetailComponent } from './canceled-detail/canceled-detail.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CanceledLancamentoListComponent } from './canceled-lancamentos-list/canceled-lancamentos-list.component';

const routes: Routes = [
  {
    path: '',
    component: CanceledMainComponent,
    resolve: {}
  },
  {
    path: 'detail/:id',
    component: CanceledDetailComponent,
    resolve: {}
  },
  {
    path:'lancamentos/:id',
    component:CanceledLancamentoListComponent,
    resolve:{}
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedMaterialComponentsModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    NgxDatatableModule,
    SharedModule,
    FlexLayoutModule,
    NgxPermissionsModule.forRoot({})


  ],
  declarations: [CanceledMainComponent, CanceledListComponent, CanceledSidebarComponent, CanceledDetailComponent,CanceledLancamentoListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CanceledModule { }
