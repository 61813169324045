import { MarcaService } from './../services/marca.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarcaResolveService {

  constructor(private marcaService : MarcaService) { }

  resolve(): Observable<any> {
     return this.marcaService.page(10,1, true).pipe(
       take(1),
       mergeMap(marca => {
        return of(marca['object']);
       })
     );
  }
}
