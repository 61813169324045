import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProfileService } from '../services/profile.service';



@Injectable({
  providedIn: 'root'
})
export class ResolveProfileService implements Resolve<any> {

  constructor(
    private profileService: UserProfileService) { }

  resolve(): Observable<any> {
    return this.profileService.findAll().pipe(
      take(1),
      mergeMap(perfil => {
        return of(perfil['object']);
      })
    );
  }

}
