import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BenefitsModel } from '../../../model/benefits/benefits.model';
import { ReplaySubject, Subject } from 'rxjs';
import { BenefitsService } from '../../../../global/components/benefits/services/benefits.service';
import { takeUntil } from 'rxjs/operators';
import { MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { DiscountModel } from '../../../model/discount/discount.module';
import { DiscountService } from '../../../../global/components/discount/services/discount.service';
import { fuseAnimations } from '@fuse/animations';
import { ImpostService } from 'app/main/global/components/impost/services/impost.service';
import { ImpostModel } from 'app/main/shared/model/impost/impost.model';
import { Employee } from 'app/main/shared/model/employee/employee.model';
import { VehiclesModel } from 'app/main/shared/model/vehicles/vehicles.model';
import { RoleModel } from 'app/main/shared/model/role/role.model';

interface DialogData {
  employee: Employee,
  action: string,
  veiculos: Array<VehiclesModel>;
  cargos: Array<RoleModel> ;
  descontos: Array<DiscountModel>;
  impostos: Array<ImpostModel>;
  beneficios: Array<BenefitsModel>;
}

@Component({
  selector: 'app-employee-folha-pagamento-form',
  templateUrl: './employee-folha-pagamento-form.component.html',
  styleUrls: ['./employee-folha-pagamento-form.component.scss'],
  animations: fuseAnimations
})
export class EmployeeFolhaPagamentoFormComponent implements OnInit {

  @Input() employee: FormGroup;
  beneficios: Array<BenefitsModel>;
  descontos: Array<DiscountModel>
  impostos: Array<ImpostModel>

  public beneficioFilterControl: FormControl = new FormControl();
  public filteredBeneficio: ReplaySubject<BenefitsModel[]> = new ReplaySubject<BenefitsModel[]>(1);

  public descontoFilterControl: FormControl = new FormControl();
  public filteredDesconto: ReplaySubject<DiscountModel[]> = new ReplaySubject<DiscountModel[]>(1);

  public impostoFilterControl: FormControl = new FormControl();
  public filteredImposto: ReplaySubject<ImpostModel[]> = new ReplaySubject<ImpostModel[]>(1);


  constructor(
    private benefitsService: BenefitsService,
    private discountService: DiscountService,
    private impostService: ImpostService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    this.beneficios = this._data.beneficios
    this.descontos = this._data.descontos;
    this.impostos = this._data.impostos;

    this.filteredBeneficio.next(this.beneficios.slice());
    this.beneficioFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBeneficios();
      });


    this.filteredDesconto.next(this.descontos.slice());
    this.descontoFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDesconto();

      })


    this.filteredImposto.next(this.impostos.slice());
    this.impostoFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterImposto();
      });


  }

  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();

  ngOnInit() { }

  private filterBeneficios() {
    if (!this.beneficios) {
      return;
    }

    let search = this.beneficioFilterControl.value;
    if (!search) {
      this.filteredBeneficio.next(this.beneficios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBeneficio.next(
      this.beneficios.filter(beneficio => beneficio.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterDesconto() {
    if (!this.descontos) {
      return;
    }

    let search = this.descontoFilterControl.value;
    if (!search) {
      this.filteredDesconto.next(this.descontos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredDesconto.next(
      this.descontos.filter(desconto => desconto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterImposto() {
    if (!this.impostos) {
      return;
    }

    let search = this.impostoFilterControl.value;
    if (!search) {
      this.filteredImposto.next(this.impostos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredImposto.next(
      this.impostos.filter(imposto => imposto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
