import { Component, OnInit, Inject, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSelect } from '@angular/material';
import {
    Validators,
    FormBuilder,
    FormGroup,
    FormControl,
} from '@angular/forms';
import * as _ from 'lodash';
import { Team } from '../../model/team/team.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { TeamService } from 'app/main/global/components/teams/services/team.service';
import { ActivityFieldService } from '../../../global/components/activity-field/services/activity-field.service';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { EmployeeAssignment } from '../../model/enums/employee-assignment.enum';
import { RotasService } from 'app/main/global/components/rotas/services/rotas.service';
import { Rota } from '../../model/rota/rota.model';
import { Posto } from '../../model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

export interface DialogData {
    team: Team;
    action: string;
}

@Component({
    selector: 'app-team-form',
    templateUrl: './team-form.component.html',
    styleUrls: ['./team-form.component.scss']
})

/**
 * @name TeamFormComponent
 * @description
 * Representação do formulário da Equipe com suas validações
 *
 */

export class TeamFormComponent implements OnInit, OnDestroy, AfterViewInit {
    team: FormGroup;
    titleHeader: string;
    fucaoEnum: EmployeeAssignment;

    @ViewChild('descricao') descricao: ElementRef;

    fiscal: any;
    dialogMessages;
    funcionarios: Employee[];
    disabledButton: boolean;
    rotas: any;
    rotasName = [];
    rotasList: Array<Rota> = [];
    postos: Array<any>;
    posto: Posto;
    public CARGO_FISCAL = 7;
    selectedTuno = 'MANHA';

    public rotaFilterCtrl: FormControl = new FormControl();
    public filteredRotas: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

    public fiscalFilterCtrl: FormControl = new FormControl();
    public filteredFiscal: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
    
    public postoFilterCtrl: FormControl = new FormControl();
    public filteredPosto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    public usuario  = JSON.parse(sessionStorage.getItem('funcionario'));
  
    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private teamService: TeamService,
        private employeeService: EmployeeService,
        private rotaService: RotasService,
        private postoService: PostsService,
        private activityFieldService: ActivityFieldService,
        public matDialogRef: MatDialogRef<TeamFormComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
        this.changeTitleHeader();
    }

    @ViewChild('singleSelect') singleSelect: MatSelect;
    @ViewChild('multiSelect') multiSelect: MatSelect;
    private _onDestroy = new Subject<void>();

    ngOnInit(): void {
        this.setFormGroup();

        if (this.usuario.idPosto != null) {
            this.carregarRotaEFiscal(this.usuario.idPosto);
        } else {
            this.postoLista();
        }
    }

    changeTitleHeader(): void {
        // tslint:disable-next-line:triple-equals
        if (this._data.action == 'new') {
            this.titleHeader = 'Nova Equipe';
        } else {
            this.titleHeader = 'Editar Equipe';
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    ngAfterViewInit(): void {
        setTimeout(() => this.descricao.nativeElement.focus(), 1000);
      }

    setFormGroup(): void {
        const equipe = this._data.team;
        let fiscal = null;
        let posto = null;
        const postoUsuario = new Posto({});

        // tslint:disable-next-line:triple-equals
        if (this._data.action == 'update') {    
            posto = this._data.team.posto;  
          }

        // tslint:disable-next-line:triple-equals
        if (this._data.action == 'update') {
            fiscal = this._data.team.fiscal;
            this._data.team.rotas.forEach(rota => {
                this.rotasName.push(rota.descricao);
              });
        }

        if (this._data.team.posto != null) {
            this.carregarRotaEFiscal(posto.id);
        }

        if (this.usuario.idPosto != null) {
            postoUsuario.id = this.usuario.idPosto;
        }

        this.team = this.formBuilder.group({
            id: equipe.id || null,
            descricao: [equipe.descricao, Validators.required],
            turno: [equipe.turno, Validators.required],
            fiscal: [fiscal ? this._data.team.fiscal.id : null , Validators.required],
            rotas: [this.rotasName || null],
            posto: [posto ? this._data.team.posto.descricao : postoUsuario, Validators.required]
        });
    }

    /**
     * @description Método de Inserção de Equipe no banco de dados,
     * É iterado os array do FormGroup e inseridos em um array de Funcionarios,
     * depois é instanciado um objeto do Tipo Team passando o valor do FormGroup
     * @param data - Objeto do tipo any.
     * @return void
     *
     */
    
    onAdd(data): void {
       
        this.disabledButton = true;
        const rotaList = [];
        
        this.employeeService.findById(data.fiscal).subscribe( response => {

            data.fiscal = response.object;
            
            data.rotas.forEach(element => {
                // tslint:disable-next-line:triple-equals
                rotaList.push(this.rotas.filter(rota => rota.descricao == element)[0]);
            });
    
            data.rotas = rotaList;

            this.rotasList.forEach(p => {
                // tslint:disable-next-line:triple-equals
                if (p.descricao == data.rotas.descricao) {
                    data.rotas = p;
                }
            });
    
            // tslint:disable-next-line:triple-equals
            if (this.postos != null && this.postos != []) {
                this.postos.forEach(posto => {
                    // tslint:disable-next-line:triple-equals
                    if (posto.descricao == data.posto) {
                      data.posto = posto;
                    }      
                });
            } else {
                const posto = new Posto({});
                posto.id = this.usuario.idPosto;
                data.posto = posto;
            }
            this.save(data);
        });

    }

    /**
     * @description Método de Atualização de Equipe no banco de dados,
     * É iterado os array do FormGroup e inseridos em um array de Funcionarios,
     * depois é instanciado um objeto do Tipo Team passando o valor do FormGroup
     *
     * @param data - Objeto do tipo any.
     * @return void
     *
     */

    onUpdate(data): void {
        
        this.disabledButton = true;
        const rotaList = [];
        
        this.employeeService.findById(data.fiscal).subscribe(response => {

            data.fiscal = response.object;
            
            data.rotas.forEach(element => {
                // tslint:disable-next-line:triple-equals
                rotaList.push(this.rotas.filter(rota => rota.descricao == element)[0]);
            });
    
            data.rotas = rotaList;

            this.rotasList.forEach(p => {
                // tslint:disable-next-line:triple-equals
                if (p.descricao == data.rotas.descricao) {
                    data.rotas = p;
                }
            });
    
            // tslint:disable-next-line:triple-equals
            if (this.postos != null && this.postos != []) {
                this.postos.forEach(posto => {
                    // tslint:disable-next-line:triple-equals
                    if (posto.descricao == data.posto) {
                      data.posto = posto;
                    }      
                });
            } else {
                const posto = new Posto({});
                posto.id = this.usuario.idPosto;
                data.posto = posto;
            }
            this.save(data);
        });
    }

    private employeerList(idPosto: number): void {

        this.employeeService.findAllByCargoAndPosto(this.CARGO_FISCAL, idPosto).subscribe(data => {
            const listFuncionarios = data.object;

            this.fiscal = listFuncionarios;
            this.filteredFiscal.next(this.fiscal.slice());

            this.fiscalFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterFiscal();
                });
        });
    }

    private save(data: any): void {

        this.teamService.add(data).subscribe(apiReturn => {

            if (apiReturn.success) {
                this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `${apiReturn.messages[0].text}`
                };

                this.feedback(this.dialogMessages);
                this.matDialogRef.close(apiReturn);
            } else {
                if (apiReturn.messages != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${apiReturn.messages[0].text}`
                    };
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    };
                }
                this.feedback(this.dialogMessages);
                this.disabledButton = false;
            }
        });
    }

    public carregarRotaEFiscal(idPosto: number): void {

        this.rotaService.findByIdPosto(idPosto).subscribe(data => {
            const listRotas = data.object;
            this.rotas = listRotas;
            this.filteredRotas.next(this.rotas.slice());

            this.rotaFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterRota();
                });

        });

        this.employeerList(idPosto);
    }

    private postoLista(): void{

        this.postoService.listarPostos().subscribe(data => {
          this.postos = data.object;
          this.filteredPosto.next(this.postos.slice());
          this.postoFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });
        });
      }

    onClosed(): void {
        this.matDialogRef.close();
    }

    getErrorMessage(field: string): string {
        return `O campo ${field} é obrigatório`;
    }

    onChange(event): void {  // event will give you full breif of action
        const newVal = event.target.value;
    }

    private feedback(message: string[]): void {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }

    private filterFiscal(): void {
        if (!this.fiscal) {
            return;
        }

        let search = this.fiscalFilterCtrl.value;
        if (!search) {
            this.filteredFiscal.next(this.fiscal.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredFiscal.next(
            this.fiscal.filter(fiscal => fiscal.pessoa.nome.toLowerCase().indexOf(search) > -1)
        );
    }

    private filterRota(): void {
        if (!this.rotas) {
            return;
        }

        let search = this.rotaFilterCtrl.value;
        if (!search) {
            this.filteredRotas.next(this.rotas.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredRotas.next(
            this.rotas.filter(rotas => rotas.pessoa.nome.toLowerCase().indexOf(search) > -1)
        );
    }

    private filterPosto(): void {
        if (!this.filteredPosto) {
          return;
        }
        let search = this.postoFilterCtrl.value;
        if (!search) {
          this.filteredPosto.next(this.postos.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        this.filteredPosto.next(
          this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
        );
      }
      
}
