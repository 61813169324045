import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-empresa-dados-form',
  templateUrl: './empresa-dados-form.component.html',
  styleUrls: ['./empresa-dados-form.component.scss'],
  animations:fuseAnimations
})
export class EmpresaDadosFormComponent implements OnInit {

  @Input() empresa: FormGroup;
  maskCnpj= new Mask('00.000.000/0000-00')

  maska: string;
  dialogRef: any;
  snackbarRef: any;
  isCpf = true;
  step = 0;
  @ViewChild('nome') nome: ElementRef;

  constructor(private formBuilder : FormBuilder) { 

  }

  ngOnInit() {
    if(this.empresa.get('listTelefone').value.length == 0){
      this.addPhone();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }


  get phoneForms(): FormArray {
    return this.empresa.get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null)
    });
    this.phoneForms.push(phone);
  };

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

}
