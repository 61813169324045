import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from '../../../../shared/model/contract/contract.model';
import { ContractsService } from '../../../services/contracts.service';
import { ConfirmationContractComponent } from '../../../../shared/dialogs/confirmation-contract/confirmation-contract.component';
import { CancelContractComponent } from '../../../../shared/dialogs/cancel-contract/cancel-contract.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SelectedDialogComponent } from '../../../../shared/dialogs/selected/selected.component';
import { FuseUtils } from '@fuse/utils';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { ConfirmationChangesComponent } from '../../../../shared/dialogs/confirmation-changes/confirmation-changes.component';
import { Page } from 'app/main/shared/model/page/page.model';
import { SelectMaquinaComponent } from 'app/main/shared/components/select-maquina/select-maquina.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlterarContratoFormComponent } from 'app/main/shared/components/alterar-contrato-form/alterar-contrato-form.component';
import { VisitasService } from 'app/main/customers/components/visitas/services/visitas.service';
import { ReagendarContratoFormComponent } from 'app/main/shared/components/reagendar-contrato-form/reagendar-contrato-form.component';

@Component({
  selector: 'app-released-main',
  templateUrl: './released-main.component.html',
  styleUrls: ['./released-main.component.scss'],
  animations: fuseAnimations,
})
export class ReleasedMainComponent implements OnInit {
  rows: Array<Contract> = [];
  itensAPI: Array<Contract>;

  selected: Array<Contract>;
  contractValue: any;

  dialogMessages;
  searchInput: FormControl;
  interval;
  count: number = 0;
  page = new Page();
  public loading = false;
  selectedClear: boolean;
  search;
  filter: string = null;
  pageNumber;
  situacao;
  rest;
  permissoes = [];
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private contractService: ContractsService,
    private visitaService: VisitasService,
    private permissionsService: NgxPermissionsService,
  ) {
    this.situacao = 'LIBERADO';
    this.selected = new Array<Contract>();
    this.route.data.subscribe((res) => {
      this.rows = res.rows.content;
      this.itensAPI = this.rows;
      this.setPage(res.rows);

      this.itensAPI.forEach((contrato) => {
        if (contrato.visita.pagamentoPix) {
          var diferenca = this.getDiferencaEmDias(this.dataSemHoras(new Date(contrato.dataLiberacao)), this.dataSemHoras(new Date()));
          contrato.diferencaDias = diferenca;
        }
      });
    });

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach((element) => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);
  }

  ngOnInit() {}

  over() {
    this.search = null;
    this.loading = true;
    this.contractService.page('LIBERADO', 10, 1).subscribe((res) => {
      this.loading = false;
      this.rows = res.object.content;
      this.itensAPI = this.rows;
      this.setPage(res.object);
      this.situacao = 'LIBERADO';
    });
    this.clearSelectBox();
  }

  clearSelectBox() {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  onSelectResponsible() {
    this.dialog
      .open(SelectedDialogComponent, {
        data: this.rows,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.selected.forEach((contract) => {
            contract.visita.vendedor = data.funcionario;
            this.contractService.alterarVendedor(contract).subscribe((apiReturn) => {
              this.over();
              this.rest = apiReturn;
            });
          });

          if (this.selected.length > 1) {
            const dialogRef = this.dialog.open(ConfirmationChangesComponent, {
              data: this.selected,
            });
            dialogRef.afterClosed().subscribe(() => {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Alterações Realizadas Com Sucesso!`,
              };
              this.clearSelectBox();
              this.feedback(this.dialogMessages);
            });
          } else {
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: 'Vendedor Alterado Com Sucesso!',
            };
            this.clearSelectBox();
            this.feedback(this.dialogMessages);
          }
        }
      });
  }

  confirmContract(select: any) {
    const dialogRef = this.dialog.open(ConfirmationContractComponent, {
      data: select,
    });
    dialogRef.afterClosed().subscribe((data) => {
      data.forEach((contract) => {
        this.contractService.confirmContract(contract.id).subscribe((data) => {
          this.count++;
          if (this.count == select.length) {
            if (data.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `${data.messages[0].text}`,
              };
            } else {
              if (data.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${data.messages[0].text}`,
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
            }
            this.feedback(this.dialogMessages);
          }
          this.over();
        });
      });
    });
    this.clearSelectBox();
  }

  scheduleContract(select: any) {
    const dialogRef = this.dialog.open(CancelContractComponent, {
      data: { data: select, schedule: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        result.data.forEach((contract) => {
          contract.date = result.data.date;
          contract.status = 'NOVO';
          // this.contractService.reagendaContract(contract);
        });
      }
    });
    this.clearSelectBox();
  }

  cancelContract(select: any) {
    this.count = 0;
    const dialogRef = this.dialog.open(CancelContractComponent, {
      data: { data: select, schedule: false },
    });
    dialogRef.afterClosed().subscribe((contract) => {
      if (contract) {
        this.selected.forEach((cont) => {
          let array = [];
          array.push(contract);
          this.count++;
          cont.parecerCancelamento = contract.parecerCancelamento;
          this.contractService.cancelContract(cont).subscribe((data) => {
            if (this.count == array.length) {
              if (data.success) {
                this.dialogMessages = {
                  type: 'success',
                  title: 'Sucesso!',
                  text: `Contrato(s) cancelado(s) com sucesso.`,
                };
              } else {
                if (data.messages[0].text != null) {
                  this.dialogMessages = {
                    type: 'info',
                    title: 'Informação!',
                    text: `${data.messages[0].text}`,
                  };
                } else {
                  this.dialogMessages = {
                    type: 'error',
                    title: 'Error!',
                  };
                }
              }
              this.feedback(this.dialogMessages);
              this.over();
            }
          });
        });
      }
    });
  }

  printContract() {
    this.selected.forEach((contract) => {
      this.contractService.getContract(contract.id).subscribe((ondata) => {
        let objectURL = URL.createObjectURL(ondata);
        window.open(objectURL, '_blank').print();
      });
    });
    this.clearSelectBox();
  }

  printAllContract() {
    this.contractService.printAllContract().subscribe((ondata) => {
      let objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  vincularMaquina(value) {
    this.dialog
      .open(SelectMaquinaComponent, {
        data: {
          action: 'alterar',
          contrato: value,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          value.maquinaCartao = data.maquina;
          this.contractService.vincularMaquina(value).subscribe((data) => {
            if (data.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Máquina alterada com Sucesso`,
              };
              this.feedback(this.dialogMessages);
              this.over();
            } else {
              if (data.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${data.messages[0].text}`,
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                };
              }
              this.feedback(this.dialogMessages);
              this.over();
            }
          });
        }
      });
  }

  onSelected(event): void {
    this.selected = event;
  }

  getTotalContractValue(event: number) {
    this.contractValue = event;
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  alterarProduto(value) {
    this.dialog
      .open(AlterarContratoFormComponent, {
        data: {
          contrato: value,
          action: 'alterarProduto',
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.success) {
          this.over();
        }
      });
  }

  reagendarContrato(value): any {
    this.dialog.open(ReagendarContratoFormComponent, {
      data: {
         contrato: value
      }
    }).afterClosed().subscribe(data => {
      if (data.success) {
        this.over();
      }
    });
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  setPage(data) {
    //Utilizando para o pageable
    this.page.pageNumber = 0;
    this.page.totalPages = data ? data.totalPages : 0;
    this.page.size = data ? data.size : 0;
    this.page.totalElements = data ? data.totalElements : 0;
  }

  getDataFilter(event) {
    this.rows = event.content;
    let page = event;
    page.pageNumber = 0;
    this.setPage(page);
  }

  getSearchFilter(event) {
    this.search = event;
  }

  getSituacao(event) {
    this.situacao = event;
  }

  getFilter(event) {
    this.situacao = event;
    this.filter = event;
    if (event == 'LIBERADO') {
      this.over();
    }
  }

  // Para Cada página ser de acordo com o filtro
  getPage(event) {
    this.loading = true;
    this.pageNumber = event;
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search == '') {
          this.contractService.page('LIBERADO', 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByDocumentoCliente('LIBERADO', this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search == '') {
          this.contractService.page('LIBERADO', 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByNomeCliente('LIBERADO', this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_CONTRATO':
        if (this.search == '') {
          this.contractService.page('LIBERADO', 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByResponsavelContrato('LIBERADO', this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        if (this.search == '') {
          this.contractService.page('LIBERADO', 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByResponsavelRepasse('LIBERADO', this.search, 10, this.pageNumber).subscribe((data) => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case null:
        this.contractService.page('LIBERADO', 10, this.pageNumber).subscribe((data) => {
          this.rows = data.object.content;
          this.loading = false;

          this.setPage(data.object);
        });
        break;
    }
  }

  private getDiferencaEmDias(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  private dataSemHoras(dateTime: Date) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }
}
