import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LancamentoAgendaService } from 'app/main/global/components/lancamento-agenda/services/lancamento-agenda.service';
import { LancamentoAgenda } from '../../model/lancamento-agenda/lancamento-agenda.model';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-acompanhamento-lancamento-agenda',
  templateUrl: './acompanhamento-lancamento-agenda.component.html',
  styleUrls: ['./acompanhamento-lancamento-agenda.component.scss']
})
export class AcompanhamentoLancamentoAgendaComponent implements OnInit {
  
  public observacao: string;

  public lancamentoAgenda: LancamentoAgenda;
  dialogMessages;
  
  now = new Date();
  dateValue: any;
  disabledButton: boolean;
  
  constructor(public matDialogRef: MatDialogRef<AcompanhamentoLancamentoAgendaComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private lancamentoAgendaService: LancamentoAgendaService) {}

  ngOnInit(): void {
    this.lancamentoAgenda = new LancamentoAgenda();
  }
  
  
  onClick(): void {
    this.disabledButton = true;
    if (this.data) {  
      
      this.lancamentoAgenda.id = this.data.lancamentoAgenda.id;
      this.lancamentoAgenda.posto = this.data.lancamentoAgenda.posto;
      this.lancamentoAgenda.equipe = this.data.lancamentoAgenda.equipe;
      this.lancamentoAgenda.rota = this.data.lancamentoAgenda.rota;
      this.lancamentoAgenda.dataAgendamento = new Date(this.data.lancamentoAgenda.dataAgendamento);
      this.lancamentoAgenda.descricao = this.data.lancamentoAgenda.descricao;

      this.lancamentoAgendaService.realizarAcompanhamento(this.lancamentoAgenda).subscribe(
        (data) => {
         
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Acompanhamento realizado com sucesso!`
          };
          this.feedback(this.dialogMessages);
          this.matDialogRef.close(data);
        }
      );

    }
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }
  
  onClose(): void {
    this.matDialogRef.close();
  }


  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`; 
  }

}
