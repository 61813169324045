import { CobrancasService } from './../../../../financial/components/cobrancas/services/cobrancas.services';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Page } from 'app/main/shared/model/page/page.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'app-canceled-list',
  templateUrl: './canceled-list.component.html',
  styleUrls: ['./canceled-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CanceledListComponent implements OnInit {

  @Input() rows: any[];
  @Input() page: Page;
  @Input() filter: any;
  @Input() pageNum: any;
  @Input() dataPeriodo: any;
  @Input() search: any;
  @Output() pageNumber: EventEmitter<any> = new EventEmitter();
  @Output() borderoEvent: EventEmitter<any> = new EventEmitter();
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'))

  timeout: any;
  
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  fortEletro = this.funcionario.postoEletro;

  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  constructor(private contractsService: ContractsService,
              private cobrancaService: CobrancasService, private router: Router) {
  }

  ngOnInit() {
  }

  goToDetails(id: any) {
    this.router.navigate(["/contracts/cancelados/detail/" + id], 
      {queryParams: {filter: this.filter, search: this.search, pageNum: this.pageNum, dataPeriodo: this.dataPeriodo}})
  }

  onPage(event):void{
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
    this.pageNumber.emit(event.offset+1)
    this.pageNum = (event.offset + 1)
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  bordero(value: any) {
    this.borderoEvent.emit(value)
  }

  public gerarBoleto(parcelaId: number): void {
    this.cobrancaService.gerarBoleto(parcelaId).subscribe((data: Blob | any) => {
      if (data) {
        FileSaver.saveAs(data, 'boleto.pdf');
      }
    });
  }
}
