import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cliente } from '../../model/cliente/cliente.model';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { ReferenciasPixFormComponent } from '../referencias-pix-form/referencias-pix-form.component';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { UtilsService } from 'app/shared/util/utils.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-referencia-pix-form-confirmacao',
  templateUrl: './referencia-pix-form-confirmacao.component.html',
  styleUrls: ['./referencia-pix-form-confirmacao.component.scss']
})
export class ReferenciaPixFormConfirmacaoComponent implements OnInit {
  @ViewChild(ReferenciasPixFormComponent) referenciasPixFormComponent: ReferenciasPixFormComponent;

  cliente: Cliente;
  dialogMessages;
  referenciaPixFormInvalido: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private matDialogRef: MatDialogRef<ReferenciaPixFormConfirmacaoComponent>, 
    private clienteService:ClienteService,
    private utilsService: UtilsService,
    private dialog: MatDialog) {
    
    this.clienteService.findById(this.data.contrato.visita.cliente.id)
    .subscribe((response: ResponseAPI) => {
      if (response) {
        this.cliente = (response.object as Cliente);
      } else {
        this.onClosed(null);
      }
    });
  }

  ngOnInit() { }

  checkReferenciaPixFormInvalid(invalido: boolean): void {
    this.referenciaPixFormInvalido = invalido;
  }

  atualizarReferencias(): void {
    this.referenciasPixFormComponent.saveReferencias().subscribe(
      (response) => {
        if (response.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `Referencias Pix Atualizadas com Sucesso!`,
          };
          this.feedback(this.dialogMessages);
          this.onClosed(true);
        } else {
          this.utilsService.feedbackError(
            'Ocorreu um Erro ao Atualizar as Referencias Pix! \n Tente novamente!', 
            TimeMensagem.TIME_MENSAGEM
          );
          this.onClosed(null);
        }
      }
    );
  }

  onClosed(result) : void {
    if (result !== null) {
      this.matDialogRef.close(result);
    } else {
      this.matDialogRef.close();
    }
  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
