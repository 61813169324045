import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ContentComponent } from 'app/layout/components/content/content.component';
import { NgxLoadingModule } from 'ngx-loading';
import { LoaderService } from '../loader/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from '../loader/loader.interceptor';
import { LoadProgressComponent } from 'app/main/shared/dialogs/load-progress/load-progress.component';
import { SharedModule } from 'app/main/shared/shared.module';

@NgModule({
    declarations: [
        ContentComponent
    ],
    imports     : [
        RouterModule,
        FuseSharedModule,
        NgxLoadingModule.forRoot({}),
        SharedModule
    ],
    exports: [
        ContentComponent
    ],
    providers: [
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ]
})
export class ContentModule
{
}
