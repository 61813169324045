import { Component, OnInit } from '@angular/core';
import { Employee } from '../../model/employee/employee.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-declaracao-caixa-form',
  templateUrl: './declaracao-caixa-form.component.html',
  styleUrls: ['./declaracao-caixa-form.component.scss']
})
export class DeclaracaoCaixaFormComponent implements OnInit {

  form: FormGroup;
  employee: Array<Employee>

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);
  
  constructor(private employeeService : EmployeeService,
              private formBuilder : FormBuilder,
              public dialog : MatDialog,
              public dialogRef : MatDialogRef<DeclaracaoCaixaFormComponent>) { 

    this.employeeService.findAll().subscribe(data => {
      this.employee = data.object.content;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfils();
        });
    })

  }

  ngOnInit(){
    this.form = this.formBuilder.group({
      responsavel: [null, Validators.required],
      colaborador : [null, Validators.required],
    })
  }

  private _onDestroy = new Subject<void>();

  private filterPerfils() {
    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
      this.filteredEmployee.next(this.employee.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEmployee.next(
      this.employee.filter(employee => employee.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }


  onCancel(): void {
    this.dialog.closeAll()
  }

  onSave(value){
    this.dialogRef.close(value);
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }
}
