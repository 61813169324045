import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ListUnididadeFederacao } from '../../../model/uf/ufs.model';
import { Employee } from '../../../model/employee/employee.model';
import { MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-employee-endereco-form',
  templateUrl: './employee-endereco-form.component.html',
  styleUrls: ['./employee-endereco-form.component.scss'],
  animations: fuseAnimations
})
export class EmployeeEnderecoFormComponent implements OnInit {
  
  @Input() employee: FormGroup;

  step = 0;
  totalEmployee: number;
  address: FormGroup;
  addressComercial: FormGroup;
  showPanelId: Boolean;
  ufs = ListUnididadeFederacao;
  currentEmployee: Employee;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any) {}

  ngOnInit() {
    if (this.employee.get('pessoa').get('listEndereco').value.length == 0) {
      this.add()
    }
    console.log(this.address)
  }

  get addressForms(): FormArray {
    return this.employee.get('pessoa').get('listEndereco') as FormArray;
  }

  add(required?: boolean): void {
      this.address = this.formBuilder.group({
      cep: new FormControl(null, Validators.required),
      logradouro: new FormControl(null),
      numero: new FormControl(null, Validators.required),
      bairro: new FormControl(null, Validators.required),
      complemento: new FormControl(null),
      pontoReferencia: new FormControl(null),
      municipio: new FormControl(null, Validators.required),
      estado: new FormControl(null, Validators.required),
      tipoEndereco: new FormControl(null)
    });
    
    this.addressForms.push(this.address);
  };

  delete(index: number): void {
    this.addressForms.removeAt(index);
  }

  getBairro(event){
    
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`;
  }

}
