import {Component, OnInit, ViewChild} from '@angular/core';
import {Contract} from '../../../../shared/model/contract/contract.model';
import {Page} from '../../../../shared/model/page/page.model';
import {InProgressSidebarComponent} from '../../in-progress/in-progress-sidebar/in-progress-sidebar.component';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ContractsService} from '../../../services/contracts.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {data} from '../../../../shared/data/chart-data';
import {fuseAnimations} from '../../../../../../@fuse/animations';

@Component({
  selector: 'app-reagendado-main',
  templateUrl: './reagendado-main.component.html',
  styleUrls: ['./reagendado-main.component.scss'],
  animations: fuseAnimations
})
export class ReagendadoMainComponent implements OnInit {


  rows: Array<Contract> = [];
  itensAPI: Array<Contract>;
  page = new Page();
  pageNumber;
  sort;

  interval;
  selected: Array<any>;
  dialogMessages;
  count = 0;
  public loading = false;
  selectedClear: boolean;
  search = '';
  dataPeriodo;
  filter: string = null;

  permissoes = [];
  superUsuario = JSON.parse(sessionStorage.getItem('superUsuario'));
  lastFilter: string;
  lastSearchFilter;

  @ViewChild(InProgressSidebarComponent)
  private sidebar: InProgressSidebarComponent;

  constructor(
      public dialog: MatDialog,
      private route: ActivatedRoute,
      private contractService: ContractsService,
      private permissionsService: NgxPermissionsService,
      private contratoService: ContractsService) {
    this.selected = new Array<any>();

    // Serviço para validação de todas as permissões do usuário
    const perm = JSON.parse(sessionStorage.getItem('permissoes'));
    perm.forEach(element => {
      this.permissoes.push(element);
    });
    this.permissionsService.loadPermissions(this.permissoes);


    this.route.data.subscribe(
        (res) => {
          this.rows = res.rows.content;
          this.itensAPI = this.rows;
          this.rows.forEach(soma => {
            this.contractService.getsomaReagendados(soma.contratoOrigem)
                .subscribe(response => {
                  soma.somaReagendamentos = response.object;
                });
          });
        }
    );
  }

  ngOnInit(): void {
    this.over();
  }

  over(): void {
      this.contractService.getListContratosReagendados(10, 1)
        .subscribe ((res) => {
          this.loading = false;
          this.rows = res.object.content;
          this.rows.forEach(soma => {
            this.contractService.getsomaReagendados(soma.contratoOrigem)
                .subscribe(response => {
                  soma.somaReagendamentos = response.object;
                });
          });
          const page = res.object;
          page.pageNumber = 0;
          this.setPage(page);
        });
  }


  setPage(list: any): void {
    // Utilizando para o pageable
    this.page.pageNumber = list ? list.pageNumber : 0;
    this.page.totalPages = list ? list.totalPages : 0;
    this.page.size = list ? list.size : 0;
    this.page.totalElements = data ? list.totalElements : 0;
  }

  getDataFilter(event): void  {
    this.rows = event.content;
    this.rows.forEach(contrato => {
      this.contractService.getsomaReagendados(contrato.contratoOrigem).subscribe(response => {
        contrato.somaReagendamentos = response.object;
      });
    });
    const page = event;
    page.pageNumber = 0;
    this.setPage(page);
  }

  getSearchFilter(event): void  {
    this.search = event;
  }

  getFilter(event): void {
    this.filter = event;
    if (event == 'ALL') {
      this.over();
      this.filter = 'NOME_CLIENTE';
    }
  }

  clearSelectBox(): void {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }


  clearFilter(): any {
    this.over();
  }

  getPage(event): void {
    this.pageNumber = event ? event : 1;
    this.contractService.getListContratosReagendados(10, this.pageNumber)
    .subscribe ((res) => {
      this.loading = false;
      this.rows = res.object.content;
      this.rows.forEach(contrato => {
        this.contractService.getsomaReagendados(contrato.contratoOrigem).subscribe(response => {
          contrato.somaReagendamentos = response.object;
        });
      });
      const page = res.object;
      page.pageNumber = this.pageNumber - 1;
      this.setPage(page);
    });
  };



}
