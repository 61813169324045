import { NgModule } from '@angular/core';

import { HorizontalLayout1Module } from './horizontal/layout-1/layout-1.module';

import { NgxLoadingModule } from 'ngx-loading';
import { ToolbarModule } from './components/toolbar/toolbar.module';

@NgModule({
    imports: [
        HorizontalLayout1Module,
    ],
    exports: [
        HorizontalLayout1Module,
    ],
    declarations: []
})
export class LayoutModule
{
}
