import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { Caixa } from 'app/main/shared/model/caixa/caixa.model';
import { ReturnApi } from 'app/main/shared/model/return-api/returnApi-model';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

export interface DialogData {
  caixa: Caixa;
  type: string;
}
@Component({
  selector: 'app-caixa-controle-edit',
  templateUrl: './caixa-controle-edit.component.html',
  styleUrls: ['./caixa-controle-edit.component.scss']
})
export class CaixaControleEditComponent implements OnInit, AfterViewInit {

  caixaEdit: FormGroup;
  dialogMessages;
  disabledButton: boolean;
  title: string;
  type: string;

  @ViewChild('valor') valor: ElementRef;

  constructor(private formBiulder: FormBuilder,
    private utilsService: UtilsService,
    private caixaService: CaixaService,
    private transferenciaService: TransferenciaService,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<CaixaControleEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.type = this.data.type;
    this.title = 'Gerar Saldo';

    this.setForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.valor.nativeElement.focus(), 1000);
  }

  setForm(): void {
    this.caixaEdit = this.formBiulder.group({
      type: ['cct'],
      id: [this.data.caixa.id],
      saldo: [null, Validators.required],
      idPosto: this.data.caixa.posto.id
    });

  }

  onCancel(): void {
    this._matDialog.closeAll();
  }

  onEdit(caixa: any): void {

    const saldoCaixaControleTO  = {
        idPosto: caixa.idPosto,
        valor: caixa.saldo 
    };

    this.disabledButton = true;
    this.transferenciaService.gerarSaldoControle(saldoCaixaControleTO).subscribe((data: ReturnApi) => {
      
      this.utilsService.feedbackApi(data, `${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
      
      this.matDialogRef.close(data.object);

    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }



}
