import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-contract',
  templateUrl: './confirmation-contract.component.html',
  styleUrls: ['./confirmation-contract.component.scss']
})
export class ConfirmationContractComponent implements OnInit {

  /**
   * Constructor
   *
   * @param {MatDialogRef<ConfirmationContractComponent>} matDialogRef
   * @param _data
   */
  disabledButton:boolean;

  constructor(
    public matDialogRef: MatDialogRef<ConfirmationContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    
  }

  onNoClick(data?): void {    
    this.disabledButton = true;
    this.matDialogRef.close(data);
  }

}
