import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ParameterService } from "app/main/parameter/services/parameter.service";
import { MessageDialogComponent } from "app/main/shared/components/message-dialog/message-dialog.component";
import { Mask } from "@fagnerlima/ng-mask";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
    selector: 'app-parameter-form',
    templateUrl: './parameter-form.component.html',
    styleUrls: ['./parameter-form.component.scss']
})
export class ParameterFormComponent implements OnInit {

    instrucao_2 = '';
    instrucao_3 = '';
    juros_mora = '';
    nosso_numero = '';
    dialogMessages;
    mask = new Mask('00000000');

    constructor(private dialog: MatDialog,
        private dialogRef: MatDialogRef<ParameterFormComponent>,
        private _matDialog: MatDialog,
        private parameterService: ParameterService) { }

    public disableAccept = false;

    ngOnInit(): void {
        this.parameterService.findByNome('INSTRUCAO_2').subscribe(inst2 => {
            if (inst2.object) {
                this.instrucao_2 = inst2.object.valor
            }
        });

        this.parameterService.findByNome('INSTRUCAO_3').subscribe(inst3 => {
            if (inst3.object) {
                this.instrucao_3 = inst3.object.valor
            }
        });

        this.parameterService.findByNome('JUROS_MORA').subscribe(juros_mora => {
            if (juros_mora.object) {
                this.juros_mora = juros_mora.object.valor
            }
        });

        this.parameterService.findByNome('NOSSO_NUMERO').subscribe(nosso_numero => {
            if (nosso_numero.object) {
                this.nosso_numero = nosso_numero.object.valor
            }
        });
    }

    public openPopup(): void {
        this.dialogRef = this.dialog.open(ParameterFormComponent, {
            data: '',
        });
        setTimeout(() => this.dialogRef.close());
    }

    public onSave() {


        let datas = {
            instrucao2: this.instrucao_2,
            instrucao3: this.instrucao_3,
            jurosMora: this.juros_mora,
            nossoNumero: this.nosso_numero
        };

        console.log(datas)

        this.parameterService.saveParametros(datas).subscribe(rest => {
            if (rest.success) {
                this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `Registro atualizado com sucesso`
                }
                this.feedback(this.dialogMessages);
            } else {
                if (rest.message != null) {
                    this.dialogMessages = {
                        type: 'info',
                        title: 'Informação!',
                        text: `${rest.messages[0].text}`
                    }
                } else {
                    this.dialogMessages = {
                        type: 'error',
                        title: 'Error!',
                    }
                    this.feedback(this.dialogMessages);
                }
            }
        });
    }

    private feedback(message: string[]) {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        })
        setTimeout(() => dialogRef.close(), 8000);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
