import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AttendanceService } from '../../../global/components/attendance/services/attendance.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Employee } from '../../model/employee/employee.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { AttendanceModel } from '../../model/attendace/attendance.model';
import { ReturnApi } from '../../model/return-api/returnApi-model';

export interface DialogData {
  attendance: AttendanceModel,
  action: string
}

@Component({
  selector: 'app-attendance-form',
  templateUrl: './attendance-form.component.html',
  styleUrls: ['./attendance-form.component.scss']
})
export class AttendanceFormComponent implements OnInit {

  titleHeader: string;
  attendance: FormGroup;
  dialogMessages;
  employees: Array<Employee>
  disabledButton:boolean;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private employeeService: EmployeeService,
    public matDialogRef: MatDialogRef<AttendanceFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {
      this.employeeService.findAll().subscribe(data => {
        this.employees = data.object.content;
      })
      
    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Frequência' : 'Edição de Frequência';
    }
    
    ngOnInit() {
      this.setFormGroup();
  }


  setFormGroup() {
    this.attendance = this.formBuilder.group({
      id          : [ this._data.attendance.id],
      funcionario : [ this._data.attendance.funcionario ?this._data.attendance.funcionario.pessoa.nomePessoa : null],
      presenca    : [ this._data.attendance.presenca],
      dataFrequencia        : [ new Date(this._data.attendance.dataFrequencia)]
    })
  }



  onAdd(value) {
    this.disabledButton = true;
    value.funcionario = this.employees.filter((filter) => value.funcionario == filter.pessoa.nomePessoa)[0]

    this.attendanceService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR FREQUÊNCIA', onerror);
      }
    )
    
  }

  onUpdate(value) {  
    this.disabledButton = true;
    value.funcionario = this.employees.filter((filter) => value.funcionario == filter.pessoa.nomePessoa)[0]
      
    this.attendanceService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR FREQUÊNCIA', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  onClosed() {
    this.matDialogRef.close();
  }

}
