import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { Customer } from '../../../../shared/model/customer/customer.model';
import { Information } from '../../../../shared/model/information/information.model';
import { MAT_DIALOG_DATA, MatDialog, MatSelect } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { CaptacaoService } from '../../../../customers/components/captacao/services/captacao.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Segment } from '../../../model/segment/segment.model';
import { MessageDialogComponent } from '../../message-dialog/message-dialog.component';
import { Employee } from '../../../model/employee/employee.model';
import { takeUntil } from 'rxjs/operators';
import { Mask } from '@fagnerlima/ng-mask';
import { Product } from 'app/main/shared/model/product/product.model';
import { ProdutoService } from 'app/main/global/components/produto/services/produto-service.service';
import { Posto } from 'app/main/shared/model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { Team } from 'app/main/shared/model/team/team.model';
import { TeamService } from 'app/main/global/components/teams/services/team.service';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { ContractsRoutingModule } from 'app/main/contracts/routes/contracts.routing';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';

@Component({
  selector: 'app-captacao-forms-pessoa',
  templateUrl: './captacao-forms-pessoa.component.html',
  styleUrls: ['./captacao-forms-pessoa.component.scss'],
  animations: [fuseAnimations]
})
export class CaptacaoFormsPessoaComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('nome') nome: ElementRef;

  indicadores = [];
  @Input() customerId;
  @Input() customer: FormGroup;
  @Output() employeeLoggedEmitter: EventEmitter<Employee> = new EventEmitter();
  @Output() employeeValidateEmmiter: EventEmitter<boolean> = new EventEmitter();
  @Output() isCpfEvent: EventEmitter<any> = new EventEmitter();
  @Output() isEnderecoPreenchido: EventEmitter<any> = new EventEmitter();

  maskCpf = new Mask('000.000.000-00');
  maskCnpj = new Mask('00.000.000/0000-00');
  maskHora = new Mask('00:00');

  maska: string;
  dialogRef: any;
  snackbarRef: any;
  cpf: string;
  isCpf = true;

  step = 0;
  totalCustomer: number;
  showPanelId: Boolean;
  currentCustomer: Customer;
  customerInformation: Information;
  telephones: FormArray;
  filteredOptions: Array<Segment>;
  listFuncionarios: Array<Employee>;
  employeeLogged: Employee;
  produtos: Array<Product>;
  produto: Product;
  produtoList: any[] = [];
  postos: Array<Posto>;
  posto: Posto;
  equipes: Array<Team>;
  employee: Array<Employee> =  [];
  dialogMessages;
  turnos: string[] = [
    'MADRUGADA',
    'MANHA',
    'TARDE',
    'NOITE'
  ];
  selectedTuno = null;
  

  public usuario = JSON.parse(sessionStorage.getItem('funcionario'));


  public segmentFilterCtrl: FormControl = new FormControl();
  public filteredSegment: ReplaySubject<Segment[]> = new ReplaySubject<Segment[]>(1);

  public produtoFilterCtrl: FormControl = new FormControl();
  public filteredProduto: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);

  public equipeFilterControl: FormControl = new FormControl();
  public filteredEquipe: ReplaySubject<Team[]> = new ReplaySubject<Team[]>(1);

  public EmployeeFilterControl: FormControl = new FormControl();
  public filteredEmployee: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);


  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private captacaoService: CaptacaoService,
    private productService: ProdutoService,
    private teamService: TeamService,
    private postoService: PostsService,
    private clienteService: ClienteService,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {

    this.captacaoService.getAtividade().subscribe(
      (ondata) => {
        this.filteredOptions = ondata.object.content;
        this.filteredSegment.next(this.filteredOptions.slice());
        this.segmentFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterAtividadeComercial();
          });
      }
    );

    this.productService.findAll().subscribe(data => {
      this.produtoList = data.object.content;

      this.filteredProduto.next(this.produtoList.slice());
      this.produtoFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProduto();
        });
    });


    if (this.usuario.idPosto != null) {
        this.getVisitadores(this.usuario.idPosto);
    }
  }


  private getVisitadores(idPosto: number): void {
    this.employeeService.findVisitadorByPosto(idPosto).subscribe(data => {
      this.employee = data.object;
      this.filteredEmployee.next(this.employee.slice());
      this.EmployeeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterVisitador();
        });
    });
  }


  private filterVisitador(): void {

    if (!this.employee) {
      return;
    }

    let search = this.EmployeeFilterControl.value;
    if (!search) {
        this.filteredEmployee.next(this.employee.slice());
        return;
    } else {
        search = search.toLowerCase();
    }

    this.filteredEmployee.next(
        this.employee.filter(employee => employee.pessoa.nome.toLowerCase().indexOf(search) > -1)
    );
  }


  ngOnInit(): void {
    if (this.usuario.idPosto == null) {
      this.postoService.listarPostos().subscribe(data => {
        this.postos = data.object;
        this.filteredPosto.next(this.postos.slice());
        this.postoFilterControl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPosto();
          });

          const postoDescricao = this.customer.get('posto').value;

          this.posto = this.postos.filter(posto => posto.descricao === postoDescricao)[0];

          // tslint:disable-next-line:triple-equals
          if (this.posto != undefined) {
            this.getEquipesByPosto(this.posto.id);
            this.getVisitadores(this.posto.id);
          }
      });



    } else {
      this.getEquipesByPosto(this.usuario.idPosto);
    }

    // tslint:disable-next-line:triple-equals
    if (this.customer.get('tipoPessoa').value == 'PESSOA_FISICA') {
      this.setCpfField(true);
    }

    // tslint:disable-next-line:triple-equals
    if (this.customer.get('tipoPessoa').value == 'PESSOA_JURIDICA') {
      this.setCpfField(false);
    }

    if (this.isCpf) {
      this.cpf = this.customer.get('cpf').value;
    }
  }


  getEquipesByPosto(idPosto: number): void {
    this.teamService.findByPostoId(idPosto).subscribe(data => {
      this.equipes = data.object;
      this.filteredEquipe.next(this.equipes.slice());
      this.equipeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEquipe();
        });
    });
  }

  getEquipesEVisitadores(posto: Posto): void {
    this.teamService.findByPostoId(posto.id).subscribe(data => {
      this.equipes = data.object;
      this.filteredEquipe.next(this.equipes.slice());
      this.equipeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEquipe();
        });
    });

    this.getVisitadores(posto.id);
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }
    let search = this.postoFilterControl.value;

    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterEquipe(): void {
    if (!this.equipes) {
      return;
    }
    let search = this.equipeFilterControl.value;
    if (!search) {
      this.filteredEquipe.next(this.equipes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredEquipe.next(
      this.equipes.filter(equipe => equipe.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.nome.nativeElement.focus(), 1000);
  }

  ngAfterViewChecked(): void {
    if (!this.customer.controls.cpf.pristine) {
      this.validacaoPessoaFisicaJaCadastrada();
      this.customer.controls.cpf.markAsPristine();
    }
  }

  get phoneForms(): FormArray {
    return this.customer.get('listTelefone') as FormArray;
  }

  addPhone(required?: boolean): void {
    const phone = this.formBuilder.group({
      ativo: new FormControl(null),
      numero: new FormControl(null),
      id: new FormControl(null),
      tipoSmartphone: new FormControl(false),
      possuiInternet: new FormControl(false),
      instalarAppCliente: new FormControl(false)
    });

    this.phoneForms.push(phone);
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }


  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  validacaoPessoaFisicaJaCadastrada(): void {
    const campo = this.customer.get('cpf');

    if (!campo.pristine && campo.value != this.cpf) {
      this.captacaoService.validarCaptadoExistente(this.customer.get('cpf').value, this.usuario.idPosto).subscribe(resCaptado => {
        if (resCaptado.object) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `Captado já cadastrado!`
          };
          this.feedback(this.dialogMessages);
          this.employeeValidateEmmiter.emit(true);
        } else {
          this.clienteService.validarPessoaJaCadastradaNome(this.customer.get('cpf').value).subscribe(res => {
            if (res.object) {
              this.customer.get('nome').setValue(res.object.nomePessoa);
              this.customer.get('listTelefone').setValue(res.object.listTelefone);
              this.isEnderecoPreenchido.emit(res.object.listEndereco);
            }

            this.employeeValidateEmmiter.emit(false);
          });
        }
      });
    }
  }

  validacaoPessoaJuridicaJaCadastrada(): void {
    this.captacaoService.validarCaptadoExistente(this.customer.get('cnpj').value, this.usuario.idPosto).subscribe(resCaptado => {
      // tslint:disable-next-line:triple-equals
      if (resCaptado.object) {
        this.dialogMessages = {
          type: 'info',
          title: 'Informação!',
          text: `Captado já cadastrado!`
        };
        this.feedback(this.dialogMessages);
        this.employeeValidateEmmiter.emit(true);
      } else {
        this.clienteService.validarPessoaJaCadastradaNome(this.customer.get('cnpj').value).subscribe(res => {
          if (res.object) {
            console.log(res.object);
          }
        });
      }
    });
    
  }

  setCpfField(isCpf: boolean): void {
    this.customer.get('isCpf').setValue(isCpf);
    this.isCpfEvent.emit(isCpf);
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  private filterAtividadeComercial(): any {
    if (!this.filteredOptions) {
      return;
    }

    let search = this.segmentFilterCtrl.value;
    if (!search) {
      this.filteredSegment.next(this.filteredOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSegment.next(
      this.filteredOptions.filter(segment => segment.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  messageDialog(msg: string, icon: string): void {
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: [msg, icon]
    });
  }

  private filterProduto(): any {
    if (!this.produtoList) {
      return;
    }
    let search = this.produtoFilterCtrl.value;
    if (!search) {
      this.filteredProduto.next(this.produtoList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredProduto.next(
      this.produtoList.filter(p => p.nome.toLowerCase().indexOf(search) > -1)
    );
  }
}
