import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-reagendamento-visita',
  templateUrl: './reagendamento-visita.component.html',
  styleUrls: ['./reagendamento-visita.component.scss']
})
export class ReagendamentoVisitaComponent implements OnInit {
  visit: FormGroup
  dataNovaVisita: any;
  now = new Date();
  dateValue: any;
  disabledButton:boolean;
  constructor(public matDialogRef: MatDialogRef<ReagendamentoVisitaComponent>,
              private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.visit = this.formBuilder.group({
      dataVisita: ['', Validators.required],
      parecerAgendamento: ['', Validators.required]
    });


    
  }
  
  onChooseDate(event){
    console.log(this.visit.value.dataVisita);
  }

  onClick(data?): void {
    this.disabledButton = true;
    if (data) {      
      this.dataNovaVisita = this.visit.get('dataVisita').value.format('DD/MM/YYYY');
      this.matDialogRef.close(this.visit);
    }
  }
  
  onClose(){
    this.matDialogRef.close();
  }


  getErrorMessage(field: string) {
    return `O campo ${field} é obrigatório`; 
  }

}
