import 'hammerjs';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth/auth-guard.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

const appRoutes: Routes = [
    {
        path: 'customer',
        loadChildren: './main/customers/customers.module#CustomersModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'authentication',
        loadChildren: './main/authentication/authentication.module#AuthenticationModule',
    },
    {
        path: 'contracts',
        loadChildren: './main/contracts/contracts.module#ContractsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'financial',
        loadChildren: './main/financial/financial.module#FinancialModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'global',
        loadChildren: './main/global/global.module#GlobalModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        loadChildren: './main/home/home.module#HomeModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'processing',
        loadChildren: './main/processing/processing.module#ProcessingModule',
        canActivate: [ AuthGuard ],
    },
    {
        path: 'report',
        loadChildren: './main/report/report.module#ReportModule'

    },
    {
        path: '**',
        redirectTo: 'authentication/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }

export const RootRouterGuards = [];

export const RootRouterComponents = [];
