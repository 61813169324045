import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { EmpresaService } from 'app/main/global/components/empresa/services/empresa-service.service';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { PersonModel } from '../../model/person/persons.model';
import { fuseAnimations } from '@fuse/animations';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { CustomersModel } from '../../model/customers/customers-model';
import { ActivityFieldService } from 'app/main/global/components/activity-field/services/activity-field.service';
import { GlobalService } from 'app/main/global/services/global.service';
interface DATA {
  empresa: PersonModel;
  action: string;
}
declare var google: any;

@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.scss'],
  animations: fuseAnimations
})

export class EmpresaFormComponent implements OnInit {

  empresa: FormGroup
  titleHeader: string;
  listTelefone: any;
  listEndereco: any;
  maska: string;
  currentCustomer: any;
  segmentList: any[] = [];
  bairrosList: any[] = [];
  estadosList: any[] = [];
  municipiosDescriptions: any[] = []
  objectMunicipio: any;
  dialogMessages
  disabledButton:boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public _data: DATA,
    private matDialogRef: MatDialogRef<EmpresaFormComponent>,
    private empresaService: EmpresaService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private activityFieldService: ActivityFieldService) {

    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Empresa' : 'Edição de Empresa';

    this.activityFieldService.getEstado().subscribe(data => {
      this.estadosList = data.object;
    })
    
  }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    if (this._data.action == "update") {
      this.listEndereco = this._data.empresa.listEndereco
      this.listTelefone = this._data.empresa.listTelefone
    } else {
      this.listEndereco = new CustomersModel({}).listEndereco;
      this.listTelefone = new CustomersModel({}).listTelefone;
    }
    this.empresa = this.formBuilder.group({
      id: this._data.empresa ? this._data.empresa.id : null,
      nome: [this._data.empresa ? this._data.empresa.nome : null],
      nomeFantasia: [this._data.empresa ? this._data.empresa.nomeFantasia : null, Validators.required],
      razaoSocial: [this._data.empresa ? this._data.empresa.razaoSocial : null, Validators.required],
      cnpj: [this._data.empresa ? this._data.empresa.documento : null, Validators.required],
      email: [this._data.empresa ? this._data.empresa.email : null],
      listTelefone: this.buildPhone(),
      listEndereco: this.buildAddress(),
      type: ['pj']
    })
  }

  buildPhone(): FormArray {
    const phone = this.listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: [this._data.empresa ? v.ativo : true],
        numero: [this._data.empresa ? v.numero : null, [Validators.required, Validators.minLength(10)]],
        id: [this._data.empresa ? v.id : null]
      });
    });
    return this.formBuilder.array(phone);
  }

  get phoneForms(): FormArray {
    return this.empresa.get('listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  buildAddress(): FormArray {

    let address = this.listEndereco.map(a => {
      return this.formBuilder.group({
        id: new FormControl(this._data.empresa ? a.id : null),
        cep: new FormControl(this._data.empresa ? a.cep : null),
        logradouro: new FormControl(this._data.empresa ? a.logradouro : null),
        numero: new FormControl(this._data.empresa ? a.numero : null),
        latitude: new FormControl(this._data.empresa ? a.latitude : null),
        longitude: new FormControl(this._data.empresa ? a.longitude : null),
        bairro: new FormControl(this._data.empresa ? (a.bairro ? a.bairro.descricao : null) : null),
        municipio: new FormControl(this._data.empresa ? (a.municipio ? a.municipio.nomeMunicipio : null) : null),
        complemento: new FormControl(this._data.empresa ? a.complemento : null),
        estado: new FormControl(this._data.empresa ? (a.estado ? a.estado.sigla : null) : null),
        pontoReferencia: new FormControl(this._data.empresa ? a.pontoReferencia : null),
        tipoEndereco: new FormControl(this._data.empresa ? a.tipoEndereco : null)
      });
    });
    return this.formBuilder.array(address);
  }

  get addressForms(): FormArray {
    return this.empresa.get('listEndereco') as FormArray;
  }

  googleMaps(customer) {
    const geocoder = new google.maps.Geocoder();
    const address: string = `${customer.logradouro}, ${customer.numero}, ${customer.municipio.nomeMunicipio}, ${customer.estado}, ${customer.cep}`

    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        customer.logradouroCompleto = results[0].formatted_address
        customer.latitude = results[0].geometry.location.lat();
        customer.longitude = results[0].geometry.location.lng();
      }
    })

  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }
  onAdd(customers: any) {
    this.disabledButton = true;
    let UF = '';
    let municipio: any;
    customers.listEndereco.forEach(element => {
      UF = element.estado
      municipio = element.municipio
    })

    delete customers.indicador;
    delete customers.isCpf;


    customers.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio
          data.estado = data.bairro.municipio.estado
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            let bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0]
            data.bairro = bairro;
            data.municipio = bairro.municipio
            data.estado = bairro.municipio.estado
          })
        }
      }
    })

    customers.listEndereco.forEach(element => {
      this.googleMaps(element)



      setTimeout(() => {
        this.create(customers);
      }, 800)

    })
  }

  onUpdate(customers: any) {
    this.disabledButton = true;
    let UF = '';
    let municipio: any;
    customers.listEndereco.forEach(element => {
      UF = element.estado
      municipio = element.municipio
    })

    delete customers.indicador;
    delete customers.isCpf;

    customers.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio
          data.estado = data.bairro.municipio.estado
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            let bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0]
            data.bairro = bairro;
            data.municipio = bairro.municipio
            data.estado = bairro.municipio.estado
          })
        }
      }
    })

    customers.listEndereco.forEach(element => {
      this.googleMaps(element);
    })


    setTimeout(() => {
      this.update(customers);
    }, 800)

  }

  private create(object: any) {
    this.empresaService.add(object).subscribe(
      (data) => {
        if (data.success) {
          this.matDialogRef.close(data.object);
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          }
          this.feedback(this.dialogMessages);

        } else {

          if(data.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }
          this.feedback(this.dialogMessages);

          this.matDialogRef.close();
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }

  private update(object: any) {
    this.empresaService.update(object).subscribe(
      (data) => {
        if (data.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          }
          this.feedback(this.dialogMessages);
          this.onClosed();

        } else {

          if(data.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${data.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }




}
