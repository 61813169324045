import { Component, OnInit, Inject } from '@angular/core';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { SituationTransfer } from '../../model/enums/situation-transfer.enum';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { DebitoFaltaService } from 'app/main/financial/services/debito-falta/debito-falta.service';
import { MotivoDesistenciaContratoService } from 'app/main/financial/services/motivo-desistencia-contrato/motivo-desistencia-contrato.service';
import { MotivoDesistenciaContrato } from '../../model/motivo-desistencia-contrato/motivo-desistencia-contrato.model';
import { Router } from '@angular/router';

export interface DialogData {
  funcionarioId: number;
  confirmation: any;
  origem: boolean;
  situacao: string;
  id: number;
  type: string;
}

export namespace Situation {
  export function values(): any {
    return Object.keys(SituationTransfer).filter((type) => isNaN(<any>type) && type !== 'values');
  }
}

@Component({
  selector: 'app-contrato-liberado-dialog',
  templateUrl: './contrato-liberado-dialog.component.html',
  styleUrls: ['./contrato-liberado-dialog.component.scss'],
})
export class ContratoLiberadoDialogComponent implements OnInit {
  situaces = Situation;
  confirmation: any;
  dialogRef: any;
  titulo: string;
  titleHeader: string;
  origem: any;
  message: string;
  situacao: string;
  disabledButton: boolean;
  dataRest;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ContratoLiberadoDialogComponent>,
    private _matDialog: MatDialog,
    private router: Router
  ) {
  }

  ngOnInit(): void {}

  confirmTransfer() {
    this.matDialogRef.close(true);
  }

  onCancel(): void {
      this.router.navigate ( ['/contracts/liberados'] );
      this.matDialogRef.close(false);
  }
}
