import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-changes',
  templateUrl: './confirmation-changes.component.html',
  styleUrls: ['./confirmation-changes.component.scss']
})
export class ConfirmationChangesComponent implements OnInit {
  disabledButton:boolean;

  constructor(public matDialogRef: MatDialogRef<ConfirmationChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }
  
    onNoClick(data?): void {   
      this.disabledButton = true;
      this.matDialogRef.close(data);
    }
}
