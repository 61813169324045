import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-released-sidebar',
  templateUrl: './released-sidebar.component.html',
  styleUrls: ['./released-sidebar.component.scss']
})
export class ReleasedSidebarComponent implements OnInit, OnChanges {


  filterBy: string;
  all: boolean = false;
  ativo: boolean;
  novo: boolean;
  inativo: boolean
  filter: string;
  inputDisabled = false;
  search = '';
  placeholder: string;
  situacao: string;

  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() situacaoEvent: EventEmitter<string> = new EventEmitter();
  @Input() searchInput: FormControl;

  constructor(private contractService: ContractsService) {
    this.situacao = "LIBERADO"
    this.placeholder = "Buscar por Cliente"
    this.filter = 'NOME_CLIENTE'
  }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.searchInput == null){
      this.search = '';
    }
  }


  //Seleção de filtro
  getFilter(filter) {
    switch (filter) {
      case 'LIBERADO':
        this.filter = 'NOME_CLIENTE';
        this.filterEvent.emit('LIBERADO');
        this.situacao = "LIBERADO"
        this.inputDisabled = false;
        this.placeholder = "Buscar por Cliente";
        this.situacaoEvent.emit(this.situacao)
        break;
      case 'DOCUMENTO_CLIENTE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Documento Cliente";
        this.filterEvent.emit('DOCUMENTO_CLIENTE');
        break
      case 'NOME_CLIENTE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Cliente";
        this.filterEvent.emit('NOME_CLIENTE');
        break
      case 'RESPONSAVEL_CONTRATO':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Responsável Contrato";
        this.filterEvent.emit('RESPONSAVEL_CONTRATO');
        break
      case 'RESPONSAVEL_REPASSE':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Responsável Repasse";
        this.filterEvent.emit('RESPONSAVEL_REPASSE');
        break
      case 'ROTA':
        this.filter = filter
        this.inputDisabled = false;
        this.placeholder = "Buscar por Rota";
        this.filterEvent.emit('ROTA');
        break
    }
  }

  //Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any) {
    this.searchEvent.emit(this.search)
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search == '') {
          this.contractService.page(this.situacao, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        } else {
          this.contractService.pageByDocumentoCliente(this.situacao, this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search == '') {
          this.contractService.page(this.situacao, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        } else {
          this.contractService.pageByNomeCliente(this.situacao, this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        }
        break
      case 'RESPONSAVEL_CONTRATO':
        if (this.search == '') {
          this.contractService.page(this.situacao, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        } else {
          this.contractService.pageByResponsavelContrato(this.situacao, this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        }
        break
      case 'RESPONSAVEL_REPASSE':
        if (this.search == '') {
          this.contractService.page(this.situacao, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        } else {
          this.contractService.pageByResponsavelRepasse(this.situacao, this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        }
        break
      case 'ROTA':
        if (this.search == '') {
          this.contractService.page(this.situacao, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        } else {
          this.contractService.pageByResponsavelRota(this.situacao, this.search, 10, 1).subscribe(data => {
            this.data.emit(data.object)
          })
        }
        break
    }
  };

}
