import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { ImpostService } from '../services/impost.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveImpostService implements Resolve<any> {

  
  constructor(
    private impostService: ImpostService) { }

  resolve(): Observable<any> {
     return this.impostService.findAll().pipe(
       take(1),
       mergeMap(impost => {
        return of(impost['object']);
       })
     );
  }

}
