import { Customer } from './customer.model';

export class ArquivoCliente {
 
    id: number;
    nome: string;
    cliente: Customer;
    dataCriacao: Date;
    filePath: string;
    tipoReferenciaArquivoCliente: string;
    documentoBase64: string;
    clienteId: number

    constructor(obj?: any){
        this.setAtributes(obj);
    }

    setAtributes(obj: any): void {
        this.id = obj ? obj.id : null;
        this.nome =  obj ? obj.nome : null;
        this.cliente =  obj ? obj.cliente : null;
        this.dataCriacao =  obj ? obj.dataCriacao : new Date();
        this.filePath =  obj ? obj.filePath : null;
        this.tipoReferenciaArquivoCliente =  obj ? obj.tipoReferenciaArquivoCliente : null;
        this.documentoBase64 =  obj ? obj.documentoBase64 : null;
        this.clienteId = obj ? obj.clienteId : null;
    }
}
