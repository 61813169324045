import { Injectable } from '@angular/core';
import { ClienteService } from '../services/cliente.service';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveClienteService implements Resolve<any>{

  constructor(
    private clienteService: ClienteService) { }

  resolve(): Observable<any> {
    return this.clienteService.page(10,1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
