import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveCaixaControleService {

  type:string = "cct"

  constructor(private caixaService: CaixaService) { }
  
  resolve(): Observable<any> | any {
    return this.caixaService.page(this.type,10,1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
  }
}
