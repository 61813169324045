import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Caixa } from '../../model/caixa/caixa.model';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { Posto } from '../../model/posto/posto.model';
import { ReplaySubject, Subject } from 'rxjs';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from 'app/shared/util/utils.service';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { OdinService } from 'app/shared/util/odin.service';
import { SGF_API } from 'app/shared/api/sgf.api';

@Component({
  selector: 'app-transferencia-form',
  templateUrl: './transferencia-form.component.html',
  styleUrls: ['./transferencia-form.component.scss'],
  animations: fuseAnimations
})
export class TransferenciaFormComponent implements OnInit {

  transfer: FormGroup;
  component: any;
  dialogMessages;
  filteredOptions: Array<any>[];
  idDestino: number;
  caixaOrigem: any;
  caixaDestino: any;
  caixaFuncionarios: Caixa[];
  selectFuncionarioOrigem = false;
  selectFuncionarioDestino = false;
  caixa: any;
  funcionarioId: any ;
  disabledButton: boolean;
  posto: Posto;
  postos: Array<Posto>;
  public postoFilterControl: FormControl = new FormControl();
  public filteredPosto: ReplaySubject<Posto[]> = new ReplaySubject<Posto[]>(1);
  destinoType: string;
  natureza = '';
  

  // tslint:disable-next-line:member-ordering
  private _onDestroy = new Subject<void>();

  caixas = [
    {type: 'cct', nome: 'Caixa Controle'},
    {type: 'cge', nome:  'Caixa Geral'},
  ];

  naturezas = [
    'COMPLEMENTO',
    'SANGRIA'
  ];
  
  
  constructor(private formBuilder: FormBuilder,
              private _matDialog: MatDialog,
              private matDialogRef: MatDialogRef<TransferenciaFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private postoService: PostsService,
              private transferenciaService: TransferenciaService,
              private caixaService: CaixaService,
              private odinService: OdinService,
              private utilsService: UtilsService) { 
            
              this.component = data;
              this.destinoType = data.destinoType;
              
              
              caixaService.findByIdCaixa(data.id).subscribe( response => {
                this.caixaOrigem = response.object;
              });

              this.listarPostoParaComplemento();
  }

  private async listarPostoParaComplemento(): Promise<void> {
    const response = await this.postoService.listarPostos().toPromise();
    const postosExternos = await this.listarPostosExternos();

    this.postos = response.object.concat(postosExternos);
    this.filteredPosto.next(this.postos.slice());
    this.postoFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.filterPosto());
  }

  private async listarPostosExternos(): Promise<Posto[]> {
    try {
      const produtosExternos = await this.odinService.listarProdutos().toPromise();
      return await produtosExternos
        .map(produto => `${produto.dominio}/`)
        .filter(dominio => dominio !== SGF_API)
        .map(async (url) => {
          try {
            const responseExterna = await this.postoService.listarPostosDeOutroEstado(url).toPromise();
            return responseExterna.success ? responseExterna.object : [];
          } catch (error) {
            return [];
          }
        })
        .reduce(async (todosOsPostos, postosDoEstado) => [...await todosOsPostos, ...await postosDoEstado]);
    } catch {
      return [];
    }
  }

  ngOnInit(): void {
    this.setFormBuilder();
  }

  setNatureza(natureza: string): void {
    if (natureza === 'COMPLEMENTO') {
      this.natureza = 'COMPLEMENTO';
    } else {
      this.natureza = 'SANGRIA';
    }
  }

  setFormBuilder(): void {
    this.transfer = this.formBuilder.group({
      type: [null],
      origem: this.formBuilder.group({
        type: [this.data.type || null],
        id: [this.data.id || null]
      }),
      destino: this.formBuilder.group({
        type: [this.data.destinoType],
        id: [this.data.destinoId]
      }),
      posto: null,
      postoDestino: null,
      natureza: [null, Validators.required],
      parecerSangria: [null],
      valorPrevisto: [null, Validators.required],
      situacao: ['GERADA']
    });
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`; 
  }
  
  onCancel(): void {
    this._matDialog.closeAll();
  }

  transferir(transferencia: any): void {

    // Criando o type da transferencia
    const orig = transferencia.origem.type.slice(1);
    const dest = transferencia.destino.type.slice(1);
    transferencia.type = `t${orig}${dest}`; 
   
    transferencia.valorRealizado = transferencia.valorPrevisto;
    transferencia.destino.id = this.idDestino;
    transferencia.posto = this.caixaOrigem.posto;

    // tslint:disable-next-line: triple-equals
    if (this.destinoType == 'cge') {
      this.transferirControleGeral(transferencia);
      // tslint:disable-next-line: triple-equals
    } else if (this.destinoType == 'cct') {
      this.transferirGeralControle(transferencia);
    }
  }

  transferirControleGeral(transferencia: any): void {
    this.transferenciaService.transferirSaldoControleGeral(transferencia).subscribe(
      (response: ReturnApi) => {
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

        if (isSuccess) {
          this.matDialogRef.close();
        } else {
          this.disabledButton = false;
        }
      });
 
    this.disabledButton = true;
  }

  transferirGeralControle(transferencia: any): void {

    this.transferenciaService.transferirSaldoGeralControle(transferencia).subscribe(
      (response: ReturnApi) => {
        const isSuccess = this.utilsService.feedbackApi(response, 'Registro realizado com sucesso!', TimeMensagem.TIME_MENSAGEM_FINANCEIRO);

        if (isSuccess) {
          this.matDialogRef.close();
        } else {
          this.disabledButton = false;
        }
      }
    );
  
    this.disabledButton = true;
  }

  private filterPosto(): void {
    if (!this.postos) {
      return;
    }

    let search = this.postoFilterControl.value;

    if (!search) {
      this.filteredPosto.next(this.postos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPosto.next(
      this.postos.filter(posto => posto.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
