import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange, MatDialog } from '@angular/material';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { Parcela } from '../../model/parcela/parcela.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Mask } from '@fagnerlima/ng-mask';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { data } from '../../data/chart-data';
import { Contract } from '../../model/contract/contract.model';
import { ContratoLancamento } from '../../model/contrato-lancamento/contrato-lancamento.model';
declare var jsPDF: any;

@Component({
  selector: 'app-bordero-form',
  templateUrl: './bordero-form.component.html',
  styleUrls: ['./bordero-form.component.scss']
})
export class BorderoFormComponent implements OnInit, AfterViewInit {

  mask = new Mask('000')
  form: FormGroup;
  dialogMessages;
  contrato: Contract;
  valorTotal: number = 0;
  lancamentos: ContratoLancamento[];

  public showValor = false;

  public ParcelaControl: FormControl = new FormControl();
  public filteredParcelas: ReplaySubject<Parcela[]> = new ReplaySubject<Parcela[]>(1);

  @ViewChild('valorExedente') valorExedente: ElementRef;

  constructor(private contratoService: ContractsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BorderoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matDialog: MatDialog) {
      this.contrato = _data.contrato;
  }


  private _onDestroy = new Subject<void>();

  public ngOnInit(): void {
    let valorExcedente = 0;
    this._data.contrato.listParcela.map(parcela => {
      valorExcedente = parcela.valorExcedente + valorExcedente
    });
    this.form = this.formBuilder.group({
      taxaJuros: [null],
      valorExcedente: [valorExcedente]
    });
    this.contratoService.getLancamentos(this.contrato.id).subscribe(data => {
      this.lancamentos = data.object;

      this.lancamentos.forEach(data => {
        this.valorTotal = this.valorTotal + data.valorLancamento
      })
    });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }


  ngAfterViewInit() {
    setTimeout(() => this.valorExedente.nativeElement.focus(), 1000);
  }

  public onSave(value: any): void {
    let hoje = new Date();
    let dataCalculo = new Date(this._data.contrato.dataVencimento);
    dataCalculo.setDate(dataCalculo.getDate() + 32);
    const diffTime = Math.abs(dataCalculo.getTime() - hoje.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const calculo = (value.valorExcedente * value.taxaJuros)/100;
    const resultado = calculo/30;
    const juros = resultado * diffDays;
    const valorAPagar = value.valorExcedente - juros;

    this.dialogRef.close(valorAPagar);
  }

  gerarPDF() {
    let rows = []
    let dataEmissao = new Date(this.contrato.dataEmissao)
    let dataVencimento = new Date(this.contrato.dataVencimento)
    let doc = new jsPDF('p', 'pt');

    let valorContrato = this.contrato.valorLiquido*100/(100-this.contrato.taxaJuros);

    doc.setFontSize(11);
    doc.text(7, 20, `Emissão: ${dataEmissao.toLocaleDateString('pt-br')} Hora: ${dataEmissao.toLocaleTimeString('pt-br').slice(0, 5)}`);
    doc.setFontSize(9);
    doc.text(7, 38, `Titulo: ${this.contrato.id}, Cliente: ${this.contrato.visita.cliente.id}-${this.contrato.visita.cliente.pessoa.nomePessoa ? this.contrato.visita.cliente.pessoa.nomePessoa : this.contrato.visita.cliente.pessoa.nome}, Ativ.Comercial: ${this.contrato.visita.cliente.atividadeComercial.descricao} `);
    doc.setFontSize(9);
    doc.text(7, 48, `Valor do Contrato: ${valorContrato.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })},Vencimento: ${dataVencimento.toLocaleDateString('pt-br')}, Situação: ${this.contrato.situacaoContrato}`)
    doc.setFontSize(9);
    doc.text(7, 58, `Parcela: ${this.contrato.listParcela[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, Valor Total Lançamentos: ${this.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, Taxa Juros: ${this.contrato.taxaJuros ? this.contrato.taxaJuros : 0}`
    );



    //Keys
    let columns = [
      { title: "Lançamento", dataKey: "dataVencimento" },
      { title: "Valor do Lançamento", dataKey: "valorLancamento" },
    ];


    //Objeto criado para utilização da tablea
    this.lancamentos.forEach(element => {
      let date = new Date(element.dataVencimento);
      let data = {
        dataVencimento: date.toLocaleDateString('pt-br'),
        valorLancamento: element.valorLancamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      }
      rows.push(data)
    })

    //Tabela exibida no pdf
    doc.autoTable(columns, rows, {
      theme: "striped",
      margin: { horizontal: 7, top: 65 },
      bodyStyles: { valign: 'top' },
      styles: { overflow: 'linebreak' },
    });


    // //Tabela exibida no pdf
    // doc.autoTable(columns = [
    //   { title: "Valor a Receber", dataKey: "valorAReceber" },
    //   { title: "Total Pago", dataKey: "valorPago" }], rows = [{
    //     valorAReceber: this.valorAReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    //     valorPago: this.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    //   }], {
    //     theme: "plain",
    //     margin: { horizontal: 7, top: 70 },
    //     bodyStyles: { valign: 'top' },
    //   });


    doc.save(`acompanhamento-lancamentos.pdf`);
  }
}
