import { Routes } from '@angular/router';

import { ErrorComponent } from '../components/error/error.component';
import { ForgotComponent } from '../components/forgot/forgot.component';
import { LockscreenComponent } from '../components/lockscreen/lockscreen.component';
import { LoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';
export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [{
      path: '404',
      component: ErrorComponent
    }, {
      path: 'forgot',
      component: ForgotComponent
    }, {
      path: 'lockscreen',
      component: LockscreenComponent
    }, {
      path: 'login',
      component: LoginComponent
    }, {
      path: 'register',
      component: RegisterComponent
    }]
  }
];
