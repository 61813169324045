import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Parcela } from 'app/main/shared/model/parcela/parcela.model';
import { MatDialog } from '@angular/material';
import { MudarResponsavelFormComponent } from 'app/main/shared/components/mudar-responsavel-form/mudar-responsavel-form.component';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { MessageDialogComponent } from 'app/main/shared/components/message-dialog/message-dialog.component';
import { ReceberParcelaFormComponent } from 'app/main/shared/components/receber-parcela-form/receber-parcela-form.component';

@Component({
  selector: 'app-in-progress-boleto-detail-list',
  templateUrl: './in-progress-boleto-detail-list.component.html',
  styleUrls: ['./in-progress-boleto-detail-list.component.scss'],
  animations: fuseAnimations
})
export class InProgressBoletoDetailListComponent implements OnInit {

  @Input() rows: any;
  timeout: any;
  @Input() selectedClear;
  @Input() contrato;

  @Output() selectEvent = new EventEmitter<any>();
  selected: Array<Parcela>;
  @Output() alterarResponsavelEvent = new EventEmitter<any>();
  @Output() alterarParcelaEvent = new EventEmitter<any>();
  @Output() receberParcelaEvent = new EventEmitter<any>();
  @Output() confirmParcelaEvent = new EventEmitter<any>();

  dialogMessages = []
  constructor(private _matDialog: MatDialog,
    private cobrancaService: CobrancasService) {
    this.selected = new Array<Parcela>();

  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.selectedClear) {
      this.selected = new Array<any>();
    }
  }

  onPage(event): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }

  generateMask(value: number) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
