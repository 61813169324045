import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export type MessageDialogComponentType = 'success' | 'info' | 'warning' | 'error';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  title: string;
  text: string;
  showCancelButton: boolean;
  showConfirmButton: boolean;
  textCancelButton: string;
  textConfirmButton: string;
  type: MessageDialogComponentType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<MessageDialogComponent>) {
    this.title = data.title;
    this.type = data.type;
    this.text = data.text;
    this.showCancelButton = data.showCancelButton;
    this.showConfirmButton = data.showConfirmButton;
    this.textCancelButton = data.textCancelButton;
    this.textConfirmButton = data.textConfirmButton;
  }

  ngOnInit(): void { }

  close(value): void {
    this.matDialogRef.close(value);
  }
}
