import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { HomeComponent } from './home.component';
import { HomeSharedModule } from './shared/shared.module';
import { SharedMaterialComponentsModule } from '../../shared-material-components.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HomeResolveService } from './resolve/home-resolve.service';

const routes = [
    {
        path     : '',
        component: HomeComponent
    }
];

@NgModule({
    declarations: [
        HomeComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        HomeSharedModule,
        FuseSharedModule,

        NgxChartsModule,
        SharedMaterialComponentsModule
    ],
    exports     : [
        HomeComponent
    ]
})

export class HomeModule
{
}
