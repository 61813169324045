import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ContractsService } from '../../../services/contracts.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-reagendados-sidebar',
  templateUrl: './reagendados-sidebar.component.html',
  styleUrls: ['./reagendados-sidebar.component.scss']
})
export class ReagendadosSidebarComponent implements OnInit, OnChanges {

  filter: string;
  inputDisabled = false;
  search = '';
  placeholder: string;

  @Output() data: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();

  @Input() searchInput;

  constructor(private contractService: ContractsService) {
    this.placeholder = "Buscar por Cliente"
    this.filter = 'NOME_CLIENTE'
  }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.searchInput == null){
      this.search = '';
    }
  }

  //Seleção de filtro
  getFilter(filter: string) {
    this.filterEvent.emit(filter);
    this.filter = filter === 'ALL' ? 'NOME_CLIENTE' : filter;
    this.inputDisabled = false;
    
    if (['ALL', 'NOME_CLIENTE'].includes(filter)) {
      this.placeholder = "Buscar por Cliente";
    }

    if (filter === 'ROTA') {
      this.placeholder = "Buscar por Rota";
    }
  }

  //Pesquisa de acordo com o filtro
  onKeyPressFilter(event: any) {
    this.searchEvent.emit(this.search)
    if (this.search === '') {
        this.contractService.getListContratosReagendados(10, 1).subscribe(data => {
          this.data.emit(data.object)
        })
    }
    if (this.search.length > 2 || event.code == "Enter") {
        if (this.filter === 'NOME_CLIENTE') {
            this.contractService.getListContratosReagendados(10, 1, { nomeCliente: this.search }).subscribe(data => {
                this.data.emit(data.object)
            });
        }
        if (this.filter === 'ROTA') {
            this.contractService.getListContratosReagendados(10, 1, { descricaoRota: this.search }).subscribe(data => {
                this.data.emit(data.object)
            });
        }
    }
  };
}
