
import { Address } from "../address/address.model";
import { Telephone } from "../telephone/telephone.model";
import { User } from "../user/user.model";

export class PersonPf{
    ativo?: boolean;
    id?: number;
    type?: string;
    email?: string;
    nome?: string;
    nomePessoa?: string
    cpf?: string;
    listEndereco?: Array<Address>;
    listTelefone?: Array<Telephone>;
    dataNascimento? : string;
    documento?:string;
    rg?:string;
    identificador?:string;
    sexo:any;
    estadoCivil?:string;

    constructor(obj?: any){
        this.ativo = obj ? obj.ativo : true;
        this.id = obj ? obj.id : null;
        this.type = obj ? obj.type : 'pf';
        this.email = obj ? obj.email : null;
        this.nome = obj ? obj.nome : null;
        this.nomePessoa = obj ? obj.nomePessoa : null;
        this.cpf = obj ? obj.cpf : null;
        this.listEndereco = obj ? obj.listEndereco : [new Address({})];
        this.listTelefone = obj ? obj.listTelefone : [new Telephone({})];
        this.dataNascimento = obj ? obj.dataNascimento : null;
        this.documento = obj ? obj.documento : null;
        this.rg = obj ? obj.rg : null;
        this.identificador = obj ? obj.identificador : null;
        this.sexo = obj ? obj.sexo : null;
        this.estadoCivil = obj ? obj.estadoCivil : null;


    }
}
