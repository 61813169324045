import { Component, ViewEncapsulation, AfterViewInit, OnChanges } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { Http } from '@angular/http';
import { Subject } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { timeout } from 'rxjs/operators';

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements AfterViewInit {

    isLoading: Subject<boolean> = this.loaderService.isLoading;
    showSpinner;

    loading: boolean;
    constructor(private loaderService: LoaderService,
        private router: Router) {
        this.loaderService.isLoading.subscribe((v) => {
            setTimeout(() => {
                this.loading = v;
            }, 10)
        });
    }

    ngAfterViewInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.showSpinner = true;
                }
                else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.showSpinner = false;
                }
            });
    }

}
