import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ContaService } from 'app/main/financial/components/conta/services/conta-service.service';
import { BancoService } from 'app/main/financial/components/banco/services/banco-service.service';
import { AgenciaService } from 'app/main/financial/components/agencia/services/agencia-service.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
  selector: 'app-conta-form',
  templateUrl: './conta-form.component.html',
  styleUrls: ['./conta-form.component.scss']
})
export class ContaFormComponent implements OnInit {

  conta: FormGroup;
  dialogMessages;
  titleHeader: string;
  agencias: any[];
  bancos: any[];
  disabledButton: boolean;

  private _onDestroy = new Subject<void>();

  public bancoFilterControl: FormControl = new FormControl();
  public filteredBanco: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private formBuilder: FormBuilder,
    private agenciaService: AgenciaService,
    private contaService: ContaService,
    private matDialogRef: MatDialogRef<ContaFormComponent>,
    private bancoService: BancoService,
    private utilsService: UtilsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any) {

    // tslint:disable-next-line:triple-equals
    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Conta' : 'Edição de Conta';

    this.agenciaService.findAll().subscribe(data => {
      this.agencias = data.object;
    });

    this.bancoService.findAll().subscribe(data => {
      this.bancos = data.object.content;
      this.filteredBanco.next(this.bancos.slice());
      this.bancoFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBancos();
        });
    });
  }

  ngOnInit(): void {
    this.setForm();
  }


  setForm(): void {
    this.conta = this.formBuilder.group({
      id: [this._data.conta ? this._data.conta.id : null],
      agencia: [this._data.conta ? this._data.conta.agencia.identificador : null, Validators.required],
      banco: [this._data.conta ? this._data.conta.agencia.banco.descricao : null, Validators.required],
      numero: [this._data.conta ? this._data.conta.numero : null, Validators.required]
    });
  }


  create(conta): void {
    this.disabledButton = true;
    // tslint:disable-next-line:triple-equals
    conta.agencia = this.agencias.filter(agencia => agencia.identificador == conta.agencia)[0];
    // tslint:disable-next-line:triple-equals
    conta.banco = this.bancos.filter(banco => banco.descricao == conta.banco)[0];


    this.contaService.add(conta).subscribe(data => {
      if (data.success) {
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }
      }
    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar conta`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR CONTA', onerror);
      }
    );
  }

  update(conta): void {
    this.disabledButton = true;
    // tslint:disable-next-line:triple-equals
    conta.agencia = this.agencias.filter(agencia => agencia.identificador == conta.agencia)[0];
    // tslint:disable-next-line:triple-equals
    conta.banco = this.bancos.filter(banco => banco.descricao == conta.banco)[0];

    this.contaService.add(conta).subscribe(data => {
      if (data.success) {
        this.utilsService.feedbackSuccess(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
        this.matDialogRef.close(data.object);
      } else {
        if (data.messages[0].text != null) {
          this.utilsService.feedbackInfo(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.disabledButton = false;
        } else {
          this.utilsService.feedbackError(`${data.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close();
        }
      }

    },
      (onerror) => {
        this.utilsService.feedbackError(`Não foi possível criar conta`, TimeMensagem.TIME_MENSAGEM);
        console.log('ERROR CONTA', onerror);
      }
    );
  }

  getErrorMessage(field: string): any {
    return `O campo ${field} é obrigatório`;
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  private filterBancos(): void {
    if (!this.bancos) {
      return;
    }

    let search = this.bancoFilterControl.value;
    if (!search) {
      this.filteredBanco.next(this.bancos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBanco.next(
      this.bancos.filter(banco => banco.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

}
