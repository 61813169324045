import { FuseNavigation } from '@fuse/types';
export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Inicio',
        translate: 'HOME.TITLE',
        type: 'item',
        icon: 'home',
        url: '/home',
    },
    {
        id: 'MENU_GESTAO_CLIENTES',
        title: 'Gestão de Clientes',
        translate: 'CUSTOMERMANAGEMENT.TITLE',
        icon: 'group',
        type: 'group',
        children: [
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/cliente-planejamento',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMI'
            },
            {
                id: 'CAPTADO_FIND_ALL',
                url: '/customer/captacao',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMB'
            },
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/visitas',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMC'
            },
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/visitados',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMD'
            },
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/clientes-inativos',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMJ'
            },
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/creditos',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEME'
            },
            {
                id: 'CLIENTE_FIND_ALL',
                url: '/customer/clientes',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMF'
            },
            {
                id: 'CLIENTE_FIND_ALL',
                url: '/customer/captacoes-rejeitadas',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMG'
            },
            {
                id: 'VISITA_FIND_ALL',
                url: '/customer/acompanhamento-visitas',
                type: 'item',
                title: 'CUSTOMERMANAGEMENT.ITEMH'
            }
            
        ]
    },
    {
        id: 'MENU_GESTAO_CONTRATOS',
        title: 'Gestão de Contratos',
        translate: 'CONTRACTMANAGEMENT.TITLE',
        icon: 'assignment',
        type: 'group',
        children: [
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/gerados',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMA'
            },
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/reagendados',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMF'
            },
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/liberados',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMB'
            },
            {
                id: 'CONTRATO_FIND_ALL',
                title: 'Contratos Digitais',
                translate: 'CONTRACTMANAGEMENT.ITEMH',
                type: 'collapsable',
                children: [
                    {
                        id: 'CONTRATO_FIND_ALL',
                        url: '/contracts/pix',
                        type: 'item',
                        title: 'CONTRACTMANAGEMENT.ITEMH'
                    },
                    {
                        id: 'CONTRATO_FIND_ALL',
                        url: '/contracts/pix/repassados',
                        type: 'item',
                        title: 'CONTRACTMANAGEMENT.ITEMK'
                    },
                ]
            },
            {
                id: 'CONTRATO_FIND_ALL',
                title: 'Contratos Boleto',
                translate: 'CONTRACTMANAGEMENT.ITEMH',
                type: 'collapsable',
                children: [
                    {
                        id: 'CONTRATO_FIND_ALL',
                        url: '/contracts/boleto',
                        type: 'item',
                        title: 'CONTRACTMANAGEMENT.ITEMH'
                    },
                    {
                        id: 'CONTRATO_FIND_ALL',
                        url: '/contracts/boleto/repassados',
                        type: 'item',
                        title: 'CONTRACTMANAGEMENT.ITEMK'
                    },
                ]
            },
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/em-andamento',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMC'
            },
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/cancelados',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMD'
            },
            {
                id: 'CONTRATO_FIND_ALL',
                url: '/contracts/a-cancelar',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEME'
            }
        ]
    },
    {
        id: 'MENU_GESTAO_FINANCEIRA',
        title: 'Gestão Financeira',
        translate: 'FiNANCEMANAGEMENT.TITLE',
        icon: 'monetization_on',
        type: 'group',
        children: [
            {
                id: 'MENU_GESTAO_FINANCEIRA_CAIXAS',
                title: 'Caixas',
                translate: 'FiNANCEMANAGEMENT.ITEMA.TITLE',
                type: 'collapsable',
                children: [
                    {
                        id: 'CAIXA_FIND_ALL',
                        url: '/financial/caixas-abertos',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMA'
                    },
                    {
                        id: 'CAIXA_COBRANCA_FIND_ALL',
                        url: '/financial/caixa-cobranca',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMB'
                    },
                    {
                        id: 'CAIXA_CONTROLE_FIND_ALL',
                        url: '/financial/caixa-controle',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMC'
                    },
                    {
                        id: 'CAIXA_DESPESA_FIND_ALL',
                        url: '/financial/caixa-despesa',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMD'
                    },
                    {
                        id: 'CAIXA_GERAL_FIND_ALL',
                        url: '/financial/caixa-geral',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMH'
                    },
                    {
                        id: 'CAIXA_PROVISAO_FIND_ALL',
                        url: '/financial/caixa-provisao',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMI'
                    },
                    {
                        id: 'CAIXA_REPASSE_FIND_ALL',
                        url: '/financial/caixa-repasse',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEMJ'
                    },
                    {
                        id: 'CAIXA_REPASSE_FIND_ALL',
                        url: '/financial/caixa-sangria',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMA.ITEML'
                    }
                ]
            },
            {
                id: 'MENU_GESTAO_FINANCEIRA_CAIXAS_FUNCIONARIOS',
                title: 'Caixas Funcionário',
                translate: 'FiNANCEMANAGEMENT.ITEMB.TITLE',
                type: 'collapsable',
                children: [
                    {
                        id: 'CAIXA_DIVIDA_FUNCIONARIO_FIND_ALL',
                        url: '/financial/caixa-divida-funcionario',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMB.ITEMA'
                    },
                    {
                        id: 'CAIXA_FIND_ALL_BY_FUNCIONARIO_ID' ,
                        url: '/financial/caixa-funcionario',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMB.ITEMB'
                    },
                    {
                        id: 'CAIXA_DESPESA_FUNCIONARIO_FIND_ALL',
                        url: '/financial/caixa-despesa-funcionario',
                        type: 'item',
                        title: 'FiNANCEMANAGEMENT.ITEMB.ITEMC'
                    }

                ]
            },
            {
                id: 'CAIXA_REPASSE_FIND_ALL',
                url: '/financial/repasses',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMP'
            },
            {
                id: 'ACOMPANHAMENTO_COBRANCA_FIND_ALL',
                url: '/financial/acompanhamento-recebimentos',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMD',
            },
            {
                id: 'PENDENCIA_FIND_ALL',
                url: '/financial/pendencias',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMF'
            },
            {
                id: 'LANCAMENTO_SAIDAS_FIND_ALL',
                url: '/financial/liberacoes-saidas',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMG'
            },
            {
                id: 'LANCAMENTO_ENTRADAS_FIND_ALL',
                url: '/financial/lancamentos-entradas',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMH'
            },

            {
                id: 'CONFIRMAR_RECEBIMENTO_FIND_ALL',
                url: '/financial/confirmar-recebimentos',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMI'
            },
            {
                id: 'CONFIRMACAO_PENDENCIAS_FIND_ALL',
                url: '/financial/confirmacao-pendencia',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMJ'
            },
            {
                id: 'ARQUIVO_RETORNO',
                url: '/financial/arquivo-retorno',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMK'
            },
            {
                id: 'ANALISE_SOBRA',
                url: '/financial/analise-sobra',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEML'
            },
            {
                id: 'CONFIRMACAO_CREDITO_SOBRA',
                url: '/financial/confirmar-credito-sobra',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMM'
            },
            {
                id: 'FALTA',
                url: '/financial/faltas',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMN'
            },
        ]
    },
    {
        id: 'MENU_RECURSOS_HUMANOS',
        title: 'Gestão Administrativa',
        translate: 'HUMANRESOURCES.TITLE',
        icon: 'work',
        type: 'group',
        children: [
            {
                id: 'FERIADO_FIND_ALL',
                url: '/global/feriados',
                type: 'item',
                title: 'HUMANRESOURCES.ITEMD',
            },
            // {
            //     id: 'CARGO_FIND_ALL',
            //     url: '/global/cargos',
            //     type: 'item',
            //     title: 'HUMANRESOURCES.ITEMG',
            // },
            {
                id: 'EQUIPE_FIND_ALL',
                url: '/global/postos',
                type: 'item',
                title: 'HUMANRESOURCES.ITEMH',
            },
            {
                id: 'EQUIPE_FIND_ALL',
                url: '/global/equipes',
                type: 'item',
                title: 'HUMANRESOURCES.ITEMI',
            },
            {
                id: 'FUNCIONARIO_FIND_ALL',
                url: '/global/funcionarios',
                type: 'item',
                title: 'HUMANRESOURCES.ITEMJ',
            },
            {
                id: 'FUNCIONARIO_FIND_ALL',
                url: '/global/rotas',
                type: 'item',
                title: 'HUMANRESOURCES.ITEMM',
            },

        ]
    },
    {
        id: 'MENU_SERVICOS_GLOBAIS',
        title: 'Serviços Globais',
        translate: 'GLOBALSERVICES.TITLE',
        icon: 'settings',
        type: 'group',
        children: [
            {
                id: 'USUARIO_FIND_ALL',
                title: 'Gestão de Usuários',
                translate: 'GLOBALSERVICES.ITEMA.TITLE',
                type: 'collapsable',
                children: [
                    {
                        id: 'USUARIO_FIND_ALL',
                        url: '/global/usuarios',
                        type: 'item',
                        title: 'GLOBALSERVICES.ITEMA.ITEMA',
                    },
                    {
                        id: 'PERFIL_FIND_ALL',
                        url: '/global/perfis',
                        type: 'item',
                        title: 'GLOBALSERVICES.ITEMA.ITEMB',
                    },
                    {
                        id: 'VEICULO_FIND_ALL',
                        url: '/global/veiculos',
                        type: 'item',
                        title: 'GLOBALSERVICES.ITEMA.ITEMC',
                    },

                ]
            },

            {
                id: 'ATIVIDADE_COMERCIAL_FIND_ALL',
                url: '/global/atividade-comercial',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMB',
            },
            {
                id: 'AREA_ATUACAO_FIND_ALL',
                url: '/global/areaatuacao',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMC',
            },
            {
                id: 'BAIRRO_FIND_ALL',
                url: '/global/bairros',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMD',
            },
            {
                id: 'PERMISSAO_FIND_ALL',
                url: '/global/empresas',
                type: 'item',
                title: 'GLOBALSERVICES.ITEME',
            },
            {
                id: 'AGENCIAS_FIND_ALL',
                url: '/financial/agencias',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMF',
            },
            {
                id: 'BANCOS_FIND_ALL',
                url: '/financial/bancos',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMG',
            },
            {
                id: 'CONTA_CORRENTE_FIND_ALL',
                url: '/financial/contas',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMH',
            },
            {
                id: 'MAQUINA_CARTAO_FIND_ALL',
                url: '/financial/maquina-cartao',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMI'
            },
            {
                id: 'PRODUTO_FIND_ALL',
                url: '/global/produtos',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMJ'
            },
            {
                id: 'MARCA_FIND_ALL',
                url: '/global/marcas',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMK'
            },
            {
                id: 'MODELO_FIND_ALL',
                url: '/global/modelos',
                type: 'item',
                title: 'GLOBALSERVICES.ITEML'
            },
            {
                id: 'MARCA_FIND_ALL',
                url: '/global/lancamento-agenda',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMM'
            },
            {
                id: 'MARCA_FIND_ALL',
                url: '/global/agenda',
                type: 'item',
                title: 'GLOBALSERVICES.ITEMN'
            },
        ]

    },
    {
        id: 'PROCESSAMENTOS_LACAMENTOS',
        title: 'Processamentos',
        translate: 'PROCESSAMENTOS.TITLE',
        icon: 'work',
        type: 'group',
        children: [
        ]
    },
    {
        id: 'MENU_GERACAO_RELATORIOS',
        title: 'Relatórios',
        translate: 'RELATORIOS.TITLE',
        icon: 'description',
        type: 'group',
        children: [

            {
                id: 'FOLHA_COBRANCA',
                url: '/financial/folha-cobranca',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMO'
            },
            {
                id: 'ACOMPANHAMENTO_NOVATOS_INATIVOS',
                url: '/contracts/acompanhamento-novatos-inativos',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMG'
            },
            {
                id: 'FOLHA_COBRANCA',
                url: '/financial/motivo-contrato-cancelado',
                type: 'item',
                title: 'FiNANCEMANAGEMENT.ITEMW'
            },
            {
                id: 'ACOMPANHAMENTO_ENTREGUES_NAO_ENTREGUES',
                url: '/contracts/acompanhamento-entregues-nao-entregues',
                type: 'item',
                title: 'CONTRACTMANAGEMENT.ITEMJ'
            }
        ]
    },
    {
        id: 'SUPER_USUARIO',
        title: 'Gerar Rotas',
        translate: 'ROTA.TITLE',
        icon: 'get_app',
        type: 'group',
        children: [
        ]
    },
    {
        id: 'MENU_PARAMETRO',
        title: 'Sistema',
        translate: 'PARAMETRO.TITLE',
        icon: 'view_headline',
        type: 'group',
        children: [
        ]
    }
];

