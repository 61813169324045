import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from '../../../../shared/model/contract/contract.model';
import { TeamService } from '../../../../global/components/teams/services/team.service';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Page } from 'app/main/shared/model/page/page.model';
import { SGF_TYPE } from 'app/shared/api/sgf.api';
import { MatDialog } from '@angular/material';
import { ReagendarContratoFormComponent } from 'app/main/shared/components/reagendar-contrato-form/reagendar-contrato-form.component';

@Component({
  selector: 'app-released-list',
  templateUrl: './released-list.component.html',
  styleUrls: ['./released-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ReleasedListComponent implements OnInit, OnChanges {

  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  fortEletro = this.funcionario.postoEletro;

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;
  @Input() rows: any[];
  @Input() page: Page;

  @Output() onSelected = new EventEmitter<any>();
  @Output() getTotalContractValue = new EventEmitter<any>();
  @Output() pageNumber = new EventEmitter<any>();
  @Output() vincularMaquinaEvent = new EventEmitter<any>();
  @Output() alterarProdutoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() visualizarImageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() reagendarContratoEvent: EventEmitter<any> = new EventEmitter<any>();

  selected: Array<Contract>;
  teams: any[] = [];
  timeout: any;
  @Input() selectedClear;

  interval;

  constructor(
    private contractsService: ContractsService
    ) {
    this.selected = new Array<Contract>();
  }


  ngOnInit() {
    this.getTotalValue();     
  }

  ngOnChanges(){
    this.getTotalValue(); 
    if(this.selectedClear){
      this.selected = new Array<Contract>();
    }
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectContract();
  }

  onSelectContract() {
    this.onSelected.emit(this.selected);
  }

  onPage(event):void{
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
    this.pageNumber.emit(event.offset+1)
  
  }
  generateMask(value: number){
    return value.toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' });
  }

  getTotalValue(){
    let totalValue:number = 0;
    this.rows.forEach(contract =>{
      totalValue = contract.visita.valorAprovado + totalValue;
    }) 
    this.getTotalContractValue.emit(totalValue);
  }

  getSupervisor(cobrador: any) {    
    let supervisor: any;
    let exists: boolean = false;

    // if (this.teams != null && cobrador != null) {
    //   this.teams.forEach(data => {
    //     data.listFuncionarios.forEach(func => {
    //       if (func.funcao == 'COBRADOR' && func.funcionario.pessoa.id == cobrador.pessoa.id) {
    //         exists = true;
    //       }

    //       if (exists && func.funcao == 'SUPERVISOR') {
    //         supervisor = func;
    //       }
    //     })
    //   })
    // }
    
    return supervisor ? supervisor.funcionario.pessoa.nome : '---';

  }

  getVendedor(cobrador: any) {
    let vendedor: any;
    let exists: boolean = false;

    if (this.teams != null && cobrador != null) {
      this.teams.forEach(data => {
        data.listFuncionarios.forEach(func => {
          if (func.funcao == 'COBRADOR' && func.funcionario.pessoa.id == cobrador.pessoa.id) {
            exists = true;
          }

          if (exists && func.funcao == 'VENDEDOR') {
            vendedor = func;
          }
        })
      })
    }
    
    return vendedor ? vendedor.funcionario.pessoa.nome : '---';

  }

  vincularMaquina(value){
    this.vincularMaquinaEvent.emit(value);
  }


  alterarProduto(value){
    this.alterarProdutoEvent.emit(value);
  }

  visualizarImagem(value): void {
    window.open('/contracts/liberados/galeria/' + value.contratoOrigem);
  }
  
  getId(row): any {
    return row.id;
  }

  getCor(value): any {
    if (value.diferencaDias) {
      if (value.diferencaDias > 1) {
        return {
          fontWeight: 'normal',
          color: 'red',
        };
      } else if ((value.diferencaDias = 1)) {
        return {
          fontWeight: 'normal',
          color: '#cdbb13',
        };
      }
    }
  }

  reagendarContrato(value): any {
    this.reagendarContratoEvent.emit(value);
  }

}
