import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VisitadosService } from '../../../customers/components/visitados/services/visitados.services';
import { VisitModel } from '../../model/visit/visit-model';
import { Telephone } from '../../model/telephone/telephone.model';
import { Address } from '../../model/address/address.model';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { SegmentsService } from 'app/main/global/components/segments/services/segments.service';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { GlobalService } from 'app/main/global/services/global.service';
import { SGF_API, SGF_TYPE } from 'app/shared/api/sgf.api';
import { PersonPf } from '../../model/person/person-pf.model';
import { AgendarVisitaComponent } from '../agendar-visita/agendar-visita.component';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { ReferenciasPixFormComponent } from '../referencias-pix-form/referencias-pix-form.component';

declare var google: any;


export interface DialogData {
  visit: VisitModel;
  action: string;
}

@Component({
  selector: 'app-visitados-full',
  templateUrl: './visitados-full.component.html',
  styleUrls: ['./visitados-full.component.scss'],
})
export class VisitadosFullComponent implements OnInit {
  @ViewChild(ReferenciasPixFormComponent) referenciasPixFormComponent: ReferenciasPixFormComponent;

  private static RENDA_MENSAL_PADRAO = 15000;
  private static OCUPACAO_PROFISSIONAL_PADRAO = 'Comerciante';

  visit: FormGroup;
  parecer: FormGroup;
  customer;
  listReferencia = [];
  segmentList: any[] = [];
  valorSugerido;
  limiteCreditoSugerido;
  cliente;

  banco = [];

  referenciaPessoal;
  referenciaComercial;
  referenciaBancaria;
  referenciaFiador;
  referenciaPolitica;
  dialogMessages;
  disabledButtonFinalizar: boolean;
  disabledButtonCliente: boolean;
  referenciaPixFormInvalido: boolean = false;
  isCLientePostoPix: boolean = false;
  dataNascimento: any;
  listReferenciaPessoal: any;
  loggedEmployee: Employee;
  funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
  previousIsCpfField: boolean;
  public loading = false;

  galeria = '/customer/visitados/galeria/' + this._data.visit.id;

  // tslint:disable-next-line:triple-equals
  fortCartao = `${SGF_TYPE}` == 'FORTALEZA-CARTAO' ? true : false;
  // tslint:disable-next-line:triple-equals
  dev = `${SGF_TYPE}` == 'DEV' ? true : false;

  // valida cpf e cnpj
  cpfCnpj = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;
  novaVisita = true;

  imagens: any[];

  constructor(
    public matDialogRef: MatDialogRef<VisitadosFullComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private segmentService: SegmentsService,
    private visitadosService: VisitadosService,
    private globalService: GlobalService,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData,
    private utilsService: UtilsService) {
    this.visit = this.buildCustomerFormGroup(this._data.visit);
    this.parecer = this.buildParecerFormGroup(this._data.visit);
    this.customer = this.visit.get('pessoa');
    this.cliente = this._data.visit.cliente;
    this.isCLientePostoPix = this.cliente.posto.parametroPosto.pixBoleto;

    this.previousIsCpfField = this.visit.get('pessoa').get('isCpf').value;
    this.visit.get('pessoa').get('isCpf').valueChanges.subscribe(this.onChangePessoaTypeField.bind(this));

    this.segmentService.findAll().subscribe(result => {
      this.segmentList = result.object.content;
    });
  }

  ngOnInit(): void {
    if (this.funcionario.idFuncionario > 0) {
      // tslint:disable-next-line:radix
      this.employeeService.findById(parseInt(this.funcionario.idFuncionario)).subscribe(result => {
        this.loggedEmployee = result.object;
        this._data.visit.atendente = result.object;
      });
    }
  }

  onChangePessoaTypeField(isCpf: boolean): void {
    // tslint:disable-next-line:triple-equals
    if (this.previousIsCpfField != isCpf) {
      this.previousIsCpfField = isCpf;
      const pessoaForm = <FormGroup>this.visit.get('pessoa');
      if (pessoaForm) {
        const pessoa = this._data.visit.cliente.pessoa;
        pessoaForm.get('cpf').setValidators(isCpf ? Validators.required : null);
        pessoaForm.get('cnpj').setValidators(!isCpf ? Validators.required : null);
        pessoaForm.setControl('listSocio', this.buildSocio(pessoa.listSocio ? pessoa.listSocio : [new PersonPf()], isCpf)),
        this.visit.updateValueAndValidity();
      }
    }
  }

  buildParecerFormGroup(visit: VisitModel): FormGroup {
    return this.formBuilder.group({
      limiteCreditoSugerido: [visit.limiteCreditoSugerido],
      valorSugerido: [visit.valorSugerido],
      parecerVisita: [visit.parecerVisita],
      pagamentoBoleto: [visit.pagamentoBoleto],
      pagamentoCartao: [visit.pagamentoCartao],
      pagamentoDinheiro: [visit.pagamentoDinheiro],
      pagamentoPix: [visit.pagamentoPix],
      quantidadeParcelas: [this.validarQuantidadeParcela(visit), Validators.required],
      tipoContrato: [visit.tipoContrato, Validators.required],
      produto: [visit.produto ? visit.produto.id : null],
      // tslint:disable-next-line:triple-equals
      isEletro: [visit.tipoContrato ? (visit.tipoContrato == 'ELETRO' ? true : false) : false || null],
      dataVencimento: [visit.dataVencimento ? new Date(visit.dataVencimento) : null],
      taxaJuros: [visit.taxaJuros ? visit.taxaJuros : null],
      diasAntecipacao: [visit.diasAntecipacao ? visit.diasAntecipacao : null],
      antecipacao: [visit.antecipacao ? 'true' : 'false'],
      periodicidadeParcela: [visit.periodicidadeParcela ? visit.periodicidadeParcela : 'DIARIA'],
      taxaJurosMora: [visit.taxaJurosMora ? visit.taxaJurosMora : null],
      modalidadeEstabelecimento: [visit.modalidadeEstabelecimento ? visit.modalidadeEstabelecimento : null, Validators.required],
      cnae: [visit.cnae ? visit.cnae.id : null],
      rota: [visit.rota ? visit.rota.id : null, Validators.required],
      dataInicioAtividade: [visit.dataInicioAtividade ? new Date(visit.dataInicioAtividade) : null, Validators.required],
    });
  }

  buildCustomerFormGroup(visit: VisitModel): FormGroup {
    return this.formBuilder.group({
      atividadeComercial: [visit.cliente.atividadeComercial ? visit.cliente.atividadeComercial.descricao : null, Validators.required],
      cnae: [visit.cnae ? visit.cnae.descricao : null],
      rota: [visit.rota ? visit.rota.descricao : null, Validators.required],
      listReferencia: this.referencia(visit),
      referenciaPessoal: this.buildReferenciaPessoal(this.listReferenciaPessoal),
      periodicidadeParcela: [visit.periodicidadeParcela ? visit.periodicidadeParcela : 'DIARIA'],
      pessoa: this.buildPessoa(visit),
    });
  }

  buildPessoa(ref): FormGroup {
    return this.formBuilder.group({
      nome: [ref.cliente.pessoa.nome],
      // tslint:disable-next-line:triple-equals
      nome_social: [ref.cliente.pessoa.nomePessoa, ref.cliente.pessoa.type == 'pj' ? Validators.required : ''],
      isCpf: [ref.cliente.pessoa.cpf ? true : false],
      // tslint:disable-next-line:triple-equals
      cpf: [ref.cliente.pessoa.cpf, ref.cliente.pessoa.type == 'pf' ? Validators.required : ''],
      // tslint:disable-next-line:triple-equals
      cnpj: [ref.cliente.pessoa.cnpj, ref.cliente.pessoa.type == 'pj' ? Validators.required : ''],
      razaoSocial: [ref.cliente.pessoa.razaoSocial, ref.cliente.pessoa.type === 'pj' ? Validators.required : ''],
      nomeFantasia: [ref.cliente.pessoa.nomeFantasia, ref.cliente.pessoa.type === 'pj' ? Validators.required : ''],
      // tslint:disable-next-line:triple-equals
      rg: [ref.cliente.pessoa.rg,  ref.cliente.pessoa.tipoDocumentoEnum == 'RG'  ? Validators.required : null],
      // tslint:disable-next-line:triple-equals
      rgOrgaoEmissor: [ref.cliente.pessoa.rgOrgaoEmissor, ref.cliente.pessoa.tipoDocumentoEnum == 'RG'  ? Validators.required : null],
      // tslint:disable-next-line:triple-equals
      rgUf: [ref.cliente.pessoa.rgUf, ref.cliente.pessoa.tipoDocumentoEnum == 'RG'  ? Validators.required : null],
      // tslint:disable-next-line:triple-equals
      rgDataEmissao: [ref.cliente.pessoa.rgDataEmissao ? new Date(ref.cliente.pessoa.rgDataEmissao) : null, ref.cliente.pessoa.tipoDocumentoEnum == 'RG'  ? Validators.required : null],

      formacaoAcademica: [ref.cliente.pessoa.formacaoAcademica],
      ocupacaoProfissional: [ref.cliente.pessoa.ocupacaoProfissional ? ref.cliente.pessoa.ocupacaoProfissional : VisitadosFullComponent.OCUPACAO_PROFISSIONAL_PADRAO],
      entidadeTrabalho: [ref.cliente.pessoa.entidadeTrabalho],

      rendaMensal: [ref.cliente.pessoa.rendaMensal ? ref.cliente.pessoa.rendaMensal : VisitadosFullComponent.RENDA_MENSAL_PADRAO ],
      bensMoveis: [ref.cliente.pessoa.bensMoveis],
      bensImoveis: [ref.cliente.pessoa.bensImoveis],
      aplicacoesFinanceiras: [ref.cliente.pessoa.aplicacoesFinanceiras],
      outrosRendimentos: [ref.cliente.pessoa.outrosRendimentos],

      nomeProcurador: [ref.cliente.pessoa.nomeProcurador],
      documentoProcurador: [ref.cliente.pessoa.documentoProcurador],
      cpfProcurador: [ref.cliente.pessoa.cpfProcurador],
      emailProcurador: [ref.cliente.pessoa.emailProcurador],
      ctps: [ref.cliente.pessoa.ctps,  ref.cliente.pessoa.tipoDocumentoEnum == 'CTPS' ? Validators.required : ''],
      ctpsDataEmissao: [ref.cliente.pessoa.ctpsDataEmissao ? new Date(ref.cliente.pessoa.ctpsDataEmissao) : null,
        ref.cliente.pessoa.tipoDocumentoEnum == 'CTPS'  ? Validators.required : null],
      cnh: [ref.cliente.pessoa.cnh,  ref.cliente.pessoa.tipoDocumentoEnum == 'CNH'  ? Validators.required : null],
      dataVencimentoCnh: [ref.cliente.pessoa.dataVencimentoCnh ? new Date(ref.cliente.pessoa.dataVencimentoCnh) : null,
        ref.cliente.pessoa.tipoDocumentoEnum == 'CNH'  ? Validators.required : null],
      tipoDocumentoEnum: [ref.cliente.pessoa.tipoDocumentoEnum, ref.cliente.pessoa.type === 'pf' ? Validators.required : ''],
      nomeMae: [ref.cliente.pessoa.nomeMae],
      nomePai: [ref.cliente.pessoa.nomePai],
      naturalidade: [ref.cliente.pessoa.naturalidade],
      // tslint:disable-next-line:triple-equals
      sexo: [ref.cliente.pessoa.sexo, ref.cliente.pessoa.type == 'pf' ? Validators.required : ''],
      // tslint:disable-next-line:triple-equals
      estadoCivil: [ref.cliente.pessoa.estadoCivil, ref.cliente.pessoa.type == 'pf' ? Validators.required : ''],
      // tslint:disable-next-line:triple-equals
      dataNascimento: [ref.cliente.pessoa.dataNascimento ? new Date(ref.cliente.pessoa.dataNascimento) : null, ref.cliente.pessoa.type == 'pf' ? Validators.required : ''],
      alfabetizado: [ref.alfabetizado],
      type: [ref.cliente.pessoa.type],
      numeroCartao: [ref.cliente.numeroCartao ? ref.cliente.numeroCartao : ''],
      vencimentoCartao: [ref.cliente.vencimentoCartao ? ref.cliente.vencimentoCartao : ''],

      dataFundacao: [new Date(ref.cliente.pessoa.dataFundacao)],
      paisOrigem: [ref.cliente.pessoa.paisOrigem],
      municipioAprovacao: [ref.cliente.pessoa.municipioAprovacao],
      formaConstituicao: [ref.cliente.pessoa.formaConstituicao],
      atividadePrincipal: [ref.cliente.pessoa.atividadePrincipal],
      faturamentoConsolidado: [ref.cliente.pessoa.faturamentoConsolidado],
      documentoBeneficiario: [ref.cliente.pessoa.documentoBeneficiario],

      email: [ref.cliente.pessoa.email],
      nomeConjuge: [ref.cliente.nomeConjuge ? ref.cliente.nomeConjuge : ''],
      cpfConjuge: [ref.cliente.cpfConjuge ? ref.cliente.cpfConjuge : ''],

      listTelefone: this.buildPhone(ref.cliente.pessoa.listTelefone, true),
      listEndereco: this.buildAddress(ref.cliente.pessoa.listEndereco, false),
      listContaBancaria: this.buildContaBancaria(ref.cliente.pessoa.listContaBancaria),
      listSocio: this.buildSocio(ref.cliente.pessoa.listSocio ? ref.cliente.pessoa.listSocio : [new PersonPf()], ref.cliente.pessoa.cpf),
    });
  }

  buildContaBancaria(listContaBancaria): FormArray {
    const registrosPixMapper = (registro) => this.formBuilder.group({
      id: registro.id,
      chavePix: registro.chavePix,
      tipoChavePix: registro.tipoChavePix
    });

    const contaBancariaMapper = (conta) => this.formBuilder.group({
      id: conta.id,
      numero: conta.numero,
      agencia: conta.agencia,
      banco: conta.banco,
      tipo: conta.tipo,
      favorecido: conta.favorecido,
      tipoDocumento: conta.tipoDocumento,
      cpf: conta.cpf,
      cnpj: conta.cnpj,
      principal: conta.principal,
      registrosPix: this.formBuilder.array(conta.registrosPix.map(registrosPixMapper))
    });

    return this.formBuilder.array(listContaBancaria.map(contaBancariaMapper));
  }

  buildSocio(listSocio, isRequired: boolean): FormArray {
    const socio = listSocio.map(a => {
      return this.formBuilder.group({
        // tslint:disable-next-line:triple-equals
        nome: new FormControl(a ? a.nome : null, isRequired == false ? Validators.required : null),
        id: new FormControl(a ? a.id : null),
        sexo: new FormControl(a ? a.sexo : null),
        participacaoCapital: new FormControl(a ? a.participacaoCapital : null),
        dataEntrada: new FormControl(a ? new Date(a.dataEntrada ? a.dataEntrada : '') : null),
        dataNascimento: new FormControl(a ? new Date(a.dataNascimento ? a.dataNascimento : '') : null),
        // tslint:disable-next-line:triple-equals
        cpf: new FormControl(a ? a.cpf : null, isRequired == false ? Validators.required : null),
        // tslint:disable-next-line:triple-equals
        rg: new FormControl(a ? a.rg : null, isRequired == false ? Validators.required : null),
        estadoCivil: new FormControl(a ? a.estadoCivil : null),
        // tslint:disable-next-line:triple-equals
        listTelefone: this.buildPhone(a ? a.listTelefone : [], isRequired == false ? true : null),
        // tslint:disable-next-line:triple-equals
        listEndereco: this.buildAddress(a ? a.listEndereco : [], isRequired == false ? true : null),
        type: 'pf',
      });
    });
    return this.formBuilder.array(socio);
  }

  buildReferenciaPessoal(listReferencia): FormArray {
    const referenciasPessoais = [];
    this._data.visit.cliente.listReferencia.forEach(r => {
      // tslint:disable-next-line:triple-equals
      if (r.type == 'rp') {
        referenciasPessoais.push(r);
      }
    });
    this.listReferenciaPessoal = referenciasPessoais;

    const referencia = this.listReferenciaPessoal.map(a => {
      return this.formBuilder.group({
        id: new FormControl(this.listReferencia ? a.id : null),
        nome: new FormControl(this.listReferencia ? a.nome : null, Validators.required),
        situacao: new FormControl(this.listReferencia ? a.situacao : null, Validators.required),
        type: new FormControl(this.listReferencia ? a.type : null),
        listTelefone: new FormControl(this.listReferencia ? this.buildPhone(a.listTelefone, true) : this.buildPhone([], true)),
      });
    });
    return this.formBuilder.array(referencia);
  }

  referencia(visit: VisitModel): FormGroup {
    visit.cliente.listReferencia.forEach(element => {
      // tslint:disable-next-line:triple-equals
      if (element.type == 'rc') {
        this.referenciaComercial = element;
        // tslint:disable-next-line:triple-equals
      } else if (element.type == 'rb') {
        this.referenciaBancaria = element;
        // tslint:disable-next-line:triple-equals
      } else if (element.type == 'fi') {
        this.referenciaFiador = element;
      } else if (element.type == 'pp') {
        this.referenciaPolitica = element;
      }
    });

    return this.formBuilder.group({
      referenciaComercial: this.buildContactComercial(this.referenciaComercial || ''),
      referenciaBancaria: this.buildBanking(this.referenciaBancaria || ''),
      referenciaFiador: this.buildFiador(this.referenciaFiador || ''),
      referenciaPolitica: this.buildReferenciaPolitica(this.referenciaPolitica || ''),
    });
  }

  buildContactComercial(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id],
      type: ['rc'],
      nome: [ref.nome],
      listTelefone: this.buildPhone(ref.listTelefone || [''], false),
      situacao: [ref.situacao],
    });
  }

  buildBanking(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id],
      type: ['rb'],
      nome: [ref.nome],
      listTelefone: this.buildPhone(ref.listTelefone || [''], false),
      credito: [ref.credito],
    });
  }

  buildReferenciaPolitica(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id],
      type: ['pp'],
      expostaPoliticamente: [ref.expostaPoliticamente],
      nomePrimeiro: [ref.nomePrimeiro],
      cpfPrimeiro: [ref.cpfPrimeiro],
      grauRelacionamentoPrimeiro: [ref.grauRelacionamentoPrimeiro],
      cargoOrgaoPrimeiro: [ref.cargoOrgaoPrimeiro],
      nomeSegundo: [ref.nomeSegundo],
      cpfSegundo: [ref.cpfSegundo],
      grauRelacionamentoSegundo: [ref.grauRelacionamentoSegundo],
      cargoOrgaoSegundo: [ref.cargoOrgaoSegundo],
      listEndereco: this.buildAddress(ref.listEndereco || [''], false),
    });
  }

  buildFiador(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id],
      type: ['fi'],
      nomeFiador: [ref.nomeFiador],
      cpfFiador: [ref.cpfFiador],
      listEndereco: this.buildAddress(ref.listEndereco || [''], false),
      nomeConjuge: [ref.nomeConjuge],
      cpfConjuge: [ref.cpfConjuge],
      numeroCartao: [ref.numeroCartao],
      vencimentoCartao: [ref.vencimentoCartao],
    });
  }

  buildPhone(telefone: Telephone[], isRequired?: boolean): FormArray {
    const phone = telefone.map(tel => {
      return this.formBuilder.group({
        ativo: new FormControl(tel.ativo || true),
        numero: new FormControl(tel.numero || null, isRequired ? Validators.required : null),
        id: new FormControl(tel.id),
        tipoSmartphone: new FormControl(tel.tipoSmartphone),
        possuiInternet: new FormControl(tel.possuiInternet),
        instalarAppCliente: new FormControl(tel.instalarAppCliente),
      });
    });
    return this.formBuilder.array(phone);
  }

  get phoneForms(): FormArray {
    return this.visit.get('pessoa.listTelefone') as FormArray;
  }

  get socioForms(): FormArray {
    return this.visit.get('pessoa').get('listSocio') as FormArray;
  }

  buildAddress(endereco: Address[], isRequired?: boolean): FormArray {
    const address = endereco.map(end => {
      return this.formBuilder.group({
        id: new FormControl(end.id),
        cep: new FormControl(end.cep, isRequired ? Validators.required : null),
        logradouro: new FormControl(end.logradouro, isRequired ? Validators.required : null),
        numero: new FormControl(end.numero, isRequired ? Validators.required : null),
        bairro: new FormControl(end.bairro ? end.bairro.descricao : null),
        municipio: new FormControl(end.municipio ? end.municipio.nomeMunicipio : null),
        complemento: new FormControl(end.complemento),
        estado: new FormControl(end.estado ? end.estado.sigla : null),
        pontoReferencia: new FormControl(end.pontoReferencia || null),
        tipoEndereco: new FormControl(end.tipoEndereco || null),
      });
    });

    return this.formBuilder.array(address);
  }

  private validarQuantidadeParcela(visita: any) {
    if (visita.quantidadeParcelas && visita.contrato2Parcelas) {
      return 2;
    }else if (visita.quantidadeParcelas) {
      return visita.quantidadeParcelas;
    } else {
      return null
    }
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  onSave(value): void {

    const UF = '';
    const listReferencia = [];

    delete value.pessoa.isCpf;

    this.validaNumeroCliente(value.pessoa.listTelefone, value.pessoa.nome);

    // tslint:disable-next-line:no-shadowed-variable
    value.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    if (value.pessoa.type === 'pj') {
      value.pessoa.listSocio.forEach(s => {
        // tslint:disable-next-line:no-shadowed-variable
        s.listEndereco.forEach(data => {
          if (data.bairro != null) {
            if (typeof data.bairro === 'object') {
              data.municipio = data.bairro.municipio;
              data.estado = data.bairro.municipio.estado;
            } else {
              this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
                const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
                data.bairro = bairro;
                data.municipio = bairro.municipio;
                data.estado = bairro.municipio.estado;
              });
            }
          }
        });
      });
    }

    this.segmentList.forEach(att => {
      if (att.descricao === value.atividadeComercial) {
        this._data.visit.cliente.atividadeComercial = att;
      }
    });

    value.referenciaPessoal.forEach(r => {
      if (r.listTelefone != null) {
        r.listTelefone = r.listTelefone.value;
        listReferencia.push(r);
      }
    });

    listReferencia.push(value.listReferencia.referenciaComercial);
    listReferencia.push(value.listReferencia.referenciaBancaria);

    value.listReferencia.referenciaFiador.listEndereco.forEach(end => {
      if (end.bairro != null) {
        if (typeof end.bairro === 'object') {
          end.municipio = end.bairro.municipio;
          end.estado = end.bairro.municipio.estado;
        }else{
          this.globalService.bairroByDescricao(end.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === end.municipio)[0];
            end.bairro = bairro;
            end.municipio = bairro.municipio;
            end.estado = bairro.municipio.estado;
          });
        }
      }
    })

    listReferencia.push(value.listReferencia.referenciaFiador);
    listReferencia.push(value.listReferencia.referenciaPolitica);
    this._data.visit.cliente.listReferencia = listReferencia;

    const cliente = this._data.visit.cliente;

    cliente.pessoa.listEndereco = value.pessoa.listEndereco;
    cliente.pessoa.listTelefone = value.pessoa.listTelefone;
    cliente.pessoa.nome = value.pessoa.nome;
    cliente.pessoa.dataNascimento = value.pessoa.dataNascimento;
    cliente.pessoa.razaoSocial = value.pessoa.razaoSocial;
    cliente.pessoa.nomeFantasia = value.pessoa.nomeFantasia;
    cliente.pessoa.rg = value.pessoa.rg;
    cliente.pessoa.rgOrgaoEmissor = value.pessoa.rgOrgaoEmissor;
    cliente.pessoa.rgUf = value.pessoa.rgUf;
    cliente.pessoa.rgDataEmissao = value.pessoa.rgDataEmissao;
    cliente.pessoa.ocupacaoProfissional = value.pessoa.ocupacaoProfissional;
    cliente.pessoa.formacaoAcademica = value.pessoa.formacaoAcademica;
    cliente.pessoa.entidadeTrabalho = value.pessoa.entidadeTrabalho;
    cliente.pessoa.faturamentoConsolidado = value.pessoa.faturamentoConsolidado;
    cliente.pessoa.rendaMensal = value.pessoa.rendaMensal;
    cliente.pessoa.bensMoveis = value.pessoa.bensMoveis;
    cliente.pessoa.bensImoveis = value.pessoa.bensImoveis;
    cliente.pessoa.aplicacoesFinanceiras = value.pessoa.aplicacoesFinanceiras;
    cliente.pessoa.outrosRendimentos = value.pessoa.outrosRendimentos;
    cliente.pessoa.documentoBeneficiario = value.pessoa.documentoBeneficiario;
    cliente.pessoa.nomeMae = value.pessoa.nomeMae;
    cliente.pessoa.nomePai = value.pessoa.nomePai;
    cliente.pessoa.naturalidade = value.pessoa.naturalidade;
    cliente.pessoa.sexo = value.pessoa.sexo;
    cliente.pessoa.estadoCivil = value.pessoa.estadoCivil;
    cliente.numeroCartao = value.pessoa.numeroCartao;
    cliente.vencimentoCartao = value.pessoa.vencimentoCartao;
    cliente.pessoa.listContaBancaria = value.pessoa.listContaBancaria;
    cliente.pessoa.nomeProcurador = value.pessoa.nomeProcurador;
    cliente.pessoa.documentoProcurador = value.pessoa.documentoProcurador;
    cliente.pessoa.cpfProcurador = value.pessoa.cpfProcurador;
    cliente.pessoa.emailProcurador = value.pessoa.emailProcurador;
    cliente.pessoa.cnh = value.pessoa.cnh;
    cliente.pessoa.dataVencimentoCnh = value.pessoa.dataVencimentoCnh;
    cliente.pessoa.ctps = value.pessoa.ctps;
    cliente.pessoa.ctpsDataEmissao = value.pessoa.ctpsDataEmissao;
    cliente.pessoa.tipoDocumentoEnum = value.pessoa.tipoDocumentoEnum;
    cliente.pessoa.dataFundacao = value.pessoa.dataFundacao;
    cliente.pessoa.paisOrigem = value.pessoa.paisOrigem;
    cliente.pessoa.municipioAprovacao = value.pessoa.municipioAprovacao;
    cliente.pessoa.formaConstituicao = value.pessoa.formaConstituicao;
    cliente.pessoa.atividadePrincipal = value.pessoa.atividadePrincipal;
    cliente.pessoa.email = value.pessoa.email;
    cliente.nomeConjuge = value.pessoa.nomeConjuge;
    cliente.cpfConjuge = value.pessoa.cpfConjuge;

    this.handleListContaBancaria(cliente);

    setTimeout(() => {
      if (cliente.pessoa.type === 'pj') {
        cliente.pessoa.listSocio = value.pessoa.listSocio;
      }
      this.disabledButtonCliente = true;

      this.visitadosService.updateCliente(cliente).subscribe(
        (ondata: ReturnApi) => {
          if (ondata.success) {
            this.matDialogRef.close(ondata.object);
            this.dialogMessages = {
              type: 'success',
              title: 'Sucesso!',
              text: `${ondata.messages[0].text}`,
            };
            this.feedback(this.dialogMessages);
            // this.matDialogRef.close(ondata.object);

          } else {
            if (ondata.messages[0].text != null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `${ondata.messages[0].text}`,
              };
            } else {
              this.dialogMessages = {
                type: 'error',
                title: 'Error!',
              };
            }
            this.feedback(this.dialogMessages);

            // this.matDialogRef.close();
          }
        },
        (onerror) => {
          console.log('UPDATE NO CLIENTE ERROR', onerror);
        },
      );

    }, 2500);

  }

  private feedback(message: string[]): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  openGallery(): void {
    window.open(this.galeria, '_blank');
  }

  finalizar(value): void {

    let UF = '';
    let municipio: any;
    const listReferencia = [];

    this.loading = true;

    value.pessoa.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
      this.googleMaps(element);
    });


    delete value.pessoa.isCpf;

    this.validaNumeroCliente(value.pessoa.listTelefone, value.pessoa.nome);

    // tslint:disable-next-line:no-shadowed-variable
    value.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    if (value.pessoa.type === 'pj') {
      value.pessoa.listSocio.forEach(s => {
        // tslint:disable-next-line:no-shadowed-variable
        s.listEndereco.forEach(data => {
          if (data.bairro != null) {
            if (typeof data.bairro === 'object') {
              data.municipio = data.bairro.municipio;
              data.estado = data.bairro.municipio.estado;
            } else {
              this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
                const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
                data.bairro = bairro;
                data.municipio = bairro.municipio;
                data.estado = bairro.municipio.estado;
              });
            }
          }
        });
      });
    }

    this.segmentList.forEach(att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == value.atividadeComercial) {
        this._data.visit.cliente.atividadeComercial = att;
      }
    });


    if (value.referenciaPessoal != null) {
      value.referenciaPessoal.forEach(referencia => {
        if (referencia.listTelefone != null && referencia.listTelefone.value) {
          referencia.listTelefone.value.forEach(telefone => {
            if (telefone.numero == null) {
              this.dialogMessages = {
                type: 'info',
                title: 'Informação!',
                text: `Contato da Referencia Pessoa: ${referencia.nome} é obrigatório!`,
              };
              this.disabledButtonFinalizar = false;
              this.loading = false;
              this.feedback(this.dialogMessages);
              throw new Error(`Contato da Referencia Pessoa: ${referencia.nome} é obrigatório!`);
            }
          });
        }
      });
    }

    value.referenciaPessoal.forEach(r => {
      if (r.listTelefone != null) {
        const listaDeTelefones = r.listTelefone.value;
        if (Array.isArray(listaDeTelefones)) {
          r.listTelefone = listaDeTelefones;
        }
        listReferencia.push(r);
      }
    });

    listReferencia.push(value.listReferencia.referenciaComercial);
    listReferencia.push(value.listReferencia.referenciaBancaria);

    // tslint:disable-next-line:no-shadowed-variable
    value.listReferencia.referenciaFiador.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    listReferencia.push(value.listReferencia.referenciaFiador);
    listReferencia.push(value.listReferencia.referenciaPolitica);

    this._data.visit.cliente.listReferencia = listReferencia;

    value.atendente = this.loggedEmployee;
    const cliente = this._data.visit.cliente;


    cliente.pessoa.listEndereco = value.pessoa.listEndereco;
    cliente.pessoa.listTelefone = value.pessoa.listTelefone;
    cliente.pessoa.nome = value.pessoa.nome;
    cliente.pessoa.dataNascimento = value.pessoa.dataNascimento;

    cliente.pessoa.razaoSocial = value.pessoa.razaoSocial;
    cliente.pessoa.nomeFantasia = value.pessoa.nomeFantasia;

    cliente.pessoa.rg = value.pessoa.rg;
    cliente.pessoa.rgOrgaoEmissor = value.pessoa.rgOrgaoEmissor;
    cliente.pessoa.rgUf = value.pessoa.rgUf;
    cliente.pessoa.rgDataEmissao = value.pessoa.rgDataEmissao;

    cliente.pessoa.ocupacaoProfissional = value.pessoa.ocupacaoProfissional;
    cliente.pessoa.formacaoAcademica = value.pessoa.formacaoAcademica;
    cliente.pessoa.entidadeTrabalho = value.pessoa.entidadeTrabalho;

    cliente.pessoa.faturamentoConsolidado = value.pessoa.faturamentoConsolidado;
    cliente.pessoa.rendaMensal = value.pessoa.rendaMensal;
    cliente.pessoa.bensMoveis = value.pessoa.bensMoveis;
    cliente.pessoa.bensImoveis = value.pessoa.bensImoveis;
    cliente.pessoa.aplicacoesFinanceiras = value.pessoa.aplicacoesFinanceiras;
    cliente.pessoa.outrosRendimentos = value.pessoa.outrosRendimentos;

    cliente.pessoa.nomeMae = value.pessoa.nomeMae;
    cliente.pessoa.nomePai = value.pessoa.nomePai;

    cliente.pessoa.naturalidade = value.pessoa.naturalidade;
    cliente.pessoa.documentoBeneficiario = value.pessoa.documentoBeneficiario;

    cliente.pessoa.sexo = value.pessoa.sexo;
    cliente.pessoa.estadoCivil = value.pessoa.estadoCivil;
    cliente.numeroCartao = value.pessoa.numeroCartao;
    cliente.vencimentoCartao = value.pessoa.vencimentoCartao;
    cliente.pessoa.listContaBancaria = value.pessoa.listContaBancaria;

    cliente.pessoa.nomeProcurador = value.pessoa.nomeProcurador;
    cliente.pessoa.documentoProcurador = value.pessoa.documentoProcurador;
    cliente.pessoa.cpfProcurador = value.pessoa.cpfProcurador;
    cliente.pessoa.emailProcurador = value.pessoa.emailProcurador;
    cliente.pessoa.cnh = value.pessoa.cnh;
    cliente.pessoa.dataVencimentoCnh = value.pessoa.dataVencimentoCnh;
    cliente.pessoa.ctps = value.pessoa.ctps;
    cliente.pessoa.ctpsDataEmissao = value.pessoa.ctpsDataEmissao;
    cliente.pessoa.tipoDocumentoEnum = value.pessoa.tipoDocumentoEnum;
    cliente.pessoa.dataFundacao = value.pessoa.dataFundacao;
    cliente.pessoa.paisOrigem = value.pessoa.paisOrigem;
    cliente.pessoa.municipioAprovacao = value.pessoa.municipioAprovacao;
    cliente.pessoa.formaConstituicao = value.pessoa.formaConstituicao;
    cliente.pessoa.atividadePrincipal = value.pessoa.atividadePrincipal;
    cliente.nomeConjuge = value.pessoa.nomeConjuge;
    cliente.cpfConjuge = value.pessoa.cpfConjuge;

    this.handleListContaBancaria(cliente);

    this._data.visit.periodicidadeParcela = value.periodicidadeParcela;
    const visita = Object.assign(this._data.visit, value);

    const quantidadeRef = this.parecer.get('quantidadeParcelas');
    const periodicidadeRef = this.parecer.get('periodicidadeParcela');
    const pagamentoBoleto = this.parecer.get('pagamentoBoleto');
    const pagamentoCartao = this.parecer.get('pagamentoCartao');
    const pagamentoDinheiro = this.parecer.get('pagamentoDinheiro');
    const pagamentoPix = this.parecer.get('pagamentoPix');
    
    visita.quantidadeParcelas = quantidadeRef.value ? quantidadeRef.value : visita.quantidadeParcelas;
    visita.periodicidadeParcela = periodicidadeRef.value ? periodicidadeRef.value : visita.periodicidadeParcela;
    visita.pagamentoBoleto = pagamentoBoleto.value;
    visita.pagamentoCartao = pagamentoCartao.value;
    visita.pagamentoDinheiro = pagamentoDinheiro.value;
    visita.pagamentoPix = pagamentoPix.value;

    visita.contrato2Parcelas = visita.quantidadeParcelas == 2 ? true : false;

    this.disabledButtonFinalizar = true;

    setTimeout(() => {
      if (cliente.pessoa.type === 'pj') {
        cliente.pessoa.listSocio = value.pessoa.listSocio;
      }
      this.visitadosService.updateCliente(cliente).subscribe(
        (ondata: ReturnApi) => {
          if (ondata.success) {
            this.visitadosService.updateVisit(visita).subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              (ondata: ReturnApi) => {
                if (ondata.success) {

                  // REFERENCIAS PIX SÃO SALVAS APÓS FINALIZAÇÃO 
                  // PARA SALVAR UNIDADE DE CLIENTE MAIS ATUALIZADA POSSÍVEL DENTRO DA VISITA
                  // EM CASO DE ERRO NÃO EXISTE ROLL BACK, POIS CAMPOS SÃO REVISITADOS APÓS LIBERAÇÃO DE CONTARTO
                  // OBS: HÁ MENSAGEM AMIGÁVEL PARA CORREÇÃO EM LIBERAÇÀO DE CONTRATO
                  this.referenciasPixFormComponent.saveReferencias().subscribe(
                    (response) => {
                      if (response.success) {
                        this.dialogMessages = {
                          type: 'success',
                          title: 'Sucesso!',
                          text: `Referencias Pix Atualizadas com Sucesso!`,
                        };
                        this.feedback(this.dialogMessages);
                      } else {
                        this.utilsService.feedbackError(
                          'Ocorreu um Erro ao Atualizar as Referencias Pix, \n Referencias poderão ser atualizadas na liberação de contrato!', 
                          TimeMensagem.TIME_MENSAGEM
                        );
                      }
                    }
                  );

                  this.matDialogRef.close(ondata);

                  this.dialogMessages = {
                    type: 'success',
                    title: 'Sucesso!',
                    text: `${ondata.messages[0].text}`,
                  };
                  this.feedback(this.dialogMessages);
                  this.matDialogRef.close(ondata.object);

                } else {
                  if (ondata.messages[0].text != null) {

                    this.utilsService.feedbackInfo(`${ondata.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
                    this.loading = false;
                    this.matDialogRef.close();

                  } else {
                    this.utilsService.feedbackError(`${ondata.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
                    this.loading = false;
                    this.matDialogRef.close();
                  }

                }
              },
              (error) => {
                this.loading = false;
                console.log('UPDATE VISITA ERROR', error);
              },
            );

          } else {

            let mensagemErro = '';
            if (ondata.messages[0].text.includes('br.com.sgf.exception.BusinessException:')) {
              mensagemErro = ondata.messages[0].text.replace('br.com.sgf.exception.BusinessException:', '');
            } else {
              mensagemErro = ondata.messages[0].text;
            }

            if (ondata.messages[0].text != null) {
              this.utilsService.feedbackInfo(`${ondata.messages[0].text}`, TimeMensagem.TIME_MENSAGEM);
            } else {
              this.utilsService.feedbackError('Error', TimeMensagem.TIME_MENSAGEM);
            }

            this.loading = false;
            this.disabledButtonFinalizar = false;
            this.matDialogRef.close();

          }
        },
        (onerror) => {
          console.log('UPDATE NO CLIENTE ERROR', onerror);
        },
      );

    }, 2500);
  }

  private handleListContaBancaria(customers): void {
    const contas = customers.pessoa.listContaBancaria;
    if (contas.length === 1 && !contas[0].id && !contas[0].agencia && !contas[0].numero) {
      customers.pessoa.listContaBancaria = [];
    }
  }

  googleMaps(customer): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${customer.logradouro}, ${customer.numero}, ${customer.municipio ? customer.municipio.nomeMunicipio : ''}, ${customer.estado}, ${customer.cep}`;
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        customer.logradouroCompleto = results[0].formatted_address;
        customer.latitude = results[0].geometry.location.lat();
        customer.longitude = results[0].geometry.location.lng();
      }
    });

  }

  reagendar(): void {
    // tslint:disable-next-line:no-shadowed-variable
    this.dialog.open(AgendarVisitaComponent, {
      data: {
        cliente: this.cliente,
      },
    }).afterClosed().subscribe(data => {

      this.matDialogRef.close();

    });
  }

  get addressForms(): FormArray {
    return this.visit.get('listEndereco') as FormArray;
  }

  private validaNumeroCliente(listaTelefone: any, nomeClente: string): void {
    if (listaTelefone != null) {
      listaTelefone.forEach(telefone => {

        if (telefone != null && telefone.numero.length < 10) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `Telefone do(a): ${nomeClente} deve conter todos os digitos !`,
          };
          this.disabledButtonFinalizar = false;
          this.loading = false;
          this.feedback(this.dialogMessages);
          throw new Error(`Telefone do(a): ${nomeClente} deve conter todos os digitos !`);
        }
      });
    }
  }

  checkReferenciaPixFormInvalid(invalido: boolean): void {
    this.referenciaPixFormInvalido = invalido;
  }
}
