import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
@Component({
  selector: 'app-posto-form-parametros',
  templateUrl: './posto-form-parametros.component.html',
  styleUrls: ['./posto-form-parametros.component.scss']
})
export class PostoFormParametrosComponent implements OnInit {

  @Input() posto: FormGroup;

  parametroPosto;

  constructor(
      private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.posto.value.parametroPosto == null) {
      this.add();
    }
    this.parametroPosto = this.posto.get('parametroPosto') as FormGroup;
  }

  get parametrosForms(): FormGroup {
    return this.posto.get('parametroPosto') as FormGroup;
  }

  private add(): void {
    const parametros = this.formBuilder.group({
      id    : new FormControl(null),
      valorRefeicao : new FormControl(null),
      portePostoEnum : new FormControl(null),
      conferivel: new FormControl(null),
      optantePorCobrancasFDS: new FormControl(null),
      pixBoleto: new FormControl(null),
      habilitarDezParcelas: new FormControl(null),
      eletro: new FormControl(null),
      boleto: new FormControl(null)
    });
    this.parametrosForms.value(this.parametroPosto);
  }

}
