import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from '../../model/customer/customer.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-creditos-historico',
  templateUrl: './creditos-historico.component.html',
  styleUrls: ['./creditos-historico.component.scss'],
  animations: [fuseAnimations]
})
export class CreditosHistoricoComponent implements OnInit {

  @ViewChild('dialogContent')
  dialogContent: TemplateRef<any>;
  @Input() rows: any[];
  @Output() onSelectEvent = new EventEmitter<any>();

  selected: Array<Customer>;
  timeout: any;

  constructor() {
    this.selected = new Array<Customer>();
  }

  ngOnInit() {
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.onSelectEvent.emit(selected[0]);
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }


}
