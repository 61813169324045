import { Component, OnInit, Inject } from '@angular/core';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Mask } from '@fagnerlima/ng-mask';
import { UtilsService } from 'app/shared/util/utils.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

@Component({
    selector: 'app-quitar-falta-form',
    templateUrl: './quitar-falta.component.html',
    styleUrls: ['./quitar-falta.component.scss']
})
export class QuitarFaltaComponent implements OnInit {
    form: FormGroup;
    dialogMessages;

    constructor(private transferenciaService: TransferenciaService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<QuitarFaltaComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _matDialog: MatDialog,
        private utilsService: UtilsService) {
        console.log(this._data);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            valor: [this._data.valor ? this._data.valor : null, Validators.required]
        });
    }

    public onSave(value: any): void {
        value.idTransferencia = this._data.confirmation.id;

        this.transferenciaService.quitarFalta(value).subscribe((response: ReturnApi) => {

            const isSucess = this.utilsService.feedbackApi(response, 'Quitamento de Falta realizado com sucesso', TimeMensagem.TIME_MENSAGEM);

            if (isSucess) {
                this.dialogRef.close();
            }

        }, (error): void => {
            console.log(JSON.stringify(error))
            this.utilsService.feedbackInfo('Não foi possível quitar falta, tente novamente.', TimeMensagem.TIME_MENSAGEM);
        });
    }

    getErrorMessage(field: string): any {
        return `O campo ${field} é obrigatório`;
    }

    private feedback(message: string[]): void {
        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 3000);
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }
}