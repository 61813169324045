import { Component, OnInit, Inject, ViewChild, OnDestroy, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSelect } from '@angular/material';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import * as _ from 'lodash';

import { TeamService } from 'app/main/global/components/teams/services/team.service';
import {Subject} from 'rxjs';
import {Team} from 'app/main/shared/model/team/team.model';
import { EmployeeAssignment } from 'app/main/shared/model/enums/employee-assignment.enum';
import { EmployeeService } from 'app/main/global/components/employees/services/employee.service';
import {MessageDialogComponent} from '../../message-dialog/message-dialog.component';
import {Posto} from 'app/main/shared/model/posto/posto.model';
import { PostsService } from 'app/main/global/components/posts/services/posts.service';

export interface DialogData {
    team: any;
    posto: Posto;
    action: string;
}

@Component({
    selector: 'app-posto-form-main',
    templateUrl: './posto-form-main.component.html',
    styleUrls: ['./posto-form-main.component.scss']
})

/**
 * @name PostoFormMainComponent
 * @description
 * Representação do formulário da Equipe com suas validações
 *
 */

export class PostoFormComponent implements OnInit, OnDestroy {

    team: FormGroup;
    @Input() posto: FormGroup;
    titleHeader: string;
    fucaoEnum: EmployeeAssignment;

    dialogMessages;
    listEquipe = [];

    vendedoresName;
    passadorDeRota;
    gestorName;
    continuoName;

    equipes: Team[];

    disabledButton: boolean;

    constructor(
        public dialog: MatDialog,
        public matDialogRef: MatDialogRef<PostoFormComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    }

    @ViewChild('singleSelect') singleSelect: MatSelect;
    @ViewChild('multiSelect') multiSelect: MatSelect;
    private _onDestroy = new Subject<void>();

    ngOnInit(): void {
        
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    
    onClosed(): void {
        this.matDialogRef.close();
    }

    getErrorMessage(field: string): string {
        return `O campo ${field} é obrigatório`;
    }

    onChange(event): void {
        const newVal = event.target.value;
    }

    private feedback(message: string[]): void {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
            data: message,
        });
        setTimeout(() => dialogRef.close(), 8000);
    }
}
