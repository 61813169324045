import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { ConfirmarComponent } from '../../dialogs/confirmar/confirmar.component';
import { AuditoriaConferenciaCaixaGeral } from '../../model/conferencia-caixa/auditoria-conferencia-caixa';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { AuditoriaFormComponent } from '../auditoria-form/auditoria-form.component';

@Component({
  selector: 'app-conferencia-caixa-geral-form',
  templateUrl: './conferencia-caixa-geral-form.component.html',
  styleUrls: ['./conferencia-caixa-geral-form.component.scss']
})
export class ConferenciaCaixaGeralFormComponent implements OnInit {

  dto: AuditoriaConferenciaCaixaGeral = new AuditoriaConferenciaCaixaGeral();

  constructor(private _matDialog: MatDialog, private service: CaixaService,
    private matDialogRef: MatDialogRef<ConferenciaCaixaGeralFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  private utilsService: UtilsService) { 
    this.checkIfHasPosto();
  }

  ngOnInit() {}

  onCancel(): void {
    this.matDialogRef.close();
  }

  onConfirm(): void {
    const valor = this.generateMask(this.dto.valor);
    this._matDialog.open(ConfirmarComponent, {
      data: {
          text: `Você confirma que o Valor: ${valor} é o que você tem nesse momento em caixa?`
      }
    }).afterClosed().subscribe((result) => {  
      if (result) {
        this.openAuditoriaForm();
      }
    });
  }

  private openAuditoriaForm(): any {
    this._matDialog.open(AuditoriaFormComponent, {
      data: {
        titulo: 'Autenticar Auditoria',
        hasSuccessFeedback: false
      }
    }).afterClosed().subscribe(result => {
      if (result && result.idFuncionario >= 0) {
        this.dto.funcionarioId = result.idFuncionario;
        this.saveConferencia(this.dto);
      }
    });
  }

  private saveConferencia(conferencia: AuditoriaConferenciaCaixaGeral): void {
    this.service.conferenciaCaixaGeral(conferencia).subscribe((response: any) => {
      this.feedback(response);
    })
  }

  feedback(response: any) : void {
    if (response.success) {
      this.utilsService.feedbackSuccess(response.messages[0].text, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
      this.matDialogRef.close();
    } else {
      if (response.messages[0].text != null) {
        this.utilsService.feedbackInfo(response.messages[0].text, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
        this.matDialogRef.close();
      } else {
        this.utilsService.feedbackError(`Erro: Contate o Setor de TI`, TimeMensagem.TIME_MENSAGEM_FINANCEIRO);
        this.matDialogRef.close();
      }
    }
  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.dto.postoId = Number.isInteger(user.idPosto) ? user.idPosto : 0;
    }
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
