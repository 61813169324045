import { Component, OnInit, Inject } from '@angular/core';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { SituationTransfer } from '../../model/enums/situation-transfer.enum';
import { CobrancasService } from 'app/main/financial/components/cobrancas/services/cobrancas.services';
import { ClientePlanejamentoService } from 'app/main/customers/components/cliente-planejamento/services/cliente-planejamento.service';

export interface DialogData {
  confirmation: any,
  origem: boolean
  situacao: string;
  id: number;
  type: string;
}
export namespace Situation {
  export function values() {
    return Object.keys(SituationTransfer).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

@Component({
  selector: 'app-confirmar-captacao',
  templateUrl: './confirmar-captacao.component.html',
  styleUrls: ['./confirmar-captacao.component.scss']
})
export class ConfirmarCaptacaoComponent implements OnInit {

  situaces = Situation
  confirmation: any;
  dialogRef: any;
  titulo: string;
  titleHeader: string;
  origem: any;
  message: string;
  situacao: string;
  disabledButton: boolean;
  dataRest;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private clientePlanejamentoService: ClientePlanejamentoService,
    private matDialogRef: MatDialogRef<ConfirmarCaptacaoComponent>,
    private _matDialog: MatDialog,
    private cobrancaService: CobrancasService) {

    this.origem = data.origem;
  }

  ngOnInit(): void {
  }

  confirmTransfer() {
    this.clientePlanejamentoService.captar(this.data.cliente).subscribe(
      (data) => {
        this.rest(data)
      }
    )
  }


  cancelar() {
    this.matDialogRef.close(this.data.confirmation.id);

  }

  rest(data: any) {
    if (data.success === true) {
      this.matDialogRef.close(data.object);
      this.messageDialogSucess(data.messages[0].text);
    } else {
      this.matDialogRef.close();
      this.messageDialogError(data)
    }
    (onerror) => {
      console.log(onerror);
    }
  }


  messageDialogSucess(mensagem: any): void {
    if (this.data.origem == false) {
      this.message = 'Recebimento de transferência confirmado!'
    } else {
      this.message = 'Transferência confirmada!'
    }

    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: {
        type: 'success',
        title: 'Sucesso!',
        text: `${mensagem}`
      }
    });
    setTimeout(() => this.dialogRef.close(), 2700);
  }

  messageDialogError(data: any): void {
    if (data.messages[0].text == null) {
      this.dataRest = {
        type: 'error',
        title: 'Error!',
      }
    } else {
      this.dataRest = {
        type: 'info',
        title: 'Informação!',
        text: `${data.messages[0].text}`
      }
    }
    this.dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: this.dataRest
    });
    setTimeout(() => this.dialogRef.close(), 2700);
  }

  onCancel(): void {
    this.matDialogRef.close();
  }
}
