import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contract } from './../../model/contract/contract.model';
import { Component, OnInit, Inject } from '@angular/core';

export interface UploadRepassePix {
  contratoId: number;
  nomeDocumento: string;
  documentoBase64: string;
}

@Component({
  selector: 'app-repasse-pix-form',
  templateUrl: './repasse-pix-form.component.html',
  styleUrls: ['./repasse-pix-form.component.scss']
})
export class RepassePixFormComponent implements OnInit {

  contrato: Contract;
  documento: File;
  nomeDocumento = '';
  documentoBase64 = '';

  constructor(public matDialogRef: MatDialogRef<RepassePixFormComponent>, @Inject(MAT_DIALOG_DATA) public _data: Contract) {
    this.contrato = _data;
  }

  ngOnInit(): void { }

  onClose(accepted: boolean): void {
    if (accepted) {
      const returnData: UploadRepassePix = {contratoId: this.contrato.id, nomeDocumento: this.nomeDocumento, documentoBase64: this.documentoBase64};
      this.matDialogRef.close(returnData);
    } else {
      this.matDialogRef.close();
    }
  }

  onFileSelected(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files.item(0);
    this.documento = file;
    this.nomeDocumento = file.name;

    const reader = new FileReader();
    const future = new Promise((resolve) => {
      reader.addEventListener('loadend', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });

    future.then(data => this.transformBlobTo64(data));
  }

  transformBlobTo64(result: any): void {
    this.documentoBase64 = result;
  }
}
