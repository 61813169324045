import {CaixaService} from './../../../services/caixa/caixa.service';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {take, mergeMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResolveLancamentoEntradasService {

    constructor(private caixaService: CaixaService) {
    }

    resolve(): Observable<any> | any {
        // return this.caixaService.dashboardLancamentoEntrada().pipe(
        //     take(1),
        //     mergeMap(teste => {
        //         return of(teste['object']);
        //     })
        // );
    }
}
