import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';

import * as _ from 'lodash';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { UserService } from 'app/main/global/components/users/services/users/users.service';
import { User } from '../../model/user/user.model';
import { EmployeeService } from '../../../global/components/employees/services/employee.service';
import { Employee } from '../../model/employee/employee.model';
import { UserProfile } from '../../model/user-profile/user-profile.model';
import { UserProfileService } from '../../../global/components/profiles/services/profile.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Profile } from '../../model/profile/profile.model';
import { ClienteService } from 'app/main/customers/components/cliente/services/cliente.service';
import { Cliente } from '../../model/cliente/cliente.model';
import { Mask } from '@fagnerlima/ng-mask';
import { EmpresaService } from 'app/main/global/components/empresa/services/empresa-service.service';
import { UtilsService } from 'app/shared/util/utils.service';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';

export interface DialogData {
  user: User;
  action: string;
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})

/** 
 * @name UserFormComponent
 * @description 
 * Representação do formulário de Funcionário com suas validações
 * 
 */

export class UserFormComponent implements OnInit {
  titleHeader: string;
  user: FormGroup;
  dialogMessages;
  listPermissions: Profile[] = [];
  employees: Array<Employee>;
  profiles: Array<UserProfile> = [];
  listPerfisDescription = [];
  clientes: Array<Cliente> = [];
  isCpf: boolean;
  cnpj: string;
  cpf: string;
  type: string;
  pessoa: any;
  maskCpf = new Mask('000.000.000-00');
  maskCnpj = new Mask('00.000.000/0000-00');
  disabledButton = false;
  typeUser;
  senhasInvalid: boolean;
  public funcionarioFilterControl: FormControl = new FormControl();
  public filteredFuncionario: ReplaySubject<Employee[]> = new ReplaySubject<Employee[]>(1);

  public perfilFilterControl: FormControl = new FormControl();
  public filteredPerfil: ReplaySubject<UserProfile[]> = new ReplaySubject<UserProfile[]>(1);

  public clienteFilterControl: FormControl = new FormControl();
  public filteredCliente: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private employeeService: EmployeeService,
    private profileService: UserProfileService,
    public matDialogRef: MatDialogRef<UserFormComponent>,
    private clienteService: ClienteService,
    private pessoaService: EmpresaService,
    private funcionarioService: EmployeeService,
    private utilsSerivce: UtilsService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) {

    this.employeeService.findAll().subscribe(data => {
      this.employees = data.object.content;
      this.filteredFuncionario.next(this.employees.slice());
      this.funcionarioFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterFuncionario();
        });
    });

    this.profileService.findByNivelUsuario().subscribe(data => {
      this.profiles = data.object;
      this.filteredPerfil.next(this.profiles.slice());
      this.perfilFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterPerfil();
        });
    });

    this.clienteService.findAll().subscribe(data => {
      this.clientes = data.object.content;
      this.filteredCliente.next(this.clientes.slice());
      this.clienteFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCliente();
        });
    });

    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      if (this._data.user != null && this._data.user.pessoa != null && this._data.user.pessoa.documento.length > 14) {
        this.isCpf = false;
        this.cnpj = this._data.user.pessoa.documento;
        this.type = 'pj';
      } else {
        this.isCpf = true;
        this.cpf = this._data.user.pessoa ? this._data.user.pessoa.documento : null;
        this.type = 'pf';
      }
    }

    this.changeTitleHeader();
  }

  ngOnInit(): void {
    this.setFormGroup();
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      this.pessoaService.findById(this._data.user.pessoa.id).subscribe(data => {
        this.pessoa = data.object;
      });
    }
  }

  // tslint:disable-next-line:member-ordering
  private _onDestroy = new Subject<void>();

  changeTitleHeader(): void {
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'new') {
      this.titleHeader = 'Novo Usuário';
    } else {
      this.titleHeader = 'Editar Usuário';
    }
  }

  
  setFormGroup(): void {
    let funcionario = null;
    let pessoa = null;
    let cliente = null;

    
    // tslint:disable-next-line:triple-equals
    if (this._data.action == 'update') {
      this._data.user.listPerfils.forEach(p => {
        this.listPerfisDescription.push(p.descricao);
      });
      if (this._data.user != null && this._data.user.administrador != null) {
        this.typeUser = 'ua';
        pessoa = this._data.user.pessoa;

      } else if (this._data.user != null && this._data.user.cliente != null) {
        this.typeUser = 'uc';
        cliente = this._data.user.cliente;
      } else {
        this.typeUser = 'uf';
        funcionario = this._data.user.funcionario;
      }
    } else {
      this.typeUser = 'uf';
      this.isCpf = true;
    }

    this.user = this.formBuilder.group({
      id: [this._data.user ? this._data.user.id : null],
      funcionario: [funcionario ? this._data.user.funcionario.id : null],
      cliente: [cliente ? this._data.user.cliente : null],
      administrador: [pessoa ? this._data.user.administrador : null],
      login: [this._data.user ? this._data.user.login : null, Validators.required],
      senha: [this._data.user ? this._data.user.senha : null, Validators.required],
      repeat_senha: ['', Validators.required],
      pessoa: this.formBuilder.group({
        id: this._data.user ? this._data.user.pessoa.id : null,
        isCpf: this._data.user ? this.isCpf : true,
        nome: [pessoa ? this._data.user.pessoa.nomePessoa : null],
        // tslint:disable-next-line:max-line-length
        nomeFantasia: [this._data.user ? this._data.user.pessoa.nomeFantasiaPessoa : null, 
          // tslint:disable-next-line:triple-equals
          Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        // tslint:disable-next-line:triple-equals
        razaoSocial: [this._data.user ? this._data.user.pessoa.nomePessoa : null, Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        // tslint:disable-next-line:triple-equals
        cpf: [this._data.user ? this.cpf : null, Validators.compose([this.isCpf == true && this.typeUser == 'ua' ? Validators.required : null])],
        // tslint:disable-next-line:triple-equals
        cnpj: [this._data.user ? this.cnpj : null, Validators.compose([this.isCpf == false && this.typeUser == 'ua' ? Validators.required : null])],
        // tslint:disable-next-line:triple-equals
        rg: [this._data.user ? this._data.user.pessoa.rg : null, Validators.compose([this.isCpf == true && this.typeUser == 'ua' ? Validators.required : null])],
        type: [this.type ? this.type : 'pf']
      }),
      listPerfils: [this.listPerfisDescription || null, Validators.required],
      type: [this.typeUser || null, Validators.required],
      superUsuario: [null],
      ativo: [true],
      usuarioNoturno: [this._data.user ? this._data.user.usuarioNoturno : false]
    });
  }

  get usuarioClienteValid(): boolean {
    return this.user.get('type').value === 'uc' && this.user.get('cliente').value !== null;
  }

  selectPermission(obj: any): void {

  }

  setCpfField(isCpf: boolean, type: string): void {
    this.isCpf = isCpf;
    this.user.get('pessoa').get('isCpf').setValue(isCpf);
    this.user.get('pessoa').get('type').setValue(type);
  }

  setTypeUser(type: string): void {
    this.typeUser = type;
  }

  onAdd(object: any): void {
    // delete object.repeat_senha;
    const perfils = [];
    object.listPerfils.forEach(element => {
      // tslint:disable-next-line:triple-equals
      perfils.push(this.profiles.filter(perfilsResult => perfilsResult.descricao == element)[0]);
    });

    object.listPerfils = perfils;

    this.profiles.forEach(p => {
      // tslint:disable-next-line:triple-equals
      if (p.descricao == object.listPerfils.descricao) {
        object.listPerfils = p;

      }
    });

   
    object.ativo = true;

    if (object.type === 'ua') {
      delete object.cliente;
      delete object.funcionario;
      this.pessoaService.add(object.pessoa).subscribe(data => {
        this.pessoa = data.object;
        object.administrador = this.pessoa;
        object.administrador.type = object.pessoa.type;
        object.pessoa = this.pessoa;
        object.superUsuario = true;
        this.create(object);
      });
    }

    // tslint:disable-next-line:triple-equals
    if (object.type == 'uc') {
      object.login = object.cliente.pessoa.documento;
      object.senha = '0000';
      object.repeat_senha = '0000';
      this.create(object);
    }

    // tslint:disable-next-line:triple-equals
    if (object.type == 'uf') {
      this.funcionarioService.findById(this.user.value.funcionario).subscribe(data => {
        this.create(object);
        object.funcionario = data.object;
      });
    }

  }

  onUpdate(object: any): void {
    const perfils = [];

    object.listPerfils.forEach(element => {
      // tslint:disable-next-line:triple-equals
      perfils.push(this.profiles.filter(perfilsResult => perfilsResult.descricao == element)[0]);
    });

    object.listPerfils = perfils;


    this.profiles.forEach(p => {
      // tslint:disable-next-line:triple-equals
      if (p.descricao == object.listPerfils.descricao) {
        object.listPerfils = p;
      }
    });

    if (object.type === 'ua') {
      delete object.cliente;
      delete object.funcionario;
      object.pessoa.listTelefone = [];
      object.pessoa.listEndereco = [];
      this.pessoaService.update(object.pessoa).subscribe(data => {
        this.pessoa = data.object;
        object.administrador = this.pessoa;
        object.administrador.type = object.pessoa.type;
        object.pessoa = this.pessoa;
        object.superUsuario = true;
        this.update(object);
      });
    }

    // tslint:disable-next-line:triple-equals
    if (object.type == 'uc') {
      this.update(object);
    }

    // tslint:disable-next-line:triple-equals
    if (object.type == 'uf') {
      this.funcionarioService.findById(this.user.value.funcionario).subscribe(data => {
        object.funcionario = data.object;
        this.update(object);
      });
    }
  }


  getPessoa(id: number): void {
    this.pessoaService.findById(id).subscribe(data => {
      this.pessoa = data.object;
    });
  }

  private filterFuncionario(): void {
    if (!this.employees) {
      return;
    }

    let search = this.funcionarioFilterControl.value;
    if (!search) {
      this.filteredFuncionario.next(this.employees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredFuncionario.next(
      this.employees.filter(funcionario => funcionario.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPerfil(): void {
    if (!this.profiles) {
      return;
    }

    let search = this.perfilFilterControl.value;
    if (!search) {
      this.filteredPerfil.next(this.profiles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPerfil.next(
      this.profiles.filter(perfil => perfil.descricao.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCliente(): any {
    if (!this.clientes) {
      return;
    }

    let search = this.clienteFilterControl.value;
    if (!search) {
      this.filteredCliente.next(this.clientes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCliente.next(
      this.clientes.filter(cliente => cliente.pessoa.nomePessoa.toLowerCase().indexOf(search) > -1)
    );
  }

  create(object): void {
    this.checkPassword(object.repeat_senha);
    // tslint:disable-next-line:triple-equals
    if (object.senha == object.repeat_senha) {
      this.disabledButton = true;

      this.userService.add(object).subscribe(seg => {
        if (seg.success) {
          this.utilsSerivce.feedbackSuccess(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close(seg.object);
        } else {
          if (seg.messages != null) {
            this.utilsSerivce.feedbackInfo(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
            this.disabledButton = false;
          } else {
            this.utilsSerivce.feedbackError(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
            this.disabledButton = false;
          }
        }
      });
    }
  }

  update(object): void {
    this.checkPassword(object);
    // tslint:disable-next-line:triple-equals
    if (object.senha == object.repeat_senha) {
      
      this.disabledButton = true;
      delete object.repeat_senha;

      this.userService.updateUser(object).subscribe(seg => {
        if (seg.success) {
          this.utilsSerivce.feedbackSuccess(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
          this.matDialogRef.close(seg.object);
        } else {
          if (seg.messages != null) {
            this.utilsSerivce.feedbackInfo(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
            this.disabledButton = false;
          } else {
            this.utilsSerivce.feedbackError(seg.messages[0].text, TimeMensagem.TIME_MENSAGEM);
            this.disabledButton = false;
          }
        }
        
      });
    }
  }


  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }
  onClosed(): void {
    this.matDialogRef.close();
  }

  checkPassword(value): void {
    
    // tslint:disable-next-line:triple-equals
    if (value.senha != value.repeat_senha) {
      this.senhasInvalid = true;
    } else {
      this.senhasInvalid = false;
    }
  }

  buscarUsuariosFiltro(nomeBusca: string): void {
    this.clienteService.findClienteByNome(nomeBusca).subscribe(response => {
      this.clientes = response.object.content;
      this.filteredCliente.next(this.clientes.slice());
    });
  }
}
