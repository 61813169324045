import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReagendadoMainComponent } from './reagendado-main/reagendado-main.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedMaterialComponentsModule} from '../../../../shared-material-components.module';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseConfirmDialogModule, FuseSidebarModule} from '../../../../../@fuse/components';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {SharedModule} from '../../../shared/shared.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxPermissionsModule} from 'ngx-permissions';
import { ReagendadosDetailListComponent } from './reagendados-detail-list/reagendados-detail-list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {NgxLoadingModule} from 'ngx-loading';
import { ReagendadosSidebarComponent } from './reagendados-sidebar/reagendados-sidebar.component';


    const routes: Routes = [{
                path: '',
                component: ReagendadoMainComponent,
                resolve: {}
            }
        ];


@NgModule({
  imports: [
      CommonModule,
      RouterModule.forChild(routes),
      SharedMaterialComponentsModule,
      FuseSharedModule,
      FuseConfirmDialogModule,
      FuseSidebarModule,
      NgxDatatableModule,
      SharedModule,
      FlexLayoutModule,
      NgxLoadingModule.forRoot({}),
      NgxPermissionsModule.forRoot({})
  ],
  declarations: [
      ReagendadoMainComponent,
      ReagendadosDetailListComponent,
      ReagendadosSidebarComponent,
  ],
  exports: [
      ReagendadoMainComponent,
      ReagendadosDetailListComponent,
      ReagendadosSidebarComponent,
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReagendadosModule { }
