import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BancoService {

  private banco = `${SGF_API}` + 'sgf/api/public/banco';

  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    return this.httpClient.get<any[]>(this.banco)
      .pipe(
        catchError(this.handleError)
      );
  }

  findByDescricao(descricao: string): Observable<any> {
    return this.httpClient.get<any[]>(`${this.banco}?descricao=${descricao}`)
        .pipe(
            catchError(this.handleError)
        );
  }

  create(banco: any): Observable<any> {
    const body = JSON.stringify(banco);
    return this.httpClient.post(this.banco, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(banco: any): Observable<any> {
    const body = JSON.stringify(banco);
    return this.httpClient.put(this.banco, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  httpOptionsPdf(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
