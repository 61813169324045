import { ClientePlanejamentoService } from "../services/cliente-planejamento.service";
import { Observable, of } from "rxjs";
import { takeLast, mergeMap, take } from "rxjs/operators";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ResolveClientePlanejamentoService implements Resolve<any> {
    constructor ( private clientePlanejamentoService: ClientePlanejamentoService) {}

    resolve(): Observable<any> {
        return this.clientePlanejamentoService.page(10,1).pipe(
            take(1),
            mergeMap(teste => {
                return of(teste['object'])
            })
        )
    }
}