import { Injectable } from '@angular/core';
import { SGF_API } from 'app/shared/api/sgf.api';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LancamentoAgendaService {

  private agenda = `${SGF_API}` + 'global/api/public/lancamento-agenda';

  constructor(private httpClient: HttpClient) { }

  findMarcaById(marcaId: number): any {
    return this.httpClient.get(`${this.agenda}/${marcaId}`)
      .pipe(
        catchError(this.handleError));
  }

  create(value): any {
    const body = JSON.stringify(value);
    return this.httpClient.post(`${this.agenda}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }
  
  update(value): any {
    const body = JSON.stringify(value);
    return this.httpClient.put(`${this.agenda}`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  remove(id: number): any {
    return this.httpClient.delete(`${this.agenda}/${id}`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  realizarAcompanhamento(value): any {
    const body = JSON.stringify(value);
    return this.httpClient.put(`${this.agenda}/realizar-acompanhamento`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError));
  }

  findAll(): any {
    return this.httpClient.get(`${this.agenda}?size=1000&ativo=true`)
      .pipe(
        catchError(this.handleError));
  }
  page(size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.agenda}?size=${size}&page=${page}&ativo=${ativo}&sort=-id`)
      .pipe(
        catchError(this.handleError));
  }

  filterMarca(filter: string, size: number, page: number, ativo: boolean): any {
    return this.httpClient.get(`${this.agenda}?nome=like:${filter}&size=${size}&page=${page}&ativo=${ativo}`)
      .pipe(
        catchError(this.handleError));
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
