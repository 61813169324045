import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SGF_API } from 'app/shared/api/sgf.api';
import { Rota } from 'app/main/shared/model/rota/rota.model';

@Injectable({
  providedIn: 'root'
})

export class RotasService {

  private host = `${SGF_API}` + 'global/api/public/rota';
  private posto: string;
  constructor(private httpClient: HttpClient) { }

  page(size: number, page: number): Observable<any>  {
   
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`)
    .pipe(
      catchError(this.handleError)
    );
  
  }

  listAll(): Observable<any> {
    this.checkIfHasPosto();
    
    return this.httpClient.get<Rota[]>(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  }


  

  findByIdPosto(idPosto: number): Observable<any> {
    return this.httpClient.get<Rota>(`${this.host}/rota-por-posto/${idPosto}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  

  findByIdEquipe(idEquipe: number): Observable<any> {
    return this.httpClient.get<Rota>(`${this.host}/rota-por-equipe/${idEquipe}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<Rota>(`${this.host}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  
  findByIdCobrador(id: number): Observable<any> {
    return this.httpClient.get<Rota>(`${this.host}/find-rota-por-cobrador/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }


  add(rota: any): Observable<any> {
    const body = JSON.stringify(rota);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  deletar(id: number): any {
    return this.httpClient.delete(`${this.host}/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePosto(rota: Rota): Observable<any> {
    const body = JSON.stringify(rota);

    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
