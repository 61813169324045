import { Injectable } from "@angular/core";
import { ScrumboardCardModel } from '../model/scrumboard-card-model.model';

const scrumboardDataPT: ScrumboardCardModel[] =[
    {icon: 'person_pin', description: 'CAPTAÇÃO', link: '/customer/captacao/all/lista'},
    {icon: 'watch_later', description: 'VISITAR', link: '/customer/visitas'},
    {icon: 'check_circle', description: 'VISITADOS', link: '/customer/visitados'},
    {icon: 'monetization_on', description: 'APROVAÇÃO', link: '/customer/creditos'},
    {icon: 'file_copy', description: 'CONTRATOS', link: '/contracts/gerados'},

]

const scrumboardDataEN: ScrumboardCardModel[] =[
    {icon: 'person_pin', description: 'COLLECT', link: '/customer/captacao/all/lista'},
    {icon: 'watch_later', description: 'TO VISIT', link: '/customer/visitas'},
    {icon: 'check_circle', description: 'VISITED', link: '/customer/visitados'},
    {icon: 'monetization_on', description: 'APPROVED', link: '/customer/creditos'},
    {icon: 'file_copy', description: 'CONTRACTS', link: '/contracts/gerados'},

]


@Injectable()
export class ScrumboardCard{
    getAllScrumboardCardsPT(): any{
        return scrumboardDataPT;
    }

    getAllScrumboardCardsEN(): any{
        return scrumboardDataEN;
    }
}