import { Injectable } from "@angular/core";
import { CaixaService } from "app/main/financial/services/caixa/caixa.service";
import { Observable, of  } from "rxjs";
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class ResolveRepasseService {
    
    type:string = "cfu"
  
    constructor(private caixaService: CaixaService) { }
    
    resolve(): Observable<any> | any {
      return this.caixaService.pageSituacao('LIBERADO',this.type,10,1).pipe(
        take(1),
        mergeMap(teste => {
          return of(teste['object']);
        })
      );
    }
  }