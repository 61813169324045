import { Injectable } from '@angular/core';
import { FolhaPagamentoService } from '../services/folha-pagamento.services';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveFolhaPagamentoService {
  
  type:string = "tfuco"
  
  constructor(private folhaPagamentoService : FolhaPagamentoService) { }

  resolve(): Observable<any> {
    return this.folhaPagamentoService.findAll().pipe(
      take(1),
      mergeMap(transf => {
      return of(transf['object']);
      
      })
    )    
      
  }
}
