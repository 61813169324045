import { LoadProgressComponent } from './../../dialogs/load-progress/load-progress.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TransferenciaService } from 'app/main/financial/services/transferencia/transferencia.service';
import { SituationTransfer } from '../../model/enums/situation-transfer.enum';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { CaixaService } from 'app/main/financial/services/caixa/caixa.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';

export interface DialogData {
  confirmation: any;
  origem: boolean;
  situacao: string;
  id: number;
  type: string;
}
export namespace Situation {
  export function values(): any {
    return Object.keys(SituationTransfer).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

@Component({
  selector: 'app-confirmar-repasse',
  templateUrl: './confirmar-repasse.component.html',
  styleUrls: ['./confirmar-repasse.component.scss']
})
export class ConfirmarRepasseComponent implements OnInit {

  situaces = Situation;
  confirmation: any;
  dialogRef: any;
  titulo: string;
  titleHeader: string;
  origem: any;
  message: string;
  situacao: string;
  disabledButton: boolean;
  transferencias: any[];
  confirmado: number;
  dialogMessages;
  form: FormGroup;
  nomeClienteDisabled = true;
  dataRest;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ConfirmarRepasseComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private transferenciaService: TransferenciaService,
    private caixaService: CaixaService,
    private _matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      transferencias: this.buildTransferencia()
    });
  }

  confirmTransfer(value): void {
    this.transferenciaService.confirmarRepassesNaoConfirmados(value.transferencias).subscribe(resultRepasseNaoConfirmado => {
      if (resultRepasseNaoConfirmado.success) {
        const matDialog = this._matDialog.open(LoadProgressComponent, { disableClose: true });
        this.caixaService.findByIdCaixa(this.data.idCaixa).subscribe(resultCaixa => {
          resultCaixa.object.type = 'cdf';
          delete resultCaixa.object.funcionario.rota.equipe;
          this.caixaService.confirmarPrestacaoDeContaPendencias(resultCaixa.object).subscribe((res: ReturnApi) => {
            if (res.success) {
              this.dialogMessages = {
                type: 'success',
                title: 'Sucesso!',
                text: `Registro atualizado com sucesso`
              };
              this.feedback(this.dialogMessages);
              matDialog.close();
              this.router.navigate(['/financial/pendencias']);

            } else {
              if (res.messages[0].text != null) {
                this.dialogMessages = {
                  type: 'info',
                  title: 'Informação!',
                  text: `${res.messages[0].text}`
                };
              } else {
                this.dialogMessages = {
                  type: 'error',
                  title: 'Error!',
                  text: `${res.messages[0].text}`
                };
              }
              this.feedback(this.dialogMessages);
              matDialog.close();
            }
          }),
            // tslint:disable-next-line:no-unused-expression
            (onerror) => {
              matDialog.close();
              console.log('ERROR AO CONFIRMAR REPASSES', onerror);
            };
        });
      } else {
        if (resultRepasseNaoConfirmado.messages[0].text != null) {
          this.dialogMessages = {
            type: 'info',
            title: 'Informação!',
            text: `${resultRepasseNaoConfirmado.messages[0].text}`
          };
        } else {
          this.dialogMessages = {
            type: 'error',
            title: 'Error!',
          };
        }
        this.feedback(this.dialogMessages);
      }
    });
    this.disabledButton = true;
    this.matDialogRef.close(true);
  }


  onCancel(): void {
    this.matDialogRef.close();
  }

  buildTransferencia(): any {
    const formTransferencia = this.data.transferencias.map(t => {
      return this.formBuilder.group({
        transferencia: new FormControl(t.id || null),
        nomeCliente: new FormControl({ value: t.contrato.visita.cliente.pessoa.nomePessoa || null, disabled: true }),
        contrato: new FormControl({ value: t.contrato.id || null, disabled: true }),
        confirmado: new FormControl(null)
      });
    });
    return this.formBuilder.array(formTransferencia);

  }

  get transferenciaForms(): FormArray {
    return this.form.get('transferencias') as FormArray;
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
  }

}
