import { HttpErrorResponse } from '@angular/common/http';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { ActivatedRoute, Data } from '@angular/router';
import { ContractsService } from 'app/main/contracts/services/contracts.service';
import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Contract } from 'app/main/shared/model/contract/contract.model';
import { Page } from 'app/main/shared/model/page/page.model';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AssinaturaDigitalFormComponent } from 'app/main/shared/components/assinatura-digital-form/assinatura-digital-form.component';
import { UtilsService } from 'app/shared/util/utils.service';
import { RepasseBoletoFormComponent } from 'app/main/shared/components/repasse-boleto-form/repasse-boleto-form.component';
import * as FileSaver from 'file-saver';

export interface AcaoBoletoMain {
  acao: string;
  contrato: Contract;
}

@Component({
  selector: 'app-boleto-main',
  templateUrl: './boleto-main.component.html',
  styleUrls: ['./boleto-main.component.scss'],
  animations: fuseAnimations
})
export class BoletoMainComponent implements OnInit {
  public static ACAO_IMPRIMIR_CONTRATO = 'IMPRIMIR_CONTRATO';
  public static ACAO_IMPRIMIR_ASSINATURA_DIGITAL = 'IMPRIMIR_ASSINATURA_DIGITAL';
  public static ACAO_IMPRIMIR_COMPROVANTE_BOLETO = 'IMPRIMIR_COMPROVANTE_BOLETO';
  public static ACAO_CONFIRMAR_ASSINATURA_DIGITAL = 'CONFIRMAR_ASSINATURA_DIGITAL';
  public static ACAO_CONFIRMAR_REPASSE_BOLETO = 'CONFIRMAR_REPASSE_BOLETO';
  private readonly defaultPage: Page = {number: 0, size: 0, pageNumber: 0, totalElements: 0, totalPages: 0};
  
  page: Page = Object.assign({}, this.defaultPage);
  rows: Contract[] = [];
  itensAPI: Array<Contract>;
  selected: Array<Contract>;

  search;
  situacao;
  filter: string = null;
  public loading = false;
  selectedClear: boolean;

  dataPeriodo;
  isPeriodo = false;
  dataInicial;
  dataFinal;

  tipo: string;

  tittle = 'Contratos Liberados para Assinatura Digital';
  status = 'LIBERADO';

  pageNumber;

  constructor(
    private matDialog: MatDialog, 
    private route: ActivatedRoute, 
    private contractService: ContractsService, 
    private utilService: UtilsService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((tipo: Data) => {
      this.tipo = tipo['situacao'];
      this.tittle = tipo['situacao'] === 'LIBERADOS' ? 'Contratos Boleto Liberados para Assinatura Digital' : 'Contratos Boleto Em Andamento';
      this.status = tipo['situacao'] === 'LIBERADOS' ? 'LIBERADO' : 'EM_ANDAMENTO,EM_ATRASO,PENDENTE';
    });

    this.getFirstPage();
  }

  getFirstPage(pageNumber: number = 1): void {
    this.contractService.pageContratosGraphQL(this.status, 10, pageNumber, null, null, null, false, true)
    .pipe(map(({data, errors}) => data.findAllPageable))
    .subscribe(pageable => {
      this.rows = pageable.content;
      this.setPage(pageable);
    });
  }

  setPage(data): void {
    this.page.pageNumber = data ? data.number : 0;
    this.page.totalPages = data ? data.totalPages : 0;
    this.page.size = data ? data.size : 0;
    this.page.totalElements = data ? data.totalElements : 0;
  }

  getPage(event): void {
    this.loading = true;
    this.pageNumber = event;
    switch (this.filter) {
      case 'DOCUMENTO_CLIENTE':
        if (this.search === '') {
          this.contractService.page(this.status, 10, this.pageNumber, null, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByDocumentoCliente(this.status, this.search, 10, this.pageNumber, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'NOME_CLIENTE':
        if (this.search === '') {
          this.contractService.page(this.status, 10, this.pageNumber, null, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByNomeCliente(this.status, this.search, 10, this.pageNumber, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_CONTRATO':
        if (this.search === '') {
          this.contractService.page(this.status, 10, this.pageNumber, null, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByResponsavelContrato(this.status, this.search, 10, this.pageNumber, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
      case 'RESPONSAVEL_REPASSE':
        if (this.search === '') {
          this.contractService.page(this.status, 10, this.pageNumber, null, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        } else {
          this.contractService.pageByResponsavelRepasse(this.status, this.search, 10, this.pageNumber, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        }
        break;
        case 'PERIODO':
          this.isPeriodo = true;
          this.loading = true;
          if (this.dataPeriodo == '') {
            this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataLiberacao', null, null, false, true)
            .pipe(map(({data, errors}) => data.findAllPageable))
            .subscribe(pageable => {
              this.loading = false;
              this.rows = pageable.content;
              this.setPage(pageable);
            });
          } else {
            this.contractService.pageContratosGraphQL(this.situacao, 10, 1, '+dataLiberacao', null, null, false, true, this.dataInicial, this.dataFinal).pipe(map(({data, errors}) => data.findAllPageable))
            .subscribe(pageable => {
              this.loading = false;
              this.rows = pageable.content;
              this.setPage(pageable);
            });
          }
        break;
        case null:
          this.contractService.page(this.status, 10, this.pageNumber, null, null, null, false, true).subscribe(data => {
            this.rows = data.object.content;
            this.loading = false;

            this.setPage(data.object);
          });
        break;
    }
  }

  executarAcao(event: AcaoBoletoMain): void {
    const acoes = new Map<string, (contrato: Contract) => void>()
    .set(BoletoMainComponent.ACAO_IMPRIMIR_CONTRATO, (contrato) => this.imprimirContrato(contrato))
    .set(BoletoMainComponent.ACAO_IMPRIMIR_ASSINATURA_DIGITAL, (contrato) => this.imprimirAssinaturaDigital(contrato))
    .set(BoletoMainComponent.ACAO_IMPRIMIR_COMPROVANTE_BOLETO, (contrato) => this.imprimirComprovanteBoleto(contrato))
    .set(BoletoMainComponent.ACAO_CONFIRMAR_ASSINATURA_DIGITAL, (contrato) => this.confirmarAssinaturaDigital(contrato))
    .set(BoletoMainComponent.ACAO_CONFIRMAR_REPASSE_BOLETO, (contrato) => this.confirmarRepasseBoleto(contrato));

    acoes.get(event.acao)(event.contrato);
  }

  imprimirContrato(contrato: Contract): void {
    this.contractService.getContract(contrato.id).subscribe(ondata => {
      const objectURL = URL.createObjectURL(ondata);
      window.open(objectURL, '_blank').print();
    });
  }

  imprimirAssinaturaDigital(contrato: Contract): void {
    this.contractService.imprimirComprovanteAssinaturaDigital(contrato.id)
    .subscribe((response: Blob) => {
      FileSaver.saveAs(response, `assinatura_digital_contrato_${contrato.id}.pdf`);
    });
  }

  imprimirComprovanteBoleto(contrato: Contract): void {
    this.contractService.imprimirComprovanteRepassePix(contrato.id)
    .subscribe((response: Blob) => {
      FileSaver.saveAs(response, `comprovante_repasse_contrato_${contrato.id}.pdf`);
    });
  }

  confirmarAssinaturaDigital(contrato: Contract): void {
    this.matDialog.open(AssinaturaDigitalFormComponent, {
      data: contrato
    }).afterClosed().subscribe(response => {
      if (response) {
        this.contractService.confirmarAssinaturaDigital(response)
        .subscribe((responseApi: ResponseAPI) => {
          if (responseApi.success) {
            this.utilService.feedbackSuccess('Assinatura Digital Realizada Com Sucesso!', 3000,
            () => this.getFirstPage());
          } else {
            this.utilService.feedbackError('Error', 3000);
          }
        },
        (error: HttpErrorResponse) => {
          this.utilService.feedbackError(`Error <br/> ${error.message}`, 3000);
        });
      }
    });
  }

  confirmarRepasseBoleto(contrato: Contract): void {
    this.matDialog.open(RepasseBoletoFormComponent, {
      data: contrato
    }).afterClosed().subscribe(response => {
      if (response) {
        this.contractService.confirmarRepasseContratoBoleto(response)
        .subscribe((responseApi: ResponseAPI) => {
          if (responseApi.success) {
            this.utilService.feedbackSuccess('Repasse de contrato Boleto Realizada Com Sucesso!', 3000,
            () => this.getFirstPage());
          } else {
            this.utilService.feedbackError('Error', 3000);
          }
        },
        (error: HttpErrorResponse) => {
          this.utilService.feedbackError(`Error <br/> ${error.message}`, 3000);
        });
      }
    });
  }

  getPeriodoFilter(event): void {
    this.isPeriodo = true;
    this.dataPeriodo = event;
    const periodo = this.dataPeriodo.split(',');
    this.dataInicial = periodo[0];
    this.dataFinal = periodo[1];
  }

  getSearchFilter(event): void {
    this.search = event;
  }

  getFilter(event): void {
    this.situacao = event;
    this.filter = event;
    if (event === this.status) {
      this.over();
    }
  }

  getSituacao(event): void {
    this.situacao = event;
  }

  getDataFilter(event): void {
    this.rows = event.content;
    const page = event;
    page.pageNumber = 0;
    this.setPage(page);
  }

  over(): void {
    this.search = null;
    this.loading = true;
    this.contractService.page(this.status, 10, 1, null, null, null, true)
      .subscribe(
        (res) => {
          this.loading = false;
          this.rows = res.object.content;
          this.itensAPI = this.rows;
          this.setPage(res.object);
          this.situacao = this.status;
        }
      );
    this.clearSelectBox();
  }

  clearSelectBox(): void {
    this.selectedClear = true;
    this.selected = new Array<Contract>();
  }

  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
