import { DialogData } from './../benefits-form/benefits-form.component';
import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { ImpostService } from 'app/main/global/components/impost/services/impost.service';
import { ReturnApi } from '../../model/return-api/returnApi-model';

@Component({
  selector: 'app-impost-form',
  templateUrl: './impost-form.component.html',
  styleUrls: ['./impost-form.component.scss']
})
export class ImpostFormComponent implements OnInit, AfterViewInit {

  titleHeader: string;
  impost: FormGroup;
  disabledButton:boolean;
  dialogMessages;

  @ViewChild('descricao') descricao: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ImpostFormComponent>,
    private impostService: ImpostService,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData) { }

  ngOnInit() {

    this.titleHeader = (this._data.action == 'new') ? 'Cadastro de Imposto' : 'Edição de Imposto';

    this.setFormGroup();
  }

  ngAfterViewInit() {
    setTimeout(() => this.descricao.nativeElement.focus(), 1000);
  }


  setFormGroup() {
    let aliquota = this._data.data.aliquota ? this._data.data.aliquota.toString() : null;
    let tamAliquota = this._data.data.aliquota ? aliquota.length : null;
    aliquota = this._data.data.aliquota ? `${aliquota.substring(0, tamAliquota - 2)}.${aliquota.substring(tamAliquota - 2)}` : null;

    this.impost = this.formBuilder.group({
      id            : [ this._data.data ? this._data.data.id : null ],
      descricao     : [ this._data.data ? this._data.data.descricao  : null, Validators.required ],
      valorInicial  : [ this._data.data ? this._data.data.valorInicial : null , Validators.required ],
      valorFinal    : [ this._data.data ? this._data.data.valorFinal : null, Validators.required ],
      aliquota      : [ aliquota       || null, Validators.required ]
    })
  }

  onClosed() {
    this.matDialogRef.close();
  }

  onAdd(value) {
    this.disabledButton = true;
    value.valorInicial = value.valorInicial.toString().replace(/[.,]/g, "");
    value.valorFinal = value.valorFinal.toString().replace(/[.,]/g, "");
    value.aliquota = value.aliquota.toString().replace(/[.,]/g, "");

    this.impostService.create(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR IMPOSTO', onerror);
      }
    )
  }

  onUpdate(value) {
    this.disabledButton = true;
    value.valorInicial = value.valorInicial.toString().replace(/[.,]/g, "");
    value.valorFinal = value.valorFinal.toString().replace(/[.,]/g, "");
    value.aliquota = value.aliquota.toString().replace(/[.,]/g, "");
    
    this.impostService.update(value).subscribe(
      (ondata: ReturnApi) => {

        if (ondata.success) {
          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${ondata.messages[0].text}`
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close(ondata.object);
        } else {
          if(ondata.messages[0].text != null){
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: `${ondata.messages[0].text}`
              }
          }else{
           this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            }
          }

          this.feedback(this.dialogMessages);
          this.matDialogRef.close();
        }

      },
      (onerror) => {
        console.log('ERROR IMPOSTO', onerror);
      }
    )
  }

  private feedback(message: string[]) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: message,
    })
    setTimeout(() => dialogRef.close(), 8000);
  }

}
