import { take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../services/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveUserService implements Resolve<any> {

  superUser: any;

  constructor(
    private userService: UserService) {
      
     }

  resolve(): Observable<any> | any {
    
    return this.userService.pageAll(10,1).pipe(
      take(1),
      mergeMap(teste => {
        return of(teste['object']);
      })
    );
    
  }

}
