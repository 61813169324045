import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogData} from '../role-form/role-form.component';
import {FolhaCobrancaService} from '../../../financial/services/folha-cobranca/folha-cobranca.service';
import {EmployeeService} from '../../../global/components/employees/services/employee.service';
import {Observable} from 'rxjs';
import {Employee} from '../../model/employee/employee.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-data-unica-form',
  templateUrl: './data-unica-form.component.html',
  styleUrls: ['./data-unica-form.component.scss']
})
export class DataUnicaFormComponent implements OnInit {

  dataRetroativa: any;
  filteredOptions: Observable<Employee[]>;
  currentFuncionario = null;
  funcionarios: any;

  public usuario = JSON.parse(sessionStorage.getItem('funcionario'));

  constructor(
      public matDialogRef: MatDialogRef<DataUnicaFormComponent>,
      @Inject(MAT_DIALOG_DATA) public _data: DialogData,
      private folhaCobrancaService: FolhaCobrancaService,
      private funcionarioService: EmployeeService
      ) {

    this.funcionarioService.findAll().subscribe(
        (ondata) => {
          this.filteredOptions = ondata.object;
        }
    );

  }

  ngOnInit(): void {
  }

  onClosed(): void {
    this.matDialogRef.close();
  }

  gerar(): void {

  this.folhaCobrancaService.processarFolhaCobrancaRetroativa(this.dataRetroativa, this.currentFuncionario.id, this.usuario.idUsuario).subscribe((folha: Blob | any) => {
    if (folha) {
      const hoje = new Date();
      // tslint:disable-next-line: max-line-length
      FileSaver.saveAs(folha, `folha_cobranca_retroativa_${this.currentFuncionario.id}_${('00' + hoje.getDate()).slice(-2)}${('00' + (hoje.getMonth() + 1)).slice(-2)}${hoje.getFullYear()}.pdf`);
    }
  });

    this.onClosed();
  }


  doFilter(evento: Event): any{
    const target = evento.target as HTMLTextAreaElement;
    this.funcionarioService.getFuncionarioFilterDescricao(target.value).subscribe( data => {
      this.funcionarios = data.object.content;
    });

  }


  displayFn(funcionario: Employee): string {
    return funcionario ? funcionario.pessoa.nomePessoa : '';
  }

  public serviceOnSelect(funcionario: any): void {
    this.currentFuncionario = funcionario;
  }
}
